var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "page-padding",
    },
    [
      _vm.isGoodsManage
        ? _c(
            "div",
            {
              attrs: {
                name: "fade-transform",
                mode: "out-in",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "page-header--wrap",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "page-header-wrap--title",
                    },
                    [_vm._v("商品管理")]
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                          },
                          on: {
                            click: _vm.handleAddBtn,
                          },
                        },
                        [_vm._v(" 发布商品 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "warning",
                            disabled: _vm.multipleSelection.length == 0,
                          },
                          on: {
                            click: _vm.handleUnShelve,
                          },
                        },
                        [_vm._v(" 下架 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "success",
                            disabled: _vm.multipleSelection.length == 0,
                          },
                          on: {
                            click: _vm.handleEnableShelve,
                          },
                        },
                        [_vm._v(" 上架 ")]
                      ),
                      _c(
                        "el-popconfirm",
                        {
                          staticStyle: {
                            "margin-left": "10px",
                          },
                          attrs: {
                            title: "确定删除该数据吗？",
                            placement: "left",
                          },
                          on: {
                            onConfirm: _vm.handleDeleteMulti,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                slot: "reference",
                                type: "danger",
                                disabled: _vm.multipleSelection.length == 0,
                              },
                              slot: "reference",
                            },
                            [_vm._v(" 删除 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "page-filter--wrap",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "page-color--normal",
                    },
                    [_vm._v("商品名称")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "page-select--item",
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入商品名称",
                        },
                        model: {
                          value: _vm.searchForm.name,
                          callback: function callback($$v) {
                            _vm.$set(_vm.searchForm, "name", $$v)
                          },
                          expression: "searchForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "page-color--normal",
                    },
                    [_vm._v("销量")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "page-select--item flex-center",
                    },
                    [
                      _c("el-input", {
                        staticStyle: {
                          width: "80px",
                        },
                        model: {
                          value: _vm.searchForm.saleNumStart,
                          callback: function callback($$v) {
                            _vm.$set(
                              _vm.searchForm,
                              "saleNumStart",
                              _vm._n($$v)
                            )
                          },
                          expression: "searchForm.saleNumStart",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            margin: "0 5px",
                          },
                        },
                        [_vm._v("-")]
                      ),
                      _c("el-input", {
                        staticStyle: {
                          width: "80px",
                        },
                        model: {
                          value: _vm.searchForm.saleNumEnd,
                          callback: function callback($$v) {
                            _vm.$set(_vm.searchForm, "saleNumEnd", _vm._n($$v))
                          },
                          expression: "searchForm.saleNumEnd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "page-select--item flex-center",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "page-color--normal",
                        },
                        [_vm._v("商品分组")]
                      ),
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            multiple: "",
                          },
                          model: {
                            value: _vm.searchForm.goods_tag_id_set,
                            callback: function callback($$v) {
                              _vm.$set(_vm.searchForm, "goods_tag_id_set", $$v)
                            },
                            expression: "searchForm.goods_tag_id_set",
                          },
                        },
                        _vm._l(_vm.goodsTagList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.name,
                              value: item.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "page-select--item flex-center",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "page-color--normal",
                        },
                        [_vm._v("商品分类")]
                      ),
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            multiple: "",
                          },
                          model: {
                            value: _vm.searchForm.goods_classification_id_set,
                            callback: function callback($$v) {
                              _vm.$set(
                                _vm.searchForm,
                                "goods_classification_id_set",
                                $$v
                              )
                            },
                            expression:
                              "searchForm.goods_classification_id_set",
                          },
                        },
                        _vm._l(_vm.goodsClassificationList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.name,
                              value: item.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "page-color--normal",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                          },
                          on: {
                            click: _vm.getList,
                          },
                        },
                        [_vm._v(" 搜索 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "page-color--normal",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                          },
                          on: {
                            click: _vm.resetSearchForm,
                          },
                        },
                        [_vm._v(" 重置筛选条件 ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-tabs",
                {
                  staticClass: "my-tab",
                  attrs: {
                    type: "border-card",
                  },
                  on: {
                    "tab-click": _vm.handleClick,
                  },
                  model: {
                    value: _vm.tabActive,
                    callback: function callback($$v) {
                      _vm.tabActive = $$v
                    },
                    expression: "tabActive",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: {
                      label: "全部",
                      name: "",
                    },
                  }),
                  _c("el-tab-pane", {
                    attrs: {
                      label: "销售中",
                      name: "ONSALE",
                    },
                  }),
                  _c("el-tab-pane", {
                    attrs: {
                      label: "已售罄",
                      name: "SOLDOUT",
                    },
                  }),
                  _c("el-tab-pane", {
                    attrs: {
                      label: "补货中",
                      name: "REPLENISHING",
                    },
                  }),
                  _c("el-tab-pane", {
                    attrs: {
                      label: "待上架",
                      name: "WATTING",
                    },
                  }),
                  _c("el-tab-pane", {
                    attrs: {
                      label: "仓库中",
                      name: "WAREHOUSE",
                    },
                  }),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      ref: "dragTable",
                      staticStyle: {
                        width: "100%",
                      },
                      attrs: {
                        "row-key": "id",
                        data: _vm.goodsData,
                        stripe: "",
                        border: "",
                        "highlight-current-row": "",
                      },
                      on: {
                        "selection-change": _vm.handleSelectionChange,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "55",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "序号",
                          width: "60",
                          align: "center",
                        },
                      }),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            align: "center",
                            label: "排序",
                            width: "80",
                          },
                        },
                        [
                          _c("svg-icon", {
                            staticClass: "drag-handler",
                            attrs: {
                              "icon-class": "drag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          width: "200px",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function fn(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                      },
                                      on: {
                                        click: function click($event) {
                                          return _vm.handleEdit(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v(" 编辑 ")]
                                  ),
                                  scope.row.sale_status ===
                                  _vm.SALE_STATUS_VALUE_MAP["FOR_SALE"]
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                          },
                                          on: {
                                            click: function click($event) {
                                              return _vm.handleDisableGoods(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 下架 ")]
                                      )
                                    : _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                          },
                                          on: {
                                            click: function click($event) {
                                              return _vm.handleEnableGoods(
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 上架 ")]
                                      ),
                                  !_vm.isVirtual
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            loading: scope.row.btn_loading,
                                          },
                                          on: {
                                            click: function click($event) {
                                              return _vm.handlePromote(
                                                scope.row,
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 推广 ")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        loading: scope.row.btn_loading,
                                      },
                                      on: {
                                        click: function click($event) {
                                          return _vm.handleCopyGood(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v(" 复制 ")]
                                  ),
                                  _c("el-popconfirm", {
                                    staticStyle: {
                                      "margin-left": "10px",
                                    },
                                    attrs: {
                                      title: "确定删除该数据吗？",
                                      placement: "left",
                                    },
                                    on: {
                                      onConfirm: function onConfirm($event) {
                                        return _vm.handleDeleteGoods(scope.row)
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "reference",
                                          fn: function fn() {
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                  },
                                                },
                                                [_vm._v(" 删除 ")]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3675441932
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "400",
                          align: "center",
                          prop: "name",
                          label: "商品名称",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function fn(scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex-center",
                                      staticStyle: {
                                        "justify-content": "start",
                                      },
                                    },
                                    [
                                      _c("el-image", {
                                        staticStyle: {
                                          "max-width": "80px",
                                          height: "80px",
                                          "margin-right": "10px",
                                        },
                                        attrs: {
                                          src: scope.row.cover_image_address,
                                          fit: "contain",
                                        },
                                      }),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticStyle: {
                                            overflow: "hidden",
                                            "text-overflow": "ellipsis",
                                          },
                                          attrs: {
                                            effect: "dark",
                                            content: scope.row.name,
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "max-width": "210px",
                                              },
                                              attrs: {
                                                type: "text",
                                              },
                                              on: {
                                                click: function click($event) {
                                                  return _vm.handleEdit(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(scope.row.name) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          739922347
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "sale_status",
                          label: "商品状态",
                          formatter: _vm.saleStatusFormatter,
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "商品分组",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function fn(_ref) {
                                var _row$related_goods_ta
                                var row = _ref.row
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        ((_row$related_goods_ta =
                                          row.related_goods_tags) === null ||
                                        _row$related_goods_ta === void 0
                                          ? void 0
                                          : _row$related_goods_ta
                                              .map(function (item) {
                                                return item.goods_tag_name
                                              })
                                              .join("、")) || "-"
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1529711749
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "商品分类",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function fn(_ref2) {
                                var _row$related_goods_cl
                                var row = _ref2.row
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        ((_row$related_goods_cl =
                                          row.related_goods_classifications) ===
                                          null ||
                                        _row$related_goods_cl === void 0
                                          ? void 0
                                          : _row$related_goods_cl
                                              .map(function (item) {
                                                return item.goods_classification_name
                                              })
                                              .join("、")) || "-"
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3667105701
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "stock_num",
                          label: "库存",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          width: "160px",
                          align: "center",
                          prop: "min_price",
                          label: "价格",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function fn(_ref3) {
                                var row = _ref3.row
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatPrice({
                                          price:
                                            row.goods_sku_info_list[0]
                                              .retail_price,
                                          point:
                                            row.goods_sku_info_list[0]
                                              .retail_points,
                                        })
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3224569421
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "visit_num",
                          label: "访问量",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "visitor_num",
                          label: "访客数",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "销量",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function fn(_ref4) {
                                var row = _ref4.row
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row.sale_num == null ? 0 : row.sale_num
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2764376437
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "创建人/修改人",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function fn(_ref5) {
                                var _row$creator, _row$updater
                                var row = _ref5.row
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        ""
                                          .concat(
                                            (_row$creator = row.creator) !==
                                              null && _row$creator !== void 0
                                              ? _row$creator
                                              : "-",
                                            "/"
                                          )
                                          .concat(
                                            (_row$updater = row.updater) !==
                                              null && _row$updater !== void 0
                                              ? _row$updater
                                              : "-"
                                          )
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4287583979
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "创建时间/修改时间",
                          "min-width": "110",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function fn(_ref6) {
                                var row = _ref6.row
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        ""
                                          .concat(
                                            row.create_time
                                              ? _vm.formatTime(row.create_time)
                                              : "-",
                                            "/"
                                          )
                                          .concat(
                                            row.update_time
                                              ? _vm.formatTime(row.update_time)
                                              : "-"
                                          )
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1625046708
                        ),
                      }),
                    ],
                    1
                  ),
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.total > 0,
                        expression: "total > 0",
                      },
                    ],
                    attrs: {
                      total: _vm.total,
                      page: _vm.listQuery.page,
                      limit: _vm.listQuery.limit,
                    },
                    on: {
                      "update:page": function updatePage($event) {
                        return _vm.$set(_vm.listQuery, "page", $event)
                      },
                      "update:limit": function updateLimit($event) {
                        return _vm.$set(_vm.listQuery, "limit", $event)
                      },
                      pagination: _vm.getList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isGoodsManage
        ? _c(
            "div",
            [
              _c("goods-info", {
                attrs: {
                  "spu-code": _vm.spuCode,
                  "is-copy": _vm.isCopy,
                },
                on: {
                  goBack: _vm.goBack,
                  close: function close($event) {
                    _vm.isCopy = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "推广",
            visible: _vm.dialogPromote,
            width: "500px",
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.dialogPromote = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.formPromote,
                "label-width": "90px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "分享链接",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: "",
                        },
                        model: {
                          value: _vm.formPromote.path,
                          callback: function callback($$v) {
                            _vm.$set(_vm.formPromote, "path", $$v)
                          },
                          expression: "formPromote.path",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                          },
                          on: {
                            click: function click($event) {
                              return _vm.handleCopy(
                                _vm.formPromote.path,
                                $event
                              )
                            },
                          },
                        },
                        [_vm._v(" 复制 ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "小程序码",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "column",
                        "align-items": "flex-start",
                      },
                    },
                    [
                      _c("el-image", {
                        staticClass: "code-img",
                        attrs: {
                          src: _vm.formPromote.qr_code_data,
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            margin: "10px 0",
                          },
                          attrs: {
                            type: "text",
                          },
                          on: {
                            click: _vm.handleDownloadQRcode,
                          },
                        },
                        [_vm._v(" 下载小程序码 ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }