var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "el-dialog",
    {
      attrs: {
        width: "1000px",
        visible: _vm.visible,
        title: "批量发货",
        "close-on-click-modal": false,
      },
      on: {
        close: function close($event) {
          return _vm.handleVisibleChange(false)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "flex",
        },
        [
          _c(
            "div",
            _vm._l(_vm.activities, function (activity, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "step",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "dot",
                    },
                    [_vm._v(" " + _vm._s(index + 1) + " ")]
                  ),
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "first-row",
                      },
                      [_vm._v(_vm._s(activity.firstRow))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "second-row",
                      },
                      [_vm._v(_vm._s(activity.secondRow))]
                    ),
                  ]),
                  index < _vm.activities.length - 1
                    ? _c("div", {
                        staticClass: "tiem-line",
                      })
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            {
              staticClass: "grow",
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.downloadData,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "订单状态：",
                      },
                    },
                    [
                      _vm._v(" 待发货"),
                      _c(
                        "span",
                        {
                          staticClass: "ml-[30px]",
                        },
                        [
                          _vm._v(
                            "注意：仅支持订单内所有商品都未发货的订单，部分商品已发货的订单不支持批量发货。"
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "dates",
                        label: "订单日期范围：",
                        rules: _vm.rules.dates,
                        required: "",
                      },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          clearable: false,
                          "value-format": "yyyy-MM-dd",
                        },
                        on: {
                          change: _vm.handleDateChange,
                        },
                        model: {
                          value: _vm.downloadData.dates,
                          callback: function callback($$v) {
                            _vm.$set(_vm.downloadData, "dates", $$v)
                          },
                          expression: "downloadData.dates",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "logistics_company",
                        label: "物流公司：",
                        rules: _vm.rules.logistics_company,
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择物流公司",
                            filterable: "",
                          },
                          model: {
                            value: _vm.downloadData.logistics_company,
                            callback: function callback($$v) {
                              _vm.$set(
                                _vm.downloadData,
                                "logistics_company",
                                $$v
                              )
                            },
                            expression: "downloadData.logistics_company",
                          },
                        },
                        _vm._l(_vm.express_options, function (item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: {
                              label: item.name,
                              value: item.name,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            round: "",
                            type: "primary",
                            loading: _vm.downloadLoading,
                          },
                          on: {
                            click: _vm.handleExport,
                          },
                        },
                        [_vm._v("导出订单")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "温馨提示：",
                      },
                    },
                    [
                      _vm._v(
                        " 1、请勿更改导出文件中的订单号及物流公司，否则可能导致发货失败。 "
                      ),
                      _c("br"),
                      _vm._v("2、在导出文件中填写物流单号。 "),
                      _c("br"),
                      _vm._v(
                        "3、上传前检查数据的完整性及准确性，即确认好物流商的选择是否正确，"
                      ),
                      _c("br"),
                      _vm._v("物流单号是否填写完整，否则无法完成批量发货。 "),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "upload",
                          staticClass: "upload-container",
                          attrs: {
                            "on-remove": _vm.handleRemove,
                            action: "empty",
                            "http-request": _vm.handleCheck,
                            "file-list": _vm.fileList,
                            limit: 1,
                          },
                        },
                        [
                          !_vm.fileList.length
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "mr-[10px]",
                                  attrs: {
                                    round: "",
                                    type: "primary",
                                  },
                                },
                                [_vm._v("上传文件")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.success_count + _vm.fail_count
                    ? _c("el-form-item", [
                        _vm._v(
                          " 共上传 " +
                            _vm._s(_vm.success_count + _vm.fail_count) +
                            " 个，成功 "
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "blue",
                          },
                          [_vm._v(_vm._s(_vm.success_count))]
                        ),
                        _vm._v(" 个，失败 "),
                        _c(
                          "span",
                          {
                            staticClass: "red",
                          },
                          [_vm._v(_vm._s(_vm.fail_count))]
                        ),
                        _vm._v(" 个 "),
                        _vm.fail_count
                          ? _c("div", [_vm._v("失败原因：")])
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "fail-list-container",
                          },
                          _vm._l(_vm.fail_list, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: ""
                                  .concat(item.order_num, "_")
                                  .concat(index),
                              },
                              [
                                _vm._v(
                                  "订单" +
                                    _vm._s(item.order_num) +
                                    "导入失败，" +
                                    _vm._s(item.fail_msg)
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "mt-[100px]",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            round: "",
                          },
                          on: {
                            click: function click($event) {
                              return _vm.handleVisibleChange(false)
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            round: "",
                            type: "primary",
                          },
                          on: {
                            click: _vm.handleSubmit,
                          },
                        },
                        [_vm._v("提交并发货")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }