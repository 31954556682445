import { formatTimestamp } from "@/chat/utils/dates";
// import { notFinishListReq } from "@/views/mall-im/instant/services/room-api";
import { formatRoomList } from "@/views/mall-im/instant/utils/room";
import { commonUtil } from "@/utils";
import { CUSTOMER_SERVICE } from "@/constants";
import api from "@/api";

const { MESSAGE_TYPE_VALUE_MAP, USER_TYPE_VALUE_MAP } = CUSTOMER_SERVICE;
const { to } = commonUtil;

const state = {
  currentRoom: {},
  notFinishList: [],
  messages: [
    {
      // _id: this.currentUserId,
      _id: "ADMIN", // ADMIN 代表客服
      content: "我是一条消息",
      // senderId: 1234,
      senderId: "",
      avatar: "https://cdn.jmj1995.com/front_161578905677754.jpeg",
      date: formatTimestamp(new Date(), new Date()),
      timestamp: formatTimestamp(new Date(), new Date()),
    },
  ],
  currentChatTab: "notFinish", //可选：会话中、已结束、最近。默认会话中
};
/**
 * mutations
 */
const mutations = {
  PUSH_MESSAGE: (state, message) => state.messages.push(message),
  SET_CURRENT_ROOM: (state, currentRoom) => {
    state.currentRoom = currentRoom;
    state.messages = [];
  },
  RESET_CURRENT_ROOM: (state) => (state.currentRoom = {}),
  SET_NOT_FINISH_LIST: (state, notFinishList) => (state.notFinishList = notFinishList),
  SET_CURRENTCHAT_TAB: (state, payload) => (state.currentChatTab = payload),
};
/**
 * actions
 */
const actions = {
  /**
   * 把历史消息整理成前端需要的数据格式
   */
  pushMessage({ commit, dispatch }, message) {
    const messageType = message?.message_type;
    let msgObj;
    if ([MESSAGE_TYPE_VALUE_MAP["TEXT"], MESSAGE_TYPE_VALUE_MAP["IMAGE"]].includes(messageType)) {
      msgObj = textAndImgMseeage(message);
    } else if ([MESSAGE_TYPE_VALUE_MAP["GOODS"], MESSAGE_TYPE_VALUE_MAP["ORDER"]].includes(messageType)) {
      msgObj = fileMessage(message);
    } else {
      return;
    }

    if (isMessageBelongsCurrentRoom(msgObj)) commit("PUSH_MESSAGE", msgObj);
    else to(dispatch("setNotFinishList"));
  },
  /**
   * 设置当前联系人信息
   */
  setCurrentRoom({ commit }, currentRoom) {
    commit("SET_CURRENT_ROOM", currentRoom);
  },
  resetCurrentRoom({ commit }) {
    commit("RESET_CURRENT_ROOM");
  },
  async setNotFinishList({ commit, dispatch }, payload = {}) {
    const { finished, mobile_number } = payload;
    const list = await getNotFinishList();

    // 如果从历史会话页面跳转过来，此人会话可能会超过200条，所以单独查一下跳转过来的数据，不在list里面就加到里面来显示。
    if (mobile_number) {
      const res = await getNotFinishList({ blurry: mobile_number });
      (res ?? []).forEach((newItem) => {
        if (!list.some((item) => item.session_id === newItem.session_id)) {
          list.push(newItem);
        }
      });
    }

    const data = formatRoomList(list);
    await dispatch("setCurrentChatTab", finished ? "finished" : "notFinish");
    commit("SET_NOT_FINISH_LIST", data);
    return data;
  },
  setCurrentChatTab({ commit }, tabName) {
    commit("SET_CURRENTCHAT_TAB", tabName);
  },
};

async function getNotFinishList(params = {}) {
  const [err, res] = await api.customerService.customerService({
    resource: "notFinish",
    params: {
      page: 1,
      size: 200,
      ...params,
    },
  });
  return err ? [] : res.list;
}

/**
 * 构造 text 类型的消息 TODO:待重构
 */
function textAndImgMseeage(message) {
  let msgObj = {
    id: message.id,
    _id: message.id,
    sessionId: message.session_id,
    senderId: message.user_type === USER_TYPE_VALUE_MAP["USER"] ? message.openId ?? USER_TYPE_VALUE_MAP["USER"] : USER_TYPE_VALUE_MAP["ADMIN"],
    content: message.content ? message.content : message.text || "",
    avatar: message.user_avatar_url,
    date: "",
    timestamp: "",
    file: {
      type: "",
      url: "",
    },
  };

  if (message.image_url) {
    msgObj.file.type = "png";
    msgObj.file.url = message.image_url;
  } else {
    delete msgObj.file;
  }
  let date = message.date ? message.date : message.create_time;
  let timestamp = message.timestamp ? message.timestamp : message.create_time;
  msgObj.date = formatTimestamp(new Date(date), new Date(date));
  msgObj.timestamp = formatTimestamp(new Date(timestamp), new Date(timestamp));
  return msgObj;
}

/**
 * 构造商品、订单类型的消息TODO:待重构
 */
function fileMessage(message) {
  let msgObj = {
    id: message.id,
    _id: message.id,
    sessionId: message.session_id,
    senderId: message.user_type === USER_TYPE_VALUE_MAP["USER"] ? message.openId ?? USER_TYPE_VALUE_MAP["USER"] : USER_TYPE_VALUE_MAP["ADMIN"],
    content: "",
    avatar: message.user_avatar_url,
    date: "",
    timestamp: "",
    // 如果是商品链接或者订单链接特殊处理
    file: {
      type: "png",
      url: "",
      msg_type: "",
      order_no: "",
    },
  };

  msgObj.avatar = message.user_avatar_url;
  if (message.message_type === MESSAGE_TYPE_VALUE_MAP["ORDER"]) {
    msgObj.content = `订单编号: ${message.order_no} ￥${message.paid_money} 【订单】`;
  } else {
    msgObj.content = message.content ? message.content : `${message.goods_name} ￥${message.price} 【商品】` || "";
  }
  msgObj.file.url = message.image_url;
  msgObj.file.msg_type = message.message_type;
  msgObj.file.order_no = message.order_no ? message.order_no : "";
  let date = message.date ? message.date : message.create_time;
  let timestamp = message.timestamp ? message.timestamp : message.create_time;
  msgObj.date = formatTimestamp(new Date(date), new Date(date));
  msgObj.timestamp = formatTimestamp(new Date(timestamp), new Date(timestamp));
  return msgObj;
}

function isMessageBelongsCurrentRoom(message) {
  return [state.currentRoom.sessionId].includes(message.sessionId);
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
