import Layout from "@/layout";

const accountRouter = {
  path: "/activity",
  component: Layout,
  redirect: "/activity",
  name: "activity",
  alwaysShow: true,
  meta: {
    title: "活动管理",
    icon: "staff",
    affix: true,
    roles: ["admin"],
  },
  children: [
    {
      path: "/depreciateList",
      name: "Depreciate",
      component: () => import("@/views/activity/depreciate"),
      meta: { title: "限时降价" },
    },
    {
      path: "/depreciateDetail",
      name: "DepreciateDetail",
      hidden: true,
      component: () => import("@/views/activity/depreciate/detail/index"),
      meta: { title: "活动管理" },
    },
  ],
};

export default accountRouter;
