var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("el-alert", {
        attrs: {
          title: "物流信息仅支持 2 次更正，请仔细填写并核对。",
          type: "warning",
          "show-icon": "",
          closable: false,
        },
      }),
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: {
            "padding-top": "20px",
          },
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            "label-width": "90px",
            "label-position": "left",
          },
        },
        _vm._l(_vm.pacData, function (item, index) {
          return _c(
            "div",
            {
              key: index,
            },
            [
              _c(
                "el-row",
                {
                  attrs: {
                    type: "flex",
                  },
                },
                [
                  _c("el-form-item", {
                    attrs: {
                      label: "\u5305\u88F9".concat(index + 1),
                      "label-width": "50px",
                    },
                  }),
                  _c("el-form-item", {
                    attrs: {
                      label: "\u5171".concat(item.num, "\u4EF6\u5546\u54C1"),
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  attrs: {
                    type: "flex",
                  },
                },
                [
                  _c(
                    "el-col",
                    {
                      attrs: {
                        span: 12,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "物流公司",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: {
                                width: "200px",
                              },
                              model: {
                                value: item.express_company,
                                callback: function callback($$v) {
                                  _vm.$set(item, "express_company", $$v)
                                },
                                expression: "item.express_company",
                              },
                            },
                            _vm._l(_vm.express_options, function (option) {
                              return _c("el-option", {
                                key: option.value,
                                attrs: {
                                  label: option.label,
                                  value: option.value,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      attrs: {
                        span: 12,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "运单编号",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "200px",
                            },
                            model: {
                              value: item.logistics_order_no,
                              callback: function callback($$v) {
                                _vm.$set(item, "logistics_order_no", $$v)
                              },
                              expression: "item.logistics_order_no",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "text",
              },
              on: {
                click: _vm.handleCancel,
              },
            },
            [_vm._v(" 取消 ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
              },
              on: {
                click: _vm.handleConfirm,
              },
            },
            [_vm._v(" 保存 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }