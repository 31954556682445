var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            rules: _vm.formRule,
            model: _vm.form,
            "label-width": "200px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "div",
                {
                  staticClass: "panel-item",
                  staticStyle: {
                    "margin-top": "20px",
                  },
                },
                [_vm._v("交易设置")]
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    span: 18,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "待付款订单取消时间：",
                        prop: "time_in_minute_to_trigger_auto_cancel_order",
                      },
                    },
                    [
                      _vm._v(" 拍下后 "),
                      _c("el-input", {
                        staticStyle: {
                          width: "80px",
                        },
                        model: {
                          value:
                            _vm.form
                              .time_in_minute_to_trigger_auto_cancel_order,
                          callback: function callback($$v) {
                            _vm.$set(
                              _vm.form,
                              "time_in_minute_to_trigger_auto_cancel_order",
                              _vm._n($$v)
                            )
                          },
                          expression:
                            "form.time_in_minute_to_trigger_auto_cancel_order",
                        },
                      }),
                      _vm._v(" 分钟未付款，自动取消订单 "),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "发货后自动确认收货时间：",
                      },
                    },
                    [
                      _vm._v(" 发货后 "),
                      _c("el-input", {
                        staticStyle: {
                          width: "80px",
                        },
                        model: {
                          value:
                            _vm.form.days_to_convert_delivering_to_received,
                          callback: function callback($$v) {
                            _vm.$set(
                              _vm.form,
                              "days_to_convert_delivering_to_received",
                              _vm._n($$v)
                            )
                          },
                          expression:
                            "form.days_to_convert_delivering_to_received",
                        },
                      }),
                      _vm._v(" 天，自动确认收货 "),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "is-malls",
                          rawName: "v-is-malls",
                          value: [
                            _vm.MALL_CODE_VALUE_MAP.jiumaojiu_mall,
                            _vm.MALL_CODE_VALUE_MAP.song_points_mall,
                            _vm.MALL_CODE_VALUE_MAP.mountain_outside_mall,
                          ],
                          expression:
                            "[MALL_CODE_VALUE_MAP.jiumaojiu_mall, MALL_CODE_VALUE_MAP.song_points_mall, MALL_CODE_VALUE_MAP.mountain_outside_mall]",
                        },
                      ],
                      attrs: {
                        label: "订单可申请售后时间：",
                      },
                    },
                    [
                      _vm._v(" 订单发货后 "),
                      _c("el-input", {
                        staticStyle: {
                          width: "80px",
                        },
                        model: {
                          value: _vm.form.after_sale_period_days,
                          callback: function callback($$v) {
                            _vm.$set(
                              _vm.form,
                              "after_sale_period_days",
                              _vm._n($$v)
                            )
                          },
                          expression: "form.after_sale_period_days",
                        },
                      }),
                      _vm._v(" 天内 "),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "退款是否返还库存：",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.form
                                .increase_stock_after_after_sale_completed,
                            callback: function callback($$v) {
                              _vm.$set(
                                _vm.form,
                                "increase_stock_after_after_sale_completed",
                                $$v
                              )
                            },
                            expression:
                              "form.increase_stock_after_after_sale_completed",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                label: true,
                              },
                            },
                            [_vm._v(" 是 ")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                label: false,
                              },
                            },
                            [_vm._v(" 否 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "当前隐私政策版本号",
                        prop: "time_in_minute_to_trigger_auto_cancel_order",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: {
                          width: "80px",
                        },
                        model: {
                          value: _vm.form.version,
                          callback: function callback($$v) {
                            _vm.$set(_vm.form, "version", _vm._n($$v))
                          },
                          expression: "form.version",
                        },
                      }),
                      _vm._v(
                        " （注意：隐私政策版本号更新后，商城会重新弹窗提示用户同意） "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  attrs: {
                    span: 3,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "text-align": "center",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                          },
                          on: {
                            click: _vm.handelFormSubmit,
                          },
                        },
                        [_vm._v(" 确定 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }