import api from "@/api";
import { resetRouter } from "@/router";
import { getToken, removeToken, setToken } from "@/utils/auth";
import { MALL } from "@/constants";
const { MALL_CODE_VALUE_MAP } = MALL;

const getDefaultState = () => {
  return {
    token: getToken(),
    name: "",
    avatar: "",
    auth_info: {
      current_mall: {},
    },
    isGroupMall: false,
    isMoneyMall: false,
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_AUTH_INFO: (state, auth_info) => {
    state.auth_info = auth_info;
    if (auth_info.current_mall === null) auth_info.current_mall = {};
    state.isGroupMall = [
      MALL_CODE_VALUE_MAP["group_shopping_mall"],
      MALL_CODE_VALUE_MAP["group_points_mall"],
      MALL_CODE_VALUE_MAP["group_love_mall"],
    ].includes(auth_info.current_mall.code);
    state.isMoneyMall = [
      MALL_CODE_VALUE_MAP["taier_mall"],
      MALL_CODE_VALUE_MAP["song_mall"],
      MALL_CODE_VALUE_MAP["miliang_mall"],
      MALL_CODE_VALUE_MAP["mira_mall"],
    ].includes(auth_info.current_mall.code);
  },
};

const actions = {
  async handleHumanResourceLogin({ commit }, jwt) {
    const [err, token] = await api.users.humanResourceMiddlePlatformLogin({
      method: "post",
      data: { jwt },
    });
    if (err) throw err;
    commit("SET_TOKEN", token);
    setToken(token);
  },
  // user login
  async login({ commit }, userInfo) {
    const { username, password } = userInfo;
    const [err, token] = await api.users.login({
      method: "post",
      data: {
        username: username.trim(),
        password,
      },
    });
    if (err) return [err];
    commit("SET_TOKEN", token);
    setToken(token);
    return [null];
  },

  // get user info
  async getInfo({ commit }) {
    const [err, data] = await api.users.info();
    if (err || !data) return [new Error("Verification failed, please Login again.")];
    const { name, avatar_path } = data.user_info;
    commit("SET_NAME", name);
    commit("SET_AVATAR", avatar_path);
    commit("SET_AUTH_INFO", data);
    return [null, data];
  },
  // eslint-disable-next-line no-unused-vars
  changeUserRole({ commit }, mall_code) {
    return api.users.switchMall({
      method: "post",
      data: { mall_code },
    });
  },
  // user logout
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      api.users
        .logout({
          method: "delete",
        })
        .then(() => {
          removeToken(); // must remove  token  first
          resetRouter();
          commit("RESET_STATE");
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      removeToken(); // must remove  token  first
      commit("RESET_STATE");
      resolve();
    });
  },

  setIdentifyToken({ commit }, token) {
    commit("SET_TOKEN", token);
    setToken(token);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
