var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "page-padding",
    },
    [
      _vm._m(0),
      _c(
        "el-row",
        {
          staticClass: "page-filter--wrap",
        },
        [
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [_vm._v("营业日期:")]
          ),
          _c("el-date-picker", {
            attrs: {
              "value-format": "yyyy-MM-dd",
              type: "daterange",
              size: "mini",
              clearable: false,
              "range-separator": "至",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
            },
            on: {
              change: function change($event) {
                return _vm.handleDateChange($event, "consumption")
              },
            },
            model: {
              value: _vm.date_time_range,
              callback: function callback($$v) {
                _vm.date_time_range = $$v
              },
              expression: "date_time_range",
            },
          }),
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [
              _c(
                "el-button-group",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                      },
                      on: {
                        click: function click($event) {
                          return _vm.getNdayRange(2)
                        },
                      },
                    },
                    [_vm._v(" 昨天 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                      },
                      on: {
                        click: function click($event) {
                          return _vm.getNdayRange(7)
                        },
                      },
                    },
                    [_vm._v(" 近7天 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                      },
                      on: {
                        click: function click($event) {
                          return _vm.getNdayRange(30)
                        },
                      },
                    },
                    [_vm._v(" 近30天 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: {
                round: "",
                size: "mini",
                icon: "el-icon-refresh",
              },
              on: {
                click: _vm.resetSearchForm,
              },
            },
            [_vm._v(" 重置 ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                round: "",
                size: "mini",
                icon: "el-icon-search",
              },
              on: {
                click: function click($event) {
                  return _vm.getList()
                },
              },
            },
            [_vm._v(" 搜索 ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                round: "",
                size: "mini",
                icon: "el-icon-refresh",
                loading: _vm.refreshLoading,
              },
              on: {
                click: function click($event) {
                  return _vm.handleRefresh()
                },
              },
            },
            [_vm._v(" 刷新 ")]
          ),
          _c(
            "el-popconfirm",
            {
              attrs: {
                title: "是否按当前搜索条件导出文件？",
                "confirm-button-text": "确认",
                "cancel-button-text": "取消",
                icon: "el-icon-question",
                "icon-color": "#409EFF",
              },
              on: {
                onConfirm: _vm.exportFile,
              },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: {
                    "margin-left": "10px",
                  },
                  attrs: {
                    slot: "reference",
                    type: "success",
                    round: "",
                    size: "mini",
                    icon: "el-icon-download",
                    loading: _vm.downloading,
                  },
                  slot: "reference",
                },
                [_vm._v(" 导出文件 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: {
            width: "100%",
          },
          attrs: {
            data: _vm.reportData,
            stripe: "",
            border: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "序号",
              width: "60",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "create_time",
              label: "时间",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "customer_price",
              label: "客单价",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "total_price",
              label: "总成交金额",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "refund_price",
              label: "成功退款金额",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "refund_ratio",
              label: "退款率",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "payer_num",
              label: "支付人数",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "pay_order_num",
              label: "支付订单数",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "pay_amount",
              label: "支付件数",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "new_customer_deal_num",
              label: "新成交客户数",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "old_customer_deal_num",
              label: "老成交客户数",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "new_customer_deal_price",
              label: "新成交客户支付金额",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "old_customer_deal_price",
              label: "老成交客户支付金额",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "new_customer_deal_price_ratio",
              label: "新成交客户支付金额占比",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "old_customer_deal_price_ratio",
              label: "老成交客户支付金额占比",
              "show-overflow-tooltip": "",
            },
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function updatePage($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function updateLimit($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "page-header--wrap",
      },
      [
        _c(
          "div",
          {
            staticClass: "page-header-wrap--title",
          },
          [_vm._v("全店日报")]
        ),
        _c("div"),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }