var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("div", {
        staticClass: "room-right-header",
      }),
      _c(
        "el-tabs",
        {
          staticClass: "tabs-class",
          attrs: {
            type: "border-card",
          },
          on: {
            "tab-click": _vm.handleTabClick,
          },
          model: {
            value: _vm.activeTab,
            callback: function callback($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: "资料",
                name: "profile",
              },
            },
            [_c("customer-info")],
            1
          ),
          _c(
            "el-tab-pane",
            {
              staticClass: "checkbox-group",
              attrs: {
                label: "订单",
                name: "order",
              },
            },
            _vm._l(_vm.ordersData, function (order, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "order-wrap",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "line-height28 order-link",
                      on: {
                        click: function click($event) {
                          return _vm.handleOrderDetail(order.order_id)
                        },
                      },
                    },
                    [_vm._v("订单编号：" + _vm._s(order.order_number))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "line-height28",
                    },
                    [
                      _vm._v(" 下单时间：" + _vm._s(order.place_time) + " "),
                      _c(
                        "span",
                        {
                          staticClass: "order-link",
                          on: {
                            click: function click($event) {
                              return _vm.handleOrderDetail(order.order_id)
                            },
                          },
                        },
                        [_vm._v(_vm._s(order.status_chinese_representation))]
                      ),
                    ]
                  ),
                  _vm._l(order.order_line_info_list, function (goods, gindex) {
                    return _c(
                      "div",
                      {
                        key: gindex,
                        staticClass: "flex-center",
                        staticStyle: {
                          "justify-content": "start",
                        },
                      },
                      [
                        _c("el-image", {
                          staticClass: "goods-image",
                          attrs: {
                            src: goods.goods_sku_image_address,
                            fit: "contain",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              flex: "0 1 70%",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "line-height28",
                                staticStyle: {
                                  "max-width": "210px",
                                },
                                attrs: {
                                  type: "text",
                                },
                              },
                              [_vm._v(" " + _vm._s(goods.goods_name) + " ")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "line-height28",
                              },
                              [
                                _vm._v(
                                  "规格：" +
                                    _vm._s(goods.goods_sku_specification)
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "line-height28",
                      staticStyle: {
                        display: "flex",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-right": "5px",
                          },
                        },
                        [
                          _vm._v(
                            " 共 " +
                              _vm._s(order.order_line_info_list.length) +
                              " 件商品，实付 "
                          ),
                          order.pay_type === _vm.PAY_TYPE_VALUE_MAP["MONEY"]
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#f56c6c",
                                  },
                                },
                                [_vm._v(" ￥" + _vm._s(order.total_price))]
                              )
                            : _vm._e(),
                          order.pay_type === _vm.PAY_TYPE_VALUE_MAP["POINTS"]
                            ? _c("span", [
                                _vm._v(_vm._s(order.total_points) + " 积分"),
                              ])
                            : _vm._e(),
                          order.pay_type ===
                          _vm.PAY_TYPE_VALUE_MAP["MIX_MONEY_POINTS"]
                            ? _c("span", [
                                _vm._v(
                                  "￥" +
                                    _vm._s(order.total_price) +
                                    "+" +
                                    _vm._s(order.total_points || 0) +
                                    " 积分"
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "top",
                            trigger: "hover",
                          },
                        },
                        [
                          _c("p", [
                            _vm._v(
                              "收货人：" +
                                _vm._s(order.shipping_info.receiver_name)
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              "联系电话：" +
                                _vm._s(
                                  order.shipping_info.receiver_mobile_number
                                )
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              "收货地址：" +
                                _vm._s(_vm.getCompleteAddress(order))
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "text-align": "right",
                                margin: "0",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                  },
                                  on: {
                                    click: function click($event) {
                                      _vm.handleSendAddress(
                                        "\u6536\u8D27\u4EBA\uFF1A"
                                          .concat(
                                            order.shipping_info.receiver_name,
                                            "\n\u8054\u7CFB\u7535\u8BDD\uFF1A"
                                          )
                                          .concat(
                                            order.shipping_info
                                              .receiver_mobile_number,
                                            "\n\u6536\u8D27\u5730\u5740\uFF1A"
                                          )
                                          .concat(_vm.getCompleteAddress(order))
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 发送地址 ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                slot: "reference",
                                type: "text",
                              },
                              slot: "reference",
                            },
                            [_vm._v(" 收货地址 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                slot: "reference",
                                type: "text",
                              },
                              on: {
                                click: function click($event) {
                                  return _vm.handleSendOrder(order)
                                },
                              },
                              slot: "reference",
                            },
                            [_vm._v(" 发送订单 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              )
            }),
            0
          ),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: "商品",
                name: "goods",
              },
            },
            [
              _c(
                "el-checkbox-group",
                {
                  staticClass: "checkbox-group",
                  on: {
                    change: _vm.handleCheckboxChange,
                  },
                  model: {
                    value: _vm.checkList,
                    callback: function callback($$v) {
                      _vm.checkList = $$v
                    },
                    expression: "checkList",
                  },
                },
                _vm._l(_vm.goodsList, function (item, index) {
                  return _c(
                    "el-checkbox",
                    {
                      key: index,
                      staticClass: "goods-checkbox",
                      attrs: {
                        label: item.id,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "flex-center",
                          staticStyle: {
                            "justify-content": "start",
                          },
                        },
                        [
                          _c("el-image", {
                            staticClass: "goods-image",
                            attrs: {
                              src: item.cover_image_address,
                              fit: "contain",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                flex: "0 1 70%",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "max-width": "210px",
                                  },
                                  attrs: {
                                    type: "text",
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              ),
                              item.pay_type === _vm.PAY_TYPE_VALUE_MAP["MONEY"]
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#f56c6c",
                                        margin: "3px",
                                      },
                                    },
                                    [_vm._v("￥" + _vm._s(item.min_price || 0))]
                                  )
                                : _vm._e(),
                              item.pay_type === _vm.PAY_TYPE_VALUE_MAP["POINTS"]
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#f56c6c",
                                        margin: "3px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(item.min_points || 0) + "积分"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.pay_type ===
                              _vm.PAY_TYPE_VALUE_MAP["MIX_MONEY_POINTS"]
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#f56c6c",
                                        margin: "3px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " ￥" +
                                          _vm._s(item.min_price || 0) +
                                          "+" +
                                          _vm._s(item.min_points || 0) +
                                          "积分 "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "space-between",
                                    color: "#909399",
                                  },
                                },
                                [
                                  _c("div", [
                                    _vm._v("库存：" + _vm._s(item.stock_num)),
                                  ]),
                                  _c("div", [
                                    _vm._v("销量：" + _vm._s(item.sale_num)),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                      },
                      on: {
                        click: _vm.handleSubmitGoods,
                      },
                    },
                    [_vm._v(" 确定 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }