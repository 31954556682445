var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "page-padding",
    },
    [
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "page-header--wrap",
            },
            [
              _c(
                "div",
                {
                  staticClass: "page-header-wrap--title",
                },
                [_vm._v("订位表")]
              ),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                      },
                      on: {
                        click: _vm.handleSettingBtn,
                      },
                    },
                    [_vm._v(" 设置 ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-row",
            {
              staticClass: "page-filter--wrap",
            },
            [
              _c(
                "div",
                {
                  staticClass: "page-color--normal",
                },
                [_vm._v("日期")]
              ),
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  clearable: "",
                  "unlink-panels": "",
                },
                on: {
                  change: _vm.handleDateChange,
                },
                model: {
                  value: _vm.order_time,
                  callback: function callback($$v) {
                    _vm.order_time = $$v
                  },
                  expression: "order_time",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "page-color--normal",
                },
                [_vm._v("大厨工作室")]
              ),
              _c(
                "el-select",
                {
                  staticStyle: {
                    width: "150px",
                  },
                  attrs: {
                    placeholder: "全部",
                  },
                  model: {
                    value: _vm.searchForm.type,
                    callback: function callback($$v) {
                      _vm.$set(_vm.searchForm, "type", $$v)
                    },
                    expression: "searchForm.type",
                  },
                },
                [
                  _c("el-option", {
                    attrs: {
                      label: "湘菜",
                      value: 1,
                    },
                  }),
                  _c("el-option", {
                    attrs: {
                      label: "川菜",
                      value: 2,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticStyle: {
                    margin: "0 20px",
                  },
                  attrs: {
                    round: "",
                    icon: "el-icon-refresh",
                  },
                  on: {
                    click: _vm.resetSearchForm,
                  },
                },
                [_vm._v(" 重置 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    round: "",
                    icon: "el-icon-search",
                  },
                  on: {
                    click: _vm.getList,
                  },
                },
                [_vm._v(" 搜索 ")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: {
                width: "100%",
              },
              attrs: {
                data: _vm.booksData,
                stripe: "",
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "date",
                  label: "日期",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "time",
                  label: "时间段",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref) {
                      var row = _ref.row
                      return [
                        _vm._v(
                          " " + _vm._s(row.time == 1 ? "中午" : "晚上") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "type",
                  label: "大厨工作室",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref2) {
                      var row = _ref2.row
                      return [
                        _vm._v(
                          " " + _vm._s(row.type == 1 ? "湘菜" : "川菜") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "is_on_off",
                  label: "工作室用餐",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref3) {
                      var row = _ref3.row
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-value": 1,
                            "inactive-value": 0,
                            "active-text": "启用",
                            "inactive-text": "禁用",
                          },
                          on: {
                            change: function change($event) {
                              return _vm.changeStatus($event, row, "studio")
                            },
                          },
                          model: {
                            value: row.is_on_off,
                            callback: function callback($$v) {
                              _vm.$set(row, "is_on_off", $$v)
                            },
                            expression: "row.is_on_off",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "is_on_off_to_home",
                  label: "上门服务",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref4) {
                      var row = _ref4.row
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-value": 1,
                            "inactive-value": 0,
                            "active-text": "启用",
                            "inactive-text": "禁用",
                          },
                          on: {
                            change: function change($event) {
                              return _vm.changeStatus($event, row, "home")
                            },
                          },
                          model: {
                            value: row.is_on_off_to_home,
                            callback: function callback($$v) {
                              _vm.$set(row, "is_on_off_to_home", $$v)
                            },
                            expression: "row.is_on_off_to_home",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "is_book",
                  label: "预订状态",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref5) {
                      var row = _ref5.row
                      return [
                        row.is_book == 1
                          ? _c(
                              "div",
                              [
                                _c("el-badge", {
                                  staticClass: "status-item",
                                  attrs: {
                                    "is-dot": "",
                                    type: "success",
                                  },
                                }),
                                _vm._v("已预订"),
                              ],
                              1
                            )
                          : _vm._e(),
                        row.is_book == 0
                          ? _c(
                              "div",
                              [
                                _c("el-badge", {
                                  staticClass: "status-item",
                                  attrs: {
                                    "is-dot": "",
                                    type: "danger",
                                  },
                                }),
                                _vm._v("未预订"),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "num",
                  label: "预订人数",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "cook_type",
                  label: "用餐方式",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref6) {
                      var row = _ref6.row
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              row.cook_type == 1 ? "工作室用餐" : "上门服务"
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "order_no",
                  label: "预订单号",
                },
              }),
            ],
            1
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit,
            },
            on: {
              "update:page": function updatePage($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function updateLimit($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置",
            visible: _vm.dialogSetting,
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.dialogSetting = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.formSetting,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "大厨工作室",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择",
                      },
                      on: {
                        change: _vm.handleTypeChange,
                      },
                      model: {
                        value: _vm.formSetting.type,
                        callback: function callback($$v) {
                          _vm.$set(_vm.formSetting, "type", $$v)
                        },
                        expression: "formSetting.type",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label: "湘菜",
                          value: 1,
                        },
                      }),
                      _c("el-option", {
                        attrs: {
                          label: "川菜",
                          value: 2,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "用餐方式",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择",
                      },
                      on: {
                        change: _vm.handleCookTypeChange,
                      },
                      model: {
                        value: _vm.formSetting.cook_type,
                        callback: function callback($$v) {
                          _vm.$set(_vm.formSetting, "cook_type", $$v)
                        },
                        expression: "formSetting.cook_type",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label: "工作室用餐",
                          value: 1,
                        },
                      }),
                      _c("el-option", {
                        attrs: {
                          label: "上门服务",
                          value: 2,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "金额",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "用餐金额",
                    },
                    model: {
                      value: _vm.formSetting.money,
                      callback: function callback($$v) {
                        _vm.$set(_vm.formSetting, "money", $$v)
                      },
                      expression: "formSetting.money",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", {
                attrs: {
                  label: "默认可预订时间",
                },
              }),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "中午",
                  },
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.formSetting.afternoon_allow_time,
                        callback: function callback($$v) {
                          _vm.$set(_vm.formSetting, "afternoon_allow_time", $$v)
                        },
                        expression: "formSetting.afternoon_allow_time",
                      },
                    },
                    _vm._l(_vm.weeks, function (val, k) {
                      return _c(
                        "el-checkbox",
                        {
                          key: k,
                          attrs: {
                            label: val,
                          },
                        },
                        [_vm._v(" " + _vm._s(k) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "晚上",
                  },
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.formSetting.dinner_allow_time,
                        callback: function callback($$v) {
                          _vm.$set(_vm.formSetting, "dinner_allow_time", $$v)
                        },
                        expression: "formSetting.dinner_allow_time",
                      },
                    },
                    _vm._l(_vm.weeks, function (val, k) {
                      return _c(
                        "el-checkbox",
                        {
                          key: k,
                          attrs: {
                            label: val,
                          },
                        },
                        [_vm._v(" " + _vm._s(k) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              attrs: {
                slot: "footer",
              },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "text",
                  },
                  on: {
                    click: function click($event) {
                      _vm.dialogSetting = false
                    },
                  },
                },
                [_vm._v(" 取 消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                  },
                  on: {
                    click: _vm.handleSettingFormConfirm,
                  },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }