var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "el-dialog",
    {
      attrs: {
        title: "用户同意隐私政策历史",
        visible: _vm.visible,
      },
      on: {
        open: _vm.getPrivacyHistroy,
        close: function close($event) {
          return _vm.$emit("update:visible", false)
        },
      },
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: {
            width: "100%",
          },
          attrs: {
            data: _vm.privacyData,
            stripe: "",
            border: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "序号",
              width: "60",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "version",
              label: "版本号",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "agree",
              label: "是否同意",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref) {
                  var row = _ref.row
                  return [
                    _vm._v(" " + _vm._s(row.agree === 1 ? "是" : "否") + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "version_time",
              label: "版本更新时间",
              align: "center",
            },
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function updatePage($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function updateLimit($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getPrivacyHistroy,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }