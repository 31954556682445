var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showRoomsList,
          expression: "showRoomsList",
        },
      ],
      staticClass: "vac-rooms-container vac-app-border-r",
      class: {
        "vac-rooms-container-full": _vm.isMobile,
      },
    },
    [
      _vm._t("rooms-header"),
      _c("rooms-search", {
        attrs: {
          rooms: _vm.rooms,
          "loading-rooms": _vm.loadingRooms,
          "text-messages": _vm.textMessages,
          "show-add-room": _vm.showAddRoom,
        },
        on: {
          "search-room": _vm.searchRoom,
          "add-room": function addRoom($event) {
            return _vm.$emit("add-room")
          },
        },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.$scopedSlots, function (i, name) {
              return {
                key: name,
                fn: function fn(data) {
                  return [_vm._t(name, null, null, data)]
                },
              }
            }),
          ],
          null,
          true
        ),
      }),
      _c("loader", {
        attrs: {
          show: _vm.loadingRooms,
        },
      }),
      _c(
        "el-tabs",
        {
          staticClass: "room-list-tabs vac-room-list",
          attrs: {
            type: "border-card",
            value: _vm.currentChatTab,
          },
          on: {
            "tab-click": _vm.handleTabClick,
          },
        },
        [
          _c("el-tab-pane", {
            attrs: {
              label: "\u4F1A\u8BDD\u4E2D",
              name: "notFinish",
            },
          }),
          _c("el-tab-pane", {
            attrs: {
              label: "\u5DF2\u7ED3\u675F",
              name: "finished",
            },
          }),
          _c("el-tab-pane", {
            attrs: {
              label: "最近",
              name: "recent",
            },
          }),
          !_vm.loadingRooms
            ? _c(
                "div",
                {},
                [
                  _vm._l(_vm.filteredRooms, function (fRoom) {
                    return _c(
                      "div",
                      {
                        key: fRoom.roomId,
                        staticClass: "vac-room-item",
                        class: {
                          "vac-room-selected":
                            _vm.selectedRoomId === fRoom.roomId,
                        },
                        attrs: {
                          id: fRoom.roomId,
                        },
                        on: {
                          click: function click($event) {
                            return _vm.openRoom(fRoom)
                          },
                        },
                      },
                      [
                        _c("room-content", {
                          attrs: {
                            "current-user-id": _vm.currentUserId,
                            room: fRoom,
                            "text-formatting": _vm.textFormatting,
                            "link-options": _vm.linkOptions,
                            "text-messages": _vm.textMessages,
                            "room-actions": _vm.roomActions,
                          },
                          on: {
                            "room-action-handler": function roomActionHandler(
                              $event
                            ) {
                              return _vm.$emit("room-action-handler", $event)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              _vm._l(_vm.$scopedSlots, function (i, name) {
                                return {
                                  key: name,
                                  fn: function fn(data) {
                                    return [_vm._t(name, null, null, data)]
                                  },
                                }
                              }),
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    )
                  }),
                  _c(
                    "transition",
                    {
                      attrs: {
                        name: "vac-fade-message",
                      },
                    },
                    [
                      _vm.rooms.length && !_vm.loadingRooms
                        ? _c(
                            "infinite-loading",
                            {
                              attrs: {
                                spinner: "spiral",
                              },
                              on: {
                                infinite: _vm.loadMoreRooms,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  attrs: {
                                    slot: "spinner",
                                  },
                                  slot: "spinner",
                                },
                                [
                                  _c("loader", {
                                    attrs: {
                                      show: true,
                                      infinite: true,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", {
                                attrs: {
                                  slot: "no-results",
                                },
                                slot: "no-results",
                              }),
                              _c("div", {
                                attrs: {
                                  slot: "no-more",
                                },
                                slot: "no-more",
                              }),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      !_vm.loadingRooms && !_vm.rooms.length
        ? _c(
            "div",
            {
              staticClass: "vac-rooms-empty",
            },
            [
              _vm._t("rooms-empty", function () {
                return [
                  _vm._v(" " + _vm._s(_vm.textMessages.ROOMS_EMPTY) + " "),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }