<template>
  <div v-show="showRoomsList" class="vac-rooms-container vac-app-border-r" :class="{ 'vac-rooms-container-full': isMobile }">
    <slot name="rooms-header" />

    <rooms-search
      :rooms="rooms"
      :loading-rooms="loadingRooms"
      :text-messages="textMessages"
      :show-add-room="showAddRoom"
      @search-room="searchRoom"
      @add-room="$emit('add-room')"
    >
      <template v-for="(i, name) in $scopedSlots" #[name]="data">
        <slot :name="name" v-bind="data" />
      </template>
    </rooms-search>

    <loader :show="loadingRooms" />

    <el-tabs type="border-card" :value="currentChatTab" class="room-list-tabs vac-room-list" @tab-click="handleTabClick">
      <el-tab-pane :label="`会话中`" name="notFinish" />
      <el-tab-pane :label="`已结束`" name="finished" />
      <el-tab-pane label="最近" name="recent" />
      <!-- <el-tab-pane label="星标" name="star"></el-tab-pane> -->
      <div v-if="!loadingRooms" class="">
        <div
          v-for="fRoom in filteredRooms"
          :id="fRoom.roomId"
          :key="fRoom.roomId"
          class="vac-room-item"
          :class="{ 'vac-room-selected': selectedRoomId === fRoom.roomId }"
          @click="openRoom(fRoom)"
        >
          <room-content
            :current-user-id="currentUserId"
            :room="fRoom"
            :text-formatting="textFormatting"
            :link-options="linkOptions"
            :text-messages="textMessages"
            :room-actions="roomActions"
            @room-action-handler="$emit('room-action-handler', $event)"
          >
            <template v-for="(i, name) in $scopedSlots" #[name]="data">
              <slot :name="name" v-bind="data" />
            </template>
          </room-content>
        </div>

        <transition name="vac-fade-message">
          <infinite-loading v-if="rooms.length && !loadingRooms" spinner="spiral" @infinite="loadMoreRooms">
            <div slot="spinner">
              <loader :show="true" :infinite="true" />
            </div>
            <div slot="no-results" />
            <div slot="no-more" />
          </infinite-loading>
        </transition>
      </div>
    </el-tabs>

    <div v-if="!loadingRooms && !rooms.length" class="vac-rooms-empty">
      <slot name="rooms-empty">
        {{ textMessages.ROOMS_EMPTY }}
      </slot>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import InfiniteLoading from "vue-infinite-loading";

import Loader from "../../components/Loader";

import RoomsSearch from "./RoomsSearch";
import RoomContent from "./RoomContent";

import filteredUsers from "../../utils/filter-items";
import { formatRoomList } from "@/views/mall-im/instant/utils/room";
import api from "@/api";
import { commonUtil } from "@/utils";

const { to } = commonUtil;

export default {
  name: "RoomsList",
  components: {
    InfiniteLoading,
    Loader,
    RoomsSearch,
    RoomContent,
  },

  props: {
    currentUserId: { type: [String, Number], required: true },
    textMessages: { type: Object, required: true },
    showRoomsList: { type: Boolean, required: true },
    showAddRoom: { type: Boolean, required: true },
    textFormatting: { type: Boolean, required: true },
    linkOptions: { type: Object, required: true },
    isMobile: { type: Boolean, required: true },
    rooms: { type: Array, required: true },
    finishedRooms: { type: Array, required: false, default: () => [] },
    loadingRooms: { type: Boolean, required: true },
    roomsLoaded: { type: Boolean, required: true },
    room: { type: Object, required: true },
    roomActions: { type: Array, required: true },
  },

  data() {
    return {
      activeTab: "notFinish",
      currentTabRooms: [], // 记录当前tab下的房间列表
      filteredRooms: this.rooms || [],
      infiniteState: null,
      loadingMoreRooms: false,
      selectedRoomId: "",
      // currentChatTab: this.$store.state.chat.currentChatTab,
      isFirstTimeOpen: true,
      searchValue: "",
    };
  },

  computed: {
    ...mapState("chat", {
      currentRoom: (state) => state.currentRoom,
      messages: (state) => state.messages,
      notFinishList: (state) => state.notFinishList,
      currentChatTab: (state) => state.currentChatTab,
    }),
  },
  watch: {
    rooms(newVal, oldVal) {
      this.filteredRooms = newVal;

      if (this.infiniteState && (newVal.length !== oldVal.length || this.roomsLoaded)) {
        this.infiniteState.loaded();
        this.loadingMoreRooms = false;
      }
      if (this.isFirstTimeOpen) {
        this.isFirstTimeOpen = false;
        const roomId = Number(this.$route.query.roomId) || this.currentRoom.roomId;
        if (Number.isInteger(roomId)) {
          let room = this.filteredRooms.find((item) => item.roomId === roomId);
          if (!room) {
            room = this.getExtraRoomByMobile(roomId);
          }
          room && this.openRoom(room);
        }
      }
    },
    loadingRooms(val) {
      if (val) this.infiniteState = null;
    },
    loadingMoreRooms(val) {
      this.$emit("loading-more-rooms", val);
    },
    roomsLoaded(val) {
      if (val && this.infiniteState) {
        this.loadingMoreRooms = false;
        this.infiniteState.complete();
      }
    },
    // 退出接待时重新获取「会话中」列表
    async currentRoom(newVal) {
      if (!newVal.roomId) {
        this.filteredRooms = await this.$store.dispatch("chat/setNotFinishList");
      }
    },
    async notFinishList(newVal) {
      this.filteredRooms = newVal;
    },
  },

  methods: {
    async handleTabClick(tab) {
      let { name } = tab;
      to(this.$store.dispatch("chat/setCurrentChatTab", name));
      if (name === "notFinish") {
        this.filteredRooms = await this.$store.dispatch("chat/setNotFinishList");
      } else if (name === "finished") {
        const [err, res] = await api.customerService.customerService({
          resource: "finished",
          params: {
            page: 1,
            size: 200,
          },
        });
        if (err) return;
        this.filteredRooms = formatRoomList(res.list);
      } else if (name === "recent") {
        const [err, res] = await api.customerService.customerService({
          resource: "recent",
          params: {
            page: 1,
            size: 200,
          },
        });
        if (err) return;
        this.filteredRooms = formatRoomList(res.list);
      }
      this.currentTabRooms = this.filteredRooms;
      this.filteredRooms = filteredUsers(this.currentTabRooms, "roomName", this.searchValue);
    },
    async getExtraRoomByMobile(roomId) {
      const { mobile_number, finished } = this.$route.query;
      const [err, res] = await api.customerService.customerService({
        resource: finished ? "finished" : "notFinish",
        params: {
          page: 1,
          size: 10,
          blurry: mobile_number,
        },
      });
      if (err) return;
      this.filteredRooms.concat(formatRoomList(res.list));
      return res.list.find((item) => item.roomId === roomId);
    },
    searchRoom(ev) {
      this.searchValue = ev.target.value?.trim();
      this.filteredRooms = filteredUsers(this.currentTabRooms, "roomName", ev.target.value?.trim());
    },
    /**
     * 单击左侧联系人触发事件
     */
    openRoom(room) {
      // 设置消息为已读
      this.setMessageRead(room);

      // 获取历史消息
      this.getHistoryMessage(room);

      if (!this.isMobile) this.selectedRoomId = room.roomId;
      this.$emit("fetch-room", { room });
    },
    // 直接修改room的信息，有副作用
    setRoomInfo(room, info) {
      for (const key of Object.keys(info)) {
        room[key] = info[key];
      }
    },
    setMessageRead(room) {
      if (!room.unreadCount) return;
      this.setRoomInfo(room, { unreadCount: "" });
      to(
        api.customerService.customerService({
          method: "post",
          resource: "read",
          data: {
            read: true,
            session_id: room.sessionId,
          },
        })
      );
    },
    async getHistoryMessage(room) {
      const { sessionId, openId: open_id } = room;
      const [err, res] = await api.customerService.history({
        id: sessionId,
        params: {
          open_id,
          page: 1,
          size: 200,
        },
      });

      if (err) return;
      const { customer_service_user, list } = res;
      const { province = "", city = "", mobile_number = "", member_card_num, level_name } = customer_service_user;
      const additionalInfo = {
        phone: mobile_number,
        district: `${province ?? ""}${city ?? ""}`,
        memberCardNum: member_card_num,
        levelName: level_name,
      };
      this.setRoomInfo(room, additionalInfo);

      // 把当前单击的用户信息先保存到 vuex
      await this.$store.dispatch("chat/setCurrentRoom", room);

      // 把当前单击的用户聊天记录展示到消息面板
      list.list.reverse().forEach((item) => {
        this.$store.dispatch("chat/pushMessage", { ...item, openId: customer_service_user.open_id });
      });
    },

    loadMoreRooms(infiniteState) {
      if (this.loadingMoreRooms) return;

      if (this.roomsLoaded) {
        this.loadingMoreRooms = false;
        return infiniteState.complete();
      }

      this.infiniteState = infiniteState;
      this.$emit("fetch-more-rooms");
      this.loadingMoreRooms = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.vac-rooms-container {
  display: flex;
  flex-flow: column;
  flex: 0 0 25%;
  min-width: 260px;
  max-width: 500px;
  position: relative;
  background: var(--chat-sidemenu-bg-color);
  height: 100%;
  border-top-left-radius: var(--chat-container-border-radius);
  border-bottom-left-radius: var(--chat-container-border-radius);
}

.vac-rooms-container-full {
  flex: 0 0 100%;
  max-width: 100%;
}

.vac-rooms-empty {
  font-size: 14px;
  color: #9ca6af;
  font-style: italic;
  text-align: center;
  margin: 40px 0;
  line-height: 20px;
  white-space: pre-line;
}

.vac-room-list {
  flex: 1;
  position: relative;
  max-width: 100%;
  cursor: pointer;
  padding: 0 10px 5px;
  overflow-y: auto;
}

.vac-room-item {
  border-radius: 8px;
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  margin-bottom: 5px;
  padding: 0 14px;
  position: relative;
  min-height: 71px;

  &:hover {
    background: var(--chat-sidemenu-bg-color-hover);
    transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }

  &:not(:hover) {
    transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
}

.vac-room-selected {
  color: var(--chat-sidemenu-color-active) !important;
  background: var(--chat-sidemenu-bg-color-active) !important;

  &:hover {
    background: var(--chat-sidemenu-bg-color-active) !important;
  }
}

@media only screen and (max-width: 768px) {
  .vac-room-list {
    padding: 0 7px 5px;
  }

  .vac-room-item {
    min-height: 60px;
    padding: 0 8px;
  }
}
.room-list-tabs {
  height: 100%;
  width: 100%;
  padding: 0;
  border: none;
  // border-top: 1px solid #e1e4e8;
  // border-left: none;
  // border-right: none;
  // border-bottom: none;
}
</style>
