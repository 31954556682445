var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "page-padding",
    },
    [
      _vm._m(0),
      _c(
        "el-row",
        {
          staticClass: "page-filter--wrap",
        },
        [
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [_vm._v("日期")]
          ),
          _c("el-date-picker", {
            attrs: {
              "value-format": "yyyy-MM-dd HH:mm:ss",
              type: "datetimerange",
              "range-separator": "至",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
              clearable: "",
              "unlink-panels": "",
            },
            on: {
              change: _vm.handleDateChange,
            },
            model: {
              value: _vm.order_time,
              callback: function callback($$v) {
                _vm.order_time = $$v
              },
              expression: "order_time",
            },
          }),
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [_vm._v("商品名称")]
          ),
          _c("el-input", {
            staticClass: "page-select--item",
            staticStyle: {
              width: "150px",
            },
            attrs: {
              clearable: "",
              placeholder: "请输入商品名称",
            },
            model: {
              value: _vm.searchForm.spuName,
              callback: function callback($$v) {
                _vm.$set(_vm.searchForm, "spuName", $$v)
              },
              expression: "searchForm.spuName",
            },
          }),
          _c(
            "el-button",
            {
              staticStyle: {
                "margin-left": "20px",
              },
              attrs: {
                round: "",
                icon: "el-icon-refresh",
              },
              on: {
                click: _vm.resetSearchForm,
              },
            },
            [_vm._v(" 重置 ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                round: "",
                icon: "el-icon-search",
              },
              on: {
                click: _vm.getList,
              },
            },
            [_vm._v(" 搜索 ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: {
            width: "100%",
          },
          attrs: {
            data: _vm.feedbackData,
            stripe: "",
            border: "",
            "highlight-current-row": "",
          },
        },
        [
          _c(
            "el-table-column",
            {
              attrs: {
                type: "index",
                align: "center",
                width: "60",
              },
            },
            [
              _c(
                "template",
                {
                  slot: "header",
                },
                [_vm._v(" 序号 ")]
              ),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "remark_time",
              label: "反馈时间",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "spu_name",
              label: "商品名称",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "remark_name",
              label: "用户名",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "remark_phone",
              label: "手机号",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "remark_str",
              label: "留言内容",
            },
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function updatePage($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function updateLimit($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "page-header--wrap",
      },
      [
        _c(
          "div",
          {
            staticClass: "page-header-wrap--title",
          },
          [_vm._v("留言反馈")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }