var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      on: {
        mouseenter: function mouseenter($event) {
          return _vm.handleMouseEnter($event)
        },
      },
    },
    [
      _c(
        "el-upload",
        {
          attrs: {
            action: _vm.upload_qiniu_area,
            "list-type": "picture-card",
            "show-file-list": false,
            "before-upload": _vm.beforeUploadVideo,
            "http-request": _vm.uploadQiniu,
          },
        },
        [
          _vm.video_url != "" && _vm.videoFlag == false
            ? _c(
                "video",
                {
                  attrs: {
                    src: _vm.video_url,
                    controls: "controls",
                    width: "300",
                    height: "200",
                  },
                },
                [_vm._v(" 您的浏览器不支持视频播放 ")]
              )
            : _vm.video_url == "" && _vm.videoFlag == false
            ? _c(
                "div",
                {
                  staticClass: "el-icon-plus",
                  staticStyle: {
                    color: "#409eff",
                  },
                },
                [_vm._v("添加视频")]
              )
            : _vm._e(),
          _vm.videoFlag == true
            ? _c("el-progress", {
                staticStyle: {
                  "margin-top": "10px",
                },
                attrs: {
                  type: "circle",
                  percentage: _vm.videoUploadPercent,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }