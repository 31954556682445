var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value:
            (_vm.isMobile && !_vm.showRoomsList) ||
            !_vm.isMobile ||
            _vm.singleRoom,
          expression: "(isMobile && !showRoomsList) || !isMobile || singleRoom",
        },
      ],
      staticClass: "vac-col-messages",
    },
    [
      _vm.showNoRoom
        ? _vm._t("no-room-selected", function () {
            return [
              _c(
                "div",
                {
                  staticClass: "vac-container-center vac-room-empty",
                },
                [_c("div", [_vm._v(_vm._s(_vm.textMessages.ROOM_EMPTY))])]
              ),
            ]
          })
        : _c("room-header", {
            attrs: {
              "current-user-id": _vm.currentUserId,
              "text-messages": _vm.textMessages,
              "single-room": _vm.singleRoom,
              "show-rooms-list": _vm.showRoomsList,
              "is-mobile": _vm.isMobile,
              "room-info": _vm.roomInfo,
              "menu-actions": _vm.menuActions,
              room: _vm.room,
            },
            on: {
              "toggle-rooms-list": function toggleRoomsList($event) {
                return _vm.$emit("toggle-rooms-list")
              },
              "room-info": function roomInfo($event) {
                return _vm.$emit("room-info")
              },
              "menu-action-handler": function menuActionHandler($event) {
                return _vm.$emit("menu-action-handler", $event)
              },
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.$scopedSlots, function (i, name) {
                  return {
                    key: name,
                    fn: function fn(data) {
                      return [_vm._t(name, null, null, data)]
                    },
                  }
                }),
              ],
              null,
              true
            ),
          }),
      _c(
        "div",
        {
          ref: "scrollContainer",
          staticClass: "vac-container-scroll",
          on: {
            scroll: _vm.onContainerScroll,
          },
        },
        [
          _c("loader", {
            attrs: {
              show: _vm.loadingMessages,
            },
          }),
          _c(
            "div",
            {
              staticClass: "vac-messages-container",
            },
            [
              _c(
                "div",
                {
                  class: {
                    "vac-messages-hidden": _vm.loadingMessages,
                  },
                },
                [
                  _c(
                    "transition",
                    {
                      attrs: {
                        name: "vac-fade-message",
                      },
                    },
                    [
                      _vm.showNoMessages
                        ? _c(
                            "div",
                            {
                              staticClass: "vac-text-started",
                            },
                            [
                              _vm._t("messages-empty", function () {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.textMessages.MESSAGES_EMPTY) +
                                      " "
                                  ),
                                ]
                              }),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.showMessagesStarted
                        ? _c(
                            "div",
                            {
                              staticClass: "vac-text-started",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.textMessages.CONVERSATION_STARTED) +
                                  " " +
                                  _vm._s(_vm.messages[0].date)
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "transition",
                    {
                      attrs: {
                        name: "vac-fade-message",
                      },
                    },
                    [
                      _vm.messages.length
                        ? _c(
                            "infinite-loading",
                            {
                              class: {
                                "vac-infinite-loading": !_vm.messagesLoaded,
                              },
                              attrs: {
                                spinner: "spiral",
                                direction: "top",
                                distance: 40,
                              },
                              on: {
                                infinite: _vm.loadMoreMessages,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  attrs: {
                                    slot: "spinner",
                                  },
                                  slot: "spinner",
                                },
                                [
                                  _c("loader", {
                                    attrs: {
                                      show: true,
                                      infinite: true,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", {
                                attrs: {
                                  slot: "no-results",
                                },
                                slot: "no-results",
                              }),
                              _c("div", {
                                attrs: {
                                  slot: "no-more",
                                },
                                slot: "no-more",
                              }),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "transition-group",
                    {
                      key: _vm.roomId,
                      attrs: {
                        name: "vac-fade-message",
                      },
                    },
                    _vm._l(_vm.messages, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                        },
                        [
                          _c("message", {
                            attrs: {
                              "current-user-id": _vm.currentUserId,
                              message: item,
                              index: index,
                              messages: _vm.messages,
                              "edited-message": _vm.editedMessage,
                              "message-actions": _vm.messageActions,
                              "room-users": _vm.room.users,
                              "text-messages": _vm.textMessages,
                              "room-footer-ref": _vm.$refs.roomFooter,
                              "new-messages": _vm.newMessages,
                              "show-reaction-emojis": _vm.showReactionEmojis,
                              "show-new-messages-divider":
                                _vm.showNewMessagesDivider,
                              "text-formatting": _vm.textFormatting,
                              "link-options": _vm.linkOptions,
                              "emojis-list": _vm.emojisList,
                              "hide-options": _vm.hideOptions,
                            },
                            on: {
                              "message-added": _vm.onMessageAdded,
                              "message-action-handler":
                                _vm.messageActionHandler,
                              "open-file": _vm.openFile,
                              "open-user-tag": _vm.openUserTag,
                              "send-message-reaction": _vm.sendMessageReaction,
                              "hide-options": function hideOptions($event) {
                                _vm.hideOptions = $event
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                _vm._l(_vm.$scopedSlots, function (idx, name) {
                                  return {
                                    key: name,
                                    fn: function fn(data) {
                                      return [_vm._t(name, null, null, data)]
                                    },
                                  }
                                }),
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      !_vm.loadingMessages
        ? _c(
            "div",
            [
              _c(
                "transition",
                {
                  attrs: {
                    name: "vac-bounce",
                  },
                },
                [
                  _vm.scrollIcon
                    ? _c(
                        "div",
                        {
                          staticClass: "vac-icon-scroll",
                          on: {
                            click: _vm.scrollToBottom,
                          },
                        },
                        [
                          _c(
                            "transition",
                            {
                              attrs: {
                                name: "vac-bounce",
                              },
                            },
                            [
                              _vm.scrollMessagesCount
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vac-badge-counter vac-messages-count",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.scrollMessagesCount) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._t("scroll-icon", function () {
                            return [
                              _c("svg-icon", {
                                attrs: {
                                  name: "dropdown",
                                  param: "scroll",
                                },
                              }),
                            ]
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          ref: "roomFooter",
          staticClass: "vac-room-footer",
        },
        [
          _c("room-message-reply", {
            attrs: {
              room: _vm.room,
              "message-reply": _vm.messageReply,
              "text-formatting": _vm.textFormatting,
              "link-options": _vm.linkOptions,
            },
            on: {
              "reset-message": _vm.resetMessage,
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.$scopedSlots, function (i, name) {
                  return {
                    key: name,
                    fn: function fn(data) {
                      return [_vm._t(name, null, null, data)]
                    },
                  }
                }),
              ],
              null,
              true
            ),
          }),
          _c("room-emojis", {
            attrs: {
              "filtered-emojis": _vm.filteredEmojis,
            },
            on: {
              "select-emoji": function selectEmoji($event) {
                return _vm.selectEmoji($event)
              },
            },
          }),
          _c("room-users-tag", {
            attrs: {
              "filtered-users-tag": _vm.filteredUsersTag,
            },
            on: {
              "select-user-tag": function selectUserTag($event) {
                return _vm.selectUserTag($event)
              },
            },
          }),
          _c(
            "div",
            {
              staticClass: "vac-box-footer",
              class: {
                "vac-app-box-shadow":
                  _vm.filteredEmojis.length || _vm.filteredUsersTag.length,
              },
            },
            [
              _vm.imageFile
                ? _c(
                    "div",
                    {
                      staticClass: "vac-media-container",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "vac-svg-button vac-icon-media",
                          on: {
                            click: _vm.resetMediaFile,
                          },
                        },
                        [
                          _vm._t("image-close-icon", function () {
                            return [
                              _c("svg-icon", {
                                attrs: {
                                  name: "close",
                                  param: "image",
                                },
                              }),
                            ]
                          }),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "vac-media-file",
                        },
                        [
                          _c("img", {
                            ref: "mediaFile",
                            attrs: {
                              src: _vm.imageFile,
                            },
                            on: {
                              load: _vm.onMediaLoad,
                            },
                          }),
                        ]
                      ),
                    ]
                  )
                : _vm.videoFile
                ? _c(
                    "div",
                    {
                      staticClass: "vac-media-container",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "vac-svg-button vac-icon-media",
                          on: {
                            click: _vm.resetMediaFile,
                          },
                        },
                        [
                          _vm._t("image-close-icon", function () {
                            return [
                              _c("svg-icon", {
                                attrs: {
                                  name: "close",
                                  param: "image",
                                },
                              }),
                            ]
                          }),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        {
                          ref: "mediaFile",
                          staticClass: "vac-media-file",
                        },
                        [
                          _c(
                            "video",
                            {
                              attrs: {
                                width: "100%",
                                height: "100%",
                                controls: "",
                              },
                            },
                            [
                              _c("source", {
                                attrs: {
                                  src: _vm.videoFile,
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm.file
                ? _c(
                    "div",
                    {
                      staticClass: "vac-file-container",
                      class: {
                        "vac-file-container-edit": _vm.editedMessage._id,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "vac-icon-file",
                        },
                        [
                          _vm._t("file-icon", function () {
                            return [
                              _c("svg-icon", {
                                attrs: {
                                  name: "file",
                                },
                              }),
                            ]
                          }),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "vac-file-message",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.file.audio ? _vm.file.name : _vm.message
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "vac-svg-button vac-icon-remove",
                          on: {
                            click: function click($event) {
                              return _vm.resetMessage(null, true)
                            },
                          },
                        },
                        [
                          _vm._t("file-close-icon", function () {
                            return [
                              _c("svg-icon", {
                                attrs: {
                                  name: "close",
                                },
                              }),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
              _c("textarea", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.file || _vm.imageFile || _vm.videoFile,
                    expression: "!file || imageFile || videoFile",
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.message,
                    expression: "message",
                  },
                ],
                ref: "roomTextarea",
                staticClass: "vac-textarea",
                class: {
                  "vac-textarea-outline": _vm.editedMessage._id,
                },
                style: {
                  "min-height": "".concat(
                    _vm.mediaDimensions ? _vm.mediaDimensions.height : 20,
                    "px"
                  ),
                  "padding-left": "".concat(
                    _vm.mediaDimensions ? _vm.mediaDimensions.width - 10 : 12,
                    "px"
                  ),
                },
                attrs: {
                  placeholder: _vm.textMessages.TYPE_MESSAGE,
                },
                domProps: {
                  value: _vm.message,
                },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.message = $event.target.value
                    },
                    _vm.onChangeInput,
                  ],
                  keydown: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "esc", 27, $event.key, [
                          "Esc",
                          "Escape",
                        ])
                      )
                        return null
                      return _vm.escapeTextarea.apply(null, arguments)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      if (
                        $event.ctrlKey ||
                        $event.shiftKey ||
                        $event.altKey ||
                        $event.metaKey
                      )
                        return null
                      $event.preventDefault()
                    },
                  ],
                },
              }),
              _c(
                "div",
                {
                  staticClass: "vac-icon-textarea",
                },
                [
                  _vm.editedMessage._id
                    ? _c(
                        "div",
                        {
                          staticClass: "vac-svg-button",
                          on: {
                            click: _vm.resetMessage,
                          },
                        },
                        [
                          _vm._t("edit-close-icon", function () {
                            return [
                              _c("svg-icon", {
                                attrs: {
                                  name: "close-outline",
                                },
                              }),
                            ]
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.showEmojis &&
                  (!_vm.file || _vm.imageFile || _vm.videoFile)
                    ? _c("emoji-picker", {
                        attrs: {
                          "emoji-opened": _vm.emojiOpened,
                          "position-top": true,
                        },
                        on: {
                          "add-emoji": _vm.addEmoji,
                          "open-emoji": function openEmoji($event) {
                            _vm.emojiOpened = $event
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            _vm._l(_vm.$scopedSlots, function (i, name) {
                              return {
                                key: name,
                                fn: function fn(data) {
                                  return [_vm._t(name, null, null, data)]
                                },
                              }
                            }),
                          ],
                          null,
                          true
                        ),
                      })
                    : _vm._e(),
                  _vm.showFiles
                    ? _c(
                        "div",
                        {
                          staticClass: "vac-svg-button",
                          on: {
                            click: _vm.launchFilePicker,
                          },
                        },
                        [
                          _vm._t("paperclip-icon", function () {
                            return [
                              _c("svg-icon", {
                                attrs: {
                                  name: "paperclip",
                                },
                              }),
                            ]
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.textareaAction
                    ? _c(
                        "div",
                        {
                          staticClass: "vac-svg-button",
                          on: {
                            click: _vm.textareaActionHandler,
                          },
                        },
                        [
                          _vm._t("custom-action-icon", function () {
                            return [
                              _c("svg-icon", {
                                attrs: {
                                  name: "deleted",
                                },
                              }),
                            ]
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.showFiles
                    ? _c("input", {
                        ref: "file",
                        staticStyle: {
                          display: "none",
                        },
                        attrs: {
                          type: "file",
                          accept: "image/*",
                        },
                        on: {
                          change: function change($event) {
                            return _vm.onFileChange($event.target.files)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.showSendIcon
                    ? _c(
                        "div",
                        {
                          staticClass: "vac-svg-button",
                          class: {
                            "vac-send-disabled": _vm.isMessageEmpty,
                          },
                          on: {
                            click: _vm.sendMessage,
                          },
                        },
                        [
                          _vm._t("send-icon", function () {
                            return [
                              _c("svg-icon", {
                                attrs: {
                                  name: "send",
                                  param: _vm.isMessageEmpty ? "disabled" : "",
                                },
                              }),
                            ]
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }