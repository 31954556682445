import * as R from "ramda";

// https://stackoverflow.com/questions/38920291/how-to-map-an-array-of-objects-to-a-single-object-using-ramda
export const listToKeyObj = (key) => R.indexBy(R.prop(key));
export const keyObjToValueList = (value) => R.map(R.prop(value));
/**
 * 根据list返回字典对象
 *
 * @param { array } 例子 [{ label: '男', value: 1 }, { label: '女', value: 2 }]
 * @return { object } 返回值 { '男': 1, '女': 2 }
 */
export const listToLabelDict = R.pipe(listToKeyObj("label"), keyObjToValueList("value"));
export const listToValueDict = R.pipe(listToKeyObj("value"), keyObjToValueList("label"));
export const listValueToMap = R.pipe(listToKeyObj("value"), keyObjToValueList("value"));

function arrListValueToLabelDict(arrListMap) {
  const dic = {};
  for (const key of Object.keys(arrListMap)) {
    dic[`${key}_LABEL_DIC`] = listToLabelDict(arrListMap[key]);
  }
  return dic;
}

function arrListValueToValueDict(arrListMap) {
  const dic = {};
  for (const key of Object.keys(arrListMap)) {
    dic[`${key}_VALUE_DIC`] = listToValueDict(arrListMap[key]);
  }
  return dic;
}

function arrListValueToMapList(arrListMap) {
  const dic = {};
  for (const key of Object.keys(arrListMap)) {
    dic[`${key}_VALUE_MAP`] = listValueToMap(arrListMap[key]);
  }
  return dic;
}

export function arrListToMapLists(arrListMap) {
  const dic1 = arrListValueToLabelDict(arrListMap);
  const dic2 = arrListValueToValueDict(arrListMap);
  const map = arrListValueToMapList(arrListMap);
  return { ...dic1, ...dic2, ...map, ...arrListMap };
}

// https://stackoverflow.com/questions/56220286/rename-keys-with-ramda-js/56220677
export const renameKeys = (keysMap) => (obj) =>
  Object.entries(obj).reduce((res, [k, v]) => (k in keysMap ? { ...res, [keysMap[k]]: v } : { ...res, [k]: v }), {});

export const omitEmptyValue = R.reject(R.anyPass([R.equals(""), R.isNil]));
