import store from "@/store";

function checkPermission(el, binding) {
  const { value } = binding;
  // const roles = store.getters && store.getters.auth_info.user.roles;
  const roles = store.state && store.state.user.auth_info.roles;

  if (value && value instanceof Array) {
    if (value.length > 0) {
      const permissionRoles = value;

      const hasPermission = roles.some((role) => {
        return permissionRoles.includes(role);
      });

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    }
  } else {
    throw new Error(`need roles! Like v-permission="['admin','outletAdmin']"`);
  }
}

export function mallPermission(el, binding, vnode) {
  const store = vnode.context.$store;
  const {
    current_mall: { code: mallCode },
  } = store.getters?.auth_info ?? { current_mall: {} };
  const { value: mallCodeArr } = binding;
  if (!mallCodeArr.includes(mallCode)) {
    if (el.parentNode) el.parentNode.removeChild(el);
    else {
      setTimeout(() => {
        el.parentNode && el.parentNode.removeChild(el);
      });
    }
  }
}

export default {
  inserted(el, binding) {
    checkPermission(el, binding);
  },
  update(el, binding) {
    checkPermission(el, binding);
  },
};
