var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "page-padding goods-group",
    },
    [
      _c(
        "div",
        {
          staticClass: "page-header--wrap",
        },
        [
          _c(
            "div",
            {
              staticClass: "page-header-wrap--title",
            },
            [_vm._v("商品分组")]
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-plus",
                    type: "primary",
                  },
                  on: {
                    click: _vm.handleAddScheme,
                  },
                },
                [_vm._v(" 新增商品分组 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("el-row", {
        staticClass: "page-filter--wrap",
      }),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "dragTable",
          attrs: {
            "row-key": "id",
            data: _vm.schemeData,
            "row-class-name": "data-row-class",
            stripe: "",
            border: "",
          },
          on: {
            "row-click": _vm.handleRowClick,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              type: "index",
              label: "序号",
              width: "60",
            },
          }),
          _c(
            "el-table-column",
            {
              attrs: {
                align: "center",
                label: "排序",
                width: "80",
              },
            },
            [
              _c("svg-icon", {
                staticClass: "drag-handler",
                attrs: {
                  "icon-class": "drag",
                },
              }),
            ],
            1
          ),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "prop",
              label: "操作",
              width: "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref) {
                  var row = _ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                        },
                        on: {
                          click: function click($event) {
                            return _vm.handleUpdateScheme(row)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                    _c(
                      "el-popconfirm",
                      {
                        staticStyle: {
                          "margin-left": "10px",
                        },
                        attrs: {
                          title: "确定删除该数据吗？",
                          placement: "left",
                        },
                        on: {
                          onConfirm: function onConfirm($event) {
                            return _vm.handleDelete(row)
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              type: "text",
                            },
                            slot: "reference",
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "name",
              label: "分组名称",
            },
          }),
          _c("el-table-column", {
            attrs: {
              "class-name": "img-column",
              align: "center",
              label: "分组配图",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref2) {
                  var row = _ref2.row
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "img-cover",
                      },
                      [
                        _c("upload-img", {
                          attrs: {
                            "limit-count": 1,
                            "already-file": row.image_address,
                            token: _vm.token,
                          },
                          on: {
                            uploadSuccess: function uploadSuccess(val) {
                              return _vm.uploadPicSuccess(val, row)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "number_of_related_goods",
              label: "商品数量",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "show-overflow-tooltip": "",
              label: "创建人/修改人",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref3) {
                  var row = _ref3.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(row.creator) +
                        " / " +
                        _vm._s(row.updater) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "show-overflow-tooltip": "",
              label: "创建时间/修改时间",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref4) {
                  var row = _ref4.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(row.create_time) +
                        " / " +
                        _vm._s(row.update_time) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._m(0),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "".concat(
              _vm.textMap[_vm.dialogStatus],
              "\u5546\u54C1\u5206\u7EC4"
            ),
            visible: _vm.dialogGroupForm,
            width: "400px",
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.dialogGroupForm = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "goodsTagForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.goodsTagForm,
                "label-width": "70px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "名称",
                    prop: "name",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.goodsTagForm.name,
                      callback: function callback($$v) {
                        _vm.$set(_vm.goodsTagForm, "name", $$v)
                      },
                      expression: "goodsTagForm.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "是否可见",
                    prop: "related_goods_visible",
                  },
                },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.goodsTagForm.related_goods_visible,
                      callback: function callback($$v) {
                        _vm.$set(_vm.goodsTagForm, "related_goods_visible", $$v)
                      },
                      expression: "goodsTagForm.related_goods_visible",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "text",
                  },
                  on: {
                    click: function click($event) {
                      _vm.dialogGroupForm = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                  },
                  on: {
                    click: _vm.handleDialogConfirm,
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.dialogStatus === "update" ? "修改" : "确定") +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "table-tooltip",
      },
      [
        _c("i", {
          staticClass: "el-icon-warning-outline",
        }),
        _c(
          "div",
          {
            staticStyle: {
              "margin-left": "10px",
            },
          },
          [
            _c("div", [
              _vm._v("门店同款建议尺寸：宽160*高195px，jpg、png格式，500K以内"),
            ]),
            _c("div", [
              _vm._v("太二潮品建议尺寸：宽180*高90px，jpg、png格式，500K以内"),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }