<template>
  <div class="page-padding">
    <div>
      <div class="page-header--wrap">
        <div class="page-header-wrap--title">发货管理</div>
        <div>
          <el-button :disabled="multipleSelection.length == 0" type="primary" @click="handleBatchExpress"> 批量发货 </el-button>
        </div>
      </div>

      <el-row class="page-filter--wrap">
        <div class="page-color--normal">订单搜索</div>
        <el-input v-model="orderTypeInput" placeholder="请输入内容" style="width: 350px" clearable @input="handleTypeInputChange">
          <el-select slot="prepend" v-model="orderType" placeholder="请选择" style="width: 120px" @change="handleTypeChange">
            <el-option label="发货单编号" value="item_no" />
            <el-option label="定时发货单号" value="regular_order_no" />
            <!-- <el-option label="买家手机号" value="user_phone"> </el-option> -->
          </el-select>
        </el-input>
        <div class="page-color--normal">预计发货时间</div>
        <el-date-picker
          v-model="order_time"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
          unlink-panels
          @change="handleDateChange"
        />
        <div class="page-color--normal">物流单号</div>
        <el-input v-model="searchForm.delivery_no" clearable placeholder="请输入物流单号" class="page-select--item" style="width: 150px" />
      </el-row>
      <el-row class="page-filter--wrap">
        <div class="page-color--normal">发货时间</div>
        <el-date-picker
          v-model="delivery_time"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
          unlink-panels
          @change="handleDeliveryDateChange"
        />
        <div class="page-color--normal">仅看当前节气</div>
        <el-select v-model="searchForm.sole_term" placeholder="" class="page-select--item" style="width: 150px">
          <el-option label="是" :value="true" />
          <el-option label="否" :value="false" />
        </el-select>
        <div class="page-color--normal">收货人手机</div>
        <el-input v-model="searchForm.receiver_phone" clearable placeholder="请输入收货人手机" class="page-select--item" style="width: 150px" />
      </el-row>
      <el-row class="page-filter--wrap" style="margin: 20px 0">
        <el-button round icon="el-icon-refresh" @click="resetSearchForm"> 重置 </el-button>
        <el-button type="primary" round icon="el-icon-search" @click="getList"> 搜索 </el-button>
        <el-popconfirm title="是否按当前搜索条件导出文件？" @onConfirm="exportFile('order')">
          <el-button slot="reference" type="success" round icon="el-icon-download" :loading="downloadingOrder" style="margin-left: 10px">
            导出订单文件
          </el-button>
        </el-popconfirm>
      </el-row>

      <el-row>
        <div style="margin-bottom: 15px">
          <el-radio-group v-model="order_filter" size="small" @change="handleOrderRadioChange">
            <el-radio-button label="全部" />
            <el-radio-button label="NODELIVERY"> 待发货 </el-radio-button>
            <el-radio-button label="DELIVERY"> 已发货 </el-radio-button>
            <el-radio-button label="SKIP"> 已跳过 </el-radio-button>
          </el-radio-group>
        </div>
      </el-row>

      <el-table v-loading="listLoading" :data="storeData" stripe border style="width: 100%" row-key="id" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column label="操作" align="center" width="250">
          <template slot-scope="{ row }">
            <el-button :disabled="row.order_states != 'NODELIVERY'" type="text" @click="handleDispatchOrder(row)"> 发货 </el-button>
            <el-button type="text" @click="handleAddNote(row)"> 备注 </el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="spu_name" label="商品信息" />
        <el-table-column align="center" prop="delivery_no" label="物流单号" />
        <el-table-column align="center" prop="order_no" label="订单编号" />
        <el-table-column align="center" prop="regular_order_no" label="定时发货单号" />
        <el-table-column align="center" prop="item_no" label="发货单编号" />
        <el-table-column align="center" prop="pre_sent_time" label="预计发货时间" />
        <el-table-column align="center" prop="solar_term" label="所属节气" />
        <el-table-column align="center" label="收货人">
          <template slot-scope="{ row }">
            <div>
              <div>{{ row.receiver_name }}</div>
              <div>{{ row.receiver_phone }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="收货地址" show-overflow-tooltip>
          <template slot-scope="{ row }"> {{ row.province }}{{ row.city }}{{ row.county }}{{ row.receiver_address }} </template>
        </el-table-column>
        <el-table-column align="center" label="发货状态">
          <template slot-scope="{ row }">
            {{ row.order_states | regularOrderDetailText }}
          </template>
        </el-table-column>
        <el-table-column prop="remark" align="center" label="备注" show-overflow-tooltip />
      </el-table>
      <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    </div>

    <!-- 添加备注的弹窗 -->
    <el-dialog title="卖家备注" :visible.sync="dialogAddNote" width="40%">
      <el-input v-model="note_form.remark" type="textarea" placeholder="最多可输入256个字" maxlength="256" show-word-limit />
      <div slot="footer">
        <el-button type="text" @click="dialogAddNote = false"> 取消 </el-button>
        <el-button type="primary" @click="handleAddNoteConfirm"> 确定 </el-button>
      </div>
    </el-dialog>

    <!-- 发货的弹窗 -->
    <el-dialog title="订单发货" :visible.sync="dialogDispatchOrder" top="5vh">
      <dispatch-order :goods-data="currentGoodsData" @handleDelivery="handleDelivery" />
    </el-dialog>
  </div>
</template>

<script>
import DispatchOrder from "./components/DispatchOrder";
import Pagination from "@/components/Pagination";
import TableListMixin from "@/mixin/TableList";
import { downloadBlobFile, filterEmptyProp, commonUtil } from "@/utils";
import api from "@/api";
import clip from "@/utils/clipboard";
import { regularOrderDetailText } from "@/filters/regular_order";
export default {
  name: "OrderManage",
  components: { DispatchOrder, Pagination },
  filters: {
    statusToText(val) {
      let map = {
        0: "待付款",
        1: "待发货",
        2: "已发货",
        3: "已完成",
        4: "已关闭",
        5: "售后中",
      };
      return map[val];
    },
    regularOrderDetailText,
  },
  mixins: [TableListMixin],
  data() {
    return {
      downloadingOrder: false,
      downloadingGoods: false,
      multipleSelection: [],
      popSty: false,
      textarea: "",
      dialogDispatchOrder: false,
      dialogAddNote: false,
      note_form: {
        remark: "",
        item_no: "",
      },
      order_time: [],
      delivery_time: [],
      payment_time: [],
      tran_time: [],
      order_filter: "全部",
      orderType: "item_no",
      orderTypeInput: "",
      storeCode: "",
      storeData: [],
      currentGoodsData: [],
      searchForm: {
        order_status: "",
        regular_order_status: "",
        goods_name: "",
        sole_term: false,
      },
    };
  },
  created() {
    this.getList();
  },

  methods: {
    handleCopy(v, e) {
      clip(v, e);
    },
    handlePickedUp(row) {
      let { item_no, pick_up_code } = row;
      this.axios.post(`/emall_order/pickedUp`, { item_no, pick_up_code }).then(() => {
        this.$message.success("操作成功");
        this.getList();
      });
    },
    handleRevoke(row) {
      this.axios.get(`emall_order/express/rollback/${row.item_no}`).then((res) => {
        this.$message.success(res.msg);
        this.getList();
      });
    },
    // 根据查询条件导出Excel文件
    async exportFile(type) {
      const option = {
        params: {
          ...filterEmptyProp(this.searchForm),
          // page: this.listQuery.page,
          // size: this.listQuery.limit,
        },
        filename: "",
        downloadFunc: null,
      };
      if (type == "order") {
        option.downloadFunc = api.download.regularOrderDetail;
        option.filename = "订单文件";
        this.downloadingOrder = true;
      }
      // if (type == "product") {
      //   option.downloadFunc = downloadTrmallOrderProduct;
      //   option.filename = "商品文件";
      //   this.downloadingGoods = true;
      // }
      const [err, blobData] = await option.downloadFunc({ params: option.params, responseType: "blob" });
      this.downloading = false;
      if (err) return;
      await commonUtil.tryCatch(downloadBlobFile, { obj: blobData, name: option.filename, suffix: "xlsx" });
      this.downloadingOrder = false;
      this.downloadingGoods = false;
    },
    handleTypeInputChange(val) {
      this.searchForm[this.orderType] = val;
    },
    handleTypeChange(val) {
      this.searchForm = {};
      this.orderTypeInput = "";
      this.searchForm[val] = "";
    },
    handleDateChange(val) {
      // 预计发货时间
      this.searchForm.pre_sent_time_start = val ? val[0] : "";
      this.searchForm.pre_sent_time_end = val ? val[1] : "";
    },
    handleDeliveryDateChange(val) {
      this.searchForm.sent_time_start = val ? val[0] : "";
      this.searchForm.sent_time_end = val ? val[1] : "";
    },

    resetSearchForm() {
      this.order_time = [];
      this.delivery_time = [];
      this.payment_time = [];
      this.tran_time = [];
      this.orderTypeInput = "";
      this.searchForm = {
        order_status: "",
        goods_name: "",
        pre_sent_time_start: "",
        pre_sent_time_end: "",
        sent_time_start: "",
        sent_time_end: "",
      };
      this.getList();
    },
    handleBatchExpress() {
      let flag = this.multipleSelection.some((item) => {
        return item.order_states != "NODELIVERY";
      });
      if (flag) {
        this.$message.warning("已发货的订单无法再次发货");
      } else {
        this.currentGoodsData = this.multipleSelection;
        this.dialogDispatchOrder = true;
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleOrderRadioChange(v) {
      this.listQuery.page = 1;
      let { order_filter } = this;
      if (order_filter != "全部") {
        this.searchForm.order_status = v;
      } else {
        this.searchForm.order_status = "";
      }
      this.getList();
    },
    // eslint-disable-next-line no-unused-vars

    getList() {
      // 获取数据
      this.getListMixin(api.order.deliverList).then(([, res]) => {
        this.storeData = res.list;
      });
    },
    handleAddNoteConfirm() {
      let { item_no, remark } = this.note_form;
      this.axios.get(`/regular_goods/remark/${item_no}/${remark}`).then(() => {
        this.$notify.success("添加成功");
        this.dialogAddNote = false;
        this.note_form = {
          item_no: "",
          remark: "",
        };
        this.getList();
      });
    },
    goBack() {},
    handleDispatchOrder(row) {
      this.currentGoodsData = [row];
      this.dialogDispatchOrder = true;
    },
    handleAddNote(row) {
      this.note_form.item_no = row.item_no;
      this.dialogAddNote = true;
    },
    handleOrderDetail(row) {
      this.$router.push({ path: "/order/schedule_detail", query: { id: row.item_no, source: "order_mange" } });
    },
    handleAfterSaleDetail(row) {
      this.$router.push({ path: "/order/sales_detail", query: { id: row.after_sale_no, source: "order_mange" } });
    },

    handleAddStore() {},

    handleDelivery(err) {
      if (!err) {
        this.getList();
        this.dialogDispatchOrder = false;
        this.$notify.success("操作成功");
      }
    },
  },
};
</script>
