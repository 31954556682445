var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "vac-box-search",
    },
    [
      !_vm.loadingRooms && _vm.rooms.length
        ? _c(
            "div",
            {
              staticClass: "vac-icon-search",
            },
            [
              _vm._t("search-icon", function () {
                return [
                  _c("svg-icon", {
                    attrs: {
                      name: "search",
                    },
                  }),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      !_vm.loadingRooms && _vm.rooms.length
        ? _c("input", {
            staticClass: "vac-input",
            attrs: {
              type: "search",
              placeholder: _vm.textMessages.SEARCH,
              autocomplete: "off",
            },
            on: {
              input: function input($event) {
                return _vm.$emit("search-room", $event)
              },
            },
          })
        : _vm._e(),
      _vm.showAddRoom
        ? _c(
            "div",
            {
              staticClass: "vac-svg-button vac-add-icon",
              on: {
                click: function click($event) {
                  return _vm.$emit("add-room")
                },
              },
            },
            [
              _vm._t("add-icon", function () {
                return [
                  _c("svg-icon", {
                    attrs: {
                      name: "add",
                    },
                  }),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }