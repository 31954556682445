var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "page-padding",
    },
    [
      _c(
        "div",
        {
          staticClass: "panel-item",
          staticStyle: {
            "margin-top": "20px",
          },
        },
        [_vm._v("配送管理")]
      ),
      _c(
        "div",
        {
          staticClass: "header",
        },
        [
          _c("div", [_vm._v("设置禁发地区")]),
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-plus",
                type: "primary",
              },
              on: {
                click: _vm.handleOpenAddDialog,
              },
            },
            [_vm._v(" 新增 ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "page-filter--wrap",
        },
        [
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [_vm._v("地区：")]
          ),
          _c(
            "div",
            {
              staticClass: "page-select--item",
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入省/市/区搜索",
                },
                model: {
                  value: _vm.searchForm.keyword,
                  callback: function callback($$v) {
                    _vm.$set(_vm.searchForm, "keyword", $$v)
                  },
                  expression: "searchForm.keyword",
                },
              }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: {
                round: "",
                size: "mini",
                icon: "el-icon-refresh",
              },
              on: {
                click: _vm.resetSearchForm,
              },
            },
            [_vm._v(" 重置 ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                round: "",
                size: "mini",
                icon: "el-icon-search",
              },
              on: {
                click: _vm.getList,
              },
            },
            [_vm._v(" 搜索 ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: {
            width: "100%",
          },
          attrs: {
            data: _vm.districtData,
            stripe: "",
            border: "",
            "highlight-current-row": "",
          },
        },
        [
          _c(
            "el-table-column",
            {
              attrs: {
                type: "index",
                align: "center",
                width: "60",
              },
            },
            [
              _c(
                "template",
                {
                  slot: "header",
                },
                [_vm._v(" 序号 ")]
              ),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "300",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref) {
                  var row = _ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                        },
                        on: {
                          click: function click($event) {
                            return _vm.handleOpenEditDialog(row)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                    _c(
                      "el-popconfirm",
                      {
                        staticStyle: {
                          margin: "0 10px",
                        },
                        attrs: {
                          title: "确定删除该数据吗？",
                          placement: "left",
                        },
                        on: {
                          onConfirm: function onConfirm($event) {
                            return _vm.handleDelete(row)
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              type: "text",
                            },
                            slot: "reference",
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "province",
              label: "省",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "city",
              label: "市",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "district",
              label: "区",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "creator",
              label: "创建人",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "create_time",
              label: "创建时间",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "updater",
              label: "最后修改人",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "update_time",
              label: "最后修改时间",
              "show-overflow-tooltip": "",
            },
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function updatePage($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function updateLimit($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "".concat(
              _vm.textMap[_vm.dialogStatus],
              "\u7981\u53D1\u5730\u533A"
            ),
            visible: _vm.dialogDistrictForm,
            width: "500px",
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.dialogDistrictForm = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "districtForm",
              attrs: {
                model: _vm.districtForm,
                rules: _vm.districtRules,
                "label-width": "90px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "禁用地区",
                    prop: "cities",
                  },
                },
                [
                  _vm.dialogDistrictForm
                    ? _c("el-cascader", {
                        ref: "cities",
                        staticStyle: {
                          width: "100%",
                        },
                        attrs: {
                          size: "mini",
                          props: _vm.cascadeProps,
                        },
                        on: {
                          change: _vm.handleCityChange,
                        },
                        model: {
                          value: _vm.selected_city,
                          callback: function callback($$v) {
                            _vm.selected_city = $$v
                          },
                          expression: "selected_city",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "text",
                  },
                  on: {
                    click: function click($event) {
                      _vm.dialogDistrictForm = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                  },
                  on: {
                    click: _vm.handleDialogConfirm,
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.dialogStatus == "update" ? "修改" : "确定") +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }