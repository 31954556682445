var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "page-padding",
      staticStyle: {
        background: "#fff",
      },
    },
    [
      _c(
        "sticky",
        {
          attrs: {
            "z-index": 20,
          },
        },
        [
          _c("el-page-header", {
            staticClass: "go-back",
            attrs: {
              content: "会员详情",
            },
            on: {
              back: _vm.goBack,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.detailLoading,
              expression: "detailLoading",
            },
          ],
        },
        [
          _c(
            "el-form",
            {
              staticClass: "form-class",
              attrs: {
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "div",
                    {
                      staticClass: "panel-item",
                    },
                    [_vm._v("基本信息")]
                  ),
                  _c(
                    "el-col",
                    {
                      attrs: {
                        span: 6,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "会员姓名：",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.m_data.nick_name) + " ")]
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "会员级别：",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.m_data.level) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      attrs: {
                        span: 6,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "手机号码：",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.m_data.phone_number) + " ")]
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "会员性别：",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("genderText")(_vm.m_data.gender)) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      attrs: {
                        span: 6,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "注册时间：",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.m_data.registration_time) + " "
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "最近消费：",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.m_data.recent_consumption_time) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      attrs: {
                        span: 6,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "累计消费金额：",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.m_data.accruing_amount) + " ")]
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "累计消费次数：",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.m_data.accumulated_consumption_time) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }