var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "el-dialog",
    {
      attrs: {
        title: "".concat(
          _vm.rowId ? "编辑" : "新增",
          "\u81EA\u63D0\u5730\u5740"
        ),
        visible: _vm.visible,
      },
      on: {
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "省市区",
                required: "",
                prop: "province",
              },
            },
            [
              _vm.dialogDistrictForm
                ? _c("el-cascader", {
                    ref: "cities",
                    staticStyle: {
                      width: "300px",
                    },
                    attrs: {
                      size: "mini",
                      props: _vm.cascadeProps,
                    },
                    on: {
                      change: _vm.handleCityChange,
                    },
                    model: {
                      value: _vm.selected_city,
                      callback: function callback($$v) {
                        _vm.selected_city = $$v
                      },
                      expression: "selected_city",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "详细地址",
                prop: "street_address",
              },
            },
            [
              _c("el-input", {
                staticStyle: {
                  width: "400px",
                },
                attrs: {
                  type: "textarea",
                  maxlength: "30",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.formData.street_address,
                  callback: function callback($$v) {
                    _vm.$set(_vm.formData, "street_address", $$v)
                  },
                  expression: "formData.street_address",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "联系人",
                prop: "contact_name",
              },
            },
            [
              _c("el-input", {
                staticStyle: {
                  width: "300px",
                },
                model: {
                  value: _vm.formData.contact_name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.formData, "contact_name", $$v)
                  },
                  expression: "formData.contact_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "联系人电话",
                prop: "contact_phone_number",
              },
            },
            [
              _c("el-input", {
                staticStyle: {
                  width: "300px",
                },
                model: {
                  value: _vm.formData.contact_phone_number,
                  callback: function callback($$v) {
                    _vm.$set(_vm.formData, "contact_phone_number", $$v)
                  },
                  expression: "formData.contact_phone_number",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "是否启用",
                required: "",
              },
            },
            [
              _c("el-switch", {
                attrs: {
                  "active-value": 1,
                  "inactive-value": 0,
                },
                model: {
                  value: _vm.formData.status,
                  callback: function callback($$v) {
                    _vm.$set(_vm.formData, "status", $$v)
                  },
                  expression: "formData.status",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "text",
              },
              on: {
                click: _vm.close,
              },
            },
            [_vm._v(" 取消 ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
              },
              on: {
                click: _vm.handleDialogConfirm,
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.dialogStatus == "update" ? "修改" : "确定") +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }