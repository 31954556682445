var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _c(
      "div",
      {
        staticClass: "app-container",
        class: {
          "app-mobile": _vm.isDevice,
          "app-mobile-dark": _vm.theme === "dark",
        },
      },
      [
        _vm.showChat
          ? _c("chat-container", {
              attrs: {
                "current-user-id": _vm.currentUserId,
                theme: _vm.theme,
                "is-device": _vm.isDevice,
              },
              on: {
                "show-demo-options": function showDemoOptions($event) {
                  _vm.showDemoOptions = $event
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }