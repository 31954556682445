var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "page-padding",
    },
    [
      _vm._m(0),
      _c(
        "el-row",
        {
          staticClass: "page-filter--wrap",
        },
        [
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [_vm._v("会员名/手机号")]
          ),
          _c("el-input", {
            staticClass: "page-select--item",
            staticStyle: {
              width: "180px",
            },
            attrs: {
              placeholder: "请输入会员名/手机号",
            },
            nativeOn: {
              keyup: function keyup($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.getList.apply(null, arguments)
              },
            },
            model: {
              value: _vm.searchForm.keyword,
              callback: function callback($$v) {
                _vm.$set(_vm.searchForm, "keyword", $$v)
              },
              expression: "searchForm.keyword",
            },
          }),
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [_vm._v("状态")]
          ),
          _c(
            "el-select",
            {
              staticClass: "page-select--item",
              attrs: {
                placeholder: "请选择",
              },
              model: {
                value: _vm.searchForm.status,
                callback: function callback($$v) {
                  _vm.$set(_vm.searchForm, "status", $$v)
                },
                expression: "searchForm.status",
              },
            },
            [
              _c("el-option", {
                attrs: {
                  label: "正常",
                  value: "1",
                },
              }),
              _c("el-option", {
                attrs: {
                  label: "禁用",
                  value: "0",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [_vm._v("注册会员时间")]
          ),
          _c("el-date-picker", {
            attrs: {
              "value-format": "yyyy-MM-dd",
              type: "daterange",
              "range-separator": "至",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
            },
            on: {
              change: function change($event) {
                return _vm.handleDateChange($event, "registration")
              },
            },
            model: {
              value: _vm.registration_time,
              callback: function callback($$v) {
                _vm.registration_time = $$v
              },
              expression: "registration_time",
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "reset-btn",
              attrs: {
                round: "",
                icon: "el-icon-refresh",
              },
              on: {
                click: _vm.resetSearchForm,
              },
            },
            [_vm._v(" 重置 ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                round: "",
                icon: "el-icon-search",
              },
              on: {
                click: function click($event) {
                  return _vm.getList()
                },
              },
            },
            [_vm._v(" 搜索 ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: {
            width: "100%",
          },
          attrs: {
            data: _vm.memberData,
            stripe: "",
            border: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "序号",
              width: "60",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "80px",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref) {
                  var row = _ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          disabled: true,
                          type: "text",
                        },
                        on: {
                          click: function click($event) {
                            return _vm.handleMemberDetail(row)
                          },
                        },
                      },
                      [_vm._v(" 查看详情 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "open_id",
              label: "OpenId",
              width: "240px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "status",
              label: "状态",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref2) {
                  var row = _ref2.row
                  return [
                    _c(
                      "span",
                      {
                        class: {
                          green: row.status === 1,
                          gray: row.status === 0,
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(row.status === 1 ? "正常" : "禁用") + " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "name",
              label: "会员名",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "membership_card_num",
              label: "会员ID",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "phone",
              label: "手机号",
              width: "100px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "create_time",
              label: "注册时间",
              width: "150px",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "agree_privacy_policy",
              label: "是否同意隐私政策",
              width: "100px",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref3) {
                  var row = _ref3.row
                  return [
                    _c(
                      "span",
                      {
                        on: {
                          click: function click($event) {
                            return _vm.openPrivacyDialog(row.id)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              row.agree_privacy_policy === 1 ? "是" : "否"
                            ) +
                            " "
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                            },
                          },
                          [_vm._v("查看历史")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "total_consumption_times",
              label: "累计消费次数",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "total_consumption_amount",
              label: "累计消费金额",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "last_consumption_time",
              label: "上次消费时间",
              "show-overflow-tooltip": "",
            },
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function updatePage($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function updateLimit($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c("PrivacyDialog", {
        attrs: {
          visible: _vm.privacy.visible,
          "user-id": _vm.privacy.userId,
        },
        on: {
          "update:visible": function updateVisible($event) {
            return _vm.$set(_vm.privacy, "visible", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "page-header--wrap",
      },
      [
        _c(
          "div",
          {
            staticClass: "page-header-wrap--title",
          },
          [_vm._v("会员管理")]
        ),
        _c("div"),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }