import download from "@/utils/download";
const BASE_URL = process.env.VUE_APP_BIG_DATA_API;

// 下载商城订单订单 - 订单维度
// export function downloadTrmallOrder(params) {
//   return download({
//     // url: `${BASE_URL}emall_order/downloadTrmallOrder`,
//     url: `${BASE_URL}emall_order/downloadTrmallOrder/v2`,
//     method: "get",
//     params,
//   });
// }

// 下载商城订单订单 - 商品维度
// export function downloadTrmallOrderProduct(params) {
//   return download({
//     // url: `${BASE_URL}emall_order/downloadTrmallOrderProduct`,
//     url: `${BASE_URL}emall_order/downloadTrmallOrderProduct/v2`,
//     method: "get",
//     params,
//   });
// }

// 下载商城售后订单
export function downloadAfterSaleOrder(params) {
  return download({
    url: `${BASE_URL}afterSales/downloadAfterSaleOrder`,
    method: "get",
    params,
  });
}

// 下载其他发货 - 订单维度
export function downloadOtherOrder(params) {
  return download({
    url: `${BASE_URL}otherOrder/downloadOtherOrder`,
    method: "get",
    params,
  });
}

// 下载其他发货 - 商品维度
export function downloadOtherProduct(params) {
  return download({
    url: `${BASE_URL}otherOrder/downloadOtherOrderItem`,
    method: "get",
    params,
  });
}

// 发货管理下载商城订单订单
export function downloadRegularOrder(params) {
  return download({
    url: `${BASE_URL}regular_goods/downloadRegularOrderDetail`,
    method: "get",
    params,
  });
}

// 定时发货订单下载
export function downloadScheduleOrder(params) {
  return download({
    url: `${BASE_URL}regular_goods/downloadRegularOrder`,
    method: "get",
    params,
  });
}

// 发票下载
export function downloadInvoice(params) {
  return download({
    url: `${BASE_URL}regular_goods/downBill`,
    method: "get",
    params,
  });
}

// 下载兑换码
export function downloadCdKey(orderNo) {
  return download({
    url: `${BASE_URL}regular_goods/downCdKey/${orderNo}`,
    method: "get",
  });
}
