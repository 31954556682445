<template>
  <div class="page-padding">
    <Sticky :z-index="20">
      <el-page-header content="限时降价" class="go-back" @back="handleNavBack()" />
    </Sticky>
    <div class="panel-item">基本信息</div>
    <section class="page-filter--wrap">
      <el-form ref="form" :rules="rules" :model="formData" label-width="120px">
        <el-form-item label="活动名称" prop="name">
          <el-input v-model="formData.name" placeholder="请输入活动名称" />
        </el-form-item>
        <el-form-item label="活动简介">
          <el-input v-model="formData.intro" placeholder="请输入活动简介" />
        </el-form-item>
        <el-form-item label="日期时间" prop="timeRange">
          <el-date-picker
            v-model="formData.timeRange"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
        </el-form-item>
        <el-form-item label="活动上架状态" prop="enable">
          <el-select v-model="formData.enable">
            <el-option label="上架" :value="true" />
            <el-option label="下架" :value="false" />
          </el-select>
        </el-form-item>
      </el-form>
    </section>
    <section>
      <div class="panel-item">活动商品信息</div>
      <el-button class="mt-[6px] mb-[6px]" @click="handleOpen"> 选择/修改商品 </el-button>
      <el-table :span-method="handleSpan" :data="tableData" border stripe>
        <el-table-column prop="index" label="商品序号" align="center" />
        <el-table-column prop="name" label="商品名称">
          <template #header>
            <div class="flex justify-center">商品名称</div>
          </template>
          <template slot-scope="scope">
            <div class="flex items-center">
              <img :src="scope.row.imgUrl" class="goodImg mr-[10px]" /><span>{{ scope.row.name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="tag_set" :formatter="formatCategoryName" label="商品分组" align="center" />
        <el-table-column prop="props" label="规格" align="center" />
        <el-table-column prop="price" label="售价（元）" align="center" />
        <el-table-column align="center">
          <template slot="header"> <span style="color: red">*</span>促销价（元） </template>
          <template slot-scope="scope">
            <el-input v-model="scope.row.activity_price" @blur="handleCheckActivityPrice(scope.row)" />
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="flex justify-center mt-[50px]">
      <el-button size="medium" class="mr-[20px]" @click="handleNavBack()"> 取消 </el-button>
      <el-button size="medium" type="primary" @click="handleSubmit">
        {{ formData.id ? "修改" : "创建" }}
      </el-button>
    </section>
    <InfoPopup :goods-id-set="goodsIdSet" :visible.sync="infoPopupVisible" @confirm="formatTableData" />
  </div>
</template>

<script>
import * as R from "ramda";
import Sticky from "@/components/Sticky";
import InfoPopup from "@/views/activity/depreciate/detail/InfoPopup";
import api from "@/api";
import { commonUtil } from "@/utils";

const { to } = commonUtil;

const rules = {
  name: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
  timeRange: [{ required: true, message: "请选择活动时间", trigger: "blur" }],
  enable: [{ required: true, message: "请选择上架状态", trigger: "blur" }],
};
const initFormData = {
  id: undefined,
  name: "", // 活动名称
  intro: "", // 活动介绍
  enable: true, // 活动状态
  start_time: [],
  end_time: [],
  timeRange: [], // 不需要传给后台
};
export default {
  name: "DepreciateDetail",
  components: {
    InfoPopup,
    Sticky,
  },
  data() {
    return {
      infoPopupVisible: false,
      tableData: [],
      goodsIdSet: [], // 选中的spuIds
      oldActivityPriceList: [], // 保存当前促销价对象{ skuId: xxx, activity_price: yyy }
      rules,
      formData: R.clone(initFormData),
    };
  },
  watch: {
    $route(to) {
      const { name } = to;
      if (name === "DepreciateDetail") {
        this.init();
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.formData.id = this.$route.query.activityId;
      if (this.formData.id) this.fetchActivity();
      else this.reset();
    },
    reset() {
      this.tableData = [];
      this.goodsIdSet = [];
      this.oldActivityPriceList = [];
      this.formData = R.clone(initFormData);
    },
    async fetchActivity() {
      const [, res] = await api.activity.activity({
        id: this.formData.id,
      });
      const { goods_info_list, name, intro, start_time, end_time, enable } = res;
      this.formatTableData(goods_info_list);
      this.oldActivityPriceList = this.tableData.map(({ skuId, activity_price }) => ({ skuId, activity_price }));

      this.formData.name = name;
      this.formData.intro = intro;
      this.formData.timeRange = [start_time, end_time];
      this.formData.enable = enable;
    },
    handleNavBack({ isRefresh = false } = {}) {
      this.$store.dispatch("tagsView/delView", this.$route);
      this.reset();
      this.$router.replace({ name: "Depreciate", query: { isRefresh } });
    },
    handleNavAdd() {
      this.$router.push("/shopper/member");
    },
    handleOpen() {
      this.oldActivityPriceList = this.tableData.map(({ skuId, activity_price }) => ({ skuId, activity_price }));
      this.goodsIdSet = [...new Set(this.tableData.map((item) => item.goodsId))];
      this.infoPopupVisible = true;
    },
    formatTableData(selected) {
      const _selected = selected.reduce((pre, cur, index) => {
        const { name, image_list = [] } = cur;
        return pre.concat(
          cur.goods_sku_info_list.map(({ id: skuId, retail_price, specification, activity_price, brand_code }, skuIndex) => {
            const { activity_price: oldActivityPrice } = this.oldActivityPriceList.find((item) => item.skuId === skuId) ?? {};
            return {
              brand_code,
              rowSpan: skuIndex === 0 ? cur.goods_sku_info_list.length : 0,
              index: index + 1,
              name,
              tag_set: cur.tag_set,
              goodsId: cur.id,
              skuId,
              imgUrl: image_list[0] ?? "",
              price: retail_price.toFixed(2),
              props: Object.values(JSON.parse(specification)).join("+"),
              activity_price: oldActivityPrice ?? activity_price?.toFixed(2),
            };
          })
        );
      }, []);
      this.tableData = _selected;
    },
    formatCategoryName({ tag_set = [] } = {}) {
      if (!tag_set) return "-";
      return R.join("，", tag_set);
    },
    handleSpan({ row, columnIndex }) {
      if (columnIndex < 3) return [row.rowSpan, 1];
      return [1, 1];
    },
    formatData() {
      const { intro, timeRange, enable, name, id } = this.formData;
      const activity_goods_sku_info_list = this.tableData.map((item) => ({
        activity_price: Number(item.activity_price),
        goods_sku_id: item.skuId,
      }));
      const data = {
        id,
        name,
        intro,
        enable,
        start_time: timeRange[0],
        end_time: timeRange[1],
        activity_goods_sku_info_list,
      };
      return data;
    },
    handleCheckActivityPrice(row) {
      const { activity_price } = row;
      if (Number.isNaN(+activity_price)) {
        row.activity_price = undefined;
        this.$message.error("请输入正确的促销价");
      } else {
        row.activity_price = Number(activity_price).toFixed(2);
      }
    },
    async validateData({ activity_goods_sku_info_list = [] } = {}) {
      let [, flag] = await to(this.$refs.form.validate());
      if (activity_goods_sku_info_list.length === 0) {
        this.$message.error("请先选择活动商品");
        flag = false;
      } else if (activity_goods_sku_info_list.some((item) => !item.activity_price)) {
        this.$message.error("请输入促销价");
        flag = false;
      }

      return flag;
    },
    async handleSubmit() {
      const data = this.formatData();
      if (!(await this.validateData(data))) return;
      const [err] = await api.activity.activity({
        method: data.id ? "put" : "post",
        id: data.id,
        data: R.omit("id", data),
      });
      if (!err) {
        this.$message.success(`${data.id ? "修改" : "创建"}成功`);
        this.handleNavBack({ isRefresh: true });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.goodImg {
  width: 100px;
  height: 100px;
}
.go-back {
  background: #eff1f3;
  margin: 0;
  padding-left: 10px;
}
.panel-item {
  margin: 0;
}
::v-deep .el-table__row {
  .el-input__inner {
    text-align: center;
  }
}
</style>
