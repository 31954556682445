<template>
  <div class="upload-wrap">
    <el-upload
      ref="elUpload"
      :class="{ 'hide-upload-holder': hideUpload }"
      :action="upload_qiniu_area"
      :auto-upload="true"
      :limit="limitCount"
      :multiple="true"
      :accept="acceptType.join(',')"
      :file-list="fileList"
      :list-type="listType"
      :on-preview="picCardPreview"
      :before-upload="beforePicUpload"
      :on-exceed="handleExceed"
      :on-remove="removePic"
      :http-request="uploadQiniu"
    >
      <div v-if="listType === 'picture-card'" slot="default" class="el-icon-plus" style="color: #409eff">添加图片</div>
      <slot v-if="listType === 'text'" name="btn" />
    </el-upload>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" />
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
const upload_qiniu_address = "https://cdn.jmj1995.com/"; //七牛云返回储存图片的子域名（外链域名）

export default {
  name: "UploadPicture",
  props: {
    /**
     * 允许上传的图片张数，默认1张
     */
    limitCount: {
      type: Number,
      default: 1,
      required: true,
    },
    /**
     * 图片尺寸，默认2MB
     */
    imgSize: {
      type: Number,
      default: 2,
    },
    /**
     * 根据已有图片URL，回填图片
     */
    alreadyFile: {
      type: [String, Array],
      default: "",
    },
    token: {
      type: String,
      default: "",
    },
    acceptType: {
      type: Array,
      default: () => ["image/png", "image/jpg", "image/jpeg", "image/gif"],
    },
    uploadDir: {
      type: String,
      default: "",
    },
    listType: {
      type: String,
      default: "picture-card",
    },
  },
  data() {
    return {
      fileList: [],
      hideUpload: false,
      dialogImageUrl: "",
      dialogVisible: false,
      upload_qiniu_area: "https://upload-z2.qiniup.com", //七牛云上传储存区域的上传域名
      progressFlag: false,
    };
  },

  watch: {
    alreadyFile(val) {
      if (this.limitCount == 1) {
        this.hideUpload = true;
      }
      if (typeof val == "string" && val != "") {
        this.fileList = [{ url: val }];
      }
      if (Array.isArray(val) && val.length != 0) {
        this.initImage(val);
      }
      if (val == "" || val === null) {
        this.hideUpload = false;
        this.fileList = [];
      }
    },
  },
  created() {
    let val = this.alreadyFile;
    if (typeof val == "string" && val != "" && this.limitCount == 1) {
      this.fileList = [{ url: val }];
      this.hideUpload = true;
    }
  },

  methods: {
    initImage(val) {
      let arrSet = new Set(val);
      let tempVal = [];
      for (let k of arrSet) {
        tempVal.push(k);
      }

      let tempArr = [];
      tempVal.forEach((item) => {
        let tempObj = { url: "" };
        tempObj.url = item;
        tempArr.push(tempObj);
      });
      this.fileList = tempArr;
    },
    picCardPreview(file) {
      //上传图预览
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforePicUpload(file) {
      //图片校验
      const limitPic = this.acceptType.includes(file.type);
      if (!limitPic) {
        this.$notify.warning({
          title: "提示",
          message: `请上传格式为${this.acceptType.join("、")}的图片`,
        });
      }
      const limitSize = file.size / 1024 / 1024 < this.imgSize;
      if (!limitSize) {
        this.$notify.warning({
          title: "警告",
          message: `图片大小必须小于${this.imgSize}M`,
        });
      }
      return limitPic && limitSize;
    },
    removePic(file, fileList) {
      //移除图片
      this.hideUpload = fileList.length >= this.limitCount;
      this.fileList = fileList;

      // this.$emit("uploadSuccess", this.fileList);
      this.$emit("removeSuccess", this.fileList);
    },
    handleExceed() {
      // this.hideUpload = this.fileList.length >= this.limitCount;
      //文件超出个数限制
      this.$notify.warning({
        title: "提示",
        message: "一次只能上传 " + this.limitCount + " 张图片",
        duration: 2000,
      });
    },
    uploadQiniu(request) {
      this.hideUpload = true;
      //上传七牛
      this.handleUpload(request)
        .then((result) => {
          if (!result.data.key) {
            this.$notify.error({
              message: "图片上传失败,请重新上传",
              duration: 2000,
            });
          } else {
            this.fileList.push({ url: upload_qiniu_address + result.data.key });
            this.$emit("uploadSuccess", this.fileList);
            this.hideUpload = this.fileList.length >= this.limitCount;
          }
        })
        .catch((err) => {
          this.$notify.error({
            message: `图片上传失败${err}`,
            duration: 2000,
          });
        });
    },
    handleUpload(request) {
      const promise = new Promise((resolve, reject) => {
        const config = {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            let num = ((progressEvent.loaded / progressEvent.total) * 100) | 0; //百分比
            request.onProgress({ percent: Number(num) }); //进度条
          },
        };
        let fileType = request.file.type.split("/")[1] || "";

        const key = `${this.uploadDir || ""}front_${new Date().getTime()}${Math.floor(Math.random() * 100)}.${fileType}`; //自定义图片名

        const fd = new FormData();
        fd.append("file", request.file);
        fd.append("token", this.token);
        fd.append("key", key);
        axios
          .post(this.upload_qiniu_area, fd, config)
          .then((res) => {
            if (res.status == 200 && res.data) {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((err) => {
            this.$notify.error({
              message: `上传失败[${err.status}]`,
              duration: 2000,
            });
          });
      });
      return promise;
    },
    clearFiles() {
      this.$refs.elUpload.clearFiles();
      this.$nextTick(() => {
        this.fileList = [];
        this.hideUpload = false;
      });
    },
  },
};
</script>

<style scoped>
.upload-wrap >>> .hide-upload-holder .el-upload--picture-card {
  display: none;
}
.upload-wrap ::v-deep .el-upload-list__item {
  transition: none !important;
}
</style>
