<template>
  <div @mouseenter="handleMouseEnter($event)">
    <el-upload
      :action="upload_qiniu_area"
      list-type="picture-card"
      :show-file-list="false"
      :before-upload="beforeUploadVideo"
      :http-request="uploadQiniu"
    >
      <video v-if="video_url != '' && videoFlag == false" :src="video_url" controls="controls" width="300" height="200">
        您的浏览器不支持视频播放
      </video>

      <div v-else-if="video_url == '' && videoFlag == false" class="el-icon-plus" style="color: #409eff">添加视频</div>
      <el-progress v-if="videoFlag == true" type="circle" :percentage="videoUploadPercent" style="margin-top: 10px" />
    </el-upload>
  </div>
</template>

<script>
import axios from "axios";
const upload_qiniu_address = "https://cdn.jmj1995.com/";
export default {
  props: {
    alreadyFile: {
      type: [String, Array],
      default: "",
    },
    token: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      upload_qiniu_area: "https://upload-z2.qiniup.com", //七牛云上传储存区域的上传域名
      video_url: "",
      videoFlag: false,
      videoUploadPercent: 0,
      fileList: [],
    };
  },
  watch: {
    alreadyFile(val) {
      this.video_url = val;
    },
  },
  methods: {
    handleMouseEnter(e) {
      if (this.video_url != "") e.currentTarget.className = "video-wrap";
    },
    beforeUploadVideo(file) {
      //不超过 20M
      const isLt10M = file.size / 1024 / 1024 < 20;
      if (["video/mp4", "video/ogg", "video/flv", "video/avi", "video/wmv", "video/rmvb"].indexOf(file.type) == -1) {
        this.$notify.error("请上传正确的视频格式");
        return false;
      }
      if (!isLt10M) {
        this.$notify.error("上传视频大小不能超过20MB哦!");
        return false;
      }
    },
    // uploadVideoProcess(event, file, fileList) {
    //   // console.log(file.percentage);
    //   // console.log(file, fileList);
    //   // this.videoFlag = true;
    //   // this.videoUploadPercent = file.percentage.toFixed(0);
    // },
    handleVideoSuccess(url) {
      this.fileList.push({ video_url: url });
      this.video_url = url;
      this.videoFlag = false;
      this.videoUploadPercent = 0;
      // if (res.status == 200) {
      //   // this.videoForm.videoUploadId = res.data.uploadId;
      //   // this.videoForm.video_url = res.data.uploadUrl;
      // } else {
      //   this.$notify.error("视频上传失败，请重新上传！");
      // }
    },
    uploadQiniu(request) {
      //上传七牛
      this.handleUpload(request)
        .then((result) => {
          if (!result.data.key) {
            this.$notify.error({
              message: "视频上传失败,请重新上传",
              duration: 2000,
            });
          } else {
            let url = upload_qiniu_address + result.data.key;
            this.handleVideoSuccess(url);
            // this.$emit("uploadSuccess", this.fileList);
            this.$emit("uploadSuccess", url);
          }
        })
        .catch((err) => {
          this.$notify.error({
            message: `视频上传失败${err}`,
            duration: 2000,
          });
        });
    },
    handleUpload(request) {
      const promise = new Promise((resolve, reject) => {
        const config = {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            let num = ((progressEvent.loaded / progressEvent.total) * 100) | 0; //百分比
            this.videoFlag = true;
            this.videoUploadPercent = num;
            request.onProgress({ percent: Number(num) }); //进度条
          },
        };
        let fileType = request.file.type;

        const key = `video_${new Date().getTime()}${Math.floor(Math.random() * 100)}.${fileType}`; //自定义图片名

        const fd = new FormData();
        fd.append("file", request.file);
        fd.append("token", this.token);
        fd.append("key", key);
        axios
          .post(this.upload_qiniu_area, fd, config)
          .then((res) => {
            if (res.status == 200 && res.data) {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((err) => {
            this.$notify.error({
              message: `上传失败[${err.status}]`,
              duration: 2000,
            });
          });
      });
      return promise;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep video {
  width: 148px;
  height: 148px;
}

.video-wrap ::v-deep .el-upload--picture-card {
  position: relative;

  &::after {
    display: inline-block;
    content: "重新上传";
    color: #fff;
    height: 100%;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 30%;
  }

  &:hover {
    width: 148px;
    height: 148px;
    cursor: pointer;
    text-align: center;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s;
  }

  &:hover::after {
    opacity: 1;
  }
}
</style>
