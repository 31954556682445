import Layout from "@/layout";

const reportRouter = {
  path: "/reports",
  component: Layout,
  redirect: "/reports/reports_list",
  name: "reports",
  meta: {
    title: "数据中心",
    icon: "report",
    affix: true,
    roles: ["admin"],
  },
  children: [
    {
      path: "daily_report",
      name: "dailyReport",
      component: () => import("@/views/mall-manage/daily-report"),
      meta: { title: "全店日报", icon: "detail" },
    },
    {
      path: "goods_effect",
      name: "goodsEffect",
      component: () => import("@/views/mall-manage/goods-effect"),
      meta: { title: "商品效果", icon: "detail" },
    },
  ],
};

export default reportRouter;
