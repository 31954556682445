<template>
  <div style="background: #fff" class="page-padding">
    <sticky :z-index="20">
      <el-page-header content="会员详情" class="go-back" @back="goBack" />
    </sticky>
    <div v-loading="detailLoading">
      <el-form class="form-class" label-width="130px">
        <el-row>
          <div class="panel-item">基本信息</div>
          <el-col :span="6">
            <el-form-item label="会员姓名：">
              {{ m_data.nick_name }}
            </el-form-item>
            <!-- <el-form-item label="会员ID："> {{ m_data.member_no }}</el-form-item> -->
            <el-form-item label="会员级别：">
              {{ m_data.level }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号码：">
              {{ m_data.phone_number }}
            </el-form-item>
            <el-form-item label="会员性别：">
              {{ m_data.gender | genderText }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="注册时间：">
              {{ m_data.registration_time }}
            </el-form-item>
            <el-form-item label="最近消费：">
              {{ m_data.recent_consumption_time }}
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="累计消费金额：">
              {{ m_data.accruing_amount }}
            </el-form-item>
            <el-form-item label="累计消费次数：">
              {{ m_data.accumulated_consumption_time }}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import Sticky from "@/components/Sticky";
export default {
  components: { Sticky },
  filters: {
    genderText(val) {
      let map = {
        0: "未知",
        1: "男",
        2: "女",
      };
      return map[val];
    },
  },
  data() {
    return {
      detailLoading: false,
      m_data: {},
    };
  },
  activated() {
    let member_no = this.$route.query.id;
    this.getMemberDetail(member_no);
  },
  created() {
    let member_no = this.$route.query.id;
    this.getMemberDetail(member_no);
  },
  methods: {
    goBack() {
      this.$router.replace("/shopper/member");
    },
    getMemberDetail(id) {
      this.detailLoading = true;
      this.axios.get(`/shop_member/detail?id=${id}`).then((res) => {
        this.m_data = res.ndata;
        this.detailLoading = false;
      });
    },
  },
};
</script>

<style scoped>
.form-class ::v-deep .el-form-item__label {
  color: #909399;
}
</style>
