import Layout from "@/layout";
const mallIMRouter = {
  path: "/im",
  component: Layout,
  name: "IM",
  meta: {
    title: "客服消息",
    icon: "im",
    roles: ["admin"],
  },
  children: [
    // {
    //   path: `${window.origin}/im`,
    //   name: "MallIM",
    //   meta: { title: "客服消息" },
    // },
    {
      path: "/instant",
      name: "Instant",
      component: () => import("@/views/mall-im/instant"),
      meta: { title: "即时会话" },
    },
    {
      path: "/history",
      name: "History",
      component: () => import("@/views/mall-im/history"),
      meta: { title: "历史会话" },
    },
    {
      path: "/keywords",
      name: "Keywords",
      component: () => import("@/views/mall-im/keywords"),
      meta: { title: "关键词回复" },
    },
  ],
};

export default mallIMRouter;
