<template>
  <div>
    <div class="room-right-header" />
    <el-tabs v-model="activeTab" class="tabs-class" type="border-card" @tab-click="handleTabClick">
      <el-tab-pane label="资料" name="profile">
        <customer-info />
      </el-tab-pane>
      <el-tab-pane label="订单" name="order" class="checkbox-group">
        <div v-for="(order, index) in ordersData" :key="index" class="order-wrap">
          <div class="line-height28 order-link" @click="handleOrderDetail(order.order_id)">订单编号：{{ order.order_number }}</div>
          <div class="line-height28">
            下单时间：{{ order.place_time }}
            <span class="order-link" @click="handleOrderDetail(order.order_id)">{{ order.status_chinese_representation }}</span>
          </div>
          <div v-for="(goods, gindex) in order.order_line_info_list" :key="gindex" class="flex-center" style="justify-content: start">
            <el-image class="goods-image" :src="goods.goods_sku_image_address" fit="contain" />
            <div style="flex: 0 1 70%">
              <div class="line-height28" type="text" style="max-width: 210px">
                {{ goods.goods_name }}
              </div>
              <div class="line-height28">规格：{{ goods.goods_sku_specification }}</div>
            </div>
          </div>
          <div class="line-height28" style="display: flex">
            <div style="margin-right: 5px">
              共 {{ order.order_line_info_list.length }} 件商品，实付
              <span v-if="order.pay_type === PAY_TYPE_VALUE_MAP['MONEY']" style="color: #f56c6c"> ￥{{ order.total_price }}</span>
              <span v-if="order.pay_type === PAY_TYPE_VALUE_MAP['POINTS']">{{ order.total_points }} 积分</span>
              <span v-if="order.pay_type === PAY_TYPE_VALUE_MAP['MIX_MONEY_POINTS']"
                >￥{{ order.total_price }}+{{ order.total_points || 0 }} 积分</span
              >
            </div>
            <el-popover placement="top" trigger="hover">
              <p>收货人：{{ order.shipping_info.receiver_name }}</p>
              <p>联系电话：{{ order.shipping_info.receiver_mobile_number }}</p>
              <p>收货地址：{{ getCompleteAddress(order) }}</p>
              <div style="text-align: right; margin: 0">
                <el-button
                  type="text"
                  @click="
                    handleSendAddress(
                      `收货人：${order.shipping_info.receiver_name}
联系电话：${order.shipping_info.receiver_mobile_number}
收货地址：${getCompleteAddress(order)}`
                    )
                  "
                >
                  发送地址
                </el-button>
              </div>
              <el-button slot="reference" type="text"> 收货地址 </el-button>
              <el-button slot="reference" type="text" @click="handleSendOrder(order)"> 发送订单 </el-button>
            </el-popover>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="商品" name="goods">
        <el-checkbox-group v-model="checkList" class="checkbox-group" @change="handleCheckboxChange">
          <el-checkbox v-for="(item, index) in goodsList" :key="index" class="goods-checkbox" :label="item.id">
            <div class="flex-center" style="justify-content: start">
              <el-image class="goods-image" :src="item.cover_image_address" fit="contain" />
              <!-- <el-button type="text" style="max-width: 210px;">{{ item.spu_name }}</el-button> -->
              <div style="flex: 0 1 70%">
                <div type="text" style="max-width: 210px">
                  {{ item.name }}
                </div>
                <div v-if="item.pay_type === PAY_TYPE_VALUE_MAP['MONEY']" style="color: #f56c6c; margin: 3px">￥{{ item.min_price || 0 }}</div>
                <div v-if="item.pay_type === PAY_TYPE_VALUE_MAP['POINTS']" style="color: #f56c6c; margin: 3px">{{ item.min_points || 0 }}积分</div>
                <div v-if="item.pay_type === PAY_TYPE_VALUE_MAP['MIX_MONEY_POINTS']" style="color: #f56c6c; margin: 3px">
                  ￥{{ item.min_price || 0 }}+{{ item.min_points || 0 }}积分
                </div>
                <div style="display: flex; justify-content: space-between; color: #909399">
                  <div>库存：{{ item.stock_num }}</div>
                  <div>销量：{{ item.sale_num }}</div>
                </div>
              </div>
            </div>
          </el-checkbox>
        </el-checkbox-group>

        <div>
          <el-button type="primary" @click="handleSubmitGoods"> 确定 </el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CustomerInfo from "./components/CustomerInfo.vue";
import api from "@/api";
import { CUSTOMER_SERVICE, GOODS } from "@/constants";

const { MESSAGE_TYPE_VALUE_MAP } = CUSTOMER_SERVICE;
const { PAY_TYPE_VALUE_MAP } = GOODS;

export default {
  components: {
    CustomerInfo,
  },
  data() {
    return {
      activeTab: "profile",
      checkList: [],
      goodsList: [],
      selectedGoods: [],
      ordersData: [],
      PAY_TYPE_VALUE_MAP,
    };
  },
  computed: {
    ...mapState({
      currentRoom: (state) => state.chat.currentRoom,
    }),
  },
  watch: {
    currentRoom: function () {
      this.activeTab = "profile";
    },
  },
  created() {
    this.getGoodsList();
  },
  methods: {
    getCompleteAddress(order) {
      return `${order.shipping_info.shipping_address_province}${order.shipping_info.shipping_address_city}${order.shipping_info.shipping_address_district}${order.shipping_info.shipping_address_street_address}`;
    },
    handleSendAddress(address) {
      const messageObj = {
        msg_type: MESSAGE_TYPE_VALUE_MAP["TEXT"],
        text: address,
        img_url: null,
        session_id: undefined, // ChatWindow.vue里面加上
        order_no: null,
        spu_code: null,
      };
      this.$emit("send-message", messageObj);
    },
    handleSendOrder(order) {
      const messageObj = {
        msg_type: MESSAGE_TYPE_VALUE_MAP["ORDER"],
        text: null,
        img_url: order.order_line_info_list[0].goods_sku_image_address,
        session_id: undefined, // ChatWindow.vue里面加上
        order_no: order.order_number,
        spu_code: null,
      };
      this.$emit("send-message", messageObj);
    },
    handleOrderDetail(order_id) {
      this.$router.push({ path: "/order/order_detail", query: { orderId: order_id, source: "order_mange" } });
    },
    async handleTabClick(tabInstance) {
      if (tabInstance.name === "order") {
        const [, res] = await api.order.orders({
          params: {
            page: 1,
            size: 100,
            open_id: this.currentRoom.openId,
          },
        });
        this.ordersData = res?.list ?? [];
      }
    },
    handleSubmitGoods() {
      if (this.selectedGoods.length > 3) return this.$message.warning("最多选择可选择 3 个商品");

      this.selectedGoods.forEach((item) => {
        const messageObj = {
          msg_type: MESSAGE_TYPE_VALUE_MAP["GOODS"],
          text: "",
          img_url: item.cover_image_address,
          session_id: undefined, // ChatWindow.vue里面加上
          order_no: null,
          spu_code: item.code,
        };
        this.$emit("send-message", messageObj);
      });

      this.checkList = [];
    },
    async getGoodsList() {
      const [, res] = await api.goods.goods({
        params: {
          page: 1,
          size: 200,
        },
      });

      this.goodsList = res.list;
    },
    handleCheckboxChange(checkedList) {
      if (checkedList.length > 3) {
        this.$message.warning("最多选择可选择 3 个商品");
        this.checkList.pop();
        return;
      }
      this.selectedGoods = this.goodsList.filter((item) => checkedList.includes(item.id));
    },
  },
};
</script>

<style scoped>
.room-right-header {
  height: 64px;
  background: var(--chat-header-bg-color);
}
.tabs-class {
  height: calc(100vh - 205px);
  border: none;
  /* border-top: 1px solid #e1e4e8; */
}
.checkbox-group {
  height: calc(100vh - 305px);
  overflow: scroll;
}
.goods-checkbox {
  display: flex;
  align-items: center;
  padding: 5px;
  border-bottom: 1px solid #f2f6fc;
}
.goods-checkbox ::v-deep .el-checkbox__label {
  width: 100%;
}
.goods-image {
  max-width: 80px;
  height: 80px;
  margin-right: 10px;
  flex: 0 0 30%;
}
.order-wrap {
  padding: 5px 0;
  border-bottom: 3px solid #e4e7ed;
  font-size: 14px;
}
.line-height28 {
  line-height: 28px;
}
.order-link {
  color: #409eff;
}
.order-link:hover {
  cursor: pointer;
}
</style>
