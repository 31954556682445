<template>
  <el-dialog title="设置语言" width="480px" :visible.sync="computedVisible" v-bind="$attrs" @open="handleOpen">
    <el-form>
      <el-form-item v-for="(first, index) in languages" :key="first.code" class="flex" :label="`第${index + 1}种语言: `">
        <el-select
          v-model="selectedLanguage[index]"
          clearable
          class="flex-grow"
          :disabled="disabledFirst(index)"
          @change="(val) => handleChange(val, index)"
        >
          <el-option
            v-for="second in languages"
            :key="second.language_type"
            :disabled="disabledOption(second)"
            :value="second.language_type"
            :label="second.cn_name"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import api from "@/api";
import { Message } from "element-ui";
import { computed, onMounted, ref } from "vue";

export default {
  props: {
    visible: {
      type: Boolean,
    },
    defaultSelectedLanguage: {
      type: Array,
      default: () => ["zh-CN"],
    },
  },
  emits: ["confirm"],
  setup(props, { emit }) {
    const computedVisible = computed({
      get: () => props.visible,
      set: (val) => emit("update:visible", val),
    });
    // 第一语种默认简体中文，不可修改
    const disabledFirst = (index) => index === 0;
    const disabledOption = (option) => selectedLanguage.value.includes(option.language_type);
    const selectedLanguage = ref([]);

    function handleChange(val, index) {
      selectedLanguage.value[index] = val;
    }

    async function handleConfirm() {
      await handleSettingLanguages();
      emit("confirm", [...selectedLanguage.value]);
      handleClose();
    }

    async function handleSettingLanguages() {
      const [err] = await api.multiLanguage.setting({
        method: "post",
        data: {
          languages: selectedLanguage.value
            .map((item, index) => {
              if (item) return { language_type: item, language_sort: index + 1 };
            })
            .filter(Boolean),
        },
      });

      if (err) throw err;
      Message.success("设置成功");
    }

    function handleClose() {
      computedVisible.value = false;
    }

    const languages = ref([]);
    async function getAllLanguage() {
      const [err, res] = await api.multiLanguage.getAllLanguage();
      if (err) return;
      languages.value = res;
    }

    function handleOpen() {
      selectedLanguage.value = [...props.defaultSelectedLanguage];
    }

    onMounted(() => {
      getAllLanguage();
    });

    return {
      handleOpen,
      languages,
      computedVisible,
      props,
      emit,
      disabledFirst,
      disabledOption,
      selectedLanguage,
      handleChange,
      handleConfirm,
      handleClose,
    };
  },
};
</script>

<style lang="scss" scoped></style>
