<template>
  <div ref="imageRef" class="vac-image-container">
    <loader :style="{ top: `${imageResponsive.loaderTop}px` }" :show="isImageLoading" />
    <div>
      <el-image
        :class="{
          'vac-image-loading': isImageLoading && message.senderId === currentUserId,
        }"
        :style="{
          'background-image': `url('${imageBackground}')`,
          // 'max-height': `${imageResponsive.maxHeight}px`,
        }"
        fit="scale-down"
        :src="formattedImageUrl || message.file.url"
        :preview-src-list="srcList"
        @click.stop="handleClickItem"
      />

      <transition name="vac-fade-image">
        <div v-if="imageHover && !isImageLoading" class="vac-image-buttons">
          <div class="vac-svg-button vac-button-view" @click.stop="$emit('open-file', 'preview')">
            <slot name="eye-icon">
              <svg-icon name="eye" />
            </slot>
          </div>
          <div class="vac-svg-button vac-button-download" @click.stop="$emit('open-file', 'download')">
            <slot name="document-icon">
              <svg-icon name="document" />
            </slot>
          </div>
        </div>
      </transition>
    </div>
    <format-message
      :content="message.content"
      :users="roomUsers"
      :text-formatting="textFormatting"
      :link-options="linkOptions"
      @open-user-tag="$emit('open-user-tag')"
    >
      <template v-for="(i, name) in $scopedSlots" #[name]="data">
        <slot :name="name" v-bind="data" />
      </template>
    </format-message>
  </div>
</template>

<script>
import Loader from "../../components/Loader";
import SvgIcon from "../../components/SvgIcon";
import FormatMessage from "../../components/FormatMessage";
import api from "@/api";

const { isImageFile } = require("../../utils/media-file");

export default {
  name: "MessageImage",
  components: { SvgIcon, Loader, FormatMessage },

  props: {
    currentUserId: { type: [String, Number], required: true },
    message: { type: Object, required: true },
    roomUsers: { type: Array, required: true },
    textFormatting: { type: Boolean, required: true },
    linkOptions: { type: Object, required: true },
    imageHover: { type: Boolean, required: true },
  },

  data() {
    return {
      imageLoading: false,
      imageResponsive: "",
      formattedImageUrl: null,
    };
  },

  computed: {
    isImageLoading() {
      return this.imageLoading;
      // return this.message.file.url?.indexOf("blob:http") !== -1 || this.imageLoading;
    },
    imageBackground() {
      return this.isImageLoading ? this.message.file.preview || this.formattedImageUrl : this.formattedImageUrl;
    },
    srcList() {
      return [this.formattedImageUrl];
    },
  },

  watch: {
    message: {
      immediate: true,
      handler() {
        this.checkImgLoad();
      },
    },
  },

  mounted() {
    this.imageResponsive = {
      maxHeight: this.$refs.imageRef.clientWidth - 18,
      loaderTop: this.$refs.imageRef.clientWidth / 2,
    };
  },

  methods: {
    async getImageUrl(url) {
      if (!url?.startsWith("http://mmbiz.qpic.cn")) return url;
      const [err, blobData] = await api.customerService.forwardImage({
        responseType: "blob",
        params: {
          url,
        },
      });
      if (err) return url;
      const blob = new Blob([blobData], { type: blobData.type });
      return URL.createObjectURL(blob);
    },
    // 点击遮罩关闭预览
    handleClickItem() {
      this.$nextTick(() => {
        // 获取遮罩层dom
        let domImageMask = document.querySelector(".el-image-viewer__mask");
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener("click", () => {
          // 点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector(".el-image-viewer__close").click();
        });
      });
    },
    async checkImgLoad() {
      if (!isImageFile(this.message.file) || !this.message.file.url) return;
      this.imageLoading = true;
      const image = new Image();
      image.src = await this.getImageUrl(this.message.file.url);
      this.formattedImageUrl = image.src;
      image.addEventListener("load", () => (this.imageLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.vac-image-container {
  width: 250px;
  max-width: 100%;
}

.vac-image-loading {
  filter: blur(3px);
}

.vac-image-buttons {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 55%,
    rgba(0, 0, 0, 0.02) 60%,
    rgba(0, 0, 0, 0.05) 65%,
    rgba(0, 0, 0, 0.1) 70%,
    rgba(0, 0, 0, 0.2) 75%,
    rgba(0, 0, 0, 0.3) 80%,
    rgba(0, 0, 0, 0.5) 85%,
    rgba(0, 0, 0, 0.6) 90%,
    rgba(0, 0, 0, 0.7) 95%,
    rgba(0, 0, 0, 0.8) 100%
  );

  svg {
    height: 26px;
    width: 26px;
  }

  .vac-button-view,
  .vac-button-download {
    position: absolute;
    bottom: 6px;
    left: 7px;
  }

  :first-child {
    left: 40px;
  }

  .vac-button-view {
    max-width: 18px;
    bottom: 8px;
  }
}
</style>
