var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "container flex items-center justify-center",
      },
      [
        _c("div", {
          staticStyle: {
            height: "100px",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }