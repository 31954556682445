var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "page-padding",
    },
    [
      _c(
        "div",
        {
          staticClass: "page-header--wrap",
        },
        [
          _c(
            "div",
            {
              staticClass: "page-header-wrap--title",
            },
            [_vm._v("自提地址管理")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                round: "",
              },
              on: {
                click: _vm.handleAdd,
              },
            },
            [_vm._v("新 增")]
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "page-filter--wrap",
        },
        [
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [_vm._v("状态")]
          ),
          _c(
            "el-select",
            {
              model: {
                value: _vm.searchForm.status,
                callback: function callback($$v) {
                  _vm.$set(_vm.searchForm, "status", $$v)
                },
                expression: "searchForm.status",
              },
            },
            [
              _c("el-option", {
                attrs: {
                  value: undefined,
                  label: "全部",
                },
              }),
              _c("el-option", {
                attrs: {
                  value: 1,
                  label: "启用",
                },
              }),
              _c("el-option", {
                attrs: {
                  value: 0,
                  label: "禁用",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [_vm._v("联系人")]
          ),
          _c("el-input", {
            staticStyle: {
              width: "150px",
            },
            attrs: {
              clearable: "",
            },
            model: {
              value: _vm.searchForm.contact_name,
              callback: function callback($$v) {
                _vm.$set(_vm.searchForm, "contact_name", $$v)
              },
              expression: "searchForm.contact_name",
            },
          }),
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [_vm._v("联系人电话")]
          ),
          _c("el-input", {
            staticStyle: {
              width: "150px",
            },
            attrs: {
              clearable: "",
            },
            model: {
              value: _vm.searchForm.contact_phone_number,
              callback: function callback($$v) {
                _vm.$set(_vm.searchForm, "contact_phone_number", $$v)
              },
              expression: "searchForm.contact_phone_number",
            },
          }),
          _c(
            "el-button",
            {
              staticStyle: {
                "margin-left": "20px",
              },
              attrs: {
                round: "",
                icon: "el-icon-refresh",
              },
              on: {
                click: _vm.resetSearchForm,
              },
            },
            [_vm._v(" 重置 ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                round: "",
                icon: "el-icon-search",
              },
              on: {
                click: _vm.getList,
              },
            },
            [_vm._v(" 搜索 ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: {
            width: "100%",
          },
          attrs: {
            data: _vm.addressData,
            stripe: "",
            border: "",
            "highlight-current-row": "",
          },
        },
        [
          _c(
            "el-table-column",
            {
              attrs: {
                type: "index",
                align: "center",
                width: "60",
              },
            },
            [
              _c(
                "template",
                {
                  slot: "header",
                },
                [_vm._v(" 序号 ")]
              ),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "remark_time",
              label: "操作",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref) {
                  var row = _ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          disabled: row.status === 1,
                        },
                        on: {
                          click: function click($event) {
                            return _vm.handleChangeStatus(row.id, true)
                          },
                        },
                      },
                      [_vm._v("启用")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          disabled: row.status === 0,
                        },
                        on: {
                          click: function click($event) {
                            return _vm.handleChangeStatus(row.id, false)
                          },
                        },
                      },
                      [_vm._v("禁用")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                        },
                        on: {
                          click: function click($event) {
                            return _vm.handleOpenEditDialog(row)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "status",
              label: "状态",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref2) {
                  var row = _ref2.row
                  return [
                    row.status === 1
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "success",
                            },
                          },
                          [_vm._v("启用")]
                        )
                      : _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "danger",
                            },
                          },
                          [_vm._v("禁用")]
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "省市区",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref3) {
                  var row = _ref3.row
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(row.province) +
                          " " +
                          _vm._s(row.city) +
                          " " +
                          _vm._s(row.district)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "street_address",
              label: "详细地址",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "contact_name",
              label: "联系人",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "contact_phone_number",
              label: "联系电话",
            },
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function updatePage($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function updateLimit($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c("pickupAddressDialog", {
        ref: "pickupAddressDialogRef",
        on: {
          refresh: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }