var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: "欢迎语",
      },
      on: {
        open: _vm.handleOpen,
        close: _vm.close,
      },
    },
    [
      _c("el-input", {
        attrs: {
          type: "textarea",
          placeholder: "请输入回复文案",
          autosize: {
            minRows: 8,
            maxRows: 12,
          },
          maxlength: "500",
          "show-word-limit": "",
        },
        model: {
          value: _vm.greetingText,
          callback: function callback($$v) {
            _vm.greetingText = $$v
          },
          expression: "greetingText",
        },
      }),
      _c(
        "div",
        {
          staticClass: "flex justify-center",
          attrs: {
            slot: "footer",
          },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function click($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v(" 取 消 ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
              },
              on: {
                click: _vm.handleConfirm,
              },
            },
            [_vm._v(" 确 定 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }