<template>
  <div class="h-full w-full flex justify-center">
    <img class="max-h-[50%] max-w-[40%] object-contain mt-[10vh]" src="@/assets/welcome.png" alt="404" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
