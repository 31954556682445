var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _c(
      "div",
      {
        staticClass: "vac-audio-player",
      },
      [
        _c(
          "div",
          {
            staticClass: "vac-svg-button",
            on: {
              click: _vm.playback,
            },
          },
          [
            _vm.isPlaying
              ? _vm._t("audio-pause-icon", function () {
                  return [
                    _c("svg-icon", {
                      attrs: {
                        name: "audio-pause",
                      },
                    }),
                  ]
                })
              : _vm._t("audio-play-icon", function () {
                  return [
                    _c("svg-icon", {
                      attrs: {
                        name: "audio-play",
                      },
                    }),
                  ]
                }),
          ],
          2
        ),
        _c("audio-control", {
          attrs: {
            percentage: _vm.progress,
          },
          on: {
            "change-linehead": _vm.onUpdateProgress,
            "hover-audio-progress": function hoverAudioProgress($event) {
              return _vm.$emit("hover-audio-progress", $event)
            },
          },
        }),
        _c("audio", {
          attrs: {
            id: _vm.playerUniqId,
            src: _vm.audioSource,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }