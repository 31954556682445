<template>
  <div class="page-padding">
    <div class="page-header--wrap">
      <div class="page-header-wrap--title">自提地址管理</div>
      <el-button type="primary" round @click="handleAdd">新 增</el-button>
    </div>
    <el-row class="page-filter--wrap">
      <div class="page-color--normal">状态</div>
      <el-select v-model="searchForm.status">
        <el-option :value="undefined" label="全部"></el-option>
        <el-option :value="1" label="启用"></el-option>
        <el-option :value="0" label="禁用"></el-option>
      </el-select>
      <div class="page-color--normal">联系人</div>
      <el-input v-model="searchForm.contact_name" style="width: 150px" clearable></el-input>
      <div class="page-color--normal">联系人电话</div>
      <el-input v-model="searchForm.contact_phone_number" style="width: 150px" clearable></el-input>
      <el-button round style="margin-left: 20px" icon="el-icon-refresh" @click="resetSearchForm"> 重置 </el-button>
      <el-button type="primary" round icon="el-icon-search" @click="getList"> 搜索 </el-button>
    </el-row>
    <el-table v-loading="listLoading" :data="addressData" stripe border highlight-current-row style="width: 100%">
      <el-table-column type="index" align="center" width="60">
        <template slot="header"> 序号 </template>
      </el-table-column>

      <el-table-column align="center" prop="remark_time" label="操作">
        <template slot-scope="{ row }">
          <el-button type="text" :disabled="row.status === 1" @click="handleChangeStatus(row.id, true)">启用</el-button>
          <el-button type="text" :disabled="row.status === 0" @click="handleChangeStatus(row.id, false)">禁用</el-button>
          <el-button type="text" @click="handleOpenEditDialog(row)"> 编辑 </el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="status" label="状态" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <el-tag v-if="row.status === 1" type="success">启用</el-tag>
          <el-tag v-else type="danger">禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="省市区" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <div>{{ row.province }} {{ row.city }} {{ row.district }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="street_address" label="详细地址" show-overflow-tooltip />
      <el-table-column align="center" prop="contact_name" label="联系人" />
      <el-table-column align="center" prop="contact_phone_number" label="联系电话" />
    </el-table>
    <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    <pickupAddressDialog ref="pickupAddressDialogRef" @refresh="getList"></pickupAddressDialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import TableListMixin from "@/mixin/TableList";
import api from "@/api";
import * as R from "ramda";
import pickupAddressDialog from "./coponents/pickup-address-dialog.vue";

const initFormData = {
  contact_name: "",
  contact_phone_number: "",
  status: undefined,
};
export default {
  name: "PickupAddressManage",
  components: { Pagination, pickupAddressDialog },
  mixins: [TableListMixin],
  data() {
    return {
      searchForm: R.clone(initFormData),
      addressData: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleAdd() {
      this.$refs.pickupAddressDialogRef.handleOpenDialog();
    },
    handleOpenEditDialog(row) {
      this.$refs.pickupAddressDialogRef.handleOpenDialog(row);
    },
    async handleChangeStatus(id, newStatus) {
      const [err] = await api.settings.pickUpAddress({ method: "put", id, resource: "status", data: newStatus });
      if (err) return this.$message.error(err.message);
      this.$message.success("更新成功");
      this.getList();
    },
    resetSearchForm() {
      this.searchForm.keyword = undefined;
      this.getList();
    },
    async getList() {
      // 获取数据
      const [, res] = await this.getListMixin(api.settings.pickUpAddress);
      this.addressData = res.list ?? [];
    },
  },
};
</script>

<style lang="scss">
.panel-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: unset;
  padding-right: 4px;
}
</style>
