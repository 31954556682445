<template>
  <div>
    <el-form ref="form" label-width="100px">
      <el-form-item label="">
        <el-avatar :size="50" :src="currentRoom.avatar" />
      </el-form-item>
      <el-form-item label="昵称：">
        {{ currentRoom.roomName | formatEmptyString }}
      </el-form-item>
      <el-form-item label="手机号：">
        {{ currentRoom.phone | formatEmptyString }}
      </el-form-item>
      <el-form-item label="会员卡号：">
        {{ currentRoom.memberCardNum | formatEmptyString }}
      </el-form-item>
      <el-form-item label="会员等级：">
        {{ currentRoom.levelName | formatEmptyString }}
      </el-form-item>
      <el-form-item label="地区：">
        {{ currentRoom.district | formatEmptyString }}
      </el-form-item>
      <el-form-item label="备注："> - </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  filters: {
    formatEmptyString(value) {
      return value || "-";
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      currentRoom: (state) => state.chat.currentRoom,
    }),
  },
};
</script>

<style></style>
