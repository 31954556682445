/**
 * 格式化时间戳 yyyy-MM-dd HH:mm:ss
 * @param {Date}
 * @returns  {yyyy-MM-dd HH:mm:ss}
 */

export function formatTime(date) {
  let t = new Date(date);
  let y = String(t.getFullYear());
  let mon = String(t.getMonth() + 1).padStart(2, "0");
  let d = String(t.getDate()).padStart(2, "0");
  let h = String(t.getHours()).padStart(2, "0");
  let m = String(t.getMinutes()).padStart(2, "0");
  let s = String(t.getSeconds()).padStart(2, "0");
  return `${y}-${mon}-${d} ${h}:${m}:${s}`;
}

/**
 * 获取昨日日期
 * @returns {yyyy-MM-dd}
 */
export function getYesterdayDate() {
  let t = new Date();
  t.setTime(t.getTime() - 24 * 60 * 60 * 1000);
  let y = String(t.getFullYear());
  let mon = String(t.getMonth() + 1).padStart(2, "0");
  let d = String(t.getDate()).padStart(2, "0");
  return `${y}-${mon}-${d}`;
}

/**
 * 获取今日日期
 * @returns {yyyy-MM-dd}
 */
export function getTodayDate() {
  let t = new Date();
  let y = String(t.getFullYear());
  let mon = String(t.getMonth() + 1).padStart(2, "0");
  let d = String(t.getDate()).padStart(2, "0");
  return `${y}-${mon}-${d}`;
}

/**
 * 获取明日日期
 * @returns {yyyy-MM-dd}
 */
export function getTomorrowDate() {
  let t = new Date();
  t.setTime(t.getTime() + 24 * 60 * 60 * 1000);
  let y = String(t.getFullYear());
  let mon = String(t.getMonth() + 1).padStart(2, "0");
  let d = String(t.getDate()).padStart(2, "0");
  return `${y}-${mon}-${d}`;
}

/**
 * 获取当前月份
 * @returns {yyyy-MM}
 */
export function getCurrentMonth() {
  let t = new Date();
  let y = String(t.getFullYear());
  let mon = String(t.getMonth() + 1).padStart(2, "0");
  return `${y}-${mon}`;
}

/**
 * 获取近n天
 * @returns {yyyy-MM-dd}
 */
export function getNDay(day) {
  let t = new Date();
  t.setTime(t.getTime() + 24 * 60 * 60 * 1000 * day);
  let y = String(t.getFullYear());
  let mon = String(t.getMonth() + 1).padStart(2, "0");
  let d = String(t.getDate()).padStart(2, "0");
  return `${y}-${mon}-${d}`;
}

/**
 * 格式化时长
 * @param {Number} time 时长（秒）
 * @returns {String}
 */
export function formatDuration(time) {
  // 一分钟内
  if (time <= 60) return `${time}秒`;
  // 一小时内
  if (time < 3600) {
    const minute = Math.floor(time / 60);
    const second = time % 60;
    return `${minute}分${second}秒`;
  }
  // 大于等于一小时
  if (time >= 3600) {
    if (time === 3600) return "1小时";
    const hour = Math.floor(time / 3600);
    const remain = time % 3600;
    if (remain < 60) return `${hour}小时${remain}秒`;
    return `${hour}小时${parseInt(remain / 60)}分`;
  }
}
