var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "el-table-column",
    _vm._b(
      {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function fn(_ref) {
              var row = _ref.row
              return [
                _vm._v(" " + _vm._s(_vm.format(row, _vm.$attrs.prop)) + " "),
              ]
            },
          },
        ]),
      },
      "el-table-column",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }