<template>
  <el-dialog :visible.sync="_visible" top="4%" width="60%" @open="handleOpen" @close="handleClose">
    <template #title>
      <section class="flex items-center">
        <span>选择/修改商品</span>
        <div class="title__condition">
          <el-select v-model="searchParams.categoryName" style="width: 200px">
            <el-option v-for="item in categoryList" :key="item.id" :value="item.name" :label="item.name" />
          </el-select>
          <el-input v-model="searchParams.goodsName" class="ml-[10px]" style="width: 200px" placeholder="搜索商品名称" />
        </div>
      </section>
    </template>
    <el-table ref="goodTable" row-key="id" :data="visibleGoodList" border :max-height="maxHeight" @selection-change="handleSelectionChange">
      <el-table-column reserve-selection type="selection" header-align="center" align="center" />
      <el-table-column label="商品名称" prop="name" header-align="center" align="center">
        <template slot-scope="scope">
          <div class="flex items-center">
            <img style="width: 100px; height: 100px" :src="scope.row.image_list[0]" />
            <span style="margin-left: 10px">{{ scope.row.name }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="商品分组" :formatter="formatCategoryName" header-align="center" align="center" />
      <el-table-column label="售价（元）" :formatter="formatPrice" header-align="center" align="center" />
    </el-table>
    <div class="mt-[10px]">
      已选商品<span style="color: red"> {{ multipleSelection.length }}</span>
    </div>
    <template #footer>
      <div class="flex justify-center">
        <el-button size="medium" @click="handleCancel"> 取消 </el-button>
        <el-button size="medium" type="primary" @click="handleConfirm"> 修改 </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import * as R from "ramda";
import api from "@/api";

export default {
  name: "InfoPopup",
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    goodsIdSet: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      categoryList: [],
      goodList: [],
      visibleGoodList: [],
      searchParams: {
        categoryName: "全部分组",
        goodsName: "",
      },
      maxHeight: 600,
      multipleSelection: [],
    };
  },
  computed: {
    _visible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  watch: {
    searchParams: {
      handler() {
        this.visibleGoodList = this.filterData(this.goodList);
      },
      deep: true,
    },
  },
  async mounted() {
    await this.fetchGoodCategoryList();
    await this.fetchGoodList();
    this.toggleSelection();
    this.maxHeight = window.innerHeight * 0.7;
  },
  methods: {
    toggleSelection() {
      this.$nextTick(() => {
        this.goodList.forEach((row) => {
          if (this.goodsIdSet.includes(row.id)) {
            this.$refs.goodTable?.toggleRowSelection(row, true);
          } else {
            this.$refs.goodTable?.toggleRowSelection(row, false);
          }
        });
      });
    },
    async fetchGoodCategoryList() {
      const [, categoryList] = await api.goods.goodsTags();
      categoryList.unshift({ id: "", name: "全部分组" });
      this.categoryList = categoryList;
    },
    formatCategoryName({ tag_set = [] } = {}) {
      if (!tag_set) return "-";
      return R.join("，", tag_set);
    },
    formatPrice({ goods_sku_info_list = [] } = {}) {
      const priceList = R.map(R.prop("retail_price"), goods_sku_info_list);
      let minPrice = R.reduce(R.min, Infinity, priceList).toFixed(2);
      let maxPrice = R.reduce(R.max, 0, priceList).toFixed(2);
      if (minPrice === maxPrice) return minPrice;
      return `${minPrice}~${maxPrice}`;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCancel() {
      this.$emit("update:visible", false);
    },
    handleOpen() {
      this.toggleSelection();
    },
    handleClose() {
      this.$refs.goodTable.clearSelection();
    },
    handleConfirm() {
      const selectedRow = this.$refs.goodTable.selection;
      this.$emit("confirm", selectedRow);
      this.handleCancel();
    },
    addCategoryName(rows) {
      return rows.map((item) => ({ ...item, categoryName: this.formatCategoryName(item) }));
    },
    async fetchGoodList() {
      const [, goodList] = await api.activity.searchGoods({
        params: this.searchParams,
      });
      this.visibleGoodList = this.goodList = goodList;
    },
    filterData(data) {
      this.visibleGoodList = data.filter(
        (row) =>
          row.name.includes(this.searchParams.goodsName) &&
          (this.searchParams.categoryName === "全部分组" || row.tag_set?.includes(this.searchParams.categoryName))
      );
      // 根据现有选择和显示的商品列表来决定是否清空选中状态
      return this.visibleGoodList;
    },
  },
};
</script>

<style lang="scss" scoped>
.title__condition {
  margin: 0 auto;
}
</style>
