<template>
  <el-dialog :title="`${rowId ? '编辑' : '新增'}自提地址`" :visible="visible" @close="close">
    <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
      <el-form-item label="省市区" required prop="province">
        <el-cascader
          v-if="dialogDistrictForm"
          ref="cities"
          v-model="selected_city"
          size="mini"
          :props="cascadeProps"
          style="width: 300px"
          @change="handleCityChange"
        />
      </el-form-item>
      <el-form-item label="详细地址" prop="street_address">
        <el-input v-model="formData.street_address" style="width: 400px" type="textarea" maxlength="30" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="联系人" prop="contact_name">
        <el-input v-model="formData.contact_name" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="联系人电话" prop="contact_phone_number">
        <el-input v-model="formData.contact_phone_number" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="是否启用" required>
        <el-switch v-model="formData.status" :active-value="1" :inactive-value="0"> </el-switch>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button type="text" @click="close"> 取消 </el-button>
      <el-button type="primary" @click="handleDialogConfirm">
        {{ dialogStatus == "update" ? "修改" : "确定" }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import api from "@/api";
import { commonUtil } from "@/utils/index";
const { to } = commonUtil;
import pick from "ramda/src/pick";

const initFormData = {
  province: "",
  province_code: "",
  city: "",
  city_code: "",
  district: "",
  district_code: "",

  street_address: "",
  contact_name: "",
  contact_phone_number: "",

  status: 1,
};

const rules = {
  province: [{ required: true, message: "请选择省市区", trigger: "change" }],
  street_address: [{ required: true, message: "请填写详细地址", trigger: "blur" }],
  contact_name: [{ required: true, message: "请填写联系人", trigger: "blur" }],
  contact_phone_number: [{ required: true, message: "请填写联系人电话", trigger: "blur" }],
};
export default {
  name: "PickupAddressDialog",
  data() {
    return {
      dialogStatus: "create",
      formData: { ...initFormData },
      rowId: undefined,
      visible: false,
      dialogDistrictForm: true,
      selected_city: [],
      cascadeProps: {
        lazy: true,
        label: "name",
        value: "code",
        async lazyLoad(node, resolve) {
          const code = node?.data?.code;
          const [, areaList = []] = await api.settings.area({
            id: code ? code : null,
            resource: "children",
          });
          resolve([...areaList.map((item) => ({ ...item, leaf: node.level >= 2 }))]);
        },
      },
      rules,
    };
  },
  methods: {
    initFormData(row) {
      if (!row) return;
      this.formData = { ...initFormData, ...pick(Object.keys(initFormData), row) };
      let { province_code, city_code, district_code } = row;
      this.selected_city = Array.from(new Set([province_code, city_code, district_code]));
      this.rowId = row.id;
    },
    handleOpenDialog(row) {
      this.initFormData(row);
      this.visible = true;
      this.dialogDistrictForm = true;
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    handleCityChange() {
      const [lastNode] = this.$refs.cities.getCheckedNodes(false);
      const pathsNodes = lastNode.pathNodes;
      let { formData } = this;
      const formKeyNames = ["province", "city", "district"];

      for (let i = 0; i <= 2; i++) {
        const node = pathsNodes[i]?.data ?? { name: "全部", code: "000000" };
        const keyName = formKeyNames[i];
        [formData[keyName], formData[`${keyName}_code`]] = [node.name, node.code];
      }
    },
    async handleDialogConfirm() {
      const [, isValid] = await to(this.$refs.form.validate());
      if (!isValid) return;
      const [err] = await api.settings.pickUpAddress({
        id: this.rowId,
        method: this.rowId ? "put" : "post",
        data: this.formData,
      });

      if (err) return this.$message.error(err);

      this.$message.success(this.rowId ? "更新成功" : "添加成功");
      this.$emit("refresh");
      this.close();
    },
    resetFormData() {
      this.dialogDistrictForm = false;
      this.formData = { ...initFormData };
      this.selected_city = [];
      if (this.$refs.cities) {
        // https://github.com/ElemeFE/element/issues/18669
        this.$refs.cities.$refs.panel.checkedValue = [];
        this.$refs.cities.$refs.panel.activePath = [];
        this.$refs.cities.$refs.panel.syncActivePath();
      }
      this.rowId = undefined;
    },
    close() {
      this.visible = false;
      this.resetFormData();
    },
  },
};
</script>
