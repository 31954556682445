var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "section",
    {
      staticClass: "filter-item flex items-center",
    },
    [
      _vm.label
        ? _c(
            "div",
            {
              staticClass: "mx-[10px] text-[14px] text-[#606266] font-[500]",
            },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _vm._e(),
      _c("div", [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }