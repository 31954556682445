export function regularOrderText(val) {
  let map = {
    NOACTIVATE: "未激活",
    ACTIVATE: "已激活",
    FINISHED: "已完成",
  };
  return map[val];
}

// 发货单详情的发货状态
export function regularOrderDetailText(val) {
  let map = {
    NODELIVERY: "待发货",
    DELIVERY: "已发货",
    SKIP: "已跳过",
  };
  return map[val];
}
