var render = function render() {
  var _vm$detailData$coupon
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "sticky",
        {
          attrs: {
            "z-index": 20,
          },
        },
        [
          _c("el-page-header", {
            staticClass: "go-back",
            attrs: {
              content: "订单详情",
            },
            on: {
              back: _vm.goBack,
            },
          }),
        ],
        1
      ),
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.detailLoading,
              expression: "detailLoading",
            },
          ],
          staticClass: "box-card",
        },
        [
          _c(
            "div",
            {
              staticClass: "order-header",
              attrs: {
                slot: "header",
              },
              slot: "header",
            },
            [
              _c(
                "div",
                {
                  staticClass: "header-item",
                },
                [_vm._v("订单号：" + _vm._s(_vm.detailData.order_number))]
              ),
              _c(
                "div",
                {
                  staticClass: "header-item",
                },
                [_vm._v("下单时间：" + _vm._s(_vm.detailData.place_time))]
              ),
            ]
          ),
          _c(
            "div",
            [
              _c(
                "el-row",
                {
                  staticClass: "order-info",
                },
                [
                  _c(
                    "el-col",
                    {
                      attrs: {
                        span: 10,
                      },
                    },
                    [
                      _vm.detailData.status ===
                      _vm.ORDER_STATUS_VALUE_MAP["TO_BE_DELIVERED"]
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "order-status",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.detailData
                                        .status_chinese_representation
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "order-note",
                              },
                              [
                                _vm._v(
                                  "买家已付款至微信待结算账户，请尽快发货，否则买家有权申请退款。"
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  margin: "10px 0",
                                },
                              },
                              [
                                !_vm.isGroupMall
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                        },
                                        on: {
                                          click: _vm.handleDispatchOrder,
                                        },
                                      },
                                      [_vm._v(" 发货 ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "text",
                                    },
                                    on: {
                                      click: _vm.handleAddNote,
                                    },
                                  },
                                  [_vm._v(" 备注 ")]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm.detailData.status ===
                      _vm.ORDER_STATUS_VALUE_MAP["DELIVERING"]
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "order-status",
                              },
                              [_vm._v("已发货")]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  margin: "10px 0",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                    },
                                    on: {
                                      click: _vm.handleUpdateExpress,
                                    },
                                  },
                                  [_vm._v(" 修改物流 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "text",
                                    },
                                    on: {
                                      click: _vm.handleAddNote,
                                    },
                                  },
                                  [_vm._v(" 备注 ")]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      attrs: {
                        span: 14,
                      },
                    },
                    [
                      _c(
                        "el-steps",
                        {
                          attrs: {
                            space: 200,
                            active: _vm._f("activeNum")(_vm.detailData.status),
                            "process-status": "success",
                            "finish-status": "success",
                            "align-center": "",
                          },
                        },
                        _vm._l(
                          _vm.detailData.operation_log_list,
                          function (item, index) {
                            return _c("el-step", {
                              key: index,
                              attrs: {
                                title: item.operation,
                                description: item.time,
                              },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.hasNotDeliverInfoOrIsPickUp()
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        margin: "0 0 20px",
                        "min-height": "200px",
                        "font-size": "16px",
                      },
                    },
                    [
                      !_vm.hasDeliverInfo()
                        ? _c(
                            "div",
                            {
                              staticClass: "row-info",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "label",
                                },
                                [_vm._v("物流状态：")]
                              ),
                              _c("div", [_vm._v("暂无物流信息")]),
                            ]
                          )
                        : _vm._e(),
                      _vm.detailData.deliver_method ===
                      _vm.DELIVER_METHOD_VALUE_MAP["CUSTOMER_PICK_UP"]
                        ? _c(
                            "div",
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "row-info",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "label",
                                    },
                                    [_vm._v("自提码：")]
                                  ),
                                  _c("div", [
                                    _vm._v(_vm._s(_vm.detailData.pick_up_code)),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "row-info",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "label",
                                      staticStyle: {
                                        "align-self": "baseline",
                                      },
                                    },
                                    [_vm._v("自提地址：")]
                                  ),
                                  _c("div", [
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.detailData.shipping_info
                                              .shipping_address_street_address
                                          )
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "ml-[4px]",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.detailData.shipping_info
                                                .shipping_address_province
                                            ) +
                                              " " +
                                              _vm._s(
                                                _vm.detailData.shipping_info
                                                  .shipping_address_city
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.detailData.shipping_info
                                                  .shipping_address_district
                                              )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.detailData.shipping_info
                                            .receiver_name || "-"
                                        ) +
                                          " " +
                                          _vm._s(
                                            _vm.detailData.shipping_info
                                              .receiver_mobile_number || "-"
                                          )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.detailData.status !==
                                      _vm.ORDER_STATUS_VALUE_MAP[
                                        "TO_BE_PICKED_UP"
                                      ],
                                    type: "primary",
                                  },
                                  on: {
                                    click: _vm.handlePickedUp,
                                  },
                                },
                                [_vm._v(" 自提核销 ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm.detailData.delivery_sheet_info_list &&
              _vm.detailData.delivery_sheet_info_list.length
                ? _c(
                    "el-tabs",
                    {
                      staticStyle: {
                        "min-height": "200px",
                      },
                      attrs: {
                        type: "card",
                      },
                      model: {
                        value: _vm.activePackage,
                        callback: function callback($$v) {
                          _vm.activePackage = $$v
                        },
                        expression: "activePackage",
                      },
                    },
                    _vm._l(
                      _vm.detailData.delivery_sheet_info_list || [],
                      function (delivery_sheet_info, index) {
                        return _c(
                          "el-tab-pane",
                          {
                            key: index,
                            staticStyle: {
                              margin: "0 0 10px 20px",
                            },
                            attrs: {
                              label: "\u5305\u88F9".concat(index + 1),
                              name: "\u5305\u88F9".concat(index + 1),
                            },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticClass:
                                      "order-info-wrap order-packages",
                                    attrs: {
                                      span: 6,
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "order-info-item",
                                        },
                                        [
                                          _vm._v(
                                            " 运单号： " +
                                              _vm._s(
                                                ""
                                                  .concat(
                                                    delivery_sheet_info.logistics_company_name,
                                                    " "
                                                  )
                                                  .concat(
                                                    delivery_sheet_info.tracking_number
                                                  )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "packages-title",
                                        staticStyle: {
                                          margin: "0",
                                        },
                                      },
                                      _vm._l(
                                        delivery_sheet_info.delivery_sheet_item_list,
                                        function (delivery_item, _index) {
                                          return _c(
                                            "div",
                                            {
                                              key: _index,
                                              staticStyle: {
                                                "margin-right": "5px",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  width: "80px",
                                                  margin: "10px 5px",
                                                },
                                                attrs: {
                                                  src: delivery_item.image_address,
                                                },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "fs12 deliveryItem",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(delivery_item.name)
                                                  ),
                                                  _c("strong", [
                                                    _vm._v(
                                                      " x" +
                                                        _vm._s(
                                                          delivery_item.num
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    staticClass:
                                      "order-info-wrap order-packages",
                                    attrs: {
                                      span: 12,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          margin: "0 0 20px 20px",
                                        },
                                      },
                                      [
                                        _c("span", [_vm._v("物流状态：")]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#f56c6c",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                delivery_sheet_info.state_str
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-timeline",
                                      {
                                        attrs: {
                                          reverse: true,
                                        },
                                      },
                                      _vm._l(
                                        delivery_sheet_info.route_node_list,
                                        function (route_node, _index) {
                                          return _c(
                                            "el-timeline-item",
                                            {
                                              key: _index,
                                              attrs: {
                                                timestamp: route_node.time,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(route_node.remark) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  )
                : _vm._e(),
              _c(
                "el-row",
                {
                  staticClass: "order-info",
                },
                [
                  _vm.detailData.deliver_method !==
                  _vm.DELIVER_METHOD_VALUE_MAP["CUSTOMER_PICK_UP"]
                    ? _c(
                        "el-col",
                        {
                          staticClass: "order-info-wrap",
                          attrs: {
                            span: 6,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "flex",
                            },
                            [
                              _c("h5", [_vm._v("收货人信息")]),
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    margin: "0 10px",
                                  },
                                  attrs: {
                                    type: "text",
                                  },
                                  on: {
                                    click: function click($event) {
                                      _vm.updateAddressDialogVisible = true
                                    },
                                  },
                                },
                                [_vm._v(" 修改 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                  },
                                  on: {
                                    click: function click($event) {
                                      return _vm.handleCopy("", $event)
                                    },
                                  },
                                },
                                [_vm._v(" 复制 ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "order-info-item",
                            },
                            [
                              _vm._v(
                                "收货人：" +
                                  _vm._s(_vm.shipping_info.receiver_name)
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "order-info-item",
                            },
                            [
                              _vm._v(
                                "联系电话：" +
                                  _vm._s(
                                    _vm.shipping_info.receiver_mobile_number
                                  )
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "order-info-item",
                            },
                            [_vm._v("收货地址：" + _vm._s(_vm.completeAddress))]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.detailData.deliver_method !==
                  _vm.DELIVER_METHOD_VALUE_MAP["CUSTOMER_PICK_UP"]
                    ? _c(
                        "el-col",
                        {
                          staticClass: "order-info-wrap",
                          attrs: {
                            span: 6,
                          },
                        },
                        [
                          _c("h5", [_vm._v("配送信息")]),
                          _c(
                            "div",
                            {
                              staticClass: "order-info-item",
                            },
                            [
                              _vm._v(
                                "配送方式： " +
                                  _vm._s(
                                    _vm.DELIVER_METHOD_VALUE_DIC[
                                      _vm.detailData.deliver_method
                                    ]
                                  )
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    {
                      staticClass: "order-info-wrap",
                      attrs: {
                        span: 6,
                      },
                    },
                    [
                      _c("h5", [_vm._v("付款信息")]),
                      _c(
                        "div",
                        {
                          staticClass: "order-info-item flex",
                        },
                        [
                          _vm._v(
                            "实付金额：" +
                              _vm._s(
                                _vm.formatPrice({
                                  price: _vm.detailData.total_price,
                                  point: _vm.detailData.total_points,
                                })
                              )
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "order-info-item",
                        },
                        [
                          _vm._v(
                            "付款方式：" +
                              _vm._s(
                                _vm.PAY_TYPE_VALUE_DIC[_vm.detailData.pay_type]
                              )
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "order-info-item",
                        },
                        [_vm._v("付款时间：" + _vm._s(_vm.detailData.pay_time))]
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "order-info-wrap",
                      attrs: {
                        span: 6,
                      },
                    },
                    [
                      _c("h5", [_vm._v("买家信息")]),
                      _c(
                        "div",
                        {
                          staticClass: "order-info-item",
                        },
                        [_vm._v("买家：" + _vm._s(_vm.detailData.buyer_name))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "order-info-item",
                        },
                        [
                          _vm._v(
                            "买家留言：" + _vm._s(_vm.detailData.buyer_remark)
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "order-info-item",
                        },
                        [
                          _vm._v(
                            "会员卡号：" +
                              _vm._s(
                                _vm.detailData.buyer_membership_card_number
                              )
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "order-info-wrap",
                      attrs: {
                        span: 6,
                      },
                    },
                    [
                      _c("h5", [_vm._v("商户订单号")]),
                      _c(
                        "div",
                        {
                          staticClass: "order-info-item",
                        },
                        [
                          _vm._v(
                            "内部支付订单号：" +
                              _vm._s(
                                _vm.detailData
                                  .internal_wechat_pay_order_number || ""
                              )
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "order-info-item",
                        },
                        [
                          _vm._v(
                            "微信支付订单号：" +
                              _vm._s(
                                _vm.detailData.wechat_pay_order_number || ""
                              )
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: {
                        width: "100%",
                      },
                      attrs: {
                        data: _vm.detailData.order_line_info_list,
                        border: "",
                        stripe: "",
                        "highlight-current-row": "",
                        "header-cell-style": {
                          background: "#eef1f6",
                          color: "#606266",
                        },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          width: "300",
                          align: "",
                          prop: "goods_name",
                          label: "商品名称",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function fn(_ref) {
                              var row = _ref.row
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex-center",
                                    staticStyle: {
                                      "justify-content": "start",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "imgSty",
                                      staticStyle: {
                                        width: "80px",
                                      },
                                      attrs: {
                                        src: row.goods_sku_image_address,
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          padding: "0 5px",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticStyle: {
                                              overflow: "hidden",
                                              "text-overflow": "ellipsis",
                                              "white-space": "nowrap",
                                            },
                                            attrs: {
                                              effect: "dark",
                                              content: row.goods_name,
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(_vm._s(row.goods_name)),
                                            ]),
                                          ]
                                        ),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(row.goods_sku_specification)
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "after_discount_price",
                          label: "单价",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function fn(_ref2) {
                              var row = _ref2.row
                              return [
                                row.goods_sku_unit_price &&
                                row.goods_sku_unit_points
                                  ? [
                                      _c("div", [
                                        _vm._v(
                                          "￥" +
                                            _vm._s(row.goods_sku_unit_price) +
                                            "+" +
                                            _vm._s(row.goods_sku_unit_points) +
                                            "积分"
                                        ),
                                      ]),
                                    ]
                                  : [
                                      row.goods_sku_unit_price
                                        ? _c("div", [
                                            _vm._v(
                                              "￥" +
                                                _vm._s(row.goods_sku_unit_price)
                                            ),
                                          ])
                                        : _vm._e(),
                                      row.goods_sku_unit_points
                                        ? _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                row.goods_sku_unit_points
                                              ) + "积分"
                                            ),
                                          ])
                                        : _vm._e(),
                                    ],
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "shopping_num",
                          label: "数量",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "优惠",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function fn(_ref3) {
                              var row = _ref3.row
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatPrice({
                                        price: row.deductible_price,
                                        point: row.deductible_points,
                                      })
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "receivable_money",
                          label: "小计",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function fn(_ref4) {
                              var row = _ref4.row
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatPrice({
                                        price: row.discount_price,
                                        point: row.discount_points,
                                      })
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "refund_state_str",
                          label: "售后状态",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function fn(_ref5) {
                              var row = _ref5.row
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "text",
                                    },
                                    on: {
                                      click: function click($event) {
                                        return _vm.handleAfterSaleDetail(row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.AFTER_SALE_STATUS_VALUE_DIC[
                                            row.after_sale_status
                                          ]
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "delivery_status",
                          formatter: function formatter(_, __, value) {
                            return _vm.DELIVERY_STATUS_VALUE_DIC[value]
                          },
                          align: "center",
                          label: "配送状态",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              (_vm$detailData$coupon = _vm.detailData.coupon_info_list) !==
                null &&
              _vm$detailData$coupon !== void 0 &&
              _vm$detailData$coupon.length
                ? _c(
                    "el-row",
                    {
                      staticClass: "mt-[20px]",
                    },
                    [
                      _c(
                        "el-col",
                        {
                          attrs: {
                            span: 8,
                          },
                        },
                        [
                          _c(
                            "el-table",
                            {
                              attrs: {
                                data: _vm.detailData.coupon_info_list,
                                "header-cell-style": {
                                  background: "#eef1f6",
                                  color: "#606266",
                                },
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "coupon_record_id",
                                  label: "票券ID",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "coupon_name",
                                  label: "票券名称",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  prop: "deductible_price",
                                  label: "优惠金额",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function fn(_ref6) {
                                        var row = _ref6.row
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formatPrice({
                                                  price: row.deductible_price,
                                                  point: row.deductible_points,
                                                })
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1734441197
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-row",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "flex-end",
                    "align-items": "flex-end",
                  },
                },
                [
                  _c(
                    "el-col",
                    {
                      attrs: {
                        span: 2,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "price-footer-l",
                        },
                        [_vm._v("商品总价：")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "price-footer-l",
                        },
                        [_vm._v("运费：")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "price-footer-l",
                        },
                        [_vm._v("优惠金额：")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "price-footer-l",
                        },
                        [_vm._v("实收金额：")]
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      attrs: {
                        span: 3,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "price-footer-r",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatPrice({
                                  price: _vm.detailData.origin_price,
                                  point: _vm.detailData.origin_points,
                                })
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "price-footer-r",
                        },
                        [_vm._v("￥" + _vm._s(_vm.detailData.freight || 0))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "price-footer-r",
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.formatPrice({
                                price: _vm.detailData.deductible_price,
                                point: _vm.detailData.deductible_points,
                              })
                            )
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "price-footer-r",
                          staticStyle: {
                            color: "red",
                            "font-size": "20px",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatPrice({
                                  price: _vm.detailData.total_price,
                                  point: _vm.detailData.total_points,
                                })
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "卖家备注",
            visible: _vm.dialogAddNoteVisible,
            width: "40%",
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.dialogAddNoteVisible = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              placeholder: "最多可输入256个字",
              maxlength: "256",
              "show-word-limit": "",
            },
            model: {
              value: _vm.note_form.remark,
              callback: function callback($$v) {
                _vm.$set(_vm.note_form, "remark", $$v)
              },
              expression: "note_form.remark",
            },
          }),
          _c(
            "div",
            {
              attrs: {
                slot: "footer",
              },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "text",
                  },
                  on: {
                    click: function click($event) {
                      _vm.dialogAddNoteVisible = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                  },
                  on: {
                    click: _vm.handleAddNoteConfirm,
                  },
                },
                [_vm._v(" 确定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("dispatch-order", {
        attrs: {
          visible: _vm.dialogDispatchOrderVisible,
          "detail-data": _vm.detailData,
        },
        on: {
          "update:visible": function updateVisible($event) {
            _vm.dialogDispatchOrderVisible = $event
          },
          success: _vm.getDetailByID,
        },
      }),
      _c("update-address", {
        attrs: {
          visible: _vm.updateAddressDialogVisible,
          "order-id": _vm.orderId,
          "detail-data": _vm.detailData,
        },
        on: {
          "update:visible": function updateVisible($event) {
            _vm.updateAddressDialogVisible = $event
          },
          success: _vm.getDetailByID,
        },
      }),
      _c("update-express", {
        attrs: {
          visible: _vm.updateExpressDialogVisible,
          "order-id": _vm.orderId,
          "delivery-sheet-info-list": _vm.detailData.delivery_sheet_info_list,
        },
        on: {
          "update:visible": function updateVisible($event) {
            _vm.updateExpressDialogVisible = $event
          },
          success: _vm.handleUpdateExpressClosed,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }