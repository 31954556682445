var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          staticStyle: {
            width: "100%",
          },
          attrs: {
            data: _vm.scheduleDetailData,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "params",
              label: "期数",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref) {
                  var row = _ref.row
                  return [
                    _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          title: "确定跳过本期吗？",
                          placement: "left",
                        },
                        on: {
                          onConfirm: function onConfirm($event) {
                            return _vm.handleSkipDispatch(row)
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              disabled: row.order_states != "NODELIVERY",
                              type: "text",
                            },
                            slot: "reference",
                          },
                          [_vm._v(" 跳过本期 ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "item_no",
              label: "发货单编号",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "solar_term_time",
              label: "日期",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "solar_term",
              label: "所属节气",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "pre_sent_time",
              label: "预计发货时间",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "",
              label: "发货状态",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref2) {
                  var row = _ref2.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("regularOrderDetailText")(row.order_states)
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "sent_time",
              label: "发货时间",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
              },
              on: {
                click: _vm.handleConfirm,
              },
            },
            [_vm._v(" 关闭 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }