export * as compileUtil from "./compile";
export * as commonUtil from "./common";
export * as transformUtil from "./transform";
export * as goodUtil from "./good";
export * as authUtil from "./auth";

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string") {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time);
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), "/");
      }
    }

    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    return value.toString().padStart(2, "0");
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (("" + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return "刚刚";
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + "分钟前";
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + "小时前";
  } else if (diff < 3600 * 24 * 2) {
    return "1天前";
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + "月" + d.getDate() + "日" + d.getHours() + "时" + d.getMinutes() + "分";
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split("?")[1]).replace(/\+/g, " ");
  if (!search) {
    return {};
  }
  const obj = {};
  const searchArr = search.split("&");
  searchArr.forEach((v) => {
    const index = v.indexOf("=");
    if (index !== -1) {
      const name = v.substring(0, index);
      const val = v.substring(index + 1, v.length);
      obj[name] = val;
    }
  });
  return obj;
}

/**
 * @param {Object} obj
 * @returns {Object}
 */
export function filterEmptyProp(obj) {
  if ({}.toString.call(obj) !== "[object Object]") return obj;
  const temObj = Object.assign({}, obj);
  for (const key in temObj) {
    const val = temObj[key];
    if (val === null || typeof val === "undefined" || val === "") {
      delete temObj[key];
    }
  }
  return temObj;
}

/**
 * 分转元
 * @param {Number}} money 待格式化金额
 * @return {String}
 */
export function moneyFormatter(money, { prefix = true } = {}) {
  if (typeof money !== "number" && !money) return "/";
  money = Number(money);
  return isNaN(money) ? "/" : money <= 0 ? 0 : `${prefix ? "¥" : ""}${(money / 100).toFixed(2)}`;
}

/**
 * 增加百分号格式化
 * @param {*} data 待格式化值
 * @param {*} param1 配置项
 */
export function percentFormatter(data, { suffix = true } = {}) {
  if (typeof data !== "number" && !data) return "/";
  data = Number(data);
  return isNaN(data) ? "/" : data === 0 ? 0 : `${data.toFixed(2)}${suffix ? "%" : ""}`;
}

/**
 * 除以100后的格式化
 * @param {*} data 待格式化值
 * @param {*} param1 配置项
 */
export function percentToFixedFormatter(data, { suffix = true } = {}) {
  if (typeof data !== "number" && !data) return "/";
  data = Number(data);
  data = data / 100;
  return isNaN(data) ? "/" : data === 0 ? 0 : `${data.toFixed(2)}${suffix ? "%" : ""}`;
}

/**
 * 下载二进制文件
 * @param {Blob} obj 二进制文件流
 * @param {String} name 文件名
 * @param {String} suffix 文件后缀
 */
export function downloadBlobFile({ obj, name, suffix }) {
  // 将二进制流转为blob
  const blob = new Blob([obj], { type: "application/octet-stream" });
  const filename = name + "." + suffix;
  if (typeof window.navigator.msSaveBlob !== "undefined") {
    // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
    window.navigator.msSaveBlob(blob, decodeURI(filename));
  } else {
    // 创建新的URL并指向File对象或者Blob对象的地址
    const blobURL = window.URL.createObjectURL(blob);
    // 创建a标签，用于跳转至下载链接
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", decodeURI(filename));
    // 兼容：某些浏览器不支持HTML5的download属性
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }
    // 挂载a标签
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    // 释放blob URL地址
    window.URL.revokeObjectURL(blobURL);
  }
}
