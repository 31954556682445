import { transformUtil } from "@/utils";

const ARR_LIST_MAP = {
  MESSAGE_TYPE: [
    { label: "商品", value: "GOODS" },
    { label: "图片", value: "IMAGE" },
    { label: "订单", value: "ORDER" },
    { label: "文本", value: "TEXT" },
  ],
  USER_TYPE: [
    { label: "系统", value: "SYS" },
    { label: "客服", value: "ADMIN" },
    { label: "用户", value: "USER" },
  ],
};

export default transformUtil.arrListToMapLists(ARR_LIST_MAP);
