import api from "@/api";

// TODO 查一下为什么报错，立即运行的时候，api是undefined的。
// export const getLogisticsCompanies = (async () => {
//   let companies = null;
//   if (!companies) {
//     console.log(api);
//     const [, res] = await api.logistics.logisticsCompanies();
//     companies = res;
//   }
//   return () => companies;
// })();

export async function getLogisticsCompanies() {
  return await api.logistics.logisticsCompanies();
}
