<template>
  <div>
    <el-form ref="form" :rules="formRule" :model="form" label-width="150px">
      <el-row>
        <div class="panel-item" style="margin-top: 20px">退货信息</div>
        <el-col :span="12">
          <el-form-item label="收货人姓名：">
            <el-input v-model="form.receiver_name" placeholder="输入收货人姓名" />
          </el-form-item>
          <el-form-item label="收货人手机：">
            <el-input v-model="form.receiver_mobile_number" placeholder="输入收货人手机" />
          </el-form-item>
          <el-form-item label="收货人地址：">
            <el-input v-model="form.return_goods_address" type="textarea" placeholder="输入收货人地址" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="2">
          <el-form-item style="text-align: center">
            <el-button type="primary" @click="handelFormSubmit"> 确定 </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "ReturnInfo",
  data() {
    return {
      form: {},
      formRule: {},
    };
  },
  created() {
    this.getConfig();
  },
  methods: {
    async getConfig() {
      const [, res] = await api.settings.returnGoodsInfo();
      this.form = res ?? {};
    },
    async handelFormSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      const [err] = await api.settings.returnGoodsInfo({
        method: "put",
        data: this.form,
      });
      if (!err) this.$message.success("操作成功");
    },
  },
};
</script>

<style></style>
