<template>
  <div class="page-padding">
    <div>
      <div class="page-header--wrap">
        <div class="page-header-wrap--title">订位表</div>
        <div>
          <el-button type="primary" @click="handleSettingBtn"> 设置 </el-button>
        </div>
      </div>

      <el-row class="page-filter--wrap">
        <div class="page-color--normal">日期</div>
        <el-date-picker
          v-model="order_time"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
          unlink-panels
          @change="handleDateChange"
        />
        <div class="page-color--normal">大厨工作室</div>
        <el-select v-model="searchForm.type" placeholder="全部" style="width: 150px">
          <el-option label="湘菜" :value="1" />
          <el-option label="川菜" :value="2" />
        </el-select>
        <el-button round style="margin: 0 20px" icon="el-icon-refresh" @click="resetSearchForm"> 重置 </el-button>
        <el-button type="primary" round icon="el-icon-search" @click="getList"> 搜索 </el-button>
      </el-row>

      <el-table v-loading="listLoading" :data="booksData" stripe border style="width: 100%">
        <el-table-column align="center" prop="date" label="日期" />
        <el-table-column align="center" prop="time" label="时间段">
          <template slot-scope="{ row }">
            {{ row.time == 1 ? "中午" : "晚上" }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="type" label="大厨工作室">
          <template slot-scope="{ row }">
            {{ row.type == 1 ? "湘菜" : "川菜" }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="is_on_off" label="工作室用餐">
          <template slot-scope="{ row }">
            <el-switch
              v-model="row.is_on_off"
              :active-value="1"
              :inactive-value="0"
              active-text="启用"
              inactive-text="禁用"
              @change="changeStatus($event, row, 'studio')"
            />
          </template>
        </el-table-column>

        <el-table-column align="center" prop="is_on_off_to_home" label="上门服务">
          <template slot-scope="{ row }">
            <el-switch
              v-model="row.is_on_off_to_home"
              :active-value="1"
              :inactive-value="0"
              active-text="启用"
              inactive-text="禁用"
              @change="changeStatus($event, row, 'home')"
            />
          </template>
        </el-table-column>

        <el-table-column align="center" prop="is_book" label="预订状态">
          <template slot-scope="{ row }">
            <div v-if="row.is_book == 1"><el-badge is-dot type="success" class="status-item" />已预订</div>
            <div v-if="row.is_book == 0"><el-badge is-dot type="danger" class="status-item" />未预订</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="num" label="预订人数" />
        <el-table-column align="center" prop="cook_type" label="用餐方式">
          <template slot-scope="{ row }">
            {{ row.cook_type == 1 ? "工作室用餐" : "上门服务" }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="order_no" label="预订单号" />
      </el-table>
      <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    </div>

    <el-dialog title="设置" :visible.sync="dialogSetting">
      <el-form :model="formSetting" label-width="120px">
        <el-form-item label="大厨工作室">
          <el-select v-model="formSetting.type" placeholder="请选择" @change="handleTypeChange">
            <el-option label="湘菜" :value="1" />
            <el-option label="川菜" :value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="用餐方式">
          <el-select v-model="formSetting.cook_type" placeholder="请选择" @change="handleCookTypeChange">
            <el-option label="工作室用餐" :value="1" />
            <el-option label="上门服务" :value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="金额">
          <el-input v-model="formSetting.money" placeholder="用餐金额" />
        </el-form-item>
        <el-form-item label="默认可预订时间" />
        <el-form-item label="中午">
          <el-checkbox-group v-model="formSetting.afternoon_allow_time">
            <el-checkbox v-for="(val, k) in weeks" :key="k" :label="val">
              {{ k }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="晚上">
          <el-checkbox-group v-model="formSetting.dinner_allow_time">
            <el-checkbox v-for="(val, k) in weeks" :key="k" :label="val">
              {{ k }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="text" @click="dialogSetting = false"> 取 消 </el-button>
        <el-button type="primary" @click="handleSettingFormConfirm"> 确 定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import TableListMixin from "@/mixin/TableList";
import api from "@/api";
import { commonUtil } from "@/utils";
import * as R from "ramda";

const { to } = commonUtil;
export default {
  name: "ReserveTable",
  components: { Pagination },
  mixins: [TableListMixin],
  data() {
    return {
      // weeks: { 1: "周一", 2: "周二", 3: "周三", 4: "周四", 5: "周五", 6: "周六", 0: "周日" },
      weeks: { 周一: 1, 周二: 2, 周三: 3, 周四: 4, 周五: 5, 周六: 6, 周日: 0 },
      dialogSetting: false,
      order_time: [],
      searchForm: {
        type: 1,
      },
      booksData: [],
      formSetting: {
        afternoon_allow_time: [],
        dinner_allow_time: [],
        money: "",
      },
      settingData: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async changeStatus(value, item, sType) {
      if (sType === "studio") {
        item.cook_type = 1;
      } else if (sType === "home") {
        item.cook_type = 2;
      }
      item.is_on_off = value;

      item = R.pick(["date", "type", "cook_type", "time", "is_on_off"], item);
      const [err] = await api.booking.updateLimitSetting({ method: "post", data: item });
      if (!err) to(this.getList());
    },
    async getList() {
      const [, res] = await this.getListMixin(api.booking.reserveList);
      this.booksData = res.list;
    },
    resetSearchForm() {
      this.order_time = [];
      this.searchForm = { type: 1 };
      this.getList();
    },
    handleDateChange(val) {
      this.searchForm.start = val ? val[0] : "";
      this.searchForm.end = val ? val[1] : "";
    },

    async handleSettingBtn() {
      const [, res] = await api.booking.reserveSetting();
      this.settingData = res;
      let tempForm = {};
      tempForm = res[0];
      tempForm.afternoon_allow_time = res[0].allow_time ?? [];
      tempForm.dinner_allow_time = res[1].allow_time ?? [];
      this.formSetting = Object.assign(this.formSetting, tempForm);
      this.dialogSetting = true;
    },

    // 回填设置表单的内容
    fillSettingForm(data) {
      this.formSetting.afternoon_allow_time = data[0].allow_time ?? [];
      this.formSetting.dinner_allow_time = data[1].allow_time ?? [];
    },

    handleTypeChange(val) {
      let { settingData, formSetting } = this;
      let tempArr = settingData
        .filter((item) => {
          return item.type == val;
        })
        .filter((jitem) => {
          return jitem.cook_type == formSetting.cook_type;
        });
      this.fillSettingForm(tempArr);
    },
    handleCookTypeChange(val) {
      let { settingData, formSetting } = this;
      let tempArr = settingData
        .filter((item) => {
          return item.cook_type == val;
        })
        .filter((jitem) => {
          return jitem.type == formSetting.type;
        });
      this.fillSettingForm(tempArr);
    },
    handleSettingFormConfirm() {
      this.axios.post(`/chef_studio`, { ...this.formSetting }).then(() => {
        this.getList();
        this.dialogSetting = false;
        this.$message.success("操作成功");
      });
    },
  },
};
</script>

<style></style>
