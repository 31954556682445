<template>
  <div v-loading="goodsDetailLoading" class="goods-wrap page-padding" style="background: #fff" element-loading-text="加载中...">
    <div>
      <sticky :z-index="20">
        <el-page-header content="发货详情" class="go-back" @back="goBack" />
      </sticky>
    </div>
    <el-form ref="formGoods" :model="formGoods" :rules="formGoodsRules" label-width="120px" class="goods-form--wrap">
      <el-row>
        <div class="panel-item">物流信息</div>
        <el-col :span="24">
          <el-form-item label="发货单号：">
            {{ detailData.order_no }}
          </el-form-item>
          <el-form-item label="安排邮政发货：">
            {{ detailData.push_info }}
          </el-form-item>
          <el-form-item label="物流单号：">
            <span v-for="(item, index) in detailData.logistics_order_no_list" :key="index">{{ item }}</span>
          </el-form-item>
          <el-form-item label="物流轨迹：">
            <div v-if="detailData.packages.length == 0" style="margin: 0 0 20px 40px; min-height: 200px">暂无物流信息</div>
            <el-tabs v-else v-model="activePackage" type="card" style="min-height: 200px">
              <el-tab-pane
                v-for="(item, index) in detailData.packages"
                :key="index"
                style="margin: 0 0 10px 20px"
                :label="`包裹${index + 1}`"
                :name="`包裹${index + 1}`"
              >
                <el-row>
                  <el-col :span="6" class="order-info-wrap order-packages">
                    <div v-if="item.express_item_list.length != 0">
                      <div class="order-info-item">发货人：{{ item.express_item_list[0].sender }}</div>
                      <div class="order-info-item">运单号： {{ item.express_item_list[0].logistics_order_no }}</div>
                      <div class="order-info-item">发货方式： {{ item.express_item_list[0].type }}</div>
                      <div class="order-info-item">发货时间： {{ formatTime(item.express_item_list[0].sent_date) }}</div>
                    </div>

                    <div class="packages-title" style="margin: 0">
                      <div v-for="(imgItem, index) in item.express_item_list" :key="index" style="width: 80px; margin-right: 5px">
                        <img style="width: 80px; margin: 10px 5px" :src="imgItem.img_url" />
                        <div class="fs12 text-ellipsis">{{ imgItem.sku_name }}x{{ imgItem.num }}</div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12" class="order-info-wrap order-packages">
                    <div style="margin: 0 0 20px 20px">
                      <span>物流状态：</span> <span style="color: #f56c6c">{{ item.state_str }}</span>
                    </div>
                    <el-timeline :reverse="true">
                      <el-timeline-item v-for="(jitem, jindex) in item.mail_tracks" :key="jindex" :timestamp="jitem.op_time">
                        {{ jitem.op_desc }}
                      </el-timeline-item>
                    </el-timeline>
                  </el-col>
                </el-row>
              </el-tab-pane>
            </el-tabs>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <div class="panel-item">发货商品</div>
        <el-col :span="20" style="margin-bottom: 30px">
          <el-table :data="detailData.products" style="width: 100%">
            <el-table-column align="center" prop="goods_name" label="发货商品" />
            <el-table-column align="center" prop="spec" label="规格属性" />
            <el-table-column align="center" label="EMS编码">
              <template slot-scope="{ row }">
                {{ row.sku_code }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="num" label="发货数量" />
          </el-table>
        </el-col>
      </el-row>

      <!-- 收货信息 -->
      <el-row>
        <div class="panel-item">收货信息</div>
        <el-col :span="8">
          <el-form-item label="收货人：" prop="receiver">
            {{ detailData.receiver }}
          </el-form-item>
          <el-form-item label="联系电话：" prop="receiver_phone">
            {{ detailData.receiver_phone }}
          </el-form-item>
          <el-form-item label="所在区域：" prop="cities">
            {{ detailData.province }}{{ detailData.city }}{{ detailData.county }}{{ detailData.address_detail }}
          </el-form-item>
          <!-- <el-form-item label="" prop="address_detail">
            <el-input v-model="detailData.address_detail" type="textarea" placeholder="请填写详细地址"></el-input>
          </el-form-item> -->
        </el-col>
      </el-row>

      <!-- 下单来源 -->
      <el-row>
        <div class="panel-item">下单来源</div>
        <el-col :span="8">
          <el-form-item label="下单来源：">
            {{ detailData.channel }}
          </el-form-item>
          <el-form-item label="渠道单号：" prop="channel_order_no">
            {{ detailData.channel_order_no }}
          </el-form-item>
          <el-form-item label="备注说明：">
            {{ detailData.seller_remark }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item style="text-align: center">
        <el-button @click="goBack"> 返回 </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Sticky from "@/components/Sticky";
import { formatTime } from "@/utils/format-time";
export default {
  components: { Sticky },
  data() {
    return {
      formatTime,
      goodsDetailLoading: false,
      source: "order_mange", // order_mange 或 after_sales

      order_no: "",

      detailData: { address: { receiver: "" }, mail_tracks: [], packages: [] },
      activePackage: "包裹1",

      currentGoodsData: [],
    };
  },

  activated() {},
  created() {
    this.order_no = this.$route.query.id;
    this.source = this.$route.query.source;
    this.getDetailByID();
  },
  methods: {
    handleUpdateExpressClosed() {
      this.updateExpressDialog = false;
    },

    getDetailByID() {
      this.goodsDetailLoading = true;
      this.axios.get(`/otherOrder/detail?order_no=${this.order_no}`).then((res) => {
        this.detailData = res.ndata;
        this.goodsDetailLoading = false;
      });
    },
    goBack() {
      this.$router.replace("/order/order_other");
    },
    handleAddNote() {
      this.dialogAddNote = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.go-back {
  margin: 0;
  padding-left: 10px;
}
.order-packages {
  height: inherit;
  overflow-y: scroll;
  padding: 15px 0;

  .packages-title {
    margin: 0 0 20px 40px;
    display: flex;
    align-items: center;
  }
}
.order-header {
  display: flex;
  font-size: 14px;
}
.header-item {
  margin-right: 100px;
}
.order-info {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  height: 200px;
}
.order-status {
  font-size: 22px;
  font-weight: bold;
  line-height: 44px;
}
.order-note,
.order-info-item {
  font-size: 14px;
  color: #bbb;
}
.order-note {
  line-height: 28px;
}
.order-info-item {
  line-height: 22px;
}
.price-footer {
  font-size: 14px;
}
.order-info-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: 30px;
}
.price-footer-l {
  display: flex;
  justify-content: flex-end;
}
.price-footer-l,
.price-footer-r {
  font-size: 14px;
  line-height: 28px;
}
</style>
