var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "el-row",
        {
          staticClass: "margin-item",
          attrs: {
            gutter: 10,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: true,
                rules: _vm.formRules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "商品信息",
                    prop: "food",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: {
                      width: "200px",
                    },
                    model: {
                      value: _vm.form.food,
                      callback: function callback($$v) {
                        _vm.$set(_vm.form, "food", $$v)
                      },
                      expression: "form.food",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "物流公司",
                    prop: "express_company_code",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "200px",
                      },
                      model: {
                        value: _vm.form.express_company_code,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "express_company_code", $$v)
                        },
                        expression: "form.express_company_code",
                      },
                    },
                    _vm._l(_vm.express_options, function (item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: {
                          label: item.name,
                          value: item.code,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: {
                width: "100%",
              },
              attrs: {
                data: _vm.goodsData,
                border: "",
                stripe: "",
                "highlight-current-row": "",
                "header-cell-style": {
                  background: "#eef1f6",
                  color: "#606266",
                },
              },
              on: {
                "selection-change": _vm.handleSelectionChange,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "运单号",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref) {
                      var row = _ref.row
                      return [
                        _c("el-input", {
                          model: {
                            value: row.delivery_no,
                            callback: function callback($$v) {
                              _vm.$set(row, "delivery_no", $$v)
                            },
                            expression: "row.delivery_no",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "params",
                  label: "期数",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "item_no",
                  label: "订单编号",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "order_states",
                  label: "发货状态",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref2) {
                      var row = _ref2.row
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("regularOrderDetailText")(row.order_states)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  width: "200",
                  align: "center",
                  label: "收货人",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref3) {
                      var row = _ref3.row
                      return [
                        _c("div", [
                          _c("div", [_vm._v(_vm._s(row.receiver_name))]),
                          _c("div", [_vm._v(_vm._s(row.receiver_phone))]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "收货地址",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref4) {
                      var row = _ref4.row
                      return [
                        _vm._v(
                          " " +
                            _vm._s(row.province) +
                            _vm._s(row.city) +
                            _vm._s(row.county) +
                            _vm._s(row.receiver_address) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          attrs: {
            gutter: 10,
            type: "flex",
            justify: "center",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
              },
              on: {
                click: _vm.handleDelivery,
              },
            },
            [_vm._v(" 发货 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }