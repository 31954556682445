<template>
  <div class="page-padding goods-group">
    <div class="page-header--wrap">
      <div class="page-header-wrap--title">商品分组</div>
      <div>
        <el-button icon="el-icon-plus" type="primary" @click="handleAddScheme"> 新增商品分组 </el-button>
      </div>
    </div>
    <el-row class="page-filter--wrap" />
    <el-table
      ref="dragTable"
      v-loading="listLoading"
      row-key="id"
      :data="schemeData"
      row-class-name="data-row-class"
      stripe
      border
      @row-click="handleRowClick"
    >
      <el-table-column align="center" type="index" label="序号" width="60" />
      <el-table-column align="center" label="排序" width="80">
        <svg-icon class="drag-handler" icon-class="drag" />
      </el-table-column>
      <el-table-column align="center" prop="prop" label="操作" width="200">
        <template slot-scope="{ row }">
          <el-button type="text" @click="handleUpdateScheme(row)"> 编辑 </el-button>
          <el-popconfirm title="确定删除该数据吗？" style="margin-left: 10px" placement="left" @onConfirm="handleDelete(row)">
            <el-button slot="reference" type="text"> 删除 </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="name" label="分组名称" />
      <el-table-column class-name="img-column" align="center" label="分组配图">
        <template slot-scope="{ row }">
          <div class="img-cover">
            <upload-img :limit-count="1" :already-file="row.image_address" :token="token" @uploadSuccess="(val) => uploadPicSuccess(val, row)" />
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="number_of_related_goods" label="商品数量" />
      <el-table-column align="center" show-overflow-tooltip label="创建人/修改人">
        <template slot-scope="{ row }"> {{ row.creator }} / {{ row.updater }} </template>
      </el-table-column>
      <el-table-column align="center" show-overflow-tooltip label="创建时间/修改时间">
        <template slot-scope="{ row }"> {{ row.create_time }} / {{ row.update_time }} </template>
      </el-table-column>
    </el-table>
    <div class="table-tooltip">
      <i class="el-icon-warning-outline" />
      <div style="margin-left: 10px">
        <div>门店同款建议尺寸：宽160*高195px，jpg、png格式，500K以内</div>
        <div>太二潮品建议尺寸：宽180*高90px，jpg、png格式，500K以内</div>
      </div>
    </div>
    <!--    <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />-->
    <!-- 新增商品分组的弹窗 -->
    <el-dialog :title="`${textMap[dialogStatus]}商品分组`" :visible.sync="dialogGroupForm" width="400px">
      <el-form ref="goodsTagForm" :rules="rules" :model="goodsTagForm" label-width="70px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="goodsTagForm.name" />
        </el-form-item>
        <el-form-item label="是否可见" prop="related_goods_visible">
          <el-switch v-model="goodsTagForm.related_goods_visible" />
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button type="text" @click="dialogGroupForm = false"> 取消 </el-button>
        <el-button type="primary" @click="handleDialogConfirm">
          {{ dialogStatus === "update" ? "修改" : "确定" }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as R from "ramda";
import UploadImg from "@/components/Upload/UploadImg";
import TableListMixin from "@/mixin/TableList";
import api from "@/api";
import Sortable from "sortablejs";

const initGoodsTagForm = {
  id: undefined,
  name: "",
  image_address: "",
  related_goods_visible: true,
};
export default {
  name: "GoodsGroup",
  components: { UploadImg },
  mixins: [TableListMixin],
  data() {
    return {
      rules: {
        name: [{ required: true, message: "请输入分组名称", trigger: "blur" }],
      },
      token: "",
      listLoading: false,
      is_create: true,
      dialogGroupForm: false,
      dialogCategoryName: "",
      valueBrand: "",
      daterange1: "",
      daterange2: "",
      schemeData: [],
      goodsTagForm: R.clone(initGoodsTagForm),
    };
  },
  created() {
    this.getToken();
    this.getList();
  },
  activated() {
    // src/views/mall-manage/goods-manage/goods-category/index.vue这个组件跳转过来使用
    if (this.$route.params?.autoAdd) this.handleAddScheme();
  },
  methods: {
    async getList() {
      // 获取数据
      let err;
      this.listLoading = true;
      [err, this.schemeData = []] = await api.goods.goodsTags();
      this.listLoading = false;
      if (err) console.error(err);
      this.$nextTick(() => this.setSort());
    },
    setSort() {
      const el = this.$refs.dragTable.$el.querySelectorAll(".el-table__body-wrapper > table > tbody")[0];
      this.sortable = Sortable.create(el, {
        ghostClass: "sortable-ghost", // Class name for the drop placeholder,
        onEnd: (evt) => {
          const { oldIndex, newIndex } = evt;
          if (oldIndex === newIndex) return;
          const targetRow = this.schemeData[newIndex];
          const sourceRow = this.schemeData.splice(oldIndex, 1)[0];

          this.schemeData.splice(newIndex, 0, sourceRow);
          this.sortRequest(sourceRow.id, targetRow.id, oldIndex > newIndex ? "FRONT" : "BACK");
        },
      });
    },
    async sortRequest(source_goods_tag_id, target_goods_tag_id, front_back) {
      const data = { source_goods_tag_id, target_goods_tag_id, front_back };
      const [err] = await api.goods.goodsTagsMove({
        method: "post",
        data,
      });
      if (err) await this.getList();
      else this.$notify.success("操作成功");
      this.$forceUpdate();
    },
    async getToken() {
      [, this.token] = await api.auth.getQiNiuToken();
    },
    handleRowClick(row) {
      this.goodsTagForm = R.clone(row);
    },
    async uploadPicSuccess(val, row) {
      this.handleRowClick(row);
      this.goodsTagForm.image_address = val[0]?.["url"] ?? "";

      const { id, ...data } = this.goodsTagForm;

      const [err] = await api.goods.goodsTags({
        method: "put",
        id,
        data,
      });
      if (!err) await this.getList();
    },
    async handleDialogConfirm() {
      const isValid = await this.$refs.goodsTagForm.validate();
      if (!isValid) return;
      const { id, ...data } = this.goodsTagForm;
      await api.goods.goodsTags({
        method: id ? "put" : "post",
        id,
        data,
      });
      this.dialogGroupForm = false;
      this.$notify.success("操作成功");
      await this.getList();
    },
    resetGoodsTagForm() {
      this.goodsTagForm = R.clone(initGoodsTagForm);
    },
    handleAddScheme() {
      this.resetGoodsTagForm();
      this.dialogStatus = "create";
      this.dialogGroupForm = true;
      this.$nextTick(() => {
        this.$refs.goodsTagForm.clearValidate();
      });
    },
    handleUpdateScheme(row) {
      this.dialogCategoryName = row.name;
      this.goodsTagForm = R.pick(["id", "name", "image_address", "related_goods_visible"], row);

      this.dialogStatus = "update";
      this.dialogGroupForm = true;
    },
    async handleDelete(row) {
      const [err] = await api.goods.goodsTags({
        method: "delete",
        id: row.id,
      });
      if (err) return console.error(err);
      this.$notify.success("删除成功");
      await this.getList();
    },
  },
};
</script>

<style lang="scss">
.goods-group {
  .upload-wrap {
    .el-upload--picture-card {
      width: 100px;
      height: 100px;
      line-height: 100px;
    }
    .el-upload-list__item {
      margin: 0;
      width: 100px;
      height: 100px;
    }
    .el-progress--circle {
      width: 80px;
      .el-progress-circle {
        width: 80px !important;
        height: 80px !important;
      }
    }
  }
  .img-column {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .table-tooltip {
    color: rgb(187, 187, 187);
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  .sortable-ghost {
    opacity: 0.8;
    color: #fff !important;
    background: #42b983 !important;
  }
}
</style>
