var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      attrs: {
        id: "sku",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "specification",
        },
        [
          _c(
            "ul",
            {
              staticClass: "spec-list",
            },
            [
              _vm._l(_vm.specification, function (item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "item",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "name",
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "规格名",
                          },
                          on: {
                            change: function change() {
                              return _vm.handleSpecChange("edit")
                            },
                          },
                          model: {
                            value: item.name,
                            callback: function callback($$v) {
                              _vm.$set(item, "name", $$v)
                            },
                            expression: "item.name",
                          },
                        }),
                        _c("i", {
                          staticClass: "icon el-icon-circle-close",
                          on: {
                            click: function click($event) {
                              return _vm.delSpec(index)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "values",
                      },
                      [
                        _c(
                          "draggable",
                          {
                            attrs: {
                              list: item.value,
                            },
                            on: {
                              end: _vm.handleChangeSpecSort,
                            },
                          },
                          _vm._l(item.value, function (tag, num) {
                            return _c(
                              "span",
                              {
                                key: tag,
                                staticClass: "el-tag",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "el-select__tags-text",
                                  },
                                  [_vm._v(_vm._s(tag))]
                                ),
                                _c("i", {
                                  staticClass: "el-tag__close el-icon-close",
                                  on: {
                                    click: function click($event) {
                                      return _vm.delSpecTag(index, num)
                                    },
                                  },
                                }),
                              ]
                            )
                          }),
                          0
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "add-attr",
                          },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: {
                                  placeholder: "规格值",
                                  icon: "plus",
                                },
                                on: {
                                  click: function click($event) {
                                    return _vm.addSpecTag(index)
                                  },
                                },
                                nativeOn: {
                                  keyup: function keyup($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.addSpecTag(index)
                                  },
                                },
                                model: {
                                  value: _vm.addValues[index],
                                  callback: function callback($$v) {
                                    _vm.$set(_vm.addValues, index, $$v)
                                  },
                                  expression: "addValues[index]",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    icon: "el-icon-check",
                                    type: "primary",
                                  },
                                  on: {
                                    click: function click($event) {
                                      return _vm.addSpecTag(index)
                                    },
                                  },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                )
              }),
              _c(
                "div",
                {
                  staticClass: "add-spec",
                  staticStyle: {
                    padding: "7px 10px",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "info",
                        disabled: _vm.specification.length >= 5,
                      },
                      on: {
                        click: _vm.addSpec,
                      },
                    },
                    [_vm._v(" 添加规格项目 ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ]
      ),
      _vm._t("middle"),
      _c(
        "div",
        {
          staticClass: "example",
        },
        [
          _c(
            "table",
            {
              staticClass: "stock-table",
              attrs: {
                cellspacing: "0",
                cellpadding: "0",
              },
            },
            [
              _c("thead", [
                _c(
                  "tr",
                  [
                    _vm._l(_vm.specification, function (item, index) {
                      return _c(
                        "th",
                        {
                          key: index,
                          staticStyle: {
                            width: "100px",
                          },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    _c(
                      "th",
                      {
                        staticStyle: {
                          width: "120px",
                        },
                      },
                      [_vm._v("skuId")]
                    ),
                    _c(
                      "th",
                      {
                        staticStyle: {
                          width: "150px",
                        },
                      },
                      [_vm._v("图片")]
                    ),
                    _vm.goodsType !== _vm.TYPE_VALUE_MAP["REGULAR"]
                      ? _c(
                          "th",
                          {
                            staticStyle: {
                              width: "150px",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "red",
                                },
                              },
                              [_vm._v("*")]
                            ),
                            _vm._v(
                              _vm._s(
                                _vm.goodsType === _vm.TYPE_VALUE_MAP["PHYSICAL"]
                                  ? "企得宝编码"
                                  : "关联优惠券"
                              ) + " "
                            ),
                            _vm.goodsType === _vm.TYPE_VALUE_MAP["PHYSICAL"]
                              ? _c("batch-input", {
                                  attrs: {
                                    placeholder: "企得宝编码",
                                  },
                                  on: {
                                    input: function input(val) {
                                      return _vm.handleBatchUpdate(
                                        "third_party_code",
                                        val
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "th",
                      {
                        staticStyle: {
                          width: "120px",
                        },
                      },
                      [
                        _vm._v(" 成本价（元）"),
                        _c("batch-input", {
                          attrs: {
                            placeholder: "成本价（元）",
                          },
                          on: {
                            input: function input(val) {
                              return _vm.handleBatchUpdate("cost_price", val)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    !_vm.isMember && _vm.isMoney
                      ? _c(
                          "th",
                          {
                            staticStyle: {
                              width: "120px",
                            },
                          },
                          [
                            _vm._v(" 现金划线价"),
                            _c("batch-input", {
                              attrs: {
                                placeholder: "现金划线价",
                              },
                              on: {
                                input: function input(val) {
                                  return _vm.handleBatchUpdate(
                                    "original_price",
                                    val
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.isMember && _vm.isPoints
                      ? _c(
                          "th",
                          {
                            staticStyle: {
                              width: "120px",
                            },
                          },
                          [
                            _vm._v(" 积分划线价"),
                            _c("batch-input", {
                              attrs: {
                                placeholder: "积分划线价",
                              },
                              on: {
                                input: function input(val) {
                                  return _vm.handleBatchUpdate(
                                    "original_points",
                                    val
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.memberRights
                      ? _c(
                          "th",
                          {
                            staticStyle: {
                              width: "100px",
                            },
                          },
                          [
                            _vm._v(" 会员权益 "),
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择",
                                },
                                on: {
                                  change: function change(val) {
                                    return _vm.handleBatchUpdate(
                                      "member_rights_type",
                                      val
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.batchData.memberRightTypeOption,
                                  callback: function callback($$v) {
                                    _vm.$set(
                                      _vm.batchData,
                                      "memberRightTypeOption",
                                      $$v
                                    )
                                  },
                                  expression: "batchData.memberRightTypeOption",
                                },
                              },
                              _vm._l(
                                _vm.getFilterMemberRight(),
                                function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isMoney
                      ? _c(
                          "th",
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "red",
                                },
                              },
                              [_vm._v("*")]
                            ),
                            _vm._v("现金售价"),
                            _c("batch-input", {
                              attrs: {
                                placeholder: "现金售价",
                              },
                              on: {
                                input: function input(val) {
                                  return _vm.handleBatchUpdate(
                                    "retail_price",
                                    val
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isPoints
                      ? _c(
                          "th",
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "red",
                                },
                              },
                              [_vm._v("*")]
                            ),
                            _vm._v("积分售价"),
                            _c("batch-input", {
                              attrs: {
                                placeholder: "积分售价",
                              },
                              on: {
                                input: function input(val) {
                                  return _vm.handleBatchUpdate(
                                    "retail_points",
                                    val
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "th",
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "red",
                              width: "120px",
                            },
                          },
                          [_vm._v("*")]
                        ),
                        _vm._v("库存"),
                        _c("batch-input", {
                          attrs: {
                            placeholder: "库存",
                          },
                          on: {
                            input: function input(val) {
                              return _vm.handleBatchUpdate("stock_num", val)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "th",
                      [
                        _vm.goodsType === _vm.TYPE_VALUE_MAP["PHYSICAL"]
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "red",
                                  width: "120px",
                                },
                              },
                              [_vm._v("*")]
                            )
                          : _vm._e(),
                        _vm._v("重量（kg）"),
                        _c("batch-input", {
                          attrs: {
                            placeholder: "重量（kg）",
                          },
                          on: {
                            input: function input(val) {
                              return _vm.handleBatchUpdate("weight", val)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "th",
                      [
                        _vm._v(" 是否上架 "),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content:
                                "关闭后，用户购买该商品时对应sku不可见。",
                              placement: "top",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-question",
                              staticStyle: {
                                "margin-left": "4px",
                                cursor: "help",
                              },
                            }),
                          ]
                        ),
                        _c(
                          "div",
                          [
                            _c("el-switch", {
                              attrs: {
                                disabled: _vm.isDisabledSkuOnSale,
                              },
                              on: {
                                change: function change(val) {
                                  return _vm.handleBatchUpdate(
                                    "sku_on_sale",
                                    val
                                  )
                                },
                              },
                              model: {
                                value: _vm.switchValue,
                                callback: function callback($$v) {
                                  _vm.switchValue = $$v
                                },
                                expression: "switchValue",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]),
              _vm.specification[0] && _vm.specification[0].value.length
                ? _c(
                    "tbody",
                    _vm._l(_vm.countSum(0), function (_, index) {
                      return _c(
                        "tr",
                        {
                          key: index,
                          staticStyle: {
                            "text-align": "center",
                          },
                        },
                        [
                          _vm._l(
                            _vm.specification.length,
                            function (n, specIndex) {
                              return [
                                _vm.showTd(specIndex, index)
                                  ? _c(
                                      "td",
                                      {
                                        key: n,
                                        attrs: {
                                          rowspan: _vm.countSum(n),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getSpecAttr(specIndex, index)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            }
                          ),
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.showSkuId ? _vm.skus[index].id : "-"
                                ) +
                                " "
                            ),
                          ]),
                          _c(
                            "td",
                            [
                              _c("upload-picture", {
                                attrs: {
                                  "limit-count": 1,
                                  "already-file": _vm.skus[index].image_address,
                                  token: _vm.uploadToken,
                                },
                                on: {
                                  uploadSuccess: function uploadSuccess(
                                    uploadArr
                                  ) {
                                    return _vm.handleUploadSuccess(
                                      uploadArr,
                                      index
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.goodsType !== _vm.TYPE_VALUE_MAP["REGULAR"]
                            ? _c(
                                "td",
                                {
                                  style: "width: ".concat(
                                    _vm.isShoppingMall ? "220px" : "100px"
                                  ),
                                },
                                [
                                  _vm.goodsType ===
                                  _vm.TYPE_VALUE_MAP["PHYSICAL"]
                                    ? _c("el-input", {
                                        attrs: {
                                          type: "text",
                                          placeholder: "输入企得宝编码",
                                        },
                                        model: {
                                          value:
                                            _vm.skus[index].third_party_code,
                                          callback: function callback($$v) {
                                            _vm.$set(
                                              _vm.skus[index],
                                              "third_party_code",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "skus[index].third_party_code",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.goodsType ===
                                  _vm.TYPE_VALUE_MAP["ELECTRONIC"]
                                    ? [
                                        !_vm.isShoppingMall
                                          ? [
                                              _c(
                                                "el-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "loadmore",
                                                      rawName: "v-loadmore",
                                                      value:
                                                        _vm.loadMoreCoupons,
                                                      expression:
                                                        "loadMoreCoupons",
                                                    },
                                                  ],
                                                  attrs: {
                                                    "remote-method":
                                                      _vm.getNameCoupons,
                                                    remote: "",
                                                    filterable: "",
                                                    placeholder: "请选择",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.handleCouponSelect,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.skus[index]
                                                        .third_party_code,
                                                    callback: function callback(
                                                      $$v
                                                    ) {
                                                      _vm.$set(
                                                        _vm.skus[index],
                                                        "third_party_code",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "skus[index].third_party_code",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.couponData,
                                                  function (item, optionIndex) {
                                                    return _c("el-option", {
                                                      key: ""
                                                        .concat(index, "-")
                                                        .concat(
                                                          optionIndex,
                                                          "-"
                                                        )
                                                        .concat(
                                                          item.third_party_code
                                                        ),
                                                      attrs: {
                                                        label: ""
                                                          .concat(
                                                            item.name,
                                                            "("
                                                          )
                                                          .concat(
                                                            item.third_party_code,
                                                            ")"
                                                          ),
                                                        value:
                                                          item.third_party_code,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ]
                                          : _c(
                                              "div",
                                              {
                                                staticClass: "flex",
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      placeholder: "请选择品牌",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.handleChangeBrand,
                                                    },
                                                    model: {
                                                      value: _vm.currentBrand,
                                                      callback:
                                                        function callback($$v) {
                                                          _vm.currentBrand = $$v
                                                        },
                                                      expression:
                                                        "currentBrand",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.brandList,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.code,
                                                        attrs: {
                                                          label: item.name,
                                                          value: item.code,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                                _c(
                                                  "el-select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "loadmore",
                                                        rawName: "v-loadmore",
                                                        value:
                                                          _vm.loadMoreCoupons,
                                                        expression:
                                                          "loadMoreCoupons",
                                                      },
                                                    ],
                                                    attrs: {
                                                      "remote-method":
                                                        _vm.getNameCoupons,
                                                      remote: "",
                                                      filterable: "",
                                                      placeholder: "请选择票券",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.handleCouponSelect,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.skus[index]
                                                          .third_party_code,
                                                      callback:
                                                        function callback($$v) {
                                                          _vm.$set(
                                                            _vm.skus[index],
                                                            "third_party_code",
                                                            $$v
                                                          )
                                                        },
                                                      expression:
                                                        "skus[index].third_party_code",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.couponData,
                                                    function (
                                                      item,
                                                      optionIndex
                                                    ) {
                                                      return _c("el-option", {
                                                        key: ""
                                                          .concat(index, "-")
                                                          .concat(
                                                            optionIndex,
                                                            "-"
                                                          )
                                                          .concat(
                                                            item.third_party_code
                                                          ),
                                                        attrs: {
                                                          label: ""
                                                            .concat(
                                                              item.name,
                                                              "("
                                                            )
                                                            .concat(
                                                              item.third_party_code,
                                                              ")"
                                                            ),
                                                          value:
                                                            item.third_party_code,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _vm._e(),
                          _c(
                            "td",
                            {
                              staticStyle: {
                                width: "100px",
                              },
                            },
                            [
                              _c("el-input", {
                                key: _vm.skus[index].id,
                                attrs: {
                                  type: "text",
                                  placeholder: "输入成本价",
                                },
                                model: {
                                  value: _vm.skus[index].cost_price,
                                  callback: function callback($$v) {
                                    _vm.$set(
                                      _vm.skus[index],
                                      "cost_price",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "skus[index].cost_price",
                                },
                              }),
                            ],
                            1
                          ),
                          !_vm.isMember && _vm.isMoney
                            ? _c(
                                "td",
                                [
                                  _c("el-input", {
                                    key: _vm.skus[index].id,
                                    attrs: {
                                      type: "text",
                                      placeholder: "输入划线价",
                                    },
                                    model: {
                                      value: _vm.skus[index].original_price,
                                      callback: function callback($$v) {
                                        _vm.$set(
                                          _vm.skus[index],
                                          "original_price",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "skus[index].original_price",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.isMember && _vm.isPoints
                            ? _c(
                                "td",
                                [
                                  _c("el-input", {
                                    key: _vm.skus[index].id,
                                    attrs: {
                                      type: "text",
                                      placeholder: "积分划线价",
                                    },
                                    model: {
                                      value: _vm.skus[index].original_points,
                                      callback: function callback($$v) {
                                        _vm.$set(
                                          _vm.skus[index],
                                          "original_points",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "skus[index].original_points",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isMember
                            ? _c(
                                "td",
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        placeholder: "请选择",
                                      },
                                      model: {
                                        value:
                                          _vm.skus[index].member_rights_type,
                                        callback: function callback($$v) {
                                          _vm.$set(
                                            _vm.skus[index],
                                            "member_rights_type",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "skus[index].member_rights_type",
                                      },
                                    },
                                    _vm._l(
                                      _vm.getFilterMemberRight(),
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isMoney
                            ? _c(
                                "td",
                                {
                                  staticStyle: {
                                    width: "100px",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    key: _vm.skus[index].id,
                                    attrs: {
                                      type: "text",
                                      placeholder: "输入售价",
                                    },
                                    model: {
                                      value: _vm.skus[index].retail_price,
                                      callback: function callback($$v) {
                                        _vm.$set(
                                          _vm.skus[index],
                                          "retail_price",
                                          $$v
                                        )
                                      },
                                      expression: "skus[index].retail_price",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isPoints
                            ? _c(
                                "td",
                                {
                                  staticStyle: {
                                    width: "100px",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    key: _vm.skus[index].id,
                                    attrs: {
                                      type: "text",
                                      placeholder: "积分售价",
                                    },
                                    model: {
                                      value: _vm.skus[index].retail_points,
                                      callback: function callback($$v) {
                                        _vm.$set(
                                          _vm.skus[index],
                                          "retail_points",
                                          $$v
                                        )
                                      },
                                      expression: "skus[index].retail_points",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "td",
                            {
                              staticStyle: {
                                width: "100px",
                              },
                            },
                            [
                              _c("el-input", {
                                key: _vm.skus[index].id,
                                attrs: {
                                  type: "text",
                                  placeholder: "输入库存",
                                },
                                model: {
                                  value: _vm.skus[index].stock_num,
                                  callback: function callback($$v) {
                                    _vm.$set(_vm.skus[index], "stock_num", $$v)
                                  },
                                  expression: "skus[index].stock_num",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            {
                              staticStyle: {
                                width: "100px",
                              },
                            },
                            [
                              _c("el-input", {
                                key: _vm.skus[index].id,
                                attrs: {
                                  type: "text",
                                  placeholder: "输入重量",
                                },
                                model: {
                                  value: _vm.skus[index].weight,
                                  callback: function callback($$v) {
                                    _vm.$set(_vm.skus[index], "weight", $$v)
                                  },
                                  expression: "skus[index].weight",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c("el-switch", {
                                key: _vm.skus[index].id,
                                attrs: {
                                  disabled: _vm.isDisabledSkuOnSale,
                                },
                                model: {
                                  value: _vm.skus[index].sku_on_sale,
                                  callback: function callback($$v) {
                                    _vm.$set(
                                      _vm.skus[index],
                                      "sku_on_sale",
                                      $$v
                                    )
                                  },
                                  expression: "skus[index].sku_on_sale",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }