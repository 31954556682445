var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "page-padding",
    },
    [
      _vm._m(0),
      _c(
        "el-row",
        {
          staticClass: "page-filter--wrap",
        },
        [
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [_vm._v("日期:")]
          ),
          _c("el-date-picker", {
            attrs: {
              "value-format": "yyyy-MM-dd",
              type: "daterange",
              clearable: false,
              "range-separator": "至",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
            },
            on: {
              change: function change($event) {
                return _vm.handleDateChange($event, "consumption")
              },
            },
            model: {
              value: _vm.date_time_range,
              callback: function callback($$v) {
                _vm.date_time_range = $$v
              },
              expression: "date_time_range",
            },
          }),
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [
              _c(
                "el-button-group",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function click($event) {
                          return _vm.getNdayRange(1)
                        },
                      },
                    },
                    [_vm._v(" 今天 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function click($event) {
                          return _vm.getNdayRange(2)
                        },
                      },
                    },
                    [_vm._v(" 昨天 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function click($event) {
                          return _vm.getNdayRange(7)
                        },
                      },
                    },
                    [_vm._v(" 近7天 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function click($event) {
                          return _vm.getNdayRange(30)
                        },
                      },
                    },
                    [_vm._v(" 近30天 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "page-filter--wrap",
        },
        [
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [_vm._v("商品名称:")]
          ),
          _c("el-input", {
            staticClass: "page-select--item",
            staticStyle: {
              width: "305px",
            },
            attrs: {
              placeholder: "请输入商品名称",
            },
            model: {
              value: _vm.searchForm.goodsName,
              callback: function callback($$v) {
                _vm.$set(_vm.searchForm, "goodsName", $$v)
              },
              expression: "searchForm.goodsName",
            },
          }),
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [_vm._v("商品状态:")]
          ),
          _c(
            "el-select",
            {
              staticClass: "page-select--item",
              attrs: {
                placeholder: "请选择",
              },
              model: {
                value: _vm.searchForm.goodsStates,
                callback: function callback($$v) {
                  _vm.$set(_vm.searchForm, "goodsStates", $$v)
                },
                expression: "searchForm.goodsStates",
              },
            },
            [
              _c("el-option", {
                attrs: {
                  label: "全部",
                  value: "",
                },
              }),
              _c("el-option", {
                attrs: {
                  label: "销售中",
                  value: "ONSALE",
                },
              }),
              _c("el-option", {
                attrs: {
                  label: "已售罄",
                  value: "SOLDOUT",
                },
              }),
              _c("el-option", {
                attrs: {
                  label: "补货中",
                  value: "REPLENISHING",
                },
              }),
              _c("el-option", {
                attrs: {
                  label: "待上架",
                  value: "WATTING",
                },
              }),
              _c("el-option", {
                attrs: {
                  label: "仓库中",
                  value: "WAREHOUSE",
                },
              }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: {
                round: "",
                icon: "el-icon-refresh",
              },
              on: {
                click: _vm.resetSearchForm,
              },
            },
            [_vm._v(" 重置 ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                round: "",
                icon: "el-icon-search",
              },
              on: {
                click: function click($event) {
                  return _vm.getList()
                },
              },
            },
            [_vm._v(" 搜索 ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                round: "",
                size: "mini",
                icon: "el-icon-refresh",
                loading: _vm.refreshLoading,
              },
              on: {
                click: function click($event) {
                  return _vm.handleRefresh()
                },
              },
            },
            [_vm._v(" 刷新 ")]
          ),
          _c(
            "el-popconfirm",
            {
              attrs: {
                title: "是否按当前搜索条件导出文件？",
                "confirm-button-text": "确认",
                "cancel-button-text": "取消",
                icon: "el-icon-question",
                "icon-color": "#409EFF",
              },
              on: {
                onConfirm: _vm.exportFile,
              },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: {
                    "margin-left": "10px",
                  },
                  attrs: {
                    slot: "reference",
                    type: "success",
                    round: "",
                    icon: "el-icon-download",
                    loading: _vm.downloading,
                  },
                  slot: "reference",
                },
                [_vm._v(" 导出文件 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: {
            width: "100%",
          },
          attrs: {
            data: _vm.reportData,
            stripe: "",
            border: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "序号",
              width: "60",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "spu_code",
              label: "商品编码",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "goods_name",
              label: "商品名称",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "sale_price_range",
              label: "商品价格",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "exposure_num",
              label: "曝光次数",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "exposure_people",
              label: "曝光人数",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "customer_num",
              label: "访客数",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "page_views",
              label: "浏览量",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "extra_purchase_quantity",
              label: "加购件数",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "extra_purchase_people",
              label: "加购人数",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "paid_people",
              label: "付款人数",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "paid_goods_quantity",
              label: "付款商品件数",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "paid_goods_price",
              label: "商品付款金额",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "apply_refund_order_num",
              label: "申请退款订单数",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "apply_refund_people",
              label: "申请退款人数",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "refund_success_order_num",
              label: "成功退款订单数",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "refund_success_people",
              label: "成功退款人数",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "refund_success_price",
              label: "成功退款金额",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "refund_ratio",
              label: "退款率",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "transfer_ratio",
              label: "单品转化率",
              "show-overflow-tooltip": "",
            },
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function updatePage($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function updateLimit($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "page-header--wrap",
      },
      [
        _c(
          "div",
          {
            staticClass: "page-header-wrap--title",
          },
          [_vm._v("商品效果")]
        ),
        _c("div"),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }