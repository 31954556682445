var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "page-padding",
    },
    [
      _c(
        "div",
        {
          staticClass: "page-header--wrap",
        },
        [
          _c(
            "div",
            {
              staticClass: "page-header-wrap--title",
              on: {
                click: [
                  function ($event) {
                    if (!$event.shiftKey) return null
                    return _vm.handleCount(_vm.activePositionId)
                  },
                  function ($event) {
                    if (!$event.metaKey) return null
                    return _vm.handleCount()
                  },
                ],
              },
            },
            [_vm._v("广告位")]
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-plus",
                    type: "primary",
                  },
                  on: {
                    click: _vm.handleAddScheme,
                  },
                },
                [_vm._v(" 新增 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-tabs",
        {
          on: {
            "tab-click": _vm.handleClick,
          },
          model: {
            value: _vm.activePositionId,
            callback: function callback($$v) {
              _vm.activePositionId = $$v
            },
            expression: "activePositionId",
          },
        },
        [
          _vm._l(_vm.tabData, function (item) {
            return _c("el-tab-pane", {
              key: item.id,
              attrs: {
                label: item.name,
                name: "".concat(item.id),
              },
            })
          }),
          _c(
            "el-row",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  ref: "dragTable",
                  attrs: {
                    "row-key": "id",
                    data: _vm.schemeData,
                    "row-class-name": "data-row-class",
                    stripe: "",
                    border: "",
                  },
                  on: {
                    "row-click": _vm.handleRowClick,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      type: "index",
                      label: "序号",
                      width: "60",
                    },
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        align: "center",
                        label: "排序",
                        width: "80",
                      },
                    },
                    [
                      _c("svg-icon", {
                        staticClass: "drag-handler",
                        attrs: {
                          "icon-class": "drag",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "200px",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function fn(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                },
                                on: {
                                  click: function click($event) {
                                    return _vm.handleEdit(scope.row)
                                  },
                                },
                              },
                              [_vm._v(" 编辑 ")]
                            ),
                            _c(
                              "el-popconfirm",
                              {
                                staticStyle: {
                                  "margin-left": "10px",
                                },
                                attrs: {
                                  title: "确定删除该数据吗？",
                                  placement: "left",
                                },
                                on: {
                                  onConfirm: function onConfirm($event) {
                                    return _vm.handleDeleteAd(scope.row)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      type: "text",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v(" 删除 ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "配图",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function fn(_ref) {
                          var row = _ref.row
                          return [
                            _c("el-image", {
                              staticStyle: {
                                width: "100px",
                                height: "100px",
                              },
                              attrs: {
                                fit: "contain",
                                src: row.image_address,
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "target_address",
                      label: "链接地址",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "banner_status",
                      label: "状态",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "begin_time",
                      label: "开始时间",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "end_time",
                      label: "结束时间",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "show-overflow-tooltip": "",
                      label: "创建人/修改人",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function fn(_ref2) {
                          var row = _ref2.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(row.creator) +
                                " / " +
                                _vm._s(row.updater) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      "show-overflow-tooltip": "",
                      label: "创建时间/修改时间",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function fn(_ref3) {
                          var row = _ref3.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(row.create_time) +
                                " / " +
                                _vm._s(row.update_time) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "table-tooltip",
                },
                [
                  _c("i", {
                    staticClass: "el-icon-warning-outline",
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-left": "10px",
                      },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          "配图建议尺寸：宽1125*高690px，jpg、png格式，500K以内"
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.limit,
                },
                on: {
                  "update:page": function updatePage($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function updateLimit($event) {
                    return _vm.$set(_vm.listQuery, "limit", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "".concat(
              _vm.textMap[_vm.dialogStatus],
              "\u5E7F\u544A\u4F4D"
            ),
            visible: _vm.dialogGroupForm,
            width: "550px",
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.dialogGroupForm = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "schemeForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.schemeForm,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "配图",
                    prop: "image_address",
                  },
                },
                [
                  _c("upload-img", {
                    attrs: {
                      "limit-count": 1,
                      "already-file": _vm.schemeForm.image_address,
                      token: _vm.token,
                    },
                    on: {
                      uploadSuccess: _vm.uploadPicSuccess,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "发布时间",
                  },
                },
                [
                  _c("el-date-picker", {
                    staticStyle: {
                      width: "100%",
                    },
                    attrs: {
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "开始时间",
                      "end-placeholder": "结束时间",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                    },
                    on: {
                      change: _vm.handleBeginToEndTimeChange,
                    },
                    model: {
                      value: _vm.beginToEndTime,
                      callback: function callback($$v) {
                        _vm.beginToEndTime = $$v
                      },
                      expression: "beginToEndTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "链接地址",
                    prop: "target_address",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: "请输入跳转链接",
                      maxlength: "200",
                    },
                    model: {
                      value: _vm.schemeForm.target_address,
                      callback: function callback($$v) {
                        _vm.$set(_vm.schemeForm, "target_address", $$v)
                      },
                      expression: "schemeForm.target_address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "跳转目标",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.schemeForm.target_type,
                        callback: function callback($$v) {
                          _vm.$set(_vm.schemeForm, "target_type", $$v)
                        },
                        expression: "schemeForm.target_type",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            label: _vm.TARGET_TYPE_VALUE_MAP.URL,
                          },
                        },
                        [_vm._v(" 推文 ")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            label: _vm.TARGET_TYPE_VALUE_MAP.MINI_PROGRAM_PAGE,
                          },
                        },
                        [_vm._v(" 小程序 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.schemeForm.target_type ===
              _vm.TARGET_TYPE_VALUE_MAP.MINI_PROGRAM_PAGE
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "描述",
                        prop: "mini_program_page_description",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "描述",
                          maxlength: "250",
                        },
                        model: {
                          value: _vm.schemeForm.mini_program_page_description,
                          callback: function callback($$v) {
                            _vm.$set(
                              _vm.schemeForm,
                              "mini_program_page_description",
                              $$v
                            )
                          },
                          expression:
                            "schemeForm.mini_program_page_description",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "text",
                  },
                  on: {
                    click: function click($event) {
                      _vm.dialogGroupForm = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                  },
                  on: {
                    click: _vm.handleDialogConfirm,
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.dialogStatus === "update" ? "修改" : "确定") +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.clickCountObj.id
              ? "\u5220\u9664\u5E7F\u544A\u4F4D\u3010".concat(
                  _vm.clickCountObj.name,
                  "\u3011"
                )
              : "创建广告位",
            visible: _vm.clickCountObj.dialogVisible,
          },
        },
        [
          !_vm.clickCountObj.id
            ? _c(
                "el-form",
                {
                  ref: "clickObjForm",
                  attrs: {
                    model: _vm.clickCountObj,
                    "label-width": "100",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "name",
                        label: "广告位名称",
                        required: "",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: {
                          width: "40%",
                        },
                        model: {
                          value: _vm.clickCountObj.name,
                          callback: function callback($$v) {
                            _vm.$set(_vm.clickCountObj, "name", $$v)
                          },
                          expression: "clickCountObj.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: {
                slot: "footer",
              },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function click($event) {
                      _vm.clickCountObj.dialogVisible = false
                    },
                  },
                },
                [_vm._v(" 取 消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                  },
                  on: {
                    click: function click($event) {
                      return _vm.handleConfirmCountDialog(_vm.clickCountObj.id)
                    },
                  },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }