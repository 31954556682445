var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "transition",
    {
      attrs: {
        name: "vac-slide-up",
      },
    },
    [
      _vm.filteredEmojis.length
        ? _c(
            "div",
            {
              staticClass: "vac-emojis-container vac-app-box-shadow",
              style: {
                bottom: "".concat(
                  _vm.$parent.$refs.roomFooter.clientHeight,
                  "px"
                ),
              },
            },
            _vm._l(_vm.filteredEmojis, function (emoji) {
              return _c(
                "div",
                {
                  key: emoji,
                  staticClass: "vac-emoji-element",
                  on: {
                    click: function click($event) {
                      return _vm.$emit("select-emoji", emoji)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(emoji) + " ")]
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }