<template>
  <div class="page-padding">
    <div class="page-header--wrap">
      <div class="page-header-wrap--title" @click.shift="handleCount(activePositionId)" @click.meta="handleCount()">广告位</div>
      <div>
        <el-button icon="el-icon-plus" type="primary" @click="handleAddScheme"> 新增 </el-button>
      </div>
    </div>
    <el-tabs v-model="activePositionId" @tab-click="handleClick">
      <el-tab-pane v-for="item in tabData" :key="item.id" :label="item.name" :name="`${item.id}`" />
      <el-row>
        <el-table
          ref="dragTable"
          v-loading="listLoading"
          row-key="id"
          :data="schemeData"
          row-class-name="data-row-class"
          stripe
          border
          @row-click="handleRowClick"
        >
          <el-table-column align="center" type="index" label="序号" width="60" />
          <el-table-column align="center" label="排序" width="80">
            <svg-icon class="drag-handler" icon-class="drag" />
          </el-table-column>
          <el-table-column label="操作" width="200px" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="handleEdit(scope.row)"> 编辑 </el-button>
              <el-popconfirm title="确定删除该数据吗？" style="margin-left: 10px" placement="left" @onConfirm="handleDeleteAd(scope.row)">
                <el-button slot="reference" type="text"> 删除 </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
          <!-- <el-table-column class-name="img-column" align="center" label="配图"> -->
          <el-table-column align="center" label="配图">
            <template slot-scope="{ row }">
              <el-image fit="contain" style="width: 100px; height: 100px" :src="row.image_address" />
            </template>
          </el-table-column>
          <el-table-column align="center" prop="target_address" label="链接地址" />
          <el-table-column align="center" prop="banner_status" label="状态" />
          <el-table-column align="center" prop="begin_time" label="开始时间" />
          <el-table-column align="center" prop="end_time" label="结束时间" />

          <el-table-column align="center" show-overflow-tooltip label="创建人/修改人">
            <template slot-scope="{ row }"> {{ row.creator }} / {{ row.updater }} </template>
          </el-table-column>
          <el-table-column align="center" show-overflow-tooltip label="创建时间/修改时间">
            <template slot-scope="{ row }"> {{ row.create_time }} / {{ row.update_time }} </template>
          </el-table-column>
        </el-table>
        <div class="table-tooltip">
          <i class="el-icon-warning-outline" />
          <div style="margin-left: 10px">
            <div>配图建议尺寸：宽1125*高690px，jpg、png格式，500K以内</div>
          </div>
        </div>
        <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
      </el-row>
    </el-tabs>

    <el-dialog :title="`${textMap[dialogStatus]}广告位`" :visible.sync="dialogGroupForm" width="550px">
      <el-form ref="schemeForm" :rules="rules" :model="schemeForm" label-width="80px">
        <el-form-item label="配图" prop="image_address">
          <upload-img :limit-count="1" :already-file="schemeForm.image_address" :token="token" @uploadSuccess="uploadPicSuccess" />
        </el-form-item>
        <el-form-item label="发布时间">
          <el-date-picker
            v-model="beginToEndTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 100%"
            @change="handleBeginToEndTimeChange"
          />
        </el-form-item>
        <el-form-item label="链接地址" prop="target_address">
          <el-input v-model="schemeForm.target_address" type="text" placeholder="请输入跳转链接" maxlength="200" />
        </el-form-item>
        <el-form-item label="跳转目标">
          <el-radio-group v-model="schemeForm.target_type">
            <el-radio :label="TARGET_TYPE_VALUE_MAP.URL"> 推文 </el-radio>
            <el-radio :label="TARGET_TYPE_VALUE_MAP.MINI_PROGRAM_PAGE"> 小程序 </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="schemeForm.target_type === TARGET_TYPE_VALUE_MAP.MINI_PROGRAM_PAGE" label="描述" prop="mini_program_page_description">
          <el-input v-model="schemeForm.mini_program_page_description" type="textarea" placeholder="描述" maxlength="250" />
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button type="text" @click="dialogGroupForm = false"> 取消 </el-button>
        <el-button type="primary" @click="handleDialogConfirm">
          {{ dialogStatus === "update" ? "修改" : "确定" }}
        </el-button>
      </div>
    </el-dialog>
    <el-dialog :title="clickCountObj.id ? `删除广告位【${clickCountObj.name}】` : '创建广告位'" :visible="clickCountObj.dialogVisible">
      <el-form v-if="!clickCountObj.id" ref="clickObjForm" :model="clickCountObj" label-width="100">
        <el-form-item prop="name" label="广告位名称" required>
          <el-input v-model="clickCountObj.name" style="width: 40%" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clickCountObj.dialogVisible = false"> 取 消 </el-button>
        <el-button type="primary" @click="handleConfirmCountDialog(clickCountObj.id)"> 确 定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UploadImg from "@/components/Upload/UploadImg";
import Pagination from "@/components/Pagination";
import TableListMixin from "@/mixin/TableList";
import Sortable from "sortablejs";
import api from "@/api";
import { commonUtil } from "@/utils";
import * as R from "ramda";

const { to } = commonUtil;
const TARGET_TYPE_VALUE_MAP = {
  MINI_PROGRAM_PAGE: "MINI_PROGRAM_PAGE",
  URL: "URL",
};
const initSchemeFormData = {
  id: undefined,
  image_address: "",
  mini_program_page_description: null,
  position_id: 0,
  target_address: null,
  target_type: TARGET_TYPE_VALUE_MAP.URL,
  begin_time: null,
  end_time: null,
};

const addressValidator = (_, value, callback) => {
  if (/^\s+|\W+$/.test(value)) {
    callback("路径不能为纯字符路径");
  }
  callback();
};
const rules = { image_address: [{ required: true, message: "请先上传配图" }], target_address: [{ validator: addressValidator, trigger: "blur" }] };

export default {
  name: "AdBanner",
  components: { UploadImg, Pagination },
  mixins: [TableListMixin],
  data() {
    return {
      TARGET_TYPE_VALUE_MAP,
      clickCountObj: {
        times: 0,
        name: undefined,
        dialogVisible: false,
        id: undefined,
      }, // 用于计数点击广告位标题次数，点击次数达到一定次数后，弹出创建或删除窗口
      tabData: [],
      token: "",
      sortable: null,
      activePositionId: "",
      listLoading: false,
      is_create: true,
      dialogGroupForm: false,
      beginToEndTime: [],
      schemeData: [],
      schemeForm: R.clone(initSchemeFormData),
      searchForm: {
        position_id: 0,
      },
      rules,
    };
  },
  async created() {
    to(this.getToken());
    to(this.init());
  },
  methods: {
    async init() {
      const [err] = await to(this.getTabList());
      if (err) return;
      to(this.getList());
    },
    handleCount(id) {
      this.clickCountObj.id === id ? this.clickCountObj.times++ : (this.clickCountObj.times = 1);
      this.clickCountObj.name = this.tabData.find((item) => item.id === +id)?.name;
      this.clickCountObj.id = id;
      if (this.clickCountObj.times >= 5) {
        this.clickCountObj.times = 0;
        !id && (this.clickCountObj.name = undefined);
        this.clickCountObj.dialogVisible = true;
      }
    },
    handleConfirmCountDialog(id) {
      id ? this.handleDeletePosition() : this.handleAddPosition();
    },
    async handleAddPosition() {
      const [, res] = await to(this.$refs.clickObjForm.validate());
      if (!res) return;
      const [err] = await api.advertisement.bannerTags({
        method: "post",
        data: {
          name: this.clickCountObj.name,
          seq: 0,
        },
      });
      if (err) return;
      this.clickCountObj.dialogVisible = false;
      this.$message.success("创建成功");
      to(this.init());
    },
    async handleDeletePosition() {
      const [, res] = await to(
        this.$confirm("删除后无法恢复，是否继续删除！", "提示", {
          type: "warning",
        })
      );

      if (!res) return;
      const [err] = await api.advertisement.bannerTags({
        method: "delete",
        id: this.clickCountObj.id,
      });
      if (err) return;
      this.clickCountObj.dialogVisible = false;
      this.$message.success("删除成功");
      to(this.init());
    },
    async getToken() {
      [, this.token] = await api.auth.getQiNiuToken();
    },
    async getTabList() {
      const [, res] = await api.advertisement.bannerTags();
      this.searchForm.position_id = this.schemeForm.position_id = res?.[0]?.id ?? "";
      if (!this.searchForm.position_id) throw new Error("没有广告位位置信息");
      this.activePositionId = `${this.schemeForm.position_id}`;
      this.tabData = res;
    },
    setSort() {
      const el = this.$refs.dragTable.$el.querySelectorAll(".el-table__body-wrapper > table > tbody")[0];
      this.sortable = Sortable.create(el, {
        ghostClass: "sortable-ghost", // Class name for the drop placeholder,
        setData: function (dataTransfer) {
          dataTransfer.setData("Text", "");
        },
        onEnd: (evt) => {
          const { oldIndex, newIndex } = evt;
          if (oldIndex === newIndex) return;
          let { schemeData } = this;
          const targetRow = schemeData[newIndex];
          const sourceRow = schemeData.splice(oldIndex, 1)[0];
          this.schemeData.splice(newIndex, 0, sourceRow);

          this.sortRequest(sourceRow.id, targetRow.id, newIndex === 0 ? "FRONT" : "BACK");
        },
      });
    },
    async sortRequest(source_banner_id, target_banner_id, front_back) {
      const data = { source_banner_id, target_banner_id, front_back };
      const [err] = await api.advertisement.moveLocation({
        method: "post",
        data,
      });

      if (err) await this.getList();
      else this.$notify.success("操作成功");
    },
    setSchemeForm(row) {
      this.beginToEndTime = row.begin_time && row.end_time ? [row.begin_time, row.end_time] : [];
      return R.pick([...Object.keys(this.schemeForm), "id"], { ...row, position_id: this.schemeForm.position_id });
    },
    handleEdit() {
      this.dialogStatus = "update";
      this.dialogGroupForm = true;
      this.handleClearValidate();
    },
    async handleDeleteAd(row) {
      const [err] = await api.advertisement.banners({
        method: "delete",
        id: row.id,
      });
      if (err) return;
      this.$notify.success("删除成功");
      await this.getList();
    },
    handleClick(tab) {
      this.searchForm.position_id = tab.name;
      this.getList();
    },
    async getList() {
      // 获取数据
      const [, res] = await this.getListMixin(api.advertisement.banners);
      this.schemeData = res;
      this.$nextTick(() => {
        this.setSort();
      });
    },
    handleRowClick(row) {
      this.schemeForm = this.setSchemeForm(R.clone(row));
    },
    handleBeginToEndTimeChange(time) {
      [this.schemeForm.begin_time, this.schemeForm.end_time] = time ?? [];
    },
    uploadPicSuccess(val) {
      this.schemeForm.image_address = val.length ? val[0]["url"] : "";
    },
    async handleDialogConfirm() {
      const [isValid] = await to(this.$refs.schemeForm.validate());
      if (isValid === false) return;
      const { id } = this.schemeForm;
      let method = id ? "put" : "post";
      const data = id ? R.omit(["id", "position_id"], this.schemeForm) : R.omit(["id"], this.schemeForm);
      const [err] = await api.advertisement.banners({
        method,
        id,
        data,
      });
      if (err) return;
      this.dialogGroupForm = false;
      this.$notify.success("操作成功");
      await this.getList();
    },
    handleAddScheme() {
      this.resetSchemeFormData();
      this.dialogStatus = "create";
      this.dialogGroupForm = true;
      this.beginToEndTime = [];
      this.schemeForm.position_id = this.activePositionId;
      this.handleClearValidate();
    },
    handleClearValidate() {
      this.$nextTick(() => {
        this.$refs.schemeForm.clearValidate();
      });
    },
    resetSchemeFormData() {
      this.schemeForm = R.clone(initSchemeFormData);
    },
    handleUpdateScheme(row) {
      this.schemeForm = Object.assign({}, row);
      this.dialogStatus = "update";
      this.dialogGroupForm = true;
    },
  },
};
</script>

<style>
.data-row-class {
  height: 90px;
}
.img-column {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 113px;
}
.img-cover {
  height: 90px;
  line-height: 40px;
  width: 90px;
  display: flex;
  border: 1px dashed #ccc;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.table-tooltip {
  color: rgb(187, 187, 187);
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
</style>
