import * as R from "ramda";

// https://github.com/scopsy/await-to-js
export function to(promise, errorExt) {
  return promise
    .then((data) => [null, data])
    .catch((err) => {
      if (errorExt) {
        const parsedError = Object.assign({}, err, errorExt);
        return [parsedError, undefined];
      }
      return [err, undefined];
    });
}

export async function tryCatch(fn, ...args) {
  try {
    const res = await fn(...args);
    return [null, res];
  } catch (err) {
    console.error(err);
    return [err, undefined];
  }
}

// https://stackoverflow.com/questions/27078285/simple-throttle-in-javascript
export function throttle(func, wait, options = {}) {
  let context, result;
  let timeout = null;
  let previous = 0;
  const later = function (...args) {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = null;
  };
  return function (...args) {
    let now = Date.now();
    if (!previous && options.leading === false) previous = now;
    const remaining = wait - (now - previous);
    context = this;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
}

export async function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const isDefined = R.compose(R.not, R.equals(undefined));
export const pickDefined = R.pickBy(isDefined);
