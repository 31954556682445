<template>
  <div>
    <el-table :data="scheduleDetailData" style="width: 100%">
      <el-table-column align="center" prop="params" label="期数" />
      <el-table-column align="center" label="操作">
        <template slot-scope="{ row }">
          <el-popconfirm title="确定跳过本期吗？" placement="left" @onConfirm="handleSkipDispatch(row)">
            <el-button slot="reference" :disabled="row.order_states != 'NODELIVERY'" type="text"> 跳过本期 </el-button>
          </el-popconfirm>
          <!-- <el-button type="text" @click="handleDispatchOrder(row)" style="margin-right: 10px">发货</el-button> -->
        </template>
      </el-table-column>
      <el-table-column align="center" prop="item_no" label="发货单编号" />
      <el-table-column align="center" prop="solar_term_time" label="日期" />
      <el-table-column align="center" prop="solar_term" label="所属节气" />
      <el-table-column align="center" prop="pre_sent_time" label="预计发货时间" />
      <el-table-column align="center" prop="" label="发货状态">
        <template slot-scope="{ row }">
          {{ row.order_states | regularOrderDetailText }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="sent_time" label="发货时间" />
    </el-table>
    <div class="dialog-footer">
      <!-- <el-button type="text" @click="handleCancel">取消</el-button> -->
      <el-button type="primary" @click="handleConfirm"> 关闭 </el-button>
    </div>
  </div>
</template>

<script>
import { regularOrderDetailText } from "@/filters/regular_order";
export default {
  filters: { regularOrderDetailText },
  props: {
    // orderNo: {
    //   type: String,
    //   default: "",
    // },
    // scheduleDetailData: [],
    scheduleDetailData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    handleSkipDispatch(row) {
      this.axios.post(`/regular_goods/updateIsSkip`, { is_skip: true, item_no: row.item_no }).then(() => {
        this.$message.success("操作成功");
        row.order_states = "SKIP";
      });
    },
    handleDispatchOrder() {},
    handleCancel() {
      this.$emit("closed", "cancel");
    },
    handleConfirm() {
      this.$emit("closed", "cancel");
      // this.$refs.form.validate((valid) => {
      //   if (valid) {
      //     this.form.regular_order_no = this.orderNo;
      //     this.axios.post(`/regular_goods/activate`, { ...this.form }).then(() => {
      //       this.$emit("closed", "confirm");
      //       this.$refs.form.resetFields();
      //       this.selected_city = [];
      //     });
      //   }
      // });
    },
  },
};
</script>

<style></style>
