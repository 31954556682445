var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "page-header flex justify-between py-[10px] items-center",
    },
    [
      _c(
        "div",
        {
          staticClass: "text-[20px] text-[#303133] leading-[22px]",
        },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _vm._t("right"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }