<template>
  <div class="page-padding">
    <div class="page-header--wrap">
      <div class="page-header-wrap--title">留言反馈</div>
    </div>
    <el-row class="page-filter--wrap">
      <div class="page-color--normal">日期</div>
      <el-date-picker
        v-model="order_time"
        value-format="yyyy-MM-dd HH:mm:ss"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        clearable
        unlink-panels
        @change="handleDateChange"
      />
      <div class="page-color--normal">商品名称</div>
      <el-input v-model="searchForm.spuName" clearable placeholder="请输入商品名称" class="page-select--item" style="width: 150px" />
      <el-button round style="margin-left: 20px" icon="el-icon-refresh" @click="resetSearchForm"> 重置 </el-button>
      <el-button type="primary" round icon="el-icon-search" @click="getList"> 搜索 </el-button>
    </el-row>
    <el-table v-loading="listLoading" :data="feedbackData" stripe border highlight-current-row style="width: 100%">
      <el-table-column type="index" align="center" width="60">
        <template slot="header"> 序号 </template>
      </el-table-column>

      <el-table-column align="center" prop="remark_time" label="反馈时间" show-overflow-tooltip />
      <el-table-column align="center" prop="spu_name" label="商品名称" show-overflow-tooltip />
      <el-table-column align="center" prop="remark_name" label="用户名" show-overflow-tooltip />
      <el-table-column align="center" prop="remark_phone" label="手机号" show-overflow-tooltip />
      <!-- <el-table-column align="center" prop="labels" label="您遇到哪方面问题？（多选）" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <div v-for="(item, index) in row.labels" :key="index">- {{ item | labelToText }}</div>
        </template>
      </el-table-column> -->
      <el-table-column align="center" prop="remark_str" label="留言内容" />
    </el-table>
    <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import TableListMixin from "@/mixin/TableList";
import api from "@/api";
import * as R from "ramda";

const initFormData = {
  startTime: undefined,
  endTime: undefined,
  spuName: undefined,
};
export default {
  name: "GoodsFeedback",
  components: { Pagination },
  filters: {},
  mixins: [TableListMixin],
  data() {
    return {
      order_time: [],
      searchForm: R.clone(initFormData),
      feedbackData: [],
    };
  },
  created() {
    this.getList();
  },

  methods: {
    resetSearchForm() {
      this.order_time = [];
      this.searchForm = R.clone(initFormData);
      this.getList();
    },
    handleDateChange(val) {
      [this.searchForm.startTime, this.searchForm.endTime] = val ?? [];
    },
    async getList() {
      const [, res] = await this.getListMixin(api.goods.remark);
      this.feedbackData = res?.list ?? [];
    },

    // 点击遮罩关闭预览
    handleClickItem() {
      this.$nextTick(() => {
        // 获取遮罩层dom
        let domImageMask = document.querySelector(".el-image-viewer__mask");
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener("click", () => {
          // 点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector(".el-image-viewer__close").click();
        });
      });
    },
  },
};
</script>

<style></style>
