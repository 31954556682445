var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      ref: _vm.message._id,
      attrs: {
        id: _vm.message._id,
      },
    },
    [
      _vm.showDate
        ? _c(
            "div",
            {
              staticClass: "vac-card-info vac-card-date",
            },
            [_vm._v(" " + _vm._s(_vm.message.date) + " ")]
          )
        : _vm._e(),
      _vm.message.system
        ? _c(
            "div",
            {
              staticClass: "vac-card-info vac-card-system",
            },
            [_vm._v(" " + _vm._s(_vm.message.content) + " ")]
          )
        : _c(
            "div",
            {
              staticClass: "vac-message-box",
              class: {
                "vac-offset-current":
                  _vm.message.senderId === _vm.currentUserId,
              },
            },
            [
              _vm._t(
                "message",
                function () {
                  return [
                    _vm.message.avatar &&
                    _vm.message.senderId !== _vm.currentUserId
                      ? _c("div", {
                          staticClass: "vac-avatar",
                          style: {
                            "background-image": "url('".concat(
                              _vm.message.avatar,
                              "')"
                            ),
                          },
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "vac-message-container",
                        class: {
                          "vac-message-container-offset": _vm.messageOffset,
                        },
                        on: {
                          click: function click($event) {
                            return _vm.handleMessageClick(_vm.message)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "vac-message-card",
                            class: {
                              "vac-message-highlight": _vm.isMessageHover,
                              "vac-message-current":
                                _vm.message.senderId === _vm.currentUserId,
                              "vac-message-deleted": _vm.message.deleted,
                            },
                          },
                          [
                            _vm.roomUsers.length > 2 &&
                            _vm.message.senderId !== _vm.currentUserId
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "vac-text-username",
                                    class: {
                                      "vac-username-reply":
                                        !_vm.message.deleted &&
                                        _vm.message.replyMessage,
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.message.username)),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            !_vm.message.deleted && _vm.message.replyMessage
                              ? _c("message-reply", {
                                  attrs: {
                                    message: _vm.message,
                                    "room-users": _vm.roomUsers,
                                    "text-formatting": _vm.textFormatting,
                                    "link-options": _vm.linkOptions,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      _vm._l(
                                        _vm.$scopedSlots,
                                        function (i, name) {
                                          return {
                                            key: name,
                                            fn: function fn(data) {
                                              return [
                                                _vm._t(name, null, null, data),
                                              ]
                                            },
                                          }
                                        }
                                      ),
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : _vm._e(),
                            _vm.message.deleted
                              ? _c(
                                  "div",
                                  [
                                    _vm._t("deleted-icon", function () {
                                      return [
                                        _c("svg-icon", {
                                          staticClass: "vac-icon-deleted",
                                          attrs: {
                                            name: "deleted",
                                          },
                                        }),
                                      ]
                                    }),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.textMessages.MESSAGE_DELETED)
                                      ),
                                    ]),
                                  ],
                                  2
                                )
                              : !_vm.message.file
                              ? _c("format-message", {
                                  attrs: {
                                    content: _vm.message.content,
                                    users: _vm.roomUsers,
                                    "text-formatting": _vm.textFormatting,
                                    "link-options": _vm.linkOptions,
                                  },
                                  on: {
                                    "open-user-tag": _vm.openUserTag,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      _vm._l(
                                        _vm.$scopedSlots,
                                        function (i, name) {
                                          return {
                                            key: name,
                                            fn: function fn(data) {
                                              return [
                                                _vm._t(name, null, null, data),
                                              ]
                                            },
                                          }
                                        }
                                      ),
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : _vm.isImage
                              ? _c("message-image", {
                                  attrs: {
                                    "current-user-id": _vm.currentUserId,
                                    message: _vm.message,
                                    "room-users": _vm.roomUsers,
                                    "text-formatting": _vm.textFormatting,
                                    "link-options": _vm.linkOptions,
                                    "image-hover": _vm.imageHover,
                                  },
                                  on: {
                                    "open-file": _vm.openFile,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      _vm._l(
                                        _vm.$scopedSlots,
                                        function (i, name) {
                                          return {
                                            key: name,
                                            fn: function fn(data) {
                                              return [
                                                _vm._t(name, null, null, data),
                                              ]
                                            },
                                          }
                                        }
                                      ),
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : _vm.isVideo
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "vac-video-container",
                                  },
                                  [
                                    _c(
                                      "video",
                                      {
                                        attrs: {
                                          width: "100%",
                                          height: "100%",
                                          controls: "",
                                        },
                                      },
                                      [
                                        _c("source", {
                                          attrs: {
                                            src: _vm.message.file.url,
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm.isAudio
                              ? _c("audio-player", {
                                  attrs: {
                                    src: _vm.message.file.url,
                                  },
                                  on: {
                                    "update-progress-time":
                                      function updateProgressTime($event) {
                                        _vm.progressTime = $event
                                      },
                                    "hover-audio-progress":
                                      function hoverAudioProgress($event) {
                                        _vm.hoverAudioProgress = $event
                                      },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      _vm._l(
                                        _vm.$scopedSlots,
                                        function (i, name) {
                                          return {
                                            key: name,
                                            fn: function fn(data) {
                                              return [
                                                _vm._t(name, null, null, data),
                                              ]
                                            },
                                          }
                                        }
                                      ),
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : _c(
                                  "div",
                                  {
                                    staticClass: "vac-file-message",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vac-svg-button vac-icon-file",
                                        on: {
                                          click: function click($event) {
                                            $event.stopPropagation()
                                            return _vm.openFile("download")
                                          },
                                        },
                                      },
                                      [
                                        _vm._t("document-icon", function () {
                                          return [
                                            _c("svg-icon", {
                                              attrs: {
                                                name: "document",
                                              },
                                            }),
                                          ]
                                        }),
                                      ],
                                      2
                                    ),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.message.content)),
                                    ]),
                                  ]
                                ),
                            _vm.isAudio && !_vm.message.deleted
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "vac-progress-time",
                                  },
                                  [_vm._v(" " + _vm._s(_vm.progressTime) + " ")]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "vac-text-timestamp",
                              },
                              [
                                _vm.message.edited && !_vm.message.deleted
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "vac-icon-edited",
                                      },
                                      [
                                        _vm._t("pencil-icon", function () {
                                          return [
                                            _c("svg-icon", {
                                              attrs: {
                                                name: "pencil",
                                              },
                                            }),
                                          ]
                                        }),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.message.timestamp)),
                                ]),
                                _vm.isCheckmarkVisible
                                  ? _c(
                                      "span",
                                      [
                                        _vm._t(
                                          "checkmark-icon",
                                          function () {
                                            return [
                                              _c("svg-icon", {
                                                staticClass: "vac-icon-check",
                                                attrs: {
                                                  name: _vm.message.distributed
                                                    ? "double-checkmark"
                                                    : "checkmark",
                                                  param: _vm.message.seen
                                                    ? "seen"
                                                    : "",
                                                },
                                              }),
                                            ]
                                          },
                                          null,
                                          {
                                            message: _vm.message,
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _c("message-actions", {
                              attrs: {
                                "current-user-id": _vm.currentUserId,
                                message: _vm.message,
                                "message-actions": _vm.messageActions,
                                "room-footer-ref": _vm.roomFooterRef,
                                "show-reaction-emojis": _vm.showReactionEmojis,
                                "hide-options": _vm.hideOptions,
                                "message-hover": _vm.messageHover,
                                "hover-message-id": _vm.hoverMessageId,
                                "hover-audio-progress": _vm.hoverAudioProgress,
                              },
                              on: {
                                "hide-options": function hideOptions($event) {
                                  return _vm.$emit("hide-options", false)
                                },
                                "update-message-hover":
                                  function updateMessageHover($event) {
                                    _vm.messageHover = $event
                                  },
                                "update-options-opened":
                                  function updateOptionsOpened($event) {
                                    _vm.optionsOpened = $event
                                  },
                                "update-emoji-opened":
                                  function updateEmojiOpened($event) {
                                    _vm.emojiOpened = $event
                                  },
                                "message-action-handler":
                                  _vm.messageActionHandler,
                                "send-message-reaction":
                                  function sendMessageReaction($event) {
                                    return _vm.sendMessageReaction($event)
                                  },
                              },
                              scopedSlots: _vm._u(
                                [
                                  _vm._l(_vm.$scopedSlots, function (i, name) {
                                    return {
                                      key: name,
                                      fn: function fn(data) {
                                        return [_vm._t(name, null, null, data)]
                                      },
                                    }
                                  }),
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _c("message-reactions", {
                          attrs: {
                            "current-user-id": _vm.currentUserId,
                            message: _vm.message,
                            "emojis-list": _vm.emojisList,
                          },
                          on: {
                            "send-message-reaction":
                              function sendMessageReaction($event) {
                                return _vm.sendMessageReaction($event)
                              },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                null,
                {
                  message: _vm.message,
                }
              ),
            ],
            2
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }