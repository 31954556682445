export default {
  ROOMS_EMPTY: "会话中列表为空",
  ROOM_EMPTY: "暂无会话消息",
  NEW_MESSAGES: "新消息",
  MESSAGE_DELETED: "This message was deleted",
  MESSAGES_EMPTY: "无历史会话",
  CONVERSATION_STARTED: "会话开始于：",
  TYPE_MESSAGE: "在此处输入消息",
  SEARCH: "搜索",
  IS_ONLINE: "在线",
  LAST_SEEN: "最近沟通",
  IS_TYPING: "正在输入...",
};
