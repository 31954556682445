var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: "".concat(
          _vm.ruleObject && _vm.ruleObject.id ? "修改" : "新增",
          "\u5173\u952E\u5B57"
        ),
      },
      on: {
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          attrs: {
            "label-width": "80px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "规则名称",
              },
            },
            [
              _c("el-input", {
                staticStyle: {
                  width: "30%",
                },
                attrs: {
                  placeholder: "请输入规则名称",
                },
                model: {
                  value: _vm.ruleObject.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.ruleObject, "name", $$v)
                  },
                  expression: "ruleObject.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "关键词",
              },
            },
            [
              _vm._l(_vm.ruleObject.key_info_list, function (item, index) {
                return [
                  _c(
                    "div",
                    {
                      key: index,
                      staticClass: "flex mb-[10px]",
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "mr-[10px]",
                          staticStyle: {
                            width: "20%",
                          },
                          attrs: {
                            placeholder: "请输入规则名称",
                          },
                          model: {
                            value: item.match,
                            callback: function callback($$v) {
                              _vm.$set(item, "match", $$v)
                            },
                            expression: "item.match",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              value: "HALF",
                              label: "半匹配",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              value: "ALL",
                              label: "全匹配",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("el-input", {
                        staticClass: "mr-[10px]",
                        staticStyle: {
                          width: "20%",
                        },
                        attrs: {
                          placeholder: "请输入关键词",
                        },
                        model: {
                          value: item.key,
                          callback: function callback($$v) {
                            _vm.$set(item, "key", $$v)
                          },
                          expression: "item.key",
                        },
                      }),
                      _vm.ruleObject.key_info_list.length <
                      _vm.MAX_KEYWORD_LENGTH
                        ? _c("el-button", {
                            attrs: {
                              type: "primary",
                              icon: "el-icon-plus",
                              circle: "",
                            },
                            on: {
                              click: function click($event) {
                                return _vm.handleAdd(index)
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.ruleObject.key_info_list.length > 1
                        ? _c("el-button", {
                            attrs: {
                              type: "primary",
                              icon: "el-icon-minus",
                              circle: "",
                            },
                            on: {
                              click: function click($event) {
                                return _vm.handleDelete(index)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "回复类型",
              },
            },
            [
              _c(
                "el-radio",
                {
                  attrs: {
                    label: "TEXT",
                  },
                  model: {
                    value: _vm.ruleObject.message_type,
                    callback: function callback($$v) {
                      _vm.$set(_vm.ruleObject, "message_type", $$v)
                    },
                    expression: "ruleObject.message_type",
                  },
                },
                [_vm._v(" 文字 ")]
              ),
              _c(
                "el-radio",
                {
                  attrs: {
                    label: "IMAGE",
                  },
                  model: {
                    value: _vm.ruleObject.message_type,
                    callback: function callback($$v) {
                      _vm.$set(_vm.ruleObject, "message_type", $$v)
                    },
                    expression: "ruleObject.message_type",
                  },
                },
                [_vm._v(" 图片 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "回复内容",
              },
            },
            [
              _vm.ruleObject.message_type === "TEXT"
                ? _c("el-input", {
                    staticStyle: {
                      width: "50%",
                    },
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入回复文案",
                      autosize: {
                        minRows: 5,
                        maxRows: 7,
                      },
                      maxlength: "200",
                    },
                    model: {
                      value: _vm.ruleObject.response_text,
                      callback: function callback($$v) {
                        _vm.$set(_vm.ruleObject, "response_text", $$v)
                      },
                      expression: "ruleObject.response_text",
                    },
                  })
                : _c("UploadImg", {
                    attrs: {
                      accept: "image/jpg,image/png,image/jpeg",
                      token: _vm.token,
                      "limit-count": 1,
                      "already-file": _vm.ruleObject.image_url,
                    },
                    on: {
                      uploadSuccess: _vm.handleUploadSuccess,
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: {
            slot: "footer",
          },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: _vm.close,
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
              },
              on: {
                click: _vm.handleConfirm,
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }