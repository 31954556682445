var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "vac-card-window",
      style: [
        {
          height: _vm.height,
        },
        _vm.cssVars,
      ],
    },
    [
      _c(
        "div",
        {
          staticClass: "vac-chat-container",
        },
        [
          !_vm.singleRoom
            ? _c("rooms-list", {
                attrs: {
                  "current-user-id": _vm.currentUserId,
                  rooms: _vm.orderedRooms,
                  "loading-rooms": _vm.loadingRooms,
                  "rooms-loaded": _vm.roomsLoaded,
                  room: _vm.room,
                  "finished-rooms": _vm.finishedRooms,
                  "room-actions": _vm.roomActions,
                  "text-messages": _vm.t,
                  "show-add-room": _vm.showAddRoom,
                  "show-rooms-list": _vm.showRoomsList,
                  "text-formatting": _vm.textFormatting,
                  "link-options": _vm.linkOptions,
                  "is-mobile": _vm.isMobile,
                },
                on: {
                  "fetch-room": _vm.fetchRoom,
                  "fetch-more-rooms": _vm.fetchMoreRooms,
                  "loading-more-rooms": function loadingMoreRooms($event) {
                    _vm.loadingMoreRooms = $event
                  },
                  "add-room": _vm.addRoom,
                  "room-action-handler": _vm.roomActionHandler,
                },
                scopedSlots: _vm._u(
                  [
                    _vm._l(_vm.$scopedSlots, function (i, name) {
                      return {
                        key: name,
                        fn: function fn(data) {
                          return [_vm._t(name, null, null, data)]
                        },
                      }
                    }),
                  ],
                  null,
                  true
                ),
              })
            : _vm._e(),
          _vm.currentRoom.roomId
            ? [
                _c("room", {
                  attrs: {
                    "current-user-id": _vm.currentUserId,
                    rooms: _vm.rooms,
                    "room-id": _vm.room.roomId || "",
                    "load-first-room": _vm.loadFirstRoom,
                    messages: _vm.messages,
                    "room-message": _vm.roomMessage,
                    "messages-loaded": _vm.messagesLoaded,
                    "menu-actions": _vm.menuActions,
                    "message-actions": _vm.messageActions,
                    "show-send-icon": _vm.showSendIcon,
                    "show-files": _vm.showFiles,
                    "show-audio": _vm.showAudio,
                    "show-emojis": _vm.showEmojis,
                    "show-reaction-emojis": _vm.showReactionEmojis,
                    "show-new-messages-divider": _vm.showNewMessagesDivider,
                    "show-footer": _vm.showFooter,
                    "text-messages": _vm.t,
                    "single-room": _vm.singleRoom,
                    "show-rooms-list": _vm.showRoomsList,
                    "text-formatting": _vm.textFormatting,
                    "link-options": _vm.linkOptions,
                    "is-mobile": _vm.isMobile,
                    "loading-rooms": _vm.loadingRooms,
                    "room-info": _vm.$listeners["room-info"],
                    "textarea-action":
                      _vm.$listeners["textarea-action-handler"],
                    "accepted-files": _vm.acceptedFiles,
                  },
                  on: {
                    "toggle-rooms-list": _vm.toggleRoomsList,
                    "room-info": _vm.roomInfo,
                    "fetch-messages": _vm.fetchMessages,
                    "send-message": _vm.sendMessage,
                    "edit-message": _vm.editMessage,
                    "delete-message": _vm.deleteMessage,
                    "open-file": _vm.openFile,
                    "open-user-tag": _vm.openUserTag,
                    "menu-action-handler": _vm.menuActionHandler,
                    "message-action-handler": _vm.messageActionHandler,
                    "send-message-reaction": _vm.sendMessageReaction,
                    "typing-message": _vm.typingMessage,
                    "textarea-action-handler": _vm.textareaActionHandler,
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm._l(_vm.$scopedSlots, function (i, name) {
                        return {
                          key: name,
                          fn: function fn(data) {
                            return [_vm._t(name, null, null, data)]
                          },
                        }
                      }),
                    ],
                    null,
                    true
                  ),
                }),
                _c("room-right", {
                  staticStyle: {
                    flex: "0 0 25%",
                    "min-width": "260px",
                    "max-width": "500px",
                  },
                  on: {
                    "send-message": _vm.sendMessage,
                  },
                }),
              ]
            : [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      "text-align": "center",
                      "line-height": "500px",
                      color: "#bbb",
                    },
                  },
                  [_vm._v("暂无会话消息")]
                ),
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }