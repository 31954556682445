<template>
  <div class="page-padding">
    <div class="page-header--wrap">
      <div class="page-header-wrap--title">全店日报</div>
      <div />
    </div>

    <el-row class="page-filter--wrap">
      <div class="page-color--normal">营业日期:</div>
      <el-date-picker
        v-model="date_time_range"
        value-format="yyyy-MM-dd"
        type="daterange"
        size="mini"
        :clearable="false"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="handleDateChange($event, 'consumption')"
      />
      <div class="page-color--normal">
        <el-button-group>
          <el-button size="mini" @click="getNdayRange(2)"> 昨天 </el-button>
          <el-button size="mini" @click="getNdayRange(7)"> 近7天 </el-button>
          <el-button size="mini" @click="getNdayRange(30)"> 近30天 </el-button>
        </el-button-group>
      </div>
      <el-button round size="mini" icon="el-icon-refresh" @click="resetSearchForm"> 重置 </el-button>
      <el-button type="primary" round size="mini" icon="el-icon-search" @click="getList()"> 搜索 </el-button>
      <el-button type="primary" round size="mini" icon="el-icon-refresh" :loading="refreshLoading" @click="handleRefresh()"> 刷新 </el-button>
      <el-popconfirm
        title="是否按当前搜索条件导出文件？"
        confirm-button-text="确认"
        cancel-button-text="取消"
        icon="el-icon-question"
        icon-color="#409EFF"
        @onConfirm="exportFile"
      >
        <el-button slot="reference" type="success" round size="mini" icon="el-icon-download" :loading="downloading" style="margin-left: 10px">
          导出文件
        </el-button>
      </el-popconfirm>
    </el-row>

    <el-table v-loading="listLoading" :data="reportData" stripe border highlight-current-row style="width: 100%">
      <el-table-column type="index" label="序号" width="60" align="center" />
      <el-table-column align="center" prop="create_time" label="时间" show-overflow-tooltip />
      <el-table-column align="center" prop="customer_price" label="客单价" show-overflow-tooltip />
      <el-table-column align="center" prop="total_price" label="总成交金额" show-overflow-tooltip />
      <el-table-column align="center" prop="refund_price" label="成功退款金额" show-overflow-tooltip />
      <el-table-column align="center" prop="refund_ratio" label="退款率" show-overflow-tooltip />
      <el-table-column align="center" prop="payer_num" label="支付人数" show-overflow-tooltip />
      <el-table-column align="center" prop="pay_order_num" label="支付订单数" show-overflow-tooltip />
      <el-table-column align="center" prop="pay_amount" label="支付件数" show-overflow-tooltip />
      <el-table-column align="center" prop="new_customer_deal_num" label="新成交客户数" show-overflow-tooltip />
      <el-table-column align="center" prop="old_customer_deal_num" label="老成交客户数" show-overflow-tooltip />
      <el-table-column align="center" prop="new_customer_deal_price" label="新成交客户支付金额" show-overflow-tooltip />
      <el-table-column align="center" prop="old_customer_deal_price" label="老成交客户支付金额" show-overflow-tooltip />
      <el-table-column align="center" prop="new_customer_deal_price_ratio" label="新成交客户支付金额占比" show-overflow-tooltip />
      <el-table-column align="center" prop="old_customer_deal_price_ratio" label="老成交客户支付金额占比" show-overflow-tooltip />
    </el-table>

    <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
  </div>
</template>

<script>
import TableListMixin from "@/mixin/TableList";
import Pagination from "@/components/Pagination";
import { downloadBlobFile, commonUtil } from "@/utils";
import api from "@/api";
import dayjs from "dayjs";

const { tryCatch } = commonUtil;
const today = dayjs().format("YYYY-MM-DD");
export default {
  name: "DailyReport",
  components: {
    Pagination,
  },
  mixins: [TableListMixin],
  data() {
    return {
      date_time_range: [today, today],
      listLoading: false,
      downloading: false,
      refreshLoading: false,
      reportData: [],
      searchForm: {
        startTime: today,
        endTime: today,
      },
    };
  },
  watch: {
    date_time_range(timeRange) {
      [this.searchForm.startTime, this.searchForm.endTime] = timeRange;
    },
  },
  created() {
    this.getList();
  },
  methods: {
    // 重置搜索表单
    resetSearchForm() {
      this.date_time_range = [today, today];
      this.$nextTick(this.getList);
    },
    async getList() {
      const [, res] = await this.getListMixin(api.malls.dailyReport);
      this.reportData = res?.list ?? [];
    },
    async handleRefresh() {
      this.refreshLoading = true;
      const startTime = dayjs().subtract(10, "day").format("YYYY-MM-DD");
      const endTime = "2099-01-01";
      const [err] = await api.malls.refreshDailyReport({
        params: {
          startTime,
          endTime,
        },
      });
      if (!err) this.$message.success("刷新成功");
      this.refreshLoading = false;
    },
    //查询昨天的列表
    getNdayRange(fromDays) {
      const startDay = dayjs()
        .subtract(fromDays - 1, "day")
        .format("YYYY-MM-DD");
      let endDay = today;
      if (fromDays === 2) endDay = startDay;
      this.date_time_range = [startDay, endDay];
      this.$nextTick(this.getList);
    },
    // 根据查询条件导出Excel文件
    async exportFile() {
      this.downloading = true;
      const startTime = this.searchForm.startTime;
      const endTime = this.searchForm.endTime;
      const filename =
        startTime === endTime ? `全店日报${endTime.split("-").join("")}` : `全店日报${startTime.split("-").join("")}_${endTime.split("-").join("")}`;

      // 后端协商最多下载50000条数据
      const params = {
        startTime,
        endTime,
        page: 1,
        size: 50000,
      };
      const [err, blobData] = await api.malls.downloadDailyReport({ params, responseType: "blob" });
      this.downloading = false;
      if (err) return;
      await tryCatch(downloadBlobFile, { obj: blobData, name: filename, suffix: "xlsx" });
    },
    handleDateChange(val, val2) {
      if (val2 === "consumption") {
        this.searchForm.startTime = val[0];
        this.searchForm.endTime = val[1];
      }
    },
  },
};
</script>

<style></style>
