<template>
  <PageMain class="h-full overflow-y-auto">
    <PageHeader title="多语言管理">
      <template #right>
        <div>
          <el-button :loading="languageLoading" type="primary" @click="settingVisible = true">设置语言</el-button>
          <el-button type="success" @click="publishVisible = true">发布语言</el-button>
        </div>
      </template>
    </PageHeader>
    <FilterMain>
      <FilterItem label="商品名称">
        <el-input v-model="goodsName" placeholder="请输入商品名称" clearable></el-input>
      </FilterItem>
      <template #buttons>
        <el-button type="primary" @click="getAllGoods">搜索</el-button>
      </template>
    </FilterMain>

    <el-row :gutter="20">
      <el-col :span="4">
        <el-scrollbar
          native
          :style="{
            height: mainHeight + 'px',
          }"
          wrap-class="left-scrollbar-wrapper"
        >
          <el-menu :default-active="currentGoodId + ''" @select="(id) => getTableData(+id)">
            <el-submenu index="all">
              <template #title> 商品列表 </template>
              <template v-if="goodList.length">
                <el-menu-item v-for="item in goodList" :key="item.goods_id" :index="item.goods_id + ''">
                  <template #title>
                    <el-tooltip placement="right" :content="item.name">
                      <span class="truncate" :title="item.name">{{ item.name }}</span>
                    </el-tooltip>
                  </template>
                </el-menu-item>
              </template>
              <template v-else>
                <el-menu-item :index="null">
                  <template #title> 暂无数据 </template>
                </el-menu-item>
              </template>
            </el-submenu>
          </el-menu>
        </el-scrollbar>
      </el-col>
      <el-col :span="20">
        <el-table v-loading="tableLoading" :max-height="mainHeight" stripe highlight-current-row border :data="tableData" class="w-full">
          <el-table-column align="center" type="index" width="60" label="序号"> </el-table-column>
          <el-table-column align="center" width="60" label="操作">
            <template #default="scope">
              <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="infoTypeCN" label="商品信息"> </el-table-column>
          <el-table-column
            v-for="item in allSettedLanguages"
            :key="item.language_type + item.language_sort"
            :prop="item.language_type"
            align="center"
            :label="item.cn_name"
          >
          </el-table-column>
          <el-table-column align="center" label="修改人/修改时间">
            <template #default="scope">
              <span>{{ scope.row.updater }}</span
              >/
              <span>{{ scope.row.update_time }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="发布人/发布时间">
            <template #default="scope">
              <span>{{ scope.row.public_user }}</span
              >/
              <span>{{ scope.row.public_time }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <SettingLanguage
      ref="settingLanguageInstance"
      :default-selected-language="languageTypes"
      :visible.sync="settingVisible"
      @confirm="handleLanguageChange"
    >
      <div>hello</div>
    </SettingLanguage>
    <el-dialog title="发布语言" :visible="publishVisible" width="480px">
      发布后，商城用户侧立即生效，用户可自行切换已 设置的语种及翻译进行浏览，请确认是否发布？
      <span slot="footer" class="dialog-footer">
        <el-button :loading="publishLoading" @click="handlePublishClose">取 消</el-button>
        <el-button :loading="publishLoading" type="primary" @click="handlePublishConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <EditI18n
      :default-i18n-value="defaultEditI18nValue"
      :translation-id="currentTranslationId"
      :visible.sync="editVisible"
      @confirm="getTableData(currentGoodId, true)"
    ></EditI18n>
  </PageMain>
</template>

<script>
import api from "@/api";
import FilterItem from "@/components/Filter/FilterItem.vue";
import FilterMain from "@/components/Filter/FilterMain.vue";
import PageHeader from "@/components/Layout/PageHeader.vue";
import PageMain from "@/components/Layout/PageMain.vue";
import { useWindowSize } from "@vueuse/core";
import { Message } from "element-ui";
import { onMounted, ref, shallowRef, watch } from "vue";
import EditI18n from "./components/EditI18n.vue";
import SettingLanguage from "./components/SettingLanguage.vue";

const INFO_MAP = {
  GOOD_NAME: "商品名称",
  GOOD_DESC: "商品卖点",
  SKU_NAME: "商品规格",
  SKU_VALUE: "规格值",
};

export default {
  name: "TheI18n",
  components: {
    PageMain,
    PageHeader,
    FilterMain,
    FilterItem,
    SettingLanguage,
    EditI18n,
  },
  setup() {
    const goodsName = ref("");
    const goodList = ref([]);
    const languageLoading = ref(false);
    const tableLoading = ref(false);
    const mainHeight = ref(0);

    const tableData = ref([]);
    async function getTableData(goodsId, forceRefresh = false) {
      if (goodsId === currentGoodId.value && !forceRefresh) return;
      tableLoading.value = true;
      currentGoodId.value = goodsId;
      const [err, res] = await api.multiLanguage.goodInfo({
        params: {
          goodsId,
        },
      });
      tableLoading.value = false;

      tableData.value = [];
      if (err) return;
      // 将翻译信息映射到商品信息中
      tableData.value = res.map((item) => {
        const translationMap =
          item.translations?.reduce((obj, item) => {
            obj[item.language_type] = item.language_translation;
            return obj;
          }, {}) ?? {};
        translationMap["zh-CN"] = item.info_name;

        return {
          ...item,
          infoTypeCN: INFO_MAP[item.info_type],
          ...translationMap,
        };
      });
    }

    const languageTypes = ref(["zh-CN"]);
    const allSettedLanguages = ref([]);
    const settingVisible = ref(false);
    async function getAllLanguageSetting() {
      languageLoading.value = true;
      const [err, res] = await api.multiLanguage.getSetting();
      languageLoading.value = false;

      if (err) return;
      allSettedLanguages.value = res;
      languageTypes.value = formatLanguages(res);
    }
    function formatLanguages(val) {
      const maxIndex = val.reduce((max, item) => Math.max(max, item.language_sort), 0);
      const newVal = new Array(maxIndex).fill(null);
      val.forEach((item) => {
        newVal[item.language_sort - 1] = item.language_type;
      });
      return newVal;
    }

    function handleLanguageChange(val) {
      let isDifferent = val.reduce((pre, cur, index) => pre || cur !== languageTypes.value[index], false);
      if (isDifferent) {
        getAllLanguageSetting();
        getTableData(currentGoodId.value, true);
      }
    }

    const publishVisible = ref(false);
    const publishLoading = ref(false);
    function handlePublishClose() {
      publishVisible.value = false;
    }
    async function handlePublishConfirm() {
      publishLoading.value = true;
      const [err] = await api.multiLanguage.publish();
      publishLoading.value = false;

      if (err) return;
      Message.success("发布成功");
      publishVisible.value = false;
    }

    const editVisible = ref(false);
    const defaultEditI18nValue = shallowRef([]);
    const currentTranslationId = ref(null);
    function handleEdit(row) {
      currentTranslationId.value = row.id;
      defaultEditI18nValue.value = allSettedLanguages.value.map((item) => {
        return {
          cn_name: item.cn_name,
          language_type: item.language_type,
          language_translation: row[item.language_type],
        };
      });
      editVisible.value = true;
    }

    async function getAllGoods() {
      tableLoading.value = true;
      const [err, res] = await api.goods.all({
        params: {
          goodsName: goodsName.value,
        },
      });
      tableLoading.value = false;

      if (err) return;
      goodList.value = res;
    }

    const currentGoodId = ref(null);
    watch(goodList, (val) => {
      const firstGood = val[0];
      if (firstGood) {
        getTableData(firstGood.goods_id);
      }
    });

    const { height } = useWindowSize();
    watch(height, () => {
      mainHeight.value = getHeight();
    });

    function getHeight({ marginBottom = 40, minHeight = 300, unit } = {}) {
      const documentHeight = document.documentElement.clientHeight;

      const navBarHeight = document.querySelector(".navbar")?.clientHeight;
      const tagsViewHeight = document.querySelector(".tags-view-container")?.clientHeight;
      const pageHeaderHeight = document.querySelector(".page-header")?.clientHeight;
      const filterMainHeight = document.querySelector(".filter-main")?.clientHeight;

      const tableHeight = documentHeight - navBarHeight - tagsViewHeight - pageHeaderHeight - filterMainHeight - marginBottom;
      if (unit) return Math.max(tableHeight, minHeight) + unit;
      return Math.max(tableHeight, minHeight);
    }
    onMounted(async () => {
      await getAllGoods();
      await getAllLanguageSetting();

      mainHeight.value = getHeight();
    });

    return {
      goodsName,
      getAllGoods,
      goodList,
      languageLoading,
      tableLoading,
      publishLoading,
      currentGoodId,
      mainHeight,
      allSettedLanguages,
      tableData,

      getTableData,
      handleLanguageChange,

      languageTypes,
      settingVisible,

      publishVisible,
      handlePublishClose,
      handlePublishConfirm,

      editVisible,
      defaultEditI18nValue,
      currentTranslationId,
      handleEdit,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.left-scrollbar-wrapper) {
  overflow-x: hidden !important;
}
</style>
