<template>
  <div>
    <div class="app-container" :class="{ 'app-mobile': isDevice, 'app-mobile-dark': theme === 'dark' }">
      <chat-container
        v-if="showChat"
        :current-user-id="currentUserId"
        :theme="theme"
        :is-device="isDevice"
        @show-demo-options="showDemoOptions = $event"
      />
    </div>
  </div>
</template>

<script>
import { authUtil, commonUtil } from "@/utils";
import ChatContainer from "./ChatContainer";
import { mapState } from "vuex";
import api from "@/api";

const { tryCatch } = commonUtil;

export default {
  name: "MallIMInstant",
  components: {
    ChatContainer,
  },

  data() {
    return {
      theme: "light",
      showChat: true,
      users: [
        // {
        //   _id: "6jMsIXUrBHBj7o2cRlau",
        //   username: "Yoda",
        //   avatar: "https://vignette.wikia.nocookie.net/teamavatarone/images/4/45/Yoda.jpg/revision/latest?cb=20130224160049",
        // },
      ],
      currentUserId: "ADMIN",
      isDevice: false,
      showDemoOptions: true,
      updatingData: false,
      timer: null,
      socket: null,
    };
  },

  computed: {
    ...mapState("chat", {
      currentRoom: (state) => state.currentRoom,
      notFinishList: (state) => state.notFinishList,
    }),
  },
  watch: {
    currentUserId() {
      this.showChat = false;
      setTimeout(() => (this.showChat = true), 150);
    },
  },

  mounted() {
    this.isDevice = window.innerWidth < 500;
    window.addEventListener("resize", (ev) => {
      if (ev.isTrusted) this.isDevice = window.innerWidth < 500;
    });
  },

  created() {
    this.connect();
  },
  destroyed() {
    this.disconnect();
    clearTimeout(this.timer);
  },
  methods: {
    async initSocket() {
      this.socket = new WebSocket(`${process.env.VUE_APP_WS}customerService/subscribe?token=${authUtil.getToken()}`);
      this.socket.onopen = () => {
        console.log("============Socket Connected Successful==========");
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.socket.onmessage = ({ data }) => {
          let message = JSON.parse(data);
          if (message.session_id && this.currentRoom.sessionId === message.session_id) this.setMessageRead(message.session_id);

          this.$store.dispatch("chat/pushMessage", message?.customer_service_record);
        };
        this.socket.onclose = () => {
          console.log("============socket onclose============");
          // 后台保持发送message过来保证websocket存活，前端去掉重连
          // this.reconnect();
        };
        this.socket.onerror = () => this.$notify.error("socket onerror");
      };
    },
    async connect() {
      const [err] = await tryCatch(this.initSocket);
      if (err) return this.$notify.error(err + "websocket 连接失败");
    },
    resetSocket() {
      this.disconnect();
      this.socket = null;
    },
    disconnect() {
      this.socket.close();
    },
    reconnect() {
      if (this.socket) this.disconnect();
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.initSocket();
        //没连接上会一直重连，设置延迟避免请求过多
        // this.socket.onclose = this.reconnect;
        // this.socket.onerror = () => this.$notify.error("socket onerror");
      }, 10000);
    },
    setMessageRead(sessionId) {
      // 设置消息为已读
      api.customerService.customerService({
        method: "post",
        resource: "read",
        data: {
          read: true,
          session_id: sessionId,
        },
      });
    },
  },
};
</script>

<style lang="scss">
body {
  background: #fafafa;
  margin: 0;
}

input {
  -webkit-appearance: none;
}

.app-container {
  font-family: "Quicksand", sans-serif;
  padding: 20px 30px 30px;
}

.app-mobile {
  padding: 0;

  &.app-mobile-dark {
    background: #131415;
  }

  .user-logged {
    margin: 10px 5px 0 10px;
  }

  select {
    margin: 10px 0;
  }

  .button-theme {
    margin: 10px 10px 0 0;

    .button-github {
      height: 23px;

      img {
        height: 23px;
      }
    }
  }
}

.user-logged {
  font-size: 12px;
  margin-right: 5px;
  margin-top: 10px;

  &.user-logged-dark {
    color: #fff;
  }
}

select {
  height: 20px;
  outline: none;
  border: 1px solid #e0e2e4;
  border-radius: 4px;
  background: #fff;
  margin-bottom: 20px;
}

.button-theme {
  float: right;
  display: flex;
  align-items: center;

  .button-light {
    background: #fff;
    border: 1px solid #46484e;
    color: #46484e;
  }

  .button-dark {
    background: #1c1d21;
    border: 1px solid #1c1d21;
  }

  button {
    color: #fff;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    padding: 6px 12px;
    margin-left: 10px;
    border: none;
    font-size: 14px;
    transition: 0.3s;
    vertical-align: middle;

    &.button-github {
      height: 30px;
      background: none;
      padding: 0;
      margin-left: 20px;

      img {
        height: 30px;
      }
    }

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }

    @media only screen and (max-width: 768px) {
      padding: 3px 6px;
      font-size: 13px;
    }
  }
}

.version-container {
  padding-top: 20px;
  text-align: right;
  font-size: 14px;
  color: grey;
}
</style>
