var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "page-padding",
    },
    [
      _c(
        "Sticky",
        {
          attrs: {
            "z-index": 20,
          },
        },
        [
          _c("el-page-header", {
            staticClass: "go-back",
            attrs: {
              content: "限时降价",
            },
            on: {
              back: function back($event) {
                return _vm.handleNavBack()
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "panel-item",
        },
        [_vm._v("基本信息")]
      ),
      _c(
        "section",
        {
          staticClass: "page-filter--wrap",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                model: _vm.formData,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "活动名称",
                    prop: "name",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入活动名称",
                    },
                    model: {
                      value: _vm.formData.name,
                      callback: function callback($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "活动简介",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入活动简介",
                    },
                    model: {
                      value: _vm.formData.intro,
                      callback: function callback($$v) {
                        _vm.$set(_vm.formData, "intro", $$v)
                      },
                      expression: "formData.intro",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "日期时间",
                    prop: "timeRange",
                  },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "开始时间",
                      "end-placeholder": "结束时间",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                    },
                    model: {
                      value: _vm.formData.timeRange,
                      callback: function callback($$v) {
                        _vm.$set(_vm.formData, "timeRange", $$v)
                      },
                      expression: "formData.timeRange",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "活动上架状态",
                    prop: "enable",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.formData.enable,
                        callback: function callback($$v) {
                          _vm.$set(_vm.formData, "enable", $$v)
                        },
                        expression: "formData.enable",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label: "上架",
                          value: true,
                        },
                      }),
                      _c("el-option", {
                        attrs: {
                          label: "下架",
                          value: false,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "section",
        [
          _c(
            "div",
            {
              staticClass: "panel-item",
            },
            [_vm._v("活动商品信息")]
          ),
          _c(
            "el-button",
            {
              staticClass: "mt-[6px] mb-[6px]",
              on: {
                click: _vm.handleOpen,
              },
            },
            [_vm._v(" 选择/修改商品 ")]
          ),
          _c(
            "el-table",
            {
              attrs: {
                "span-method": _vm.handleSpan,
                data: _vm.tableData,
                border: "",
                stripe: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "index",
                  label: "商品序号",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "商品名称",
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function fn() {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "flex justify-center",
                          },
                          [_vm._v("商品名称")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function fn(scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "flex items-center",
                          },
                          [
                            _c("img", {
                              staticClass: "goodImg mr-[10px]",
                              attrs: {
                                src: scope.row.imgUrl,
                              },
                            }),
                            _c("span", [_vm._v(_vm._s(scope.row.name))]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "tag_set",
                  formatter: _vm.formatCategoryName,
                  label: "商品分组",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "props",
                  label: "规格",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "price",
                  label: "售价（元）",
                  align: "center",
                },
              }),
              _c(
                "el-table-column",
                {
                  attrs: {
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function fn(scope) {
                        return [
                          _c("el-input", {
                            on: {
                              blur: function blur($event) {
                                return _vm.handleCheckActivityPrice(scope.row)
                              },
                            },
                            model: {
                              value: scope.row.activity_price,
                              callback: function callback($$v) {
                                _vm.$set(scope.row, "activity_price", $$v)
                              },
                              expression: "scope.row.activity_price",
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    {
                      slot: "header",
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "red",
                          },
                        },
                        [_vm._v("*")]
                      ),
                      _vm._v("促销价（元） "),
                    ]
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "section",
        {
          staticClass: "flex justify-center mt-[50px]",
        },
        [
          _c(
            "el-button",
            {
              staticClass: "mr-[20px]",
              attrs: {
                size: "medium",
              },
              on: {
                click: function click($event) {
                  return _vm.handleNavBack()
                },
              },
            },
            [_vm._v(" 取消 ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                size: "medium",
                type: "primary",
              },
              on: {
                click: _vm.handleSubmit,
              },
            },
            [_vm._v(" " + _vm._s(_vm.formData.id ? "修改" : "创建") + " ")]
          ),
        ],
        1
      ),
      _c("InfoPopup", {
        attrs: {
          "goods-id-set": _vm.goodsIdSet,
          visible: _vm.infoPopupVisible,
        },
        on: {
          "update:visible": function updateVisible($event) {
            _vm.infoPopupVisible = $event
          },
          confirm: _vm.formatTableData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }