<template>
  <div class="page-padding">
    <div class="page-header--wrap">
      <div class="page-header-wrap--title">历史会话</div>
      <div />
    </div>

    <el-row class="page-filter--wrap">
      <div class="page-color--normal">会话开始时间</div>
      <el-date-picker
        v-model="recent_consumption_time"
        value-format="yyyy-MM-dd"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="handleDateChange($event, 'consumption')"
      />

      <div class="page-color--normal">客户资料</div>
      <el-input v-model="searchForm.blurry" placeholder="手机号/昵称" clearable class="page-select--item" style="width: 180px" />
      <!-- <el-button round size="mini" icon="el-icon-refresh"> 重置</el-button> -->
      <el-button
        type="primary"
        round
        size="mini"
        icon="el-icon-search"
        @click="
          resetCurrentPage();
          getList();
        "
      >
        搜索
      </el-button>
    </el-row>

    <el-table v-loading="listLoading" :data="memberData" stripe border highlight-current-row style="width: 100%">
      <!-- <el-table-column type="selection" width="55" align="center"></el-table-column> -->
      <el-table-column type="index" label="序号" width="60" align="center" />
      <el-table-column label="操作" width="200px" align="center">
        <template slot-scope="{ row }">
          <el-button type="text" size="mini" @click="handleMemberDetail(row)"> 详情 </el-button>
        </template>
      </el-table-column>
      <!--      <el-table-column align="center" prop="name" label="客户名称" show-overflow-tooltip> </el-table-column>-->
      <el-table-column align="center" prop="name" label="昵称" show-overflow-tooltip />
      <el-table-column align="center" prop="mobile_number" label="手机" show-overflow-tooltip />
      <!--      <el-table-column align="center" prop="level_name" label="客户身份" show-overflow-tooltip> </el-table-column>-->
      <el-table-column align="center" prop="create_time" label="会话开始时间" show-overflow-tooltip />
      <el-table-column align="center" prop="first_response_time" label="客服首次响应时间" show-overflow-tooltip />
      <el-table-column align="center" prop="admin_user_name_list" label="接待客服" show-overflow-tooltip>
        <template slot-scope="{ row }">
          {{ row.admin_user_name_list.join() }}
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
  </div>
</template>

<script>
import TableListMixin from "@/mixin/TableList";
import Pagination from "@/components/Pagination";
import api from "@/api";
export default {
  name: "MallImHistory",
  components: {
    Pagination,
  },
  mixins: [TableListMixin],
  data() {
    return {
      recent_consumption_time: [],
      listLoading: false,
      memberData: [],
      searchForm: {
        start_time: undefined,
        end_time: undefined,
        blurry: undefined,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    resetCurrentPage() {
      this.listQuery.page = 1;
    },
    async getList() {
      const [, res] = await this.getListMixin(api.customerService.historyTable);
      this.memberData = res.list ?? [];
    },
    handleDateChange(val, val2) {
      if (val2 == "consumption") {
        this.searchForm.start_time = val ? val[0] : "";
        this.searchForm.end_time = val ? val[1] : "";
      }
    },
    async handleMemberDetail(row) {
      this.$router.push({ path: "/instant", query: { roomId: row.id, mobile_number: row.mobile_number, finished: row.finished } });
    },
  },
};
</script>

<style></style>
