var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "page-padding",
    },
    [
      _vm._m(0),
      _c(
        "el-row",
        {
          staticClass: "page-filter--wrap",
        },
        [
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [_vm._v("会话开始时间")]
          ),
          _c("el-date-picker", {
            attrs: {
              "value-format": "yyyy-MM-dd",
              type: "daterange",
              "range-separator": "至",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
            },
            on: {
              change: function change($event) {
                return _vm.handleDateChange($event, "consumption")
              },
            },
            model: {
              value: _vm.recent_consumption_time,
              callback: function callback($$v) {
                _vm.recent_consumption_time = $$v
              },
              expression: "recent_consumption_time",
            },
          }),
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [_vm._v("客户资料")]
          ),
          _c("el-input", {
            staticClass: "page-select--item",
            staticStyle: {
              width: "180px",
            },
            attrs: {
              placeholder: "手机号/昵称",
              clearable: "",
            },
            model: {
              value: _vm.searchForm.blurry,
              callback: function callback($$v) {
                _vm.$set(_vm.searchForm, "blurry", $$v)
              },
              expression: "searchForm.blurry",
            },
          }),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                round: "",
                size: "mini",
                icon: "el-icon-search",
              },
              on: {
                click: function click($event) {
                  _vm.resetCurrentPage()
                  _vm.getList()
                },
              },
            },
            [_vm._v(" 搜索 ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: {
            width: "100%",
          },
          attrs: {
            data: _vm.memberData,
            stripe: "",
            border: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "序号",
              width: "60",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "200px",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref) {
                  var row = _ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          size: "mini",
                        },
                        on: {
                          click: function click($event) {
                            return _vm.handleMemberDetail(row)
                          },
                        },
                      },
                      [_vm._v(" 详情 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "name",
              label: "昵称",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "mobile_number",
              label: "手机",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "create_time",
              label: "会话开始时间",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "first_response_time",
              label: "客服首次响应时间",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "admin_user_name_list",
              label: "接待客服",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref2) {
                  var row = _ref2.row
                  return [
                    _vm._v(" " + _vm._s(row.admin_user_name_list.join()) + " "),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function updatePage($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function updateLimit($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "page-header--wrap",
      },
      [
        _c(
          "div",
          {
            staticClass: "page-header-wrap--title",
          },
          [_vm._v("历史会话")]
        ),
        _c("div"),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }