<template>
  <div class="page-padding">
    <div v-if="isGoodsManage" name="fade-transform" mode="out-in">
      <div class="page-header--wrap">
        <div class="page-header-wrap--title">商品管理</div>
        <div>
          <el-button type="primary" @click="handleAddBtn"> 发布商品 </el-button>
          <el-button type="warning" :disabled="multipleSelection.length == 0" @click="handleUnShelve"> 下架 </el-button>
          <el-button type="success" :disabled="multipleSelection.length == 0" @click="handleEnableShelve"> 上架 </el-button>

          <el-popconfirm title="确定删除该数据吗？" style="margin-left: 10px" placement="left" @onConfirm="handleDeleteMulti">
            <el-button slot="reference" type="danger" :disabled="multipleSelection.length == 0"> 删除 </el-button>
          </el-popconfirm>
        </div>
      </div>

      <div class="page-filter--wrap">
        <div class="page-color--normal">商品名称</div>
        <div class="page-select--item">
          <el-input v-model="searchForm.name" placeholder="请输入商品名称" />
        </div>
        <div class="page-color--normal">销量</div>
        <div class="page-select--item flex-center">
          <el-input v-model.number="searchForm.saleNumStart" style="width: 80px" />
          <div style="margin: 0 5px">-</div>
          <el-input v-model.number="searchForm.saleNumEnd" style="width: 80px" />
        </div>
        <div class="page-select--item flex-center">
          <div class="page-color--normal">商品分组</div>
          <el-select v-model="searchForm.goods_tag_id_set" placeholder="请选择" multiple>
            <el-option v-for="item in goodsTagList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
        <div class="page-select--item flex-center">
          <div class="page-color--normal">商品分类</div>
          <el-select v-model="searchForm.goods_classification_id_set" placeholder="请选择" multiple>
            <el-option v-for="item in goodsClassificationList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>

        <!-- <div class="page-color--normal">价格</div>
        <div class="page-select--item flex-center">
          <el-input v-model.number="searchForm.min_price"  style="width: 120px;">
            <template slot="append">元</template>
          </el-input>
          <div style="margin: 0 5px;">-</div>
          <el-input v-model.number="searchForm.max_price"  style="width: 120px;">
            <template slot="append">元</template>
          </el-input>
        </div> -->
        <div class="page-color--normal">
          <el-button type="primary" @click="getList"> 搜索 </el-button>
        </div>
        <div class="page-color--normal">
          <el-button type="text" @click="resetSearchForm"> 重置筛选条件 </el-button>
        </div>
      </div>

      <el-tabs v-model="tabActive" type="border-card" class="my-tab" @tab-click="handleClick">
        <el-tab-pane label="全部" name="" />
        <el-tab-pane label="销售中" name="ONSALE" />
        <el-tab-pane label="已售罄" name="SOLDOUT" />
        <el-tab-pane label="补货中" name="REPLENISHING" />
        <el-tab-pane label="待上架" name="WATTING" />
        <el-tab-pane label="仓库中" name="WAREHOUSE" />
        <el-table
          ref="dragTable"
          v-loading="listLoading"
          row-key="id"
          :data="goodsData"
          stripe
          border
          highlight-current-row
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" align="center" />
          <el-table-column type="index" label="序号" width="60" align="center" />
          <el-table-column align="center" label="排序" width="80">
            <svg-icon class="drag-handler" icon-class="drag" />
          </el-table-column>
          <el-table-column label="操作" width="200px" align="center">
            <template #default="scope">
              <el-button type="text" @click="handleEdit(scope.row)"> 编辑 </el-button>
              <el-button v-if="scope.row.sale_status === SALE_STATUS_VALUE_MAP['FOR_SALE']" type="text" @click="handleDisableGoods(scope.row)">
                下架
              </el-button>
              <el-button v-else type="text" @click="handleEnableGoods(scope.row)"> 上架 </el-button>
              <el-button v-if="!isVirtual" type="text" :loading="scope.row.btn_loading" @click="handlePromote(scope.row, scope.$index)">
                推广
              </el-button>
              <el-button type="text" :loading="scope.row.btn_loading" @click="handleCopyGood(scope.row)"> 复制 </el-button>
              <el-popconfirm title="确定删除该数据吗？" style="margin-left: 10px" placement="left" @onConfirm="handleDeleteGoods(scope.row)">
                <template #reference>
                  <el-button type="text"> 删除 </el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
          <el-table-column width="400" align="center" prop="name" label="商品名称">
            <template #default="scope">
              <div class="flex-center" style="justify-content: start">
                <el-image style="max-width: 80px; height: 80px; margin-right: 10px" :src="scope.row.cover_image_address" fit="contain" />
                <el-tooltip effect="dark" :content="scope.row.name" placement="top" style="overflow: hidden; text-overflow: ellipsis">
                  <el-button type="text" style="max-width: 210px" @click="handleEdit(scope.row)">
                    {{ scope.row.name }}
                  </el-button>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="sale_status" label="商品状态" :formatter="saleStatusFormatter" />
          <el-table-column align="center" label="商品分组">
            <template #default="{ row }">
              {{ row.related_goods_tags?.map((item) => item.goods_tag_name).join("、") || "-" }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="商品分类">
            <template #default="{ row }">
              {{ row.related_goods_classifications?.map((item) => item.goods_classification_name).join("、") || "-" }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="stock_num" label="库存" />
          <el-table-column width="160px" align="center" prop="min_price" label="价格">
            <template #default="{ row }">
              {{ formatPrice({ price: row.goods_sku_info_list[0].retail_price, point: row.goods_sku_info_list[0].retail_points }) }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="visit_num" label="访问量" />
          <el-table-column align="center" prop="visitor_num" label="访客数" />

          <el-table-column align="center" label="销量">
            <template #default="{ row }">
              {{ row.sale_num == null ? 0 : row.sale_num }}
            </template>
          </el-table-column>

          <el-table-column align="center" label="创建人/修改人">
            <template #default="{ row }">
              {{ `${row.creator ?? "-"}/${row.updater ?? "-"}` }}
            </template>
          </el-table-column>

          <el-table-column align="center" label="创建时间/修改时间" min-width="110">
            <template #default="{ row }">
              {{ `${row.create_time ? formatTime(row.create_time) : "-"}/${row.update_time ? formatTime(row.update_time) : "-"}` }}
            </template>
          </el-table-column>
        </el-table>

        <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
      </el-tabs>
    </div>
    <div v-if="!isGoodsManage">
      <goods-info :spu-code="spuCode" :is-copy="isCopy" @goBack="goBack" @close="isCopy = false" />
    </div>

    <el-dialog title="推广" :visible.sync="dialogPromote" width="500px">
      <el-form :model="formPromote" label-width="90px">
        <el-form-item label="分享链接">
          <div style="display: flex">
            <el-input v-model="formPromote.path" disabled />
            <el-button type="primary" @click="handleCopy(formPromote.path, $event)"> 复制 </el-button>
          </div>
        </el-form-item>
        <el-form-item label="小程序码">
          <div style="display: flex; flex-direction: column; align-items: flex-start">
            <el-image class="code-img" :src="formPromote.qr_code_data" />
            <el-button style="margin: 10px 0" type="text" @click="handleDownloadQRcode"> 下载小程序码 </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { GOODS } from "@/constants";
import GoodsInfo from "./GoodsInfo";
import Pagination from "@/components/Pagination";
import TableListMixin from "@/mixin/TableList";
import Sortable from "sortablejs";
import * as R from "ramda";
import { formatTime } from "@/utils/format-time";
import clip from "@/utils/clipboard";
import api from "@/api";
import { commonUtil } from "@/utils";

const { to } = commonUtil;
const { PAY_TYPE_VALUE_MAP, SALE_STATUS_VALUE_MAP, SALE_STATUS_VALUE_DIC } = GOODS;

export default {
  name: "GoodsList",
  components: {
    GoodsInfo,
    Pagination,
  },
  mixins: [TableListMixin],

  data() {
    return {
      PAY_TYPE_VALUE_MAP,
      SALE_STATUS_VALUE_MAP,
      sortable: null,
      oldList: [],
      newList: [],
      dialogPromote: false,
      formPromote: {},
      listLoading: false,
      spuCode: "",
      isCopy: false, // 是否复制商品
      formatTime,
      isGoodsManage: true,
      goodsTagList: [],
      goodsClassificationList: [],
      searchForm: {
        name: "", //商品名称搜索框
        saleNumStart: "",
        saleNumEnd: "",
        goods_tag_id_set: [],
        goods_classification_id_set: [],
        status: "",
      },
      // valueGoodsGroup: "", //商品分组

      tabActive: "",
      goodsData: [],
      multipleSelection: [],
    };
  },
  computed: {
    isVirtual() {
      let { name, code } = this.$store.state.user.auth_info.current_mall;
      if (name === "积分商城" || code === "tr_jf") {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.fetchGoodsTagsAndClassification();
    this.getList();
  },
  methods: {
    saleStatusFormatter({ stock_num = 0, sale_status = "" } = {}) {
      if (stock_num === 0 && sale_status === SALE_STATUS_VALUE_MAP["FOR_SALE"]) return "已售罄";
      else return SALE_STATUS_VALUE_DIC[sale_status];
    },
    setSort() {
      const el = this.$refs.dragTable.$el.querySelectorAll(".el-table__body-wrapper > table > tbody")[0];
      this.sortable = Sortable.create(el, {
        ghostClass: "sortable-ghost", // Class name for the drop placeholder,
        setData: function (dataTransfer) {
          dataTransfer.setData("Text", "");
        },
        onEnd: (evt) => {
          const { oldIndex, newIndex } = evt;
          if (oldIndex === newIndex) return;
          let { goodsData } = this;
          const targetRow = goodsData[newIndex];
          const sourceRow = goodsData.splice(oldIndex, 1)[0];
          this.goodsData.splice(newIndex, 0, sourceRow);

          this.sortRequest(sourceRow.id, targetRow.id, oldIndex > newIndex ? "FRONT" : "BACK");
        },
      });
    },
    async sortRequest(source_goods_id, target_goods_id, front_back) {
      const data = { source_goods_id, target_goods_id, front_back };
      const [err] = await api.goods.moveLocation({
        method: "post",
        data,
      });
      if (err) await this.getList();
      else this.$notify.success("操作成功");
    },
    async fetchGoodsTagsAndClassification() {
      const [err, res] = await api.goods.goodsTagsAll();
      if (err) return;

      this.goodsTagList = res;

      const idSet = new Set();
      this.goodsClassificationList = res.reduce((acc, cur) => {
        return [
          ...acc,
          ...cur.related_goods_classifications
            .map((item) => {
              return { id: item.goods_classification_id, name: item.goods_classification_name };
            })
            .filter((item) => !idSet.has(item.id) && idSet.add(item.id)),
        ];
      }, []);
    },
    handleCopy(v, e) {
      clip(v, e);
    },
    handleDownloadQRcode() {
      this.downloadIamge(this.formPromote.qr_code_data, "小程序码");
    },

    downloadIamge(imgsrc, name) {
      //下载图片地址和图片名
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },
    async getList() {
      const [, res] = await this.getListMixin(({ params }) => api.goods.pageGoods({ method: "post", data: params }));
      this.goodsData = res?.list ?? [];
      this.$nextTick(() => {
        this.setSort();
      });
    },

    handleDisableGoods(row) {
      this.$confirm("确定要下架该商品吗?", "提示", {
        type: "warning",
      }).then(() => {
        this.requestEnableDisableGoods(row.code, false, "下架成功");
      });
    },

    handleEnableGoods(row) {
      this.requestEnableDisableGoods(row.code, true, "上架成功");
    },

    async requestEnableDisableGoods(code, on, msg = "操作成功") {
      const data = {
        code_set: [code],
        on,
      };
      const [err] = await api.goods.batchPutOnOffSale({
        method: "post",
        data,
      });
      if (err) return;
      this.$notify.success(msg);
      await this.getList();
    },

    goBack() {
      this.isGoodsManage = true;
      this.getList();
    },
    handleAddBtn() {
      this.spuCode = "";
      this.isGoodsManage = false;
    },
    handleClick(tab) {
      this.searchForm.status = tab.name;
      this.listQuery.page = 1;
      this.getList();
    },
    // 编辑商品
    handleEdit(row) {
      this.isGoodsManage = false;
      this.spuCode = row.code;
    },
    // 推广
    async handlePromote(row, index) {
      row.btn_loading = true;
      const [err, res] = await api.goods.goods({
        id: row.id,
        resource: "miniProgramPageQRCode",
      });

      row.btn_loading = false;
      this.$set(this.goodsData, index, row);
      if (err) return;
      this.formPromote = res;
      this.formPromote.qr_code_data = `data:image/png;base64,${res.qr_code_data}`;
      this.dialogPromote = true;
    },
    async handleCopyGood(row) {
      this.isGoodsManage = false;
      this.spuCode = row.code;
      this.isCopy = true;
    },
    async deleteGoodsRequest(code_set) {
      const data = {
        code_set,
      };
      const [err] = await api.goods.delete({
        method: "post",
        data,
      });
      if (err) return;
      this.$notify.success("删除成功");
      await this.getList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    handleDeleteMulti() {
      if (this.multipleSelection.length === 0) {
        this.$notify.info("请勾选要删除的商品");
      } else {
        const codes = R.map(R.prop("code"), this.multipleSelection);
        this.deleteGoodsRequest(codes);
      }
    },
    handleDeleteGoods(row) {
      let arr = [];
      arr.push(row.code);
      this.deleteGoodsRequest(arr);
    },

    async unShelveGoodsRequest(code_set) {
      let [err] = await to(
        this.$confirm("此操作将下架商品, 是否继续?", "提示", {
          type: "warning",
        })
      );
      if (err) return;
      const data = {
        code_set,
        on: false,
      };
      [err] = await api.goods.batchPutOnOffSale({
        method: "post",
        data,
      });
      if (err) return;
      this.$notify.success("下架成功");
      await this.getList();
    },
    handleUnShelve() {
      if (this.multipleSelection.length === 0) {
        this.$notify.info("请勾选要下架的商品");
      } else {
        const codes = R.map(R.prop("code"), this.multipleSelection);
        this.unShelveGoodsRequest(codes);
      }
    },
    async handleEnableShelve() {
      const code_set = R.map(R.prop("code"), this.multipleSelection);
      const data = {
        code_set,
        on: true,
      };
      const [err] = await api.goods.batchPutOnOffSale({
        method: "post",
        data,
      });
      if (err) return;
      this.$notify.success("上架成功");
      await this.getList();
    },
    formatPrice({ price, point }) {
      let str = "";
      if (price) str += `￥${price}`;
      if (price && point) str += "+";
      if (point) str += `${point}积分`;
      return str;
    },
  },
};
</script>

<style scoped>
.visitors {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
/* .my-tab >>> .el-tabs__item.is-active {
  border-bottom-color: #409eff;
  background: #fff;
} */
.code-img {
  width: 90px;
  height: 90px;
}
</style>
<style>
.sortable-ghost {
  opacity: 0.8;
  color: #fff !important;
  background: #42b983 !important;
}
</style>
