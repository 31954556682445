<template>
  <el-dialog :visible="visible" :title="`${ruleObject && ruleObject.id ? '修改' : '新增'}关键字`" @close="close">
    <el-form label-width="80px">
      <el-form-item label="规则名称">
        <el-input v-model="ruleObject.name" style="width: 30%" placeholder="请输入规则名称" />
      </el-form-item>
      <el-form-item label="关键词">
        <template v-for="(item, index) in ruleObject.key_info_list">
          <div :key="index" class="flex mb-[10px]">
            <el-select v-model="item.match" class="mr-[10px]" style="width: 20%" placeholder="请输入规则名称">
              <el-option value="HALF" label="半匹配" />
              <el-option value="ALL" label="全匹配" />
            </el-select>
            <el-input v-model="item.key" class="mr-[10px]" style="width: 20%" placeholder="请输入关键词" />
            <el-button
              v-if="ruleObject.key_info_list.length < MAX_KEYWORD_LENGTH"
              type="primary"
              icon="el-icon-plus"
              circle
              @click="handleAdd(index)"
            />
            <el-button v-if="ruleObject.key_info_list.length > 1" type="primary" icon="el-icon-minus" circle @click="handleDelete(index)" />
          </div>
        </template>
      </el-form-item>
      <el-form-item label="回复类型">
        <el-radio v-model="ruleObject.message_type" label="TEXT"> 文字 </el-radio>
        <el-radio v-model="ruleObject.message_type" label="IMAGE"> 图片 </el-radio>
      </el-form-item>
      <el-form-item label="回复内容">
        <el-input
          v-if="ruleObject.message_type === 'TEXT'"
          v-model="ruleObject.response_text"
          style="width: 50%"
          type="textarea"
          placeholder="请输入回复文案"
          :autosize="{ minRows: 5, maxRows: 7 }"
          maxlength="200"
        />
        <UploadImg
          v-else
          accept="image/jpg,image/png,image/jpeg"
          :token="token"
          :limit-count="1"
          :already-file="ruleObject.image_url"
          @uploadSuccess="handleUploadSuccess"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import api from "@/api";
import UploadImg from "@/components/Upload/UploadImg";
import * as R from "ramda";

const MAX_KEYWORD_LENGTH = 10;
const initRuleObject = {
  id: null,
  key_info_list: [
    {
      key: "",
      match: "ALL",
    },
  ],
  response_text: null,
  image_url: null,
  message_type: "TEXT",
  type: "AUTO_SPEECH",
};
export default {
  name: "KeywordDialog",
  components: {
    UploadImg,
  },
  data() {
    return {
      ruleObject: R.clone(initRuleObject),
      visible: false,
      token: "",
      MAX_KEYWORD_LENGTH,
    };
  },
  mounted() {
    this.fetchQiniuToken();
  },
  methods: {
    async handleConfirm() {
      const [err] = await api.customerService.keywords({
        method: this.ruleObject.id ? "PUT" : "POST",
        resource: this.ruleObject.id ? "update" : "add",
        data: this.ruleObject,
      });
      if (!err) {
        this.close();
        this.$message.success("操作成功");
        this.$emit("success");
      }
    },
    handleAdd(index) {
      this.ruleObject.key_info_list.splice(index + 1, 0, {
        key: "",
        match: "ALL",
      });
    },
    handleDelete(index) {
      this.ruleObject.key_info_list.splice(index, 1);
    },
    async fetchQiniuToken() {
      const [err, res] = await api.auth.getQiNiuToken();
      if (err) return console.error(err);
      this.token = res;
    },
    handleUploadSuccess([image] = []) {
      this.ruleObject.image_url = image?.url ?? "";
    },
    open(ruleObject) {
      this.ruleObject = R.clone(ruleObject) ?? R.clone(initRuleObject);
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style scoped></style>
