var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "h-full w-full flex justify-center",
      },
      [
        _c("img", {
          staticClass: "max-h-[50%] max-w-[40%] object-contain mt-[10vh]",
          attrs: {
            src: require("@/assets/welcome.png"),
            alt: "404",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }