var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "transition",
    {
      attrs: {
        name: "vac-slide-up",
      },
    },
    [
      _vm.filteredUsersTag.length
        ? _c(
            "div",
            {
              staticClass: "vac-tags-container vac-app-box-shadow",
              style: {
                bottom: "".concat(
                  _vm.$parent.$refs.roomFooter.clientHeight,
                  "px"
                ),
              },
            },
            _vm._l(_vm.filteredUsersTag, function (user) {
              return _c(
                "div",
                {
                  key: user._id,
                  staticClass: "vac-tags-box",
                  on: {
                    click: function click($event) {
                      return _vm.$emit("select-user-tag", user)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "vac-tags-info",
                    },
                    [
                      user.avatar
                        ? _c("div", {
                            staticClass: "vac-avatar vac-tags-avatar",
                            style: {
                              "background-image": "url('".concat(
                                user.avatar,
                                "')"
                              ),
                            },
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "vac-tags-username",
                        },
                        [_vm._v(" " + _vm._s(user.username) + " ")]
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }