import Layout from "@/layout";

const orderRouter = {
  path: "/order",
  component: Layout,
  redirect: "/order_manage",
  name: "order",
  meta: {
    title: "订单管理",
    icon: "mall-order",
    affix: true,
    roles: ["admin", "guest"],
  },
  children: [
    {
      path: "order_manage",
      name: "OrderManage",
      component: () => import("@/views/mall-manage/order-manage/index"),
      meta: { title: "订单列表" },
    },
    {
      path: "order_detail",
      name: "OrderDetail",
      hidden: true,
      component: () => import("@/views/mall-manage/order-manage/OrderDetail"),
      meta: { title: "订单详情", activeMenu: "/order/order_manage" },
    },
    // {
    //   path: "order_other",
    //   name: "OrderOther",
    //   component: () => import("@/views/mall-manage/order-other/index"),
    //   meta: { title: "其他发货" },
    // },
    {
      path: "other_detail",
      name: "OrderOtherDetail",
      hidden: true,
      component: () => import("@/views/mall-manage/order-other/OtherOrderDetail"),
      meta: { title: "发货详情", activeMenu: "/order/order_other" },
    },
    {
      path: "after_sales",
      name: "AfterSales",
      component: () => import("@/views/mall-manage/after-sales"),
      meta: { title: "售后管理" },
    },
    {
      path: "sales_detail",
      name: "SalesDetail",
      hidden: true,
      component: () => import("@/views/mall-manage/after-sales/AfterSalesDetail"),
      meta: { title: "售后详情", activeMenu: "/order/after_sales" },
    },

    // 米良人良按时发货单
    {
      path: "schedule_order",
      name: "ScheduleOrder",
      component: () => import("@/views/mall-manage/schedule-order"),
      meta: { title: "定时发货订单" },
    },
    {
      path: "schedule_detail",
      name: "ScheduleDetail",
      hidden: true,
      component: () => import("@/views/mall-manage/schedule-order/OrderDetail"),
      meta: { title: "定时发货单详情", activeMenu: "/order/schedule_order" },
    },
    {
      path: "dispatch_manage",
      name: "DispatchManage",
      component: () => import("@/views/mall-manage/dispatch-manage"),
      meta: { title: "发货管理" },
    },
  ],
};

export default orderRouter;
