var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass:
        "filter-main flex items-center my-[10px] flex-wrap gap-[10px]",
    },
    [
      _vm._t("default"),
      _vm.$slots.buttons
        ? _c(
            "div",
            {
              staticClass: "ml-[20px]",
            },
            [_vm._t("buttons")],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }