import Layout from "@/layout";

const shopRouter = {
  path: "/shop",
  component: Layout,
  redirect: "/shop_manage",
  name: "shop",
  meta: {
    title: "店铺管理",
    icon: "store-manage",
    roles: ["admin"],
  },
  children: [
    {
      path: "/shop_manage",
      name: "ShopManage",
      component: () => import("@/views/shop/shop-manage"),
      meta: { title: "店铺管理", icon: "store-manage" },
    },
  ],
};

export default shopRouter;
