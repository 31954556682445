<template>
  <div class="window-container" :class="{ 'window-mobile': isDevice }">
    <chat-window
      :height="screenHeight"
      :styles="styles"
      :current-user-id="currentUserId"
      :room-id="roomId"
      :rooms="loadedRooms"
      :finished-rooms="finishedRooms"
      :loading-rooms="loadingRooms"
      :messages="messages"
      :messages-loaded="messagesLoaded"
      :rooms-loaded="roomsLoaded"
      :room-actions="roomActions"
      :menu-actions="menuActions"
      :room-message="roomMessage"
      @fetch-more-rooms="fetchMoreRooms"
      @fetch-messages="fetchMessages"
      @send-message="sendMessage"
      @edit-message="editMessage"
      @delete-message="deleteMessage"
      @open-file="openFile"
      @open-user-tag="openUserTag"
      @add-room="addRoom"
      @room-action-handler="menuActionHandler"
      @menu-action-handler="menuActionHandler"
      @send-message-reaction="sendMessageReaction"
      @typing-message="typingMessage"
      @toggle-rooms-list="$emit('show-demo-options', $event.opened)"
    />
  </div>
</template>

<script>
import { isSameDay, parseTimestamp } from "../../../chat/utils/dates";
import ChatWindow from "../../../chat/ChatWindow";
import { mapState } from "vuex";
import api from "@/api";

export default {
  components: {
    ChatWindow,
  },

  props: ["currentUserId", "theme", "isDevice"],

  data() {
    return {
      loadedRooms: [], // 左侧联系人列表
      finishedRooms: [],
      roomsPerPage: 15,
      rooms: [],
      roomId: "",
      startRooms: null,
      endRooms: null,
      roomsLoaded: false,
      loadingRooms: true,
      allUsers: [],
      loadingLastMessageByRoom: 0,
      roomsLoadedCount: false,
      selectedRoom: null,
      messagesPerPage: 20,
      // messages: [
      //   {
      //     _id: this.currentUserId,
      //     content: "我是一条消息",
      //     senderId: 1234,
      //     avatar: "https://cdn.jmj1995.com/front_161578905677754.jpeg",
      //     date: this.formatTimestamp(new Date(), new Date()),
      //     timestamp: this.formatTimestamp(new Date(), new Date()),
      //   },
      // ],
      messagesLoaded: false,
      roomMessage: "",
      startMessages: null,
      endMessages: null,
      roomsListeners: [],
      listeners: [],
      typingMessageCache: "",
      disableForm: false,
      addNewRoom: null,
      addRoomUsername: "",
      inviteRoomId: null,
      invitedUsername: "",
      removeRoomId: null,
      removeUserId: "",
      removeUsers: [],
      roomActions: [
        { name: "inviteUser", title: "Invite User" },
        { name: "removeUser", title: "Remove User" },
        { name: "deleteRoom", title: "Delete Room" },
      ],
      menuActions: [
        { name: "inviteUser", title: "Invite User" },
        { name: "removeUser", title: "Remove User" },
        { name: "deleteRoom", title: "Delete Room" },
      ],
      styles: { container: { borderRadius: "4px" } },
      // ,dbRequestCount: 0
    };
  },

  computed: {
    ...mapState("chat", {
      messages: (state) => state.messages,
      currentRoom: (state) => state.currentRoom,
    }),
    /**
     * 左侧联系人列表
     */
    // loadedRooms() {
    // let roomTemp = [
    //   {
    //     roomId: 1,
    //     roomName: "王小虎",
    //     avatar: "https://cdn.v2ex.com/avatar/e6bd/17c5/215188_large.png?m=1613903584",
    //     phone: "13233332222",
    //     district: "广东广州",
    //     remark: "不吃辣",
    //     unreadCount: 4,
    //     lastMessage: {
    //       content: "什么时候发货啊？",
    //       senderId: 1234,
    //       username: "John Doe",
    //       timestamp: "10:20",
    //       saved: true,
    //       distributed: false,
    //       seen: false,
    //       new: true,
    //     },
    //     users: [],
    //   },
    //   {
    //     roomId: 2,
    //     roomName: "张三三",
    //     avatar: "https://66.media.tumblr.com/avatar_c6a8eae4303e_512.pnj",
    //     users: [],
    //   },
    // ];
    // return roomTemp;
    // },
    screenHeight() {
      return this.isDevice ? window.innerHeight + "px" : "calc(100vh - 140px)";
    },
  },

  mounted() {
    this.fetchRooms();
    this.updateUserOnlineStatus();
  },

  destroyed() {
    this.resetRooms();
  },

  methods: {
    /**
     * 获取会话中和已结束联系人列表并构造前端需要的联系人列表数据格式
     */
    async getRoomList() {
      const { finished, mobile_number } = this.$route.query;
      this.loadedRooms = await this.$store.dispatch("chat/setNotFinishList", { mobile_number, finished });
    },
    resetRooms() {
      console.log("resetRooms");
      this.loadingRooms = true;
      this.loadingLastMessageByRoom = 0;
      this.roomsLoadedCount = 0;
      this.rooms = [];
      this.roomsLoaded = true;
      this.startRooms = null;
      this.endRooms = null;
      this.roomsListeners.forEach((listener) => listener());
      this.roomsListeners = [];
      this.resetMessages();
    },

    resetMessages() {
      this.messagesLoaded = false;
      this.startMessages = null;
      this.endMessages = null;
      this.listeners.forEach((listener) => listener());
      this.listeners = [];
    },

    fetchRooms() {
      this.resetRooms();
      this.fetchMoreRooms();
      this.getRoomList();
    },

    async fetchMoreRooms() {
      console.log("fetchMoreRooms");
      this.roomsLoaded = true;
      this.loadingRooms = false;
    },

    formatLastMessage(message) {
      if (!message.timestamp) return;

      let content = message.content;
      if (message.file) content = `${message.file.name}.${message.file.extension || message.file.type}`;

      return {
        ...message,
        ...{
          content,
          timestamp: this.formatTimestamp(new Date(message.timestamp.seconds * 1000), message.timestamp),
          distributed: true,
          seen: message.sender_id === this.currentUserId ? message.seen : null,
          new: message.sender_id !== this.currentUserId && (!message.seen || !message.seen[this.currentUserId]),
        },
      };
    },

    formatTimestamp(date, timestamp) {
      const timestampFormat = isSameDay(date, new Date()) ? "HH:mm" : "DD/MM/YY";
      const result = parseTimestamp(timestamp, timestampFormat);
      return timestampFormat === "HH:mm" ? `今天, ${result}` : result;
    },

    fetchMessages() {
      this.$emit("show-demo-options", false);
      this.messagesLoaded = false;

      // use timeout to imitate async server fetched data
      setTimeout(() => {
        // this.messages = [];
        this.messagesLoaded = true;
      });

      // if (options.reset) this.resetMessages();

      // if (this.endMessages && !this.startMessages) return (this.messagesLoaded = true);

      // this.selectedRoom = room.roomId;
    },

    listenMessages(messages, room) {
      messages.forEach((message) => {
        const formattedMessage = this.formatMessage(room, message);
        const messageIndex = this.messages.findIndex((m) => m._id === message.id);

        if (messageIndex === -1) {
          this.messages = this.messages.concat([formattedMessage]);
        } else {
          this.$set(this.messages, messageIndex, formattedMessage);
        }
      });
    },

    formatMessage(room, message) {
      const senderUser = room.users.find((user) => message.data().sender_id === user._id);

      const { sender_id, timestamp } = message.data();

      return {
        ...message.data(),
        ...{
          senderId: sender_id,
          _id: message.id,
          seconds: timestamp.seconds,
          timestamp: parseTimestamp(timestamp, "HH:mm"),
          date: parseTimestamp(timestamp, "DD MMMM YYYY"),
          username: senderUser ? senderUser.username : null,
          // avatar: senderUser ? senderUser.avatar : null,
          distributed: true,
        },
      };
    },

    sendMessage(data) {
      // 为啥能正常在对话框显示，因为src/views/mall-im/instant/index.vue这里面的onmessage会因为发送过去而触发
      // 然后里面会触发dispatch的pushMessage到vuex，实现消息展示
      api.customerService.customerService({
        method: "post",
        resource: "sentMsg",
        data,
        headers: {
          token: "",
        },
      });
    },

    openFile({ message }) {
      window.open(message.file.url, "_blank");
    },

    async openUserTag({ user }) {
      console.log(user);
    },

    async loadRoom(query) {
      console.log(query);
    },

    async editMessage({ messageId, newContent, roomId, file }) {
      console.log(messageId, newContent, roomId, file);
    },

    async deleteMessage({ message, roomId }) {
      console.log(message, roomId);
    },

    async uploadFile({ file, messageId, roomId }) {
      console.log(file, messageId, roomId);
    },

    menuActionHandler({ action, roomId }) {
      switch (action.name) {
        case "inviteUser":
          return this.inviteUser(roomId);
        case "removeUser":
          return this.removeUser(roomId);
        case "deleteRoom":
          return this.deleteRoom(roomId);
      }
    },

    async sendMessageReaction({ reaction, remove, messageId, roomId }) {
      console.log(reaction, remove, messageId, roomId);
    },

    typingMessage() {
      // console.log(message, roomId);
    },

    async listenRooms(query) {
      const listener = query.onSnapshot((rooms) => {
        // this.incrementDbCounter('Listen Rooms Typing Users', rooms.size)
        rooms.forEach((room) => {
          const foundRoom = this.rooms.find((r) => r.roomId === room.id);
          if (foundRoom) {
            foundRoom.typingUsers = room.data().typingUsers;
            foundRoom.index = room.data().lastUpdated.seconds;
          }
        });
      });
      this.roomsListeners.push(listener);
    },

    updateUserOnlineStatus() {
      console.log("updateUserOnlineStatus");
    },

    listenUsersOnlineStatus(rooms) {
      console.log(rooms);
    },

    addRoom() {
      this.resetForms();
      this.addNewRoom = true;
    },

    async createRoom() {
      console.log("createRoom");
    },

    inviteUser(roomId) {
      this.resetForms();
      this.inviteRoomId = roomId;
    },

    async addRoomUser() {
      console.log("addRoomUser");
    },

    removeUser(roomId) {
      this.resetForms();
      this.removeRoomId = roomId;
      this.removeUsers = this.rooms.find((room) => room.roomId === roomId).users;
    },

    async deleteRoomUser() {
      console.log("deleteRoomUser");
    },

    async deleteRoom(roomId) {
      console.log("deleteRoom", roomId);
    },

    resetForms() {
      this.disableForm = false;
      this.addNewRoom = null;
      this.addRoomUsername = "";
      this.inviteRoomId = null;
      this.invitedUsername = "";
      this.removeRoomId = null;
      this.removeUserId = "";
    },

    // ,incrementDbCounter(type, size) {
    // 	size = size || 1
    // 	this.dbRequestCount += size
    // 	console.log(type, size)
    // }
  },
};
</script>

<style lang="scss" scoped>
.window-container {
  width: 100%;
  // height: calc(100vh - 260px);
}

.window-mobile {
  form {
    padding: 0 10px 10px;
  }
}

form {
  padding-bottom: 20px;
}

input {
  padding: 5px;
  width: 140px;
  height: 21px;
  border-radius: 4px;
  border: 1px solid #d2d6da;
  outline: none;
  font-size: 14px;
  vertical-align: middle;

  &::placeholder {
    color: #9ca6af;
  }
}

button {
  background: #1976d2;
  color: #fff;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 12px;
  margin-left: 10px;
  border: none;
  font-size: 14px;
  transition: 0.3s;
  vertical-align: middle;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }

  &:disabled {
    cursor: initial;
    background: #c6c9cc;
    opacity: 0.6;
  }
}

.button-cancel {
  color: #a8aeb3;
  background: none;
  margin-left: 5px;
}

select {
  vertical-align: middle;
  height: 33px;
  width: 152px;
  font-size: 13px;
  margin: 0 !important;
}
</style>
