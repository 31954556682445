<template>
  <div>
    <!-- <el-row :gutter="10">
      <el-col :span="3"> 选择商品</el-col>
      <el-col :span="2"> 待发货 1</el-col>
      <el-col :span="2"> 已发货 0</el-col>
    </el-row> -->
    <el-row :gutter="10" class="margin-item">
      <el-table
        :data="goodsData.products"
        border
        stripe
        highlight-current-row
        style="width: 100%"
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column width="200" align="center" prop="name" label="商品名称">
          <template slot-scope="{ row }">
            <div class="flex-center" style="justify-content: start">
              <el-image style="max-width: 80px; height: 80px; margin-right: 10px" :src="row.img_url" fit="contain" />
              <el-tooltip effect="dark" :content="row.goods_name" placement="top" style="overflow: hidden; text-overflow: ellipsis">
                <el-button type="text" style="max-width: 210px">
                  {{ row.goods_name }}
                </el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" prop="price" label="价格"> </el-table-column> -->
        <el-table-column align="center" prop="num" label="数量" />
        <el-table-column align="center" prop="delivery_status" label="发货状态">
          <!-- <template slot-scope="{ row }">
            <el-tag v-if="row.delivery_status == 0" type="success">已发货</el-tag>
            <el-tag v-if="row.delivery_status == 1" type="danger">待发货</el-tag>
          </template> -->
        </el-table-column>
        <el-table-column align="center" prop="logistics_order_no_list" label="运单号" />
      </el-table>
    </el-row>
    <el-row :gutter="10" class="margin-item">
      <el-col :span="3" style="font-weight: 500"> 配送信息 </el-col>
      <el-col :span="20">
        <!-- <div class="order-info-item">配送方式：快递</div> -->
        <div class="order-info-item">收货人：{{ goodsData.receiver }}</div>
        <!-- <div class="order-info-item">联系电话：{{ goodsData.tel_number }}</div> -->
        <div class="order-info-item">收货地址：{{ goodsData.province + goodsData.city + goodsData.county + goodsData.address_detail }}</div>
      </el-col>
    </el-row>
    <el-row :gutter="10" class="margin-item">
      <el-col :span="3" style="font-weight: 500"> 发货方式 </el-col>
      <el-col :span="20">
        <el-radio-group v-model="delivery_method" @change="handleDeliveryChange">
          <el-radio :disabled="is_online_dispatch" label="1"> 在线下单 </el-radio>
          <el-radio label="2"> 手动填写 </el-radio>
        </el-radio-group>
        <div v-if="need_delivery" style="margin-top: 20px">
          <div>
            物流公司：
            <el-select v-model="delivery_company" style="width: 200px">
              <el-option v-for="item in express_options" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </div>
          <div style="margin: 10px 0">
            物流单号：
            <el-input v-model="delivery_num" style="width: 200px" />
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="10" type="flex" justify="center">
      <el-button type="primary" @click="handleDelivery"> 发货 </el-button>
    </el-row>
  </div>
</template>

<script>
import { express_options } from "@/utils/my_utils/express_options";

export default {
  props: ["goodsData"],
  data() {
    return {
      express_options,
      multipleSelection: [],
      btn_delivery: true,
      need_delivery: false,
      delivery_company: "ems",
      delivery_num: "",
      delivery_method: "1",
      // goodsData: [
      //   {
      //     goods_url: "https://cdn.jmj1995.com/suancai01.jpg",
      //     name: "太二这颗酸菜",
      //     delivery_code: "20200908131606",
      //     count: 1,
      //     delivery_status: 1,
      //   },
      // ],
    };
  },
  computed: {
    is_online_dispatch() {
      let { code } = this.$store.state.user.auth_info.current_mall;
      let is_song = code == "song" ? true : false;
      let is_miliang = code == "miliang" ? true : false;
      if (is_song || is_miliang) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.need_delivery = true;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.delivery_method = "2";
      }
      if (is_song) return is_song;
      if (is_miliang) return is_miliang;
      return is_song;
    },
  },

  methods: {
    handleSelectionChange(val) {
      // console.log(val);
      val.length != 0 ? (this.btn_delivery = false) : (this.btn_delivery = true);
      this.multipleSelection = val;
    },
    handleDeliveryChange(val) {
      if (val == 2) {
        this.need_delivery = true;
      } else if (val == 1) {
        this.need_delivery = false;
      }
    },
    // handleDelivery() {
    //   let { delivery_company, delivery_num } = this;
    //   if (this.delivery_num == "") {
    //     this.$message.warning("请填写物流单号");
    //   } else {
    //     this.axios
    //       .post(`/emall_order/express`, {
    //         order_no: this.goodsData.order_no,
    //         express_info: { express_type_code: delivery_company, logistics_order_no: delivery_num },
    //       })
    //       .then((res) => {
    //         this.$emit("handleDelivery", res.code);
    //       });
    //   }
    // },
    handleDelivery() {
      this.multipleSelection.length == 0 ? this.$notify.warning("请选择商品") : this.deliveryRequest();
    },
    deliveryRequest() {
      let { delivery_method, delivery_company, delivery_num, multipleSelection } = this;
      let api = "/singleExpressOnline";
      let obj = { oms_product_id_list: multipleSelection.map((item) => item.id) };
      if (delivery_method == "2") {
        api = "/singleExpressManual";
        obj = Object.assign({ express_company: delivery_company, logistics_order_no: delivery_num }, obj);
      }
      this.axios.post(`emall_order${api}`, { ...obj }).then((res) => {
        this.$emit("handleDelivery", res.code);
        this.delivery_num = "";
      });
    },
  },
};
</script>

<style scoped>
.order-info-item {
  line-height: 22px;
}
.margin-item {
  margin: 20px 0;
}
</style>
