const getters = {
  sidebar: (state) => state.app.sidebar,
  device: (state) => state.app.device,
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  auth_info: (state) => state.user.auth_info,
  isGroupMall: (state) => state.user.isGroupMall,
  isMoneyMall: (state) => state.user.isMoneyMall,
  cachedViews: (state) => state.tagsView.cachedViews,
  visitedViews: (state) => state.tagsView.visitedViews,
  permission_routes: (state) => state.permission.routes,
};
export default getters;
