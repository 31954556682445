import { transformUtil } from "@/utils";

const ARR_LIST_MAP = {
  INCOME_ACCOUNT: [{ label: "商品", value: "GOODS" }],
  // 扣减库存时机
  DECREASE_STOCK_OCCASION: [
    { label: "提交订单减库存", value: "CREATING_ORDER" },
    { label: "支付订单减库存", value: "PAID" },
  ],
  // 配送方法
  DELIVER_METHOD: [
    { label: "快递", value: "EXPRESS_DELIVERY" },
    { label: "自提", value: "CUSTOMER_PICK_UP" },
    { label: "无需快递", value: "NO_NEED_TO_DELIVER" },
  ],
  PAY_TYPE: [
    { label: "现金", value: "MONEY" },
    { label: "积分", value: "POINTS" },
    { label: "积分+现金", value: "MIX_MONEY_POINTS" },
  ],
  MEMBER_RIGHTS_TYPE: [
    { label: "无权益", value: 0 },
    { label: "现金折扣", value: 1 },
    { label: "积分折扣", value: 2 },
    { label: "积分+现金折扣", value: 3 },
  ],
  ORDER_QUOTA_TYPE: [
    { label: "终身限购", value: "LIFETIME" },
    { label: "每月", value: "PER_MONTH" },
    { label: "每周", value: "PER_WEEK" },
    { label: "每天", value: "PER_DAY" },
  ],
  SALE_STATUS: [
    { label: "上架开售", value: "FOR_SALE" },
    { label: "上架补货中", value: "REPLENISHING" },
    { label: "自定义上架售卖时间", value: "START_SELL_AFTER" },
    { label: "暂不售卖", value: "CURRENTLY_NOT_FOR_SALE" },
  ],
  // 商品类型
  TYPE: [
    { label: "实物商品", value: "PHYSICAL" },
    { label: "电子商品", value: "ELECTRONIC" },
    { label: "定时发货", value: "REGULAR" }, // TODO 暂时没有，迁移数据库才有
  ],
  STATUS: [
    { label: "销售中", value: "ONSALE" },
    { label: "已售罄", value: "SOLDOUT" },
    { label: "补货中", value: "REPLENISHING" },
    { label: "待上架", value: "WATTING" },
    { label: "仓库中", value: "WAREHOUSE" },
  ],
  ORDER_STATUS: [
    { label: "待付款", value: "TO_BE_PAID" },
    { label: "售后中", value: "AFTER_SALE_ING" },
    { label: "已完成", value: "COMPLETED" },
    { label: "已关闭", value: "CLOSED" },

    { label: "待发货", value: "TO_BE_DELIVERED" },
    { label: "已申请发货", value: "REQUESTED_DELIVER" },
    { label: "配送中", value: "DELIVERING" },

    { label: "待备货", value: "WAITING_FOR_SHOPPING" },
    { label: "已备货", value: "SHOPPING" },
    { label: "待自提", value: "TO_BE_PICKED_UP" },
  ],
  AFTER_SALE_STATUS: [
    { label: "用户已申请退款", value: "REQUESTED_REFUND" },
    { label: "商家已同意退款申请", value: "REFUND_REQUEST_APPROVED" },
    { label: "已退款", value: "REFUNDED" },
    { label: "用户已申请退款退货", value: "REQUESTED_REFUND_AND_RETURN_GOODS" },
    { label: "商家已同意退款退货申请", value: "REFUND_AND_RETURN_GOODS_REQUEST_APPROVED" },
    { label: "买家退货中", value: "RETURNING_GOODS" },
    { label: "商家同意退货", value: "RETURN_GOODS_APPROVED" },
    { label: "商家拒绝退货", value: "RETURN_GOODS_REFUSED" },
    { label: "已退款退货", value: "REFUNDED_AND_RETURNED_GOODS" },
  ],
  AFTER_SALE_TICKET_STATUS: [
    { label: "用户已申请", value: "REQUESTED" },
    { label: "商家已同意申请", value: "REQUEST_APPROVED" },
    { label: "买家退货中", value: "RETURNING_GOODS" },
    { label: "商家同意退货", value: "RETURN_GOODS_APPROVED" },
    { label: "商家拒绝退货", value: "RETURN_GOODS_REFUSED" },
    { label: "已完成", value: "COMPLETED" },
    { label: "商家已拒绝申请", value: "REQUEST_REFUSED" },
    { label: "买家处理超时", value: "BUYER_TIMEOUT" },
    { label: "买家取消申请", value: "CANCELED" },
    { label: "未知", value: "UNKNOWN" },
  ],
  AFTER_SALE_TYPE: [
    { label: "仅退款", value: "REFUND" },
    { label: "退货退款", value: "REFUND_AND_RETURN_GOODS" },
  ],
  DELIVERY_STATUS: [
    { label: "待配送", value: "TO_BE_DELIVERED" },
    { label: "已申请配送", value: "REQUESTED_DELIVER" },
    { label: "配送中", value: "DELIVERING" },
    { label: "待自提", value: "TO_BE_PICKED_UP" },
    { label: "已收到", value: "RECEIVED" },
    { label: "未知", value: "UNKNOWN" },
  ],
  OPERATOR_ROLE: [
    { label: "待商家处理", value: "MERCHANT" },
    { label: "待买家处理", value: "BUYER" },
  ],
  AFTER_SALE_OPERATION: [
    { label: "买家申请售后，等待商家处理", value: "BUYER_REQUEST_AFTER_SALE" },
    { label: "买家修改售后申请，等待商家处理", value: "BUYER_UPDATE_AFTER_SALE_TICKET" },
    { label: "买家取消售后", value: "BUYER_CANCEL_AFTER_SALE_TICKET" },
    { label: "买家已退货，待商家确认收货", value: "BUYER_INPUT_RETURNING_GOODS_LOGISTICS_INFO" },
    { label: "买家修改退货，待商家重新确认收货", value: "BUYER_MODIFY_RETURNING_GOODS_LOGISTICS_INFO" },
    { label: "商家同意退款申请", value: "MERCHANT_APPROVE_REFUND_REQUEST" },
    { label: "商家拒绝退款申请，待买家处理", value: "MERCHANT_REFUSE_REFUND_REQUEST" },
    { label: "商家同意退款退货申请，待买家退货", value: "MERCHANT_APPROVE_REFUND_AND_RETURN_GOODS_REQUEST" },
    { label: "商家拒绝退款退货申请，待买家处理", value: "MERCHANT_REFUSE_REFUND_AND_RETURN_GOODS_REQUEST" },
    { label: "商家确认收货", value: "MERCHANT_APPROVE_RETURN_GOODS" },
    { label: "商家拒绝收货，待买家处理", value: "MERCHANT_REFUSE_RETURN_GOODS" },
    { label: "商家退款", value: "MERCHANT_REFUNDED" },
  ],
};

export default transformUtil.arrListToMapLists(ARR_LIST_MAP);
