<template>
  <div>
    <el-alert title="物流信息仅支持 2 次更正，请仔细填写并核对。" type="warning" show-icon :closable="false" />
    <el-form ref="form" :model="form" :rules="formRules" label-width="90px" label-position="left" style="padding-top: 20px">
      <div v-for="(item, index) in pacData" :key="index">
        <el-row type="flex">
          <el-form-item :label="`包裹${index + 1}`" label-width="50px" />
          <el-form-item :label="`共${item.num}件商品`" />
        </el-row>
        <el-row type="flex">
          <el-col :span="12">
            <el-form-item label="物流公司">
              <el-select v-model="item.express_company" style="width: 200px">
                <el-option v-for="option in express_options" :key="option.value" :label="option.label" :value="option.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="运单编号">
              <el-input v-model="item.logistics_order_no" style="width: 200px" />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>

    <div class="dialog-footer">
      <el-button type="text" @click="handleCancel"> 取消 </el-button>
      <el-button type="primary" @click="handleConfirm"> 保存 </el-button>
    </div>
  </div>
</template>

<script>
import { express_options } from "@/utils/my_utils/express_options";
export default {
  props: {
    orderNo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      express_options,
      delivery_company: "",
      form: {
        order_no: this.orderNo,

        express_company: "",
        logistics_order_no: "",
        num: "",
      },
      formRules: {},
      pacData: [],
    };
  },
  mounted() {
    this.getPackages();
  },

  methods: {
    getPackages() {
      this.axios.get(`/emall_order/express/getPackages/${this.orderNo}`).then((res) => {
        this.pacData = res.ndata;
      });
    },
    handleCancel() {
      this.$emit("closed", "cancel");
    },
    handleConfirm() {
      // this.$refs.form.validate((valid) => {
      //   if (valid) {
      //     this.axios.post(`/emall_order/address/updateAfterPaid`, { ...this.form }).then(() => {
      //       this.$emit("closed", "confirm");
      //       this.$refs.form.resetFields();
      //     });
      //   }
      // });
      console.log(this.pacData);
      this.axios.post(`/emall_order/express/updateAfterDeliver`, [...this.pacData]).then(() => {
        this.$message.success("修改成功");
        this.$emit("closed", "confirm");
      });
    },
  },
};
</script>

<style></style>
