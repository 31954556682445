<template>
  <el-dialog width="1000px" :visible="visible" title="批量发货" :close-on-click-modal="false" @close="handleVisibleChange(false)">
    <div class="flex">
      <div>
        <div v-for="(activity, index) in activities" :key="index" class="step">
          <div class="dot">
            {{ index + 1 }}
          </div>
          <div>
            <div class="first-row">{{ activity.firstRow }}</div>
            <div class="second-row">{{ activity.secondRow }}</div>
          </div>
          <div v-if="index < activities.length - 1" class="tiem-line"></div>
        </div>
      </div>

      <div class="grow">
        <el-form ref="form" :model="downloadData" label-width="120px">
          <el-form-item label="订单状态：">
            待发货<span class="ml-[30px]">注意：仅支持订单内所有商品都未发货的订单，部分商品已发货的订单不支持批量发货。</span>
          </el-form-item>

          <el-form-item prop="dates" label="订单日期范围：" :rules="rules.dates" required>
            <el-date-picker
              v-model="downloadData.dates"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :clearable="false"
              value-format="yyyy-MM-dd"
              @change="handleDateChange"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item prop="logistics_company" label="物流公司：" :rules="rules.logistics_company">
            <el-select v-model="downloadData.logistics_company" placeholder="请选择物流公司" filterable>
              <el-option v-for="item in express_options" :key="item.code" :label="item.name" :value="item.name" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button round type="primary" :loading="downloadLoading" @click="handleExport">导出订单</el-button>
          </el-form-item>
          <el-form-item label="温馨提示：">
            1、请勿更改导出文件中的订单号及物流公司，否则可能导致发货失败。 <br />2、在导出文件中填写物流单号。
            <br />3、上传前检查数据的完整性及准确性，即确认好物流商的选择是否正确，<br />物流单号是否填写完整，否则无法完成批量发货。
          </el-form-item>
          <el-form-item>
            <el-upload
              ref="upload"
              class="upload-container"
              :on-remove="handleRemove"
              action="empty"
              :http-request="handleCheck"
              :file-list="fileList"
              :limit="1"
            >
              <el-button v-if="!fileList.length" round type="primary" class="mr-[10px]">上传文件</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item v-if="success_count + fail_count">
            共上传 {{ success_count + fail_count }} 个，成功 <span class="blue">{{ success_count }}</span> 个，失败
            <span class="red">{{ fail_count }}</span> 个
            <div v-if="fail_count">失败原因：</div>
            <div class="fail-list-container">
              <div v-for="(item, index) in fail_list" :key="`${item.order_num}_${index}`">订单{{ item.order_num }}导入失败，{{ item.fail_msg }}</div>
            </div>
          </el-form-item>
          <el-form-item class="mt-[100px]">
            <el-button round @click="handleVisibleChange(false)">取消</el-button>
            <el-button round type="primary" @click="handleSubmit">提交并发货</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import api from "@/api";
import { downloadBlobFile, commonUtil } from "@/utils";

const { to, tryCatch } = commonUtil;
const activities = [
  {
    firstRow: "第一步",
    secondRow: "导出订单文件",
  },
  {
    firstRow: "第二步",
    secondRow: "输入快递单号",
  },
  {
    firstRow: "第三步",
    secondRow: "上传物流信息",
  },
];
export default {
  name: "BatchDispatchDialog",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const validateDates = (rule, value, callback) => {
      if (!value.length) {
        callback(new Error("请选择订单日期范围"));
      } else {
        callback();
      }
    };
    return {
      activities,
      downloadLoading: false,
      express_options: [],
      rules: {
        dates: [{ validator: validateDates, trigger: "blur", message: "请选择订单日期范围" }],
        logistics_company: [{ required: true, message: "请选择物流公司", trigger: "blur" }],
      },
      downloadData: {
        dates: [],
        place_date_start: "",
        place_date_end: "",
        logistics_company: "",
      },
      fileList: [],
      fail_count: 0,
      success_count: 0,
      fail_list: [],
    };
  },
  async created() {
    [, this.express_options] = await api.logistics.logisticsCompanies();
  },
  methods: {
    handleDateChange(dates) {
      [this.downloadData.place_date_start, this.downloadData.place_date_end] = dates;
    },
    async handleExport() {
      const [validateError] = await to(this.$refs.form.validate());
      if (validateError !== null) return;

      this.downloadLoading = true;
      const [err, blobData] = await api.order.downloadToBeDeliverOrder({
        method: "post",
        responseType: "blob",
        data: this.downloadData,
      });
      if (!err)
        await tryCatch(downloadBlobFile, {
          obj: blobData,
          name: `订单${this.downloadData.place_date_start}-${this.downloadData.place_date_end}`,
          suffix: "xlsx",
        });
      this.downloadLoading = false;
    },
    async handleCheck(elUploadObj) {
      const { file } = elUploadObj;
      if (!file) return;
      this.fileList = [file];
      const formData = new FormData();
      formData.append("file", file);
      const [err, res] = await api.order.checkToBeDeliverOrder({
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
      });

      if (err) return;
      ({ fail_count: this.fail_count, success_count: this.success_count, fail_list: this.fail_list } = res);
    },
    handleRemove() {
      this.resetCheckInfo();
    },
    resetCheckInfo() {
      this.fail_count = 0;
      this.success_count = 0;
      this.fail_list = [];
      this.fileList = [];
    },
    async handleSubmit() {
      if (this.fileList.length === 0) return this.$message.warning("未上传订单文件");

      const file = this.fileList[0];
      const formData = new FormData();
      formData.append("file", file);
      const [err] = await api.order.uploadToBeDeliverOrder({
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
      });

      if (err) return;
      this.$message.success("提交并发货成功");
      this.handleVisibleChange(false);
    },
    handleVisibleChange(visible) {
      this.$emit("update:visible", visible);
    },
  },
};
</script>

<style lang="scss" scoped>
.fail-list-container {
  max-height: 200px;
  overflow-y: auto;
}
.blue {
  color: #1890ff;
}
.red {
  color: #d9001b;
}
.step {
  width: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #409eff;
}
.dot {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border: 2px solid #409eff;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-bottom: 6px;
}
.first-row,
.second-row {
  text-align: center;
  font-size: 14px;
  line-height: 1.4em;
  font-weight: 500;
}
.tiem-line {
  height: 50px;
  width: 1px;
  background-color: #409eff;
  margin-top: 8px;
  margin-bottom: 10px;
}
.upload-container {
  display: flex;
  align-items: flex-end;
}
::v-deep {
  .upload-container .el-upload {
    width: auto;
  }
  .el-upload-list__item:first-child {
    margin: unset;
  }
}
</style>
