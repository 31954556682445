<template>
  <div class="page-padding">
    <div class="page-header--wrap">
      <div class="page-header-wrap--title">售后管理</div>
    </div>

    <el-row class="page-filter--wrap">
      <div class="page-color--normal">订单编号</div>
      <el-input v-model="searchForm.order_number" placeholder="请输入订单编号" class="page-select--item" />
      <div class="page-color--normal">申请时间</div>
      <el-date-picker
        v-model="apply_time"
        value-format="yyyy-MM-dd"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="handleDateChange"
      />
    </el-row>
    <el-row class="page-filter--wrap" />
    <el-row class="page-filter--wrap">
      <div class="page-color--normal">售后编号</div>
      <el-input v-model="searchForm.after_sale_ticket_number" placeholder="请输入商品名称" class="page-select--item" />
      <div class="page-color--normal">商品名称</div>
      <el-input v-model="searchForm.after_sale_goods_name" placeholder="请输入商品名称" class="page-select--item" />
      <div class="page-color--normal">售后类型</div>
      <el-select v-model="searchForm.after_sale_type" placeholder="请选择" class="page-select--item">
        <el-option label="全部" :value="undefined" />
        <el-option v-for="item in AFTER_SALE_TYPE" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
    </el-row>
    <el-row class="page-filter--wrap">
      <div class="page-color--normal">退款类型</div>
      <el-select v-model="searchForm.after_sale_ticket_status" placeholder="请选择" class="page-select--item">
        <el-option label="全部" :value="undefined" />
        <el-option v-for="item in AFTER_SALE_TICKET_STATUS" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <div class="page-color--normal">物流状态</div>
      <el-select v-model="searchForm.delivery_status" placeholder="请选择" class="page-select--item">
        <el-option label="全部" :value="undefined" />
        <el-option v-for="item in DELIVERY_STATUS" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <el-button round icon="el-icon-refresh" @click="resetSearchForm"> 重置 </el-button>
      <el-button type="primary" round icon="el-icon-search" @click="getList"> 搜索 </el-button>
      <el-popconfirm title="是否按当前搜索条件导出文件？" @onConfirm="exportFile">
        <el-button slot="reference" type="success" round :loading="downloading" icon="el-icon-download" style="margin-left: 10px">
          导出文件
        </el-button>
      </el-popconfirm>
      <el-button v-if="isMerchant" type="success" round icon="el-icon-mouse" style="margin-left: 10px" @click="handleBatch"> 批量操作 </el-button>
    </el-row>

    <!-- 售后单列表 -->
    <el-tabs v-model="need_to_react_operator_role" type="border-card" class="my-tab" @tab-click="handleClick">
      <el-tab-pane label="全部" name="all" />
      <el-tab-pane v-for="item in OPERATOR_ROLE" :key="item.value" :label="item.label" :name="item.value" />
      <el-table
        v-loading="listLoading"
        :data="salesData"
        stripe
        border
        highlight-current-row
        style="width: 100%"
        :row-style="{ height: '65px' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column v-if="isMerchant" key="selection" type="selection" width="55" align="center" />
        <el-table-column v-else key="index" type="index" label="序号" width="55" align="center" />
        <el-table-column label="操作" width="100px" align="center">
          <template slot-scope="scope">
            <el-button type="text" :disabled="isRefundButtonDisabled(scope.row.status)" @click="handleRefund(scope.row)"> 处理退款 </el-button>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="status"
          :formatter="(_, __, value) => AFTER_SALE_TICKET_STATUS_VALUE_DIC[value]"
          label="售后状态"
          width="150px"
          show-overflow-tooltip
        />

        <el-table-column align="center" prop="ticket_number" label="售后编号" width="250px" show-overflow-tooltip>
          <template slot-scope="{ row }">
            <el-button type="text" @click="handleRefund(row)">
              {{ row.ticket_number }}
            </el-button>
            <el-tooltip content="复制编号" placement="top">
              <el-button type="text" @click="handleCopy(row.ticket_number, $event)">
                <i class="el-icon-document-copy" />
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="" label="订单编号" width="250px" show-overflow-tooltip>
          <template slot-scope="{ row }">
            <el-button type="text" @click="handleOrderDetail(row)">
              {{ row.related_order_number }}
            </el-button>
            <el-tooltip content="复制编号" placement="top">
              <el-button type="text" @click="handleCopy(row.related_order_number, $event)">
                <i class="el-icon-document-copy" />
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="order_line_goods_name" label="商品名称" min-width="350px" show-overflow-tooltip />
        <el-table-column
          align="center"
          prop="order_line_delivery_status"
          :formatter="(_, __, value) => DELIVERY_STATUS_VALUE_DIC[value]"
          label="物流状态"
          width="120px"
          show-overflow-tooltip
        />
        <el-table-column align="center" label="退款金额" width="140px" show-overflow-tooltip>
          <template #default="{ row }">
            {{ formatRefundAmount(row) }}
          </template>
        </el-table-column>
      </el-table>

      <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    </el-tabs>
    <el-dialog title="批量处理退款" :visible.sync="batchRefundDialogVisible" width="630px" @open="batchRefundForm.agree = true">
      <el-form>
        <el-form-item>
          <el-radio-group v-model="batchRefundForm.agree" @change="batchRefundForm.operation_reason = ''">
            <div>
              <el-radio :label="true">同意退款申请</el-radio>
            </div>
            <div class="mt-[10px]">
              <el-radio :label="false">拒绝退款申请</el-radio>
            </div>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="!batchRefundForm.agree" label="拒绝理由">
          <el-input v-model="batchRefundForm.operation_reason" type="textarea" style="width: 500px"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" style="margin-top: -20px">
        <el-button :loading="batchLoading" class="mr-[10px]" @click="batchRefundDialogVisible = false">取 消</el-button>
        <el-popconfirm :title="`确定${batchRefundForm.agree ? '同意' : '拒绝'}批量退款`" @onConfirm="batchProcessRefund">
          <el-button slot="reference" :loading="batchLoading" type="primary">确 定</el-button>
        </el-popconfirm>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TableListMixin from "@/mixin/TableList";
import Pagination from "@/components/Pagination";
import clip from "@/utils/clipboard";
import api from "@/api";
import { GOODS } from "@/constants";
import * as R from "ramda";
import { downloadBlobFile, commonUtil, transformUtil } from "@/utils";

const { tryCatch } = commonUtil;
const { omitEmptyValue } = transformUtil;
const {
  AFTER_SALE_STATUS,
  AFTER_SALE_TICKET_STATUS_VALUE_DIC,
  DELIVERY_STATUS_VALUE_DIC,
  AFTER_SALE_TYPE,
  AFTER_SALE_TICKET_STATUS,
  AFTER_SALE_TICKET_STATUS_VALUE_MAP,
  DELIVERY_STATUS,
  OPERATOR_ROLE,
  OPERATOR_ROLE_VALUE_MAP,
} = GOODS;
const initFormData = {
  order_number: undefined,
  after_sale_ticket_create_date_start: undefined,
  after_sale_ticket_create_date_end: undefined,
  after_sale_ticket_number: undefined,
  after_sale_goods_name: undefined,

  after_sale_type: undefined,
  after_sale_ticket_status: undefined,
  delivery_status: undefined,
  need_to_react_operator_role: OPERATOR_ROLE_VALUE_MAP["MERCHANT"],
};
export default {
  name: "AfterSales",
  components: {
    Pagination,
  },
  mixins: [TableListMixin],
  data() {
    return {
      AFTER_SALE_STATUS,
      AFTER_SALE_TICKET_STATUS_VALUE_DIC,
      DELIVERY_STATUS_VALUE_DIC,
      AFTER_SALE_TICKET_STATUS,
      AFTER_SALE_TYPE,
      DELIVERY_STATUS,
      OPERATOR_ROLE,
      downloading: false,
      order_num: "",
      valueBrand: "",
      apply_time: [],
      listLoading: false,
      salesData: [],
      searchForm: R.clone(initFormData),
      need_to_react_operator_role: OPERATOR_ROLE_VALUE_MAP["MERCHANT"],

      batchRefundDialogVisible: false,
      batchLoading: false,
      batchRefundForm: {
        after_sale_ticket_number_list: [],
        agree: true,
        operation_reason: "",
      },
    };
  },
  computed: {
    isMerchant() {
      return this.need_to_react_operator_role === OPERATOR_ROLE_VALUE_MAP["MERCHANT"];
    },
  },
  watch: {
    $route(to) {
      if (to.name === "AfterSales" && this.need_to_react_operator_role !== "all") {
        this.getList();
      }
    },
  },
  created() {
    this.getList();
  },
  methods: {
    isRefundButtonDisabled(status) {
      return R.includes(status, [
        AFTER_SALE_TICKET_STATUS_VALUE_MAP["COMPLETED"],
        AFTER_SALE_TICKET_STATUS_VALUE_MAP["REQUEST_REFUSED"],
        AFTER_SALE_TICKET_STATUS_VALUE_MAP["BUYER_TIMEOUT"],
        AFTER_SALE_TICKET_STATUS_VALUE_MAP["CANCELED"],
        AFTER_SALE_TICKET_STATUS_VALUE_MAP["UNKNOWN"],
      ]);
    },
    handleCopy(v, e) {
      clip(v, e);
    },
    // 根据查询条件导出Excel文件
    async exportFile() {
      this.downloading = true;
      let fileName = "售后订单";
      const [start, end] = this.apply_time;
      if (start && end) {
        if (start === end) fileName += `${start.split("-").join("")}`;
        else fileName += `${start.split("-").join("")}_${end.split("-").join("")}`;
      }

      const [err, blobData] = await api.order.downloadAfterSaleExcel({
        method: "post",
        responseType: "blob",
        data: R.omit(["page", "size"], omitEmptyValue(this.searchForm)),
      });
      if (!err) await tryCatch(downloadBlobFile, { obj: blobData, name: fileName, suffix: "xlsx" });
      this.downloading = false;
    },
    resetSearchForm() {
      this.searchForm = { ...R.clone(initFormData), ...R.pick(["need_to_react_operator_role"], this.searchForm) };
      this.apply_time = [];
      this.getList();
    },
    handleDateChange(val) {
      [this.searchForm.after_sale_ticket_create_date_start, this.searchForm.after_sale_ticket_create_date_end] = val ?? [];
    },
    async getList() {
      const [, res] = await this.getListMixin(api.order.afterSaleTickets);
      this.salesData = res?.list ?? [];
    },
    handleClick(tab) {
      this.searchForm.need_to_react_operator_role = tab.name === "all" ? "" : tab.name;
      this.need_to_react_operator_role = tab.name;
      this.getList();
    },
    handleRefund(row) {
      this.$router.push({ path: "/order/sales_detail", query: { ticketNumber: row.ticket_number } });
    },
    handleOrderDetail(row) {
      this.$router.push({ path: "/order/order_detail", query: { orderId: row.related_order_id, source: "after_sales" } });
    },
    formatRefundAmount(row) {
      let str = "";
      const { refund_amount, refund_points } = row;
      if (refund_amount) str += `￥${refund_amount}`;
      if (refund_amount && refund_points) str += "+";
      if (refund_points) str += `${refund_points}积分`;
      return str;
    },
    handleSelectionChange(rows) {
      this.batchRefundForm.after_sale_ticket_number_list = rows.map((item) => item.ticket_number);
    },
    handleBatch() {
      if (this.batchRefundForm.after_sale_ticket_number_list.length === 0) {
        this.$message.error("请勾选需要处理的售后单");
        return;
      }
      this.batchRefundDialogVisible = true;
    },
    async batchProcessRefund() {
      this.batchLoading = true;
      const [err] = await api.order.batchProcessAfterSaleRequest({ data: this.batchRefundForm, method: "post" });
      this.batchLoading = false;

      if (err) return;

      this.$message.success("操作成功");
      this.getList();
      this.batchRefundDialogVisible = false;
    },
  },
};
</script>

<style scoped>
.page-select--item {
  width: 180px;
}
</style>
