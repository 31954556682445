<template>
  <section class="filter-item flex items-center">
    <div v-if="label" class="mx-[10px] text-[14px] text-[#606266] font-[500]">{{ label }}</div>
    <div>
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: "FilterItem",
  props: {
    label: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped></style>
