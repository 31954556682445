import { render, staticRenderFns } from "./Loading.vue?vue&type=template&id=ffa58c1c&scoped=true"
import script from "./Loading.vue?vue&type=script&lang=js"
export * from "./Loading.vue?vue&type=script&lang=js"
import style0 from "./Loading.vue?vue&type=style&index=0&id=ffa58c1c&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffa58c1c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/Lmiy6ZAB/1/ifirst/imall/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ffa58c1c')) {
      api.createRecord('ffa58c1c', component.options)
    } else {
      api.reload('ffa58c1c', component.options)
    }
    module.hot.accept("./Loading.vue?vue&type=template&id=ffa58c1c&scoped=true", function () {
      api.rerender('ffa58c1c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Loading.vue"
export default component.exports