var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            rules: _vm.formRule,
            model: _vm.form,
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "div",
                {
                  staticClass: "panel-item",
                  staticStyle: {
                    "margin-top": "20px",
                  },
                },
                [_vm._v("退货信息")]
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    span: 12,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "收货人姓名：",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "输入收货人姓名",
                        },
                        model: {
                          value: _vm.form.receiver_name,
                          callback: function callback($$v) {
                            _vm.$set(_vm.form, "receiver_name", $$v)
                          },
                          expression: "form.receiver_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "收货人手机：",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "输入收货人手机",
                        },
                        model: {
                          value: _vm.form.receiver_mobile_number,
                          callback: function callback($$v) {
                            _vm.$set(_vm.form, "receiver_mobile_number", $$v)
                          },
                          expression: "form.receiver_mobile_number",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "收货人地址：",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "输入收货人地址",
                        },
                        model: {
                          value: _vm.form.return_goods_address,
                          callback: function callback($$v) {
                            _vm.$set(_vm.form, "return_goods_address", $$v)
                          },
                          expression: "form.return_goods_address",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  attrs: {
                    span: 2,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "text-align": "center",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                          },
                          on: {
                            click: _vm.handelFormSubmit,
                          },
                        },
                        [_vm._v(" 确定 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }