import Layout from "@/layout";

const accountRouter = {
  path: "/system",
  component: Layout,
  redirect: "/account",
  name: "system",
  meta: {
    title: "用户管理",
    icon: "staff",
    affix: true,
    roles: ["admin"],
  },
  children: [
    {
      path: "/account",
      name: "Account",
      component: () => import("@/views/account-manage/staff-manage"),
      meta: { title: "账号管理", icon: "staff", affix: true },
    },
    {
      path: "/role",
      name: "Role",
      component: () => import("@/views/account-manage/role-manage"),
      meta: { title: "角色管理", icon: "staff", affix: true },
    },
  ],
};

export default accountRouter;
