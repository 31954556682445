<template>
  <div class="page-padding">
    <section class="page-header--wrap">
      <div class="page-header-wrap--title">限时降价</div>
    </section>
    <section class="page-filter--wrap">
      <el-form inline label-width="auto">
        <el-form-item label="活动名称">
          <el-input v-model="searchForm.name" clearable placeholder="请输入活动名称" />
        </el-form-item>
        <el-form-item label="活动开始时间">
          <el-date-picker
            v-model="startTimeRange"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
        </el-form-item>
        <el-form-item label="活动上架状态">
          <el-select v-model="searchForm.enable" clearable>
            <el-option v-for="item in ENABLE_STATUS" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left: 20px">
          <el-button :loading="loading" type="primary" round icon="el-icon-search" @click="fetchList"> 搜索 </el-button>
          <el-button round icon="el-icon-refresh" @click="resetFormData"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </section>
    <el-tabs v-model="status" v-loading="loading" type="border-card" @tab-click="fetchList">
      <el-tab-pane label="全部" name="ALL" />
      <el-tab-pane v-for="item in STATUS" :key="item.value" :label="item.label" :name="item.value" />
      <div class="flex justify-end mb-[10px]">
        <el-button type="primary" @click="handleNavAdd"> 新增活动 </el-button>
      </div>
      <el-table :data="tableData" border stripe highlight-current-row>
        <el-table-column prop="id" label="活动序号" align="center" />
        <el-table-column prop="name" label="活动名称" align="center" />
        <el-table-column prop="intro" label="活动介绍" align="center" />
        <el-table-column prop="status" label="活动状态" align="center" />
        <el-table-column :formatter="(_, __, cellValue) => ENABLE_STATUS_VALUE_DIC[cellValue]" prop="enable" label="活动上下架状态" align="center" />
        <el-table-column prop="start_time" label="开始时间" align="center" />
        <el-table-column prop="end_time" label="结束时间" align="center" />
        <el-table-column prop="create_time" label="创建时间" align="center" />
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleClick(scope.row)"> 编辑 </el-button>
            <el-button type="text" size="small" @click="handleChangeStatus(scope.row)">
              {{ scope.row.enable ? "下架" : "上架" }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-tabs>
    <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="fetchList" />
  </div>
</template>

<script>
import * as R from "ramda";
import api from "@/api";
import Pagination from "@/components/Pagination";
import TableListMixin from "@/mixin/TableList";
import { ACTIVITY } from "@/constants";

const { ENABLE_STATUS_VALUE_DIC, STATUS, ENABLE_STATUS } = ACTIVITY;
const initSearchForm = {
  name: undefined,
  start_time_start: undefined,
  start_time_end: undefined,
  enable: undefined,
};
export default {
  name: "DepreciateIndex",
  components: { Pagination },
  mixins: [TableListMixin],
  data() {
    return {
      loading: false,
      ENABLE_STATUS,
      ENABLE_STATUS_VALUE_DIC,
      STATUS,
      tableData: [],
      searchForm: R.clone(initSearchForm),
      startTimeRange: null,
      status: "ALL",
    };
  },
  watch: {
    $route(to) {
      if (to.name === "Depreciate" && to.query.isRefresh) {
        this.fetchList();
      }
    },
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    handleClick(row) {
      this.$router.push({ name: "DepreciateDetail", query: { activityId: row.id } });
    },
    async handleChangeStatus({ id, enable }) {
      const [err] = await api.activity.activity({
        method: "put",
        id,
        resource: "enable",
        data: !enable,
      });
      if (!err) {
        this.$message.success(`${enable ? "下架" : "上架"}成功`);
        await this.fetchList();
      }
    },
    formatStatus(val) {
      return val === "ALL" ? undefined : val;
    },
    async fetchList() {
      this.searchForm.status = this.formatStatus(this.status);
      [this.searchForm.start_time_start, this.searchForm.start_time_end] = this.startTimeRange ?? [];
      const [, { list }] = await this.getListMixin(api.activity.activity);
      this.tableData = list;
    },
    resetFormData() {
      this.searchForm = R.clone(initSearchForm);
      this.startTimeRange = [];
      this.fetchList();
    },
    handleNavAdd() {
      this.$router.replace({ name: "DepreciateDetail" });
    },
  },
};
</script>

<style scoped></style>
