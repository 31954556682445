var render = function render() {
  var _vm$order_data, _vm$order_data$eviden
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "page-padding",
      staticStyle: {
        background: "#fff",
      },
    },
    [
      _c(
        "sticky",
        {
          attrs: {
            "z-index": 20,
          },
        },
        [
          _c("el-page-header", {
            staticClass: "go-back",
            attrs: {
              content: "售后详情",
            },
            on: {
              back: _vm.goBack,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.detailLoading,
              expression: "detailLoading",
            },
          ],
        },
        [
          _c(
            "div",
            {
              staticClass: "flex sales-title",
              staticStyle: {
                border: "none",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "main-txt",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.AFTER_SALE_TICKET_STATUS_VALUE_DIC[
                          _vm.order_data.status
                        ]
                      ) +
                      " "
                  ),
                ]
              ),
              _vm.isCounting
                ? _c(
                    "div",
                    {
                      staticClass: "info-txt",
                    },
                    [
                      _vm._v(" （请在 "),
                      _c(
                        "span",
                        {
                          staticClass: "time-txt",
                        },
                        [_vm._v(_vm._s(_vm.countdown) + " ")]
                      ),
                      _vm._v("内处理，逾期未处理，退款自动撤销 ） "),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "panel-item flex",
              staticStyle: {
                "justify-content": "space-between",
                "padding-right": "10px",
              },
            },
            [
              _c("div", [
                _vm._v("售后编号：" + _vm._s(_vm.order_data.ticket_number)),
              ]),
              _c("div", [
                _vm._v(" 申请人："),
                _c("span", [_vm._v(_vm._s(_vm.order_data.requester))]),
                _vm._v(
                  " | 申请时间：" + _vm._s(_vm.order_data.create_time) + " "
                ),
              ]),
            ]
          ),
          _c(
            "el-card",
            [
              _c(
                "el-form",
                {
                  staticClass: "card",
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          attrs: {
                            span: 8,
                          },
                        },
                        [
                          _c("h5", [_vm._v("售后商品")]),
                          _c(
                            "div",
                            {
                              staticClass: "flex-center",
                              staticStyle: {
                                "justify-content": "start",
                              },
                            },
                            [
                              _c("el-image", {
                                staticStyle: {
                                  width: "80px",
                                  height: "80px",
                                  "margin-right": "10px",
                                },
                                attrs: {
                                  src: _vm.related_order_line_info
                                    .goods_sku_image_address,
                                  fit: "contain",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "good-name",
                                  attrs: {
                                    title:
                                      _vm.related_order_line_info.goods_name,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.related_order_line_info.goods_name
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-col",
                        {
                          attrs: {
                            span: 8,
                          },
                        },
                        [
                          _c("h5", [_vm._v("售后信息")]),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "售后状态：",
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.AFTER_SALE_TICKET_STATUS_VALUE_DIC[
                                      _vm.order_data.status
                                    ]
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "退款金额：",
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatUnitPrice({
                                      price: _vm.order_data.refund_amount,
                                      point: _vm.order_data.refund_points,
                                    })
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "买家手机：",
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.order_data.requester_mobile_number)
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "申请人手机：",
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.order_data.contactor_mobile_number)
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "退款原因：",
                              },
                            },
                            [_c("div", [_vm._v(_vm._s(_vm.order_data.reason))])]
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "退款说明：",
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.order_data.additional_description)
                                ),
                              ]),
                            ]
                          ),
                          !!(
                            (_vm$order_data = _vm.order_data) !== null &&
                            _vm$order_data !== void 0 &&
                            (_vm$order_data$eviden =
                              _vm$order_data.evidence_images) !== null &&
                            _vm$order_data$eviden !== void 0 &&
                            _vm$order_data$eviden.length
                          )
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "申请凭证：",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex-margin-top",
                                    },
                                    _vm._l(
                                      _vm.order_data.evidence_images,
                                      function (jitem, index) {
                                        return _c("el-image", {
                                          key: index,
                                          staticClass: "imgSty",
                                          attrs: {
                                            src: jitem,
                                            "preview-src-list":
                                              _vm.order_data.evidence_images,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          attrs: {
                            span: 8,
                          },
                        },
                        [
                          _c("h5", [_vm._v("购买信息")]),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "商品单价：",
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatUnitPrice({
                                        price:
                                          _vm.related_order_line_info
                                            .goods_sku_unit_price,
                                        point:
                                          _vm.related_order_line_info
                                            .goods_sku_unit_points,
                                      })
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "订单金额：",
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatUnitPrice({
                                        price:
                                          _vm.related_order_line_info
                                            .goods_sku_unit_price *
                                          _vm.order_data
                                            .number_of_after_sale_goods_sku,
                                        point:
                                          _vm.related_order_line_info
                                            .goods_sku_unit_points *
                                          _vm.order_data
                                            .number_of_after_sale_goods_sku,
                                      })
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "发货件数：",
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.order_data
                                      .number_of_after_sale_goods_sku
                                  ) + "件"
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "发货状态：",
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.related_order_line_info
                                      .delivery_status_chinese_representation
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "订单编号：",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                  },
                                  on: {
                                    click: function click($event) {
                                      return _vm.handleOrderDetail(
                                        _vm.related_order_line_info
                                          .related_order_id
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.related_order_line_info
                                          .related_order_number
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "超时时间：",
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.order_data.process_deadline)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "panel-item",
              staticStyle: {
                "margin-top": "20px",
              },
            },
            [_vm._v("协商记录")]
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "card",
                    },
                    [
                      _c(
                        "el-timeline",
                        {
                          staticClass: "my-timeline",
                        },
                        _vm._l(
                          _vm.order_data.operation_log_list,
                          function (logInfo, index) {
                            var _logInfo$after_sale_i,
                              _logInfo$after_sale_i2,
                              _logInfo$after_sale_i3,
                              _logInfo$after_sale_i4,
                              _logInfo$after_sale_i5,
                              _logInfo$after_sale_i6,
                              _logInfo$return_goods,
                              _logInfo$return_goods2,
                              _logInfo$return_goods3,
                              _logInfo$return_goods4,
                              _logInfo$express_img_
                            return _c(
                              "el-timeline-item",
                              {
                                key: index,
                                attrs: {
                                  size: "large",
                                  timestamp: logInfo.time,
                                  icon: _vm._f("roleToClass")(
                                    logInfo.operator_role
                                  ),
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-card",
                                  [
                                    _c("h4", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.AFTER_SALE_OPERATION_VALUE_DIC[
                                            logInfo.operation
                                          ]
                                        )
                                      ),
                                    ]),
                                    logInfo.express_company
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "物流名称：",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  logInfo.express_company
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    logInfo.logistics_order_no
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "物流编号：",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  logInfo.logistics_order_no
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    logInfo.additional_description &&
                                    logInfo.role === "商家"
                                      ? _c(
                                          "div",
                                          [
                                            logInfo.role === "买家"
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "退款说明：",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          logInfo.additional_description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    !!(
                                      logInfo !== null &&
                                      logInfo !== void 0 &&
                                      (_logInfo$after_sale_i =
                                        logInfo.after_sale_info) !== null &&
                                      _logInfo$after_sale_i !== void 0 &&
                                      _logInfo$after_sale_i.after_sale_type
                                    )
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "售后方式：",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.AFTER_SALE_TYPE_VALUE_DIC[
                                                    logInfo.after_sale_info
                                                      .after_sale_type
                                                  ]
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !!(
                                      logInfo !== null &&
                                      logInfo !== void 0 &&
                                      logInfo.operation_reason
                                    )
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "拒绝原因：",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  logInfo.operation_reason
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !!(
                                      logInfo !== null &&
                                      logInfo !== void 0 &&
                                      (_logInfo$after_sale_i2 =
                                        logInfo.after_sale_info) !== null &&
                                      _logInfo$after_sale_i2 !== void 0 &&
                                      _logInfo$after_sale_i2.after_sale_reason
                                    )
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "退款原因：",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  logInfo.after_sale_info
                                                    .after_sale_reason
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !!(
                                      logInfo !== null &&
                                      logInfo !== void 0 &&
                                      (_logInfo$after_sale_i3 =
                                        logInfo.after_sale_info) !== null &&
                                      _logInfo$after_sale_i3 !== void 0 &&
                                      _logInfo$after_sale_i3.refund_amount
                                    )
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "退款金额：",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.formatUnit(
                                                    logInfo.after_sale_info
                                                      .refund_amount,
                                                    "MONEY"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !!(
                                      logInfo !== null &&
                                      logInfo !== void 0 &&
                                      (_logInfo$after_sale_i4 =
                                        logInfo.after_sale_info) !== null &&
                                      _logInfo$after_sale_i4 !== void 0 &&
                                      _logInfo$after_sale_i4.refund_points
                                    )
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "退款积分：",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.formatUnit(
                                                    logInfo.after_sale_info
                                                      .refund_points,
                                                    "POINTS"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !!(
                                      logInfo !== null &&
                                      logInfo !== void 0 &&
                                      (_logInfo$after_sale_i5 =
                                        logInfo.after_sale_info) !== null &&
                                      _logInfo$after_sale_i5 !== void 0 &&
                                      _logInfo$after_sale_i5.number_of_return_goods_sku
                                    )
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "退货数量：",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  logInfo.after_sale_info
                                                    .number_of_return_goods_sku
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !!(
                                      logInfo !== null &&
                                      logInfo !== void 0 &&
                                      (_logInfo$after_sale_i6 =
                                        logInfo.after_sale_info) !== null &&
                                      _logInfo$after_sale_i6 !== void 0 &&
                                      _logInfo$after_sale_i6.contactor_mobile_number
                                    )
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "联系方式：",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  logInfo.after_sale_info
                                                    .contactor_mobile_number
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !!(
                                      logInfo !== null &&
                                      logInfo !== void 0 &&
                                      logInfo.return_goods_logistics_info
                                    )
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "物流名称：",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  logInfo
                                                    .return_goods_logistics_info
                                                    .logistics_company_name ||
                                                    "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !!(
                                      logInfo !== null &&
                                      logInfo !== void 0 &&
                                      logInfo.return_goods_logistics_info
                                    )
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "物流编号：",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  logInfo
                                                    .return_goods_logistics_info
                                                    .tracking_number || "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !!(
                                      logInfo !== null &&
                                      logInfo !== void 0 &&
                                      (_logInfo$return_goods =
                                        logInfo.return_goods_logistics_info) !==
                                        null &&
                                      _logInfo$return_goods !== void 0 &&
                                      _logInfo$return_goods.additional_description
                                    )
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "退款说明：",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  logInfo
                                                    .return_goods_logistics_info
                                                    .additional_description ||
                                                    "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !!(
                                      logInfo !== null &&
                                      logInfo !== void 0 &&
                                      (_logInfo$return_goods2 =
                                        logInfo.return_goods_logistics_info) !==
                                        null &&
                                      _logInfo$return_goods2 !== void 0 &&
                                      _logInfo$return_goods2.contactor_mobile_number
                                    )
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "联系电话：",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  logInfo
                                                    .return_goods_logistics_info
                                                    .contactor_mobile_number ||
                                                    "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !!(
                                      logInfo !== null &&
                                      logInfo !== void 0 &&
                                      (_logInfo$return_goods3 =
                                        logInfo.return_goods_logistics_info) !==
                                        null &&
                                      _logInfo$return_goods3 !== void 0 &&
                                      (_logInfo$return_goods4 =
                                        _logInfo$return_goods3.evidence_image_list) !==
                                        null &&
                                      _logInfo$return_goods4 !== void 0 &&
                                      _logInfo$return_goods4.length
                                    )
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "申请凭证：",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "flex-margin-top",
                                              },
                                              _vm._l(
                                                logInfo
                                                  .return_goods_logistics_info
                                                  .evidence_image_list,
                                                function (jitem, jindex) {
                                                  return _c("el-image", {
                                                    key: jindex,
                                                    staticClass: "imgSty",
                                                    attrs: {
                                                      src: jitem,
                                                      "preview-src-list":
                                                        logInfo
                                                          .return_goods_logistics_info
                                                          .evidence_image_list,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !!(
                                      logInfo !== null &&
                                      logInfo !== void 0 &&
                                      (_logInfo$express_img_ =
                                        logInfo.express_img_urls) !== null &&
                                      _logInfo$express_img_ !== void 0 &&
                                      _logInfo$express_img_.length
                                    )
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "买家上传凭证：",
                                            },
                                          },
                                          [
                                            logInfo.role === "买家"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "flex-margin-top",
                                                  },
                                                  _vm._l(
                                                    logInfo.express_img_urls,
                                                    function (jitem, jindex) {
                                                      return _c("el-image", {
                                                        key: jindex,
                                                        staticClass: "imgSty",
                                                        attrs: {
                                                          src: jitem,
                                                          "preview-src-list":
                                                            logInfo.express_img_urls,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          [
            _vm.AFTER_SALE_TICKET_STATUS_VALUE_MAP["REQUESTED"],
            _vm.AFTER_SALE_TICKET_STATUS_VALUE_MAP["RETURNING_GOODS"],
          ].includes(_vm.order_data.status)
            ? _c(
                "div",
                {
                  staticStyle: {
                    "text-align": "center",
                    padding: "80px",
                  },
                },
                [
                  _vm.order_data.status ===
                  _vm.AFTER_SALE_TICKET_STATUS_VALUE_MAP["RETURNING_GOODS"]
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              on: {
                                click: _vm.showRefuseDialog,
                              },
                            },
                            [_vm._v(" 拒绝收货 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                              },
                              on: {
                                click: _vm.showAgreeDialog,
                              },
                            },
                            [_vm._v(" 同意收货 ")]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "el-button",
                            {
                              on: {
                                click: _vm.showRefuseDialog,
                              },
                            },
                            [
                              _vm._v(
                                " 拒绝" +
                                  _vm._s(
                                    _vm.AFTER_SALE_TYPE_VALUE_DIC[
                                      _vm.order_data.after_sale_type
                                    ]
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                              },
                              on: {
                                click: _vm.showAgreeDialog,
                              },
                            },
                            [
                              _vm._v(
                                " 同意" +
                                  _vm._s(
                                    _vm.AFTER_SALE_TYPE_VALUE_DIC[
                                      _vm.order_data.after_sale_type
                                    ]
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "售后维权处理",
            visible: _vm.dialogRefuseVisible,
            width: "600px",
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.dialogRefuseVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "card",
              attrs: {
                "label-width": "100px",
              },
            },
            [
              _c("el-alert", {
                attrs: {
                  title:
                    "建议你与买家协商后，再确定是否拒绝退款。如你拒绝退款后，买家可修改退款申请协议重新发起退款。也可以直接发起维权申请，将会由客服介入处理。",
                  type: "warning",
                  "show-icon": "",
                  closable: false,
                },
              }),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "售后方式：",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.AFTER_SALE_TYPE_VALUE_DIC[
                          _vm.order_data.after_sale_type
                        ]
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "售后状态：",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.AFTER_SALE_TICKET_STATUS_VALUE_DIC[
                          _vm.order_data.status
                        ]
                      ) +
                      " "
                  ),
                ]
              ),
              _vm.order_data.refund_amount
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "退款金额：",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#f56c6c",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.formatUnit(
                                _vm.order_data.refund_amount,
                                "MONEY"
                              )
                            )
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.order_data.refund_points
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "退款积分：",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#f56c6c",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.formatUnit(
                                _vm.order_data.refund_points,
                                "POINTS"
                              )
                            )
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "拒绝理由：",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请填写你的拒绝理由，不超过200个字",
                      maxlength: "200",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.processAfterSaleForm.operation_reason,
                      callback: function callback($$v) {
                        _vm.$set(
                          _vm.processAfterSaleForm,
                          "operation_reason",
                          $$v
                        )
                      },
                      expression: "processAfterSaleForm.operation_reason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "text",
                  },
                  on: {
                    click: function click($event) {
                      _vm.dialogRefuseVisible = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
              _vm.order_data.status ===
              _vm.AFTER_SALE_TICKET_STATUS_VALUE_MAP["REQUESTED"]
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.isBtnLoading,
                      },
                      on: {
                        click: function click($event) {
                          return _vm.handleProcessAfterSaleRequest(false)
                        },
                      },
                    },
                    [_vm._v(" 拒绝 ")]
                  )
                : [
                    [
                      _vm.AFTER_SALE_TICKET_STATUS_VALUE_MAP["RETURNING_GOODS"],
                    ].includes(_vm.order_data.status)
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              loading: _vm.isBtnLoading,
                            },
                            on: {
                              click: _vm.handleRefuseReturnGoods,
                            },
                          },
                          [_vm._v("拒绝退货退款")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              loading: _vm.isBtnLoading,
                            },
                            on: {
                              click: function click($event) {
                                return _vm.handleReceivedBtn("refuse")
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("rejectBtnStr")(
                                    _vm.order_data.refund_type_str
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                  ],
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "售后维权处理",
            visible: _vm.dialogAgreeVisible,
            width: "600px",
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.dialogAgreeVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "card",
              attrs: {
                "label-width": "100px",
              },
            },
            [
              _c("el-alert", {
                attrs: {
                  title:
                    "该笔订单通过微信支付付款，确认退款后，退款将自动原路退回买家付款账户。",
                  type: "warning",
                  "show-icon": "",
                  closable: false,
                },
              }),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "售后方式：",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.AFTER_SALE_TYPE_VALUE_DIC[
                          _vm.order_data.after_sale_type
                        ]
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "售后状态：",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.AFTER_SALE_TICKET_STATUS_VALUE_DIC[
                          _vm.order_data.status
                        ]
                      ) +
                      " "
                  ),
                ]
              ),
              _vm.order_data.refund_amount
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "退款金额：",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#f56c6c",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.formatUnit(
                                _vm.order_data.refund_amount,
                                "MONEY"
                              )
                            )
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.order_data.refund_points
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "退款积分：",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#f56c6c",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.formatUnit(
                                _vm.order_data.refund_points,
                                "POINTS"
                              )
                            )
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.AFTER_SALE_TYPE_VALUE_MAP["REFUND_AND_RETURN_GOODS"] ===
              _vm.order_data.after_sale_type
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "退货地址：",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.returnGoodsAddress) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "text",
                  },
                  on: {
                    click: function click($event) {
                      _vm.dialogAgreeVisible = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
              _vm.order_data.status ===
              _vm.AFTER_SALE_TICKET_STATUS_VALUE_MAP["REQUESTED"]
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.isBtnLoading,
                      },
                      on: {
                        click: function click($event) {
                          return _vm.handleProcessAfterSaleRequest(true)
                        },
                      },
                    },
                    [_vm._v(" 同意 ")]
                  )
                : [
                    [
                      _vm.AFTER_SALE_TICKET_STATUS_VALUE_MAP["RETURNING_GOODS"],
                    ].includes(_vm.order_data.status)
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              loading: _vm.isBtnLoading,
                            },
                            on: {
                              click: _vm.handleApproveReturnGoods,
                            },
                          },
                          [_vm._v(" 同意收货 ")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              loading: _vm.isBtnLoading,
                            },
                            on: {
                              click: function click($event) {
                                return _vm.handleReceivedBtn("confirm")
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("agreeBtnStr")(
                                    _vm.order_data.refund_type_str
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }