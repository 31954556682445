var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm._visible,
        top: "4%",
        width: "60%",
      },
      on: {
        "update:visible": function updateVisible($event) {
          _vm._visible = $event
        },
        open: _vm.handleOpen,
        close: _vm.handleClose,
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function fn() {
            return [
              _c(
                "section",
                {
                  staticClass: "flex items-center",
                },
                [
                  _c("span", [_vm._v("选择/修改商品")]),
                  _c(
                    "div",
                    {
                      staticClass: "title__condition",
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "200px",
                          },
                          model: {
                            value: _vm.searchParams.categoryName,
                            callback: function callback($$v) {
                              _vm.$set(_vm.searchParams, "categoryName", $$v)
                            },
                            expression: "searchParams.categoryName",
                          },
                        },
                        _vm._l(_vm.categoryList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              value: item.name,
                              label: item.name,
                            },
                          })
                        }),
                        1
                      ),
                      _c("el-input", {
                        staticClass: "ml-[10px]",
                        staticStyle: {
                          width: "200px",
                        },
                        attrs: {
                          placeholder: "搜索商品名称",
                        },
                        model: {
                          value: _vm.searchParams.goodsName,
                          callback: function callback($$v) {
                            _vm.$set(_vm.searchParams, "goodsName", $$v)
                          },
                          expression: "searchParams.goodsName",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function fn() {
            return [
              _c(
                "div",
                {
                  staticClass: "flex justify-center",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "medium",
                      },
                      on: {
                        click: _vm.handleCancel,
                      },
                    },
                    [_vm._v(" 取消 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "medium",
                        type: "primary",
                      },
                      on: {
                        click: _vm.handleConfirm,
                      },
                    },
                    [_vm._v(" 修改 ")]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-table",
        {
          ref: "goodTable",
          attrs: {
            "row-key": "id",
            data: _vm.visibleGoodList,
            border: "",
            "max-height": _vm.maxHeight,
          },
          on: {
            "selection-change": _vm.handleSelectionChange,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              "reserve-selection": "",
              type: "selection",
              "header-align": "center",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "商品名称",
              prop: "name",
              "header-align": "center",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "flex items-center",
                      },
                      [
                        _c("img", {
                          staticStyle: {
                            width: "100px",
                            height: "100px",
                          },
                          attrs: {
                            src: scope.row.image_list[0],
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "margin-left": "10px",
                            },
                          },
                          [_vm._v(_vm._s(scope.row.name))]
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "商品分组",
              formatter: _vm.formatCategoryName,
              "header-align": "center",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "售价（元）",
              formatter: _vm.formatPrice,
              "header-align": "center",
              align: "center",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "mt-[10px]",
        },
        [
          _vm._v(" 已选商品"),
          _c(
            "span",
            {
              staticStyle: {
                color: "red",
              },
            },
            [_vm._v(" " + _vm._s(_vm.multipleSelection.length))]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }