<template>
  <div class="page-padding">
    <div class="page-header--wrap">
      <div class="page-header-wrap--title">商品效果</div>
      <div />
    </div>

    <el-row class="page-filter--wrap">
      <div class="page-color--normal">日期:</div>
      <el-date-picker
        v-model="date_time_range"
        value-format="yyyy-MM-dd"
        type="daterange"
        :clearable="false"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="handleDateChange($event, 'consumption')"
      />
      <div class="page-color--normal">
        <el-button-group>
          <el-button @click="getNdayRange(1)"> 今天 </el-button>
          <el-button @click="getNdayRange(2)"> 昨天 </el-button>
          <el-button @click="getNdayRange(7)"> 近7天 </el-button>
          <el-button @click="getNdayRange(30)"> 近30天 </el-button>
        </el-button-group>
      </div>
    </el-row>
    <el-row class="page-filter--wrap">
      <div class="page-color--normal">商品名称:</div>
      <el-input v-model="searchForm.goodsName" placeholder="请输入商品名称" class="page-select--item" style="width: 305px" />
      <div class="page-color--normal">商品状态:</div>
      <el-select v-model="searchForm.goodsStates" placeholder="请选择" class="page-select--item">
        <el-option label="全部" value="" />
        <el-option label="销售中" value="ONSALE" />
        <el-option label="已售罄" value="SOLDOUT" />
        <el-option label="补货中" value="REPLENISHING" />
        <el-option label="待上架" value="WATTING" />
        <el-option label="仓库中" value="WAREHOUSE" />
      </el-select>
      <el-button round icon="el-icon-refresh" @click="resetSearchForm"> 重置 </el-button>
      <el-button type="primary" round icon="el-icon-search" @click="getList()"> 搜索 </el-button>
      <el-button type="primary" round size="mini" icon="el-icon-refresh" :loading="refreshLoading" @click="handleRefresh()"> 刷新 </el-button>
      <el-popconfirm
        title="是否按当前搜索条件导出文件？"
        confirm-button-text="确认"
        cancel-button-text="取消"
        icon="el-icon-question"
        icon-color="#409EFF"
        @onConfirm="exportFile"
      >
        <el-button slot="reference" type="success" round icon="el-icon-download" :loading="downloading" style="margin-left: 10px">
          导出文件
        </el-button>
      </el-popconfirm>
      <!-- <el-button round icon="el-icon-refresh" @click="refreshGoodsData" style="margin-left: 10px">刷新数据</el-button> -->
    </el-row>

    <el-table v-loading="listLoading" :data="reportData" stripe border highlight-current-row style="width: 100%">
      <el-table-column type="index" label="序号" width="60" align="center" />
      <el-table-column align="center" prop="spu_code" label="商品编码" show-overflow-tooltip />
      <el-table-column align="center" prop="goods_name" label="商品名称" show-overflow-tooltip />
      <el-table-column align="center" prop="sale_price_range" label="商品价格" show-overflow-tooltip />
      <el-table-column align="center" prop="exposure_num" label="曝光次数" show-overflow-tooltip />
      <el-table-column align="center" prop="exposure_people" label="曝光人数" show-overflow-tooltip />
      <el-table-column align="center" prop="customer_num" label="访客数" show-overflow-tooltip />
      <el-table-column align="center" prop="page_views" label="浏览量" show-overflow-tooltip />
      <el-table-column align="center" prop="extra_purchase_quantity" label="加购件数" show-overflow-tooltip />
      <el-table-column align="center" prop="extra_purchase_people" label="加购人数" show-overflow-tooltip />
      <el-table-column align="center" prop="paid_people" label="付款人数" show-overflow-tooltip />
      <el-table-column align="center" prop="paid_goods_quantity" label="付款商品件数" show-overflow-tooltip />
      <el-table-column align="center" prop="paid_goods_price" label="商品付款金额" show-overflow-tooltip />
      <el-table-column align="center" prop="apply_refund_order_num" label="申请退款订单数" show-overflow-tooltip />
      <el-table-column align="center" prop="apply_refund_people" label="申请退款人数" show-overflow-tooltip />
      <el-table-column align="center" prop="refund_success_order_num" label="成功退款订单数" show-overflow-tooltip />
      <el-table-column align="center" prop="refund_success_people" label="成功退款人数" show-overflow-tooltip />
      <el-table-column align="center" prop="refund_success_price" label="成功退款金额" show-overflow-tooltip />
      <el-table-column align="center" prop="refund_ratio" label="退款率" show-overflow-tooltip />
      <el-table-column align="center" prop="transfer_ratio" label="单品转化率" show-overflow-tooltip />
    </el-table>

    <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
  </div>
</template>

<script>
import TableListMixin from "@/mixin/TableList";
import Pagination from "@/components/Pagination";
import { commonUtil, downloadBlobFile } from "@/utils";
import api from "@/api";
import dayjs from "dayjs";

const { tryCatch } = commonUtil;
const today = dayjs().format("YYYY-MM-DD");
export default {
  name: "GoodsEffect",
  components: {
    Pagination,
  },
  mixins: [TableListMixin],
  data() {
    return {
      refreshLoading: false,
      downloading: false,
      date_time_range: [today, today],
      listLoading: false,
      reportData: [],
      searchForm: {
        startTime: today,
        endTime: today,
        goodsStates: "",
        goodsName: "",
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 重置搜索表单
    resetSearchForm() {
      this.searchForm = {
        startTime: today,
        endTime: today,
        goodsStates: "",
        goodsName: "",
      };
      this.date_time_range = [today, today];
      this.getList();
    },

    async getList() {
      const [, res] = await this.getListMixin(api.malls.productEffectReport);
      this.reportData = res?.list ?? [];
    },
    async handleRefresh() {
      console.log("a");
      this.refreshLoading = true;
      const startTime = dayjs().subtract(10, "day").format("YYYY-MM-DD");
      const endTime = "2099-01-01";
      const [err] = await api.malls.refreshGoods({
        params: {
          startTime,
          endTime,
        },
      });
      if (!err) this.$message.success("刷新成功");
      this.refreshLoading = false;
    },
    //查询N天的列表
    getNdayRange(fromDays) {
      let startDay = dayjs()
        .subtract(fromDays - 1, "day")
        .format("YYYY-MM-DD");
      let endDay = today;
      if (fromDays === 2) endDay = startDay;

      this.searchForm.startTime = startDay;
      this.searchForm.endTime = endDay;
      this.date_time_range = [startDay, endDay];

      this.getList();
    },
    // 根据查询条件导出Excel文件
    async exportFile() {
      this.downloading = true;
      const startTime = this.searchForm.startTime;
      const endTime = this.searchForm.endTime;
      const filename =
        startTime === endTime ? `商品效果${endTime.split("-").join("")}` : `商品效果${startTime.split("-").join("")}_${endTime.split("-").join("")}`;

      // 后端协商最多下载50000条数据
      const [err, blobData] = await api.malls.downloadProductEffectReport({
        params: {
          ...this.searchForm,
          page: 1,
          size: 50000,
        },
        responseType: "blob",
      });
      this.downloading = false;
      if (err) return;
      await tryCatch(downloadBlobFile, { obj: blobData, name: filename, suffix: "xlsx" });
    },
    handleDateChange(val, val2) {
      if (val2 === "consumption") {
        this.searchForm.startTime = val[0];
        this.searchForm.endTime = val[1];
      }
    },
  },
};
</script>

<style></style>
