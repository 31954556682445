<template>
  <div class="page-padding">
    <div>
      <div class="page-header--wrap">
        <div class="page-header-wrap--title">订单管理</div>
        <div>
          <el-button
            v-if="searchForm.status === ORDER_STATUS_VALUE_MAP['TO_BE_DELIVERED'] && !isGroupMall"
            :disabled="!multipleSelection.length"
            type="primary"
            @click="handleBatchExpress"
          >
            推送至企得宝
          </el-button>
        </div>
      </div>

      <el-row class="page-filter--wrap">
        <div class="page-color--normal">订单搜索</div>
        <el-input v-model="orderTypeInput" placeholder="请输入内容" style="width: 350px" clearable @input="handleTypeInputChange">
          <el-select slot="prepend" v-model="orderType" placeholder="请选择" style="width: 120px" @change="handleTypeChange">
            <el-option label="订单编号" value="order_number" />
            <el-option label="收货人姓名" value="receiver_name" />
            <!-- <el-option label="买家手机号" value="buyer_mobile_number"> </el-option> -->
            <el-option label="收货人手机号" value="receiver_mobile_number" />
          </el-select>
        </el-input>
        <div class="page-color--normal">下单时间</div>
        <el-date-picker
          v-model="order_time"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
          unlink-panels
          @change="handleDateChange"
        />
        <div class="page-color--normal">物流单号</div>
        <el-input v-model="searchForm.tracking_number" clearable placeholder="请输入物流单号" class="page-select--item" style="width: 150px" />
      </el-row>
      <el-row class="page-filter--wrap">
        <div class="page-color--normal">发货时间</div>
        <el-date-picker
          v-model="delivery_time"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
          unlink-panels
          @change="handleDeliveryDateChange"
        />
        <div class="page-color--normal">付款时间</div>
        <el-date-picker
          v-model="payment_time"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
          unlink-panels
          @change="handlePaymentDateChange"
        />
        <div class="page-color--normal">买家手机</div>
        <el-input v-model="searchForm.buyer_mobile_number" clearable placeholder="请输入买家手机" class="page-select--item" style="width: 150px" />
      </el-row>
      <el-row class="page-filter--wrap">
        <div class="page-color--normal">退款时间</div>
        <el-date-picker
          v-model="tran_time"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
          unlink-panels
          @change="handleTranDateChange"
        />
        <div class="page-color--normal">商品名称</div>
        <el-input v-model="searchForm.goods_name" clearable placeholder="请输入商品名称" style="width: 350px" />
        <div class="page-color--normal">卖家备注</div>
        <el-input v-model="searchForm.merchant_remark" clearable placeholder="请输入卖家备注" class="page-select--item" style="width: 150px" />
      </el-row>
      <el-row class="page-filter--wrap">
        <template v-if="isGroupMall">
          <div class="page-color--normal">部门名称</div>
          <el-input v-model="searchForm.dept_name" clearable placeholder="请输入部门名称" class="page-select--item" style="width: 350px" />
        </template>
        <div class="page-color--normal">自提码</div>
        <el-input v-model="searchForm.pick_up_code" clearable placeholder="请输入自提码" class="page-select--item" style="width: 150px" />
      </el-row>
      <el-row class="page-filter--wrap" style="margin: 20px 0">
        <el-button round icon="el-icon-refresh" @click="resetSearchForm"> 重置 </el-button>
        <el-button type="primary" round icon="el-icon-search" @click="handleSearch"> 搜索 </el-button>
        <el-popconfirm title="是否按当前搜索条件导出文件？" @onConfirm="downloadExcel('downloadOrderExcel', '订单文件')">
          <el-button slot="reference" type="success" round icon="el-icon-download" :loading="downloadOrderExcelLoading" class="ml-[10px]">
            导出订单文件
          </el-button>
        </el-popconfirm>
        <el-popconfirm title="是否按当前搜索条件导出文件？" @onConfirm="downloadExcel('downloadOrderLineExcel', '商品文件')">
          <el-button slot="reference" type="success" round icon="el-icon-download" :loading="downloadOrderLineExcelLoading" class="ml-[10px]">
            导出商品文件
          </el-button>
        </el-popconfirm>
        <el-popover placement="bottom" width="100" trigger="click">
          <div v-for="item in formatButtonByStatus(searchForm.status)" :key="item" class="batch-btn" @click="handleBatch(item)">{{ item }}</div>
          <el-button slot="reference" :disabled="!formatButtonByStatus(searchForm.status)" round type="primary" class="ml-[10px]">
            批量操作
          </el-button>
          <!-- <el-button round type="primary" class="ml-[10px]" @click="dialogBatchDispatchVisible = true"> 批量发货 </el-button> -->
        </el-popover>
      </el-row>

      <el-row>
        <div class="flex" style="margin-bottom: 15px">
          <div class="navGroup">
            <div>公共状态</div>
            <el-radio-group
              v-model="searchForm.status"
              size="small"
              :disabled="listLoading"
              @change="
                getList();
                handleChangeReceiverLabel('收货人/自提点联系人');
              "
            >
              <el-radio-button :label="undefined"> 全部 </el-radio-button>
              <el-radio-button v-for="item in getFragmentStatus(ORDER_STATUS, 0, 4)" :key="item.value" :label="item.value">
                {{ item.label }}
              </el-radio-button>
            </el-radio-group>
          </div>
          <div class="navGroup">
            <div>物流状态</div>
            <el-radio-group
              v-model="searchForm.status"
              size="small"
              :disabled="listLoading"
              @change="
                getList();
                handleChangeReceiverLabel('收货人');
              "
            >
              <el-radio-button v-for="item in getFragmentStatus(ORDER_STATUS, 4, 7)" :key="item.value" :label="item.value">
                {{ item.label }}
              </el-radio-button>
            </el-radio-group>
          </div>
          <div class="navGroup">
            <div>自提状态</div>
            <el-radio-group
              v-model="searchForm.status"
              size="small"
              :disabled="listLoading"
              @change="
                getList();
                handleChangeReceiverLabel('自提点联系人');
              "
            >
              <el-radio-button v-for="item in getFragmentStatus(ORDER_STATUS, 7)" :key="item.value" :label="item.value">
                {{ item.label }}
              </el-radio-button>
            </el-radio-group>
          </div>
        </div>
      </el-row>

      <el-table
        v-loading="listLoading"
        :data="storeData"
        stripe
        border
        style="width: 100%"
        row-key="id"
        :row-class-name="getRowClass"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center" class-name="selection-class" />
        <el-table-column label="商品信息" align="" width="300">
          <template #default="{ row }">
            <div class="mesWrap">
              <div v-for="(item, index) in row.order_line_info_list" :key="index" class="mesSty">
                <img class="imgSty" :src="item.goods_sku_image_address" />
                <div class="mesFont">
                  <el-tooltip
                    effect="dark"
                    :content="item.goods_name"
                    placement="top"
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
                  >
                    <p>{{ item.goods_name }}</p>
                  </el-tooltip>
                  <p>
                    {{ item.goods_sku_specification }}
                    <el-tag v-if="AFTER_SALE_STATUS_VALUE_DIC[row.after_sale_status]" type="danger" effect="plain">
                      {{ AFTER_SALE_STATUS_VALUE_DIC[row.after_sale_status] }}
                    </el-tag>
                    <!--                    <el-tag v-if="item.express_remark" type="danger" effect="plain">{{ item.express_remark }}</el-tag>-->
                  </p>
                </div>
              </div>
            </div>
            <div class="item" style="display: flex">
              <div style="display: flex; width: 75vw">
                <span style="margin-left: 1%"
                  >订单编号：
                  <el-button type="text" @click="handleOrderDetail(row)">{{ row.order_number }}</el-button>
                  <el-tooltip content="复制单号" placement="top">
                    <el-button type="text" @click="handleCopy(row.order_number, $event)"> <i class="el-icon-document-copy" /></el-button>
                  </el-tooltip>
                </span>
                <span class="dispatch-order-time">下单时间：{{ row.place_time }} </span>
                <div v-show="row.buyer_remark" style="color: #f23645; margin-right: 5%">买家备注：{{ row.buyer_remark }}</div>
                <div v-show="row.merchant_remark" style="color: #eba011">卖家备注：{{ row.merchant_remark }}</div>
              </div>
            </div>
            <!-- <div v-if="row.buyer_remark || row.merchant_remark" class="item" style="top: auto; padding-left: 1%;">
              <div v-show="row.buyer_remark" style="color: #f23645;">买家备注：{{ row.buyer_remark }}</div>
              <div v-show="row.merchant_remark" style="color: #eba011;">卖家备注：{{ row.merchant_remark }}</div>
            </div> -->
          </template>
        </el-table-column>
        <el-table-column align="center" label="单价/数量" width="120">
          <template #default="{ row }">
            <div v-for="(item, index) in row.order_line_info_list" :key="index" class="my-table--column">
              <div v-if="row.pay_type === PAY_TYPE_VALUE_MAP['MONEY']">￥{{ item.goods_sku_unit_price }}</div>
              <div v-if="row.pay_type === PAY_TYPE_VALUE_MAP['POINTS']">{{ item.goods_sku_unit_price }} 积分</div>
              <div>{{ item.shopping_num }} 件</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="售后">
          <template #default="{ row }">
            <div v-for="(item, index) in row.order_line_info_list" :key="index" class="my-table--column">
              <el-button type="text" :disabled="!item.after_sale_ticket_number" @click="handleAfterSaleDetail(item)">
                {{ AFTER_SALE_STATUS_VALUE_DIC[item.after_sale_status] }}
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="买家">
          <template #default="{ row }">
            <div>
              <div>{{ row.buyer_name }}</div>
              <div>{{ row.buyer_mobile_number }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="receiverLabel" width="140">
          <template #default="{ row }">
            <div>
              <div>{{ row.shipping_info.receiver_name }}</div>
              <div>{{ row.shipping_info.receiver_mobile_number }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="ncc_code" :formatter="(_, __, value) => value || '-'" align="center" label="NC编码" />
        <el-table-column prop="buyer_membership_card_number" :formatter="(_, __, value) => value || '-'" align="center" label="会员卡号" />
        <el-table-column prop="paid_money" align="center" label="实付金额">
          <template #default="{ row }">
            <div>
              <div v-if="row.pay_type === PAY_TYPE_VALUE_MAP['MONEY']">￥{{ row.total_price }}</div>
              <div v-if="row.pay_type === PAY_TYPE_VALUE_MAP['POINTS']">{{ row.total_points }} 积分</div>
              <div v-if="row.pay_type === PAY_TYPE_VALUE_MAP['MIX_MONEY_POINTS']">
                {{ formatPrice({ price: row.total_price, point: row.total_points }) }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" align="center" :formatter="(_, __, value) => ORDER_STATUS_VALUE_DIC[value]" label="订单状态" />
        <el-table-column v-if="isGroupMall" prop="dept_name" align="center" label="部门" />
        <el-table-column prop="pick_up_code" align="center" label="自提码" />
        <!-- <el-table-column align="center" prop="delivery_status" label="发货状态"></el-table-column> -->
        <el-table-column fixed="right" label="操作" align="center" width="250">
          <template #default="{ row }">
            <el-popconfirm
              v-if="row.deliver_method === DELIVER_METHOD_VALUE_MAP['CUSTOMER_PICK_UP']"
              :title="`当前自提码为${row.pick_up_code}，确认核销吗？`"
              style="margin: 0 10px"
              placement="left"
              @onConfirm="handlePickedUp(row)"
            >
              <el-button slot="reference" :disabled="row.status !== ORDER_STATUS_VALUE_MAP['TO_BE_PICKED_UP']" type="text"> 自提核销 </el-button>
            </el-popconfirm>
            <el-button :disabled="row.status !== ORDER_STATUS_VALUE_MAP['TO_BE_DELIVERED']" type="text" @click="handleDispatchOrder(row)">
              发货
            </el-button>
            <el-button type="text" @click="handleOrderDetail(row)"> 详情 </el-button>
            <el-button type="text" @click="handleAddNote(row)"> 备注 </el-button>
            <el-popconfirm
              v-if="row.status === ORDER_STATUS_VALUE_MAP['REQUESTED_DELIVER']"
              title="注意：撤销操作后，需要在企得宝订单审核前，将此订单作废。"
              style="margin-left: 10px"
              placement="left"
              @onConfirm="handleRevoke(row)"
            >
              <el-button slot="reference" type="text"> 撤销 </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

      <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    </div>

    <!-- 添加备注的弹窗 -->
    <el-dialog title="卖家备注" :visible.sync="dialogAddNote" width="40%">
      <el-input v-model="note_form.remark" type="textarea" placeholder="最多可输入256个字" maxlength="256" show-word-limit />
      <div slot="footer">
        <el-button type="text" @click="dialogAddNote = false"> 取消 </el-button>
        <el-button type="primary" @click="handleAddNoteConfirm"> 确定 </el-button>
      </div>
    </el-dialog>

    <!-- 发货的弹窗 -->
    <dispatch-order :visible.sync="dialogDispatchOrderVisible" :detail-data="currentOrderData" @success="handleDeliverSuccess" />
    <BatchDispatchDialog :visible.sync="dialogBatchDispatchVisible"></BatchDispatchDialog>
  </div>
</template>

<script>
import api from "@/api";
import DispatchOrder from "./components/DispatchOrder";
import Pagination from "@/components/Pagination";
import TableListMixin from "@/mixin/TableList";
import { downloadBlobFile, commonUtil, transformUtil } from "@/utils";
import clip from "@/utils/clipboard";
import { GOODS } from "@/constants";
import * as R from "ramda";
import { mapGetters } from "vuex";
import BatchDispatchDialog from "./components/BatchDispatchDialog.vue";

const { ORDER_STATUS_VALUE_DIC, ORDER_STATUS_VALUE_MAP, DELIVER_METHOD_VALUE_MAP, PAY_TYPE_VALUE_MAP, AFTER_SALE_STATUS_VALUE_DIC, ORDER_STATUS } =
  GOODS;
const { to, tryCatch } = commonUtil;
const { omitEmptyValue } = transformUtil;
export default {
  name: "OrderManage",
  components: { DispatchOrder, Pagination, BatchDispatchDialog },
  mixins: [TableListMixin],
  data() {
    return {
      ORDER_STATUS_VALUE_DIC,
      ORDER_STATUS_VALUE_MAP,
      DELIVER_METHOD_VALUE_MAP,
      PAY_TYPE_VALUE_MAP,
      AFTER_SALE_STATUS_VALUE_DIC,
      ORDER_STATUS,
      downloadOrderExcelLoading: false,
      downloadOrderLineExcelLoading: false,
      multipleSelection: [],
      popSty: false,
      textarea: "",
      dialogDispatchOrderVisible: false,
      dialogBatchDispatchVisible: false,
      dialogAddNote: false,
      note_form: {
        orderId: "",
        remark: "",
      },
      order_time: [],
      delivery_time: [],
      payment_time: [],
      tran_time: [],
      orderType: "order_number",
      orderTypeInput: "",
      storeCode: "",
      storeData: [],
      currentOrderData: null,

      searchForm: {
        status: undefined,
        goods_name: "",
        place_date_start: "", // 下单开始时间
        place_date_end: "", // 下单结束时间
      },
      receiverLabel: "收货人/自提点联系人",
    };
  },
  computed: {
    ...mapGetters(["auth_info", "isGroupMall"]),
  },
  created() {
    this.getList();
  },
  methods: {
    handleChangeReceiverLabel(val) {
      this.receiverLabel = val;
    },
    formatButtonByStatus(status) {
      switch (status) {
        case ORDER_STATUS_VALUE_MAP["WAITING_FOR_SHOPPING"]:
          return ["批量备货", "批量可自提", "批量核销"];
        case ORDER_STATUS_VALUE_MAP["SHOPPING"]:
          return ["批量可自提", "批量核销"];
        case ORDER_STATUS_VALUE_MAP["TO_BE_PICKED_UP"]:
          return ["批量核销"];
        case ORDER_STATUS_VALUE_MAP["TO_BE_DELIVERED"]:
        case ORDER_STATUS_VALUE_MAP["REQUESTED_DELIVER"]:
          return ["批量发货"];
        case ORDER_STATUS_VALUE_MAP["DELIVERING"]:
          return ["批量发货", "批量完成"];
      }
    },
    handleBatch(val) {
      switch (val) {
        case "批量发货":
          this.dialogBatchDispatchVisible = true;
          break;
        case "批量备货":
          this.batchUpdateStatus(ORDER_STATUS_VALUE_MAP["SHOPPING"]);
          break;
        case "批量可自提":
          this.batchUpdateStatus(ORDER_STATUS_VALUE_MAP["TO_BE_PICKED_UP"]);
          break;
        case "批量核销":
          this.batchUpdateStatus("RECEIVED");
          break;
        case "批量完成":
          this.handleBatchForwardOrderLineDeliveryStatus();
          break;
      }
    },
    async batchUpdateStatus(to_status) {
      if (this.multipleSelection.length === 0) {
        this.$message.warning("请先选择订单");
        return;
      }
      const data = {
        from_status: this.searchForm.status,
        to_status,
        order_id_set: this.multipleSelection.map((item) => item.order_id),
      };

      const [err] = await api.order.batchUpdateStatus({
        method: "post",
        data,
      });
      if (err) return this.$message.error(err.message || "操作失败");
      this.$message.success("操作成功");
      this.getList();
    },
    getFragmentStatus(arr, fromIndex, toIndex) {
      return arr.slice(fromIndex, toIndex);
    },
    handleCopy(v, e) {
      clip(v, e);
    },
    async handlePickedUp({ order_id } = {}) {
      const [err] = await api.order.orders({
        method: "post",
        id: order_id,
        resource: "confirmBuyerPickUp",
      });
      if (err) return;
      this.$message.success("操作成功");
      to(this.getList());
    },
    async handleRevoke(row) {
      const [err] = await api.order.orders({
        method: "post",
        id: row.order_id,
        resource: "undoRequestDelivery",
      });
      if (!err) {
        this.$message.success("撤销成功");
        to(this.getList());
      }
    },
    async downloadExcel(apiName, fileName) {
      this[apiName + "Loading"] = true;
      const [err, blobData] = await api.order[apiName]({
        method: "post",
        responseType: "blob",
        data: R.omit(["page", "size"], omitEmptyValue(this.searchForm)),
      });
      if (!err) await tryCatch(downloadBlobFile, { obj: blobData, name: fileName, suffix: "xlsx" });
      this[apiName + "Loading"] = false;
    },
    handleTypeInputChange(val) {
      this.searchForm[this.orderType] = val;
    },
    handleTypeChange(val) {
      this.searchForm = {};
      this.orderTypeInput = "";
      this.searchForm[val] = "";
    },
    handleDateChange(val) {
      this.searchForm.place_date_start = val ? val[0] : "";
      this.searchForm.place_date_end = val ? val[1] : "";
    },
    handleDeliveryDateChange(val) {
      this.searchForm.deliver_date_start = val ? val[0] : "";
      this.searchForm.deliver_date_end = val ? val[1] : "";
    },
    handlePaymentDateChange(val) {
      this.searchForm.pay_date_start = val ? val[0] : "";
      this.searchForm.pay_date_end = val ? val[1] : "";
    },
    handleTranDateChange(val) {
      this.searchForm.refund_date_start = val ? val[0] : "";
      this.searchForm.refund_date_end = val ? val[1] : "";
    },
    resetSearchForm() {
      this.order_time = [];
      this.delivery_time = [];
      this.payment_time = [];
      this.tran_time = [];
      this.orderTypeInput = "";
      this.searchForm = {
        status: "",
        goods_name: "",
        place_date_start: "",
        place_date_end: "",
        deliver_date_start: "",
        deliver_date_end: "",
        pay_date_start: "",
        pay_date_end: "",
      };
      this.getList();
    },
    async handleBatchExpress() {
      const order_line_id_set = this.multipleSelection.reduce((pre, cur) => {
        const { order_line_info_list } = cur;
        pre = pre.concat(order_line_info_list.map((item) => item.id));
        return pre;
      }, []);

      const [err] = await api.order.pushOrderLinesToQidebao({
        method: "post",
        data: {
          order_line_id_set,
        },
      });
      if (!err) {
        this.$message.success("推送成功");
        to(this.getList());
      }
    },
    async handleBatchForwardOrderLineDeliveryStatus() {
      const orderIdSet = this.multipleSelection.map((item) => item.order_id);
      console.log(orderIdSet, "orderIdSet");
      if (orderIdSet.length === 0) {
        this.$message.warning("请先选择订单");
        return;
      }
      const [err] = await api.order.batchForwardOrderLineDeliveryStatus({
        method: "post",
        data: orderIdSet,
      });
      if (!err) {
        this.$message.success("操作成功");
        to(this.getList());
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleOrderRadioChange(v) {
      this.listQuery.page = 1;
      if (this.searchForm.status !== "") {
        this.searchForm.status = v;
      } else {
        this.searchForm.status = "";
      }
      this.getList();
    },
    // eslint-disable-next-line no-unused-vars
    getRowClass({ row }) {
      if (row.order_line_info_list.length > 1) {
        return "has-border--bottom";
      } else if (row.buyer_remark != "" && row.merchant_remark != "") {
        return "double-height";
      } else if (row.buyer_remark != "" || row.merchant_remark != "") {
        // return "increase-height";
      } else {
        return "";
      }
    },
    iconBj() {
      this.popSty = true;
    },
    popClear() {
      this.popSty = false;
      this.textarea = "";
    },
    popEnd() {
      this.popSty = false;
    },
    handleSearch() {
      this.listQuery.page = 1;
      this.getList();
    },
    async getList() {
      const [, res] = await this.getListMixin(api.order.orders);
      this.storeData = res?.list ?? [];
    },
    handleDispatchOrder(row) {
      this.currentOrderData = row;
      this.dialogDispatchOrderVisible = true;
    },
    handleAddNote(row) {
      this.note_form.orderId = row.order_id;
      this.note_form.remark = row.merchant_remark;
      this.dialogAddNote = true;
    },
    async handleAddNoteConfirm() {
      const [err] = await api.order.orders({
        method: "put",
        id: this.note_form.orderId,
        resource: "merchantRemark",
        data: JSON.stringify(this.note_form.remark),
      });

      if (err) return;

      this.dialogAddNote = false;
      this.$notify.success("更新成功");
      to(this.getList());
    },
    handleOrderDetail(row) {
      this.$router.push({ path: "/order/order_detail", query: { orderId: row.order_id, source: "order_mange" } });
    },
    handleAfterSaleDetail(row) {
      this.$router.push({ path: "/order/sales_detail", query: { ticketNumber: row.after_sale_ticket_number, source: "order_mange" } });
    },

    handleAddStore() {},

    handleDeliverSuccess() {
      this.getList();
      this.$notify.success("发货成功");
      this.dialogDispatchOrderVisible = false;
    },
    formatPrice({ price, point }) {
      let str = "";
      if (price) str += `￥${price}`;
      if (price && point) str += "+";
      if (point) str += `${point}积分`;
      return str;
    },
  },
};
</script>

<style lang="scss">
/* .increase-height {
  height: 180px;
  height: 280px;
} */
/* .double-height {
  height: 200px !important;
} */
.navGroup {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  &:not(:last-child) {
    margin-right: 16px;
  }
  :first-child {
    margin-bottom: 4px;
  }
}
.has-border--bottom .mesSty {
  border-bottom: 1px solid #ebeef5;
}
.has-border--bottom .my-table--column {
  margin-bottom: 0px;
  border-bottom: 1px solid #ebeef5;
  height: 81px;
  /* line-height: 81px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.has-border--bottom .mesWrap .mesSty:last-child,
.my-table--column:last-child {
  border: none;
  margin-bottom: 0px;
}
.selection-class ::v-deep .cell {
  padding-left: 0px !important;
}
</style>
<style scoped>
.el-table--enable-row-transition ::v-deep .el-table__body td {
  padding-top: 40px;
  /* padding-bottom: 10vh; */
}
::v-deep .el-table tr {
  position: relative;
  width: 100%;
}
::v-deep .cell {
  /* padding-top: 3%;
  padding-bottom: 3%; */
  padding-left: 0;
  padding-right: 0;
}
.item {
  width: 90vw;
  background: #eee;
  padding: 1% 0;
  position: absolute;
  top: 0;
  left: -55px;
  z-index: 10;
  justify-content: space-between;
}
</style>
<style scoped>
.imgSty {
  width: 80px;
  display: block;
}
.mesSty {
  display: flex;
  align-items: center;
  /* padding: 5px; */
}

.dispatch-order-time {
  margin-left: 1%;
  margin-right: 5%;
  min-width: 180px;
  display: inline-block;
  line-height: 28px;
}
.pb_sty span {
  background: #1dc8de;
  padding: 2px 8px;
  border-radius: 4px;
  color: #fff;
}
.mesFont {
  padding: 0 5px;
  overflow: hidden;
}
.mesFont p {
  margin-block-start: 0;
  margin-block-end: 0;
}
.mesFont p:nth-child(2) {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.iconSty {
  float: right;
  cursor: pointer;
}
.batch-btn {
  &:hover {
    cursor: pointer;
    background: #f5f7fa;
  }
  & + .batch-btn {
    margin-top: 10px;
  }
}
</style>
