import { render, staticRenderFns } from "./DispatchOrder.vue?vue&type=template&id=0de21cb7&scoped=true"
import script from "./DispatchOrder.vue?vue&type=script&lang=js"
export * from "./DispatchOrder.vue?vue&type=script&lang=js"
import style0 from "./DispatchOrder.vue?vue&type=style&index=0&id=0de21cb7&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0de21cb7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/Lmiy6ZAB/1/ifirst/imall/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0de21cb7')) {
      api.createRecord('0de21cb7', component.options)
    } else {
      api.reload('0de21cb7', component.options)
    }
    module.hot.accept("./DispatchOrder.vue?vue&type=template&id=0de21cb7&scoped=true", function () {
      api.rerender('0de21cb7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/mall-manage/schedule-order/components/DispatchOrder.vue"
export default component.exports