<template>
  <el-dialog title="编辑" width="480px" :visible.sync="computedVisible" v-bind="$attrs" @open="handleOpen">
    <el-form label-width="70px">
      <el-form-item v-for="item in i18nSetting" :key="item.language_type" :label="item.cn_name">
        <el-input v-model="item.language_translation" :disabled="disabledLanguages.includes(item.language_type)"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :loading="loading" @click="handleClose">取 消</el-button>
      <el-button :loading="loading" type="primary" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import api from "@/api";
import { Message } from "element-ui";
import { computed, ref } from "vue";

export default {
  cn_name: "EditI18n",
  props: {
    visible: {
      type: Boolean,
    },
    translationId: {
      type: [Number, null],
    },
    disabledLanguages: {
      type: Array,
      default: () => ["zh-CN"],
    },
    defaultI18nValue: {
      type: Array,
      default: () => [
        // {
        //   cn_name: "简体中文",
        //   language_type: "zh-CN",
        //   language_translation: "123",
        // },
      ],
    },
  },
  setup(props, { emit }) {
    const computedVisible = computed({
      get: () => props.visible,
      set: (val) => emit("update:visible", val),
    });

    const i18nSetting = ref([]);
    function handleOpen() {
      i18nSetting.value = [...props.defaultI18nValue];
    }

    const loading = ref(false);
    async function handleConfirm() {
      loading.value = true;
      const translations = i18nSetting.value.map((item) => {
        return {
          language_type: item.language_type,
          language_translation: item.language_translation,
        };
      });

      const data = {
        id: props.translationId,
        translations,
      };

      const [err] = await api.multiLanguage.updateGoodTranslation({
        method: "post",
        data,
      });
      loading.value = false;
      if (err) return;
      emit("confirm");
      Message.success("操作成功");
      handleClose();
    }

    function handleClose() {
      if (loading.value) return;
      computedVisible.value = false;
    }

    return { computedVisible, props, i18nSetting, handleConfirm, handleClose, handleOpen, loading };
  },
};
</script>

<style lang="scss" scoped></style>
