/* Layout */
import Layout from "@/layout";
import Vue from "vue";
import Router from "vue-router";
import accountRouter from "./modules/account";
import goodsRouter from "./modules/goods";
import mallIMRouter from "./modules/mall_im";
import orderRouter from "./modules/orders";
import reportRouter from "./modules/report";
import reservationRouter from "./modules/reservation";
import settingsRouter from "./modules/settings";
import shopRouter from "./modules/shop";
import shopperRouter from "./modules/shopper";
import activityRouter from "./modules/activity";

Vue.use(Router);

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: "/redirect",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/redirect/:path(.*)",
        component: () => import("@/components/Redirect/index"),
      },
    ],
  },
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true,
  },
  {
    path: "/auto-login",
    component: () => import("@/views/Loading"),
    hidden: true,
  },
  {
    path: "/404",
    component: () => import("@/views/404"),
    hidden: true,
  },

  {
    path: "/",
    component: Layout,
    redirect: "/welcome",
    children: [
      {
        path: "welcome",
        name: "Welcome",
        component: () => import("@/views/welcome/index.vue"),
        meta: { title: "首页", icon: "home" },
      },
    ],
  },
  // {
  //   path: "/im",
  //   component: () => import("@/views/mall-im/index"),
  //   meta: { title: "客服消息" },
  //   hidden: true,
  // },

  // businessRouter,
  // reportRouter,
  // vipRouter,
  // invoiceRouter,
  // takeawayRouter,
  // systemRouter,
  // {
  //   path: "/dishes",
  //   component: Layout,
  //   redirect: "/dishes",
  //   children: [
  //     {
  //       path: "dishes",
  //       name: "Dishes",
  //       component: () => import("@/views/dishes/index"),
  //       meta: { title: "菜品管理", icon: "el-icon-dish" },
  //     },
  //   ],
  // },

  // // 404 page must be placed at the end !!!
  // { path: "*", redirect: "/404", hidden: true },
];

export const asyncRoutes = [
  goodsRouter,
  orderRouter,
  reportRouter,
  settingsRouter,
  shopperRouter,
  accountRouter,
  shopRouter,
  mallIMRouter,
  reservationRouter,
  activityRouter,

  { path: "*", redirect: "/404", hidden: true },
];

const createRouter = () =>
  new Router({
    mode: "history", // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
