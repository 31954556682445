var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: {
            "margin-top": "30px",
          },
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            "label-width": "90px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "收货人",
                prop: "receiver",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.receiver,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "receiver", $$v)
                  },
                  expression: "form.receiver",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "联系电话",
                prop: "phone",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.phone,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "phone", $$v)
                  },
                  expression: "form.phone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "联系地址",
                prop: "cities",
              },
            },
            [
              _c("el-cascader", {
                staticStyle: {
                  width: "100%",
                },
                attrs: {
                  options: _vm.optionsCity,
                },
                on: {
                  change: _vm.handleCityChange,
                },
                model: {
                  value: _vm.selected_city,
                  callback: function callback($$v) {
                    _vm.selected_city = $$v
                  },
                  expression: "selected_city",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "",
                prop: "receive_address",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请填写详细地址",
                },
                model: {
                  value: _vm.form.receive_address,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "receive_address", $$v)
                  },
                  expression: "form.receive_address",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "text",
              },
              on: {
                click: _vm.handleCancel,
              },
            },
            [_vm._v(" 取消 ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
              },
              on: {
                click: _vm.handleConfirm,
              },
            },
            [_vm._v(" 确定 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }