<template>
  <el-dialog :visible="visible" title="订单发货" top="5vh" @close="handleClose">
    <!-- <el-row :gutter="10">
      <el-col :span="3"> 选择商品</el-col>
      <el-col :span="2"> 待发货 1</el-col>
      <el-col :span="2"> 已发货 0</el-col>
    </el-row> -->
    <el-row :gutter="10" class="margin-item">
      <el-table
        :data="detailData && detailData.order_line_info_list"
        border
        stripe
        highlight-current-row
        style="width: 100%"
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column width="200" align="center" prop="name" label="商品名称">
          <template slot-scope="{ row }">
            <div class="flex-center" style="justify-content: start">
              <el-image style="max-width: 80px; height: 80px; margin-right: 10px" :src="row.goods_sku_image_address" fit="contain" />
              <el-tooltip effect="dark" :content="row.goods_name" placement="top" style="overflow: hidden; text-overflow: ellipsis">
                <el-button type="text" style="max-width: 210px">
                  {{ row.goods_name }}
                </el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" prop="price" label="价格"> </el-table-column> -->
        <el-table-column align="center" prop="shopping_num" label="数量" />
        <el-table-column align="center" prop="delivery_status" :formatter="(_, __, value) => ORDER_STATUS_VALUE_DIC[value]" label="发货状态">
          <!-- <template slot-scope="{ row }">
            <el-tag v-if="row.delivery_status == 0" type="success">已发货</el-tag>
            <el-tag v-if="row.delivery_status == 1" type="danger">待发货</el-tag>
          </template> -->
        </el-table-column>
        <el-table-column align="center" prop="logistics_order_no_list" label="运单号" />
      </el-table>
    </el-row>
    <el-row :gutter="10" class="margin-item">
      <el-col :span="3" style="font-weight: 500"> 配送信息 </el-col>
      <el-col :span="20">
        <!-- <div class="order-info-item">配送方式：快递</div> -->
        <div class="order-info-item">收货人：{{ detailData && detailData.shipping_info.receiver_name }}</div>
        <!-- <div class="order-info-item">联系电话：{{ detailData.tel_number }}</div> -->
        <div class="order-info-item">收货地址：{{ formatShippingAddress(detailData && detailData.shipping_info) }}</div>
      </el-col>
    </el-row>
    <el-row :gutter="10" class="margin-item">
      <el-col :span="3" style="font-weight: 500"> 发货方式 </el-col>
      <el-col :span="20">
        <el-radio-group v-model="delivery_method" @change="handleDeliveryChange">
          <el-radio :disabled="is_online_dispatch" label="online"> 在线下单 </el-radio>
          <el-radio label="manual"> 手动填写 </el-radio>
        </el-radio-group>
        <div v-if="need_delivery" style="margin-top: 20px">
          <div>
            物流公司：
            <el-select v-model="logistics_company" style="width: 200px">
              <el-option v-for="item in logisticsCompanies" :key="item.code" :label="item.name" :value="item.code" />
            </el-select>
          </div>
          <div style="margin: 10px 0">
            物流单号：
            <el-input v-model="tracking_number" style="width: 200px" />
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="10" type="flex" justify="center">
      <el-button :loading="loading" type="primary" @click="handleDelivery"> 发货 </el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import api from "@/api";
import * as R from "ramda";
import { commonUtil } from "@/utils";
import { GOODS } from "@/constants";

const { ORDER_STATUS_VALUE_DIC } = GOODS;
const { to } = commonUtil;

export default {
  props: ["detailData", "visible"],
  data() {
    return {
      loading: false,
      ORDER_STATUS_VALUE_DIC,
      multipleSelection: [],
      // btn_delivery: true,
      need_delivery: false,
      logistics_company: undefined,
      tracking_number: "",
      delivery_method: "online",
      logisticsCompanies: [],
    };
  },
  computed: {
    is_online_dispatch() {
      let { code } = this.$store.state.user.auth_info.current_mall;
      let is_song = code == "song" ? true : false;
      let is_miliang = code == "miliang" ? true : false;
      if (is_song || is_miliang) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.need_delivery = true;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.delivery_method = "manual";
      }
      if (is_song) return is_song;
      if (is_miliang) return is_miliang;
      return is_song;
    },
  },
  async mounted() {
    const [err, logisticsCompanies] = await api.logistics.logisticsCompanies();
    this.logisticsCompanies = logisticsCompanies;
    if (err) return;
    this.logistics_company = logisticsCompanies[0]?.code;
  },

  methods: {
    handleClose() {
      this.$emit("update:visible", false);
    },
    handleSelectionChange(val) {
      // val.length != 0 ? (this.btn_delivery = false) : (this.btn_delivery = true);
      this.multipleSelection = val;
    },
    handleDeliveryChange(val) {
      this.need_delivery = val === "manual";
    },
    formatShippingAddress(shippingInfo) {
      if (!shippingInfo) return "";
      const {
        shipping_address_province = "",
        shipping_address_city = "",
        shipping_address_district = "",
        shipping_address_street_address = "",
      } = shippingInfo;
      return `${shipping_address_province}${shipping_address_city}${shipping_address_district}${shipping_address_street_address}`;
    },
    handleDelivery() {
      if (!this.multipleSelection.length) return this.$notify.warning("请选择商品");
      this.deliveryRequest();
    },
    async deliveryRequest() {
      this.loading = true;
      let { delivery_method, logistics_company, tracking_number, multipleSelection } = this;
      const order_line_id_set = R.map(R.prop("id"), multipleSelection);
      let err;
      if (delivery_method === "online") {
        [err] = await to(this.pushOrderLinesToQidebao(order_line_id_set));
      } else {
        [err] = await to(this.setOrderLinesDeliverySheetInfo({ logistics_company, tracking_number, order_line_id_set }));
      }
      if (!err) {
        this.$emit("update:visible", false);
        this.$emit("success");
        this.tracking_number = "";
      }
      this.loading = false;
    },
    async pushOrderLinesToQidebao(order_line_id_set) {
      const [err] = await api.order.pushOrderLinesToQidebao({
        method: "post",
        data: {
          order_line_id_set,
        },
      });
      if (err) throw new Error(err);
    },
    async setOrderLinesDeliverySheetInfo(data) {
      const [err] = await api.order.setOrderLinesDeliverySheetInfo({
        method: "post",
        data,
      });
      if (err) throw new Error(err);
    },
  },
};
</script>

<style scoped>
.order-info-item {
  line-height: 22px;
}
.margin-item {
  margin: 20px 0;
}
</style>
