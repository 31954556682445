<template>
  <div class="upload-wrap">
    <el-upload
      :class="{ 'hide-upload-holder': hideUpload }"
      class="upload-container-ref"
      :action="upload_qiniu_area"
      :auto-upload="true"
      :limit="limitCount"
      :multiple="true"
      :accept="accept"
      :file-list="fileList"
      list-type="picture-card"
      :on-preview="picCardPreview"
      :before-upload="beforePicUpload"
      :on-exceed="handleExceed"
      :on-remove="removePic"
      :http-request="uploadQiniu"
    >
      <!-- <i class="el-icon-plus"></i> -->
      <div slot="default" class="el-icon-plus" style="color: #409eff">添加图片</div>
      <template slot="file" slot-scope="{ file }">
        <img v-if="file.status !== 'uploading'" class="upload-image" :src="file.url" alt="" />
        <label class="el-upload-list__item-status-label">
          <i
            :class="{
              'el-icon-upload-success': true,
              'el-icon-check': true,
            }"
          />
        </label>
        <el-progress v-if="file.status === 'uploading'" type="circle" :stroke-width="6" :percentage="parseInt(file.percentage, 10)" />
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview" @click="picCardPreview(file)">
            <i class="el-icon-zoom-in" />
          </span>

          <span class="el-upload-list__item-delete" @click="handleSlotRemove(file)">
            <i class="el-icon-delete" />
          </span>
        </span>
        <template v-if="showAllButtons()">
          <el-button type="text" @click="handleSetCoverImage(file)"> 设置为封面图 </el-button>
        </template>
        <template v-else>
          <el-button :disabled="isButtonDisabled(file)" type="text" @click="handleSetCoverImage()">
            {{ isButtonDisabled(file) ? "设置为封面图" : "取消设置为封面图" }}
          </el-button>
        </template>
      </template>
    </el-upload>

    <el-dialog :visible.sync="dialogVisible" :modal="false">
      <img width="100%" :src="dialogImageUrl" />
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
const upload_qiniu_address = "https://cdn.jmj1995.com/"; //七牛云返回储存图片的子域名（外链域名）

const fileTypeRegex = /image\/(png|jpg|jpeg|gif)/;
export default {
  name: "UploadPicture",
  props: {
    limitCount: {
      type: Number,
      default: 1,
      required: true,
    },
    alreadyFile: {
      type: [String, Array],
      default: "",
    },
    token: {
      type: String,
      default: "",
    },
    accept: {
      type: String,
      default: "image/jpg,image/png,image/jpeg,image/gif",
    },
    coverImageIndex: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {
      fileList: [],
      hideUpload: false,
      dialogImageUrl: "",
      dialogVisible: false,
      upload_qiniu_area: "https://upload-z2.qiniup.com", //七牛云上传储存区域的上传域名
      progressFlag: false,
    };
  },
  computed: {
    component_coverImageIndex: {
      get() {
        return this.coverImageIndex;
      },
      set(newValue) {
        this.$emit("update:coverImageIndex", newValue);
      },
    },
  },

  watch: {
    alreadyFile(val) {
      if (this.limitCount == 1) {
        this.hideUpload = true;
      }
      if (typeof val == "string" && val != "") {
        this.fileList = [{ url: val }];
      }
      if (Array.isArray(val) && val.length != 0) {
        this.initImage(val);
      }
      if (val == "") {
        this.hideUpload = false;
        this.fileList = [];
      }
    },
  },
  created() {
    let val = this.alreadyFile;
    if (typeof val == "string" && val != "" && this.limitCount == 1) {
      this.fileList = [{ url: val }];
      this.hideUpload = true;
    }
  },

  methods: {
    initImage(val) {
      let arrSet = new Set(val);
      let tempVal = [];
      for (let k of arrSet) {
        tempVal.push(k);
      }

      let tempArr = [];
      tempVal.forEach((item) => {
        let tempObj = { url: "" };
        tempObj.url = item;
        tempArr.push(tempObj);
      });
      this.fileList = tempArr;
    },
    picCardPreview(file) {
      //上传图预览
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforePicUpload(file) {
      //图片校验
      const limitPic = fileTypeRegex.test(file.type);
      if (!limitPic) {
        this.$notify.warning({
          title: "提示",
          message: "请上传格式为image/png,image/jpg,image/jpeg的图片",
        });
      }
      const limitSize = file.size / 1024 / 1024 < 2;
      if (!limitSize) {
        this.$notify.warning({
          title: "警告",
          message: "图片大小必须小于2M",
        });
      }
      return limitPic && limitSize;
    },
    handleSlotRemove(file) {
      const index = this.getImageIndex(file);
      const image = this.getImageFileByIndex(this.component_coverImageIndex);
      this.fileList.splice(index, 1);
      this.handleSetCoverImage(image);

      this.$emit("uploadSuccess", this.fileList);
    },
    getImageFileByIndex(index) {
      if (!Number.isFinite(index)) return null;
      return this.fileList[index];
    },
    getImageIndex(file) {
      return this.fileList.findIndex((item) => item === file);
    },
    removePic(file, fileList) {
      //移除图片
      this.hideUpload = fileList.length >= this.limitCount;
      this.fileList = fileList;

      this.$emit("uploadSuccess", this.fileList);
    },
    handleExceed() {
      // this.hideUpload = this.fileList.length >= this.limitCount;
      //文件超出个数限制
      this.$notify.warning({
        title: "提示",
        message: "一次只能上传 " + this.limitCount + " 张图片",
        duration: 2000,
      });
    },
    uploadQiniu(request) {
      this.hideUpload = true;
      //上传七牛
      this.handleUpload(request)
        .then((result) => {
          if (!result.data.key) {
            this.$notify.error({
              message: "图片上传失败,请重新上传",
              duration: 2000,
            });
          } else {
            this.fileList.push({ url: upload_qiniu_address + result.data.key });
            this.$emit("uploadSuccess", this.fileList);
            this.hideUpload = this.fileList.length >= this.limitCount;
          }
        })
        .catch((err) => {
          this.$notify.error({
            message: `图片上传失败${err}`,
            duration: 2000,
          });
        });
    },
    showAllButtons() {
      return !Number.isFinite(this.component_coverImageIndex);
    },
    handleSetCoverImage(file) {
      const _index = this.getImageIndex(file);
      const index = _index === -1 ? null : _index;
      this.component_coverImageIndex = index;
      // this.$emit("update:coverImageIndex", index);
    },
    isButtonDisabled(file) {
      return this.component_coverImageIndex !== this.getImageIndex(file);
    },
    handleUpload(request) {
      const promise = new Promise((resolve, reject) => {
        const config = {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            let num = ((progressEvent.loaded / progressEvent.total) * 100) | 0; //百分比
            request.onProgress({ percent: Number(num) }); //进度条
          },
        };
        let fileType = "";
        if (fileTypeRegex.test(request.file.type)) {
          fileType = `.${request.file.type.split("/")[1]}`;
        }

        const key = `front_mall_${new Date().getTime()}${Math.floor(Math.random() * 100)}${fileType}`; //自定义图片名

        const fd = new FormData();
        fd.append("file", request.file);
        // fd.append("fileBinaryData", request.file);
        fd.append("token", this.token);
        fd.append("key", key);
        axios
          .post(this.upload_qiniu_area, fd, config)
          .then((res) => {
            if (res.status == 200 && res.data) {
              resolve({
                data: {
                  key,
                },
              });
            } else {
              reject(res);
            }
          })
          .catch((err) => {
            this.$notify.error({
              message: `上传失败[${err.status}]`,
              duration: 2000,
            });
          });
      });
      return promise;
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-wrap >>> .hide-upload-holder .el-upload--picture-card {
  display: none;
}
.upload-wrap ::v-deep .el-upload-list__item {
  transition: none !important;
}

.upload-container-ref ::v-deep .el-upload-list--picture-card .el-upload-list__item {
  height: 180px;
  border: unset;
  text-align: center;
  .upload-image {
    width: 148px;
    height: 148px;
    border-radius: 6px;
    border: 1px solid #c0ccda;
  }
  .el-upload-list__item-actions {
    width: 148px;
    height: 148px;
    border-radius: 6px;
  }
}
</style>
