// https://juejin.cn/post/6974902702400602148
export const MemoryCache = {
  data: {},
  set(key, value, maxAge) {
    this.data[key] = {
      maxAge: maxAge || 0,
      value,
      now: Date.now(),
    };
  },
  get(key) {
    const cachedItem = this.data[key];
    if (!cachedItem) return null;
    const isExpired = Date.now() - cachedItem.now > cachedItem.maxAge;
    isExpired && this.delete(key);
    return isExpired ? null : cachedItem.value;
  },
  delete(key) {
    return delete this.data[key];
  },
  clear() {
    this.data = {};
  },
};

function generateReqKey(config) {
  const { method, url, params, data } = config;
  return [method, url, new URLSearchParams(params).toString(), new URLSearchParams(data).toString()].join("&");
}

function isCacheLike(cache) {
  return !!(
    typeof cache?.get === "function" &&
    typeof cache?.set === "function" &&
    typeof cache?.delete === "function" &&
    typeof cache?.clear === "function"
  );
}

export function cacheAdapterEnhancer(adapter, options) {
  const { maxAge, enabledByDefault = false, cacheFlag = "cache", defaultCache = MemoryCache } = options;
  return (config) => {
    const { method, forceUpdate } = config;
    let useCache = config[cacheFlag] ?? enabledByDefault;
    if (method === "get" && useCache) {
      const cache = isCacheLike(useCache) ? useCache : defaultCache;
      let requestKey = generateReqKey(config);
      let responsePromise = cache.get(requestKey);
      if (!responsePromise || forceUpdate) {
        responsePromise = (async () => {
          try {
            return await adapter(config);
          } catch (reason) {
            cache.delete(requestKey);
            throw reason;
          }
        })();
        cache.set(requestKey, responsePromise, maxAge);
        return responsePromise;
      }
      return responsePromise;
    }

    return adapter(config);
  };
}
