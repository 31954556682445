<template>
  <div class="page-padding">
    <div>
      <div class="page-header--wrap">
        <div class="page-header-wrap--title">定时发货订单</div>
        <div>
          <el-button v-if="order_filter == '待发货'" :disabled="multipleSelection.length == 0" type="primary" @click="handleBatchExpress">
            推送至企得宝
          </el-button>
        </div>
      </div>

      <el-row class="page-filter--wrap">
        <div class="page-color--normal">订单搜索</div>
        <el-input v-model="orderTypeInput" placeholder="请输入内容" style="width: 350px" clearable @input="handleTypeInputChange">
          <el-select slot="prepend" v-model="orderType" placeholder="请选择" disabled style="width: 120px" @change="handleTypeChange">
            <el-option label="订单编号" value="order_number" />
            <!-- <el-option label="收货人姓名" value="receiver_name"> </el-option> -->
            <!-- <el-option label="买家手机号" value="buyer_mobile_number"> </el-option> -->
            <!-- <el-option label="收货人手机号" value="receiver_phone"> </el-option> -->
          </el-select>
        </el-input>
        <div class="page-color--normal">下单时间</div>
        <el-date-picker
          v-model="order_time"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
          unlink-panels
          @change="handleDateChange"
        />
      </el-row>
      <el-row class="page-filter--wrap">
        <div class="page-color--normal">发货时间</div>
        <el-date-picker
          v-model="delivery_time"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
          unlink-panels
          @change="handleDeliveryDateChange"
        />
        <div class="page-color--normal">付款时间</div>
        <el-date-picker
          v-model="payment_time"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
          unlink-panels
          @change="handlePaymentDateChange"
        />
        <div class="page-color--normal">买家手机</div>
        <el-input v-model="searchForm.buyer_mobile_number" clearable placeholder="请输入买家手机" class="page-select--item" style="width: 150px" />
      </el-row>
      <el-row class="page-filter--wrap">
        <div class="page-color--normal">收支时间</div>
        <el-date-picker
          v-model="tran_time"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
          unlink-panels
          @change="handleTranDateChange"
        />
        <div class="page-color--normal">商品名称</div>
        <el-input v-model="searchForm.goods_name" clearable placeholder="请输入商品名称" style="width: 350px" />
        <div class="page-color--normal">激活状态</div>
        <el-select v-model="searchForm.activated" placeholder="全部" clearable style="width: 150px">
          <el-option label="未激活" value="NOACTIVATE" />
          <el-option label="已激活" value="ACTIVATE" />
          <el-option label="已完成" value="FINISHED" />
        </el-select>
      </el-row>
      <el-row class="page-filter--wrap" style="margin: 20px 0">
        <el-button round icon="el-icon-refresh" @click="resetSearchForm"> 重置 </el-button>
        <el-button type="primary" round icon="el-icon-search" @click="getList"> 搜索 </el-button>
        <el-popconfirm title="是否按当前搜索条件导出文件？" @onConfirm="exportFile('order')">
          <el-button slot="reference" type="success" round icon="el-icon-download" :loading="downloadingOrder" style="margin-left: 10px">
            导出订单文件
          </el-button>
        </el-popconfirm>
        <!-- <el-popconfirm title="是否按当前搜索条件导出文件？" @onConfirm="exportFile('product')">
          <el-button slot="reference" type="success" round icon="el-icon-download" :loading="downloadingGoods" style="margin-left: 10px"
            >导出商品文件</el-button
          >
        </el-popconfirm> -->
      </el-row>

      <el-row>
        <div style="margin-bottom: 15px">
          <el-radio-group v-model="order_filter" size="small" @change="handleOrderRadioChange">
            <el-radio-button label="全部" />
            <el-radio-button label="TO_BE_PAID"> 待付款 </el-radio-button>
            <el-radio-button label="COMPLETED"> 已完成 </el-radio-button>
            <el-radio-button label="CLOSED"> 已关闭 </el-radio-button>
          </el-radio-group>
        </div>
      </el-row>

      <el-table
        v-loading="listLoading"
        :data="storeData"
        stripe
        border
        style="width: 100%"
        row-key="id"
        :row-class-name="getRowClass"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center" class-name="selection-class" />
        <el-table-column label="商品信息" align="" width="300">
          <template slot-scope="{ row }">
            <div class="mesWrap">
              <div v-for="(item, index) in row.order_line_info_list" :key="index" class="mesSty">
                <img class="imgSty" :src="item.goods_sku_image_address" />
                <div class="mesFont">
                  <el-tooltip
                    effect="dark"
                    :content="item.goods_name"
                    placement="top"
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
                  >
                    <p>{{ item.goods_name }}</p>
                  </el-tooltip>
                  <p>
                    {{ item.goods_sku_specification }}
                    <el-tag v-if="item.delivery_status == '已发货'" type="danger" effect="plain"> 已发货 </el-tag>
                    <el-tag v-if="item.express_remark" type="danger" effect="plain">
                      {{ item.express_remark }}
                    </el-tag>
                  </p>
                </div>
              </div>
            </div>
            <div class="item" style="display: flex">
              <div style="display: flex; width: 75vw">
                <span style="margin-left: 1%">
                  订单编号：
                  <el-button type="text" @click="handleOrderDetail(row)">{{ row.order_number }}</el-button>
                  <el-tooltip content="复制单号" placement="top">
                    <el-button type="text" @click="handleCopy(row.order_number, $event)"> <i class="el-icon-document-copy" /></el-button>
                  </el-tooltip>
                </span>
                <span class="dispatch-order-time">下单时间：{{ row.place_time }} </span>
                <div v-show="row.buyer_remark" style="color: #f23645; margin-right: 5%" class="remark-text">买家备注：{{ row.buyer_remark }}</div>
                <div v-show="row.merchant_remark" style="color: #eba011; margin-left: 5%" class="remark-text">
                  卖家备注：{{ row.merchant_remark }}
                </div>
                <div>
                  <el-button type="text" @click="handleDownloadCdk(row.order_number)"> 下载兑换码 </el-button>
                </div>
              </div>
              <div />
              <div />
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="单价/数量" width="120">
          <template slot-scope="{ row }">
            <div v-for="(item, index) in row.order_line_info_list" :key="index" class="my-table--column">
              <div v-if="row.pay_type !== 'CREDIT'">￥{{ item.goods_sku_unit_price }}</div>
              <div v-if="row.pay_type === 'CREDIT'">{{ item.paid_credit }} 积分</div>
              <div>{{ item.shopping_num }} 件</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="售后">
          <template slot-scope="{ row }">
            <div v-for="(item, index) in row.order_line_info_list" :key="index" class="my-table--column">
              <el-button type="text" :disabled="!item.after_sale_ticket_number" @click="handleAfterSaleDetail(item)">
                {{ item.after_sale_status }}
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="买家">
          <template slot-scope="{ row }">
            <div>
              <div>{{ row.buyer_name }}</div>
              <div>{{ row.buyer_mobile_number }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status_chinese_representation" align="center" label="订单状态" />
        <el-table-column prop="total_price" align="center" label="实付金额">
          <template slot-scope="{ row }">
            <div>
              <div v-if="row.pay_type !== 'CREDIT'">￥{{ row.total_price }}</div>
              <div v-if="row.pay_type === 'CREDIT'">{{ row.paid_credit }} 积分</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="activated" align="center" label="激活状态">
          <template slot-scope="{ row }">
            {{ row.regular_order_status | regularOrderText }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" align="center" width="250">
          <template slot-scope="{ row }">
            <el-button :disabled="row.status_chinese_representation != '待发货'" type="text" @click="handleDispatchOrder(row)"> 发货 </el-button>
            <el-button type="text" @click="handleOrderDetail(row)"> 详情 </el-button>
            <el-button type="text" @click="handleAddNote(row)"> 备注 </el-button>
            <el-popconfirm
              v-if="searchForm.status === '已申请发货'"
              title="注意：撤销操作后，需要在企得宝订单审核前，将此订单作废。"
              style="margin-left: 10px"
              placement="left"
              @onConfirm="handleRevoke(row)"
            >
              <el-button slot="reference" type="text"> 撤销 </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

      <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    </div>

    <!-- 添加备注的弹窗 -->
    <el-dialog title="卖家备注" :visible.sync="dialogAddNote" width="40%">
      <el-input v-model="note_form.remark" type="textarea" placeholder="最多可输入256个字" maxlength="256" show-word-limit />
      <div slot="footer">
        <el-button type="text" @click="dialogAddNote = false"> 取消 </el-button>
        <el-button type="primary" @click="handleAddNoteConfirm"> 确定 </el-button>
      </div>
    </el-dialog>

    <!-- 发货的弹窗 -->
    <el-dialog title="订单发货" :visible.sync="dialogDispatchOrder" top="5vh">
      <dispatch-order :goods-data="currentGoodsData" @handleDelivery="handleDelivery" />
    </el-dialog>
  </div>
</template>

<script>
import DispatchOrder from "./components/DispatchOrder";
import Pagination from "@/components/Pagination";
import TableListMixin from "@/mixin/TableList";
import { downloadBlobFile, filterEmptyProp, commonUtil } from "@/utils";
import api from "@/api";
import clip from "@/utils/clipboard";
import { regularOrderText } from "@/filters/regular_order";

export default {
  name: "OrderManage",
  components: { DispatchOrder, Pagination },
  filters: {
    statusToText(val) {
      let map = {
        0: "待付款",
        1: "待发货",
        2: "已发货",
        3: "已完成",
        4: "已关闭",
        5: "售后中",
      };
      return map[val];
    },
    regularOrderText,
  },
  mixins: [TableListMixin],
  data() {
    return {
      downloadingOrder: false,
      downloadingGoods: false,
      multipleSelection: [],
      popSty: false,
      textarea: "",
      dialogDispatchOrder: false,
      dialogAddNote: false,
      note_form: {
        id: "",
        order_number: "",
        remark: "",
      },
      order_time: [],
      delivery_time: [],
      payment_time: [],
      tran_time: [],
      order_filter: "全部",
      orderType: "order_number",
      orderTypeInput: "",
      storeCode: "",
      storeData: [],
      currentGoodsData: [],

      searchForm: {
        status: "",
        activated: "",
        goods_name: "",
        place_date_start: "", // 下单开始时间
        place_date_end: "", // 下单结束时间
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleCopy(v, e) {
      clip(v, e);
    },
    handlePickedUp(row) {
      let { order_number, pick_up_code } = row;
      this.axios.post(`/emall_order/pickedUp`, { order_number, pick_up_code }).then(() => {
        this.$message.success("操作成功");
        this.getList();
      });
    },
    handleRevoke(row) {
      this.axios.get(`emall_order/express/rollback/${row.order_number}`).then((res) => {
        this.$message.success(res.msg);
        this.getList();
      });
    },
    // 根据查询条件导出Excel文件
    async exportFile(type) {
      const option = {
        params: {
          ...filterEmptyProp(this.searchForm),
          page: this.listQuery.page,
          size: 9999,
        },
        filename: "",
        downloadFunc: null,
      };
      if (type == "order") {
        option.downloadFunc = api.download.regularOrder;
        option.filename = "订单文件";
        this.downloadingOrder = true;
      }
      const [err, blobData] = await option.downloadFunc({ params: option.params, responseType: "blob" });
      this.downloading = false;
      if (err) return;
      await commonUtil.tryCatch(downloadBlobFile, { obj: blobData, name: option.filename, suffix: "xlsx" });
      this.downloadingOrder = false;
      this.downloadingGoods = false;
    },
    async handleDownloadCdk(orderNo) {
      const [err, blobData] = await api.download.cdKey({
        id: orderNo,
        responseType: "blob",
      });
      if (err) return;
      await commonUtil.tryCatch(downloadBlobFile, { obj: blobData, name: "兑换码", suffix: "xlsx" });
    },
    handleTypeInputChange(val) {
      this.searchForm[this.orderType] = val;
    },
    handleTypeChange(val) {
      this.searchForm = {};
      this.orderTypeInput = "";
      this.searchForm[val] = "";
    },
    handleDateChange(val) {
      this.searchForm.place_date_start = val ? val[0] : "";
      this.searchForm.place_date_end = val ? val[1] : "";
    },
    handleDeliveryDateChange(val) {
      this.searchForm.deliver_date_start = val ? val[0] : "";
      this.searchForm.deliver_date_end = val ? val[1] : "";
    },
    handlePaymentDateChange(val) {
      this.searchForm.pay_date_start = val ? val[0] : "";
      this.searchForm.pay_date_end = val ? val[1] : "";
    },
    handleTranDateChange(val) {
      this.searchForm.tran_start_time = val ? val[0] : "";
      this.searchForm.tran_end_time = val ? val[1] : "";
    },
    resetSearchForm() {
      this.order_time = [];
      this.delivery_time = [];
      this.payment_time = [];
      this.tran_time = [];
      this.orderTypeInput = "";
      this.searchForm = {
        status: "",
        goods_name: "",
        place_date_start: "",
        place_date_end: "",
        deliver_date_start: "",
        deliver_date_end: "",
        pay_date_start: "",
        pay_date_end: "",
      };
      this.getList();
    },
    handleBatchExpress() {
      let arr = [];
      this.multipleSelection.forEach((item) => {
        let obj = { oms_product_id_list: [] };
        let products = [];
        item.products.forEach((jitem) => {
          products.push(jitem.id);
        });
        obj.oms_product_id_list = products;
        arr.push(obj);
      });
      // this.axios.post(`/emall_order/batchExpressOnline`, [{ oms_product_id_list: arr }]).then((res) => {
      this.axios.post(`/emall_order/batchExpressOnline`, arr).then((res) => {
        this.order_filter = "全部";
        this.searchForm.status = "";
        this.$message(res.msg);
        this.getList();
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleOrderRadioChange(v) {
      console.log(v);
      this.listQuery.page = 1;
      let { order_filter } = this;
      if (order_filter != "全部") {
        this.searchForm.status = v;
      } else {
        this.searchForm.status = "";
      }
      this.getList();
    },
    // eslint-disable-next-line no-unused-vars
    getRowClass({ row }) {
      if (row.order_line_info_list.length > 1) {
        return "has-border--bottom";
      } else if (row.buyer_remark != "" && row.merchant_remark != "") {
        return "double-height";
      } else if (row.buyer_remark != "" || row.merchant_remark != "") {
        return "increase-height";
      } else {
        return "";
      }
    },
    iconBj() {
      this.popSty = true;
    },
    popClear() {
      this.popSty = false;
      this.textarea = "";
    },
    popEnd() {
      this.popSty = false;
    },

    getList() {
      // 获取数据
      this.getListMixin(api.order.regular).then((res) => {
        [, { list: this.storeData }] = res;
      });
    },
    async handleAddNoteConfirm() {
      const { order_id, remark } = this.note_form;
      const [err] = await api.order.orders({
        method: "put",
        id: order_id,
        resource: "merchantRemark",
        data: JSON.stringify(remark),
      });
      if (!err) {
        this.$notify.success("添加成功");
        this.dialogAddNote = false;
        this.note_form = {
          order_id: "",
          order_number: "",
          remark: "",
        };
        this.getList();
      }
    },
    goBack() {},
    handleDispatchOrder(row) {
      console.log(row);
      this.currentGoodsData = row;
      this.dialogDispatchOrder = true;
    },
    handleAddNote(row) {
      this.note_form.order_id = row.order_id;
      this.note_form.order_number = row.order_number;
      this.dialogAddNote = true;
    },
    handleOrderDetail(row) {
      this.$router.push({ path: "/order/schedule_detail", query: { id: row.order_id, source: "order_mange" } });
    },
    handleAfterSaleDetail(row) {
      this.$router.push({ path: "/order/sales_detail", query: { id: row.after_sale_ticket_number, source: "order_mange" } });
    },
    handleDelivery(msg) {
      if (msg == 0) {
        this.getList();
        this.dialogDispatchOrder = false;
        this.$notify.success("操作成功");
      }
    },
  },
};
</script>

<style>
/* .increase-height {
  height: 180px;
  height: 280px;
} */
/* .double-height {
  height: 200px !important;
} */

.has-border--bottom .mesSty {
  border-bottom: 1px solid #ebeef5;
}
.has-border--bottom .my-table--column {
  margin-bottom: 0px;
  border-bottom: 1px solid #ebeef5;
  height: 81px;
  /* line-height: 81px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.has-border--bottom .mesWrap .mesSty:last-child,
.my-table--column:last-child {
  border: none;
  margin-bottom: 0px;
}
.selection-class ::v-deep .cell {
  padding-left: 0px !important;
}
</style>
<style scoped>
.el-table--enable-row-transition ::v-deep .el-table__body td {
  padding-top: 40px;
  /* padding-bottom: 10vh; */
}
::v-deep .el-table tr {
  position: relative;
  width: 100%;
}
::v-deep .cell {
  /* padding-top: 3%;
  padding-bottom: 3%; */
  padding-left: 0;
  padding-right: 0;
}
.item {
  width: 90vw;
  background: #eee;
  padding: 1% 0;
  position: absolute;
  top: 0;
  left: -55px;
  z-index: 10;
  justify-content: space-between;
}
</style>
<style scoped>
.imgSty {
  width: 80px;
  display: block;
}
.mesSty {
  display: flex;
  align-items: center;
  /* padding: 5px; */
}

.dispatch-order-time {
  margin-left: 1%;
  margin-right: 5%;
  min-width: 180px;
  display: inline-block;
  line-height: 28px;
}
.pb_sty span {
  background: #1dc8de;
  padding: 2px 8px;
  border-radius: 4px;
  color: #fff;
}
.mesFont {
  padding: 0 5px;
  overflow: hidden;
}
.mesFont p {
  margin-block-start: 0;
  margin-block-end: 0;
}
.mesFont p:nth-child(2) {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.iconSty {
  float: right;
  cursor: pointer;
}
.remark-text {
  line-height: 28px;
}
</style>
