import Layout from "@/layout";

const goodsRouter = {
  path: "/goods",
  component: Layout,
  redirect: "/goods/goods_list",
  name: "goods",
  meta: {
    title: "商品管理",
    icon: "goods",
    affix: true,
    roles: ["admin", "guest"],
  },
  children: [
    {
      path: "goods_list",
      name: "GoodsList",
      component: () => import("@/views/mall-manage/goods-manage/goods-list"),
      meta: { title: "商品列表" },
    },
    {
      path: "goods_group",
      name: "GoodsGroup",
      component: () => import("@/views/mall-manage/goods-manage/goods-group"),
      meta: { title: "商品分组" },
    },
    {
      path: "goods_category",
      name: "GoodsCategory",
      component: () => import("@/views/mall-manage/goods-manage/goods-category"),
      meta: { title: "商品分类" },
    },
    {
      path: "ad_banner",
      name: "AdBanner",
      component: () => import("@/views/mall-manage/goods-manage/ad-banner"),
      meta: { title: "广告位", roles: ["admin"] },
    },
    {
      path: "feedback",
      name: "GoodsFeedback",
      component: () => import("@/views/mall-manage/goods-feedback"),
      meta: { title: "留言反馈", roles: ["admin"] },
    },
    {
      path: "i18n",
      name: "TheI18n",
      component: () => import("@/views/mall-manage/i18n/TheI18n.vue"),
      meta: { title: "多语言管理" },
    },
  ],
};

export default goodsRouter;
