var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "PageMain",
    {
      staticClass: "h-full overflow-y-auto",
    },
    [
      _c("PageHeader", {
        attrs: {
          title: "多语言管理",
        },
        scopedSlots: _vm._u([
          {
            key: "right",
            fn: function fn() {
              return [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          loading: _vm.languageLoading,
                          type: "primary",
                        },
                        on: {
                          click: function click($event) {
                            _vm.settingVisible = true
                          },
                        },
                      },
                      [_vm._v("设置语言")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "success",
                        },
                        on: {
                          click: function click($event) {
                            _vm.publishVisible = true
                          },
                        },
                      },
                      [_vm._v("发布语言")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "FilterMain",
        {
          scopedSlots: _vm._u([
            {
              key: "buttons",
              fn: function fn() {
                return [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                      },
                      on: {
                        click: _vm.getAllGoods,
                      },
                    },
                    [_vm._v("搜索")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "FilterItem",
            {
              attrs: {
                label: "商品名称",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入商品名称",
                  clearable: "",
                },
                model: {
                  value: _vm.goodsName,
                  callback: function callback($$v) {
                    _vm.goodsName = $$v
                  },
                  expression: "goodsName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          attrs: {
            gutter: 20,
          },
        },
        [
          _c(
            "el-col",
            {
              attrs: {
                span: 4,
              },
            },
            [
              _c(
                "el-scrollbar",
                {
                  style: {
                    height: _vm.mainHeight + "px",
                  },
                  attrs: {
                    native: "",
                    "wrap-class": "left-scrollbar-wrapper",
                  },
                },
                [
                  _c(
                    "el-menu",
                    {
                      attrs: {
                        "default-active": _vm.currentGoodId + "",
                      },
                      on: {
                        select: function select(id) {
                          return _vm.getTableData(+id)
                        },
                      },
                    },
                    [
                      _c(
                        "el-submenu",
                        {
                          attrs: {
                            index: "all",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function fn() {
                                return [_vm._v(" 商品列表 ")]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _vm.goodList.length
                            ? _vm._l(_vm.goodList, function (item) {
                                return _c("el-menu-item", {
                                  key: item.goods_id,
                                  attrs: {
                                    index: item.goods_id + "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function fn() {
                                          return [
                                            _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  placement: "right",
                                                  content: item.name,
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "truncate",
                                                    attrs: {
                                                      title: item.name,
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item.name))]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              })
                            : [
                                _c("el-menu-item", {
                                  attrs: {
                                    index: null,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "title",
                                      fn: function fn() {
                                        return [_vm._v(" 暂无数据 ")]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                              ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            {
              attrs: {
                span: 20,
              },
            },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    "max-height": _vm.mainHeight,
                    stripe: "",
                    "highlight-current-row": "",
                    border: "",
                    data: _vm.tableData,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      type: "index",
                      width: "60",
                      label: "序号",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      width: "60",
                      label: "操作",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function fn(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "small",
                                },
                                on: {
                                  click: function click($event) {
                                    return _vm.handleEdit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "infoTypeCN",
                      label: "商品信息",
                    },
                  }),
                  _vm._l(_vm.allSettedLanguages, function (item) {
                    return _c("el-table-column", {
                      key: item.language_type + item.language_sort,
                      attrs: {
                        prop: item.language_type,
                        align: "center",
                        label: item.cn_name,
                      },
                    })
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "修改人/修改时间",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function fn(scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.updater))]),
                            _vm._v("/ "),
                            _c("span", [_vm._v(_vm._s(scope.row.update_time))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "发布人/发布时间",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function fn(scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.public_user))]),
                            _vm._v("/ "),
                            _c("span", [_vm._v(_vm._s(scope.row.public_time))]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "SettingLanguage",
        {
          ref: "settingLanguageInstance",
          attrs: {
            "default-selected-language": _vm.languageTypes,
            visible: _vm.settingVisible,
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.settingVisible = $event
            },
            confirm: _vm.handleLanguageChange,
          },
        },
        [_c("div", [_vm._v("hello")])]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "发布语言",
            visible: _vm.publishVisible,
            width: "480px",
          },
        },
        [
          _vm._v(
            " 发布后，商城用户侧立即生效，用户可自行切换已 设置的语种及翻译进行浏览，请确认是否发布？ "
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: {
                slot: "footer",
              },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.publishLoading,
                  },
                  on: {
                    click: _vm.handlePublishClose,
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.publishLoading,
                    type: "primary",
                  },
                  on: {
                    click: _vm.handlePublishConfirm,
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("EditI18n", {
        attrs: {
          "default-i18n-value": _vm.defaultEditI18nValue,
          "translation-id": _vm.currentTranslationId,
          visible: _vm.editVisible,
        },
        on: {
          "update:visible": function updateVisible($event) {
            _vm.editVisible = $event
          },
          confirm: function confirm($event) {
            return _vm.getTableData(_vm.currentGoodId, true)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }