var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "sticky",
        {
          attrs: {
            "z-index": 20,
          },
        },
        [
          _c("el-page-header", {
            staticClass: "go-back",
            attrs: {
              content: "订单详情",
            },
            on: {
              back: _vm.goBack,
            },
          }),
        ],
        1
      ),
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.detailLoading,
              expression: "detailLoading",
            },
          ],
          staticClass: "box-card",
        },
        [
          _c(
            "div",
            {
              staticClass: "order-header",
              attrs: {
                slot: "header",
              },
              slot: "header",
            },
            [
              _c(
                "div",
                {
                  staticClass: "header-item",
                },
                [_vm._v("订单号：" + _vm._s(_vm.detailData.order_number))]
              ),
              _c(
                "div",
                {
                  staticClass: "header-item",
                },
                [_vm._v("下单时间：" + _vm._s(_vm.detailData.place_time))]
              ),
            ]
          ),
          _c(
            "div",
            [
              _c(
                "el-row",
                {
                  staticClass: "order-info",
                },
                [
                  _c(
                    "el-col",
                    {
                      attrs: {
                        span: 10,
                      },
                    },
                    [
                      _vm.detailData.status != "CLOSED"
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "order-status",
                              },
                              [_vm._v("交易成功")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "order-note",
                              },
                              [_vm._v("买家已支付，交易成功。")]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      attrs: {
                        span: 14,
                      },
                    },
                    [
                      _c(
                        "el-steps",
                        {
                          attrs: {
                            space: 200,
                            active: _vm._f("activeNum")(_vm.detailData.status),
                            "process-status": "success",
                            "finish-status": "success",
                            "align-center": "",
                          },
                        },
                        _vm._l(
                          _vm.detailData.operation_log_list,
                          function (item, index) {
                            return _c("el-step", {
                              key: index,
                              attrs: {
                                title: item.operation,
                                description: item.time,
                              },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              false && _vm.detailData.delivery_sheet_info_list.length != 0
                ? _c(
                    "el-tabs",
                    {
                      staticStyle: {
                        "min-height": "200px",
                      },
                      attrs: {
                        type: "card",
                      },
                      model: {
                        value: _vm.activePackage,
                        callback: function callback($$v) {
                          _vm.activePackage = $$v
                        },
                        expression: "activePackage",
                      },
                    },
                    _vm._l(
                      _vm.detailData.delivery_sheet_info_list,
                      function (item, index) {
                        return _c(
                          "el-tab-pane",
                          {
                            key: index,
                            staticStyle: {
                              margin: "0 0 10px 20px",
                            },
                            attrs: {
                              label: "\u5305\u88F9".concat(index + 1),
                              name: "\u5305\u88F9".concat(index + 1),
                            },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticClass:
                                      "order-info-wrap order-packages",
                                    attrs: {
                                      span: 6,
                                    },
                                  },
                                  [
                                    item.express_item_list.length != 0
                                      ? _c("div", [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "order-info-item",
                                            },
                                            [
                                              _vm._v(
                                                "发货人：" +
                                                  _vm._s(
                                                    item.express_item_list[0]
                                                      .sender
                                                  )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "order-info-item",
                                            },
                                            [
                                              _vm._v(
                                                "运单号： " +
                                                  _vm._s(
                                                    item.express_item_list[0]
                                                      .logistics_order_number
                                                  )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "order-info-item",
                                            },
                                            [
                                              _vm._v(
                                                "发货方式： " +
                                                  _vm._s(
                                                    item.express_item_list[0]
                                                      .type
                                                  )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "order-info-item",
                                            },
                                            [
                                              _vm._v(
                                                "发货时间： " +
                                                  _vm._s(
                                                    _vm.formatTime(
                                                      item.express_item_list[0]
                                                        .sent_date
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "packages-title",
                                        staticStyle: {
                                          margin: "0",
                                        },
                                      },
                                      _vm._l(
                                        item.express_item_list,
                                        function (imgItem, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticStyle: {
                                                width: "80px",
                                                "margin-right": "5px",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  width: "80px",
                                                  margin: "10px 5px",
                                                },
                                                attrs: {
                                                  src: imgItem.img_url,
                                                },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "fs12 text-ellipsis",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(imgItem.sku_name) +
                                                      "x" +
                                                      _vm._s(imgItem.num)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    staticClass:
                                      "order-info-wrap order-packages",
                                    attrs: {
                                      span: 12,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          margin: "0 0 20px 20px",
                                        },
                                      },
                                      [
                                        _c("span", [_vm._v("物流状态：")]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#f56c6c",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.state_str))]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-timeline",
                                      {
                                        attrs: {
                                          reverse: true,
                                        },
                                      },
                                      _vm._l(
                                        item.mail_tracks,
                                        function (jitem, jindex) {
                                          return _c(
                                            "el-timeline-item",
                                            {
                                              key: jindex,
                                              attrs: {
                                                timestamp: jitem.op_time,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(jitem.op_desc) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  )
                : _vm._e(),
              _vm._l(
                _vm.detailData.oms_regular_order_item_dto_list,
                function (item, index) {
                  return _c(
                    "el-row",
                    {
                      key: index,
                      staticClass: "order-info",
                    },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "order-info-wrap",
                          attrs: {
                            span: 6,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "flex",
                            },
                            [
                              _c("h5", [
                                _vm._v(
                                  "激活状态： " +
                                    _vm._s(
                                      _vm._f("regularOrderText")(
                                        item.regular_order_status
                                      )
                                    )
                                ),
                              ]),
                              item.regular_order_status == "NOACTIVATE" &&
                              _vm.detailData.status != "CLOSED" &&
                              _vm.detailData.status != "TO_BE_PAID"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        "margin-left": "10px",
                                      },
                                      attrs: {
                                        type: "text",
                                      },
                                      on: {
                                        click: function click($event) {
                                          return _vm.handleActivity(
                                            item.regular_order_no
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 手动激活 ")]
                                  )
                                : _vm._e(),
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "margin-left": "10px",
                                  },
                                  attrs: {
                                    type: "text",
                                  },
                                  on: {
                                    click: function click() {
                                      return _vm.handleShowBlessingDialog(item)
                                    },
                                  },
                                },
                                [_vm._v(" 礼品卡 ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "order-info-item",
                            },
                            [_vm._v("激活码：" + _vm._s(item.cd_key))]
                          ),
                          item.regular_order_status != "NOACTIVATE"
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "order-info-item",
                                  },
                                  [
                                    _vm._v(
                                      "激活时间：" + _vm._s(item.activate_time)
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "order-info-wrap",
                          attrs: {
                            span: 6,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "flex",
                            },
                            [
                              _c("h5", [_vm._v("激活人信息")]),
                              item.regular_order_status != "NOACTIVATE"
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "flex",
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            type: "text",
                                          },
                                          on: {
                                            click: function click($event) {
                                              return _vm.handleUpdateAddressBtn(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 修改 ")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                          },
                                          on: {
                                            click: function click($event) {
                                              return _vm.handleCopy(
                                                "",
                                                $event,
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 复制 ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "order-info-item",
                            },
                            [_vm._v("收货人：" + _vm._s(item.receiver_name))]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "order-info-item",
                            },
                            [_vm._v("联系电话：" + _vm._s(item.receiver_phone))]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "order-info-item",
                            },
                            [
                              _vm._v(
                                "收货地址：" +
                                  _vm._s(item.province) +
                                  _vm._s(item.city) +
                                  _vm._s(item.county) +
                                  _vm._s(item.receiver_address)
                              ),
                            ]
                          ),
                        ]
                      ),
                      item.regular_order_status != "NOACTIVATE"
                        ? _c(
                            "el-col",
                            {
                              staticClass: "order-info-wrap",
                              attrs: {
                                span: 6,
                              },
                            },
                            [
                              _c(
                                "h5",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                      },
                                      on: {
                                        click: function click($event) {
                                          return _vm.handelDispatchDetail(
                                            item.oms_regular_order_item_detail_dto_list
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 发货单详情 ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "order-info-item",
                                },
                                [_vm._v("共 24 期")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "order-info-item",
                                },
                                [
                                  _vm._v(" 已发货 "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "red",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.hasDispatchedPackage(
                                            item.oms_regular_order_item_detail_dto_list
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" 期 "),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }
              ),
              _c(
                "el-row",
                {
                  staticClass: "order-info",
                },
                [
                  _vm.detailData.deliver_method != "NO_NEED_TO_DELIVER"
                    ? _c(
                        "el-col",
                        {
                          staticClass: "order-info-wrap",
                          attrs: {
                            span: 6,
                          },
                        },
                        [
                          _c("h5", [_vm._v("配送信息")]),
                          _c(
                            "div",
                            {
                              staticClass: "order-info-item",
                            },
                            [_vm._v("配送方式： 定时配送")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    {
                      staticClass: "order-info-wrap",
                      attrs: {
                        span: 6,
                      },
                    },
                    [
                      _c("h5", [_vm._v("付款信息")]),
                      _c(
                        "div",
                        {
                          staticClass: "order-info-item flex",
                        },
                        [
                          _vm._v(" 实付金额： "),
                          _c("div", [
                            _vm._v("￥" + _vm._s(_vm.detailData.total_price)),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "order-info-item",
                        },
                        [
                          _vm._v(
                            "付款方式：" +
                              _vm._s(
                                _vm.DICT.PAY_TYPE_VALUE_DIC[
                                  _vm.detailData.pay_type
                                ]
                              )
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "order-info-item",
                        },
                        [_vm._v("付款时间：" + _vm._s(_vm.detailData.pay_time))]
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "order-info-wrap",
                      attrs: {
                        span: 6,
                      },
                    },
                    [
                      _c("h5", [_vm._v("买家信息")]),
                      _c(
                        "div",
                        {
                          staticClass: "order-info-item",
                        },
                        [_vm._v("买家：" + _vm._s(_vm.detailData.buyer_name))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "order-info-item",
                        },
                        [
                          _vm._v(
                            "买家留言：" + _vm._s(_vm.detailData.buyer_remark)
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: {
                        width: "100%",
                      },
                      attrs: {
                        data: _vm.detailData.order_line_info_list,
                        border: "",
                        stripe: "",
                        "highlight-current-row": "",
                        "header-cell-style": {
                          background: "#eef1f6",
                          color: "#606266",
                        },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          width: "300",
                          align: "",
                          prop: "goods_name",
                          label: "商品名称",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function fn(_ref) {
                              var row = _ref.row
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex-center",
                                    staticStyle: {
                                      "justify-content": "start",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "imgSty",
                                      staticStyle: {
                                        width: "80px",
                                      },
                                      attrs: {
                                        src: row.goods_sku_image_address,
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          padding: "0 5px",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticStyle: {
                                              overflow: "hidden",
                                              "text-overflow": "ellipsis",
                                              "white-space": "nowrap",
                                            },
                                            attrs: {
                                              effect: "dark",
                                              content: row.goods_name,
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(_vm._s(row.goods_name)),
                                            ]),
                                          ]
                                        ),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(row.goods_sku_specification)
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "goods_sku_unit_price",
                          label: "单价",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function fn(_ref2) {
                              var row = _ref2.row
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.isVirtual
                                        ? row.paid_credit
                                        : row.goods_sku_unit_price
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "shopping_num",
                          label: "数量",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "pay_ump_discount_money",
                          label: "优惠",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "receivable_money",
                          label: "小计",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function fn(_ref3) {
                              var row = _ref3.row
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("toFixed")(
                                        _vm.isVirtual
                                          ? row.paid_credit
                                          : row.shopping_num *
                                              row.goods_sku_unit_price,
                                        2
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "after_sale_status",
                          label: "售后状态",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function fn(_ref4) {
                              var row = _ref4.row
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "text",
                                    },
                                    on: {
                                      click: function click($event) {
                                        return _vm.handleAfterSaleDetail(row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(row.after_sale_status) + " "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "flex-end",
                    "align-items": "flex-end",
                  },
                },
                [
                  _c(
                    "el-col",
                    {
                      attrs: {
                        span: 2,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "price-footer-l",
                        },
                        [_vm._v("商品总价：")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "price-footer-l",
                        },
                        [_vm._v("运费：")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "price-footer-l",
                        },
                        [_vm._v("优惠：")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "price-footer-l",
                        },
                        [_vm._v("实收金额：")]
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      attrs: {
                        span: 2,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "price-footer-r",
                        },
                        [_vm._v("￥" + _vm._s(_vm.detailData.total_price))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "price-footer-r",
                        },
                        [_vm._v("￥" + _vm._s(_vm.detailData.freight || 0))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "price-footer-r",
                        },
                        [
                          _vm._v(
                            "￥" +
                              _vm._s(_vm.detailData.pay_ump_discount_money || 0)
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "price-footer-r",
                          staticStyle: {
                            color: "red",
                            "font-size": "20px",
                          },
                        },
                        [
                          !_vm.isVirtual
                            ? _c("span", [
                                _vm._v(
                                  "￥" + _vm._s(_vm.detailData.total_price)
                                ),
                              ])
                            : _vm._e(),
                          _vm.isVirtual
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.detailData.paid_credit) + " 积分"
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "卖家备注",
            visible: _vm.dialogAddNote,
            width: "40%",
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.dialogAddNote = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              placeholder: "最多可输入256个字",
              maxlength: "256",
              "show-word-limit": "",
            },
            model: {
              value: _vm.note_form.remark,
              callback: function callback($$v) {
                _vm.$set(_vm.note_form, "remark", $$v)
              },
              expression: "note_form.remark",
            },
          }),
          _c(
            "div",
            {
              attrs: {
                slot: "footer",
              },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "text",
                  },
                  on: {
                    click: function click($event) {
                      _vm.dialogAddNote = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                  },
                  on: {
                    click: _vm.handleAddNoteConfirm,
                  },
                },
                [_vm._v(" 确定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "订单发货",
            visible: _vm.dialogDispatchOrder,
            top: "5vh",
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.dialogDispatchOrder = $event
            },
          },
        },
        [
          _c("dispatch-order", {
            attrs: {
              "goods-data": _vm.currentGoodsData,
            },
            on: {
              handleDelivery: _vm.handleDelivery,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "激活人信息",
            visible: _vm.updateAddressDialog,
            width: "500px",
            top: "5vh",
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.updateAddressDialog = $event
            },
          },
        },
        [
          _c("update-address", {
            attrs: {
              "order-no": _vm.regularOrderNo,
              "detail-data": _vm.currentActivityDetailData,
              "is-update": _vm.isUpdateAddress,
            },
            on: {
              closed: _vm.handleUpdateAddressClosed,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改物流",
            visible: _vm.updateExpressDialog,
            width: "800px",
            top: "5vh",
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.updateExpressDialog = $event
            },
          },
        },
        [
          _c("update-express", {
            attrs: {
              "order-no": _vm.order_number,
            },
            on: {
              closed: _vm.handleUpdateExpressClosed,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "发货单详情",
            visible: _vm.dialogDispatchDetail,
            width: "900px",
            top: "5vh",
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.dialogDispatchDetail = $event
            },
          },
        },
        [
          _c("dispatch-schedule-detail", {
            attrs: {
              "schedule-detail-data": _vm.scheduleDetailData,
            },
            on: {
              closed: _vm.handleDispatchDetailClosed,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "礼品卡备注",
            visible: _vm.dialogBlessing,
            width: "730px",
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.dialogBlessing = $event
            },
          },
        },
        [
          _c("el-input", {
            staticStyle: {
              width: "200px",
            },
            attrs: {
              placeholder: "购买人/购买单位（限10个字）",
            },
            model: {
              value: _vm.blessing_form.sender_name,
              callback: function callback($$v) {
                _vm.$set(_vm.blessing_form, "sender_name", $$v)
              },
              expression: "blessing_form.sender_name",
            },
          }),
          _vm._v(
            " 送您的*季节食材(按实际送出食材显示)已发货，运单号为**********1234，祝您 "
          ),
          _c("el-input", {
            staticStyle: {
              "margin-top": "10px",
            },
            attrs: {
              placeholder: "祝福语（限20个字）",
            },
            model: {
              value: _vm.blessing_form.blessing,
              callback: function callback($$v) {
                _vm.$set(_vm.blessing_form, "blessing", $$v)
              },
              expression: "blessing_form.blessing",
            },
          }),
          _c(
            "div",
            {
              attrs: {
                slot: "footer",
              },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "text",
                  },
                  on: {
                    click: function click($event) {
                      _vm.dialogBlessing = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                  },
                  on: {
                    click: _vm.handleAddBlessing,
                  },
                },
                [_vm._v(" 确定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }