/**
 * 外部文件使用方式：
  import clip from '@/utils/clipboard' 
  methods: {
  handleCopy(text, event) {
    clip(text, event)
   }
  }
 *clip() 函数第一个参数为复制的内容，第二个参数为 event 事件。两个参数均为必填项。
 */

import Vue from "vue";
import Clipboard from "clipboard";

function clipboardSuccess() {
  Vue.prototype.$message({
    message: "已复制到剪贴板",
    type: "success",
    duration: 2000,
  });
}

function clipboardError() {
  Vue.prototype.$message({
    message: "复制失败",
    type: "error",
  });
}

export default function handleClipboard(text, event) {
  const clipboard = new Clipboard(event.target, {
    text: () => text,
  });
  clipboard.on("success", () => {
    clipboardSuccess();
    clipboard.destroy();
  });
  clipboard.on("error", () => {
    clipboardError();
    clipboard.destroy();
  });
  clipboard.onClick(event);
}
