// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/bg.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login-container[data-v-37dfd6fc] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100%;\n  min-height: 100%;\n  width: 100%;\n  background-color: #2d3a4b;\n  overflow: hidden;\n}\n.login-container .login-form[data-v-37dfd6fc] {\n  position: relative;\n  width: 520px;\n  max-width: 100%;\n  padding: 160px 35px 0;\n  margin: 0 auto;\n  overflow: hidden;\n  margin-top: 13vh;\n}\n.login-container .tips[data-v-37dfd6fc] {\n  font-size: 14px;\n  color: #fff;\n  margin-bottom: 10px;\n}\n.login-container .tips span[data-v-37dfd6fc]:first-of-type {\n  margin-right: 16px;\n}\n.login-container .svg-container[data-v-37dfd6fc] {\n  padding: 6px 5px 6px 15px;\n  color: #889aa4;\n  vertical-align: middle;\n  width: 30px;\n  display: inline-block;\n}\n.login-container .title-container[data-v-37dfd6fc] {\n  position: relative;\n}\n.login-container .title-container .title[data-v-37dfd6fc] {\n  font-size: 26px;\n  color: #000;\n  margin: 0px auto 40px auto;\n  text-align: center;\n  font-weight: bold;\n}\n.login-container .show-pwd[data-v-37dfd6fc] {\n  position: absolute;\n  right: 10px;\n  top: 7px;\n  font-size: 16px;\n  color: #889aa4;\n  cursor: pointer;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n}", ""]);
// Exports
module.exports = exports;
