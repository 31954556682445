<template>
  <div v-loading="goodsDetailLoading" class="goods-wrap" style="background: #fff" element-loading-text="加载中...">
    <div>
      <sticky :z-index="20">
        <el-page-header content="商品信息" class="go-back" @back="goBack" />
      </sticky>
    </div>
    <el-form ref="formGoods" :model="formGoods" :rules="formGoodsRules" label-width="150px" class="goods-form--wrap">
      <el-row>
        <div class="panel-item">基本信息</div>
        <el-col :span="8">
          <el-form-item label="商品名称" prop="name">
            <el-input v-model="formGoods.name" maxlength="30" show-word-limit />
          </el-form-item>
          <el-form-item label="采购编码" prop="supplier_goods_code">
            <el-input v-model="formGoods.supplier_goods_code" />
          </el-form-item>
          <el-form-item label="商品类型" required>
            <el-select v-model="formGoods.type" placeholder="请选择" style="width: 100%" @change="handleGoodsType">
              <el-option v-for="item in getFilterGoodType(GOODS.TYPE)" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="收入科目" required prop="income_account">
            <el-select v-model="formGoods.income_account" placeholder="收入科目" style="width: 100%">
              <el-option v-for="item in INCOME_ACCOUNT" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="销项税率" prop="tax_rate">
            <el-select v-model="formGoods.tax_rate" placeholder="销项税率" style="width: 100%">
              <el-option label="9%" :value="9" />
              <el-option label="13%" :value="13" />
            </el-select>
          </el-form-item>
          <el-form-item label="商品分组" prop="tag_id_list">
            <el-select v-model="formGoods.tag_id_list" multiple placeholder="请选择商品分组" style="width: 100%" @change="handleTagIdListChange">
              <el-option v-for="item in optionsGoodsGroups" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="商品分类" prop="classification_id_list">
            <el-select v-model="formGoods.classification_id_list" multiple placeholder="请选择商品分类" style="width: 100%">
              <el-option v-for="item in optionsGoodsCategories" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="支付方式">
            <el-select v-model="formGoods.pay_type" placeholder="请选择" style="width: 100%">
              <el-option v-for="item in getFilterPayType(PAY_TYPE)" :key="item.value" :value="item.value" :label="item.label" />
            </el-select>
          </el-form-item>
          <el-form-item label="商品卖点">
            <el-input v-model="formGoods.short_description" type="textarea" maxlength="60" show-word-limit />
            <!-- <div class="color-minor">
                在商品详情页标题下面展示卖点信息，建议 60 字以内。
              </div> -->
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="商品图片" prop="images">
            <div>
              <upload-picture-with-button
                :limit-count="15"
                :already-file="formGoods.images"
                :cover-image-index.sync="formGoods.cover_image_index"
                :token="token"
                @uploadSuccess="uploadDetailPicSuccess"
              />
              <div class="color-minor">建议尺寸 800*800像素，最多上传 15 张</div>
            </div>
          </el-form-item>
          <el-form-item label="主图视频" prop="video_address">
            <div style="display: flex; align-items: flex-end">
              <upload-video :already-file="formGoods.video_address" :token="token" @uploadSuccess="uploadVideoSuccess" />
              <div v-if="formGoods.video_address" style="display: flex; flex-direction: column; margin: 0 15px">
                <el-button type="text" @click="dialogVideoCover = true"> 编辑封面 </el-button>
                <span />
                <el-button type="text" @click="handelDeleteVideo"> 移除视频 </el-button>
              </div>
            </div>
            <p class="color-minor">建议视频突出核心卖点，时长 9~30 秒，宽高比 16:9。请保证视频格式正确，且不超过20M</p>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18" />
      </el-row>
      <el-row>
        <el-col :span="18" />
      </el-row>
      <!-- 价格库存 -->
      <el-row>
        <div class="panel-item">价格库存</div>
        <el-col :span="22">
          <el-form-item label="商品规格" prop="goods_sku_info_list">
            <sku
              ref="skuChild"
              :goods-type="formGoods.type"
              :pay-type="formGoods.pay_type"
              :specification="skuSpecification"
              :skus-origin="skusSingleData"
              :member-rights="formGoods.member_rights"
              :upload-token="token"
              @skus="getSkusData"
            >
              <template #middle>
                <el-radio-group
                  v-model="formGoods.member_rights"
                  v-is-malls="[MALL_CODE_VALUE_MAP.taier_points_mall, MALL_CODE_VALUE_MAP.jiumaojiu_mall, MALL_CODE_VALUE_MAP.song_points_mall]"
                >
                  <el-radio :label="true"> 会员权益 </el-radio>
                  <el-radio :label="false"> 自定义 </el-radio>
                </el-radio-group>
              </template>
            </sku>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <el-form-item label="库存扣减方式" prop="decrease_stock_occasion" class="cut-way-wrap">
            <el-radio-group v-model="formGoods.decrease_stock_occasion" class="cut-way">
              <el-radio label="CREATING_ORDER" :disabled="formGoods.pay_type === PAY_TYPE_VALUE_MAP.POINTS">
                提交订单减库存
                <div class="color-minor minor-desc">买家提交订单，扣减库存数量，可能存在恶意占用库存风险</div>
              </el-radio>
              <el-radio label="PAID">
                支付订单减库存
                <div class="color-minor minor-desc">该项可减少恶拍，降低超卖风险</div>
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 物流信息 -->
      <el-row>
        <div class="panel-item">物流信息</div>
        <el-col :span="18">
          <el-form-item
            label="快递运费"
            prop="freight"
            :rules="[{ required: isFreightRequired(), message: '请填写快递运费' }, { validator: isPositiveNumber }]"
          >
            <el-input
              v-model="formGoods.freight"
              :disabled="formGoods.deliver_method !== DELIVER_METHOD_VALUE_MAP.EXPRESS_DELIVERY"
              style="width: 195px"
              @change="(val) => (formGoods.price_break_discount_reduce = val)"
            >
              <template slot="prepend"> ￥ </template>
            </el-input>
          </el-form-item>
          <div style="display: flex">
            <el-form-item
              label="满减金额"
              prop="price_break_discount_over"
              :rules="[{ required: isFreightRequired(), message: '请填写满减金额' }, { validator: isPositiveNumber }]"
            >
              <el-input
                v-model="formGoods.price_break_discount_over"
                :disabled="formGoods.deliver_method !== DELIVER_METHOD_VALUE_MAP.EXPRESS_DELIVERY"
                style="width: 120px"
              >
                <template slot="prepend"> 满 </template>
              </el-input>
            </el-form-item>
            <el-form-item
              label-width="0"
              prop="price_break_discount_reduce"
              :rules="[{ required: isFreightRequired(), message: '请填写优惠金额' }, { validator: isPositiveNumber }, { validator: maxReduceValue }]"
            >
              <el-input
                v-model="formGoods.price_break_discount_reduce"
                :disabled="formGoods.deliver_method !== DELIVER_METHOD_VALUE_MAP.EXPRESS_DELIVERY"
                style="width: 120px"
              >
                <template slot="prepend"> 减 </template>
              </el-input>
            </el-form-item>
          </div>
          <el-form-item label="配送方式">
            <el-select
              v-model="formGoods.deliver_method"
              :disabled="formGoods.type == TYPE_VALUE_MAP.REGULAR"
              placeholder="请选择"
              style="width: 195px"
              @change="handleDeliverMethodChange"
            >
              <el-option :disabled="formGoods.type === TYPE_VALUE_MAP.ELECTRONIC" label="快递" :value="DELIVER_METHOD_VALUE_MAP.EXPRESS_DELIVERY" />
              <el-option :disabled="formGoods.type === TYPE_VALUE_MAP.ELECTRONIC" label="自提" :value="DELIVER_METHOD_VALUE_MAP.CUSTOMER_PICK_UP" />
              <el-option label="无需快递" :value="DELIVER_METHOD_VALUE_MAP.NO_NEED_TO_DELIVER" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 其他信息 -->
      <el-row>
        <div class="panel-item">其他信息</div>
        <el-col :span="18">
          <el-form-item label="开售时间" prop="sale_status">
            <el-radio-group v-model="formGoods.sale_status" class="cut-way" @change="handleSaleTime">
              {{ SALE_STATUS_VALUE_MAP.FOR_SALE }}
              <el-radio class="sale-time--margin" :label="SALE_STATUS_VALUE_MAP.FOR_SALE">
                上架开售<span v-if="formGoods.sale_status === SALE_STATUS_VALUE_MAP.FOR_SALE && formGoods.to_end_time"
                  >（下架时间：{{ formGoods.to_end_time }}）</span
                >
              </el-radio>
              <!-- <el-radio class="sale-time--margin" :label="4">上架预售</el-radio> -->
              <el-radio class="sale-time--margin" :label="SALE_STATUS_VALUE_MAP.REPLENISHING">
                <div style="display: flex; align-items: center">
                  <div style="width: 150px">上架补货中</div>
                  <el-date-picker
                    v-model="formGoods.estimated_arrival_time"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetime"
                    placeholder="预计到货时间"
                  />
                </div>
              </el-radio>
              <el-radio class="sale-time--margin" :label="SALE_STATUS_VALUE_MAP.START_SELL_AFTER">
                <div style="display: flex; align-items: center">
                  <div style="width: 150px">自定义上架售卖时间</div>
                  <el-date-picker
                    v-model="to_sell_time"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetime"
                    placeholder="自定义上架售卖时间"
                    @change="handleCustomTime"
                  />
                  <span style="color: #000; font-weight: 400">&nbsp;至&nbsp;</span>
                  <el-date-picker
                    v-model="to_end_time"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetime"
                    placeholder="不限"
                    :picker-options="{ disabledDate: disabledToEndTime }"
                    @change="handleCustomEndTime"
                  />
                </div>
              </el-radio>
              <el-radio class="sale-time--margin" :label="SALE_STATUS_VALUE_MAP.CURRENTLY_NOT_FOR_SALE"> 暂不售卖，放入仓库 </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="起售数量" prop="min_order_num">
            <el-input v-model.number="formGoods.min_order_num" controls-position="right" :min="1" style="width: 195px">
              <template slot="append"> 件 </template>
            </el-input>
            <div class="color-minor">起售数量超出商品库存时，买家无法购买购买该商品</div>
          </el-form-item>
          <el-form-item
            label="限购"
            prop="order_quota"
            :rules="[{ required: formGoods.order_quota_type !== null, message: '请填写限购数量' }, { validator: isPositiveNumber }]"
          >
            <el-select v-model="formGoods.order_quota_type" @change="handleQuotaTypeChange">
              <el-option label="不限购" :value="null" />
              <el-option v-for="item in ORDER_QUOTA_TYPE" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <el-input v-model="formGoods.order_quota" :disabled="formGoods.order_quota_type === null" style="width: 150px; margin-left: 10px">
              <template slot="append"> 件 </template>
            </el-input>
          </el-form-item>
          <div class="flex">
            <el-form-item
              v-is-malls="[MALL_CODE_VALUE_MAP.jiumaojiu_mall, MALL_CODE_VALUE_MAP.song_points_mall, MALL_CODE_VALUE_MAP.mountain_outside_mall]"
              prop="can_purchase"
              label="可购买人群"
            >
              <el-select v-model="formGoods.can_purchase" @change="resetGradeSelect">
                <el-option label="所有人" value="ALL" />
                <el-option label="指定会员等级" value="ASSIGNED_GRADE" />
              </el-select>
            </el-form-item>
            <el-form-item v-if="formGoods.can_purchase === 'ASSIGNED_GRADE'" required label-width="80px" prop="grade_id_list" label="会员等级">
              <el-select v-model="formGoods.grade_id_list" multiple clearable collapse-tags>
                <el-option v-for="item in memberGradeList" :key="item.id" :label="item.level_name" :value="item.id" />
              </el-select>
            </el-form-item>
          </div>

          <!-- <el-form-item label="售后服务">
              <el-checkbox-group v-model="formGoods.sale_service" class="cut-way">
                <el-checkbox label="EXCHANGE" name="sale_service">支持买家申请换货</el-checkbox>
                <div class="color-minor minor-desc" style="margin-top: 0; line-height: 12px;">
                  商品详情页将展示“支持退货”的说明。
                </div>
                <el-checkbox label="RETURN" name="sale_service">7 天无理由退货</el-checkbox>
              </el-checkbox-group>
            </el-form-item> -->
          <!--          <el-form-item label="限制购买" prop="order_quota">-->
          <!--            <el-input v-model.number="formGoods.order_quota" style="width: 195px">-->
          <!--              <template slot="append">件</template>-->
          <!--            </el-input>-->
          <!--            <div class="color-minor">指每个账号终身限购，不填则不限制购买</div>-->
          <!--          </el-form-item>-->
        </el-col>
      </el-row>

      <!-- 图文详情 -->
      <el-row style="z-index: 2">
        <div class="panel-item" style="margin-bottom: 0">图文详情</div>
        <el-col :span="18">
          <editor :already-content="formGoods.detail" :token="token" @editorContent="getEditorContent" />
          <el-form-item style="text-align: center">
            <el-button @click="goBack"> 返回 </el-button>
            <el-button :loading="btnLoading" type="primary" @click="submitGoodsForm('formGoods')">
              {{ nativeSpuCode === "" ? "确定" : "修改" }}
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-dialog title="编辑视频" top="2vh" :visible.sync="dialogVideoCover" width="600px">
      <el-form>
        <el-form-item label="编辑封面" prop="video_cover_image_address">
          <div>
            <upload-picture :limit-count="1" :already-file="formGoods.video_cover_image_address" @uploadSuccess="uploadVidoeCoverSuccess" />
            <div class="color-minor">建议尺寸 800*800像素，如不添加封面，系统默认截取视频第一个画面为封面。</div>
          </div>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button type="text" @click="dialogVideoCover = false"> 取 消 </el-button>
        <el-button type="primary" @click="dialogVideoCover = false"> 确 定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UploadPictureWithButton from "@/components/Upload/UploadImgWithButton";
import UploadPicture from "@/components/Upload/UploadImg";
import UploadVideo from "@/components/Upload/UploadVideo";
import Sticky from "@/components/Sticky";
import { formatTime } from "@/utils/format-time";
import { commonUtil } from "@/utils";
import Editor from "@/components/Editor";
import Sku from "./Sku.vue";
import { scrollTo } from "@/utils/scroll-to";
import api from "@/api";
import { clone } from "ramda";
import { GOODS, MALL } from "@/constants";
import { cloneDeep, isEqual } from "lodash";
import dayjs from "dayjs";
import { options } from "runjs";

const { MALL_CODE_VALUE_MAP } = MALL;
const { to } = commonUtil;
const {
  PAY_TYPE,
  TYPE,
  TYPE_VALUE_MAP,
  PAY_TYPE_VALUE_MAP,
  DELIVER_METHOD_VALUE_MAP,
  SALE_STATUS_VALUE_MAP,
  DECREASE_STOCK_OCCASION_VALUE_MAP,
  INCOME_ACCOUNT,
  INCOME_ACCOUNT_VALUE_MAP,
  ORDER_QUOTA_TYPE_VALUE_MAP,
  ORDER_QUOTA_TYPE,
} = GOODS;

export default {
  components: {
    UploadPictureWithButton,
    UploadPicture,
    UploadVideo,
    Sticky,
    // GoodsSku,
    Editor,
    Sku,
  },
  props: {
    spuCode: {
      type: String,
      default: "",
    },
    isCopy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let validatorGoodsUrl = (rule, value, callback) => {
      let img_len = this.formGoods.images.length;
      if (img_len < 1) {
        callback(new Error("至少上传一张图片"));
      } else {
        callback();
      }
    };
    let validateGoodsSku = (rule, value, callback) => {
      let { goods_sku_info_list } = this.formGoods;
      if (goods_sku_info_list.length == 0) {
        callback(new Error("请添加商品规格"));
      } else {
        callback();
      }
    };
    const validateGradeIdList = (rule, value, callback) => {
      let { grade_id_list } = this.formGoods;
      if (grade_id_list.length == 0) {
        callback(new Error("请选择会员等级"));
      } else {
        callback();
      }
    };
    const isPositiveNumber = (rule, value, callback) => {
      if ([null, ""].includes(value)) callback();
      if (this.formGoods.type !== this.TYPE_VALUE_MAP["PHYSICAL"]) return callback();
      if (/^\d+(\.\d+)?$/.test(value)) {
        callback();
      } else {
        callback(new Error("请输入正数"));
      }
    };
    const maxReduceValue = (rule, value, callback) => {
      if (value > this.formGoods.freight) {
        callback(new Error("最大满减值要小于运费"));
      } else {
        callback();
      }
    };

    return {
      GOODS,
      SALE_STATUS_VALUE_MAP,
      DELIVER_METHOD_VALUE_MAP,
      TYPE_VALUE_MAP,
      PAY_TYPE,
      PAY_TYPE_VALUE_MAP,
      INCOME_ACCOUNT,
      ORDER_QUOTA_TYPE_VALUE_MAP,
      ORDER_QUOTA_TYPE,
      MALL_CODE_VALUE_MAP,
      btnLoading: false,
      goodsDetailLoading: false,
      isPositiveNumber,
      maxReduceValue,
      nativeSpuCode: this.spuCode,
      skuSpecification: [
        // {
        //   name: "颜色",
        //   value: ["黑色", "白色"],
        // },
      ],
      skusSingleData: [],
      dialogVideoCover: false,
      alreadySku: [],
      checkbox_sku: false,
      sale_time_radio: "",
      to_sell_time: "", //自定义售卖上架时间
      to_end_time: "", // 自定义售卖下架时间
      detail: "",
      formGoods: {
        type: this.getFilterGoodType(TYPE)[0].value,
        share_desc: "",
        tag_id_list: [],
        classification_id_list: [], // 商品分类
        name: "",
        // industry_category: "", //商品分组
        short_description: "", //商品描述
        income_account: INCOME_ACCOUNT_VALUE_MAP["GOODS"],
        tax_rate: 9, //销项税率
        pay_type: this.getFilterPayType(PAY_TYPE)[0].value,
        price_break_discount_over: 0, // 满减金额
        price_break_discount_reduce: 0, // 优惠金额
        can_purchase: "ALL", // 可购买人群
        grade_id_list: [],

        images: [], //商品详情图
        video_address: "",
        video_cover_image_address: "",
        supplier_goods_code: "", // 采购编码
        goods_sku_info_list: [],
        price: null,
        discount_price: null, //划线价
        decrease_stock_occasion: DECREASE_STOCK_OCCASION_VALUE_MAP.PAID, //库存扣减方式
        stock_num: null,
        weight: "", //商品重量
        deliver_method: DELIVER_METHOD_VALUE_MAP.EXPRESS_DELIVERY,
        freight: 0, //快递运费
        sale_status: SALE_STATUS_VALUE_MAP.FOR_SALE,
        to_sell_time: "", //上架时间
        to_end_time: "", // 下架时间
        estimated_arrival_time: "", //到货时间
        sale_service: [], //售后
        min_order_num: 1, //起售
        order_quota_type: null, // 限购类型
        order_quota: "",
        detail: "",

        member_rights: false, // 会员权益
      },
      formGoodsRules: {
        name: [{ required: true, message: "请输入商品名称" }],
        tag_id_list: [{ required: true, message: "请选择商品分组", trigger: "change" }],
        // spu_code: [{ required: true, message: "请输入商品编码" }],
        images: [{ required: true, validator: validatorGoodsUrl }],
        price: [{ required: true, message: "请输入价格" }],
        tax_rate: [{ required: true, message: "请选择销项税率" }],
        stock_num: [{ required: true, message: "请输入库存" }],
        goods_sku_info_list: [{ required: true, validator: validateGoodsSku }],
        decrease_stock_occasion: [{ required: true, message: "请选择库存扣减方式" }],
        express_way: [{ type: "array", required: true, message: "至少选择一个配送方式" }],
        freight: [{ validator: isPositiveNumber }],
        min_order_num: [{ required: true, message: "请填写起售数量" }],
        sale_status: [{ required: true, message: "请填选择开售时间" }],
        grade_id_list: [{ required: true, validator: validateGradeIdList }],
      },
      express_template: "", //运费模板
      optionsGoodsGroups: [],
      optionsGoodsCategories: [],
      memberGradeList: [],
      notifyPromise: Promise.resolve(),
      token: "",
    };
  },
  beforeDestroy() {
    this.$emit("close");
  },
  async created() {
    this.fetchQiniuToken();
    // getDetailByCode里面有需要先获取分组，才能更新分类的数据，所以这里await等待
    await this.fetchGoodsGroups();
    this.fetchMemberGradeIdList();
    if (this.nativeSpuCode) {
      this.getDetailByCode(this.nativeSpuCode);
    }
    if (this.isCopy) {
      this.nativeSpuCode = "";
    }
    // 太二积分默认为true，其他默认为false
    this.formGoods.member_rights = this.$store.getters.auth_info?.current_mall.code === "taier_points_mall";
  },
  methods: {
    isFreightRequired() {
      return this.formGoods.type === TYPE_VALUE_MAP["PHYSICAL"] && this.formGoods.deliver_method === DELIVER_METHOD_VALUE_MAP["EXPRESS_DELIVERY"];
    },
    async fetchQiniuToken() {
      const [err, res] = await api.auth.getQiNiuToken();
      if (err) return console.error(err);
      this.token = res;
    },
    async fetchGoodsGroups() {
      const [err, res] = await api.goods.goodsTagsAll();
      if (err) return console.error(err);
      this.optionsGoodsGroups = res;
    },
    handleTagIdListChange(val) {
      const goodsGroups = this.optionsGoodsGroups.filter((item) => val.includes(item.id));

      const idSet = new Set();
      this.optionsGoodsCategories = goodsGroups.reduce((acc, cur) => {
        return [
          ...acc,
          ...cur.related_goods_classifications
            .map((item) => {
              return { id: item.goods_classification_id, name: item.goods_classification_name };
            })
            .filter((item) => !idSet.has(item.id) && idSet.add(item.id)),
        ];
      }, []);

      this.formGoods.classification_id_list = this.formGoods.classification_id_list?.filter((id) => idSet.has(id)) ?? [];
    },
    async fetchMemberGradeIdList() {
      const [err, res] = await api.member.listGrade();
      if (err) return console.error(err);
      this.memberGradeList = res;
    },
    resetGradeSelect(val) {
      if (val === "ALL") this.formGoods.grade_id_list = [];
    },
    handleGoodsType(val) {
      // 清空sku组件的thirdPartyCode
      this.$refs.skuChild.clearThirdPartyCode();
      this.$refs.formGoods.clearValidate(["freight", "price_break_discount_over", "price_break_discount_reduce"]);
      if ([TYPE_VALUE_MAP.ELECTRONIC, TYPE_VALUE_MAP.REGULAR].includes(val)) {
        this.formGoods.deliver_method = DELIVER_METHOD_VALUE_MAP.NO_NEED_TO_DELIVER;
        this.setFreightRelatedFormData(null);
      } else {
        this.formGoods.deliver_method = DELIVER_METHOD_VALUE_MAP.EXPRESS_DELIVERY;
        this.setFreightRelatedFormData(0);
      }
    },
    setFreightRelatedFormData(val) {
      this.formGoods.freight = val;
      this.formGoods.price_break_discount_over = val;
      this.formGoods.price_break_discount_reduce = val;
    },
    handelDeleteVideo() {
      this.formGoods.video_address = "";
      this.formGoods.video_cover_image_address = "";
    },
    getSkusData(skus, specification) {
      skus = cloneDeep(skus);
      const sortedArr = this.formatSortArr(specification);
      skus.sort((a, b) => {
        const aSort = sortedArr.findIndex((item) => isEqual(item, a.specification));
        const bSort = sortedArr.findIndex((item) => isEqual(item, b.specification));
        return aSort - bSort;
      });

      this.skusSingleData = skus;
      this.formGoods.goods_sku_info_list = skus;
    },
    formatSortArr(specificationArr) {
      const _specificationObjArr = specificationArr.map((item) => {
        const { name, value } = item;
        return value.map((v) => ({ [name]: v }));
      });

      return this.combineArrays(_specificationObjArr);
    },
    combineArrays(arr) {
      arr = cloneDeep(arr);
      if (arr.length === 1) {
        return arr[0];
      }

      const firstArray = arr[0];
      const remainingArrays = arr.slice(1);
      const remainingCombinations = this.combineArrays(remainingArrays);

      const result = [];
      for (const obj1 of firstArray) {
        for (const obj2 of remainingCombinations) {
          const combinedObj = { ...obj1, ...obj2 };
          result.push(combinedObj);
        }
      }

      return result;
    },
    // 构造后端需要的sku格式
    formatSkuData() {
      let { skus } = this.$refs.skuChild;
      skus = clone(skus);
      const payType = this.formGoods.pay_type;

      skus.forEach((item) => {
        const { specification } = item;
        const _specification = [];
        for (const [key, value] of Object.entries(specification)) {
          _specification.push({ attribute_name: key, attribute_value: value });
        }
        item.specification = _specification;

        if (payType === PAY_TYPE_VALUE_MAP.POINTS) {
          this.resetPrice(item);
        } else if (payType === PAY_TYPE_VALUE_MAP.MONEY) {
          this.resetPoints(item);
        }
        return item;
      });
      this.formGoods.goods_sku_info_list = skus;
    },
    resetPrice(sku) {
      sku.original_price = "";
      sku.retail_price = "";
    },
    resetPoints(sku) {
      sku.original_points = "";
      sku.retail_points = "";
    },
    getEditorContent(v) {
      this.detail = v;
    },
    async getDetailByCode(code) {
      this.goodsDetailLoading = true;
      const [, good] = await api.goods.detail({
        params: {
          code,
        },
      });
      this.goodsDetailLoading = false;
      this.formGoods = good;
      // 这个需要先获取goodstag，所以created那里会先await fetchGoodsGroups获取
      this.handleTagIdListChange(good.tag_id_list);
      this.formatSkusProps(clone(good.goods_sku_info_list));
      this.skuSpecification = this.formatSpec(clone(good.goods_sku_info_list));
      if (good.sale_status === SALE_STATUS_VALUE_MAP.START_SELL_AFTER) {
        this.to_sell_time = good.to_sell_time;
        this.to_end_time = good.to_end_time;
      }
    },
    // 将后端的skus中的spec转化成给前端需要的
    formatSpec(skus) {
      const res = skus.reduce((pre, cur) => {
        const { specification } = cur;
        specification.forEach((_specification) => {
          const { attribute_name, attribute_value } = _specification;
          let formatSpec = pre.find((item) => item.name === attribute_name);
          if (formatSpec) {
            formatSpec.value.add(attribute_value);
          } else {
            pre.push({ name: attribute_name, value: new Set([attribute_value]) });
          }
        });
        return pre;
      }, []);
      return res.map((item) => ({ ...item, value: [...item.value] }));
    },
    // 将后端的skus格式转化成给前端需要的
    formatSkusProps(skus) {
      skus.forEach((item) => {
        let obj = {};
        item.specification.forEach((jitem) => {
          obj[jitem.attribute_name] = jitem.attribute_value;
          item.specification = obj;
        });
      });
      this.skusSingleData = skus;
    },
    handleSaleTime(val) {
      this.to_sell_time = "";
      this.to_end_time = "";
      this.formGoods.estimated_arrival_time = "";
      this.formGoods.to_end_time = "";
      switch (val) {
        case SALE_STATUS_VALUE_MAP.FOR_SALE:
          this.formGoods.to_sell_time = formatTime(new Date());
          break;
        case SALE_STATUS_VALUE_MAP.CURRENTLY_NOT_FOR_SALE:
          this.formGoods.to_sell_time = "";
          this.formGoods.to_end_time = "";
          break;
        default:
          break;
      }
    },
    handleQuotaTypeChange(val) {
      if (!val) this.formGoods.order_quota = "";
    },
    handleCustomTime(val) {
      this.formGoods.to_sell_time = formatTime(val);
    },
    handleCustomEndTime(val) {
      this.formGoods.to_end_time = formatTime(val);
    },
    disabledToEndTime(date) {
      if (dayjs(this.to_sell_time).isSame(dayjs(date), "day")) return false;
      return dayjs(this.to_sell_time).isAfter(dayjs(date));
    },
    handleDeliverMethodChange(val) {
      if (val === DELIVER_METHOD_VALUE_MAP["EXPRESS_DELIVERY"]) this.setFreightRelatedFormData(0);
      else this.setFreightRelatedFormData(null);
    },
    goBack() {
      this.$emit("goBack", "goBack");
    },
    checkFormData() {
      return new Promise((resolve, reject) => {
        let sale_status = this.formGoods.sale_status;
        if (sale_status === SALE_STATUS_VALUE_MAP.FOR_SALE) {
          this.formGoods.to_sell_time = formatTime(new Date());
        } else if (sale_status === SALE_STATUS_VALUE_MAP.REPLENISHING) {
          this.formGoods.to_sell_time = this.to_sell_time;
        } else if (sale_status === SALE_STATUS_VALUE_MAP.CURRENTLY_NOT_FOR_SALE) {
          this.formGoods.to_sell_time = "";
        }

        this.skuSpecification.forEach((item) => {
          if (!item.name) this.skuReject(reject, "请填写规格名称");
          if (!item.value) this.skuReject(reject, "请正确填写规格值");
          item.value?.forEach((value) => {
            if (!value) this.skuReject(reject, "请正确填写规格值");
          });
        });
        let { goods_sku_info_list, type, pay_type } = this.formGoods;
        goods_sku_info_list.forEach((item) => {
          if (pay_type !== PAY_TYPE_VALUE_MAP.POINTS && item.retail_price === "") this.skuReject(reject, "请填写售价");
          if (item.weight === "") this.skuReject(reject, "请填写重量");
          if (item.stock_num === "") this.skuReject(reject, "请填写库存");
          if ([TYPE_VALUE_MAP["PHYSICAL"], TYPE_VALUE_MAP["ELECTRONIC"]].includes(type)) {
            if (!item.third_party_code) this.skuReject(reject, type === TYPE_VALUE_MAP["PHYSICAL"] ? "请填写企得宝编码" : "请选择关联优惠券");
          }
        });
        resolve();
      });
    },
    skuReject(reject, str) {
      reject(
        (this.notifyPromise = this.notifyPromise.then(this.$nextTick).then(() => {
          this.$notify.warning(str);
        }))
      );
      this.btnLoading = false;
      scrollTo(700, 800);
    },
    // 表单提交的按钮
    async submitGoodsForm(formName) {
      this.btnLoading = true;
      this.formatSkuData();
      // 因为如果直接修改this.formGoods.detail的话，会导致每次输入刷新，editor的编辑指针都会刷新到最后，所以加一个detail字段
      this.formGoods.detail = this.detail;
      const [, isValid] = await to(this.$refs.formGoods.validate());
      const [err] = await to(this.checkFormData());
      if (!isValid || err) {
        scrollTo(0, 800);
        this.btnLoading = false;
        return;
      }

      // 定时发货商品移除快递字段
      const goodData = { ...this.formGoods };
      if (this.formGoods.deliver_method !== DELIVER_METHOD_VALUE_MAP["EXPRESS_DELIVERY"]) {
        delete goodData.freight;
        delete goodData.price_break_discount_over;
        delete goodData.price_break_discount_reduce;
      }
      if (this.nativeSpuCode) {
        const [err] = await api.goods.goods({
          method: "put",
          id: this.nativeSpuCode,
          data: goodData,
        });
        if (!err) {
          this.$notify.success("修改成功");
          this.goBack();
        }
        this.btnLoading = false;
      } else {
        //创建
        const [, isValid] = await to(this.$refs[formName].validate());
        if (isValid) {
          const [err] = await api.goods.goods({
            method: "post",
            data: goodData,
          });
          if (!err) {
            this.$notify.success("操作成功");
            this.goBack();
          }
        } else {
          scrollTo(0, 800);
        }
      }
      this.btnLoading = false;
    },

    uploadDetailPicSuccess(picList) {
      this.formGoods.images = [];
      picList.forEach((item) => {
        this.formGoods.images.push(item.url);
      });
    },
    uploadVidoeCoverSuccess(val) {
      if (val.length != 0) {
        this.formGoods.video_cover_image_address = val[0]["url"];
      } else if (val.length == 0) {
        this.formGoods.video_cover_image_address = "";
      }
    },
    // 图片上传结束
    uploadVideoSuccess(url) {
      this.formGoods.video_address = url;
    },
    getFilterPayType(payTypeList) {
      const currentMall = this.$store.state.user.auth_info.current_mall;
      let _payTypeList = payTypeList;

      if (
        [
          MALL_CODE_VALUE_MAP["taier_points_mall"],
          // MALL_CODE_VALUE_MAP["song_points_mall"],
          MALL_CODE_VALUE_MAP["group_points_mall"],
          MALL_CODE_VALUE_MAP["taier_global_mall"],
        ].includes(currentMall.code)
      ) {
        _payTypeList = payTypeList.filter((item) => item.value === PAY_TYPE_VALUE_MAP.POINTS);
      }
      if (
        [
          MALL_CODE_VALUE_MAP["taier_mall"],
          MALL_CODE_VALUE_MAP["song_mall"],
          MALL_CODE_VALUE_MAP["miliang_mall"],
          MALL_CODE_VALUE_MAP["group_shopping_mall"],
          MALL_CODE_VALUE_MAP["group_love_mall"],
        ].includes(currentMall.code)
      ) {
        _payTypeList = payTypeList.filter((item) => item.value === PAY_TYPE_VALUE_MAP.MONEY);
      }
      return _payTypeList;
    },
    getFilterGoodType(goodTypeList) {
      const currentMall = this.$store.state.user.auth_info.current_mall;
      let _goodTypeList = goodTypeList;

      if ([MALL_CODE_VALUE_MAP["taier_global_mall"]].includes(currentMall.code)) {
        _goodTypeList = _goodTypeList.filter((item) => item.value === TYPE_VALUE_MAP.ELECTRONIC);
        if (this.formGoods) this.formGoods.deliver_method = DELIVER_METHOD_VALUE_MAP.NO_NEED_TO_DELIVER;
      }

      return _goodTypeList;
    },
  },
};
</script>

<style scoped>
.panel-item {
  margin-bottom: 8px;
}

.goods-wrap >>> .el-loading-mask {
  z-index: 100000;
}

.goods-form--wrap {
  min-height: 100vh;
}

.cut-way {
  display: flex;
  flex-direction: column;
}

.minor-desc {
  margin: 10px 0 10px 25px;
  font-size: 12px;
}

.sale-time--margin {
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.cut-way-wrap >>> .el-form-item__label {
  line-height: 16px;
}
</style>
