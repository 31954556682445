var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "el-dialog",
    _vm._b(
      {
        attrs: {
          title: "编辑",
          width: "480px",
          visible: _vm.computedVisible,
        },
        on: {
          "update:visible": function updateVisible($event) {
            _vm.computedVisible = $event
          },
          open: _vm.handleOpen,
        },
      },
      "el-dialog",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "el-form",
        {
          attrs: {
            "label-width": "70px",
          },
        },
        _vm._l(_vm.i18nSetting, function (item) {
          return _c(
            "el-form-item",
            {
              key: item.language_type,
              attrs: {
                label: item.cn_name,
              },
            },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.disabledLanguages.includes(item.language_type),
                },
                model: {
                  value: item.language_translation,
                  callback: function callback($$v) {
                    _vm.$set(item, "language_translation", $$v)
                  },
                  expression: "item.language_translation",
                },
              }),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: {
            slot: "footer",
          },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.loading,
              },
              on: {
                click: _vm.handleClose,
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.loading,
                type: "primary",
              },
              on: {
                click: _vm.handleConfirm,
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }