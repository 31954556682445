import { transformUtil } from "@/utils";

const ARR_LIST_MAP = {
  MALL_CODE: [
    { label: "太二商城", value: "taier_mall" },
    { label: "太二积分商城", value: "taier_points_mall" },
    { label: "太二澳门", value: "taier_global_mall" },
    { label: "怂火锅商城", value: "song_points_mall" },
    { label: "米良人良商城", value: "miliang_mall" },
    { label: "新米良人良商城", value: "mira_mall" },
    { label: "怂商城", value: "song_mall" },
    { label: "集团内购商城", value: "group_shopping_mall" },
    { label: "集团积分商城", value: "group_points_mall" },
    { label: "集团爱心商城", value: "group_love_mall" },
    { label: "九毛九商城", value: "jiumaojiu_mall" },
    { label: "山外面商城", value: "mountain_outside_mall" },
    { label: "太二新加坡商城", value: "taier_singapore_mall" },
  ],
};

export default transformUtil.arrListToMapLists(ARR_LIST_MAP);
