var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "transition",
    {
      attrs: {
        name: "vac-fade-spinner",
        appear: "",
      },
    },
    [
      _vm.show
        ? _c(
            "div",
            {
              class: {
                "vac-container-center": !_vm.infinite,
                "vac-container-top": _vm.infinite,
              },
            },
            [
              _c("div", {
                attrs: {
                  id: "vac-circle",
                },
              }),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }