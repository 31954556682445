<template>
  <div class="page-padding">
    <div class="page-header--wrap">
      <div class="page-header-wrap--title">会员管理</div>
      <div />
    </div>

    <el-row class="page-filter--wrap">
      <!-- <div class="page-color--normal">上次消费时间</div>
      <el-date-picker
        v-model="last_consumption_time"
        value-format="yyyy-MM-dd"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="handleDateChange($event, 'consumption')"
      /> -->
      <div class="page-color--normal">会员名/手机号</div>
      <el-input
        v-model="searchForm.keyword"
        placeholder="请输入会员名/手机号"
        class="page-select--item"
        style="width: 180px"
        @keyup.enter.native="getList"
      />
      <div class="page-color--normal">状态</div>
      <el-select v-model="searchForm.status" placeholder="请选择" class="page-select--item">
        <el-option label="正常" value="1" />
        <el-option label="禁用" value="0" />
      </el-select>
      <div class="page-color--normal">注册会员时间</div>
      <el-date-picker
        v-model="registration_time"
        value-format="yyyy-MM-dd"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="handleDateChange($event, 'registration')"
      />

      <el-button class="reset-btn" round icon="el-icon-refresh" @click="resetSearchForm"> 重置 </el-button>
      <el-button type="primary" round icon="el-icon-search" @click="getList()"> 搜索 </el-button>
    </el-row>

    <el-table v-loading="listLoading" :data="memberData" stripe border highlight-current-row style="width: 100%">
      <!-- <el-table-column type="selection" width="55" align="center"></el-table-column> -->
      <el-table-column type="index" label="序号" width="60" align="center" />
      <el-table-column label="操作" width="80px" align="center">
        <template #default="{ row }">
          <el-button :disabled="true" type="text" @click="handleMemberDetail(row)"> 查看详情 </el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="open_id" label="OpenId" width="240px" show-overflow-tooltip />
      <el-table-column align="center" prop="status" label="状态" show-overflow-tooltip>
        <template #default="{ row }">
          <span :class="{ green: row.status === 1, gray: row.status === 0 }">
            {{ row.status === 1 ? "正常" : "禁用" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="name" label="会员名" show-overflow-tooltip />
      <el-table-column align="center" prop="membership_card_num" label="会员ID" show-overflow-tooltip />
      <el-table-column align="center" prop="phone" label="手机号" width="100px" show-overflow-tooltip />
      <el-table-column align="center" prop="create_time" label="注册时间" width="150px" show-overflow-tooltip />
      <el-table-column align="center" prop="agree_privacy_policy" label="是否同意隐私政策" width="100px" show-overflow-tooltip>
        <template #default="{ row }">
          <span @click="openPrivacyDialog(row.id)">
            {{ row.agree_privacy_policy === 1 ? "是" : "否" }} <el-button type="text">查看历史</el-button>
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="total_consumption_times" label="累计消费次数" show-overflow-tooltip />
      <el-table-column align="center" prop="total_consumption_amount" label="累计消费金额" show-overflow-tooltip />
      <el-table-column align="center" prop="last_consumption_time" label="上次消费时间" show-overflow-tooltip />
    </el-table>

    <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    <PrivacyDialog :visible.sync="privacy.visible" :user-id="privacy.userId"></PrivacyDialog>
  </div>
</template>

<script>
import api from "@/api";
import TableListMixin from "@/mixin/TableList";
import Pagination from "@/components/Pagination";
import PrivacyDialog from "./components/PrivacyDialog";
export default {
  name: "MemberManage",
  components: {
    Pagination,
    PrivacyDialog,
  },
  mixins: [TableListMixin],
  data() {
    return {
      last_consumption_time: [],
      registration_time: [],
      listLoading: false,
      memberData: [],
      privacy: {
        visible: false,
        userId: -1,
      },

      searchForm: {
        register_date_start: "",
        register_date_end: "",
        last_consumption_date_start: "",
        last_consumption_date_end: "",
        keyword: "",
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    resetSearchForm() {
      for (let key in this.searchForm) {
        this.searchForm[key] = "";
      }
      this.last_consumption_time = [];
      this.registration_time = [];
      this.getList();
    },
    async getList() {
      const [, res] = await this.getListMixin(api.member.users);
      this.memberData = res.list;
    },
    async openPrivacyDialog(userId) {
      this.privacy.userId = userId;
      this.privacy.visible = true;
    },
    handleDateChange(dateRange, dateType) {
      console.log(dateRange);
      switch (dateType) {
        case "consumption":
          [this.searchForm.last_consumption_date_start, this.searchForm.last_consumption_date_end] = dateRange;
          break;
        case "registration":
          [this.searchForm.register_date_start, this.searchForm.register_date_end] = dateRange;
          break;
      }
    },
    handleMemberDetail(row) {
      this.$router.push({ path: "/shopper/member_detail", query: { id: row.id } });
    },
  },
};
</script>

<style>
.reset-btn {
  margin-left: 10px;
}
.green {
  color: #8dc448;
}
.gray {
  color: #c75555;
}
</style>
