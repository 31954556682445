var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "vac-reply-message",
    },
    [
      _c(
        "div",
        {
          staticClass: "vac-reply-username",
        },
        [_vm._v(" " + _vm._s(_vm.replyUsername) + " ")]
      ),
      _vm.isImage
        ? _c(
            "div",
            {
              staticClass: "vac-image-reply-container",
            },
            [
              _c("div", {
                staticClass: "vac-message-image vac-message-image-reply",
                style: {
                  "background-image": "url('".concat(
                    _vm.message.replyMessage.file.url,
                    "')"
                  ),
                },
              }),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "vac-reply-content",
        },
        [
          _c("format-message", {
            attrs: {
              content: _vm.message.replyMessage.content,
              users: _vm.roomUsers,
              "text-formatting": _vm.textFormatting,
              "link-options": _vm.linkOptions,
              reply: true,
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.$scopedSlots, function (i, name) {
                  return {
                    key: name,
                    fn: function fn(data) {
                      return [_vm._t(name, null, null, data)]
                    },
                  }
                }),
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }