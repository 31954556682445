var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "page-padding",
    },
    [
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "page-header--wrap",
            },
            [
              _c(
                "div",
                {
                  staticClass: "page-header-wrap--title",
                },
                [_vm._v("定时发货订单")]
              ),
              _c(
                "div",
                [
                  _vm.order_filter == "待发货"
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: _vm.multipleSelection.length == 0,
                            type: "primary",
                          },
                          on: {
                            click: _vm.handleBatchExpress,
                          },
                        },
                        [_vm._v(" 推送至企得宝 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-row",
            {
              staticClass: "page-filter--wrap",
            },
            [
              _c(
                "div",
                {
                  staticClass: "page-color--normal",
                },
                [_vm._v("订单搜索")]
              ),
              _c(
                "el-input",
                {
                  staticStyle: {
                    width: "350px",
                  },
                  attrs: {
                    placeholder: "请输入内容",
                    clearable: "",
                  },
                  on: {
                    input: _vm.handleTypeInputChange,
                  },
                  model: {
                    value: _vm.orderTypeInput,
                    callback: function callback($$v) {
                      _vm.orderTypeInput = $$v
                    },
                    expression: "orderTypeInput",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "120px",
                      },
                      attrs: {
                        slot: "prepend",
                        placeholder: "请选择",
                        disabled: "",
                      },
                      on: {
                        change: _vm.handleTypeChange,
                      },
                      slot: "prepend",
                      model: {
                        value: _vm.orderType,
                        callback: function callback($$v) {
                          _vm.orderType = $$v
                        },
                        expression: "orderType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label: "订单编号",
                          value: "order_number",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "page-color--normal",
                },
                [_vm._v("下单时间")]
              ),
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  clearable: "",
                  "unlink-panels": "",
                },
                on: {
                  change: _vm.handleDateChange,
                },
                model: {
                  value: _vm.order_time,
                  callback: function callback($$v) {
                    _vm.order_time = $$v
                  },
                  expression: "order_time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            {
              staticClass: "page-filter--wrap",
            },
            [
              _c(
                "div",
                {
                  staticClass: "page-color--normal",
                },
                [_vm._v("发货时间")]
              ),
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  clearable: "",
                  "unlink-panels": "",
                },
                on: {
                  change: _vm.handleDeliveryDateChange,
                },
                model: {
                  value: _vm.delivery_time,
                  callback: function callback($$v) {
                    _vm.delivery_time = $$v
                  },
                  expression: "delivery_time",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "page-color--normal",
                },
                [_vm._v("付款时间")]
              ),
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  clearable: "",
                  "unlink-panels": "",
                },
                on: {
                  change: _vm.handlePaymentDateChange,
                },
                model: {
                  value: _vm.payment_time,
                  callback: function callback($$v) {
                    _vm.payment_time = $$v
                  },
                  expression: "payment_time",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "page-color--normal",
                },
                [_vm._v("买家手机")]
              ),
              _c("el-input", {
                staticClass: "page-select--item",
                staticStyle: {
                  width: "150px",
                },
                attrs: {
                  clearable: "",
                  placeholder: "请输入买家手机",
                },
                model: {
                  value: _vm.searchForm.buyer_mobile_number,
                  callback: function callback($$v) {
                    _vm.$set(_vm.searchForm, "buyer_mobile_number", $$v)
                  },
                  expression: "searchForm.buyer_mobile_number",
                },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            {
              staticClass: "page-filter--wrap",
            },
            [
              _c(
                "div",
                {
                  staticClass: "page-color--normal",
                },
                [_vm._v("收支时间")]
              ),
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  clearable: "",
                  "unlink-panels": "",
                },
                on: {
                  change: _vm.handleTranDateChange,
                },
                model: {
                  value: _vm.tran_time,
                  callback: function callback($$v) {
                    _vm.tran_time = $$v
                  },
                  expression: "tran_time",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "page-color--normal",
                },
                [_vm._v("商品名称")]
              ),
              _c("el-input", {
                staticStyle: {
                  width: "350px",
                },
                attrs: {
                  clearable: "",
                  placeholder: "请输入商品名称",
                },
                model: {
                  value: _vm.searchForm.goods_name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.searchForm, "goods_name", $$v)
                  },
                  expression: "searchForm.goods_name",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "page-color--normal",
                },
                [_vm._v("激活状态")]
              ),
              _c(
                "el-select",
                {
                  staticStyle: {
                    width: "150px",
                  },
                  attrs: {
                    placeholder: "全部",
                    clearable: "",
                  },
                  model: {
                    value: _vm.searchForm.activated,
                    callback: function callback($$v) {
                      _vm.$set(_vm.searchForm, "activated", $$v)
                    },
                    expression: "searchForm.activated",
                  },
                },
                [
                  _c("el-option", {
                    attrs: {
                      label: "未激活",
                      value: "NOACTIVATE",
                    },
                  }),
                  _c("el-option", {
                    attrs: {
                      label: "已激活",
                      value: "ACTIVATE",
                    },
                  }),
                  _c("el-option", {
                    attrs: {
                      label: "已完成",
                      value: "FINISHED",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              staticClass: "page-filter--wrap",
              staticStyle: {
                margin: "20px 0",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    round: "",
                    icon: "el-icon-refresh",
                  },
                  on: {
                    click: _vm.resetSearchForm,
                  },
                },
                [_vm._v(" 重置 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    round: "",
                    icon: "el-icon-search",
                  },
                  on: {
                    click: _vm.getList,
                  },
                },
                [_vm._v(" 搜索 ")]
              ),
              _c(
                "el-popconfirm",
                {
                  attrs: {
                    title: "是否按当前搜索条件导出文件？",
                  },
                  on: {
                    onConfirm: function onConfirm($event) {
                      return _vm.exportFile("order")
                    },
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        "margin-left": "10px",
                      },
                      attrs: {
                        slot: "reference",
                        type: "success",
                        round: "",
                        icon: "el-icon-download",
                        loading: _vm.downloadingOrder,
                      },
                      slot: "reference",
                    },
                    [_vm._v(" 导出订单文件 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-row", [
            _c(
              "div",
              {
                staticStyle: {
                  "margin-bottom": "15px",
                },
              },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: {
                      size: "small",
                    },
                    on: {
                      change: _vm.handleOrderRadioChange,
                    },
                    model: {
                      value: _vm.order_filter,
                      callback: function callback($$v) {
                        _vm.order_filter = $$v
                      },
                      expression: "order_filter",
                    },
                  },
                  [
                    _c("el-radio-button", {
                      attrs: {
                        label: "全部",
                      },
                    }),
                    _c(
                      "el-radio-button",
                      {
                        attrs: {
                          label: "TO_BE_PAID",
                        },
                      },
                      [_vm._v(" 待付款 ")]
                    ),
                    _c(
                      "el-radio-button",
                      {
                        attrs: {
                          label: "COMPLETED",
                        },
                      },
                      [_vm._v(" 已完成 ")]
                    ),
                    _c(
                      "el-radio-button",
                      {
                        attrs: {
                          label: "CLOSED",
                        },
                      },
                      [_vm._v(" 已关闭 ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: {
                width: "100%",
              },
              attrs: {
                data: _vm.storeData,
                stripe: "",
                border: "",
                "row-key": "id",
                "row-class-name": _vm.getRowClass,
              },
              on: {
                "selection-change": _vm.handleSelectionChange,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  width: "55",
                  align: "center",
                  "class-name": "selection-class",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "商品信息",
                  align: "",
                  width: "300",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref) {
                      var row = _ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "mesWrap",
                          },
                          _vm._l(
                            row.order_line_info_list,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "mesSty",
                                },
                                [
                                  _c("img", {
                                    staticClass: "imgSty",
                                    attrs: {
                                      src: item.goods_sku_image_address,
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mesFont",
                                    },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticStyle: {
                                            overflow: "hidden",
                                            "text-overflow": "ellipsis",
                                            "white-space": "nowrap",
                                          },
                                          attrs: {
                                            effect: "dark",
                                            content: item.goods_name,
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c("p", [
                                            _vm._v(_vm._s(item.goods_name)),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.goods_sku_specification
                                              ) +
                                              " "
                                          ),
                                          item.delivery_status == "已发货"
                                            ? _c(
                                                "el-tag",
                                                {
                                                  attrs: {
                                                    type: "danger",
                                                    effect: "plain",
                                                  },
                                                },
                                                [_vm._v(" 已发货 ")]
                                              )
                                            : _vm._e(),
                                          item.express_remark
                                            ? _c(
                                                "el-tag",
                                                {
                                                  attrs: {
                                                    type: "danger",
                                                    effect: "plain",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.express_remark
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "item",
                            staticStyle: {
                              display: "flex",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  width: "75vw",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "margin-left": "1%",
                                    },
                                  },
                                  [
                                    _vm._v(" 订单编号： "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "text",
                                        },
                                        on: {
                                          click: function click($event) {
                                            return _vm.handleOrderDetail(row)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(row.order_number))]
                                    ),
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          content: "复制单号",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                            },
                                            on: {
                                              click: function click($event) {
                                                return _vm.handleCopy(
                                                  row.order_number,
                                                  $event
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-document-copy",
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "dispatch-order-time",
                                  },
                                  [
                                    _vm._v(
                                      "下单时间：" +
                                        _vm._s(row.place_time) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row.buyer_remark,
                                        expression: "row.buyer_remark",
                                      },
                                    ],
                                    staticClass: "remark-text",
                                    staticStyle: {
                                      color: "#f23645",
                                      "margin-right": "5%",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "买家备注：" + _vm._s(row.buyer_remark)
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: row.merchant_remark,
                                        expression: "row.merchant_remark",
                                      },
                                    ],
                                    staticClass: "remark-text",
                                    staticStyle: {
                                      color: "#eba011",
                                      "margin-left": "5%",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " 卖家备注：" +
                                        _vm._s(row.merchant_remark) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "text",
                                        },
                                        on: {
                                          click: function click($event) {
                                            return _vm.handleDownloadCdk(
                                              row.order_number
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 下载兑换码 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c("div"),
                            _c("div"),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "单价/数量",
                  width: "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref2) {
                      var row = _ref2.row
                      return _vm._l(
                        row.order_line_info_list,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "my-table--column",
                            },
                            [
                              row.pay_type !== "CREDIT"
                                ? _c("div", [
                                    _vm._v(
                                      "￥" + _vm._s(item.goods_sku_unit_price)
                                    ),
                                  ])
                                : _vm._e(),
                              row.pay_type === "CREDIT"
                                ? _c("div", [
                                    _vm._v(_vm._s(item.paid_credit) + " 积分"),
                                  ])
                                : _vm._e(),
                              _c("div", [
                                _vm._v(_vm._s(item.shopping_num) + " 件"),
                              ]),
                            ]
                          )
                        }
                      )
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "售后",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref3) {
                      var row = _ref3.row
                      return _vm._l(
                        row.order_line_info_list,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "my-table--column",
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    disabled: !item.after_sale_ticket_number,
                                  },
                                  on: {
                                    click: function click($event) {
                                      return _vm.handleAfterSaleDetail(item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(item.after_sale_status) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }
                      )
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "买家",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref4) {
                      var row = _ref4.row
                      return [
                        _c("div", [
                          _c("div", [_vm._v(_vm._s(row.buyer_name))]),
                          _c("div", [_vm._v(_vm._s(row.buyer_mobile_number))]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "status_chinese_representation",
                  align: "center",
                  label: "订单状态",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "total_price",
                  align: "center",
                  label: "实付金额",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref5) {
                      var row = _ref5.row
                      return [
                        _c("div", [
                          row.pay_type !== "CREDIT"
                            ? _c("div", [
                                _vm._v("￥" + _vm._s(row.total_price)),
                              ])
                            : _vm._e(),
                          row.pay_type === "CREDIT"
                            ? _c("div", [
                                _vm._v(_vm._s(row.paid_credit) + " 积分"),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "activated",
                  align: "center",
                  label: "激活状态",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref6) {
                      var row = _ref6.row
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("regularOrderText")(
                                row.regular_order_status
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: "操作",
                  align: "center",
                  width: "250",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref7) {
                      var row = _ref7.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled:
                                row.status_chinese_representation != "待发货",
                              type: "text",
                            },
                            on: {
                              click: function click($event) {
                                return _vm.handleDispatchOrder(row)
                              },
                            },
                          },
                          [_vm._v(" 发货 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                            },
                            on: {
                              click: function click($event) {
                                return _vm.handleOrderDetail(row)
                              },
                            },
                          },
                          [_vm._v(" 详情 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                            },
                            on: {
                              click: function click($event) {
                                return _vm.handleAddNote(row)
                              },
                            },
                          },
                          [_vm._v(" 备注 ")]
                        ),
                        _vm.searchForm.status === "已申请发货"
                          ? _c(
                              "el-popconfirm",
                              {
                                staticStyle: {
                                  "margin-left": "10px",
                                },
                                attrs: {
                                  title:
                                    "注意：撤销操作后，需要在企得宝订单审核前，将此订单作废。",
                                  placement: "left",
                                },
                                on: {
                                  onConfirm: function onConfirm($event) {
                                    return _vm.handleRevoke(row)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      type: "text",
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v(" 撤销 ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit,
            },
            on: {
              "update:page": function updatePage($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function updateLimit($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "卖家备注",
            visible: _vm.dialogAddNote,
            width: "40%",
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.dialogAddNote = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              placeholder: "最多可输入256个字",
              maxlength: "256",
              "show-word-limit": "",
            },
            model: {
              value: _vm.note_form.remark,
              callback: function callback($$v) {
                _vm.$set(_vm.note_form, "remark", $$v)
              },
              expression: "note_form.remark",
            },
          }),
          _c(
            "div",
            {
              attrs: {
                slot: "footer",
              },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "text",
                  },
                  on: {
                    click: function click($event) {
                      _vm.dialogAddNote = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                  },
                  on: {
                    click: _vm.handleAddNoteConfirm,
                  },
                },
                [_vm._v(" 确定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "订单发货",
            visible: _vm.dialogDispatchOrder,
            top: "5vh",
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.dialogDispatchOrder = $event
            },
          },
        },
        [
          _c("dispatch-order", {
            attrs: {
              "goods-data": _vm.currentGoodsData,
            },
            on: {
              handleDelivery: _vm.handleDelivery,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }