<template>
  <div>
    <!-- <el-alert title="仅支持修改3次，请务必在买家知情且同意下修改收货信息。" type="warning" show-icon :closable="false"> </el-alert> -->
    <el-form ref="form" :model="form" :rules="formRules" label-width="90px" style="margin-top: 30px">
      <el-form-item label="收货人" prop="receiver">
        <el-input v-model="form.receiver" />
      </el-form-item>
      <el-form-item label="联系电话" prop="phone">
        <el-input v-model="form.phone" />
      </el-form-item>
      <el-form-item label="联系地址" prop="cities">
        <el-cascader v-model="selected_city" :options="optionsCity" style="width: 100%" @change="handleCityChange" />
      </el-form-item>
      <el-form-item label="" prop="receive_address">
        <el-input v-model="form.receive_address" type="textarea" placeholder="请填写详细地址" />
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button type="text" @click="handleCancel"> 取消 </el-button>
      <el-button type="primary" @click="handleConfirm"> 确定 </el-button>
    </div>
  </div>
</template>

<script>
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
export default {
  props: {
    orderNo: {
      type: String,
      default: "",
    },
    detailData: {},
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let citiesValidator = (rule, value, callback) => {
      if (this.selected_city.length == 0) {
        callback(new Error("请填写详细地址"));
      } else {
        callback();
      }
    };
    return {
      form: {
        city: "",
        county: "",
        // order_no: this.orderNo,
        province: "",
        phone: this.detailData.phone,
        receiver: this.detailData.receiver,
        receive_address: this.detailData.receive_address,
      },
      formRules: {
        receiver: [{ required: true, message: "请填写收货人" }],
        phone: [{ required: true, message: "请填写联系电话" }],
        cities: [{ required: true, validator: citiesValidator }],
        receive_address: [{ required: true, message: "请填写详细地址" }],
      },
      optionsCity: regionData,
      selected_city: [],
    };
  },
  watch: {
    detailData: function (val) {
      console.log(val);
      this.fillAddress();
      // this.form.receiver = val.receiver;
      // this.form.phone = val.phone;
      // this.form.receive_address = val.receive_address;
    },
  },
  mounted() {
    this.fillAddress();
  },
  methods: {
    handleCancel() {
      this.$emit("closed", "cancel");
    },
    handleConfirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let api = this.isUpdate ? "updateAddress" : "activate";
          this.form.regular_order_no = this.orderNo;
          this.axios.post(`/regular_goods/${api}`, { ...this.form }).then(() => {
            this.$emit("closed", "confirm");
            this.$refs.form.resetFields();
            this.selected_city = [];
          });
        }
      });
    },
    handleCityChange(val) {
      let { form } = this;
      let area_arr = [];
      val.forEach((item) => {
        area_arr.push(CodeToText[item]);
      });
      form.province = area_arr[0];
      form.city = area_arr[1];
      form.county = area_arr[2];
    },

    fillAddress() {
      let { province, city, county, receiver_name, receiver_phone, receiver_address } = this.detailData;
      let arr = [];
      arr.push(TextToCode[province].code, TextToCode[province][city].code, TextToCode[province][city][county].code);
      this.selected_city = arr;
      this.form.province = province;
      this.form.city = city;
      this.form.county = county;

      if (this.isUpdate) {
        this.form.receiver = receiver_name;
        this.form.phone = receiver_phone;
        this.form.receive_address = receiver_address;
      }
    },
  },
};
</script>

<style></style>
