import { useFetch } from "./useFetch";
import { ref, watchEffect } from "vue";
import { until } from "@vueuse/shared";

export function useTable(api, options) {
  const total = ref(0);
  const page = ref(1);
  const limit = ref(20);
  const listLoading = ref(false);
  const isFinished = ref(false);
  const searchForm = ref({});
  const listData = ref([]);

  function getList() {
    const { data, isFetching, isFinished: _isFinished } = useFetch(api, options);
    listLoading.value = isFetching;
    isFinished.value = _isFinished;

    watchEffect(() => {
      if (data.value) {
        ({ total: total.value, list: listData.value } = data.value || {});
      }
    });
  }

  const shell = {
    total,
    page,
    limit,
    listLoading,
    isFinished,
    searchForm,
    listData,
    getList,
  };

  function waitUntilFinished() {
    return new Promise((resolve, reject) => {
      until(isFinished)
        .toBe(true)
        .then(() => resolve(shell))
        .catch((error) => reject(error));
    });
  }

  return {
    ...shell,
    then(onFulfilled, onRejected) {
      return waitUntilFinished().then(onFulfilled, onRejected);
    },
  };
}
