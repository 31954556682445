<template>
  <div class="page-header flex justify-between py-[10px] items-center">
    <div class="text-[20px] text-[#303133] leading-[22px]">{{ title }}</div>
    <slot name="right"> </slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  border-bottom: 1px solid #dcdfe6;
}
</style>
