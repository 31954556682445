<template>
  <div class="container flex items-center justify-center">
    <div style="height: 100px" />
  </div>
</template>

<script>
import { commonUtil } from "@/utils";

const { to } = commonUtil;
export default {
  name: "LoadingVue",
  mounted() {
    this.loginByJwt();
  },
  methods: {
    async loginByJwt() {
      const [jwtError] = await to(this.$store.dispatch("user/handleHumanResourceLogin", this.$route.query.jwt));
      const [, auth_info] = await this.$store.dispatch("user/getInfo");
      const accessRoutes = await this.$store.dispatch("permission/generateRoutes", auth_info.roles ?? ["admin"]);
      this.$router.addRoutes(accessRoutes);

      if (!jwtError) return this.$router.push(this.$route.query.redirect);
      await to(
        this.$confirm("自动登录出错", "提示", {
          showClose: false,
          showCancelButton: false,
          closeOnClickModal: false,
          confirmButtonText: "确定",
          type: "error",
        })
      );
      window.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  ::v-deep .el-loading-spinner {
    position: relative;
    top: 45%;
    &::after {
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-60%);
      content: "加载中...";
    }
  }
  .loading-text {
    color: #fafafa;
  }
}
</style>
