<template>
  <div class="page-padding">
    <div>
      <div class="page-header--wrap">
        <div class="page-header-wrap--title">发票管理</div>
      </div>
      <el-row class="page-filter--wrap">
        <div class="page-color--normal">日期</div>
        <el-date-picker
          v-model="order_time"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
          unlink-panels
          @change="handleDateChange"
        />

        <!-- <div class="page-color--normal">商品名称</div>
        <el-input v-model="searchForm.name" clearable placeholder="请输入商品名称" style="width: 150px"></el-input> -->
        <div class="page-color--normal">订单编号</div>
        <el-input v-model="searchForm.order_no" clearable placeholder="请输入订单编号" class="page-select--item" style="width: 150px" />
        <div class="page-color--normal">开票状态</div>
        <el-select v-model="searchForm.bill_status" placeholder="全部" clearable style="width: 150px">
          <el-option label="未开票" value="UPOPEN" />
          <el-option label="已开票" value="OPEN" />
          <el-option label="已拒绝" value="REJECT" />
        </el-select>
        <div style="margin: 0 20px">
          <el-button round icon="el-icon-refresh" @click="resetSearchForm"> 重置 </el-button>
          <el-button type="primary" round icon="el-icon-search" @click="getList"> 搜索 </el-button>
          <el-button type="success" round icon="el-icon-download" :loading="downloadingOrder" style="margin-left: 10px" @click="exportFile()">
            导出文件
          </el-button>
        </div>
      </el-row>

      <el-table v-loading="listLoading" :data="billData" stripe border style="width: 100%">
        <el-table-column type="selection" width="55" align="center" class-name="selection-class" />
        <el-table-column label="操作" align="center">
          <template slot-scope="{ row }">
            <el-button :disabled="row.bill_status != 'UPOPEN'" type="text" @click="handleBill(row, 'open')"> 开票 </el-button>
            <el-button :disabled="row.bill_status != 'UPOPEN'" type="text" @click="handleBill(row, 'reject')"> 拒绝 </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="create_bill_time" align="center" label="创建时间" />
        <el-table-column prop="bill_status" align="center" label="开票状态">
          <template slot-scope="{ row }">
            {{ row.bill_status | billStatesText }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="订单编号" prop="order_no" />
        <el-table-column align="center" label="用户名/手机">
          <template slot-scope="{ row }">
            <div>
              <div>{{ row.people_name }}</div>
              <div>{{ row.people_phone }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="bill_type" align="center" label="发票类型" />
        <el-table-column align="center" label="发票金额">
          <template slot-scope="{ row }">
            <div>￥{{ row.big_decimal }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="发票抬头" prop="bill_head" />
        <el-table-column align="center" label="公司抬头" prop="company_head" />
        <el-table-column align="center" label="识别号" prop="identification_number" />
        <el-table-column align="center" label="邮箱地址" prop="email" />
        <el-table-column prop="remark" align="center" label="备注" show-overflow-tooltip />
      </el-table>

      <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import TableListMixin from "@/mixin/TableList";
import { downloadInvoice } from "@/api/report_center/download";
import { downloadBlobFile, filterEmptyProp } from "@/utils";
export default {
  name: "InvoiceManage",
  components: { Pagination },
  filters: {
    billStatesText(val) {
      let map = {
        UPOPEN: "未开票",
        OPEN: "已开票",
        REJECT: "已拒绝",
      };
      return map[val];
    },
  },
  mixins: [TableListMixin],
  data() {
    return {
      downloadingOrder: false,
      billData: [],
      order_time: [],
      searchForm: {},
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 根据查询条件导出Excel文件
    exportFile() {
      this.downloadingOrder = true;
      const params = filterEmptyProp(this.searchForm);

      downloadInvoice(params)
        .then((res) => {
          downloadBlobFile({
            obj: res.data,
            name: "发票文件",
            suffix: "xlsx",
          });
        })
        .finally(() => {
          this.downloadingOrder = false;
        });
    },
    getList() {
      // 获取数据
      this.getListMixin("/regular_goods/openBillList").then((res) => {
        this.billData = res.ndata;
      });
    },
    handleDateChange(val) {
      this.searchForm.start_time = val ? val[0] : "";
      this.searchForm.end_time = val ? val[1] : "";
    },
    handleBill(row, type) {
      // type 1是开票2是拒绝
      type = type == "open" ? 1 : 2;
      this.axios.get(`/regular_goods/openBillList/${row.order_no}/${type}`);
      this.$message.success("操作成功");
      this.getList();
    },
  },
};
</script>

<style></style>
