import Layout from "@/layout";

const settingsRouter = {
  path: "/settings",
  component: Layout,
  redirect: "/settings/pickup_address_manage",
  name: "settings",
  meta: {
    title: "设置中心",
    icon: "system",
    roles: ["admin"],
  },
  children: [
    {
      path: "pickup_address_manage",
      name: "PickupAddressManage",
      component: () => import("@/views/settings-manage/pickup-address-manage"),
      meta: { title: "自提地址管理" },
    },
    {
      path: "trade_setting",
      name: "TradeSetting",
      component: () => import("@/views/settings-manage/trade-setting"),
      meta: { title: "交易设置" },
    },
    {
      path: "return_info",
      name: "ReturnInfo",
      component: () => import("@/views/settings-manage/return-info"),
      meta: { title: "退货信息" },
    },
    {
      path: "delivery_manage",
      name: "DeliveryManage",
      component: () => import("@/views/mall-manage/delivery-manage"),
      meta: { title: "配送管理" },
    },
    {
      path: "order_push",
      name: "OrderPush",
      component: () => import("@/views/settings-manage/order-push"),
      meta: { title: "推送管理" },
    },
  ],
};

export default settingsRouter;
