<template>
  <el-input v-bind="$attrs" v-model="inputValue" v-on="$listeners"></el-input>
</template>

<script>
export default {
  name: "BatchInput",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      inputValue: "",
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.inputValue;
      },
      set(val) {
        this.inputValue = val;
        this.$emit("input", val);
      },
    },
  },
  methods: {},
};
</script>

<style></style>
