import Layout from "@/layout";

const shopperRouter = {
  path: "/shopper",
  component: Layout,
  name: "shopper",
  redirect: "/shopper/member",
  meta: {
    title: "会员管理",
    icon: "mall-vip",
    affix: true,
    roles: ["admin"],
  },
  children: [
    {
      path: "member",
      name: "MallMember",
      component: () => import("@/views/member-manage/index"),
      meta: { title: "会员管理" },
    },
    {
      path: "member_detail",
      name: "MemberDetail",
      hidden: true,
      component: () => import("@/views/member-manage/MemberDetail"),
      meta: { title: "会员详情", activeMenu: "/shopper/member" },
    },
  ],
};

export default shopperRouter;
