<template>
  <div>
    <el-form ref="form" :rules="formRule" :model="form" label-width="200px">
      <el-row>
        <div class="panel-item" style="margin-top: 20px">交易设置</div>
        <el-col :span="18">
          <el-form-item label="待付款订单取消时间：" prop="time_in_minute_to_trigger_auto_cancel_order">
            拍下后
            <el-input v-model.number="form.time_in_minute_to_trigger_auto_cancel_order" style="width: 80px" /> 分钟未付款，自动取消订单
          </el-form-item>
          <el-form-item label="发货后自动确认收货时间：">
            发货后 <el-input v-model.number="form.days_to_convert_delivering_to_received" style="width: 80px" /> 天，自动确认收货
          </el-form-item>
          <el-form-item
            v-is-malls="[MALL_CODE_VALUE_MAP.jiumaojiu_mall, MALL_CODE_VALUE_MAP.song_points_mall, MALL_CODE_VALUE_MAP.mountain_outside_mall]"
            label="订单可申请售后时间："
          >
            订单发货后 <el-input v-model.number="form.after_sale_period_days" style="width: 80px" /> 天内
          </el-form-item>
          <el-form-item label="退款是否返还库存：">
            <el-radio-group v-model="form.increase_stock_after_after_sale_completed">
              <el-radio :label="true"> 是 </el-radio>
              <el-radio :label="false"> 否 </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="当前隐私政策版本号" prop="time_in_minute_to_trigger_auto_cancel_order">
            <el-input v-model.number="form.version" style="width: 80px" /> （注意：隐私政策版本号更新后，商城会重新弹窗提示用户同意）
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <el-form-item style="text-align: center">
            <el-button type="primary" @click="handelFormSubmit"> 确定 </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import api from "@/api";
import { MALL } from "@/constants";

const { MALL_CODE_VALUE_MAP } = MALL;
export default {
  name: "TradeSetting",
  data() {
    return {
      MALL_CODE_VALUE_MAP,
      form: {
        time_in_minute_to_trigger_auto_cancel_order: "",
        days_to_convert_delivering_to_received: "",
        after_sale_period_days: "",
        increase_stock_after_after_sale_completed: true,
      },
      formRule: {
        time_in_minute_to_trigger_auto_cancel_order: [{ required: true, message: "请填写待付款订单取消时间" }],
      },
    };
  },
  created() {
    this.getConfig();
  },
  methods: {
    async getConfig() {
      const [, res] = await api.settings.transactionRelatedConfig();
      this.form = res ?? {};
    },
    async handelFormSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      const [err] = await api.settings.transactionRelatedConfig({
        method: "put",
        data: this.form,
      });
      if (!err) this.$message.success("操作成功");
    },
  },
};
</script>

<style></style>
