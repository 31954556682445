//
export const express_options = [
  {
    label: "顺丰",
    value: "sf",
  },
  {
    label: "EMS",
    value: "ems",
  },
  {
    label: "申通",
    value: "sto",
  },
  {
    label: "圆通",
    value: "yt",
  },
  {
    label: "韵达",
    value: "yd",
  },
  {
    label: "汇通（百世快递）",
    value: "ht",
  },
  {
    label: "安能",
    value: "anneng",
  },
  {
    label: "京东",
    value: "jd",
  },
  //
];
