<template>
  <div style="background: #fff" class="page-padding">
    <sticky :z-index="20">
      <el-page-header content="售后详情" class="go-back" @back="goBack" />
    </sticky>
    <div v-loading="detailLoading">
      <div class="flex sales-title" style="border: none">
        <div class="main-txt">
          {{ AFTER_SALE_TICKET_STATUS_VALUE_DIC[order_data.status] }}
        </div>
        <div v-if="isCounting" class="info-txt">
          （请在 <span class="time-txt">{{ countdown }} </span>内处理，逾期未处理，退款自动撤销 ）
        </div>
      </div>

      <div class="panel-item flex" style="justify-content: space-between; padding-right: 10px">
        <div>售后编号：{{ order_data.ticket_number }}</div>
        <div>
          申请人：<span>{{ order_data.requester }}</span> | 申请时间：{{ order_data.create_time }}
        </div>
      </div>

      <el-card>
        <el-form class="card">
          <el-row>
            <el-col :span="8">
              <h5>售后商品</h5>
              <div class="flex-center" style="justify-content: start">
                <el-image
                  style="width: 80px; height: 80px; margin-right: 10px"
                  :src="related_order_line_info.goods_sku_image_address"
                  fit="contain"
                />
                <div :title="related_order_line_info.goods_name" class="good-name">
                  {{ related_order_line_info.goods_name }}
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <h5>售后信息</h5>
              <el-form-item label="售后状态：">
                <div>{{ AFTER_SALE_TICKET_STATUS_VALUE_DIC[order_data.status] }}</div>
              </el-form-item>
              <el-form-item label="退款金额：">
                <div>{{ formatUnitPrice({ price: order_data.refund_amount, point: order_data.refund_points }) }}</div>
              </el-form-item>
              <el-form-item label="买家手机：">
                <div>{{ order_data.requester_mobile_number }}</div>
              </el-form-item>
              <el-form-item label="申请人手机：">
                <div>{{ order_data.contactor_mobile_number }}</div>
              </el-form-item>
              <el-form-item label="退款原因：">
                <div>{{ order_data.reason }}</div>
              </el-form-item>
              <el-form-item label="退款说明：">
                <div>{{ order_data.additional_description }}</div>
              </el-form-item>
              <el-form-item v-if="!!order_data?.evidence_images?.length" label="申请凭证：">
                <div class="flex-margin-top">
                  <el-image
                    v-for="(jitem, index) in order_data.evidence_images"
                    :key="index"
                    class="imgSty"
                    :src="jitem"
                    :preview-src-list="order_data.evidence_images"
                  />
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <h5>购买信息</h5>
              <el-form-item label="商品单价：">
                <div>
                  {{ formatUnitPrice({ price: related_order_line_info.goods_sku_unit_price, point: related_order_line_info.goods_sku_unit_points }) }}
                </div>
              </el-form-item>
              <el-form-item label="订单金额：">
                <div>
                  {{
                    formatUnitPrice({
                      price: related_order_line_info.goods_sku_unit_price * order_data.number_of_after_sale_goods_sku,
                      point: related_order_line_info.goods_sku_unit_points * order_data.number_of_after_sale_goods_sku,
                    })
                  }}
                </div>
              </el-form-item>
              <!-- <el-form-item label="实付金额：">
                <div>￥{{ order_data.received_amount }}</div>
              </el-form-item> -->
              <el-form-item label="发货件数：">
                <div>{{ order_data.number_of_after_sale_goods_sku }}件</div>
              </el-form-item>
              <el-form-item label="发货状态：">
                <div>{{ related_order_line_info.delivery_status_chinese_representation }}</div>
              </el-form-item>
              <el-form-item label="订单编号：">
                <el-button type="text" @click="handleOrderDetail(related_order_line_info.related_order_id)">
                  {{ related_order_line_info.related_order_number }}
                  <!-- <span style="color: #e6a23c;">（已申请发货，待物流公司返回运单号）</span> -->
                </el-button>
              </el-form-item>
              <el-form-item label="超时时间：">
                <div>{{ order_data.process_deadline }}</div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>

      <div class="panel-item" style="margin-top: 20px">协商记录</div>
      <el-row>
        <el-col>
          <el-form class="card">
            <el-timeline class="my-timeline">
              <el-timeline-item
                v-for="(logInfo, index) in order_data.operation_log_list"
                :key="index"
                size="large"
                :timestamp="logInfo.time"
                :icon="logInfo.operator_role | roleToClass"
                placement="top"
              >
                <el-card>
                  <h4>{{ AFTER_SALE_OPERATION_VALUE_DIC[logInfo.operation] }}</h4>
                  <el-form-item v-if="logInfo.express_company" label="物流名称：">
                    {{ logInfo.express_company }}
                  </el-form-item>
                  <el-form-item v-if="logInfo.logistics_order_no" label="物流编号：">
                    {{ logInfo.logistics_order_no }}
                  </el-form-item>
                  <div v-if="logInfo.additional_description && logInfo.role === '商家'">
                    <el-form-item v-if="logInfo.role === '买家'" label="退款说明：">
                      {{ logInfo.additional_description }}
                    </el-form-item>
                  </div>
                  <el-form-item v-if="!!logInfo?.after_sale_info?.after_sale_type" label="售后方式：">
                    {{ AFTER_SALE_TYPE_VALUE_DIC[logInfo.after_sale_info.after_sale_type] }}
                  </el-form-item>
                  <el-form-item v-if="!!logInfo?.operation_reason" label="拒绝原因：">
                    {{ logInfo.operation_reason }}
                  </el-form-item>
                  <el-form-item v-if="!!logInfo?.after_sale_info?.after_sale_reason" label="退款原因：">
                    {{ logInfo.after_sale_info.after_sale_reason }}
                  </el-form-item>
                  <el-form-item v-if="!!logInfo?.after_sale_info?.refund_amount" label="退款金额：">
                    {{ formatUnit(logInfo.after_sale_info.refund_amount, "MONEY") }}
                  </el-form-item>
                  <el-form-item v-if="!!logInfo?.after_sale_info?.refund_points" label="退款积分：">
                    {{ formatUnit(logInfo.after_sale_info.refund_points, "POINTS") }}
                  </el-form-item>
                  <el-form-item v-if="!!logInfo?.after_sale_info?.number_of_return_goods_sku" label="退货数量：">
                    {{ logInfo.after_sale_info.number_of_return_goods_sku }}
                  </el-form-item>
                  <el-form-item v-if="!!logInfo?.after_sale_info?.contactor_mobile_number" label="联系方式：">
                    {{ logInfo.after_sale_info.contactor_mobile_number }}
                  </el-form-item>

                  <el-form-item v-if="!!logInfo?.return_goods_logistics_info" label="物流名称：">
                    {{ logInfo.return_goods_logistics_info.logistics_company_name || "-" }}
                  </el-form-item>
                  <el-form-item v-if="!!logInfo?.return_goods_logistics_info" label="物流编号：">
                    {{ logInfo.return_goods_logistics_info.tracking_number || "-" }}
                  </el-form-item>
                  <el-form-item v-if="!!logInfo?.return_goods_logistics_info?.additional_description" label="退款说明：">
                    {{ logInfo.return_goods_logistics_info.additional_description || "-" }}
                  </el-form-item>
                  <el-form-item v-if="!!logInfo?.return_goods_logistics_info?.contactor_mobile_number" label="联系电话：">
                    {{ logInfo.return_goods_logistics_info.contactor_mobile_number || "-" }}
                  </el-form-item>
                  <el-form-item v-if="!!logInfo?.return_goods_logistics_info?.evidence_image_list?.length" label="申请凭证：">
                    <div class="flex-margin-top">
                      <el-image
                        v-for="(jitem, jindex) in logInfo.return_goods_logistics_info.evidence_image_list"
                        :key="jindex"
                        class="imgSty"
                        :src="jitem"
                        :preview-src-list="logInfo.return_goods_logistics_info.evidence_image_list"
                      />
                    </div>
                  </el-form-item>
                  <el-form-item v-if="!!logInfo?.express_img_urls?.length" label="买家上传凭证：">
                    <div v-if="logInfo.role === '买家'" class="flex-margin-top">
                      <el-image
                        v-for="(jitem, jindex) in logInfo.express_img_urls"
                        :key="jindex"
                        class="imgSty"
                        :src="jitem"
                        :preview-src-list="logInfo.express_img_urls"
                      />
                    </div>
                  </el-form-item>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </el-form>
        </el-col>
      </el-row>

      <!-- <div v-if="order_data.role == '商家'" style="text-align: center; padding: 80px;"> -->
      <div
        v-if="[AFTER_SALE_TICKET_STATUS_VALUE_MAP['REQUESTED'], AFTER_SALE_TICKET_STATUS_VALUE_MAP['RETURNING_GOODS']].includes(order_data.status)"
        style="text-align: center; padding: 80px"
      >
        <!-- refund_state == 'BUYER_RETURNGOODS特殊处理 -->
        <div v-if="order_data.status === AFTER_SALE_TICKET_STATUS_VALUE_MAP['RETURNING_GOODS']">
          <el-button @click="showRefuseDialog"> 拒绝收货 </el-button>
          <el-button type="primary" @click="showAgreeDialog"> 同意收货 </el-button>
        </div>
        <div v-else>
          <el-button @click="showRefuseDialog"> 拒绝{{ AFTER_SALE_TYPE_VALUE_DIC[order_data.after_sale_type] }} </el-button>
          <el-button type="primary" @click="showAgreeDialog"> 同意{{ AFTER_SALE_TYPE_VALUE_DIC[order_data.after_sale_type] }} </el-button>
        </div>
      </div>
    </div>

    <el-dialog title="售后维权处理" :visible.sync="dialogRefuseVisible" width="600px">
      <el-form class="card" label-width="100px">
        <el-alert
          title="建议你与买家协商后，再确定是否拒绝退款。如你拒绝退款后，买家可修改退款申请协议重新发起退款。也可以直接发起维权申请，将会由客服介入处理。"
          type="warning"
          show-icon
          :closable="false"
        />
        <el-form-item label="售后方式：">
          {{ AFTER_SALE_TYPE_VALUE_DIC[order_data.after_sale_type] }}
        </el-form-item>
        <el-form-item label="售后状态：">
          {{ AFTER_SALE_TICKET_STATUS_VALUE_DIC[order_data.status] }}
        </el-form-item>
        <el-form-item v-if="order_data.refund_amount" label="退款金额：">
          <span style="color: #f56c6c">{{ formatUnit(order_data.refund_amount, "MONEY") }}</span>
        </el-form-item>
        <el-form-item v-if="order_data.refund_points" label="退款积分：">
          <span style="color: #f56c6c">{{ formatUnit(order_data.refund_points, "POINTS") }}</span>
        </el-form-item>
        <el-form-item label="拒绝理由：">
          <el-input
            v-model="processAfterSaleForm.operation_reason"
            type="textarea"
            placeholder="请填写你的拒绝理由，不超过200个字"
            maxlength="200"
            show-word-limit
          />
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button type="text" @click="dialogRefuseVisible = false"> 取消 </el-button>
        <el-button
          v-if="order_data.status === AFTER_SALE_TICKET_STATUS_VALUE_MAP['REQUESTED']"
          type="primary"
          :loading="isBtnLoading"
          @click="handleProcessAfterSaleRequest(false)"
        >
          拒绝
        </el-button>
        <template v-else>
          <el-button
            v-if="[AFTER_SALE_TICKET_STATUS_VALUE_MAP['RETURNING_GOODS']].includes(order_data.status)"
            type="primary"
            :loading="isBtnLoading"
            @click="handleRefuseReturnGoods"
            >拒绝退货退款</el-button
          >
          <el-button v-else type="primary" :loading="isBtnLoading" @click="handleReceivedBtn('refuse')">
            {{ order_data.refund_type_str | rejectBtnStr }}
          </el-button>
        </template>
      </div>
    </el-dialog>

    <el-dialog title="售后维权处理" :visible.sync="dialogAgreeVisible" width="600px">
      <el-form class="card" label-width="100px">
        <el-alert title="该笔订单通过微信支付付款，确认退款后，退款将自动原路退回买家付款账户。" type="warning" show-icon :closable="false" />
        <el-form-item label="售后方式：">
          {{ AFTER_SALE_TYPE_VALUE_DIC[order_data.after_sale_type] }}
        </el-form-item>
        <el-form-item label="售后状态：">
          {{ AFTER_SALE_TICKET_STATUS_VALUE_DIC[order_data.status] }}
        </el-form-item>
        <el-form-item v-if="order_data.refund_amount" label="退款金额：">
          <span style="color: #f56c6c">{{ formatUnit(order_data.refund_amount, "MONEY") }}</span>
        </el-form-item>
        <el-form-item v-if="order_data.refund_points" label="退款积分：">
          <span style="color: #f56c6c">{{ formatUnit(order_data.refund_points, "POINTS") }}</span>
        </el-form-item>
        <el-form-item v-if="AFTER_SALE_TYPE_VALUE_MAP['REFUND_AND_RETURN_GOODS'] === order_data.after_sale_type" label="退货地址：">
          {{ returnGoodsAddress }}
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button type="text" @click="dialogAgreeVisible = false"> 取消 </el-button>
        <el-button
          v-if="order_data.status === AFTER_SALE_TICKET_STATUS_VALUE_MAP['REQUESTED']"
          type="primary"
          :loading="isBtnLoading"
          @click="handleProcessAfterSaleRequest(true)"
        >
          同意
        </el-button>
        <template v-else>
          <el-button
            v-if="[AFTER_SALE_TICKET_STATUS_VALUE_MAP['RETURNING_GOODS']].includes(order_data.status)"
            type="primary"
            :loading="isBtnLoading"
            @click="handleApproveReturnGoods"
          >
            同意收货
          </el-button>
          <el-button v-else type="primary" :loading="isBtnLoading" @click="handleReceivedBtn('confirm')">
            {{ order_data.refund_type_str | agreeBtnStr }}
          </el-button>
        </template>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Sticky from "@/components/Sticky";
import "@/assets/iconfont/iconfont.css";
import api from "@/api";
import { commonUtil } from "@/utils";
import { GOODS } from "@/constants";

const { to } = commonUtil;
var timer = null;

const {
  AFTER_SALE_TICKET_STATUS_VALUE_DIC,
  AFTER_SALE_TICKET_STATUS_VALUE_MAP,
  AFTER_SALE_OPERATION_VALUE_DIC,
  AFTER_SALE_TYPE_VALUE_DIC,
  AFTER_SALE_TYPE_VALUE_MAP,
  PAY_TYPE_VALUE_MAP,
} = GOODS;
export default {
  components: { Sticky },
  filters: {
    roleToClass: function (val) {
      // TODO 查iconfont怎么用字体icon
      let map = {
        MERCHANT: "iconfont my-icon-shang",
        BUYER: "iconfont my-icon-mai",
      };
      return map[val];
    },
    rejectBtnStr: function (val) {
      let map = {
        退款: "拒绝仅退款",
        退货: "拒绝退货",
      };
      return map[val];
    },
    agreeBtnStr: function (val) {
      let map = {
        退款: "同意仅退款",
        退货: "同意退货且发送退货地址",
      };
      return map[val];
    },
  },
  data() {
    return {
      AFTER_SALE_TICKET_STATUS_VALUE_DIC,
      AFTER_SALE_TICKET_STATUS_VALUE_MAP,
      AFTER_SALE_OPERATION_VALUE_DIC,
      AFTER_SALE_TYPE_VALUE_DIC,
      AFTER_SALE_TYPE_VALUE_MAP,
      isCounting: false,
      isBtnLoading: false,
      detailLoading: false,
      dialogRefuseVisible: false,
      dialogAgreeVisible: false,
      ticketNumber: "",
      processAfterSaleForm: {
        agree: false,
        operation_reason: "",
      },
      order_data: {},
      related_order_line_info: {},
      countdown: "",
    };
  },
  computed: {
    returnGoodsAddress() {
      return this.order_data?.return_goods_info?.return_goods_address;
    },
  },
  activated() {
    this.ticketNumber = this.$route.query.ticketNumber;
    this.getAfterSalesDetail(this.ticketNumber);
  },
  created() {
    this.ticketNumber = this.$route.query.ticketNumber;
    this.getAfterSalesDetail();
  },
  destroyed() {
    clearTimeout(timer);
  },
  methods: {
    formatUnit(amount, unit) {
      switch (unit) {
        case PAY_TYPE_VALUE_MAP["POINTS"]:
          return `${amount}积分`;
        case PAY_TYPE_VALUE_MAP["MONEY"]:
          return `￥${amount}`;
        default:
          return `￥${amount}`;
      }
    },
    handleOrderDetail(orderId) {
      this.$router.push({ path: "/order/order_detail", query: { orderId: orderId, source: "after_sales" } });
    },
    handleReceivedBtn() {
      this.isBtnLoading = true;
      // param == "refuse" ? (param = "/afterSales/sellerNotReceivedGoods") : (param = "/afterSales/sellerReceivedGoods");
      // this.axios.get(`${param}?ticket_number=${this.order_data.after_sale_no}`).then(() => {
      //   setTimeout(() => {
      //     this.dialogRefuseVisible = false;
      //     this.dialogAgreeVisible = false;
      //     this.processAfterSaleForm.operation_reason = "";
      //     this.isBtnLoading = false;
      //     this.getAfterSalesDetail(this.ticketNumber);
      //   }, 3000);
      // });
    },
    async handleApproveReturnGoods() {
      const [err] = await api.order.afterSaleTickets({
        method: "post",
        id: this.ticketNumber,
        resource: "approveReturnGoods",
        data: {
          operation_reason: this.processAfterSaleForm.operation_reason,
        },
      });
      !err && to(this.getAfterSalesDetail());
      this.dialogAgreeVisible = false;
    },
    async handleRefuseReturnGoods() {
      const [err] = await api.order.afterSaleTickets({
        method: "post",
        id: this.ticketNumber,
        resource: "refuseReturnGoods",
        data: {
          operation_reason: this.processAfterSaleForm.operation_reason,
        },
      });
      !err && to(this.getAfterSalesDetail());
      this.dialogRefuseVisible = false;
    },
    async handleProcessAfterSaleRequest(isAgree) {
      this.isBtnLoading = true;
      this.processAfterSaleForm.agree = isAgree;
      const [err] = await api.order.afterSaleTickets({
        method: "post",
        id: this.ticketNumber,
        resource: "processAfterSaleRequest",
        data: this.processAfterSaleForm,
      });
      this.isBtnLoading = false;
      if (err) return;
      this.$notify.success("操作成功");
      to(this.getAfterSalesDetail());
      this.dialogRefuseVisible = false;
      this.dialogAgreeVisible = false;
    },
    countTime() {
      //时间差
      this.isCounting = true;
      let now = new Date().getTime();
      let end = new Date(this.order_data.process_deadline).getTime();

      let leftTime = end - now;
      let d, h, m, s;
      let timerCount = setInterval(() => {
        leftTime -= 1000;
        if (leftTime <= 0) {
          clearInterval(timerCount);
          this.isCounting = false;
        } else if (leftTime > 0) {
          d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
          h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
          m = Math.floor((leftTime / 1000 / 60) % 60);
          s = Math.floor((leftTime / 1000) % 60);
          this.countdown = d + " 天 " + h + " 时 " + m + " 分 " + s + " 秒 ";
        }
      }, 1000);
    },

    // 自动退款
    autoRefund() {
      let { id, after_sale_no, role } = this.order_data;
      let api = "";
      if (role == "商家") api = "/afterSales/sellerOverTime";
      if (role == "买家") api = "/afterSales/buyerOverTime";
      this.axios.post(`${api}`, { after_sale_no, id }).then(() => {
        this.getAfterSalesDetail(this.ticketNumber);
      });
    },
    async getAfterSalesDetail() {
      this.detailLoading = true;
      const [err, afterSaleInfo] = await api.order.afterSaleTickets({
        id: this.ticketNumber,
      });
      if (err) return;
      this.order_data = afterSaleInfo;
      this.related_order_line_info = afterSaleInfo.related_order_line_info;
      this.countTime();
      this.detailLoading = false;
    },
    goBack() {
      this.$router.replace("/order/after_sales");
    },
    showRefuseDialog() {
      this.dialogRefuseVisible = true;
    },
    showAgreeDialog() {
      this.dialogAgreeVisible = true;
    },
    formatUnitPrice({ price, point }) {
      let str = "";
      if (price) str += `￥${price}`;
      if (price && point) str += "+";
      if (point) str += `${point}积分`;
      return str;
    },
  },
};
</script>

<style lang="scss" scoped>
.good-name {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.my-timeline ::v-deep .el-timeline-item__node-- {
  width: 28px;
  height: 28px;
  left: -10px;
}
.my-timeline ::v-deep .iconfont {
  font-size: 28px;
}
.my-timeline ::v-deep .my-icon-mai {
  color: #909399;
}
.my-timeline ::v-deep .my-icon-shang {
  width: inherit;
  color: #409eff;
}
h5 {
  margin: 15px 0;
}
.card ::v-deep .el-form-item {
  margin-bottom: 0;
}
.sales-title {
  margin: 0 0 20px 15px;
  .main-txt {
    font-size: 16px;
    font-weight: bold;
    color: #409eff;
  }
  .info-txt {
    font-size: 14px;
  }
  .time-txt {
    color: #e6a23c;
  }
}
.imgSty {
  width: 80px;
  display: block;
  margin-right: 10px;
}
.flex-margin-top {
  display: flex;
  margin-top: 15px;
}
</style>
