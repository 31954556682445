<template>
  <div class="page-padding">
    <div class="panel-item" style="margin-top: 20px">配送管理</div>

    <div class="header">
      <div>设置禁发地区</div>
      <el-button icon="el-icon-plus" type="primary" @click="handleOpenAddDialog"> 新增 </el-button>
    </div>

    <div class="page-filter--wrap">
      <div class="page-color--normal">地区：</div>
      <div class="page-select--item">
        <el-input v-model="searchForm.keyword" placeholder="请输入省/市/区搜索" />
      </div>
      <el-button round size="mini" icon="el-icon-refresh" @click="resetSearchForm"> 重置 </el-button>
      <el-button type="primary" round size="mini" icon="el-icon-search" @click="getList"> 搜索 </el-button>
    </div>

    <el-table v-loading="listLoading" :data="districtData" stripe border highlight-current-row style="width: 100%">
      <el-table-column type="index" align="center" width="60">
        <template slot="header"> 序号 </template>
      </el-table-column>
      <el-table-column label="操作" width="300" align="center">
        <template slot-scope="{ row }">
          <el-button type="text" @click="handleOpenEditDialog(row)"> 编辑 </el-button>
          <el-popconfirm title="确定删除该数据吗？" style="margin: 0 10px" placement="left" @onConfirm="handleDelete(row)">
            <el-button slot="reference" type="text"> 删除 </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="province" label="省" show-overflow-tooltip />
      <el-table-column align="center" prop="city" label="市" show-overflow-tooltip />
      <el-table-column align="center" prop="district" label="区" show-overflow-tooltip />
      <el-table-column align="center" prop="creator" label="创建人" show-overflow-tooltip />
      <el-table-column align="center" prop="create_time" label="创建时间" show-overflow-tooltip />
      <el-table-column align="center" prop="updater" label="最后修改人" show-overflow-tooltip />
      <el-table-column align="center" prop="update_time" label="最后修改时间" show-overflow-tooltip />
    </el-table>

    <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

    <!-- 新增配送的弹窗 -->
    <el-dialog :title="`${textMap[dialogStatus]}禁发地区`" :visible.sync="dialogDistrictForm" width="500px">
      <el-form ref="districtForm" :model="districtForm" :rules="districtRules" label-width="90px">
        <el-form-item label="禁用地区" prop="cities">
          <!-- https://github.com/ElemeFE/element/issues/19219 用v-if解决回显问题-->
          <el-cascader
            v-if="dialogDistrictForm"
            ref="cities"
            v-model="selected_city"
            size="mini"
            :props="cascadeProps"
            style="width: 100%"
            @change="handleCityChange"
          />
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button type="text" @click="dialogDistrictForm = false"> 取消 </el-button>
        <el-button type="primary" @click="handleDialogConfirm">
          {{ dialogStatus == "update" ? "修改" : "确定" }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api";
import Pagination from "@/components/Pagination";
import TableListMixin from "@/mixin/TableList";
import * as R from "ramda";

const initDistrictForm = {
  id: undefined,
  province: undefined,
  province_code: undefined,
  city: undefined,
  city_code: undefined,
  district: undefined,
  district_code: undefined,
};
export default {
  name: "DeliveryManage",
  components: { Pagination },
  mixins: [TableListMixin],
  props: {
    detailData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    let citiesValidator = (rule, value, callback) => {
      this.selected_city.length ? callback() : callback(new Error("请填写禁用地址"));
    };
    return {
      dialogAuthority: false,
      selected_city: [],
      listLoading: false,
      districtData: [],
      districtForm: R.clone(initDistrictForm),
      districtRules: {
        cities: [{ required: true, validator: citiesValidator }],
      },
      dialogDistrictForm: false,
      searchForm: {
        keyword: undefined,
      },
      cascadeProps: {
        lazy: true,
        label: "name",
        value: "code",
        async lazyLoad(node, resolve) {
          const code = node?.data?.code;
          const [, areaList = []] = await api.settings.area({
            id: code ? code : null,
            resource: "children",
          });
          resolve([{ code: "000000", name: "全部", leaf: true }, ...areaList.map((item) => ({ ...item, leaf: node.level >= 2 }))]);
        },
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleCityChange() {
      const [lastNode] = this.$refs.cities.getCheckedNodes(false);
      const pathsNodes = lastNode.pathNodes;
      let { districtForm } = this;
      const formKeyNames = ["province", "city", "district"];

      for (let i = 0; i <= 2; i++) {
        const node = pathsNodes[i]?.data ?? { name: "全部", code: "000000" };
        const keyName = formKeyNames[i];
        [districtForm[keyName], districtForm[`${keyName}_code`]] = [node.name, node.code];
      }
    },
    handleOpenEditDialog(row) {
      const temp = { ...row };
      this.fillAddress(temp);
      this.dialogStatus = "update";
      this.dialogDistrictForm = true;
      this.$nextTick(() => {
        this.$refs.districtForm.clearValidate();
      });
    },
    handleOpenAddDialog() {
      this.resetFormData();
      this.dialogStatus = "create";
      this.$nextTick(() => {
        this.dialogDistrictForm = true;
      });
    },
    resetFormData() {
      this.districtForm = R.clone(initDistrictForm);
      this.selected_city = [];
      if (this.$refs.cities) {
        // https://github.com/ElemeFE/element/issues/18669
        this.$refs.cities.$refs.panel.checkedValue = [];
        this.$refs.cities.$refs.panel.activePath = [];
        this.$refs.cities.$refs.panel.syncActivePath();
      }
    },
    resetSearchForm() {
      this.searchForm.keyword = undefined;
      this.getList();
    },
    async getList() {
      // 获取数据
      const [, res] = await this.getListMixin(api.goods.notSupportedDeliveryAreas);
      this.districtData = res.list ?? [];
    },

    // 创建和修改 HTTP 请求
    async handleDialogConfirm() {
      let method = this.dialogStatus === "create" ? "post" : "put";

      const isValid = await this.$refs.districtForm.validate();
      if (!isValid) return this.$notify.warning("请填写必填信息");

      const { id, ...data } = this.districtForm;
      const [err] = await api.goods.notSupportedDeliveryAreas({
        method,
        id,
        data,
      });
      if (!err) {
        this.dialogDistrictForm = false;
        this.$notify.success("操作成功");
        await this.getList();
      }
    },
    async handleDelete(row) {
      const [err] = await api.goods.notSupportedDeliveryAreas({
        method: "delete",
        id: row.id,
      });

      if (!err) {
        this.$notify.success("删除成功");
        await this.getList();
      }
    },
    fillAddress(row) {
      let { province_code, city_code, district_code } = row;
      this.selected_city = Array.from(new Set([province_code, city_code, district_code]));
      this.districtForm = R.pick(["province", "province_code", "city", "city_code", "district", "district_code", "id"], row);
    },
  },
};
</script>

<style>
.header {
  display: flex;
  justify-content: space-between;
  margin: 15px auto;
}
</style>
