var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "navbar",
    },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: {
          "is-active": _vm.sidebar.opened,
        },
        on: {
          toggleClick: _vm.toggleSideBar,
        },
      }),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
      }),
      _c(
        "div",
        {
          staticClass: "right-menu",
        },
        [
          _c(
            "el-select",
            {
              ref: "select",
              attrs: {
                placeholder: "",
              },
              on: {
                "visible-change": function visibleChange(e) {
                  var _vm$auth_info$related
                  return _vm.handleClick(
                    e,
                    !(
                      (_vm$auth_info$related = _vm.auth_info.related_malls) !==
                        null &&
                      _vm$auth_info$related !== void 0 &&
                      _vm$auth_info$related.length
                    )
                  )
                },
                change: _vm.handleShopChange,
              },
              model: {
                value: _vm.auth_info.current_mall.code,
                callback: function callback($$v) {
                  _vm.$set(_vm.auth_info.current_mall, "code", $$v)
                },
                expression: "auth_info.current_mall.code",
              },
            },
            _vm._l(_vm.auth_info.related_malls, function (item) {
              return _c("el-option", {
                key: item.name,
                attrs: {
                  label: item.name,
                  value: item.code,
                },
              })
            }),
            1
          ),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container",
              attrs: {
                trigger: "click",
                size: "medium",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "avatar-wrapper",
                  staticStyle: {
                    cursor: "pointer",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-user",
                    staticStyle: {
                      margin: "0 5px",
                    },
                  }),
                  _c("span", [_vm._v(_vm._s(_vm.name))]),
                  _c("i", {
                    staticClass: "el-icon-caret-bottom",
                    staticStyle: {
                      top: "20px",
                    },
                  }),
                ]
              ),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: {
                    slot: "dropdown",
                  },
                  slot: "dropdown",
                },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: "/",
                      },
                    },
                    [_c("el-dropdown-item", [_vm._v(" 首页 ")])],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: {
                        divided: "",
                      },
                      nativeOn: {
                        click: function click($event) {
                          return _vm.logout.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            display: "block",
                          },
                        },
                        [_vm._v("退出登录")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }