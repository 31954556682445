var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "vac-room-header vac-app-border-b",
    },
    [
      _vm._t(
        "room-header",
        function () {
          return [
            _c(
              "div",
              {
                staticClass: "vac-room-wrapper",
              },
              [
                !_vm.singleRoom
                  ? _c(
                      "div",
                      {
                        staticClass: "vac-svg-button vac-toggle-button",
                        class: {
                          "vac-rotate-icon":
                            !_vm.showRoomsList && !_vm.isMobile,
                        },
                        on: {
                          click: function click($event) {
                            return _vm.$emit("toggle-rooms-list")
                          },
                        },
                      },
                      [
                        _vm._t("toggle-icon", function () {
                          return [
                            _c("svg-icon", {
                              attrs: {
                                name: "toggle",
                              },
                            }),
                          ]
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "vac-info-wrapper",
                    class: {
                      "vac-item-clickable": _vm.roomInfo,
                    },
                    on: {
                      click: function click($event) {
                        return _vm.$emit("room-info")
                      },
                    },
                  },
                  [
                    _vm._t(
                      "room-header-avatar",
                      function () {
                        return [
                          _c("div", {
                            staticClass: "vac-avatar",
                            style: {
                              "background-image": "url('".concat(
                                _vm.currentRoom.avatar,
                                "')"
                              ),
                            },
                          }),
                        ]
                      },
                      null,
                      {
                        room: _vm.room,
                      }
                    ),
                    _vm._t(
                      "room-header-info",
                      function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "vac-text-ellipsis",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "vac-room-name vac-text-ellipsis",
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.currentRoom.roomName) + " "
                                  ),
                                ]
                              ),
                              _vm.typingUsers
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vac-room-info vac-text-ellipsis",
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.typingUsers) + " "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vac-room-info vac-text-ellipsis",
                                    },
                                    [_vm._v(" " + _vm._s(_vm.userStatus) + " ")]
                                  ),
                            ]
                          ),
                          _vm.currentChatTab == "notFinish"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                      },
                                      on: {
                                        click: _vm.handleFinishChat,
                                      },
                                    },
                                    [_vm._v(" 退出接待 ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                      null,
                      {
                        room: _vm.room,
                        typingUsers: _vm.typingUsers,
                        userStatus: _vm.userStatus,
                      }
                    ),
                  ],
                  2
                ),
              ]
            ),
          ]
        },
        null,
        {
          room: _vm.room,
          typingUsers: _vm.typingUsers,
          userStatus: _vm.userStatus,
        }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }