var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "transition",
    {
      attrs: {
        name: "vac-slide-up",
      },
    },
    [
      _vm.messageReply
        ? _c(
            "div",
            {
              staticClass: "vac-reply-container",
              style: {
                bottom: "".concat(
                  _vm.$parent.$refs.roomFooter.clientHeight,
                  "px"
                ),
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "vac-reply-box",
                },
                [
                  _vm.isImageFile
                    ? _c("img", {
                        staticClass: "vac-image-reply",
                        attrs: {
                          src: _vm.messageReply.file.url,
                        },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "vac-reply-info",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "vac-reply-username",
                        },
                        [_vm._v(" " + _vm._s(_vm.messageReply.username) + " ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "vac-reply-content",
                        },
                        [
                          _c("format-message", {
                            attrs: {
                              content: _vm.messageReply.content,
                              users: _vm.room.users,
                              "text-formatting": _vm.textFormatting,
                              "link-options": _vm.linkOptions,
                              reply: true,
                            },
                            scopedSlots: _vm._u(
                              [
                                _vm._l(_vm.$scopedSlots, function (i, name) {
                                  return {
                                    key: name,
                                    fn: function fn(data) {
                                      return [_vm._t(name, null, null, data)]
                                    },
                                  }
                                }),
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "vac-icon-reply",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "vac-svg-button",
                      on: {
                        click: function click($event) {
                          return _vm.$emit("reset-message")
                        },
                      },
                    },
                    [
                      _vm._t("reply-close-icon", function () {
                        return [
                          _c("svg-icon", {
                            attrs: {
                              name: "close-outline",
                            },
                          }),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }