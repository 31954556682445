var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "vac-wrapper",
    },
    [
      _c("emoji-picker", {
        attrs: {
          search: _vm.search,
        },
        on: {
          emoji: _vm.append,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "emoji-invoker",
              fn: function fn(_ref) {
                var clickEvent = _ref.events.click
                return _c(
                  "div",
                  {
                    staticClass: "vac-svg-button",
                    class: {
                      "vac-button-reaction": _vm.emojiReaction,
                    },
                    on: {
                      click: [
                        function ($event) {
                          $event.stopPropagation()
                          return clickEvent.apply(null, arguments)
                        },
                        _vm.openEmoji,
                      ],
                    },
                  },
                  [
                    _vm._t("emoji-picker-icon", function () {
                      return [
                        _c("svg-icon", {
                          attrs: {
                            name: "emoji",
                            param: _vm.emojiReaction ? "reaction" : "",
                          },
                        }),
                      ]
                    }),
                  ],
                  2
                )
              },
            },
            {
              key: "emoji-picker",
              fn: function fn(_ref2) {
                var emojis = _ref2.emojis,
                  insert = _ref2.insert
                return _vm.emojiOpened
                  ? _c(
                      "div",
                      {},
                      [
                        _c(
                          "transition",
                          {
                            attrs: {
                              name: "vac-slide-up",
                              appear: "",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "vac-emoji-picker",
                                class: {
                                  "vac-picker-reaction": _vm.emojiReaction,
                                },
                                style: {
                                  height: "".concat(
                                    _vm.emojiPickerHeight,
                                    "px"
                                  ),
                                  top: _vm.positionTop
                                    ? _vm.emojiPickerHeight
                                    : "".concat(_vm.emojiPickerTop, "px"),
                                  right: _vm.emojiPickerRight,
                                  display:
                                    _vm.emojiPickerTop || !_vm.emojiReaction
                                      ? "initial"
                                      : "none",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "vac-emoji-picker__search",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.search,
                                          expression: "search",
                                        },
                                      ],
                                      attrs: {
                                        type: "text",
                                      },
                                      domProps: {
                                        value: _vm.search,
                                      },
                                      on: {
                                        input: function input($event) {
                                          if ($event.target.composing) return
                                          _vm.search = $event.target.value
                                        },
                                      },
                                    }),
                                  ]
                                ),
                                _c(
                                  "div",
                                  _vm._l(
                                    emojis,
                                    function (emojiGroup, category) {
                                      return _c(
                                        "div",
                                        {
                                          key: category,
                                        },
                                        [
                                          category !== "Frequently used"
                                            ? _c("h5", [
                                                _vm._v(
                                                  " " + _vm._s(category) + " "
                                                ),
                                              ])
                                            : _vm._e(),
                                          category !== "Frequently used"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "vac-emojis",
                                                },
                                                _vm._l(
                                                  emojiGroup,
                                                  function (emoji, emojiName) {
                                                    return _c(
                                                      "span",
                                                      {
                                                        key: emojiName,
                                                        attrs: {
                                                          title: emojiName,
                                                        },
                                                        on: {
                                                          click: function click(
                                                            $event
                                                          ) {
                                                            return insert({
                                                              emoji: emoji,
                                                              emojiName:
                                                                emojiName,
                                                            })
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(emoji) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e()
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }