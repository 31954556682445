var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "window-container",
      class: {
        "window-mobile": _vm.isDevice,
      },
    },
    [
      _c("chat-window", {
        attrs: {
          height: _vm.screenHeight,
          styles: _vm.styles,
          "current-user-id": _vm.currentUserId,
          "room-id": _vm.roomId,
          rooms: _vm.loadedRooms,
          "finished-rooms": _vm.finishedRooms,
          "loading-rooms": _vm.loadingRooms,
          messages: _vm.messages,
          "messages-loaded": _vm.messagesLoaded,
          "rooms-loaded": _vm.roomsLoaded,
          "room-actions": _vm.roomActions,
          "menu-actions": _vm.menuActions,
          "room-message": _vm.roomMessage,
        },
        on: {
          "fetch-more-rooms": _vm.fetchMoreRooms,
          "fetch-messages": _vm.fetchMessages,
          "send-message": _vm.sendMessage,
          "edit-message": _vm.editMessage,
          "delete-message": _vm.deleteMessage,
          "open-file": _vm.openFile,
          "open-user-tag": _vm.openUserTag,
          "add-room": _vm.addRoom,
          "room-action-handler": _vm.menuActionHandler,
          "menu-action-handler": _vm.menuActionHandler,
          "send-message-reaction": _vm.sendMessageReaction,
          "typing-message": _vm.typingMessage,
          "toggle-rooms-list": function toggleRoomsList($event) {
            return _vm.$emit("show-demo-options", $event.opened)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }