var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "page-padding",
    },
    [
      _vm._m(0),
      _c(
        "el-row",
        {
          staticClass: "page-filter--wrap",
        },
        [
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [_vm._v("订单编号")]
          ),
          _c("el-input", {
            staticClass: "page-select--item",
            attrs: {
              placeholder: "请输入订单编号",
            },
            model: {
              value: _vm.searchForm.order_number,
              callback: function callback($$v) {
                _vm.$set(_vm.searchForm, "order_number", $$v)
              },
              expression: "searchForm.order_number",
            },
          }),
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [_vm._v("申请时间")]
          ),
          _c("el-date-picker", {
            attrs: {
              "value-format": "yyyy-MM-dd",
              type: "daterange",
              "range-separator": "至",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
            },
            on: {
              change: _vm.handleDateChange,
            },
            model: {
              value: _vm.apply_time,
              callback: function callback($$v) {
                _vm.apply_time = $$v
              },
              expression: "apply_time",
            },
          }),
        ],
        1
      ),
      _c("el-row", {
        staticClass: "page-filter--wrap",
      }),
      _c(
        "el-row",
        {
          staticClass: "page-filter--wrap",
        },
        [
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [_vm._v("售后编号")]
          ),
          _c("el-input", {
            staticClass: "page-select--item",
            attrs: {
              placeholder: "请输入商品名称",
            },
            model: {
              value: _vm.searchForm.after_sale_ticket_number,
              callback: function callback($$v) {
                _vm.$set(_vm.searchForm, "after_sale_ticket_number", $$v)
              },
              expression: "searchForm.after_sale_ticket_number",
            },
          }),
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [_vm._v("商品名称")]
          ),
          _c("el-input", {
            staticClass: "page-select--item",
            attrs: {
              placeholder: "请输入商品名称",
            },
            model: {
              value: _vm.searchForm.after_sale_goods_name,
              callback: function callback($$v) {
                _vm.$set(_vm.searchForm, "after_sale_goods_name", $$v)
              },
              expression: "searchForm.after_sale_goods_name",
            },
          }),
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [_vm._v("售后类型")]
          ),
          _c(
            "el-select",
            {
              staticClass: "page-select--item",
              attrs: {
                placeholder: "请选择",
              },
              model: {
                value: _vm.searchForm.after_sale_type,
                callback: function callback($$v) {
                  _vm.$set(_vm.searchForm, "after_sale_type", $$v)
                },
                expression: "searchForm.after_sale_type",
              },
            },
            [
              _c("el-option", {
                attrs: {
                  label: "全部",
                  value: undefined,
                },
              }),
              _vm._l(_vm.AFTER_SALE_TYPE, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: {
                    label: item.label,
                    value: item.value,
                  },
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "page-filter--wrap",
        },
        [
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [_vm._v("退款类型")]
          ),
          _c(
            "el-select",
            {
              staticClass: "page-select--item",
              attrs: {
                placeholder: "请选择",
              },
              model: {
                value: _vm.searchForm.after_sale_ticket_status,
                callback: function callback($$v) {
                  _vm.$set(_vm.searchForm, "after_sale_ticket_status", $$v)
                },
                expression: "searchForm.after_sale_ticket_status",
              },
            },
            [
              _c("el-option", {
                attrs: {
                  label: "全部",
                  value: undefined,
                },
              }),
              _vm._l(_vm.AFTER_SALE_TICKET_STATUS, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: {
                    label: item.label,
                    value: item.value,
                  },
                })
              }),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [_vm._v("物流状态")]
          ),
          _c(
            "el-select",
            {
              staticClass: "page-select--item",
              attrs: {
                placeholder: "请选择",
              },
              model: {
                value: _vm.searchForm.delivery_status,
                callback: function callback($$v) {
                  _vm.$set(_vm.searchForm, "delivery_status", $$v)
                },
                expression: "searchForm.delivery_status",
              },
            },
            [
              _c("el-option", {
                attrs: {
                  label: "全部",
                  value: undefined,
                },
              }),
              _vm._l(_vm.DELIVERY_STATUS, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: {
                    label: item.label,
                    value: item.value,
                  },
                })
              }),
            ],
            2
          ),
          _c(
            "el-button",
            {
              attrs: {
                round: "",
                icon: "el-icon-refresh",
              },
              on: {
                click: _vm.resetSearchForm,
              },
            },
            [_vm._v(" 重置 ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                round: "",
                icon: "el-icon-search",
              },
              on: {
                click: _vm.getList,
              },
            },
            [_vm._v(" 搜索 ")]
          ),
          _c(
            "el-popconfirm",
            {
              attrs: {
                title: "是否按当前搜索条件导出文件？",
              },
              on: {
                onConfirm: _vm.exportFile,
              },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: {
                    "margin-left": "10px",
                  },
                  attrs: {
                    slot: "reference",
                    type: "success",
                    round: "",
                    loading: _vm.downloading,
                    icon: "el-icon-download",
                  },
                  slot: "reference",
                },
                [_vm._v(" 导出文件 ")]
              ),
            ],
            1
          ),
          _vm.isMerchant
            ? _c(
                "el-button",
                {
                  staticStyle: {
                    "margin-left": "10px",
                  },
                  attrs: {
                    type: "success",
                    round: "",
                    icon: "el-icon-mouse",
                  },
                  on: {
                    click: _vm.handleBatch,
                  },
                },
                [_vm._v(" 批量操作 ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          staticClass: "my-tab",
          attrs: {
            type: "border-card",
          },
          on: {
            "tab-click": _vm.handleClick,
          },
          model: {
            value: _vm.need_to_react_operator_role,
            callback: function callback($$v) {
              _vm.need_to_react_operator_role = $$v
            },
            expression: "need_to_react_operator_role",
          },
        },
        [
          _c("el-tab-pane", {
            attrs: {
              label: "全部",
              name: "all",
            },
          }),
          _vm._l(_vm.OPERATOR_ROLE, function (item) {
            return _c("el-tab-pane", {
              key: item.value,
              attrs: {
                label: item.label,
                name: item.value,
              },
            })
          }),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: {
                width: "100%",
              },
              attrs: {
                data: _vm.salesData,
                stripe: "",
                border: "",
                "highlight-current-row": "",
                "row-style": {
                  height: "65px",
                },
              },
              on: {
                "selection-change": _vm.handleSelectionChange,
              },
            },
            [
              _vm.isMerchant
                ? _c("el-table-column", {
                    key: "selection",
                    attrs: {
                      type: "selection",
                      width: "55",
                      align: "center",
                    },
                  })
                : _c("el-table-column", {
                    key: "index",
                    attrs: {
                      type: "index",
                      label: "序号",
                      width: "55",
                      align: "center",
                    },
                  }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "100px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              disabled: _vm.isRefundButtonDisabled(
                                scope.row.status
                              ),
                            },
                            on: {
                              click: function click($event) {
                                return _vm.handleRefund(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 处理退款 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "status",
                  formatter: function formatter(_, __, value) {
                    return _vm.AFTER_SALE_TICKET_STATUS_VALUE_DIC[value]
                  },
                  label: "售后状态",
                  width: "150px",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "ticket_number",
                  label: "售后编号",
                  width: "250px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref) {
                      var row = _ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                            },
                            on: {
                              click: function click($event) {
                                return _vm.handleRefund(row)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(row.ticket_number) + " ")]
                        ),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "复制编号",
                              placement: "top",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                },
                                on: {
                                  click: function click($event) {
                                    return _vm.handleCopy(
                                      row.ticket_number,
                                      $event
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-document-copy",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "",
                  label: "订单编号",
                  width: "250px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref2) {
                      var row = _ref2.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                            },
                            on: {
                              click: function click($event) {
                                return _vm.handleOrderDetail(row)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(row.related_order_number) + " ")]
                        ),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "复制编号",
                              placement: "top",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                },
                                on: {
                                  click: function click($event) {
                                    return _vm.handleCopy(
                                      row.related_order_number,
                                      $event
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-document-copy",
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "order_line_goods_name",
                  label: "商品名称",
                  "min-width": "350px",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "order_line_delivery_status",
                  formatter: function formatter(_, __, value) {
                    return _vm.DELIVERY_STATUS_VALUE_DIC[value]
                  },
                  label: "物流状态",
                  width: "120px",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "退款金额",
                  width: "140px",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref3) {
                      var row = _ref3.row
                      return [
                        _vm._v(" " + _vm._s(_vm.formatRefundAmount(row)) + " "),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit,
            },
            on: {
              "update:page": function updatePage($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function updateLimit($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批量处理退款",
            visible: _vm.batchRefundDialogVisible,
            width: "630px",
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.batchRefundDialogVisible = $event
            },
            open: function open($event) {
              _vm.batchRefundForm.agree = true
            },
          },
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-radio-group",
                    {
                      on: {
                        change: function change($event) {
                          _vm.batchRefundForm.operation_reason = ""
                        },
                      },
                      model: {
                        value: _vm.batchRefundForm.agree,
                        callback: function callback($$v) {
                          _vm.$set(_vm.batchRefundForm, "agree", $$v)
                        },
                        expression: "batchRefundForm.agree",
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                label: true,
                              },
                            },
                            [_vm._v("同意退款申请")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "mt-[10px]",
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                label: false,
                              },
                            },
                            [_vm._v("拒绝退款申请")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              !_vm.batchRefundForm.agree
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "拒绝理由",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: {
                          width: "500px",
                        },
                        attrs: {
                          type: "textarea",
                        },
                        model: {
                          value: _vm.batchRefundForm.operation_reason,
                          callback: function callback($$v) {
                            _vm.$set(
                              _vm.batchRefundForm,
                              "operation_reason",
                              $$v
                            )
                          },
                          expression: "batchRefundForm.operation_reason",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                "margin-top": "-20px",
              },
              attrs: {
                slot: "footer",
              },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "mr-[10px]",
                  attrs: {
                    loading: _vm.batchLoading,
                  },
                  on: {
                    click: function click($event) {
                      _vm.batchRefundDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-popconfirm",
                {
                  attrs: {
                    title: "\u786E\u5B9A".concat(
                      _vm.batchRefundForm.agree ? "同意" : "拒绝",
                      "\u6279\u91CF\u9000\u6B3E"
                    ),
                  },
                  on: {
                    onConfirm: _vm.batchProcessRefund,
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        slot: "reference",
                        loading: _vm.batchLoading,
                        type: "primary",
                      },
                      slot: "reference",
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "page-header--wrap",
      },
      [
        _c(
          "div",
          {
            staticClass: "page-header-wrap--title",
          },
          [_vm._v("售后管理")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }