<template>
  <div class="page-padding">
    <div class="page-header--wrap">
      <div class="page-header-wrap--title">账号管理</div>
      <div>
        <el-button icon="el-icon-plus" type="primary" disabled @click="handleAddStaff"> 新增账号 </el-button>
      </div>
    </div>

    <el-row class="page-filter--wrap">
      <el-form inline label-width="auto">
        <el-form-item label="用户查询">
          <div class="flex">
            <el-select v-model="searchKeyname">
              <el-option v-for="item in searchKeynameList" :key="item.value" :value="item.value" :label="item.label"></el-option>
            </el-select>
            <el-input v-model="searchValue" placeholder="请输入内容" class="ml-[10px]"></el-input>
          </div>
        </el-form-item>
        <el-form-item style="margin-left: 20px">
          <el-button round icon="el-icon-refresh" @click="resetFormData"> 重置 </el-button>
          <el-button type="primary" round icon="el-icon-search" @click="handleSearch"> 搜索 </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <el-table v-loading="listLoading" :data="staffData" stripe border highlight-current-row style="width: 100%">
      <el-table-column type="index" align="center" width="60">
        <template slot="header"> 序号 </template>
      </el-table-column>
      <el-table-column label="操作" width="180" align="center">
        <template slot-scope="{ row }">
          <el-button type="text" @click="handleViewStaff(row)"> 查看 </el-button>
          <el-button type="text" @click="handleUpdateAuthority(row)"> 权限 </el-button>
        </template>
      </el-table-column>
      <el-table-column :formatter="tableFormatter" align="center" prop="account_type_str" label="账号类型"> </el-table-column>
      <el-table-column :formatter="tableFormatter" align="center" prop="account_name" label="账号名" show-overflow-tooltip />
      <el-table-column :formatter="tableFormatter" align="center" prop="user_name" label="用户名" show-overflow-tooltip />
      <el-table-column :formatter="tableFormatter" align="center" prop="phone" label="手机号" show-overflow-tooltip />
      <el-table-column :formatter="tableFormatter" align="center" prop="mail" label="邮箱" show-overflow-tooltip />
      <el-table-column align="center" label="状态">
        <template slot-scope="{ row }">
          <el-tag :type="row.status === 1 ? '' : 'danger'"> {{ row.status === 1 ? "启用" : "禁用" }} </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="角色">
        <template slot-scope="{ row }">
          {{ row.roles.map((item) => item.role_name).join(", ") || "-" }}
        </template>
      </el-table-column>
      <el-table-column :formatter="tableFormatter" align="center" prop="update_user" label="操作人" show-overflow-tooltip />
      <el-table-column :formatter="tableFormatter" align="center" prop="update_time" label="操作时间" show-overflow-tooltip />
    </el-table>

    <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

    <!-- 新增账号的弹窗 -->
    <el-dialog :title="`${textMap[dialogStatus]}账号`" :visible.sync="dialogStaffForm" width="500px">
      <el-form ref="staffForm" :model="staffForm" label-width="90px" disabled>
        <el-form-item label="账号类型" prop="account_type_str" required>
          <el-input v-model="staffForm.account_type_str" />
        </el-form-item>
        <el-form-item label="账号名" prop="account_name" required>
          <el-input v-model="staffForm.account_name" />
        </el-form-item>
        <el-form-item label="用户名" prop="user_name" required>
          <el-input v-model="staffForm.user_name" />
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="staffForm.phone" />
        </el-form-item>
        <el-form-item label="邮箱" prop="mail">
          <el-input v-model="staffForm.mail" />
        </el-form-item>
        <el-form-item label="状态" prop="status" required>
          <el-radio-group v-model="staffForm.status">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button type="text" @click="dialogStaffForm = false"> 取消 </el-button>
        <el-button type="primary" @keydown.enter="dialogStaffForm = false" @click="dialogStaffForm = false">
          {{ dialogStatus === "update" ? "修改" : "确定" }}
        </el-button>
        <!-- <el-button disabled type="primary" @keydown.enter="handleDialogConfirm" @click="handleDialogConfirm">
          {{ dialogStatus === "update" ? "修改" : "确定" }}
        </el-button> -->
      </div>
    </el-dialog>

    <!-- 编辑权限的弹窗 -->
    <el-dialog title="编辑权限" :visible.sync="dialogAuthority">
      <el-form ref="authorityForm" :rules="authorityFormRules" :model="authorityForm" label-width="100px">
        <el-form-item label="店铺权限" prop="mall_ids">
          <el-checkbox-group v-model="authorityForm.mall_ids">
            <el-checkbox v-for="item in shopList" :key="item.id" :label="item.id">
              {{ item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="角色" prop="role_ids">
          <el-select v-model="authorityForm.role_ids" placeholder="请选择角色" multiple>
            <el-option v-for="item in roleList" :key="item.id" :label="item.role_name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div class="dialog-footer">
        <el-button type="text" @click="dialogAuthority = false"> 取消 </el-button>
        <el-button type="primary" @click="handleAuthConfirm"> 确定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Pagination from "@/components/Pagination";
import TableListMixin from "@/mixin/TableList";
import api from "@/api";
import { commonUtil } from "@/utils/index";
import * as R from "ramda";
import { loginEncrypt } from "@/utils/js-encrypt";
import { pick } from "lodash";
import { cloneDeep } from "lodash";

const { to } = commonUtil;
const initSearchForm = {
  account_name: "",
  user_name: "",
  phone: "",
  mail: "",
};
const initStaffForm = {
  account_type_str: "",
  account_name: "",
  user_name: "",
  phone: "",
  mail: "",
  status: 1,
};
const initAuthorityForm = {
  id: undefined,
  mall_ids: [],
  role_ids: [],
};

function arrayNotEmptyFactory(msg) {
  return (_, value, callback) => {
    if (Array.isArray(value) && value.length > 0) {
      callback();
    } else {
      callback(new Error(msg));
    }
  };
}
const authorityFormRules = {
  mall_ids: [{ required: true, validator: arrayNotEmptyFactory("请选择店铺权限"), trigger: "change" }],
  role_ids: [{ required: true, validator: arrayNotEmptyFactory("请选择角色"), trigger: "change" }],
};
export default {
  name: "StaffManage",
  components: { Pagination },
  mixins: [TableListMixin],
  data() {
    return {
      shopList: [],
      roleList: [],
      dialogAuthority: false,
      searchKeyname: "account_name", // 搜索字段
      searchValue: "", // 搜索值
      searchKeynameList: [
        { value: "account_name", label: "账号名" },
        { value: "phone", label: "手机号" },
        { value: "mail", label: "邮箱" },
        { value: "user_name", label: "用户名" },
      ],

      listLoading: false,
      searchForm: cloneDeep(initSearchForm),
      staffData: [],
      staffForm: cloneDeep(initStaffForm),
      authorityForm: cloneDeep(initAuthorityForm),
      authorityFormRules,
      dialogStaffForm: false,
    };
  },
  computed: {
    ...mapState("user", {
      related_malls: (state) => state.auth_info.related_malls,
    }),
  },
  created() {
    this.getList();
    this.getShopList();
    this.getRoleList();
  },
  methods: {
    tableFormatter(_, __, value) {
      if (!value) return "-";
      return value;
    },
    async getShopList() {
      const [, shopList] = await api.malls.allMalls();
      this.shopList = shopList;
    },
    async getRoleList() {
      const [, roleList] = await api.role.allRoles();
      this.roleList = roleList;
    },
    handleUpdateAuthority(row) {
      this.authorityForm = pick({ ...row, role_ids: row.roles.map((item) => item.role_id) }, Object.keys(initAuthorityForm));
      this.dialogAuthority = true;
      this.$nextTick(() => {
        this.$refs.authorityForm.clearValidate();
      });
    },
    resetFormData() {
      this.resetPage();
      this.searchKeyname = "account_name";
      this.searchValue = "";
      this.searchForm = R.clone(initSearchForm);
      this.getList();
    },
    handleSearch() {
      this.resetPage();
      this.getList();
    },
    handleViewStaff(row) {
      this.staffForm = pick(row, Object.keys(initStaffForm));
      this.dialogStatus = "view";
      this.dialogStaffForm = true;
    },
    resetPage() {
      this.listQuery.page = 1;
    },
    resetForm() {
      this.staffForm = cloneDeep(initStaffForm);
    },
    handleAddStaff() {
      this.resetForm();
      this.dialogStatus = "create";
      this.dialogStaffForm = true;
      this.staffForm.related_mall_code_list = this.related_malls.map((item) => item.code);
      this.$nextTick(() => {
        this.$refs.staffForm.clearValidate();
      });
    },
    async getList() {
      // 获取数据
      this.formatSearchForm();
      const [, res] = await this.getListMixin(({ params }) => api.users.page({ method: "post", data: params }));
      this.staffData = res?.list ?? [];
    },
    formatSearchForm() {
      Object.keys(initSearchForm).forEach((key) => {
        this.searchForm[key] = "";
        if (this.searchKeyname === key) this.searchForm[key] = this.searchValue;
      });
    },

    // 创建和修改 HTTP 请求
    async handleDialogConfirm() {
      let method = this.dialogStatus === "create" ? "post" : "put";

      delete this.staffForm.password2;
      const [err] = await to(this.$refs.staffForm.validate());
      if (err === false || err) return this.$notify.warning("请填写必填信息");
      const encryptData = { ...this.staffForm, account: loginEncrypt(this.staffForm.account), password: loginEncrypt(this.staffForm.password) };
      const pickArr = ["name", "password"];
      if (method === "post") pickArr.push("account", "related_mall_code_list");
      const data = R.pick(pickArr, encryptData);
      const [err1] = await api.users.adminUsers({
        method,
        id: this.staffForm.id,
        data,
      });
      if (err1) return;
      this.dialogStaffForm = false;
      this.$notify.success("操作成功");
      await this.getList();
    },
    async handleAuthConfirm() {
      const [err] = await to(this.$refs.authorityForm.validate());
      if (err === false) return;

      const [err2] = await api.users.updateAuth({
        method: "post",
        data: this.authorityForm,
      });
      if (err2) return;

      this.dialogAuthority = false;
      this.$notify.success("操作成功");
      await this.getList();
    },
  },
};
</script>

<style></style>

<style lang="scss" scoped></style>
