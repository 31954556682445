<template>
  <div>
    <el-form ref="form" :rules="formRule" :model="form" label-width="200px">
      <el-row>
        <div class="panel-item" style="margin-top: 20px">推送管理</div>
        <el-col :span="18">
          <el-form-item label="是否自动推单到企得宝：">
            <el-radio-group v-model="form.is_auto_push">
              <el-radio :label="true"> 是 </el-radio>
              <el-radio :label="false">
                否
                <el-tooltip effect="dark" placement="top">
                  <div slot="content">如选择否，则需要手动推单到企得宝发货</div>
                  <i class="el-icon-warning" style="color: #ccc" />
                </el-tooltip>
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="3">
          <el-form-item style="text-align: center">
            <el-button type="primary" @click="handelFormSubmit"> 确定 </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "OrderPush",
  data() {
    return {
      form: {
        is_auto_push: undefined,
      },
      formRule: {},
    };
  },
  created() {
    this.getConfig();
  },
  methods: {
    async getConfig() {
      const [err, res] = await api.settings.autoPushOrderToQidebao();
      if (!err) this.form.is_auto_push = res;
    },
    async handelFormSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      const [err] = await api.settings.autoPushOrderToQidebao({
        method: "put",
        data: this.form.is_auto_push,
      });
      if (!err) this.$message.success("操作成功");
    },
  },
};
</script>

<style></style>
