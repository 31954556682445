<template>
  <el-dialog :visible="visible" title="修改收货地址" width="500px" top="5vh" @open="init" @close="handleCancel">
    <el-alert title="仅支持修改3次，请务必在买家知情且同意下修改收货信息。" type="warning" show-icon :closable="false" />
    <el-form ref="form" :model="form" :rules="formRules" label-width="90px" style="margin-top: 30px">
      <el-form-item label="收货人" prop="receiver_name">
        <el-input v-model="form.receiver_name" />
      </el-form-item>
      <el-form-item label="联系电话" prop="receiver_phone">
        <el-input v-model="form.receiver_phone" />
      </el-form-item>
      <el-form-item label="联系地址" prop="cities">
        <el-cascader v-model="selected_city" size="mini" :options="optionsCity" style="width: 100%" @change="handleCityChange" />
      </el-form-item>
      <el-form-item label="" prop="address_detail">
        <el-input v-model="form.address_detail" type="textarea" placeholder="请填写详细地址" />
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button type="text" @click="handleCancel"> 取消 </el-button>
      <el-button type="primary" @click="handleConfirm"> 确定 </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { CodeToText, regionData, TextToCode } from "element-china-area-data";
import { isEmpty } from "ramda";
import api from "@/api";

export default {
  props: {
    visible: Boolean,
    detailData: {},
    orderId: undefined,
  },
  data() {
    let citiesValidator = (rule, value, callback) => {
      if (this.selected_city.length == 0) {
        callback(new Error("请填写详细地址"));
      } else {
        callback();
      }
    };
    return {
      form: {
        city: "",
        district: "",
        // order_no: this.orderNo,
        province: "",
        receiver_phone: this.detailData.receiver_phone,
        receiver_name: this.detailData.receiver,
        address_detail: this.detailData.address_detail,
      },
      formRules: {
        receiver_name: [{ required: true, message: "请填写收货人" }],
        receiver_phone: [{ required: true, message: "请填写联系电话" }],
        cities: [{ required: true, validator: citiesValidator }],
        address_detail: [{ required: true, message: "请填写详细地址" }],
      },
      optionsCity: regionData,
      selected_city: [],
    };
  },
  methods: {
    handleCancel() {
      this.$emit("update:visible", false);
    },
    async handleConfirm() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;

      const [err] = await api.order.orders({
        method: "put",
        id: this.orderId,
        resource: "shippingInfo",
        data: this.form,
      });

      if (err) return;

      this.$emit("update:visible", false);
      this.$emit("success");
      this.$message.success("修改成功");
      this.$refs.form.resetFields();
    },
    handleCityChange(selectedArr) {
      let { form } = this;
      [form.province, form.city, form.district] = selectedArr.map((item) => CodeToText[item]);
    },

    init() {
      if (isEmpty(this.detailData.shipping_info)) return;
      let {
        receiver_name,
        receiver_mobile_number,
        shipping_address_province: province,
        shipping_address_city: city,
        shipping_address_district: district,
        shipping_address_street_address,
      } = this.detailData.shipping_info ?? {};
      this.form.receiver_name = receiver_name;
      this.form.receiver_phone = receiver_mobile_number;
      this.form.province = province;
      this.form.city = city;
      this.form.district = district;
      this.form.address_detail = shipping_address_street_address;
      this.selected_city = [TextToCode[province].code, TextToCode[province][city].code, TextToCode[province][city][district].code];
    },
  },
};
</script>

<style></style>
