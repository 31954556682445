import Axios from "axios";
import { getToken } from "@/utils/auth";

export default ({ url, params, method }) => {
  return Axios({
    method,
    url,
    params,
    headers: {
      token: getToken(),
      "content-type": "application/json; charset=utf-8",
    },
    responseType: "blob",
  });
};
