<template>
  <div>
    <el-row :gutter="10" class="margin-item">
      <el-form ref="form" :model="form" :inline="true" :rules="formRules" label-width="80px">
        <el-form-item label="商品信息" prop="food">
          <el-input v-model="form.food" style="width: 200px" />
        </el-form-item>
        <el-form-item label="物流公司" prop="express_company_code">
          <el-select v-model="form.express_company_code" style="width: 200px">
            <el-option v-for="item in express_options" :key="item.code" :label="item.name" :value="item.code" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="物流单号" prop="delivery_num">
          <el-input v-model="form.delivery_num" style="width: 200px"></el-input>
        </el-form-item> -->
      </el-form>
      <el-table
        :data="goodsData"
        border
        stripe
        highlight-current-row
        style="width: 100%"
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column align="center" label="运单号">
          <template slot-scope="{ row }">
            <el-input v-model="row.delivery_no" />
          </template>
        </el-table-column>
        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
        <el-table-column align="center" prop="params" label="期数" />
        <!-- <el-table-column align="center" prop="delivery_no" label="发货编号"> </el-table-column> -->
        <el-table-column align="center" prop="item_no" label="订单编号" />
        <el-table-column align="center" prop="order_states" label="发货状态">
          <template slot-scope="{ row }">
            {{ row.order_states | regularOrderDetailText }}
          </template>
        </el-table-column>

        <el-table-column width="200" align="center" label="收货人">
          <template slot-scope="{ row }">
            <div>
              <div>{{ row.receiver_name }}</div>
              <div>{{ row.receiver_phone }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="收货地址" show-overflow-tooltip>
          <template slot-scope="{ row }"> {{ row.province }}{{ row.city }}{{ row.county }}{{ row.receiver_address }} </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- <el-row :gutter="10" class="margin-item">
      <el-col :span="2" style="font-weight: 500">配送信息</el-col>
      <el-col :span="20">
        <div class="order-info-item">收货人：{{ goodsData.receiver_name }}</div>
        <div class="order-info-item">收货地址：{{ goodsData.province + goodsData.city + goodsData.county + goodsData.receiver_address }}</div>
      </el-col>
    </el-row> -->

    <el-row :gutter="10" type="flex" justify="center">
      <el-button type="primary" @click="handleDelivery"> 发货 </el-button>
    </el-row>
  </div>
</template>

<script>
import api from "@/api";
import { regularOrderDetailText } from "@/filters/regular_order";

export default {
  filters: {
    regularOrderDetailText,
  },
  props: { goodsData: { type: Array, default: () => [] } },
  data() {
    return {
      express_options: [],
      multipleSelection: [],
      btn_delivery: true,
      need_delivery: false,

      delivery_method: "1",
      form: {
        food: "",
        express_company_code: "EMS",
        regular_item_detail_map: {},
      },
      formRules: {
        food: [{ required: true, message: "请填写商品信息" }],
        express_company_code: [{ required: true, message: "请选择物流公司" }],
        delivery_num: [{ required: true, message: "请填写物流单号" }],
      },
    };
  },
  computed: {
    is_online_dispatch() {
      let { code } = this.$store.state.user.auth_info.current_mall;
      let is_song = code == "song" ? true : false;
      let is_miliang = code == "miliang" ? true : false;
      if (is_song || is_miliang) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.need_delivery = true;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.delivery_method = "2";
      }
      if (is_song) return is_song;
      if (is_miliang) return is_miliang;
      return is_song;
    },
  },
  async created() {
    [, this.express_options] = await api.logistics.logisticsCompanies();
  },
  methods: {
    handleSelectionChange(val) {
      // console.log(val);
      val.length != 0 ? (this.btn_delivery = false) : (this.btn_delivery = true);
      this.multipleSelection = val;
    },
    handleDeliveryChange(val) {
      if (val == 2) {
        this.need_delivery = true;
      } else if (val == 1) {
        this.need_delivery = false;
      }
    },
    async deliveryRequest() {
      let { food, express_company_code } = this.form;
      let regular_item_detail_map = {};
      this.goodsData.forEach((item) => {
        regular_item_detail_map[item.item_no] = item.delivery_no;
      });

      if (this.delivery_num == "") {
        this.$message.warning("请填写物流单号");
      } else {
        const [err] = await api.order.deliverGood({
          method: "post",
          data: {
            express_company_code,
            food,
            regular_item_detail_map,
          },
        });
        this.$emit("handleDelivery", err);
        // this.axios
        //   .post(`/regular_goods/expressOnline`, {
        //     express_company_code,
        //     food,
        //     regular_item_detail_map,
        //   })
        //   .then((res) => {
        //     this.$emit("handleDelivery", res.code);
        //   });
      }
    },
    handleDelivery() {
      // this.multipleSelection.length == 0 ? this.$notify.warning("请选择商品") : this.deliveryRequest();
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.deliveryRequest();
        }
      });
    },
  },
};
</script>

<style scoped>
.order-info-item {
  line-height: 22px;
}
.margin-item {
  margin: 20px 0;
}
</style>
