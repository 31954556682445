import permission, { mallPermission } from "./permission";

const install = function (Vue) {
  Vue.directive("permission", permission);
  Vue.directive("is-malls", { bind: mallPermission });
};

if (window.Vue) {
  window["permission"] = permission;
  Vue.use(install); // eslint-disable-line
}

permission.install = install;
export default permission;
