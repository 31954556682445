var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "page-padding",
    },
    [
      _c(
        "div",
        {
          staticClass: "page-header--wrap",
        },
        [
          _c(
            "div",
            {
              staticClass: "page-header-wrap--title",
            },
            [_vm._v("角色管理")]
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-plus",
                    type: "primary",
                  },
                  on: {
                    click: _vm.handleAddStaff,
                  },
                },
                [_vm._v(" 新增角色 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-row",
        {
          staticClass: "page-filter--wrap",
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                inline: "",
                "label-width": "auto",
              },
              nativeOn: {
                submit: function submit($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "角色名称",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "请输入角色名称",
                    },
                    nativeOn: {
                      keyup: function keyup($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.handleSearch.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.searchForm.role_name,
                      callback: function callback($$v) {
                        _vm.$set(_vm.searchForm, "role_name", $$v)
                      },
                      expression: "searchForm.role_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        round: "",
                        icon: "el-icon-search",
                      },
                      on: {
                        click: _vm.handleSearch,
                      },
                    },
                    [_vm._v(" 搜索 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: {
            width: "100%",
          },
          attrs: {
            data: _vm.roleData,
            stripe: "",
            border: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "300",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref) {
                  var row = _ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                        },
                        on: {
                          click: function click($event) {
                            return _vm.handleEditRole(row)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                        },
                        on: {
                          click: function click($event) {
                            return _vm.handleUpdateRoleStatus(row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(row.status === 1 ? "禁用" : "启用") + " "
                        ),
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                        },
                        on: {
                          click: function click($event) {
                            return _vm.handleDeleteRole(row)
                          },
                        },
                      },
                      [_vm._v(" 删除 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "状态",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref2) {
                  var row = _ref2.row
                  return [
                    row.status === 1
                      ? _c("el-tag", [_vm._v(" 启用 ")])
                      : _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "danger",
                            },
                          },
                          [_vm._v(" 禁用 ")]
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              formatter: _vm.tableFormatter,
              align: "center",
              prop: "role_name",
              label: "角色名称",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              autosize: "",
              formatter: _vm.tableFormatter,
              align: "center",
              prop: "role_desc",
              label: "角色说明",
            },
          }),
          _c("el-table-column", {
            attrs: {
              formatter: _vm.tableFormatter,
              align: "center",
              label: "创建人/修改人",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref3) {
                  var _row$create_user, _row$update_user
                  var row = _ref3.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          (_row$create_user = row.create_user) !== null &&
                            _row$create_user !== void 0
                            ? _row$create_user
                            : "-"
                        ) +
                        "/" +
                        _vm._s(
                          (_row$update_user = row.update_user) !== null &&
                            _row$update_user !== void 0
                            ? _row$update_user
                            : "-"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              formatter: _vm.tableFormatter,
              align: "center",
              label: "创建时间/修改时间",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref4) {
                  var _row$create_time, _row$update_time
                  var row = _ref4.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          (_row$create_time = row.create_time) !== null &&
                            _row$create_time !== void 0
                            ? _row$create_time
                            : "-"
                        ) +
                        "/" +
                        _vm._s(
                          (_row$update_time = row.update_time) !== null &&
                            _row$update_time !== void 0
                            ? _row$update_time
                            : "-"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function updatePage($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function updateLimit($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "".concat(_vm.textMap[_vm.dialogStatus], "\u89D2\u8272"),
            visible: _vm.dialogRoleForm,
            width: "500px",
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.dialogRoleForm = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "roleForm",
              attrs: {
                model: _vm.roleForm,
                rules: _vm.roleRules,
                "label-width": "90px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "角色名称",
                    prop: "role_name",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.roleForm.role_name,
                      callback: function callback($$v) {
                        _vm.$set(_vm.roleForm, "role_name", $$v)
                      },
                      expression: "roleForm.role_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "角色权限",
                    prop: "menu_ids",
                  },
                },
                [
                  _c("treeselect", {
                    attrs: {
                      multiple: true,
                      limit: 3,
                      options: _vm.menuList,
                      placeholder: "选择权限",
                      "no-children-text": "无数据",
                      "value-consists-of": "ALL_WITH_INDETERMINATE",
                      "limit-text": function limitText(count) {
                        return "+".concat(count)
                      },
                    },
                    model: {
                      value: _vm.roleForm.menu_ids,
                      callback: function callback($$v) {
                        _vm.$set(_vm.roleForm, "menu_ids", $$v)
                      },
                      expression: "roleForm.menu_ids",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "角色描述",
                    prop: "role_desc",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                    },
                    model: {
                      value: _vm.roleForm.role_desc,
                      callback: function callback($$v) {
                        _vm.$set(_vm.roleForm, "role_desc", $$v)
                      },
                      expression: "roleForm.role_desc",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "text",
                  },
                  on: {
                    click: function click($event) {
                      _vm.dialogRoleForm = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                  },
                  on: {
                    keydown: function keydown($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleDialogConfirm.apply(null, arguments)
                    },
                    click: _vm.handleDialogConfirm,
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.dialogStatus === "update" ? "修改" : "确定") +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }