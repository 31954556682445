import axios from "axios";
import { Message } from "element-ui";
import store from "@/store";
import { compileUtil, commonUtil, authUtil } from "@/utils";
import router from "@/router";
import { cacheAdapter } from "@/adapter";

const { cacheAdapterEnhancer } = cacheAdapter;
const { getToken } = authUtil;

// create an axios instance
const service = axios.create({
  baseURL: compileUtil.getBaseUrlByEnv(), // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
  adapter: cacheAdapterEnhancer(axios.defaults.adapter, {
    enabledByDefault: false,
    maxAge: 60 * 60 * 1000, // 缓存一小时
  }),
});

const showError = commonUtil.throttle(
  (message) => {
    Message({
      message,
      type: "error",
      duration: 5 * 1000,
    });
  },
  3000,
  { leading: true, trailing: false }
);

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // config.headers["X-Token"] = getToken();
      config.headers["token"] = getToken();
      const { data } = config;
      if (["boolean", "string", "number"].includes(typeof data)) config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;
    const { isReturnOrigin = false, responseType } = response.config || {};

    if (responseType === "blob") return res;
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 0) {
      Message({
        message: res.msg || "Error",
        type: "error",
        duration: 5 * 1000,
      });

      return Promise.reject(new Error(res.message || "Error"));
    } else {
      return isReturnOrigin ? res : res.data;
    }
  },
  async (error) => {
    const { status, data = {} } = error.response;
    let message;
    let detail;
    if (data instanceof Blob) {
      message = await readBlobError(data);
    } else {
      ({ message, data: detail } = typeof data === "object" ? data.error : {});
    }

    if (status === 401) {
      store.dispatch("user/resetToken").then(() => {
        router.push({ path: "/login" });
      });
    }

    detail = Array.isArray(detail) ? `: ${detail.join("，")}` : detail;
    showError((message || error.message) + `${detail ? ` ${detail}` : ""}`);
    compileUtil.isDevelopment() && console.log("err: " + error); // for debug
    return Promise.reject(error);
  }
);

function readBlobError(blobJsonData) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsText(blobJsonData, "utf-8");
    reader.onload = function () {
      const error = JSON.parse(reader.result);
      resolve(error.error.message);
    };
  });
}

export default service;
