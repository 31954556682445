/**
 * 格式化联系人数据为前端需要的格式
 * @param {*} data
 * @returns Array
 */
export function formatRoomList(data) {
  let arrTemp = [];
  data.forEach((item) => {
    let obj = {
      sessionId: undefined,
      roomId: "",
      openId: undefined,
      roomName: "",
      avatar: "",
      unreadCount: "",
      lastMessage: {
        content: "",
        timestamp: "",
      },
      users: [],
    };
    obj.sessionId = item.session_id;
    obj.roomId = item.session_id; // 目前是多余了。
    obj.openId = item.open_id; // 目前是多余了。
    obj.roomName = item.user_name;
    obj.avatar = item.avatar_url;
    obj.unreadCount = item.not_read_num;
    obj.lastMessage.content = item.customer_service_record.text || "";
    obj.lastMessage.timestamp = item.customer_service_record.create_time;
    arrTemp.push(obj);
  });
  return arrTemp;
}
