var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "",
              },
            },
            [
              _c("el-avatar", {
                attrs: {
                  size: 50,
                  src: _vm.currentRoom.avatar,
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "昵称：",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("formatEmptyString")(_vm.currentRoom.roomName)
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "手机号：",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm._f("formatEmptyString")(_vm.currentRoom.phone)) +
                  " "
              ),
            ]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "会员卡号：",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("formatEmptyString")(_vm.currentRoom.memberCardNum)
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "会员等级：",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("formatEmptyString")(_vm.currentRoom.levelName)
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "地区：",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("formatEmptyString")(_vm.currentRoom.district)
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "备注：",
              },
            },
            [_vm._v(" - ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }