<template>
  <div>
    <div ref="editor" v-loading="editorLoading" element-loading-text="上传图片中..." class="editor" />
  </div>
</template>

<script>
import E from "wangeditor";
import axios from "axios";
const upload_qiniu_address = "https://cdn.jmj1995.com/"; //七牛云返回储存图片的子域名（外链域名）
export default {
  name: "EditorIndex",
  props: ["alreadyContent", "token"],
  data() {
    return {
      editor: null,
      upload_qiniu_area: "https://upload-z2.qiniup.com", //七牛云上传储存区域的上传域名
      editorLoading: false,
    };
  },
  watch: {
    alreadyContent: function () {
      this.editor.txt.html(this.alreadyContent);
    },
  },
  mounted() {
    const _this = this;
    let editor = new E(this.$refs.editor);
    // editor.config.uploadImgShowBase64 = true;
    editor.config.customUploadImg = function (resultFiles, insertImgFn) {
      // _this.handleUpload(resultFiles[0]).then((res) => {
      //   // 上传图片，返回结果，将图片插入到编辑器中
      //   insertImgFn(upload_qiniu_address + res.data.key);
      // });

      resultFiles.forEach((item) => {
        _this.handleUpload(item).then((res) => {
          // 上传图片，返回结果，将图片插入到编辑器中
          insertImgFn(upload_qiniu_address + res.data.key);
        });
      });
    };
    // 配置 onchange 回调函数，将数据同步到 vue 中
    editor.config.onchange = function (html) {
      _this.$emit("editorContent", html);
    };
    editor.create();
    this.editor = editor;
  },
  methods: {
    handleUpload(imgFile) {
      const promise = new Promise((resolve, reject) => {
        this.editorLoading = true;
        const config = {
          headers: { "Content-Type": "multipart/form-data", Accept: "*/*" },
        };
        let fileType = "";
        if (imgFile.type === "image/jpg") {
          fileType = "jpg";
        } else if (imgFile.type === "image/png") {
          fileType = "png";
        } else if (imgFile.type === "image/jpeg") {
          fileType = "jpeg";
        }
        const key = `front_${new Date().getTime()}${Math.floor(Math.random() * 100)}.${fileType}`; //自定义图片名

        const fd = new FormData();
        fd.append("file", imgFile);
        fd.append("token", this.token);
        fd.append("key", key);
        axios
          .post(this.upload_qiniu_area, fd, config)
          .then((res) => {
            if (res.status == 200 && res.data) {
              resolve({
                data: {
                  key,
                },
              });
            } else {
              reject(res);
            }
          })
          .catch((err) => {
            this.$notify.error({
              message: `上传失败[${err.status}]`,
              duration: 2000,
            });
          })
          .finally(() => {
            this.editorLoading = false;
          });
      });
      return promise;
    },
  },
};
</script>

<style scoped>
.editor {
  text-align: left;
  margin: 20px;
  /* width: 730px; */
}
::v-deep .w-e-text-container {
  /* height: 360px !important; */
  height: auto;
}
</style>
