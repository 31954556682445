var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.goodsDetailLoading,
          expression: "goodsDetailLoading",
        },
      ],
      staticClass: "goods-wrap page-padding",
      staticStyle: {
        background: "#fff",
      },
      attrs: {
        "element-loading-text": "加载中...",
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "sticky",
            {
              attrs: {
                "z-index": 20,
              },
            },
            [
              _c("el-page-header", {
                staticClass: "go-back",
                attrs: {
                  content: "发货详情",
                },
                on: {
                  back: _vm.goBack,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "formGoods",
          staticClass: "goods-form--wrap",
          attrs: {
            model: _vm.formGoods,
            rules: _vm.formGoodsRules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "div",
                {
                  staticClass: "panel-item",
                },
                [_vm._v("物流信息")]
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    span: 24,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "发货单号：",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.detailData.order_no) + " ")]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "安排邮政发货：",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.detailData.push_info) + " ")]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "物流单号：",
                      },
                    },
                    _vm._l(
                      _vm.detailData.logistics_order_no_list,
                      function (item, index) {
                        return _c(
                          "span",
                          {
                            key: index,
                          },
                          [_vm._v(_vm._s(item))]
                        )
                      }
                    ),
                    0
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "物流轨迹：",
                      },
                    },
                    [
                      _vm.detailData.packages.length == 0
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                margin: "0 0 20px 40px",
                                "min-height": "200px",
                              },
                            },
                            [_vm._v("暂无物流信息")]
                          )
                        : _c(
                            "el-tabs",
                            {
                              staticStyle: {
                                "min-height": "200px",
                              },
                              attrs: {
                                type: "card",
                              },
                              model: {
                                value: _vm.activePackage,
                                callback: function callback($$v) {
                                  _vm.activePackage = $$v
                                },
                                expression: "activePackage",
                              },
                            },
                            _vm._l(
                              _vm.detailData.packages,
                              function (item, index) {
                                return _c(
                                  "el-tab-pane",
                                  {
                                    key: index,
                                    staticStyle: {
                                      margin: "0 0 10px 20px",
                                    },
                                    attrs: {
                                      label: "\u5305\u88F9".concat(index + 1),
                                      name: "\u5305\u88F9".concat(index + 1),
                                    },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticClass:
                                              "order-info-wrap order-packages",
                                            attrs: {
                                              span: 6,
                                            },
                                          },
                                          [
                                            item.express_item_list.length != 0
                                              ? _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "order-info-item",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "发货人：" +
                                                          _vm._s(
                                                            item
                                                              .express_item_list[0]
                                                              .sender
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "order-info-item",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "运单号： " +
                                                          _vm._s(
                                                            item
                                                              .express_item_list[0]
                                                              .logistics_order_no
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "order-info-item",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "发货方式： " +
                                                          _vm._s(
                                                            item
                                                              .express_item_list[0]
                                                              .type
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "order-info-item",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "发货时间： " +
                                                          _vm._s(
                                                            _vm.formatTime(
                                                              item
                                                                .express_item_list[0]
                                                                .sent_date
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "packages-title",
                                                staticStyle: {
                                                  margin: "0",
                                                },
                                              },
                                              _vm._l(
                                                item.express_item_list,
                                                function (imgItem, index) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: index,
                                                      staticStyle: {
                                                        width: "80px",
                                                        "margin-right": "5px",
                                                      },
                                                    },
                                                    [
                                                      _c("img", {
                                                        staticStyle: {
                                                          width: "80px",
                                                          margin: "10px 5px",
                                                        },
                                                        attrs: {
                                                          src: imgItem.img_url,
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "fs12 text-ellipsis",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              imgItem.sku_name
                                                            ) +
                                                              "x" +
                                                              _vm._s(
                                                                imgItem.num
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            staticClass:
                                              "order-info-wrap order-packages",
                                            attrs: {
                                              span: 12,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  margin: "0 0 20px 20px",
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("物流状态："),
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#f56c6c",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.state_str)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-timeline",
                                              {
                                                attrs: {
                                                  reverse: true,
                                                },
                                              },
                                              _vm._l(
                                                item.mail_tracks,
                                                function (jitem, jindex) {
                                                  return _c(
                                                    "el-timeline-item",
                                                    {
                                                      key: jindex,
                                                      attrs: {
                                                        timestamp:
                                                          jitem.op_time,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            jitem.op_desc
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "div",
                {
                  staticClass: "panel-item",
                },
                [_vm._v("发货商品")]
              ),
              _c(
                "el-col",
                {
                  staticStyle: {
                    "margin-bottom": "30px",
                  },
                  attrs: {
                    span: 20,
                  },
                },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: {
                        width: "100%",
                      },
                      attrs: {
                        data: _vm.detailData.products,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "goods_name",
                          label: "发货商品",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "spec",
                          label: "规格属性",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "EMS编码",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function fn(_ref) {
                              var row = _ref.row
                              return [_vm._v(" " + _vm._s(row.sku_code) + " ")]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          prop: "num",
                          label: "发货数量",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "div",
                {
                  staticClass: "panel-item",
                },
                [_vm._v("收货信息")]
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    span: 8,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "收货人：",
                        prop: "receiver",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.detailData.receiver) + " ")]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "联系电话：",
                        prop: "receiver_phone",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.detailData.receiver_phone) + " ")]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所在区域：",
                        prop: "cities",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.detailData.province) +
                          _vm._s(_vm.detailData.city) +
                          _vm._s(_vm.detailData.county) +
                          _vm._s(_vm.detailData.address_detail) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "div",
                {
                  staticClass: "panel-item",
                },
                [_vm._v("下单来源")]
              ),
              _c(
                "el-col",
                {
                  attrs: {
                    span: 8,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "下单来源：",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.detailData.channel) + " ")]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "渠道单号：",
                        prop: "channel_order_no",
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.detailData.channel_order_no) + " "
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "备注说明：",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.detailData.seller_remark) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: {
                "text-align": "center",
              },
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: _vm.goBack,
                  },
                },
                [_vm._v(" 返回 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }