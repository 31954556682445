<template>
  <el-dialog :visible="visible" title="修改物流" width="800px" top="5vh" @open="init" @close="handleCancel">
    <el-alert title="物流信息仅支持 2 次更正，请仔细填写并核对。" type="warning" show-icon :closable="false" />
    <el-form ref="form" :model="form" :rules="formRules" label-width="90px" label-position="left" style="padding-top: 20px">
      <div v-for="(item, index) in localDeliverySheetInfoList" :key="index">
        <el-row type="flex">
          <el-form-item :label="`包裹${index + 1}`" label-width="50px" />
          <el-form-item :label="`共${getGoodsNum(item.delivery_sheet_item_list)}件商品`" />
        </el-row>
        <el-row type="flex">
          <el-col :span="12">
            <el-form-item label="物流公司">
              <el-select v-model="item.logistics_company" filterable style="width: 200px">
                <el-option v-for="item in logisticsCompanies" :key="item.code" :label="item.name" :value="item.code" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="运单编号">
              <el-input v-model="item.tracking_number" style="width: 200px" />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>

    <div class="dialog-footer">
      <el-button type="text" @click="handleCancel"> 取消 </el-button>
      <el-button type="primary" @click="handleConfirm"> 保存 </el-button>
    </div>
  </el-dialog>
</template>

<script>
import api from "@/api";
import * as R from "ramda";
import { transformUtil } from "@/utils";

export default {
  props: {
    visible: Boolean,
    orderNo: {
      type: String,
      default: "",
    },
    deliverySheetInfoList: Array,
  },
  data() {
    return {
      logisticsCompanies: [],
      localDeliverySheetInfoList: [],
      delivery_company: "",
      form: {
        order_no: this.orderNo,

        express_company: "",
        tracking_number: "",
        num: "",
      },
      formRules: {},
    };
  },
  mounted() {
    this.getLogisticsCompanies();
  },

  methods: {
    init() {
      this.localDeliverySheetInfoList = R.clone(this.deliverySheetInfoList);
    },
    getGoodsNum(deliverySheetItemList = []) {
      return deliverySheetItemList.reduce((acc, cur) => {
        return acc + (cur.num ?? 0);
      }, 0);
    },
    async getLogisticsCompanies() {
      [, this.logisticsCompanies = []] = await api.logistics.logisticsCompanies();
    },
    handleCancel() {
      this.$emit("update:visible", false);
    },
    async handleConfirm() {
      const data = R.map(
        R.compose(R.pick(["delivery_sheet_id", "logistics_company", "tracking_number"]), transformUtil.renameKeys({ id: "delivery_sheet_id" })),
        this.localDeliverySheetInfoList
      );

      const [err] = await api.order.deliverySheetsBatchUpdate({
        method: "post",
        data,
      });

      if (!err) {
        this.$notify.success("修改成功");
        this.$emit("success");
      }
    },
  },
};
</script>

<style></style>
