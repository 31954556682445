/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ["admin", "editor"];
  return valid_map.indexOf(str.trim()) >= 0;
}

/**
 * 校验面积相关字段
 */
export function validateArea(rule, value, callback) {
  var reg = /^\d+(\.\d+)?$/;
  if (value && !reg.test(value)) {
    return callback(new Error("请输入数字或小数"));
  } else if (typeof Number(value) == "number" && Number(value) > 10000) {
    return callback(new Error("最大不超过10000"));
  } else {
    callback();
  }
}
