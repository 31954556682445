<template>
  <div class="navbar">
    <hamburger :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

    <breadcrumb class="breadcrumb-container" />

    <div class="right-menu">
      <el-select
        ref="select"
        v-model="auth_info.current_mall.code"
        placeholder=""
        @visible-change="(e) => handleClick(e, !auth_info.related_malls?.length)"
        @change="handleShopChange"
      >
        <el-option v-for="item in auth_info.related_malls" :key="item.name" :label="item.name" :value="item.code" />
      </el-select>
      <el-dropdown class="avatar-container" trigger="click" size="medium">
        <div class="avatar-wrapper" style="cursor: pointer">
          <!-- <img :src="avatar + '?imageView2/1/w/80/h/80'" class="user-avatar" /> -->
          <!-- <img :src="my_avatar" class="user-avatar" /> -->
          <i class="el-icon-user" style="margin: 0 5px" />
          <span>{{ name }}</span>
          <i class="el-icon-caret-bottom" style="top: 20px" />
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <router-link to="/">
            <el-dropdown-item> 首页 </el-dropdown-item>
          </router-link>
          <!-- <a target="_blank" href="http://code.dev.jiumaojiu.com/ifirst/iMall">
            <el-dropdown-item>Gitlab</el-dropdown-item>
          </a> -->
          <el-dropdown-item divided @click.native="logout">
            <span style="display: block">退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import Hamburger from "@/components/Hamburger";
export default {
  name: "NavbarIndex",
  components: {
    Breadcrumb,
    Hamburger,
  },
  data() {
    return {
      my_avatar: "https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png",
      shop_options: [],
      // name: "",
    };
  },
  computed: {
    ...mapGetters(["sidebar", "avatar", "name", "auth_info"]),
  },
  created() {
    // let company = this.auth_info.company;
    // let group_obj = {
    //   label: "集团视角",
    //   options: [
    //     {
    //       shop_id: "",
    //       name: company == null ? "" : this.auth_info.company.company_name,
    //     },
    //   ],
    // };
    // let shop_obj = {
    //   label: "门店视角",
    //   options: this.auth_info.shops,
    // };
    // if (this.auth_info.enabled_group_view) {
    //   if (this.auth_info.shops.length != 0) {
    //     this.shop_options.push(group_obj, shop_obj);
    //   } else {
    //     this.shop_options.push(group_obj);
    //   }
    // } else {
    //   if (company != null) {
    //     this.shop_options.push(group_obj, shop_obj);
    //   } else {
    //     this.shop_options.push(shop_obj);
    //   }
    // }
    // this.name = this.auth_info.current_mall.name;
  },
  methods: {
    handleClick(visible, shouldPreventOpen = false) {
      if (visible && shouldPreventOpen) {
        this.$refs.select.blur();
        this.$message.warning("您没有访问数据的权限，请联系管理员");
      }
    },
    async handleShopChange(val) {
      this.$loading({ text: "加载中..." });
      try {
        await this.$store.dispatch("user/changeUserRole", val);
        // // 切换视角时重定向路由防止404
        // this.$router.replace("/goods/goods_list");
        location.reload();
      } catch (error) {
        console.log(error);
        // this.$message.error(error);
      }
      this.$loading().close();

      // this.$store.dispatch("tagsView/delAllViews");
    },
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    async logout() {
      await this.$store.dispatch("user/logout");

      // 记录上次退出登录时所在的页面，问题：切换不同权限时，由于有些页面低权限账号无法访问，会导致404
      // this.$router.push(`/login?redirect=${this.$route.fullPath}`);

      // 不记录上次退出登录时所在页面，每次登录时跳转到固定的页面
      this.$router.replace(`/login`);
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;
    margin-right: 10px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
