<template>
  <div>
    <sticky :z-index="20">
      <el-page-header content="订单详情" class="go-back" @back="goBack" />
    </sticky>
    <el-card v-loading="detailLoading" class="box-card">
      <div slot="header" class="order-header">
        <div class="header-item">订单号：{{ detailData.order_number }}</div>
        <div class="header-item">下单时间：{{ detailData.place_time }}</div>
      </div>
      <div>
        <el-row class="order-info">
          <el-col :span="10">
            <div v-if="detailData.status != 'CLOSED'">
              <div class="order-status">交易成功</div>
              <div class="order-note">买家已支付，交易成功。</div>
            </div>
          </el-col>
          <el-col :span="14">
            <el-steps :space="200" :active="detailData.status | activeNum" process-status="success" finish-status="success" align-center>
              <el-step v-for="(item, index) in detailData.operation_log_list" :key="index" :title="item.operation" :description="item.time" />
            </el-steps>
          </el-col>
        </el-row>

        <el-tabs v-if="false && detailData.delivery_sheet_info_list.length != 0" v-model="activePackage" type="card" style="min-height: 200px">
          <el-tab-pane
            v-for="(item, index) in detailData.delivery_sheet_info_list"
            :key="index"
            style="margin: 0 0 10px 20px"
            :label="`包裹${index + 1}`"
            :name="`包裹${index + 1}`"
          >
            <el-row>
              <el-col :span="6" class="order-info-wrap order-packages">
                <div v-if="item.express_item_list.length != 0">
                  <div class="order-info-item">发货人：{{ item.express_item_list[0].sender }}</div>
                  <div class="order-info-item">运单号： {{ item.express_item_list[0].logistics_order_number }}</div>
                  <div class="order-info-item">发货方式： {{ item.express_item_list[0].type }}</div>
                  <div class="order-info-item">发货时间： {{ formatTime(item.express_item_list[0].sent_date) }}</div>
                </div>

                <div class="packages-title" style="margin: 0">
                  <div v-for="(imgItem, index) in item.express_item_list" :key="index" style="width: 80px; margin-right: 5px">
                    <img style="width: 80px; margin: 10px 5px" :src="imgItem.img_url" />
                    <div class="fs12 text-ellipsis">{{ imgItem.sku_name }}x{{ imgItem.num }}</div>
                  </div>
                </div>
              </el-col>
              <el-col :span="12" class="order-info-wrap order-packages">
                <div style="margin: 0 0 20px 20px">
                  <span>物流状态：</span> <span style="color: #f56c6c">{{ item.state_str }}</span>
                </div>
                <el-timeline :reverse="true">
                  <el-timeline-item v-for="(jitem, jindex) in item.mail_tracks" :key="jindex" :timestamp="jitem.op_time">
                    {{ jitem.op_desc }}
                  </el-timeline-item>
                </el-timeline>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
        <!-- </el-row> -->

        <el-row v-for="(item, index) in detailData.oms_regular_order_item_dto_list" :key="index" class="order-info">
          <el-col :span="6" class="order-info-wrap">
            <div class="flex">
              <h5>激活状态： {{ item.regular_order_status | regularOrderText }}</h5>
              <el-button
                v-if="item.regular_order_status == 'NOACTIVATE' && detailData.status != 'CLOSED' && detailData.status != 'TO_BE_PAID'"
                type="text"
                style="margin-left: 10px"
                @click="handleActivity(item.regular_order_no)"
              >
                手动激活
              </el-button>
              <el-button type="text" style="margin-left: 10px" @click="() => handleShowBlessingDialog(item)"> 礼品卡 </el-button>
            </div>
            <div class="order-info-item">激活码：{{ item.cd_key }}</div>
            <div v-if="item.regular_order_status != 'NOACTIVATE'">
              <div class="order-info-item">激活时间：{{ item.activate_time }}</div>
            </div>
          </el-col>
          <el-col :span="6" class="order-info-wrap">
            <div class="flex">
              <h5>激活人信息</h5>
              <div v-if="item.regular_order_status != 'NOACTIVATE'" class="flex">
                <el-button type="text" style="margin-left: 10px" @click="handleUpdateAddressBtn(item)"> 修改 </el-button>
                <el-button type="text" @click="handleCopy('', $event, item)"> 复制 </el-button>
              </div>
            </div>
            <div class="order-info-item">收货人：{{ item.receiver_name }}</div>
            <div class="order-info-item">联系电话：{{ item.receiver_phone }}</div>
            <div class="order-info-item">收货地址：{{ item.province }}{{ item.city }}{{ item.county }}{{ item.receiver_address }}</div>
          </el-col>
          <el-col v-if="item.regular_order_status != 'NOACTIVATE'" :span="6" class="order-info-wrap">
            <h5>
              <el-button type="text" @click="handelDispatchDetail(item.oms_regular_order_item_detail_dto_list)"> 发货单详情 </el-button>
            </h5>
            <div class="order-info-item">共 24 期</div>
            <div class="order-info-item">
              已发货 <span style="color: red">{{ hasDispatchedPackage(item.oms_regular_order_item_detail_dto_list) }}</span> 期
            </div>
          </el-col>
        </el-row>

        <el-row class="order-info">
          <!-- <el-col :span="6" class="order-info-wrap" v-if="detailData.deliver_method != 'SELF_PICK_UP'">
            <div class="flex">
              <h5>收货人信息</h5>
              <el-button type="text" @click="updateAddressDialog = true" style="margin: 0 10px">修改</el-button>
              <el-button type="text" @click="handleCopy('', $event)">复制</el-button>
            </div>
            <div class="order-info-item">收货人：{{ detailData.receiver }}</div>
            <div class="order-info-item">联系电话：{{ detailData.receiver_phone }}</div>
            <div class="order-info-item">
              收货地址：{{ detailData.province }}{{ detailData.city }}{{ detailData.county }}{{ detailData.address_detail }}
            </div>
          </el-col> -->
          <el-col v-if="detailData.deliver_method != 'NO_NEED_TO_DELIVER'" :span="6" class="order-info-wrap">
            <h5>配送信息</h5>
            <div class="order-info-item">配送方式： 定时配送</div>
          </el-col>
          <el-col :span="6" class="order-info-wrap">
            <h5>付款信息</h5>
            <div class="order-info-item flex">
              实付金额：
              <div>￥{{ detailData.total_price }}</div>
            </div>
            <div class="order-info-item">付款方式：{{ DICT.PAY_TYPE_VALUE_DIC[detailData.pay_type] }}</div>
            <div class="order-info-item">付款时间：{{ detailData.pay_time }}</div>
          </el-col>
          <el-col :span="6" class="order-info-wrap">
            <h5>买家信息</h5>
            <div class="order-info-item">买家：{{ detailData.buyer_name }}</div>
            <div class="order-info-item">买家留言：{{ detailData.buyer_remark }}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-table
            :data="detailData.order_line_info_list"
            border
            stripe
            highlight-current-row
            style="width: 100%"
            :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
          >
            <el-table-column width="300" align="" prop="goods_name" label="商品名称">
              <template slot-scope="{ row }">
                <div class="flex-center" style="justify-content: start">
                  <img style="width: 80px" class="imgSty" :src="row.goods_sku_image_address" />
                  <div style="padding: 0 5px">
                    <el-tooltip
                      effect="dark"
                      :content="row.goods_name"
                      placement="top"
                      style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
                    >
                      <p>{{ row.goods_name }}</p>
                    </el-tooltip>
                    <div>{{ row.goods_sku_specification }}</div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="goods_sku_unit_price" label="单价">
              <template slot-scope="{ row }">
                <div>{{ isVirtual ? row.paid_credit : row.goods_sku_unit_price }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="shopping_num" label="数量" />
            <el-table-column align="center" prop="pay_ump_discount_money" label="优惠" />
            <el-table-column align="center" prop="receivable_money" label="小计">
              <template slot-scope="{ row }">
                <div>{{ (isVirtual ? row.paid_credit : row.shopping_num * row.goods_sku_unit_price) | toFixed(2) }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="after_sale_status" label="售后状态">
              <template slot-scope="{ row }">
                <el-button type="text" @click="handleAfterSaleDetail(row)">
                  {{ row.after_sale_status }}
                </el-button>
              </template>
            </el-table-column>

            <!-- <el-table-column prop="regular_order_status" align="center" label="激活状态">
              <template slot-scope="{ row }">
                {{ row.regular_order_status | regularOrderText }}
              </template>
            </el-table-column> -->
          </el-table>
        </el-row>
        <el-row style="display: flex; justify-content: flex-end; align-items: flex-end">
          <el-col :span="2">
            <div class="price-footer-l">商品总价：</div>
            <div class="price-footer-l">运费：</div>
            <div class="price-footer-l">优惠：</div>
            <div class="price-footer-l">实收金额：</div>
          </el-col>
          <el-col :span="2">
            <div class="price-footer-r">￥{{ detailData.total_price }}</div>
            <div class="price-footer-r">￥{{ detailData.freight || 0 }}</div>
            <div class="price-footer-r">￥{{ detailData.pay_ump_discount_money || 0 }}</div>
            <div class="price-footer-r" style="color: red; font-size: 20px">
              <span v-if="!isVirtual">￥{{ detailData.total_price }}</span>
              <span v-if="isVirtual">{{ detailData.paid_credit }} 积分</span>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <!-- 添加备注的弹窗 -->
    <el-dialog title="卖家备注" :visible.sync="dialogAddNote" width="40%">
      <el-input v-model="note_form.remark" type="textarea" placeholder="最多可输入256个字" maxlength="256" show-word-limit />
      <div slot="footer">
        <el-button type="text" @click="dialogAddNote = false"> 取消 </el-button>
        <el-button type="primary" @click="handleAddNoteConfirm"> 确定 </el-button>
      </div>
    </el-dialog>

    <!-- 发货的弹窗 -->
    <el-dialog title="订单发货" :visible.sync="dialogDispatchOrder" top="5vh">
      <dispatch-order :goods-data="currentGoodsData" @handleDelivery="handleDelivery" />
    </el-dialog>

    <!-- 激活人信息的弹窗 -->
    <el-dialog title="激活人信息" :visible.sync="updateAddressDialog" width="500px" top="5vh">
      <update-address
        :order-no="regularOrderNo"
        :detail-data="currentActivityDetailData"
        :is-update="isUpdateAddress"
        @closed="handleUpdateAddressClosed"
      />
    </el-dialog>

    <!-- 修改物流的弹窗 -->
    <el-dialog title="修改物流" :visible.sync="updateExpressDialog" width="800px" top="5vh">
      <update-express :order-no="order_number" @closed="handleUpdateExpressClosed" />
    </el-dialog>

    <!-- 发货单详情的弹窗 -->
    <el-dialog title="发货单详情" :visible.sync="dialogDispatchDetail" width="900px" top="5vh">
      <dispatch-schedule-detail :schedule-detail-data="scheduleDetailData" @closed="handleDispatchDetailClosed" />
    </el-dialog>

    <!-- 添加礼品卡信息的弹窗 -->
    <el-dialog title="礼品卡备注" :visible.sync="dialogBlessing" width="730px">
      <el-input v-model="blessing_form.sender_name" placeholder="购买人/购买单位（限10个字）" style="width: 200px" />
      送您的*季节食材(按实际送出食材显示)已发货，运单号为**********1234，祝您
      <el-input v-model="blessing_form.blessing" placeholder="祝福语（限20个字）" style="margin-top: 10px" />
      <div slot="footer">
        <el-button type="text" @click="dialogBlessing = false"> 取消 </el-button>
        <el-button type="primary" @click="handleAddBlessing"> 确定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api";
import DispatchOrder from "./components/DispatchOrder";
import UpdateAddress from "./components/UpdateAddress";
import UpdateExpress from "./components/UpdateExpress";
import DispatchScheduleDetail from "./components/DispatchScheduleDetail";
import Sticky from "@/components/Sticky";
import DICT from "@/constants/goods";
import { formatTime } from "@/utils/format-time";
import { regularOrderText } from "@/filters/regular_order";
import clip from "@/utils/clipboard";

export default {
  components: { DispatchOrder, UpdateAddress, UpdateExpress, DispatchScheduleDetail, Sticky },
  filters: {
    activeNum: function (val) {
      let map = {
        UNPAID: 0,
        NODELIVERY: 1,
        DELIVERED: 2,
        COMPLETED: 3,
      };
      return map[val];
    },
    regularOrderText,
  },
  data() {
    return {
      DICT,
      currentActivityDetailData: {}, // 当前激活单信息
      isUpdateAddress: false,
      scheduleDetailData: [], // 当前订单的发货单详情
      dialogDispatchDetail: false,
      formatTime,
      updateExpressDialog: false,
      updateAddressDialog: false,
      source: "order_mange", // order_mange 或 after_sales
      detailLoading: false,

      dialogAddNote: false,
      dialogDispatchOrder: false,
      order_number: "",

      detailData: { address: { receiver: "" }, mail_tracks: [], delivery_sheet_info_list: [] },
      activePackage: "包裹1",
      currentGoodsData: [],

      note_form: {
        id: "",
        order_number: "",
        remark: "",
      },

      dialogBlessing: false,
      blessing_form: {
        regular_order_id: undefined,
        sender_name: "",
        blessing: "",
      },

      regularOrderNo: null,
    };
  },
  computed: {
    isVirtual() {
      let { name, code } = this.$store.state.user.auth_info.current_mall;
      if (name === "积分商城" || code === "tr_jf") {
        return true;
      } else {
        return false;
      }
    },
  },

  activated() {
    this.order_number = this.$route.query.id;
    this.source = this.$route.query.source;
    this.getDetailByID();
  },
  created() {
    this.order_number = this.$route.query.id;
    this.source = this.$route.query.source;
    this.getDetailByID();
  },
  methods: {
    // 修改激活人信息的按钮
    handleUpdateAddressBtn(val) {
      this.updateAddressDialog = true;
      this.isUpdateAddress = true;
      this.regularOrderNo = val.regular_order_no;
      this.currentActivityDetailData = val;
    },
    // 发货单详情的按钮
    handelDispatchDetail(val) {
      this.scheduleDetailData = val;
      this.dialogDispatchDetail = true;
    },
    handleActivity(val) {
      this.updateAddressDialog = true;
      this.regularOrderNo = val;
    },
    handlePickedUp() {
      let { order_number, pick_up_code } = this.detailData;
      this.axios.post(`/emall_order/pickedUp`, { order_number, pick_up_code }).then(() => {
        this.$message.success("操作成功");
        this.getDetailByID();
      });
    },
    handleAddNoteConfirm() {
      this.axios.post(`/emall_order/remark`, { ...this.note_form }).then(() => {
        this.$notify.success("添加成功");
        this.dialogAddNote = false;
        this.note_form = {
          id: "",
          order_number: "",
          remark: "",
        };
      });
    },
    handleCopy(text, event, item) {
      let { receiver_name, receiver_phone, province, city, county, receiver_address } = item;
      text = `
收货人： ${receiver_name}
联系电话： ${receiver_phone}
收货地址： ${province}${city}${county}${receiver_address}`;
      clip(text, event);
    },
    handleUpdateExpress() {
      this.updateExpressDialog = true;
    },
    handleUpdateAddressClosed(v) {
      this.updateAddressDialog = false;
      if (v == "confirm") {
        this.getDetailByID();
      }
    },
    handleUpdateExpressClosed() {
      this.updateExpressDialog = false;
    },
    handleDispatchDetailClosed() {
      this.dialogDispatchDetail = false;
    },
    handleAfterSaleDetail(row) {
      this.$router.push({ path: "/order/sales_detail", query: { id: row.after_sale_no } });
    },
    async getDetailByID() {
      this.detailLoading = true;
      const [, res] = await api.order.regular({ resource: this.order_number });
      this.detailData = res;
      this.detailLoading = false;
    },
    goBack() {
      if (this.source == "order_mange") {
        this.$router.replace("/order/schedule_order");
      } else {
        this.$router.replace("/order/after_sales");
      }
    },
    handleAddNote() {
      //  this.note_form.id = row.id;
      this.note_form.order_number = this.order_number;
      this.dialogAddNote = true;
    },
    handleDispatchOrder() {
      this.dialogDispatchOrder = true;
      this.currentGoodsData = this.detailData;
    },
    handleDelivery(msg) {
      if (msg == 0) {
        this.getDetailByID();
        this.dialogDispatchOrder = false;
        this.$notify.success("操作成功");
      }
    },
    //已发货的期数
    hasDispatchedPackage(data) {
      let arr = data.filter((item) => item.order_states == "DELIVERY");
      return arr.length;
    },
    handleShowBlessingDialog(item) {
      this.blessing_form.regular_order_id = item.id;
      this.blessing_form.blessing = item.blessing;
      this.blessing_form.sender_name = item.sender_name;
      this.dialogBlessing = true;
    },
    validateDateBlessingForm() {
      let flag = true;
      if (this.blessing_form.blessing?.length > 20) {
        this.$message.error("祝福语最多填写20个字");
        flag = false;
      } else if (this.blessing_form.sender_name?.length > 10) {
        this.$message.error("购买人（购买单位）最多填写10个字");
        flag = false;
      }
      return flag;
    },
    async handleAddBlessing() {
      if (!this.validateDateBlessingForm()) return;
      const [err] = await api.order.updateRegularBlessing({
        method: "post",
        data: this.blessing_form,
      });
      if (err) return;

      this.dialogBlessing = false;
      this.$notify.success("保存成功");
      await this.getDetailByID();
    },
  },
};
</script>

<style lang="scss" scoped>
.go-back {
  margin: 0;
  padding-left: 10px;
}
.order-packages {
  height: inherit;
  overflow-y: scroll;
  padding: 15px 0;

  .packages-title {
    margin: 0 0 20px 40px;
    display: flex;
    align-items: center;
  }
}
.order-header {
  display: flex;
  font-size: 14px;
}
.header-item {
  margin-right: 100px;
}
.order-info {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  height: 200px;
}
.order-status {
  font-size: 22px;
  font-weight: bold;
  line-height: 44px;
}
.order-note,
.order-info-item {
  font-size: 14px;
  color: #bbb;
}
.order-note {
  line-height: 28px;
}
.order-info-item {
  line-height: 22px;
}
.price-footer {
  font-size: 14px;
}
.order-info-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: 30px;
}
.price-footer-l {
  display: flex;
  justify-content: flex-end;
}
.price-footer-l,
.price-footer-r {
  font-size: 14px;
  line-height: 28px;
}
</style>
