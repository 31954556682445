var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "upload-wrap",
    },
    [
      _c(
        "el-upload",
        {
          ref: "elUpload",
          class: {
            "hide-upload-holder": _vm.hideUpload,
          },
          attrs: {
            action: _vm.upload_qiniu_area,
            "auto-upload": true,
            limit: _vm.limitCount,
            multiple: true,
            accept: _vm.acceptType.join(","),
            "file-list": _vm.fileList,
            "list-type": _vm.listType,
            "on-preview": _vm.picCardPreview,
            "before-upload": _vm.beforePicUpload,
            "on-exceed": _vm.handleExceed,
            "on-remove": _vm.removePic,
            "http-request": _vm.uploadQiniu,
          },
        },
        [
          _vm.listType === "picture-card"
            ? _c(
                "div",
                {
                  staticClass: "el-icon-plus",
                  staticStyle: {
                    color: "#409eff",
                  },
                  attrs: {
                    slot: "default",
                  },
                  slot: "default",
                },
                [_vm._v("添加图片")]
              )
            : _vm._e(),
          _vm.listType === "text" ? _vm._t("btn") : _vm._e(),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: {
              width: "100%",
              src: _vm.dialogImageUrl,
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }