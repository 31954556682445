import JSEncrypt from "jsencrypt/bin/jsencrypt";

const publicKey =
  "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDpRm3Divqc51+cZ0iL5tKK3BAbQlvTIUMeC72ncBv1RvRBx93UfGfeFJ6aypnsZzbCvsw9xXORVYFPk2GL3WCr+gy7nbSlGwwKAXQRjYxFP/Pspt7FsXvolflNuqvPZvO/bEU0GctbF6WJsLSfsnnPIMLaaF/KApbI/RIi6oUZqwIDAQAB";

const privateKey =
  "MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBAOlGbcOK+pznX5xnSIvm0orcEBtCW9MhQx4LvadwG/VG9EHH3dR8Z94UnprKmexnNsK+zD3Fc5FVgU+T YYvdYKv6DLudtKUbDAoBdBGNjEU/8+ym3sWxe+iV+U26q89m879sRTQZy1sXpYmwtJ+yec8gwtpoX8oClsj9EiLqhRmrAgMBAAECgYEAxoY3l7jJO4r1JYgIh3dzKtXJ p0k8GFwwYHuhdcPFJ2KxMOJFeTY+txNZP2a4xCW6xtXVv6QwjDj2iaBl3cATKejC cNZnWhUsZDpBH+3VgaBY2HEU/Om2+NEAn4TdcPiMo7gbzRkAvkbm/Mzsnssa6gwceSmMLG/2T+ZRAsMeoxECQQD8hnTZCL0WM8PLx/x0yrMCXd8bmUXdDNTMldNyeeoTmLUUqh20cefvakcELzSoBZ4OxzV85CoRY1dxxxI5tGsPAkEA7HwpewB83AdestyN LLTnzEbDL+gQ+FZHqZUxkgGQec2H63yyS2YHlFi6s5n92N2I9DXNYCdC8vp5QvOOsYZXpQJAOFdPbrTyaye1gZvnCa/H7LlA9Aloev9H4zVybdIhlam2jRu/cOkRFGuj 2J0npG1MbIOpvWmysRp3Ah2mRVWppQJBALWUTIFeiYCkxAjQIjIOoeEPAHY7WTRlg1bp8IrsWpO7xb6CG6CbMfys+IZJGkm3KJ5i5VQMMXLB8fP/cTRMM+UCQB0HWOy4N3pE/KEwMK1uWh0Qu3tRNo1k+rdBgF4XLjQRuGNMqqTuRV3td68gbEFgSFB5KNN/FzEu5ILPH3jc6UM=";

const loginPublicKey = process.env.VUE_APP_LOGIN_PUBLIC_KEY;
// const loginPublicKey =
//   "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC8HNfr15E3auDMd777xb6zWmOweUXEL60LIlQUPZ0IA8s8LDUADblpK4OkaH6eGD+w85hIBRPEEqUELOMlwJco0xBPnGzUp1rE4cGDuyXY7R0YDHkyI1EOKKXsZovPeV3qGFyTazVFA6XTEqY8RO5Fct6rQI45nGHK9LHnKJ7+mQIDAQAB";

const loginEncryptor = new JSEncrypt();
loginEncryptor.setPublicKey(loginPublicKey); // 设置公钥
export function loginEncrypt(str) {
  const res = loginEncryptor.encrypt(str); // 对需要加密的数据进行加密
  return res;
}

// 加密
export function encrypt(txt) {
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(publicKey); // 设置公钥
  return encryptor.encrypt(txt); // 对需要加密的数据进行加密
}

// 解密
export function decrypt(txt) {
  const encryptor = new JSEncrypt();
  encryptor.setPrivateKey(privateKey);
  return encryptor.decrypt(txt);
}
