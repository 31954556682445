var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      ref: "progress",
      staticClass: "vac-player-bar",
      on: {
        mousedown: _vm.onMouseDown,
        mouseover: function mouseover($event) {
          return _vm.$emit("hover-audio-progress", true)
        },
        mouseout: function mouseout($event) {
          return _vm.$emit("hover-audio-progress", false)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "vac-player-progress",
        },
        [
          _c(
            "div",
            {
              staticClass: "vac-line-container",
            },
            [
              _c("div", {
                staticClass: "vac-line-progress",
                style: {
                  width: "".concat(_vm.percentage, "%"),
                },
              }),
              _c("div", {
                staticClass: "vac-line-dot",
                class: {
                  "vac-line-dot__active": _vm.isMouseDown,
                },
                style: {
                  left: "".concat(_vm.percentage, "%"),
                },
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }