import Layout from "@/layout";

const reservationRouter = {
  path: "/reservation",
  component: Layout,
  redirect: "/reserve_order",
  name: "reservation",
  meta: {
    title: "预定用餐",
    icon: "dishes",
    affix: true,
    roles: ["admin"],
  },
  children: [
    {
      path: "reservation_order",
      name: "ReservationOrder",
      component: () => import("@/views/reservation/meals-order"),
      meta: { title: "订单管理" },
    },

    {
      path: "reservation_manage",
      name: "ReservationManage",
      component: () => import("@/views/reservation/reserve-table"),
      meta: { title: "订位表" },
    },
    {
      path: "invoice_manage",
      name: "InvoiceManage",
      component: () => import("@/views/reservation/invoice_manage"),
      meta: { title: "发票管理" },
    },
  ],
};

export default reservationRouter;
