var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: "订单发货",
        top: "5vh",
      },
      on: {
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "el-row",
        {
          staticClass: "margin-item",
          attrs: {
            gutter: 10,
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: {
                width: "100%",
              },
              attrs: {
                data: _vm.detailData && _vm.detailData.order_line_info_list,
                border: "",
                stripe: "",
                "highlight-current-row": "",
                "header-cell-style": {
                  background: "#eef1f6",
                  color: "#606266",
                },
              },
              on: {
                "selection-change": _vm.handleSelectionChange,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  width: "55",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  width: "200",
                  align: "center",
                  prop: "name",
                  label: "商品名称",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref) {
                      var row = _ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "flex-center",
                            staticStyle: {
                              "justify-content": "start",
                            },
                          },
                          [
                            _c("el-image", {
                              staticStyle: {
                                "max-width": "80px",
                                height: "80px",
                                "margin-right": "10px",
                              },
                              attrs: {
                                src: row.goods_sku_image_address,
                                fit: "contain",
                              },
                            }),
                            _c(
                              "el-tooltip",
                              {
                                staticStyle: {
                                  overflow: "hidden",
                                  "text-overflow": "ellipsis",
                                },
                                attrs: {
                                  effect: "dark",
                                  content: row.goods_name,
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      "max-width": "210px",
                                    },
                                    attrs: {
                                      type: "text",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(row.goods_name) + " ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "shopping_num",
                  label: "数量",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "delivery_status",
                  formatter: function formatter(_, __, value) {
                    return _vm.ORDER_STATUS_VALUE_DIC[value]
                  },
                  label: "发货状态",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "logistics_order_no_list",
                  label: "运单号",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "margin-item",
          attrs: {
            gutter: 10,
          },
        },
        [
          _c(
            "el-col",
            {
              staticStyle: {
                "font-weight": "500",
              },
              attrs: {
                span: 3,
              },
            },
            [_vm._v(" 配送信息 ")]
          ),
          _c(
            "el-col",
            {
              attrs: {
                span: 20,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "order-info-item",
                },
                [
                  _vm._v(
                    "收货人：" +
                      _vm._s(
                        _vm.detailData &&
                          _vm.detailData.shipping_info.receiver_name
                      )
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "order-info-item",
                },
                [
                  _vm._v(
                    "收货地址：" +
                      _vm._s(
                        _vm.formatShippingAddress(
                          _vm.detailData && _vm.detailData.shipping_info
                        )
                      )
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "margin-item",
          attrs: {
            gutter: 10,
          },
        },
        [
          _c(
            "el-col",
            {
              staticStyle: {
                "font-weight": "500",
              },
              attrs: {
                span: 3,
              },
            },
            [_vm._v(" 发货方式 ")]
          ),
          _c(
            "el-col",
            {
              attrs: {
                span: 20,
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  on: {
                    change: _vm.handleDeliveryChange,
                  },
                  model: {
                    value: _vm.delivery_method,
                    callback: function callback($$v) {
                      _vm.delivery_method = $$v
                    },
                    expression: "delivery_method",
                  },
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: {
                        disabled: _vm.is_online_dispatch,
                        label: "online",
                      },
                    },
                    [_vm._v(" 在线下单 ")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: {
                        label: "manual",
                      },
                    },
                    [_vm._v(" 手动填写 ")]
                  ),
                ],
                1
              ),
              _vm.need_delivery
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-top": "20px",
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _vm._v(" 物流公司： "),
                          _c(
                            "el-select",
                            {
                              staticStyle: {
                                width: "200px",
                              },
                              model: {
                                value: _vm.logistics_company,
                                callback: function callback($$v) {
                                  _vm.logistics_company = $$v
                                },
                                expression: "logistics_company",
                              },
                            },
                            _vm._l(_vm.logisticsCompanies, function (item) {
                              return _c("el-option", {
                                key: item.code,
                                attrs: {
                                  label: item.name,
                                  value: item.code,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            margin: "10px 0",
                          },
                        },
                        [
                          _vm._v(" 物流单号： "),
                          _c("el-input", {
                            staticStyle: {
                              width: "200px",
                            },
                            model: {
                              value: _vm.tracking_number,
                              callback: function callback($$v) {
                                _vm.tracking_number = $$v
                              },
                              expression: "tracking_number",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          attrs: {
            gutter: 10,
            type: "flex",
            justify: "center",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.loading,
                type: "primary",
              },
              on: {
                click: _vm.handleDelivery,
              },
            },
            [_vm._v(" 发货 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }