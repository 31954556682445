<template>
  <div class="upload-wrap">
    <div class="upload-list">
      <draggable
        v-model="fileList"
        :options="{
          animation: 150,
          ghostClass: 'ghost',
        }"
        class="draggable-list"
        @end="handleDragEnd"
        @start="handleDragStart"
      >
        <div v-for="(file, index) in fileList" :key="index" class="upload-item">
          <img class="upload-image" :src="file.url" alt="" @click="picCardPreview(file)" />
          <span class="upload-actions">
            <span class="upload-action-item" @click="picCardPreview(file)">
              <i class="el-icon-zoom-in" />
            </span>
            <span class="upload-action-item" @click="handleReplace(file)">
              <i class="el-icon-sort rotate-90" />
            </span>
            <span class="upload-action-item" @click="handleSlotRemove(file)">
              <i class="el-icon-delete" />
            </span>
          </span>
          <template v-if="showAllButtons()">
            <el-button type="text" @click="handleSetCoverImage(file)"> 设置为封面图 </el-button>
          </template>
          <template v-else>
            <el-button :disabled="isButtonDisabled(file)" type="text" @click="handleSetCoverImage()">
              {{ isButtonDisabled(file) ? "设置为封面图" : "取消设置为封面图" }}
            </el-button>
          </template>
        </div>
      </draggable>
      <el-upload
        ref="upload"
        :class="{ 'hide-upload-holder': hideUpload }"
        class="upload-container-ref"
        :action="upload_qiniu_area"
        :auto-upload="true"
        :limit="limitCount"
        :multiple="true"
        :accept="accept"
        :file-list="[]"
        list-type="picture-card"
        :on-preview="picCardPreview"
        :before-upload="beforePicUpload"
        :on-exceed="handleExceed"
        :http-request="uploadQiniu"
      >
        <div slot="default" class="el-icon-plus" style="color: #409eff">添加图片</div>
      </el-upload>
    </div>

    <!-- 用于替换功能的隐藏上传组件 -->
    <el-upload
      ref="replaceUpload"
      class="hide"
      :action="upload_qiniu_area"
      :auto-upload="true"
      :show-file-list="false"
      :accept="accept"
      :before-upload="beforePicUpload"
      :http-request="handleReplaceUpload"
    >
      <el-button slot="trigger" style="display: none">选取文件</el-button>
    </el-upload>

    <el-dialog :visible.sync="dialogVisible" :modal="false">
      <img width="100%" :src="dialogImageUrl" />
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";

const upload_qiniu_address = "https://cdn.jmj1995.com/"; //七牛云返回储存图片的子域名（外链域名）

const fileTypeRegex = /image\/(png|jpg|jpeg|gif)/;
export default {
  name: "UploadPicture",
  components: {
    draggable,
  },
  props: {
    limitCount: {
      type: Number,
      default: 1,
      required: true,
    },
    alreadyFile: {
      type: [String, Array],
      default: "",
    },
    token: {
      type: String,
      default: "",
    },
    accept: {
      type: String,
      default: "image/jpg,image/png,image/jpeg,image/gif",
    },
    coverImageIndex: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {
      fileList: [],
      hideUpload: false,
      dialogImageUrl: "",
      dialogVisible: false,
      upload_qiniu_area: "https://upload-z2.qiniup.com", //七牛云上传储存区域的上传域名
      progressFlag: false,
      currentReplaceIndex: -1, // 当前要替换的图片索引
      originalCoverImage: null, // 添加新的数据属性用于存储原始封面图片信息
    };
  },
  computed: {
    component_coverImageIndex: {
      get() {
        return this.coverImageIndex;
      },
      set(newValue) {
        this.$emit("update:coverImageIndex", newValue);
      },
    },
  },

  watch: {
    alreadyFile(val) {
      if (this.limitCount == 1) {
        this.hideUpload = true;
      } else {
        this.hideUpload = this.limitCount <= val.length;
      }
      if (typeof val == "string" && val != "") {
        this.fileList = [{ url: val }];
      }
      if (Array.isArray(val) && val.length != 0) {
        this.initImage(val);
      }
      if (val == "") {
        this.hideUpload = false;
        this.fileList = [];
      }
    },
  },
  created() {
    let val = this.alreadyFile;
    if (typeof val == "string" && val != "" && this.limitCount == 1) {
      this.fileList = [{ url: val }];
      this.hideUpload = true;
    }
  },

  methods: {
    initImage(val) {
      let arrSet = new Set(val);
      let tempVal = [];
      for (let k of arrSet) {
        tempVal.push(k);
      }

      let tempArr = [];
      tempVal.forEach((item) => {
        let tempObj = { url: "" };
        tempObj.url = item;
        tempArr.push(tempObj);
      });
      this.fileList = tempArr;
    },
    picCardPreview(file) {
      //上传图预览
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforePicUpload(file) {
      //图片校验
      const limitPic = fileTypeRegex.test(file.type);
      if (!limitPic) {
        this.$notify.warning({
          title: "提示",
          message: "请上传格式为image/png,image/jpg,image/jpeg的图片",
        });
      }
      const limitSize = file.size / 1024 / 1024 < 2;
      if (!limitSize) {
        this.$notify.warning({
          title: "警告",
          message: "图片大小必须小于2M",
        });
      }
      return limitPic && limitSize;
    },
    handleSlotRemove(file) {
      const index = this.getImageIndex(file);
      const image = this.getImageFileByIndex(this.component_coverImageIndex);
      this.fileList.splice(index, 1);
      this.handleSetCoverImage(image);

      this.$emit("uploadSuccess", this.fileList);
    },
    getImageFileByIndex(index) {
      if (!Number.isFinite(index)) return null;
      return this.fileList[index];
    },
    getImageIndex(file) {
      return this.fileList.findIndex((item) => item === file);
    },
    // removePic(file, fileList) {
    //   //移除图片
    //   this.hideUpload = fileList.length >= this.limitCount;
    //   this.fileList = fileList;

    //   this.$emit("uploadSuccess", this.fileList);
    // },
    handleExceed() {
      // this.hideUpload = this.fileList.length >= this.limitCount;
      //文件超出个数限制
      this.$notify.warning({
        title: "提示",
        message: "一次只能上传 " + this.limitCount + " 张图片",
        duration: 2000,
      });
    },
    uploadQiniu(request) {
      this.hideUpload = true;
      //上传七牛
      this.handleUpload(request)
        .then((result) => {
          if (!result.data.key) {
            this.$notify.error({
              message: "图片上传失败,请重新上传",
              duration: 2000,
            });
          } else {
            this.fileList.push({ url: upload_qiniu_address + result.data.key });
            this.$emit("uploadSuccess", this.fileList);
            this.hideUpload = this.fileList.length >= this.limitCount;
          }
        })
        .catch((err) => {
          this.$notify.error({
            message: `图片上传失败${err}`,
            duration: 2000,
          });
        });
    },
    showAllButtons() {
      return !Number.isFinite(this.component_coverImageIndex);
    },
    handleSetCoverImage(file) {
      if (file) {
        // 如果传入了文件，就设置该文件为封面图
        const index = this.getImageIndex(file);
        this.component_coverImageIndex = index;
      } else {
        // 如果没有传入文件，就取消封面图设置
        this.component_coverImageIndex = null;
      }
    },
    isButtonDisabled(file) {
      return file.url !== this.fileList[this.component_coverImageIndex]?.url;
    },
    handleUpload(request) {
      const promise = new Promise((resolve, reject) => {
        const config = {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            let num = ((progressEvent.loaded / progressEvent.total) * 100) | 0; //百分比
            request.onProgress({ percent: Number(num) }); //进度条
          },
        };
        let fileType = "";
        if (fileTypeRegex.test(request.file.type)) {
          fileType = `.${request.file.type.split("/")[1]}`;
        }

        const key = `front_mall_${new Date().getTime()}${Math.floor(Math.random() * 100)}${fileType}`; //自定义图片名

        const fd = new FormData();
        fd.append("file", request.file);
        // fd.append("fileBinaryData", request.file);
        fd.append("token", this.token);
        fd.append("key", key);
        axios
          .post(this.upload_qiniu_area, fd, config)
          .then((res) => {
            if (res.status == 200 && res.data) {
              resolve({
                data: {
                  key,
                },
              });
            } else {
              reject(res);
            }
          })
          .catch((err) => {
            this.$notify.error({
              message: `上传失败[${err.status}]`,
              duration: 2000,
            });
          });
      });
      return promise;
    },
    handleReplace(file) {
      // 保存当前要替换的图片索引
      this.currentReplaceIndex = this.getImageIndex(file);
      // 触发隐藏的上传组件的点击事件
      this.$refs.replaceUpload.$el.querySelector("input").click();
    },
    handleReplaceUpload(request) {
      // 上传新图片
      this.handleUpload(request)
        .then((result) => {
          if (!result.data.key) {
            this.$notify.error({
              message: "图片上传失败,请重新上传",
              duration: 2000,
            });
          } else {
            // 替换原来位置的图片
            if (this.currentReplaceIndex !== -1) {
              this.$set(this.fileList, this.currentReplaceIndex, {
                url: upload_qiniu_address + result.data.key,
              });
              this.$emit("uploadSuccess", this.fileList);
            }
          }
        })
        .catch((err) => {
          this.$notify.error({
            message: `图片上传失败${err}`,
            duration: 2000,
          });
        });
    },
    handleDragStart() {
      // 在拖拽开始时保存原封面图片信息
      if (Number.isFinite(this.component_coverImageIndex)) {
        this.originalCoverImage = this.fileList[this.component_coverImageIndex];
      }
    },
    handleDragEnd() {
      // 拖拽结束后触发更新
      if (this.originalCoverImage) {
        // 在新的文件列表中找到原封面图片的新位置
        const newIndex = this.fileList.findIndex((file) => file === this.originalCoverImage);
        if (newIndex !== -1) {
          this.component_coverImageIndex = newIndex;
        }
        this.originalCoverImage = null; // 重置原始信息
      }
      this.$emit("uploadSuccess", this.fileList);
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-wrap ::v-deep .hide-upload-holder .el-upload--picture-card {
  display: none;
}
.upload-wrap ::v-deep .el-upload-list__item {
  transition: none !important;
}

.upload-container-ref ::v-deep .el-upload-list--picture-card .el-upload-list__item {
  height: 180px;
  border: unset;
  text-align: center;
  .upload-image {
    width: 148px;
    height: 148px;
    border-radius: 6px;
    border: 1px solid #c0ccda;
  }
  .el-upload-list__item-actions {
    width: 148px;
    height: 148px;
    border-radius: 6px;
    .el-upload-list__item-preview,
    .el-upload-list__item-delete,
    .el-upload-list__item-replace {
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.hide {
  display: none;
}

.upload-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: flex-start;
}

.draggable-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.upload-item {
  position: relative;
  width: 148px;
  height: 180px;
  border-radius: 6px;
  text-align: center;
  cursor: move;

  &:hover .upload-actions {
    opacity: 1;
  }

  .upload-image {
    width: 148px;
    height: 148px;
    border-radius: 6px;
    border: 1px solid #c0ccda;
  }

  .upload-actions {
    position: absolute;
    top: 0;
    left: 0;
    width: 148px;
    height: 148px;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .upload-action-item {
    color: #fff;
    font-size: 18px;
    margin: 0 7px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
