<template>
  <div id="sku">
    <!-- 规格设置 -->
    <div class="specification">
      <!-- <div class="title">产品规格设置</div> -->
      <ul class="spec-list">
        <li v-for="(item, index) in specification" :key="index" class="item">
          <div class="name">
            <el-input v-model="item.name" placeholder="规格名" @change="() => handleSpecChange('edit')" />
            <i class="icon el-icon-circle-close" @click="delSpec(index)" />
          </div>
          <div class="values">
            <draggable :list="item.value" @end="handleChangeSpecSort">
              <span v-for="(tag, num) in item.value" :key="tag" class="el-tag">
                <span class="el-select__tags-text">{{ tag }}</span>
                <i class="el-tag__close el-icon-close" @click="delSpecTag(index, num)" />
              </span>
            </draggable>
            <div class="add-attr">
              <el-input
                v-model="addValues[index]"
                placeholder="规格值"
                icon="plus"
                @click="addSpecTag(index)"
                @keyup.native.enter="addSpecTag(index)"
              >
                <el-button slot="append" icon="el-icon-check" type="primary" @click="addSpecTag(index)" />
              </el-input>
            </div>
          </div>
        </li>
        <div class="add-spec" style="padding: 7px 10px">
          <el-button type="info" :disabled="specification.length >= 5" @click="addSpec"> 添加规格项目 </el-button>
        </div>
      </ul>
    </div>

    <slot name="middle" />

    <!-- 规格表单 -->
    <div class="example">
      <table class="stock-table" cellspacing="0" cellpadding="0">
        <thead>
          <tr>
            <th v-for="(item, index) in specification" :key="index" style="width: 100px">
              {{ item.name }}
            </th>
            <th style="width: 120px">skuId</th>
            <th style="width: 150px">图片</th>
            <th v-if="goodsType !== TYPE_VALUE_MAP['REGULAR']" style="width: 150px">
              <span style="color: red">*</span>{{ goodsType === TYPE_VALUE_MAP["PHYSICAL"] ? "企得宝编码" : "关联优惠券" }}
              <batch-input
                v-if="goodsType === TYPE_VALUE_MAP['PHYSICAL']"
                placeholder="企得宝编码"
                @input="(val) => handleBatchUpdate('third_party_code', val)"
              ></batch-input>
            </th>
            <th style="width: 120px">
              成本价（元）<batch-input placeholder="成本价（元）" @input="(val) => handleBatchUpdate('cost_price', val)"></batch-input>
            </th>
            <th v-if="!isMember && isMoney" style="width: 120px">
              现金划线价<batch-input placeholder="现金划线价" @input="(val) => handleBatchUpdate('original_price', val)"></batch-input>
            </th>
            <th v-if="!isMember && isPoints" style="width: 120px">
              积分划线价<batch-input placeholder="积分划线价" @input="(val) => handleBatchUpdate('original_points', val)"></batch-input>
            </th>
            <th v-if="memberRights" style="width: 100px">
              会员权益
              <el-select
                v-model="batchData.memberRightTypeOption"
                filterable
                placeholder="请选择"
                @change="(val) => handleBatchUpdate('member_rights_type', val)"
              >
                <el-option v-for="item in getFilterMemberRight()" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </th>
            <th v-if="isMoney">
              <span style="color: red">*</span>现金售价<batch-input
                placeholder="现金售价"
                @input="(val) => handleBatchUpdate('retail_price', val)"
              ></batch-input>
            </th>
            <th v-if="isPoints">
              <span style="color: red">*</span>积分售价<batch-input
                placeholder="积分售价"
                @input="(val) => handleBatchUpdate('retail_points', val)"
              ></batch-input>
            </th>
            <th>
              <span style="color: red; width: 120px">*</span>库存<batch-input
                placeholder="库存"
                @input="(val) => handleBatchUpdate('stock_num', val)"
              ></batch-input>
            </th>
            <th>
              <span v-if="goodsType === TYPE_VALUE_MAP['PHYSICAL']" style="color: red; width: 120px">*</span>重量（kg）<batch-input
                placeholder="重量（kg）"
                @input="(val) => handleBatchUpdate('weight', val)"
              ></batch-input>
            </th>
          </tr>
        </thead>
        <tbody v-if="specification[0] && specification[0].value.length">
          <tr v-for="(_, index) in countSum(0)" :key="index" style="text-align: center">
            <template v-for="(n, specIndex) in specification.length">
              <td v-if="showTd(specIndex, index)" :key="n" :rowspan="countSum(n)">
                {{ getSpecAttr(specIndex, index) }}
              </td>
            </template>
            <td>
              {{ showSkuId ? skus[index].id : "-" }}
            </td>
            <td>
              <upload-picture
                :limit-count="1"
                :already-file="skus[index].image_address"
                :token="uploadToken"
                @uploadSuccess="(uploadArr) => handleUploadSuccess(uploadArr, index)"
              />
            </td>
            <td v-if="goodsType !== TYPE_VALUE_MAP['REGULAR']" :style="`width: ${isShoppingMall ? '220px' : '100px'}`">
              <el-input
                v-if="goodsType === TYPE_VALUE_MAP['PHYSICAL']"
                v-model="skus[index].third_party_code"
                type="text"
                placeholder="输入企得宝编码"
              />
              <template v-if="goodsType === TYPE_VALUE_MAP['ELECTRONIC']">
                <template v-if="!isShoppingMall">
                  <el-select
                    v-model="skus[index].third_party_code"
                    v-loadmore="loadMoreCoupons"
                    :remote-method="getNameCoupons"
                    remote
                    filterable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in couponData"
                      :key="item.third_party_code"
                      :label="`${item.name}(${item.third_party_code})`"
                      :value="item.third_party_code"
                    />
                  </el-select>
                </template>
                <div v-else class="flex">
                  <el-select v-model="currentBrand" placeholder="请选择品牌" @change="handleChangeBrand">
                    <el-option v-for="item in brandList" :key="item.code" :label="item.name" :value="item.code"></el-option>
                  </el-select>
                  <el-select
                    v-model="skus[index].third_party_code"
                    v-loadmore="loadMoreCoupons"
                    :remote-method="getNameCoupons"
                    remote
                    filterable
                    placeholder="请选择票券"
                  >
                    <el-option
                      v-for="item in couponData"
                      :key="item.third_party_code"
                      :label="`${item.name}(${item.third_party_code})`"
                      :value="item.third_party_code"
                    />
                  </el-select>
                </div>
              </template>
            </td>
            <td style="width: 100px">
              <el-input :key="skus[index].id" v-model.number="skus[index].cost_price" type="text" placeholder="输入成本价" />
            </td>
            <td v-if="!isMember && isMoney">
              <el-input :key="skus[index].id" v-model.number="skus[index].original_price" type="text" placeholder="输入划线价" />
            </td>
            <td v-if="!isMember && isPoints">
              <el-input :key="skus[index].id" v-model.number="skus[index].original_points" type="text" placeholder="积分划线价" />
            </td>
            <td v-if="isMember">
              <el-select v-model="skus[index].member_rights_type" filterable placeholder="请选择">
                <el-option v-for="item in getFilterMemberRight()" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </td>
            <td v-if="isMoney" style="width: 100px">
              <el-input :key="skus[index].id" v-model="skus[index].retail_price" type="text" placeholder="输入售价" />
            </td>
            <td v-if="isPoints" style="width: 100px">
              <el-input :key="skus[index].id" v-model="skus[index].retail_points" type="text" placeholder="积分售价" />
            </td>
            <td style="width: 100px">
              <el-input :key="skus[index].id" v-model="skus[index].stock_num" type="text" placeholder="输入库存" />
            </td>
            <td style="width: 100px">
              <el-input :key="skus[index].id" v-model="skus[index].weight" type="text" placeholder="输入重量" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import UploadPicture from "@/components/Upload/UploadImg";
import { GOODS, MALL } from "@/constants";
import api from "@/api";
import BatchInput from "./BatchInput.vue";
import { to } from "@/utils/common";

const { TYPE_VALUE_MAP, PAY_TYPE_VALUE_MAP, MEMBER_RIGHTS_TYPE } = GOODS;
const { MALL_CODE_VALUE_MAP } = MALL;

// 为Object添加一个原型方法，判断两个对象是否相等
function objEquals(object1 = {}, object2 = {}) {
  for (let propName in object1) {
    if (Object.prototype.hasOwnProperty.call(object1, propName) !== Object.prototype.hasOwnProperty.call(object2, propName)) {
      return false;
    } else if (typeof object1[propName] !== typeof object2[propName]) {
      return false;
    }
  }
  for (let propName in object2) {
    if (Object.prototype.hasOwnProperty.call(object1, propName) !== Object.prototype.hasOwnProperty.call(object2, propName)) {
      return false;
    } else if (typeof object1[propName] !== typeof object2[propName]) {
      return false;
    }

    if (!Object.prototype.hasOwnProperty.call(object1, propName)) {
      continue;
    }

    /** REQUIRES Array.equals**/
    if (object1[propName] instanceof Array && object2[propName] instanceof Array) {
      if (objEquals(!object1[propName], object2[propName])) {
        return false;
      }
    } else if (object1[propName] instanceof Object && object2[propName] instanceof Object) {
      if (objEquals(!object1[propName], object2[propName])) {
        return false;
      }
    } else if (object1[propName] !== object2[propName]) {
      return false;
    }
  }
  return true;
}
export default {
  name: "GoodListSku",
  directives: {
    loadmore: {
      bind(el, binding) {
        const wrapDom = el.querySelector(".el-select-dropdown .el-select-dropdown__wrap");
        wrapDom.addEventListener("scroll", () => {
          if (wrapDom.scrollHeight - wrapDom.scrollTop < wrapDom.clientHeight + 3) {
            typeof binding.value === "function" && binding.value();
          }
        });
      },
    },
  },
  components: {
    UploadPicture,
    BatchInput,
    draggable,
  },
  props: {
    uploadToken: {
      type: String,
      default: "",
    },
    specification: {
      type: Array,
      default: () => [],
    },
    skusOrigin: {
      type: Array,
      default: () => [],
    },
    /**
     * 商品类型， PHYSICAL:有实物，ELECTRONIC:虚拟的，REGULAR:定期发货的
     */
    goodsType: {
      type: String,
      default: "PHYSICAL",
    },
    /**
     * 支付方式,可用值:MONEY,POINTS,MIX_MONEY_POINTS
     */
    payType: {
      type: String,
      default: "MONEY",
    },
    /**
     * 会员权益
     */
    memberRights: Boolean,
  },
  data() {
    return {
      brandList: [],
      currentBrand: undefined,
      batchData: {
        memberRightTypeOption: undefined,
      },
      TYPE_VALUE_MAP,
      PAY_TYPE_VALUE_MAP,
      MEMBER_RIGHTS_TYPE,
      hasMoreCoupon: true,
      couponParams: {
        page: 1,
        size: 50,
        search_text: "",
      },
      couponData: [], //优惠券
      // 规格
      specificationStatus: false, // 显示规格列表
      skus: this.skusOrigin,
      showSkuId: true,
      firstTime: true,
      // specification: [
      // {
      //   name: "颜色",
      //   value: ["黑色", "白色"],
      // },
      // ],
      // 子规格
      // skus: [
      // {
      //   id: 0,
      //   props: { 颜色: "黑色" },
      //   third_party_code: "SPU_000",
      //   stock_num: 0,
      //   retail_price: 0,
      //   original_price: 0,
      //   weight: 0,
      // },
      // {
      //   id: 0,
      //   props: { 颜色: "白色" },
      //   third_party_code: "SPU_001",
      //   stock_num: 0,
      //   retail_price: 0,
      //   original_price: 0,
      //   weight: 0,
      // },
      // ],
      // 用来存储要添加的规格属性
      addValues: [],
      // 默认商品编号
      // defaultProductNo: "SPU_",
      defaultProductNo: "",
      isShoppingMall: false,
    };
  },
  computed: {
    // 所有处理过sku的object
    // stockSpecArr() {
    //   return this.skusOrigin.map((item) => item.specification);
    // },
    // ...mapGetters(["isGroupMall"]),
    isMember() {
      return this.memberRights;
    },
    isPoints() {
      return this.payType !== PAY_TYPE_VALUE_MAP.MONEY;
    },
    isMoney() {
      return this.payType !== PAY_TYPE_VALUE_MAP.POINTS;
    },
  },
  watch: {
    skusOrigin: function (val) {
      this.skus = val;
    },
    // goodsType: function (val) {
    //   if (val === TYPE_VALUE_MAP["ELECTRONIC"]) this.getCouponList();
    // },
    payType: function () {
      if (this.firstTime) return (this.firstTime = false);
      for (let i = 0; i < this.skus.length; i++) {
        this.skus[i].member_rights_type = this.getDefaultMemberRightTypeOption();
      }
    },
  },
  async mounted() {
    this.isShoppingMall = this.$store.getters.auth_info?.current_mall.code === "group_shopping_mall";
    if (this.isShoppingMall) {
      await this.fetchBrandList();
    } else {
      this.loadMoreCoupons();
    }
  },
  methods: {
    async fetchBrandList() {
      const [, res] = await api.member.listBrand();
      if (res) {
        this.brandList = res;
      }
    },
    handleBatchUpdate(key, value) {
      this.skus.forEach((item) => {
        item[key] = value;
      });
    },
    isTaierPoints() {
      // 太二积分默认选积分类型，其他默认无权益
      const currentMall = this.$store.state.user.auth_info.current_mall;
      return MALL_CODE_VALUE_MAP["taier_points_mall"] === currentMall.code;
    },
    getDefaultMemberRightTypeOption() {
      // 太二积分默认选积分类型，其他默认无权益
      return this.isTaierPoints() ? 2 : 0;
    },
    hideSkuId() {
      this.showSkuId = false;
    },
    resetCouponParams() {
      this.hasMoreCoupon = true;
      this.couponParams = {
        page: 1,
        size: 50,
        search_text: "",
      };
      this.couponData = [];
    },
    getNameCoupons(name) {
      if (this.isShoppingMall && !this.currentBrand) return;
      this.resetCouponParams();
      this.couponParams.search_text = name;
      this.loadMoreCoupons();
    },
    async loadMoreCoupons() {
      if (!this.hasMoreCoupon) return;
      const [err, res] = await to(this.getCouponList());
      if (err) return;
      const { list, hasMoreCoupon } = res;
      this.couponData.push(...list);
      this.hasMoreCoupon = hasMoreCoupon;
      this.couponParams.page++;
    },
    async getCouponList() {
      const [, data] = await api.goods.pageCoupons({ method: "post", data: { brand_code: this.currentBrand, ...this.couponParams } });
      return { list: data.list ?? [], hasMoreCoupon: data.has_next_page };
    },
    handleChangeBrand(brandCode) {
      this.resetCouponParams();
      this.loadMoreCoupons({ brandCode });
      this.skus.forEach((item) => {
        item.brand_code = brandCode;
        item.third_party_code = undefined;
      });
    },
    // 给父类调用让goodsType变化的时候清空thirdPartyCode
    clearThirdPartyCode() {
      this.skus = this.skus.map((item) => ({ ...item, third_party_code: undefined }));
    },
    // 添加规格项目
    addSpec() {
      this.hideSkuId();
      if (this.specification.length < 5) {
        // todo: 去掉eslint-disable-next-line
        // eslint-disable-next-line vue/no-mutating-props
        this.specification.push({
          name: "",
          value: [],
        });
      }
    },
    // 删除规格项目
    delSpec(index) {
      // todo: 去掉eslint-disable-next-line
      // eslint-disable-next-line vue/no-mutating-props
      this.specification.splice(index, 1);
      this.handleSpecChange("del");
    },
    // 添加规格属性
    addSpecTag(index) {
      this.hideSkuId();
      let str = (this.addValues[index] || "").trim();
      if (!str.trim()) return; // 判空
      let arr = str.split(/\s+/); // 使用空格分割成数组
      let newArr = [...this.specification[index].value, ...arr];
      newArr = Array.from(new Set(newArr)); // 去重
      this.$set(this.specification[index], "value", newArr);
      this.clearAddValues(index);
      this.handleSpecChange("add");
    },
    // 删除规格属性
    delSpecTag(index, num) {
      // todo: 去掉eslint-disable-next-line
      // eslint-disable-next-line vue/no-mutating-props
      this.specification[index].value.splice(num, 1);
      this.handleSpecChange("del");
    },
    // 清空 addValues
    clearAddValues(index) {
      this.$set(this.addValues, index, "");
    },
    /*
        根据传入的属性值，拿到相应规格的属性
        @params
          specIndex 规格项目在 advancedSpecification 中的序号
          index 所有属性在遍历时的序号
      */
    getSpecAttr(specIndex, index) {
      // 获取当前规格项目下的属性值
      const currentValues = this.specification[specIndex].value;
      let indexCopy;
      // 判断是否是最后一个规格项目
      if (this.specification[specIndex + 1] && this.specification[specIndex + 1].value.length) {
        indexCopy = index / this.countSum(specIndex + 1);
      } else {
        indexCopy = index;
      }
      const i = Math.floor(indexCopy % currentValues.length);
      if (i.toString() !== "NaN") {
        return currentValues[i];
      } else {
        return "";
      }
    },
    /*
        计算属性的乘积
        @params
          specIndex 规格项目在 advancedSpecification 中的序号
      */
    countSum(specIndex) {
      let num = 1;
      this.specification.forEach((item, index) => {
        if (index >= specIndex && item.value.length) {
          num *= item.value.length;
        }
      });
      return num;
    },
    // 根据传入的条件，来判断是否显示该td
    showTd(specIndex, index) {
      // 如果当前项目下没有属性，则不显示
      if (!this.specification[specIndex]) {
        return false;
        // 自己悟一下吧
      } else if (index % this.countSum(specIndex + 1) === 0) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * [handleSpecChange 监听标签的变化,当添加标签时；求出每一行的hash id，再动态变更库存信息；当删除标签时，先清空已有库存信息，然后将原有库存信息暂存到stockCopy中，方便后面调用]
     * @param  {string} option ['add'|'del' 操作类型，添加或删除]
     * @return {[type]}        [description]
     */
    handleSpecChange(option) {
      const stockCopy = JSON.parse(JSON.stringify(this.skus));
      if (option !== "edit") {
        this.skus = [];
      }
      for (let i = 0; i < this.countSum(0); i++) {
        this.changeStock(option, i, stockCopy);
      }
    },
    handleChangeSpecSort() {
      this.emitSkus();
    },
    /**
     * [根据规格，动态改变库存相关信息]
     * @param  {string} option    ['add'|'del' 操作类型，添加或删除]
     * @param  {number} index
     * @param  {array} stockCopy [库存信息的拷贝]
     * @return {[type]}           [description]
     */
    changeStock(option, index, stockCopy) {
      let childProduct = {
        specification: this.getprops(index),
        third_party_code: this.defaultProductNo,
        cost_price: "",
        stock_num: "",
        retail_price: "",
        retail_points: "",
        original_price: "",
        original_points: "",
        member_rights_type: this.getDefaultMemberRightTypeOption(), // 会员权益类型 - 0:无权益,1:现金折扣,2积分折扣,3积分+现金折扣
        weight: "",
      };
      const spec = childProduct.specification;
      if (option === "add") {
        const existedProduct = stockCopy.find((item) => objEquals(spec, item.specification));
        // if (!stockCopy.find((item) => objEquals(spec, item))) {
        this.skus.push(existedProduct || childProduct);
        // }
        // this.skus.push(existedProduct)
      } else if (option === "del") {
        // 因为是删除操作，理论上所有数据都能从stockCopy中获取到
        const origin = stockCopy.find((item) => objEquals(spec, item.specification));
        this.skus.push(origin || childProduct);
      } else if (option === "edit") {
        if (this.skus[index]) this.skus[index].specification = spec;
      }

      this.emitSkus();
    },
    emitSkus() {
      this.$emit("skus", this.skus, this.specification);
    },
    // 获取skus的props属性
    getprops(index) {
      let obj = {};
      this.specification.forEach((item, specIndex) => {
        obj[item.name] = this.getSpecAttr(specIndex, index);
      });
      return obj;
    },

    // 监听商品编号的blur事件
    handleNo(index) {
      // 1.当用户输入完商品编号时，判断是否重复，如有重复，则提示客户并自动修改为不重复的商品编号
      const value = this.skus[index].third_party_code;
      let isRepet;
      this.skus.forEach((item, i) => {
        if (item.third_party_code === value && i !== index) {
          isRepet = true;
        }
      });
      if (isRepet) {
        this.$message({
          type: "warning",
          message: "不允许输入重复的商品编号",
        });
        // this.$set(this.skus[index], "third_party_code", this.makeProductNoNotRepet(index));
        this.$set(this.skus[index], "third_party_code", "");
      }
    },
    // 生成不重复的商品编号
    makeProductNoNotRepet(index) {
      let No;
      let i = index;
      let isRepet = true;
      while (isRepet) {
        No = this.defaultProductNo + i;
        isRepet = this.isProductNoRepet(No);
        i++;
      }
      return No;
    },
    // 商品编号判重
    isProductNoRepet(No) {
      const result = this.skus.findIndex((item) => {
        return item.third_party_code === No;
      });
      return result > -1;
    },
    handleUploadSuccess(uploadArr = [], index) {
      const { url } = uploadArr?.[0] ?? {};
      this.skus[index].image_address = url;
    },
    getFilterMemberRight() {
      const filterArr = this.isTaierPoints() ? [] : ["无权益"];
      if (this.payType === PAY_TYPE_VALUE_MAP.MONEY) filterArr.push("现金折扣");
      if (this.payType === PAY_TYPE_VALUE_MAP.POINTS) filterArr.push("积分折扣");
      if (this.payType === PAY_TYPE_VALUE_MAP.MIX_MONEY_POINTS) filterArr.push("现金折扣", "积分折扣", "积分+现金折扣");
      return MEMBER_RIGHTS_TYPE.filter((item) => filterArr.includes(item.label));
    },
  },
};
</script>

<style lang="scss">
* {
  list-style: none;
  padding: 0;
  border: 0;
}

#sku {
  .upload-wrap {
    .el-upload--picture-card {
      width: 100px;
      height: 100px;
      line-height: 100px;
    }

    .el-upload-list__item {
      margin: 0;
      width: 100px;
      height: 100px;
    }

    .el-progress--circle {
      width: 80px;

      .el-progress-circle {
        width: 80px !important;
        height: 80px !important;
      }
    }
  }

  .title {
    padding: 0 12px;
    line-height: 1;
    font-size: 18px;
    border-left: 4px solid #50bfff;
    color: #666;
    margin: 0 0 16px 0;
    font-weight: 400;
  }

  .example {
    margin-top: 25px;

    .code-area {
      width: 100%;
      min-height: 300px;
      box-sizing: border-box;
      padding: 20px;
      border: 2px dashed #c00;
      font-size: 14px;
      line-height: 1.6;
    }
  }

  .specification {
    display: inline-block;
    vertical-align: top;
    width: 100%;

    .spec-list {
      width: auto;
      background-color: #fff;
      border: 1px solid #d8d8d8;
      padding: 10px;

      .item {
        margin-top: 5px;

        &:first-child {
          margin-top: 0;
        }

        .name {
          background: #f3f6fb;
          padding: 2px 8px;
          text-align: right;
          overflow: hidden;

          .el-input {
            float: left;
            width: 150px;
          }

          .icon {
            display: none;
            color: #929292;
            cursor: pointer;

            &:hover {
              color: #880000;
            }
          }

          &:hover {
            .icon {
              display: inline-block;
            }
          }
        }

        .values {
          display: flex;
          align-items: center;

          .el-tag {
            height: 28px;
            line-height: 28px;
            margin: 8px 0 0 8px;
          }

          .add-attr {
            display: inline-block;
            vertical-align: top;
            margin-top: 4px;

            .el-input {
              width: 200px;
              margin: 2px 0 0 4px;
            }
          }
        }
      }

      .add-spec {
        font-size: 13px;
      }
    }
  }

  .stock-table {
    // width: 740px;
    width: 100%;
    padding: 0;
    border-collapse: separate;
    border-color: #dfe6ec;
    border-style: solid;
    border-width: 1px 0 0 1px;
    background-color: #fff;

    td,
    th {
      padding: 4px 10px;
      border-bottom: 1px solid #dfe6ec;
      border-right: 1px solid #dfe6ec;
    }

    th {
      line-height: 30px;
      background-color: #eef1f6;
    }

    .link {
      cursor: pointer;
      color: #0088ee;
      font-size: 13px;
      margin-left: 6px;
    }

    .wh-foot {
      line-height: 30px;

      .label {
        display: inline-block;
        vertical-align: top;
      }

      .set-list {
        display: inline-block;
        vertical-align: top;
        font-size: 0;

        .set-item {
          display: inline-block;
          vertical-align: top;
          margin-left: 15px;
          font-size: 13px;
          cursor: pointer;
          color: #0088ee;
        }
      }

      .set-form {
        display: inline-block;
        margin-left: 10px;

        .el-input {
          display: inline-block;
          width: 120px;
        }

        .set-btn {
          display: inline-block;
          padding: 0 2px;
          font-size: 15px;
          cursor: pointer;

          &.blue {
            color: #0088ee;
          }

          &.red {
            color: #cc0000;
          }
        }
      }

      .right {
        float: right;
      }

      .text {
        font-size: 13px;
      }
    }
  }
}
</style>
