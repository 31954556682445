var TableListMixin = {
  data() {
    return {
      total: 0,
      listQuery: {
        page: 1,
        limit: 20,
      },
      listLoading: false,
      searchForm: {},
      dialogStatus: "", //创建 和 编辑表单的弹窗
      textMap: {
        view: "查看",
        update: "修改",
        create: "新增",
      },
    };
  },
  methods: {
    async getListMixin(api) {
      this.listLoading = true;
      let { page, limit } = this.listQuery;
      let { searchForm } = this;

      const _searchForm = {};
      // 过滤空值
      for (const [key, value] of Object.entries(searchForm ?? {})) {
        if (value !== "") _searchForm[key] = value;
      }

      let params = { ..._searchForm, size: limit, page };
      const [err, res] = await api({ params });
      this.total = res?.total ?? 0;
      this.listLoading = false;
      return [err, res];
    },

    // 简单的搜索表单直接使用此方法即可，对于含日期范围的搜索表单可能需要重写此方法
    resetSearchForm() {
      for (let key in this.searchForm) {
        this.searchForm[key] = "";
      }
      this.getList();
    },
  },
};
export default TableListMixin;

{
  /* <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" /> */
}
