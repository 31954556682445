var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      ref: "imageRef",
      staticClass: "vac-image-container",
    },
    [
      _c("loader", {
        style: {
          top: "".concat(_vm.imageResponsive.loaderTop, "px"),
        },
        attrs: {
          show: _vm.isImageLoading,
        },
      }),
      _c(
        "div",
        [
          _c("el-image", {
            class: {
              "vac-image-loading":
                _vm.isImageLoading &&
                _vm.message.senderId === _vm.currentUserId,
            },
            style: {
              "background-image": "url('".concat(_vm.imageBackground, "')"),
              // 'max-height': `${imageResponsive.maxHeight}px`,
            },
            attrs: {
              fit: "scale-down",
              src: _vm.formattedImageUrl || _vm.message.file.url,
              "preview-src-list": _vm.srcList,
            },
            on: {
              click: function click($event) {
                $event.stopPropagation()
                return _vm.handleClickItem.apply(null, arguments)
              },
            },
          }),
          _c(
            "transition",
            {
              attrs: {
                name: "vac-fade-image",
              },
            },
            [
              _vm.imageHover && !_vm.isImageLoading
                ? _c(
                    "div",
                    {
                      staticClass: "vac-image-buttons",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "vac-svg-button vac-button-view",
                          on: {
                            click: function click($event) {
                              $event.stopPropagation()
                              return _vm.$emit("open-file", "preview")
                            },
                          },
                        },
                        [
                          _vm._t("eye-icon", function () {
                            return [
                              _c("svg-icon", {
                                attrs: {
                                  name: "eye",
                                },
                              }),
                            ]
                          }),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "vac-svg-button vac-button-download",
                          on: {
                            click: function click($event) {
                              $event.stopPropagation()
                              return _vm.$emit("open-file", "download")
                            },
                          },
                        },
                        [
                          _vm._t("document-icon", function () {
                            return [
                              _c("svg-icon", {
                                attrs: {
                                  name: "document",
                                },
                              }),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _c("format-message", {
        attrs: {
          content: _vm.message.content,
          users: _vm.roomUsers,
          "text-formatting": _vm.textFormatting,
          "link-options": _vm.linkOptions,
        },
        on: {
          "open-user-tag": function openUserTag($event) {
            return _vm.$emit("open-user-tag")
          },
        },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.$scopedSlots, function (i, name) {
              return {
                key: name,
                fn: function fn(data) {
                  return [_vm._t(name, null, null, data)]
                },
              }
            }),
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }