<template>
  <div class="page-padding">
    <div class="page-header--wrap">
      <div class="page-header-wrap--title">店铺管理</div>
      <!--      <div>-->
      <!--        <el-button icon="el-icon-plus" type="primary" @click="handleAddShop"> 新增店铺 </el-button>-->
      <!--      </div>-->
    </div>

    <el-table v-loading="listLoading" :data="shopData" stripe border highlight-current-row style="width: 100%">
      <el-table-column type="index" align="center" width="60">
        <template slot="header"> 序号 </template>
      </el-table-column>
      <el-table-column label="操作" width="300" align="center">
        <template slot-scope="{ row }">
          <el-button type="text" @click="handleUpdateShop(row)"> 编辑 </el-button>
          <!--          <el-popconfirm title="确定删除该数据吗？" style="margin-left: 10px" placement="left" @onConfirm="handleDelete(row)">-->
          <!--            <el-button type="text" slot="reference"> 删除 </el-button>-->
          <!--          </el-popconfirm>-->
        </template>
      </el-table-column>

      <el-table-column align="center" prop="code" label="店铺编码" show-overflow-tooltip />
      <el-table-column align="center" prop="name" label="店铺名称" show-overflow-tooltip />
      <el-table-column align="center" prop="description" label="描述" show-overflow-tooltip />
      <el-table-column align="center" show-overflow-tooltip label="创建人/修改人">
        <template slot-scope="{ row }"> {{ row.creator }} / {{ row.updater }} </template>
      </el-table-column>
      <el-table-column align="center" show-overflow-tooltip label="创建时间/修改时间">
        <template slot-scope="{ row }"> {{ row.create_time }} / {{ row.update_time }} </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

    <!-- 新增账号的弹窗 -->
    <el-dialog :title="`${textMap[dialogStatus]}店铺`" :visible.sync="dialogShopForm" width="500px">
      <el-form ref="shopForm" :model="shopForm" :rules="staffRules" label-width="90px">
        <el-form-item label="店铺名称" prop="name">
          <el-input v-model="shopForm.name" :disabled="dialogStatus == 'update'" />
        </el-form-item>
        <el-form-item label="店铺编码" prop="code">
          <el-input v-model="shopForm.code" :disabled="dialogStatus == 'update'" />
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input v-model="shopForm.description" type="textarea" />
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button type="text" @click="dialogShopForm = false"> 取消 </el-button>
        <el-button type="primary" @click="handleDialogConfirm">
          {{ dialogStatus == "update" ? "修改" : "确定" }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api";
import Pagination from "@/components/Pagination";
import TableListMixin from "@/mixin/TableList";
import * as R from "ramda";
export default {
  name: "ShopManage",
  components: { Pagination },
  mixins: [TableListMixin],
  data() {
    return {
      listLoading: false,
      shopData: [],
      shopForm: {
        name: "",
        code: "",
        description: "",
      },
      staffRules: {
        name: [{ required: true, message: "请填写店铺名称" }],
      },
      dialogShopForm: false,
    };
  },

  created() {
    this.getList();
  },
  methods: {
    handleUpdateShop(row) {
      // row.role_id = Number(row.role_id);
      this.shopForm = Object.assign({}, row);
      this.dialogStatus = "update";
      this.dialogShopForm = true;
    },
    resetForm() {
      this.shopForm = {
        name: "",
        description: "",
      };
    },
    handleAddShop() {
      this.resetForm();
      this.dialogStatus = "create";
      this.dialogShopForm = true;
      this.$nextTick(() => {
        this.$refs.shopForm.clearValidate();
      });
    },
    async getList() {
      // 获取数据
      const [, res] = await this.getListMixin(api.malls.malls);
      this.shopData = res.list;
    },

    // 创建和修改 HTTP 请求
    async handleDialogConfirm() {
      let method = this.dialogStatus === "create" ? "post" : "patch";

      const isValid = await this.$refs.shopForm.validate();
      if (!isValid) return this.$notify.warning("请填写必填信息");

      const data = method === "post" ? { ...this.shopForm, database_identity: "暂时必填" } : R.pick(["description"], this.shopForm);
      const [err] = await api.malls.malls({
        method,
        id: this.shopForm.id,
        data,
      });
      if (err) return;
      this.dialogShopForm = false;
      this.$notify.success("操作成功");
      await this.getList();
    },
    async handleDelete(row) {
      const [err] = await api.malls.malls({
        method: "delete",
        id: row.id,
      });

      if (!err) {
        this.$notify.success("删除成功");
        await this.getList();
      }
    },
  },
};
</script>

<style></style>

<style lang="scss" scoped>
// ::v-deep :focus {
//   outline: 0;
// }
//
// .status-item ::v-deep .el-badge__content {
//   top: 0 !important;
// }
</style>
