var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "upload-wrap",
    },
    [
      _c(
        "el-upload",
        {
          staticClass: "upload-container-ref",
          class: {
            "hide-upload-holder": _vm.hideUpload,
          },
          attrs: {
            action: _vm.upload_qiniu_area,
            "auto-upload": true,
            limit: _vm.limitCount,
            multiple: true,
            accept: _vm.accept,
            "file-list": _vm.fileList,
            "list-type": "picture-card",
            "on-preview": _vm.picCardPreview,
            "before-upload": _vm.beforePicUpload,
            "on-exceed": _vm.handleExceed,
            "on-remove": _vm.removePic,
            "http-request": _vm.uploadQiniu,
          },
          scopedSlots: _vm._u([
            {
              key: "file",
              fn: function fn(_ref) {
                var file = _ref.file
                return [
                  file.status !== "uploading"
                    ? _c("img", {
                        staticClass: "upload-image",
                        attrs: {
                          src: file.url,
                          alt: "",
                        },
                      })
                    : _vm._e(),
                  _c(
                    "label",
                    {
                      staticClass: "el-upload-list__item-status-label",
                    },
                    [
                      _c("i", {
                        class: {
                          "el-icon-upload-success": true,
                          "el-icon-check": true,
                        },
                      }),
                    ]
                  ),
                  file.status === "uploading"
                    ? _c("el-progress", {
                        attrs: {
                          type: "circle",
                          "stroke-width": 6,
                          percentage: parseInt(file.percentage, 10),
                        },
                      })
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      staticClass: "el-upload-list__item-actions",
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "el-upload-list__item-preview",
                          on: {
                            click: function click($event) {
                              return _vm.picCardPreview(file)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-zoom-in",
                          }),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "el-upload-list__item-delete",
                          on: {
                            click: function click($event) {
                              return _vm.handleSlotRemove(file)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-delete",
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm.showAllButtons()
                    ? [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                            },
                            on: {
                              click: function click($event) {
                                return _vm.handleSetCoverImage(file)
                              },
                            },
                          },
                          [_vm._v(" 设置为封面图 ")]
                        ),
                      ]
                    : [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: _vm.isButtonDisabled(file),
                              type: "text",
                            },
                            on: {
                              click: function click($event) {
                                return _vm.handleSetCoverImage()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isButtonDisabled(file)
                                    ? "设置为封面图"
                                    : "取消设置为封面图"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                ]
              },
            },
          ]),
        },
        [
          _c(
            "div",
            {
              staticClass: "el-icon-plus",
              staticStyle: {
                color: "#409eff",
              },
              attrs: {
                slot: "default",
              },
              slot: "default",
            },
            [_vm._v("添加图片")]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            modal: false,
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: {
              width: "100%",
              src: _vm.dialogImageUrl,
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }