<template>
  <div class="page-padding">
    <div class="page-header--wrap">
      <div class="page-header-wrap--title">关键词回复</div>
      <div />
    </div>

    <el-row class="page-filter--wrap">
      <div class="page-color--normal">关键词</div>
      <el-input v-model="searchForm.key" placeholder="请输入关键词" class="mr-[10px]" style="min-width: 150px; max-width: 200px" />
      <div class="page-color--normal">规则名称</div>
      <el-input v-model="searchForm.name" placeholder="请输入规则名称" class="mr-[10px]" style="min-width: 150px; max-width: 200px" />
      <el-button round icon="el-icon-refresh" @click="resetSearchForm"> 重置 </el-button>
      <el-button type="primary" round icon="el-icon-search" @click="getList()"> 搜索 </el-button>
    </el-row>
    <div class="flex items-center justify-end mb-[12px]">
      <el-button round type="primary" @click="handleGreetingDialogOpen"> 欢迎语 </el-button>
      <el-button round type="primary" @click="handleEndResponseDialogOpen"> 结束语 </el-button>
      <el-button round type="primary" @click="handleKeywordDialogOpen()"> 添加关键词 </el-button>
    </div>

    <el-table v-loading="listLoading" :data="keywordList" stripe border highlight-current-row style="width: 100%">
      <!-- <el-table-column type="selection" width="55" align="center"></el-table-column> -->
      <el-table-column align="center" type="index" :index="formatIndex" label="序号" width="60" show-overflow-tooltip />
      <el-table-column align="center" prop="name" label="规则名称" show-overflow-tooltip />
      <el-table-column align="center" prop="key_info_list" label="关键词" :formatter="keywordFormatter" show-overflow-tooltip />
      <el-table-column
        align="center"
        prop="message_type"
        label="回复类型"
        :formatter="(_, __, value) => MESSAGE_TYPE_VALUE_DIC[value]"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="回复内容" show-overflow-tooltip>
        <template slot-scope="scope">
          <div v-if="scope.row.message_type === MESSAGE_TYPE_VALUE_MAP['TEXT']" class="ell">
            {{ scope.row.response_text }}
          </div>
          <el-image
            v-if="scope.row.message_type === MESSAGE_TYPE_VALUE_MAP['IMAGE']"
            style="width: 100px; height: 100px"
            :src="scope.row.image_url"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="create_time" label="创建时间" show-overflow-tooltip />
      <el-table-column label="操作" width="200px" align="center">
        <template slot-scope="scope">
          <el-button class="mr-[10px]" type="text" @click="handleKeywordDialogOpen(scope.row)"> 编辑 </el-button>
          <el-popconfirm title="确定要删除该关键词规则吗？" @onConfirm="handleDelete(scope.row.id)">
            <el-button slot="reference" type="text"> 删除 </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    <GreetingDialog ref="greetingDialog" />
    <EndResponseDialog ref="endResponseDialog" />
    <KeywordDialog ref="keywordDialog" @success="getList" />
  </div>
</template>

<script>
import api from "@/api";
import * as R from "ramda";
import { commonUtil } from "@/utils";
import TableListMixin from "@/mixin/TableList";
import Pagination from "@/components/Pagination";
import { CUSTOMER_SERVICE } from "@/constants";
import GreetingDialog from "@/views/mall-im/keywords/components/GreetingDialog";
import EndResponseDialog from "@/views/mall-im/keywords/components/EndResponseDialog";
import KeywordDialog from "@/views/mall-im/keywords/components/KeywordDialog";

const { MESSAGE_TYPE_VALUE_DIC, MESSAGE_TYPE_VALUE_MAP } = CUSTOMER_SERVICE;
const { to } = commonUtil;

const initSearchForm = {
  key: "",
  name: "",
};
export default {
  name: "MallImKeywords",
  components: {
    Pagination,
    GreetingDialog,
    EndResponseDialog,
    KeywordDialog,
  },
  mixins: [TableListMixin],
  data() {
    return {
      listLoading: false,
      MESSAGE_TYPE_VALUE_DIC,
      MESSAGE_TYPE_VALUE_MAP,
      keywordList: [],

      last_consumption_time: [],
      registration_time: [],
      searchForm: R.clone(initSearchForm),
    };
  },
  created() {
    this.getList();
  },
  methods: {
    keywordFormatter(_, __, keyInfoArr) {
      if (Array.isArray(keyInfoArr)) return keyInfoArr.map((keyInfo) => keyInfo.key).join("，");
    },
    formatIndex(currentRowIndex) {
      const beginIndex = (this.listQuery.page - 1) * this.listQuery.limit;
      return beginIndex + currentRowIndex + 1;
    },
    resetSearchForm() {
      this.searchForm = R.clone(initSearchForm);
      this.getList();
    },
    async getList() {
      const [, res] = await this.getListMixin(api.customerService.keywords);
      this.keywordList = res.list;
    },
    handleGreetingDialogOpen() {
      this.$refs.greetingDialog.open();
    },
    handleEndResponseDialogOpen() {
      this.$refs.endResponseDialog.open();
    },
    handleKeywordDialogOpen(row) {
      this.$refs.keywordDialog.open(row);
    },
    async handleDelete(id) {
      const [err] = await api.customerService.keywords({
        method: "delete",
        id,
      });
      if (!err) {
        this.$message.success("删除成功");
        to(this.getList());
      }
    },
  },
};
</script>

<style></style>
