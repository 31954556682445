<template>
  <el-dialog :visible="visible" title="欢迎语" @open="handleOpen" @close="close">
    <el-input
      v-model="greetingText"
      type="textarea"
      placeholder="请输入回复文案"
      :autosize="{ minRows: 8, maxRows: 12 }"
      maxlength="500"
      show-word-limit
    />
    <div slot="footer" class="flex justify-center">
      <el-button @click="visible = false"> 取 消 </el-button>
      <el-button type="primary" @click="handleConfirm"> 确 定 </el-button>
    </div>
  </el-dialog>
</template>

<script>
import api from "@/api";

export default {
  name: "GreetingDialog",
  data() {
    return {
      greetingText: "",
      visible: false,
    };
  },
  methods: {
    async handleOpen() {
      const [, res] = await api.customerService.defaultResponseText();
      this.greetingText = res?.response_text;
    },
    async handleConfirm() {
      const [err] = await api.customerService.updateDefaultResponseText({
        method: "put",
        data: {
          response_text: this.greetingText,
        },
      });
      if (!err) {
        this.close();
        this.$message.success("保存成功");
      }
    },
    open() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style scoped></style>
