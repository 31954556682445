var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "vac-room-container",
    },
    [
      _vm._t(
        "room-list-item",
        function () {
          return [
            _vm.room.avatar
              ? _c("div", {
                  staticClass: "vac-avatar",
                  style: {
                    "background-image": "url('".concat(_vm.room.avatar, "')"),
                  },
                })
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "vac-name-container vac-text-ellipsis",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "vac-title-container",
                  },
                  [
                    _vm.userStatus
                      ? _c("div", {
                          staticClass: "vac-state-circle",
                          class: {
                            "vac-state-online": _vm.userStatus === "online",
                          },
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "vac-room-name vac-text-ellipsis",
                      },
                      [_vm._v(" " + _vm._s(_vm.room.roomName) + " ")]
                    ),
                    _vm.room.lastMessage
                      ? _c(
                          "div",
                          {
                            staticClass: "vac-text-date",
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.room.lastMessage.timestamp) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "vac-text-last",
                    class: {
                      "vac-message-new":
                        _vm.room.lastMessage &&
                        _vm.room.lastMessage.new &&
                        !_vm.typingUsers,
                    },
                  },
                  [
                    _vm.isMessageCheckmarkVisible
                      ? _c(
                          "span",
                          [
                            _vm._t(
                              "checkmark-icon",
                              function () {
                                return [
                                  _c("svg-icon", {
                                    staticClass: "vac-icon-check",
                                    attrs: {
                                      name: _vm.room.lastMessage.distributed
                                        ? "double-checkmark"
                                        : "checkmark",
                                      param: _vm.room.lastMessage.seen
                                        ? "seen"
                                        : "",
                                    },
                                  }),
                                ]
                              },
                              null,
                              _vm.room.lastMessage
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.room.lastMessage &&
                    !_vm.room.lastMessage.deleted &&
                    _vm.isAudio
                      ? _c(
                          "div",
                          {
                            staticClass: "vac-text-ellipsis",
                          },
                          [
                            _vm._t("microphone-icon", function () {
                              return [
                                _c("svg-icon", {
                                  staticClass: "vac-icon-microphone",
                                  attrs: {
                                    name: "microphone",
                                  },
                                }),
                              ]
                            }),
                            _vm._v(" " + _vm._s(_vm.formattedDuration) + " "),
                          ],
                          2
                        )
                      : _vm.room.lastMessage
                      ? _c("format-message", {
                          attrs: {
                            content: _vm.getLastMessage,
                            deleted:
                              !!_vm.room.lastMessage.deleted &&
                              !_vm.typingUsers,
                            users: _vm.room.users,
                            linkify: false,
                            "text-formatting": _vm.textFormatting,
                            "link-options": _vm.linkOptions,
                            "single-line": true,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "deleted-icon",
                                fn: function fn(data) {
                                  return [
                                    _vm._t("deleted-icon", null, null, data),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      : _vm._e(),
                    !_vm.room.lastMessage && _vm.typingUsers
                      ? _c(
                          "div",
                          {
                            staticClass: "vac-text-ellipsis",
                          },
                          [_vm._v(" " + _vm._s(_vm.typingUsers) + " ")]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "vac-room-options-container",
                      },
                      [
                        _vm.room.unreadCount
                          ? _c(
                              "div",
                              {
                                staticClass: "vac-badge-counter vac-room-badge",
                              },
                              [_vm._v(" " + _vm._s(_vm.room.unreadCount) + " ")]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        },
        null,
        {
          room: _vm.room,
        }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }