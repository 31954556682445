<template>
  <div>
    <sticky :z-index="20">
      <el-page-header content="订单详情" class="go-back" @back="goBack" />
    </sticky>
    <el-card v-loading="detailLoading" class="box-card">
      <div slot="header" class="order-header">
        <div class="header-item">订单号：{{ detailData.order_number }}</div>
        <div class="header-item">下单时间：{{ detailData.place_time }}</div>
      </div>
      <div>
        <el-row class="order-info">
          <!-- test -->
          <!-- <div style="margin: 10px 0;"><el-button type="primary" @click="handleDispatchOrder()">发货</el-button></div> -->
          <!-- test -->
          <el-col :span="10">
            <div v-if="detailData.status === ORDER_STATUS_VALUE_MAP['TO_BE_DELIVERED']">
              <div class="order-status">
                {{ detailData.status_chinese_representation }}
              </div>
              <div class="order-note">买家已付款至微信待结算账户，请尽快发货，否则买家有权申请退款。</div>
              <div style="margin: 10px 0">
                <el-button v-if="!isGroupMall" type="primary" @click="handleDispatchOrder"> 发货 </el-button>
              </div>
              <div>
                <el-button type="text" @click="handleAddNote"> 备注 </el-button>
              </div>
            </div>
            <div v-if="detailData.status === ORDER_STATUS_VALUE_MAP['DELIVERING']">
              <div class="order-status">已发货</div>
              <div style="margin: 10px 0">
                <el-button type="primary" @click="handleUpdateExpress"> 修改物流 </el-button>
              </div>
              <!-- <div style="margin: 10px 0;"><el-button type="primary" @click="$message('待开发')">退款</el-button></div> -->
              <div>
                <el-button type="text" @click="handleAddNote"> 备注 </el-button>
              </div>
            </div>
          </el-col>
          <el-col :span="14">
            <el-steps :space="200" :active="detailData.status | activeNum" process-status="success" finish-status="success" align-center>
              <el-step v-for="(item, index) in detailData.operation_log_list" :key="index" :title="item.operation" :description="item.time" />
            </el-steps>
          </el-col>
        </el-row>

        <!-- <el-row class="order-info" style="height: 300px; align-items: flex-start;"> -->
        <div v-if="hasNotDeliverInfoOrIsPickUp()" style="margin: 0 0 20px; min-height: 200px; font-size: 16px">
          <div v-if="!hasDeliverInfo()" class="row-info">
            <div class="label">物流状态：</div>
            <div>暂无物流信息</div>
          </div>
          <div v-if="detailData.deliver_method === DELIVER_METHOD_VALUE_MAP['CUSTOMER_PICK_UP']">
            <!-- <el-form>
              <el-form-item label="自提码：">
                {{ detailData.pick_up_code }}
              </el-form-item>
            </el-form> -->
            <div class="row-info">
              <div class="label">自提码：</div>
              <div>{{ detailData.pick_up_code }}</div>
            </div>
            <div class="row-info">
              <div class="label" style="align-self: baseline">自提地址：</div>
              <div>
                <div>
                  {{ detailData.shipping_info.shipping_address_street_address
                  }}<span class="ml-[4px]"
                    >{{ detailData.shipping_info.shipping_address_province }} {{ detailData.shipping_info.shipping_address_city }}
                    {{ detailData.shipping_info.shipping_address_district }}</span
                  >
                </div>
                <div>{{ detailData.shipping_info.receiver_name || "-" }} {{ detailData.shipping_info.receiver_mobile_number || "-" }}</div>
              </div>
            </div>
            <el-button :disabled="detailData.status !== ORDER_STATUS_VALUE_MAP['TO_BE_PICKED_UP']" type="primary" @click="handlePickedUp">
              自提核销
            </el-button>
          </div>
        </div>

        <el-tabs
          v-if="detailData.delivery_sheet_info_list && detailData.delivery_sheet_info_list.length"
          v-model="activePackage"
          type="card"
          style="min-height: 200px"
        >
          <el-tab-pane
            v-for="(delivery_sheet_info, index) in detailData.delivery_sheet_info_list || []"
            :key="index"
            style="margin: 0 0 10px 20px"
            :label="`包裹${index + 1}`"
            :name="`包裹${index + 1}`"
          >
            <el-row>
              <el-col :span="6" class="order-info-wrap order-packages">
                <div>
                  <div class="order-info-item">
                    运单号： {{ `${delivery_sheet_info.logistics_company_name} ${delivery_sheet_info.tracking_number}` }}
                  </div>
                </div>

                <div class="packages-title" style="margin: 0">
                  <div v-for="(delivery_item, _index) in delivery_sheet_info.delivery_sheet_item_list" :key="_index" style="margin-right: 5px">
                    <img style="width: 80px; margin: 10px 5px" :src="delivery_item.image_address" />
                    <div class="fs12 deliveryItem">
                      {{ delivery_item.name }}<strong> x{{ delivery_item.num }}</strong>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :span="12" class="order-info-wrap order-packages">
                <div style="margin: 0 0 20px 20px">
                  <span>物流状态：</span> <span style="color: #f56c6c">{{ delivery_sheet_info.state_str }}</span>
                </div>
                <el-timeline :reverse="true">
                  <el-timeline-item v-for="(route_node, _index) in delivery_sheet_info.route_node_list" :key="_index" :timestamp="route_node.time">
                    {{ route_node.remark }}
                  </el-timeline-item>
                </el-timeline>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
        <!-- </el-row> -->

        <el-row class="order-info">
          <el-col v-if="detailData.deliver_method !== DELIVER_METHOD_VALUE_MAP['CUSTOMER_PICK_UP']" :span="6" class="order-info-wrap">
            <div class="flex">
              <h5>收货人信息</h5>
              <el-button type="text" style="margin: 0 10px" @click="updateAddressDialogVisible = true"> 修改 </el-button>
              <el-button type="text" @click="handleCopy('', $event)"> 复制 </el-button>
            </div>
            <div class="order-info-item">收货人：{{ shipping_info.receiver_name }}</div>
            <div class="order-info-item">联系电话：{{ shipping_info.receiver_mobile_number }}</div>
            <div class="order-info-item">收货地址：{{ completeAddress }}</div>
          </el-col>
          <el-col v-if="detailData.deliver_method !== DELIVER_METHOD_VALUE_MAP['CUSTOMER_PICK_UP']" :span="6" class="order-info-wrap">
            <h5>配送信息</h5>
            <div class="order-info-item">配送方式： {{ DELIVER_METHOD_VALUE_DIC[detailData.deliver_method] }}</div>
          </el-col>
          <el-col :span="6" class="order-info-wrap">
            <h5>付款信息</h5>
            <div class="order-info-item flex">实付金额：{{ formatPrice({ price: detailData.total_price, point: detailData.total_points }) }}</div>
            <div class="order-info-item">付款方式：{{ PAY_TYPE_VALUE_DIC[detailData.pay_type] }}</div>
            <div class="order-info-item">付款时间：{{ detailData.pay_time }}</div>
          </el-col>
          <el-col :span="6" class="order-info-wrap">
            <h5>买家信息</h5>
            <div class="order-info-item">买家：{{ detailData.buyer_name }}</div>
            <div class="order-info-item">买家留言：{{ detailData.buyer_remark }}</div>
            <div class="order-info-item">会员卡号：{{ detailData.buyer_membership_card_number }}</div>
          </el-col>
          <el-col :span="6" class="order-info-wrap">
            <h5>商户订单号</h5>
            <div class="order-info-item">内部支付订单号：{{ detailData.internal_wechat_pay_order_number || "" }}</div>
            <div class="order-info-item">微信支付订单号：{{ detailData.wechat_pay_order_number || "" }}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-table
            :data="detailData.order_line_info_list"
            border
            stripe
            highlight-current-row
            style="width: 100%"
            :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
          >
            <el-table-column width="300" align="" prop="goods_name" label="商品名称">
              <template #default="{ row }">
                <div class="flex-center" style="justify-content: start">
                  <img style="width: 80px" class="imgSty" :src="row.goods_sku_image_address" />
                  <div style="padding: 0 5px">
                    <el-tooltip
                      effect="dark"
                      :content="row.goods_name"
                      placement="top"
                      style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
                    >
                      <p>{{ row.goods_name }}</p>
                    </el-tooltip>
                    <div>{{ row.goods_sku_specification }}</div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="after_discount_price" label="单价">
              <template #default="{ row }">
                <template v-if="row.goods_sku_unit_price && row.goods_sku_unit_points">
                  <div>￥{{ row.goods_sku_unit_price }}+{{ row.goods_sku_unit_points }}积分</div>
                </template>
                <template v-else>
                  <div v-if="row.goods_sku_unit_price">￥{{ row.goods_sku_unit_price }}</div>
                  <div v-if="row.goods_sku_unit_points">{{ row.goods_sku_unit_points }}积分</div>
                </template>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="shopping_num" label="数量" />
            <el-table-column align="center" label="优惠">
              <template #default="{ row }">
                {{ formatPrice({ price: row.deductible_price, point: row.deductible_points }) }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="receivable_money" label="小计">
              <template #default="{ row }">
                {{ formatPrice({ price: row.discount_price, point: row.discount_points }) }}
                <!-- <template v-if="row.goods_sku_unit_price && row.goods_sku_unit_points">
                  <div>￥{{ row.goods_sku_unit_price * row.shopping_num }}+{{ row.goods_sku_unit_points * row.shopping_num }}积分</div>
                </template>
                <template v-else>
                  <div v-if="row.goods_sku_unit_price">￥{{ row.goods_sku_unit_price * row.shopping_num }}</div>
                  <div v-if="row.goods_sku_unit_points">{{ row.goods_sku_unit_points * row.shopping_num }}积分</div>
                </template> -->
              </template>
            </el-table-column>
            <el-table-column align="center" prop="refund_state_str" label="售后状态">
              <template #default="{ row }">
                <el-button type="text" @click="handleAfterSaleDetail(row)">
                  {{ AFTER_SALE_STATUS_VALUE_DIC[row.after_sale_status] }}
                </el-button>
              </template>
            </el-table-column>
            <el-table-column prop="delivery_status" :formatter="(_, __, value) => DELIVERY_STATUS_VALUE_DIC[value]" align="center" label="配送状态" />
          </el-table>
        </el-row>
        <el-row v-if="detailData.coupon_info_list?.length" class="mt-[20px]">
          <el-col :span="8">
            <el-table :data="detailData.coupon_info_list" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
              <el-table-column align="center" prop="coupon_record_id" label="票券ID"> </el-table-column>
              <el-table-column align="center" prop="coupon_name" label="票券名称"> </el-table-column>
              <el-table-column align="center" prop="deductible_price" label="优惠金额">
                <template #default="{ row }">
                  {{ formatPrice({ price: row.deductible_price, point: row.deductible_points }) }}
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row style="display: flex; justify-content: flex-end; align-items: flex-end">
          <el-col :span="2">
            <div class="price-footer-l">商品总价：</div>
            <div class="price-footer-l">运费：</div>
            <div class="price-footer-l">优惠金额：</div>
            <div class="price-footer-l">实收金额：</div>
          </el-col>
          <el-col :span="3">
            <div class="price-footer-r">
              {{ formatPrice({ price: detailData.origin_price, point: detailData.origin_points }) }}
            </div>
            <div class="price-footer-r">￥{{ detailData.freight || 0 }}</div>
            <div class="price-footer-r">{{ formatPrice({ price: detailData.deductible_price, point: detailData.deductible_points }) }}</div>
            <div class="price-footer-r" style="color: red; font-size: 20px">
              {{ formatPrice({ price: detailData.total_price, point: detailData.total_points }) }}
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <!-- 添加备注的弹窗 -->
    <el-dialog title="卖家备注" :visible.sync="dialogAddNoteVisible" width="40%">
      <el-input v-model="note_form.remark" type="textarea" placeholder="最多可输入256个字" maxlength="256" show-word-limit />
      <div slot="footer">
        <el-button type="text" @click="dialogAddNoteVisible = false"> 取消 </el-button>
        <el-button type="primary" @click="handleAddNoteConfirm"> 确定 </el-button>
      </div>
    </el-dialog>

    <!-- 发货的弹窗 -->
    <dispatch-order :visible.sync="dialogDispatchOrderVisible" :detail-data="detailData" @success="getDetailByID" />

    <!-- 修改收货地址的弹窗 -->
    <update-address :visible.sync="updateAddressDialogVisible" :order-id="orderId" :detail-data="detailData" @success="getDetailByID" />

    <!-- 修改物流的弹窗 -->
    <update-express
      :visible.sync="updateExpressDialogVisible"
      :order-id="orderId"
      :delivery-sheet-info-list="detailData.delivery_sheet_info_list"
      @success="handleUpdateExpressClosed"
    />
  </div>
</template>

<script>
import api from "@/api";
import { GOODS } from "@/constants";
import DispatchOrder from "./components/DispatchOrder";
import UpdateAddress from "./components/UpdateAddress";
import UpdateExpress from "./components/UpdateExpress";
import Sticky from "@/components/Sticky";
import { formatTime } from "@/utils/format-time";
import * as R from "ramda";
import clip from "@/utils/clipboard";
import { commonUtil } from "@/utils";
import { mapGetters } from "vuex";

const {
  ORDER_STATUS_VALUE_MAP,
  DELIVER_METHOD_VALUE_MAP,
  DELIVER_METHOD_VALUE_DIC,
  PAY_TYPE_VALUE_MAP,
  PAY_TYPE_VALUE_DIC,
  AFTER_SALE_STATUS_VALUE_DIC,
  DELIVERY_STATUS_VALUE_DIC,
} = GOODS;
const { to } = commonUtil;

export default {
  components: { DispatchOrder, UpdateAddress, UpdateExpress, Sticky },
  filters: {
    activeNum: function (val) {
      // 后面再改，不清晰
      let map = {
        TO_BE_PAID: 0,
        TO_BE_DELIVERED: 1,
        REQUESTED_DELIVER: 1,
        DELIVERING: 2,
        TO_BE_PICKED_UP: 2,
        AFTER_SALE_ING: 3,
        COMPLETED: 3,
        CLOSED: 3,
      };
      return map[val];
    },
  },
  data() {
    return {
      ORDER_STATUS_VALUE_MAP,
      DELIVER_METHOD_VALUE_MAP,
      DELIVER_METHOD_VALUE_DIC,
      PAY_TYPE_VALUE_MAP,
      PAY_TYPE_VALUE_DIC,
      AFTER_SALE_STATUS_VALUE_DIC,
      DELIVERY_STATUS_VALUE_DIC,
      formatTime,
      updateExpressDialogVisible: false,
      updateAddressDialogVisible: false,
      source: "order_mange", // order_mange 或 after_sales
      detailLoading: false,

      dialogAddNoteVisible: false,
      dialogDispatchOrderVisible: false,
      orderId: "",

      detailData: { shipping_info: {}, mail_tracks: [], packages: [] },
      shipping_info: {},
      activePackage: "包裹1",

      note_form: {
        remark: "",
      },
    };
  },
  computed: {
    ...mapGetters(["isGroupMall", "isMoneyMall"]),
    isVirtual() {
      let { name, code } = this.$store.state.user.auth_info.current_mall;
      return name === "积分商城" || code === "tr_jf";
    },
    completeAddress() {
      const {
        shipping_address_province = "",
        shipping_address_city = "",
        shipping_address_district = "",
        shipping_address_street_address = "",
      } = this.shipping_info;
      return `${shipping_address_province}${shipping_address_city}${shipping_address_district}${shipping_address_street_address}`;
    },
  },
  activated() {
    this.orderId = this.$route.query.orderId;
    this.source = this.$route.query.source;
    this.getDetailByID();
  },
  created() {
    this.orderId = this.$route.query.orderId;
    this.source = this.$route.query.source;
    this.getDetailByID();
  },
  methods: {
    hasNotDeliverInfoOrIsPickUp() {
      return !this.hasDeliverInfo() && this.detailData.deliver_method === DELIVER_METHOD_VALUE_MAP["CUSTOMER_PICK_UP"];
    },
    hasDeliverInfo() {
      return !this.detailData.delivery_sheet_info_list?.length && this.detailData.deliver_method !== DELIVER_METHOD_VALUE_MAP["CUSTOMER_PICK_UP"];
    },
    getExpressName(expressValue, expressOptions) {
      const expressOption = R.find(R.propEq("value", expressValue))(expressOptions);
      const expressName = R.prop("label", expressOption);
      return R.or(expressName, expressValue);
    },
    formatPrice({ price, point }) {
      if (typeof price === "number") price = Number(price.toFixed(2));
      let str = "";
      if (price) str += `￥${price}`;
      if (price && point) str += "+";
      if (point) str += `${point}积分`;
      if (!point && !price) str = this.isMoneyMall ? "￥0" : "0积分";
      return str;
    },
    async handlePickedUp() {
      const [err] = await api.order.orders({
        method: "post",
        id: this.orderId,
        resource: "confirmBuyerPickUp",
      });
      if (err) return;
      this.$message.success("操作成功");
      to(this.getDetailByID());
    },
    async handleAddNoteConfirm() {
      const [err] = await api.order.orders({
        method: "put",
        id: this.orderId,
        resource: "merchantRemark",
        data: JSON.stringify(this.note_form.remark),
      });

      if (err) return;

      this.$notify.success("更新成功");
      to(this.getDetailByID());
      this.dialogAddNoteVisible = false;
      // this.axios.post(`/emall_order/remark`, { ...this.note_form }).then(() => {
      //   this.$notify.success("添加成功");
      //   this.dialogAddNoteVisible = false;
      //   this.note_form = {
      //     id: "",
      //     orderId: "",
      //     remark: "",
      //   };
      // });
    },
    handleCopy(_, event) {
      let { receiver_name, receiver_mobile_number } = this.shipping_info;
      const text = `收货人： ${receiver_name}
联系电话： ${receiver_mobile_number}
收货地址： ${this.completeAddress}`;
      // todo 学习一下clip用法
      clip(text, event);
    },
    handleUpdateExpress() {
      this.updateExpressDialogVisible = true;
    },
    handleUpdateExpressClosed() {
      this.updateExpressDialogVisible = false;
      this.getDetailByID();
    },
    handleAfterSaleDetail(row) {
      this.$router.push({ path: "/order/sales_detail", query: { ticketNumber: row.after_sale_ticket_number } });
    },
    async getDetailByID() {
      this.detailLoading = true;
      const [, res] = await api.order.orders({
        id: this.orderId,
      });
      this.detailData = res;
      this.shipping_info = this.detailData.shipping_info;
      this.detailLoading = false;
    },
    goBack() {
      if (this.source === "order_mange") {
        this.$router.replace("/order/order_manage");
      } else {
        this.$router.replace("/order/after_sales");
      }
    },
    handleAddNote() {
      this.note_form.remark = this.detailData.merchant_remark;
      this.dialogAddNoteVisible = true;
    },
    handleDispatchOrder() {
      this.dialogDispatchOrderVisible = true;
    },
    // handleDelivery(msg) {
    //   if (msg == 0) {
    //     this.getDetailByID();
    //     this.dialogDispatchOrderVisible = false;
    //     this.$notify.success("操作成功");
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.go-back {
  margin: 0;
  padding-left: 10px;
}
.order-packages {
  height: inherit;
  overflow-y: scroll;
  padding: 15px 0;

  .packages-title {
    margin: 0 0 20px 40px;
    display: flex;
    align-items: center;
  }
}
.order-header {
  display: flex;
  font-size: 14px;
}
.header-item {
  margin-right: 100px;
}
.deliveryItem {
  word-break: break-all;
}
.order-info {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  height: 200px;
}
.order-status {
  font-size: 22px;
  font-weight: bold;
  line-height: 44px;
}
.order-note,
.order-info-item {
  font-size: 14px;
  color: #bbb;
}
.order-note {
  line-height: 28px;
}
.order-info-item {
  line-height: 22px;
}
.price-footer {
  font-size: 14px;
}
.order-info-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: 30px;
}
.price-footer-l {
  display: flex;
  justify-content: flex-end;
}
.price-footer-l,
.price-footer-r {
  font-size: 14px;
  line-height: 28px;
}
.row-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .label {
    width: 100px;
  }
}
</style>
