import { transformUtil } from "@/utils";

const ARR_LIST_MAP = {
  ENABLE_STATUS: [
    { label: "上架中", value: true },
    { label: "已下架", value: false },
  ],
  STATUS: [
    { label: "待开始", value: "NOT_STARTED" },
    { label: "活动中", value: "ONGOING" },
    { label: "已过期", value: "ENDED" },
    { label: "已下架", value: "NOT_ENABLE" },
  ],
};

export default transformUtil.arrListToMapLists(ARR_LIST_MAP);
