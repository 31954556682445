import { ref, shallowRef, watch, toRef, readonly } from "vue";
import { createEventHook, until } from "@vueuse/shared";

// options = { axiosConfig: { data: {}, params: {} } }
export function useFetch(api, options = {}) {
  // Event Hooks
  const responseEvent = createEventHook();
  const errorEvent = createEventHook();
  const finallyEvent = createEventHook();

  const isFinished = ref(false);
  const isFetching = ref(false);
  const statusCode = ref(null);
  const response = shallowRef(null);
  const error = shallowRef(null);
  const data = shallowRef(null);

  const loading = (isLoading) => {
    isFetching.value = isLoading;
    isFinished.value = !isLoading;
  };

  const execute = async (throwOnFailed = false) => {
    loading(true);
    error.value = null;
    statusCode.value = null;

    let responseData = null;

    const { axiosConfig } = options;

    return api({ isReturnOrigin: true, ...axiosConfig })
      .then(async (fetchResponse) => {
        const { code, data: resData } = fetchResponse[1];
        response.value = resData;
        statusCode.value = code;

        // responseData = await fetchResponse.clone()[config.type]();

        // if (options.afterFetch) {
        //   ({ data: responseData } = await options.afterFetch({
        //     data: responseData,
        //     response: fetchResponse,
        //   }));
        // }
        data.value = resData;

        responseEvent.trigger(fetchResponse);
        return fetchResponse;
      })
      .catch(async (fetchError) => {
        let errorData = fetchError.message || fetchError.name;

        if (options.onFetchError) {
          ({ error: errorData, data: responseData } = await options.onFetchError({
            data: responseData,
            error: fetchError,
            response: response.value,
          }));
        }

        error.value = errorData;
        // if (options.updateDataOnError) data.value = responseData;

        errorEvent.trigger(fetchError);
        if (throwOnFailed) throw fetchError;
        return null;
      })
      .finally(() => {
        loading(false);
        finallyEvent.trigger(null);
      });
  };

  // const refetch = toRef(options.refetch);
  // watch([refetch, toRef(api)], ([refetch]) => refetch && execute(), { deep: true });

  Promise.resolve().then(() => execute());

  const shell = {
    isFinished: readonly(isFinished),
    isFetching: readonly(isFetching),
    statusCode,
    response,
    error,
    data,
    execute,

    onFetchResponse: responseEvent.on,
    onFetchError: errorEvent.on,
    onFetchFinally: finallyEvent.on,
  };

  function waitUntilFinished() {
    return new Promise((resolve, reject) => {
      until(isFinished)
        .toBe(true)
        .then(() => resolve(shell))
        .catch((error) => reject(error));
    });
  }

  return {
    ...shell,
    then(onFulfilled, onRejected) {
      return waitUntilFinished().then(onFulfilled, onRejected);
    },
  };
}
