<template>
  <el-dialog title="用户同意隐私政策历史" :visible="visible" @open="getPrivacyHistroy" @close="$emit('update:visible', false)">
    <el-table v-loading="listLoading" :data="privacyData" stripe border highlight-current-row style="width: 100%">
      <el-table-column type="index" label="序号" width="60" align="center" />
      <el-table-column prop="version" label="版本号" align="center" />
      <el-table-column prop="agree" label="是否同意" align="center">
        <template #default="{ row }">
          {{ row.agree === 1 ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column prop="version_time" label="版本更新时间" align="center" />
    </el-table>

    <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getPrivacyHistroy" />
  </el-dialog>
</template>

<script>
import api from "@/api";
import TableListMixin from "@/mixin/TableList";
import Pagination from "@/components/Pagination";
export default {
  name: "PrivacyDialog",
  components: {
    Pagination,
  },
  mixins: [TableListMixin],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      privacyData: [],
    };
  },
  methods: {
    async getPrivacyHistroy() {
      this.searchForm.userId = this.userId;
      const [, res] = await this.getListMixin(api.member.privacyPolicyHistory);
      this.privacyData = res.list;
    },
  },
};
</script>

<style lang="scss" scoped></style>
