<template>
  <div class="page-padding">
    <div class="page-header--wrap">
      <div class="page-header-wrap--title">角色管理</div>
      <div>
        <el-button icon="el-icon-plus" type="primary" @click="handleAddStaff"> 新增角色 </el-button>
      </div>
    </div>

    <el-row class="page-filter--wrap">
      <el-form inline label-width="auto" @submit.native.prevent>
        <el-form-item label="角色名称">
          <el-input v-model="searchForm.role_name" clearable placeholder="请输入角色名称" @keyup.enter.native="handleSearch"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" round icon="el-icon-search" @click="handleSearch"> 搜索 </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <el-table v-loading="listLoading" :data="roleData" stripe border highlight-current-row style="width: 100%">
      <el-table-column label="操作" width="300" align="center">
        <template slot-scope="{ row }">
          <el-button type="text" @click="handleEditRole(row)"> 编辑 </el-button>
          <el-button type="text" @click="handleUpdateRoleStatus(row)"> {{ row.status === 1 ? "禁用" : "启用" }} </el-button>
          <el-button type="text" @click="handleDeleteRole(row)"> 删除 </el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态">
        <template slot-scope="{ row }">
          <el-tag v-if="row.status === 1"> 启用 </el-tag>
          <el-tag v-else type="danger"> 禁用 </el-tag>
        </template>
      </el-table-column>
      <el-table-column :formatter="tableFormatter" align="center" prop="role_name" label="角色名称" show-overflow-tooltip />
      <el-table-column autosize :formatter="tableFormatter" align="center" prop="role_desc" label="角色说明" />
      <el-table-column :formatter="tableFormatter" align="center" label="创建人/修改人" show-overflow-tooltip>
        <template slot-scope="{ row }"> {{ row.create_user ?? "-" }}/{{ row.update_user ?? "-" }} </template>
      </el-table-column>
      <el-table-column :formatter="tableFormatter" align="center" label="创建时间/修改时间" show-overflow-tooltip>
        <template slot-scope="{ row }"> {{ row.create_time ?? "-" }}/{{ row.update_time ?? "-" }} </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

    <!-- 新增账号的弹窗 -->
    <el-dialog :title="`${textMap[dialogStatus]}角色`" :visible.sync="dialogRoleForm" width="500px">
      <el-form ref="roleForm" :model="roleForm" :rules="roleRules" label-width="90px">
        <el-form-item label="角色名称" prop="role_name">
          <el-input v-model="roleForm.role_name" />
        </el-form-item>
        <el-form-item label="角色权限" prop="menu_ids">
          <treeselect
            v-model="roleForm.menu_ids"
            :multiple="true"
            :limit="3"
            :options="menuList"
            placeholder="选择权限"
            no-children-text="无数据"
            value-consists-of="ALL_WITH_INDETERMINATE"
            :limit-text="(count) => `+${count}`"
          >
          </treeselect>
        </el-form-item>
        <el-form-item label="角色描述" prop="role_desc">
          <el-input v-model="roleForm.role_desc" type="textarea" />
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button type="text" @click="dialogRoleForm = false"> 取消 </el-button>
        <el-button type="primary" @keydown.enter="handleDialogConfirm" @click="handleDialogConfirm">
          {{ dialogStatus === "update" ? "修改" : "确定" }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import TableListMixin from "@/mixin/TableList";
import api from "@/api";
import { commonUtil } from "@/utils/index";
import * as R from "ramda";
import { cloneDeep } from "lodash";
import Treeselect from "@riophae/vue-treeselect"; // 使用文档：https://vue-treeselect.js.org/
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { pick } from "lodash";

const { to } = commonUtil;
const initSearchForm = {
  role_name: undefined,
};

const initRoleForm = {
  role_name: "",
  menu_ids: [],
  role_desc: "",
};
export default {
  name: "RoleManage",
  components: { Pagination, Treeselect },
  mixins: [TableListMixin],
  data() {
    return {
      menuList: [],
      searchForm: R.clone(initSearchForm),
      roleData: [],
      roleForm: cloneDeep(initRoleForm),
      roleRules: {
        role_name: [{ required: true, message: "请输入角色名称" }],
        menu_ids: [{ required: true, message: "请选择权限" }],
      },
      dialogRoleForm: false,
    };
  },
  created() {
    this.getMenuList();
    this.getList();
  },
  methods: {
    tableFormatter(_, __, value) {
      if (!value) return "-";
      return value;
    },
    async handleUpdateRoleStatus(row) {
      let confirm = false;
      if (row.status === 1) {
        confirm = await this.$confirm("请确认是否禁用？禁用后仍关联角色的用户将无权访问任意页面！", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });

        if (!confirm) return;
        const [err] = await api.role.updateStatus({ method: "post", data: { id: row.id, status: 0 } });
        if (err) return;
        this.$message.success("禁用成功");
      } else {
        confirm = await this.$confirm("确认启用该角色吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });

        if (!confirm) return;
        const [err] = await api.role.updateStatus({ method: "post", data: { id: row.id, status: 1 } });
        if (err) return;
        this.$message.success("启用成功");
      }

      if (confirm) this.getList();
    },
    async handleDeleteRole(row) {
      const confirm = await this.$confirm("请确认是否删除？删除后仍关联角色的用户将无权访问任意页面！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });

      if (!confirm) return;

      const [err] = await api.role.delete({ params: { id: row.id } });
      if (err) return;
      this.$message.success("删除成功");
      this.getList();
    },
    handleSearch() {
      this.resetPage();
      this.getList();
    },
    resetPage() {
      this.listQuery.page = 1;
    },
    resetForm() {
      this.roleForm = cloneDeep(initRoleForm);
    },
    handleAddStaff() {
      this.resetForm();
      this.dialogStatus = "create";
      this.dialogRoleForm = true;
      this.$nextTick(() => {
        this.$refs.roleForm.clearValidate();
      });
    },
    async getMenuList() {
      const [, res] = await api.menu.fetchAll();
      this.menuList = this.formatMenuList(res);
    },
    formatMenuList(arr) {
      if (!arr) return [];
      return arr.map((item) => {
        if (item.children) {
          item.children = this.formatMenuList(item.children);
        } else {
          delete item.children;
        }
        return { ...item, label: item.title };
      });
    },
    async getList() {
      // 获取数据
      const [, res] = await this.getListMixin(({ params }) => api.role.page({ method: "post", data: params }));
      this.roleData = res?.list ?? [];
    },
    handleEditRole(row) {
      this.dialogStatus = "update";
      this.roleForm = pick(row, ["id", ...Object.keys(initRoleForm)]);
      this.dialogRoleForm = true;
    },
    // 创建和修改 HTTP 请求
    async handleDialogConfirm() {
      const [err] = await to(this.$refs.roleForm.validate());
      if (err === false) return this.$notify.warning("请填写必填信息");
      if (this.dialogStatus === "create") this.createRole();
      else this.updateRole();
    },
    async createRole() {
      const [err] = await api.role.add({
        method: "post",
        data: this.roleForm,
      });
      if (err) return;
      this.getList();
      this.$message.success("新增成功");
      this.dialogRoleForm = false;
    },
    async updateRole() {
      const [err] = await api.role.update({
        method: "post",
        data: this.roleForm,
      });
      if (err) return;
      this.getList();
      this.$message.success("修改成功");
      this.dialogRoleForm = false;
    },
  },
};
</script>

<style></style>

<style lang="scss" scoped></style>
