var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "page-padding",
    },
    [
      _vm._m(0),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: {
            width: "100%",
          },
          attrs: {
            data: _vm.shopData,
            stripe: "",
            border: "",
            "highlight-current-row": "",
          },
        },
        [
          _c(
            "el-table-column",
            {
              attrs: {
                type: "index",
                align: "center",
                width: "60",
              },
            },
            [
              _c(
                "template",
                {
                  slot: "header",
                },
                [_vm._v(" 序号 ")]
              ),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "300",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref) {
                  var row = _ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                        },
                        on: {
                          click: function click($event) {
                            return _vm.handleUpdateShop(row)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "code",
              label: "店铺编码",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "name",
              label: "店铺名称",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "description",
              label: "描述",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "show-overflow-tooltip": "",
              label: "创建人/修改人",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref2) {
                  var row = _ref2.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(row.creator) +
                        " / " +
                        _vm._s(row.updater) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "show-overflow-tooltip": "",
              label: "创建时间/修改时间",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref3) {
                  var row = _ref3.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(row.create_time) +
                        " / " +
                        _vm._s(row.update_time) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function updatePage($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function updateLimit($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "".concat(_vm.textMap[_vm.dialogStatus], "\u5E97\u94FA"),
            visible: _vm.dialogShopForm,
            width: "500px",
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.dialogShopForm = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "shopForm",
              attrs: {
                model: _vm.shopForm,
                rules: _vm.staffRules,
                "label-width": "90px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "店铺名称",
                    prop: "name",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.dialogStatus == "update",
                    },
                    model: {
                      value: _vm.shopForm.name,
                      callback: function callback($$v) {
                        _vm.$set(_vm.shopForm, "name", $$v)
                      },
                      expression: "shopForm.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "店铺编码",
                    prop: "code",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.dialogStatus == "update",
                    },
                    model: {
                      value: _vm.shopForm.code,
                      callback: function callback($$v) {
                        _vm.$set(_vm.shopForm, "code", $$v)
                      },
                      expression: "shopForm.code",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "描述",
                    prop: "description",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                    },
                    model: {
                      value: _vm.shopForm.description,
                      callback: function callback($$v) {
                        _vm.$set(_vm.shopForm, "description", $$v)
                      },
                      expression: "shopForm.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "text",
                  },
                  on: {
                    click: function click($event) {
                      _vm.dialogShopForm = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                  },
                  on: {
                    click: _vm.handleDialogConfirm,
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.dialogStatus == "update" ? "修改" : "确定") +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "page-header--wrap",
      },
      [
        _c(
          "div",
          {
            staticClass: "page-header-wrap--title",
          },
          [_vm._v("店铺管理")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }