var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "el-input",
    _vm._g(
      _vm._b(
        {
          model: {
            value: _vm.inputValue,
            callback: function callback($$v) {
              _vm.inputValue = $$v
            },
            expression: "inputValue",
          },
        },
        "el-input",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }