<template>
  <div class="login-container">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left">
      <div class="title-container">
        <h3 class="title">欢迎登录</h3>
      </div>

      <!-- <el-form-item prop="groupId">
        <span class="svg-container">
          <svg-icon icon-class="user" />
        </span>
        <el-input ref="groupId" v-model="loginForm.groupId" placeholder="企业账号" name="groupId" type="text" tabindex="1" auto-complete="on" />
      </el-form-item> -->

      <el-form-item prop="username">
        <span class="svg-container">
          <svg-icon icon-class="user" />
        </span>
        <el-input ref="username" v-model="loginForm.username" placeholder="用户名" name="username" type="text" tabindex="1" auto-complete="on" />
      </el-form-item>

      <el-form-item prop="password">
        <span class="svg-container">
          <svg-icon icon-class="password" />
        </span>
        <el-input
          :key="passwordType"
          ref="password"
          v-model="loginForm.password"
          :type="passwordType"
          placeholder="密码"
          name="password"
          tabindex="2"
          auto-complete="on"
          @keyup.enter.native="handleLogin"
        />
        <span class="show-pwd" @click="showPwd">
          <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
        </span>
      </el-form-item>

      <el-checkbox v-model="rembChecked" label="记住密码" style="margin-bottom: 18px" />

      <el-button :loading="loading" type="primary" size="large" style="width: 100%; margin-bottom: 30px" @click.native.prevent="handleLogin">
        登录
      </el-button>

      <!-- <div class="tips">
        <span style="margin-right: 20px;">username: admin</span>
        <span> password: any</span>
      </div> -->
    </el-form>
  </div>
</template>

<script>
import { encrypt, decrypt, loginEncrypt } from "@/utils/js-encrypt";
export default {
  name: "LoginIndex",
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("密码不小于6位数"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        // groupId: "", //3
        username: "", //testh
        password: "", //654321
      },
      rembChecked: false,
      loginRules: {
        // groupId: [{ required: true, trigger: "blur", message: "企业账号必填" }],
        username: [{ required: true, trigger: "blur", message: "用户名必填" }],
        password: [{ required: true, trigger: "blur", validator: validatePassword }],
      },
      loading: false,
      passwordType: "password",
      redirect: undefined,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    let loginForm = JSON.parse(decrypt(localStorage.getItem("loginForm")));
    if (loginForm) {
      this.loginForm = loginForm.loginForm;
      this.rembChecked = loginForm.rembChecked;
    }
  },
  methods: {
    cacheAccountAndPassword() {
      let { loginForm, rembChecked } = this;
      if (rembChecked) {
        let setItem = JSON.stringify(Object.assign({ loginForm }, { rembChecked }));
        localStorage.setItem("loginForm", encrypt(setItem));
      } else {
        localStorage.removeItem("loginForm");
      }
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    async handleLogin() {
      const isValid = await this.$refs.loginForm.validate();
      if (!isValid) return console.error("login validate fail");
      const [err] = await this.$store.dispatch("user/login", {
        username: loginEncrypt(this.loginForm.username),
        password: loginEncrypt(this.loginForm.password),
      });
      if (err) {
        console.error(err);
      } else {
        this.$router.push({ path: this.redirect || "/" });
        this.cacheAccountAndPassword();
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #fff;
$light_gray: #000;
$cursor: #000;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    // height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      // padding: 12px 5px 12px 15px;
      color: $light_gray;
      // height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    // background: rgba(0, 0, 0, 0.1);
    background: #fff;
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #000;

.login-container {
  background-image: url("../../assets/bg.svg");
  background-size: 100%;
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
    margin-top: 13vh;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}
</style>
