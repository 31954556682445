var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "vac-options-container",
          class: {
            "vac-options-image": _vm.isImage && !_vm.message.replyMessage,
          },
          style: {
            display: _vm.hoverAudioProgress ? "none" : "initial",
            width:
              _vm.filteredMessageActions.length && _vm.showReactionEmojis
                ? "70px"
                : "45px",
          },
        },
        [
          _c(
            "transition-group",
            {
              attrs: {
                name: "vac-slide-left",
              },
            },
            [
              _vm.isMessageActions || _vm.isMessageReactions
                ? _c("div", {
                    key: "1",
                    staticClass: "vac-blur-container",
                    class: {
                      "vac-options-me":
                        _vm.message.senderId === _vm.currentUserId,
                    },
                  })
                : _vm._e(),
              _vm.isMessageActions
                ? _c(
                    "div",
                    {
                      key: "2",
                      ref: "actionIcon",
                      staticClass: "vac-svg-button vac-message-options",
                      on: {
                        click: _vm.openOptions,
                      },
                    },
                    [
                      _vm._t("dropdown-icon", function () {
                        return [
                          _c("svg-icon", {
                            attrs: {
                              name: "dropdown",
                              param: "message",
                            },
                          }),
                        ]
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.isMessageReactions
                ? _c("emoji-picker", {
                    directives: [
                      {
                        name: "click-outside",
                        rawName: "v-click-outside",
                        value: _vm.closeEmoji,
                        expression: "closeEmoji",
                      },
                    ],
                    key: "3",
                    staticClass: "vac-message-emojis",
                    style: {
                      right: _vm.isMessageActions ? "30px" : "5px",
                    },
                    attrs: {
                      "emoji-opened": _vm.emojiOpened,
                      "emoji-reaction": true,
                      "room-footer-ref": _vm.roomFooterRef,
                      "position-right":
                        _vm.message.senderId === _vm.currentUserId,
                    },
                    on: {
                      "add-emoji": _vm.sendMessageReaction,
                      "open-emoji": _vm.openEmoji,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "emoji-picker-icon",
                          fn: function fn() {
                            return [_vm._t("emoji-picker-reaction-icon")]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.filteredMessageActions.length
        ? _c(
            "transition",
            {
              attrs: {
                name:
                  _vm.message.senderId === _vm.currentUserId
                    ? "vac-slide-left"
                    : "vac-slide-right",
              },
            },
            [
              _vm.optionsOpened
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "click-outside",
                          rawName: "v-click-outside",
                          value: _vm.closeOptions,
                          expression: "closeOptions",
                        },
                      ],
                      ref: "menuOptions",
                      staticClass: "vac-menu-options",
                      class: {
                        "vac-menu-left":
                          _vm.message.senderId !== _vm.currentUserId,
                      },
                      style: {
                        top: "".concat(_vm.menuOptionsTop, "px"),
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "vac-menu-list",
                        },
                        _vm._l(_vm.filteredMessageActions, function (action) {
                          return _c(
                            "div",
                            {
                              key: action.name,
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "vac-menu-item",
                                  on: {
                                    click: function click($event) {
                                      return _vm.messageActionHandler(action)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(action.title) + " ")]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }