import Vue from "vue";

import service from "@/utils/request";
import VueAxios from "vue-axios";

import "normalize.css/normalize.css"; // A modern alternative to CSS resets
import "./output.css";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/zh-CN"; // lang i18n

import "@/styles/index.scss"; // global css

import App from "./App";
import store from "./store";
import router from "./router";

import "@/icons"; // icon
import "@/permission"; // permission control

import permission from "@/components/Permission/index.js";

import "@/components/global"; // import global components

import { toFixed } from "@/filters/number";

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
if (process.env.NODE_ENV === "production") {
  // const { mockXHR } = require("../mock");
  // mockXHR();
}

// set ElementUI lang to EN
Vue.use(ElementUI, { locale, size: "mini" });
// 如果想要中文版 element-ui，按如下方式声明
// Vue.use(ElementUI)

Vue.use(VueAxios, service);
Vue.use(permission); //按钮级别权限控制  v-permission="['admin','outletAdmin']"

Vue.config.productionTip = false;

Vue.filter("toFixed", toFixed);

new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
