<template>
  <div class="page-padding">
    <div>
      <div class="page-header--wrap">
        <div class="page-header-wrap--title">订单管理</div>
      </div>

      <el-row class="page-filter--wrap">
        <div class="page-color--normal">订单搜索</div>
        <el-input v-model="orderTypeInput" placeholder="请输入内容" style="width: 350px" clearable @input="handleTypeInputChange">
          <el-select slot="prepend" v-model="orderType" placeholder="请选择" style="width: 120px" @change="handleTypeChange">
            <el-option label="订单编号" value="order_no" />
            <!-- <el-option label="收货人姓名" value="receiver_name"> </el-option>
            <el-option label="收货人手机号" value="receiver_phone"> </el-option> -->
          </el-select>
        </el-input>
        <div class="page-color--normal">预定时间</div>
        <el-date-picker
          v-model="order_time"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
          unlink-panels
          @change="handleDateChange"
        />

        <div class="page-color--normal">付款时间</div>
        <el-date-picker
          v-model="payment_time"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
          unlink-panels
          @change="handlePaymentDateChange"
        />
        <div class="page-color--normal">大厨工作室</div>
        <el-select v-model="searchForm.type" placeholder="全部" clearable style="width: 150px">
          <el-option label="湘菜" :value="1" />
          <el-option label="川菜" :value="2" />
        </el-select>
        <div class="page-color--normal">预订人姓名</div>
        <el-input v-model="searchForm.name" clearable placeholder="请输入预订人姓名" style="width: 150px" />
        <div class="page-color--normal">预订人手机</div>
        <el-input v-model="searchForm.phone" clearable placeholder="请输入预订人手机" class="page-select--item" style="width: 150px" />
        <el-button round icon="el-icon-refresh" @click="resetSearchForm"> 重置 </el-button>
        <el-button type="primary" round icon="el-icon-search" @click="getList"> 搜索 </el-button>
      </el-row>

      <el-row>
        <div style="margin-bottom: 15px">
          <el-radio-group v-model="order_filter" size="small" @change="handleOrderRadioChange">
            <el-radio-button label="全部" />
            <el-radio-button label="TO_BE_PAID"> 待付款 </el-radio-button>
            <el-radio-button label="PAID"> 已付款 </el-radio-button>
            <el-radio-button label="COMPLETED"> 已完成 </el-radio-button>
            <el-radio-button label="CLOSE"> 已关闭 </el-radio-button>
          </el-radio-group>
        </div>
      </el-row>

      <el-table v-loading="listLoading" :data="storeData" stripe border style="width: 100%">
        <el-table-column type="selection" width="55" align="center" class-name="selection-class" />
        <el-table-column label="操作" align="center">
          <template slot-scope="{ row }">
            <!-- <el-button type="text" @click="handleOrderDetail(row)">详情</el-button> -->
            <el-button :disabled="row.chef_studio_states != 'PAID'" type="text" @click="handleRefund(row)"> 退款 </el-button>
            <el-button type="text" @click="handleAddNote(row)"> 备注 </el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" label="订单编号" prop="order_no" width="220px" />
        <el-table-column align="center" label="预订日期" prop="order_time" />
        <el-table-column align="center" label="预订时间" prop="time">
          <template slot-scope="{ row }">
            {{ row.time == 1 ? "中午" : "晚上" }}
          </template>
        </el-table-column>
        <el-table-column prop="type" align="center" label="大厨工作室">
          <template slot-scope="{ row }">
            {{ row.type == 1 ? "湘菜" : "川菜" }}
          </template>
        </el-table-column>
        <el-table-column prop="cook_type" align="center" label="服务方式">
          <template slot-scope="{ row }">
            {{ row.cook_type == 1 ? "工作室用餐" : "上门服务" }}
          </template>
        </el-table-column>
        <el-table-column prop="num" align="center" label="人数" />
        <el-table-column prop="name" align="center" label="预订人">
          <template slot-scope="{ row }">
            <div>
              <div>{{ row.name }}</div>
              <div>{{ row.phone }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="chef_studio_states" align="center" label="订单状态">
          <template slot-scope="{ row }">
            {{ row.chef_studio_states | orderStatesText }}
          </template>
        </el-table-column>
        <el-table-column prop="chef_studio_refund_states" align="center" label="售后状态">
          <template slot-scope="{ row }">
            {{ row.chef_studio_refund_states | regularOrderText }}
          </template>
        </el-table-column>
        <el-table-column prop="chef_studio_refund_type" align="center" label="售后类型">
          <template slot-scope="{ row }">
            {{ row.chef_studio_refund_type | refundOrderText }}
          </template>
        </el-table-column>
        <el-table-column prop="total_price" align="center" label="订单金额">
          <template slot-scope="{ row }">
            <div>￥{{ row.total_price }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="pay_time" align="center" label="付款时间" />
        <el-table-column align="center" label="上门地址" show-overflow-tooltip>
          <template slot-scope="{ row }"> {{ row.province + row.city + row.county }}{{ row.address_detail }} </template>
        </el-table-column>
        <el-table-column prop="remark" align="center" label="备注" show-overflow-tooltip />
      </el-table>

      <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    </div>

    <!-- 退款的弹窗 -->
    <el-dialog title="退款" :visible.sync="dialogRefund" width="550px">
      <el-form ref="refundForm" :model="refundForm" label-width="100px">
        <el-form-item label="退款类型" required>
          <el-radio-group v-model="refundForm.chef_studio_refund_type">
            <el-radio label="CANCEL"> 仅取消 </el-radio>
            <el-radio label="CANCELANDREFUND"> 取消并退款 </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="refundForm.chef_studio_refund_type == 'CANCELANDREFUND'" label="退款金额">
          <template slot="label">
            退款金额
            <el-tooltip effect="dark" placement="top">
              <div slot="content">确定好会直接退款给用户，请谨慎操作</div>
              <i class="el-icon-question" style="color: #ccc" />
            </el-tooltip>
          </template>
          <el-input v-model="refundForm.refund_money" style="width: 195px">
            <template slot="prepend"> ￥ </template>
          </el-input>
          <div>如不填默认全款退，退款不可超过商品金额</div>
        </el-form-item>
        <el-form-item label="操作密码" required>
          <el-input v-model="refundForm.password" type="password" auto-complete="new-password" style="width: 195px" />
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button type="text" @click="dialogRefund = false"> 取消 </el-button>
        <el-button type="primary" @click="handleRefundConfirm"> 确定 </el-button>
      </div>
    </el-dialog>

    <!-- 添加备注的弹窗 -->
    <el-dialog title="备注" :visible.sync="dialogAddNote" width="40%">
      <el-input v-model="note_form.remark" type="textarea" placeholder="最多可输入256个字" maxlength="256" show-word-limit />
      <div slot="footer">
        <el-button type="text" @click="dialogAddNote = false"> 取消 </el-button>
        <el-button type="primary" @click="handleAddNoteConfirm"> 确定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import TableListMixin from "@/mixin/TableList";
import api from "@/api";
import * as R from "ramda";

export default {
  name: "MealsOrder",
  components: { Pagination },
  filters: {
    orderStatesText(val) {
      let map = {
        TO_BE_PAID: "待付款",
        PAID: "已付款",
        COMPLETED: "已完成",
        CANCEL: "已取消",
        CLOSE: "已关闭",
      };
      return map[val] || "-";
    },
    regularOrderText(val) {
      let map = {
        REQUESTED: "已申请",
        COMPLETED: "已完成",
      };
      return map[val] || "-";
    },
    refundOrderText(val) {
      let map = {
        CANCEL: "仅取消",
        CANCELANDREFUND: "取消并退款",
      };
      return map[val] || "-";
    },
  },
  mixins: [TableListMixin],
  data() {
    return {
      dialogAddNote: false,
      orderTypeInput: "",
      orderType: "order_no",
      order_time: [],
      payment_time: [],
      order_filter: "全部",
      dialogRefund: false,
      storeData: [],
      refundForm: {
        chef_studio_refund_type: "CANCEL",
        refund_money: null,
        order_no: "",
        password: "",
      },
      note_form: {
        order_no: "",
        remark: "",
      },

      searchForm: {
        order_status: "",
        type: 1,
        name: "",
        create_start_time: "", // 下单开始时间
        create_end_time: "", // 下单结束时间
      },
    };
  },
  created() {
    this.getList();
  },

  methods: {
    handleAddNote(row) {
      this.note_form.order_no = row.order_no;
      this.dialogAddNote = true;
    },
    handleAddNoteConfirm() {
      let { order_no, remark } = this.note_form;
      this.axios.get(`/chef_studio/remark/${order_no}/${remark}`).then(() => {
        this.$notify.success("添加成功");
        this.dialogAddNote = false;
        this.note_form = {
          order_no: "",
          remark: "",
        };
        this.getList();
      });
    },
    resetSearchForm() {
      this.orderTypeInput = "";
      this.order_time = [];
      this.payment_time = [];
      this.searchForm = {
        type: 1,
      };
      this.getList();
    },
    handleOrderRadioChange(v) {
      this.listQuery.page = 1;
      let { order_filter } = this;
      if (order_filter != "全部") {
        this.searchForm.order_status = v;
      } else {
        this.searchForm.order_status = "";
      }
      this.getList();
    },
    handleRefund(row) {
      this.refundForm.order_no = row.order_no;
      this.refundForm.temp_refund_money = row.total_price;
      this.dialogRefund = true;
    },
    async handleRefundConfirm() {
      let { refund_money, temp_refund_money } = this.refundForm;
      this.refundForm.refund_money = refund_money ? Number(this.refundForm.refund_money) : Number(temp_refund_money);
      const [err] = await api.booking.refund({
        method: "post",
        data: R.omit(["temp_refund_money"], this.refundForm),
      });

      if (err) return;
      this.dialogRefund = false;
      this.refundForm = {};
      await this.getList();
      // this.axios.post(`/chef_studio/refund`, { ...this.refundForm }).then(() => {
      //   this.$notify.success("操作成功");
      //   this.dialogRefund = false;
      //   this.refundForm = {};
      //   this.getList();
      // });
    },
    handleTypeInputChange(val) {
      this.searchForm[this.orderType] = val;
    },
    handleTypeChange(val) {
      this.searchForm = {};
      this.orderTypeInput = "";
      this.searchForm[val] = "";
    },
    handleDateChange(val) {
      this.searchForm.order_time_start = val ? val[0] : "";
      this.searchForm.order_time_end = val ? val[1] : "";
    },
    handlePaymentDateChange(val) {
      this.searchForm.pay_time_start = val ? val[0] : "";
      this.searchForm.pay_time_end = val ? val[1] : "";
    },
    async getList() {
      // 获取数据
      const [, res] = await this.getListMixin(api.booking.orderList);
      this.storeData = res.list ?? [];
    },
  },
};
</script>
