var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "page-padding",
    },
    [
      _vm._m(0),
      _c(
        "el-row",
        {
          staticClass: "page-filter--wrap",
        },
        [
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [_vm._v("关键词")]
          ),
          _c("el-input", {
            staticClass: "mr-[10px]",
            staticStyle: {
              "min-width": "150px",
              "max-width": "200px",
            },
            attrs: {
              placeholder: "请输入关键词",
            },
            model: {
              value: _vm.searchForm.key,
              callback: function callback($$v) {
                _vm.$set(_vm.searchForm, "key", $$v)
              },
              expression: "searchForm.key",
            },
          }),
          _c(
            "div",
            {
              staticClass: "page-color--normal",
            },
            [_vm._v("规则名称")]
          ),
          _c("el-input", {
            staticClass: "mr-[10px]",
            staticStyle: {
              "min-width": "150px",
              "max-width": "200px",
            },
            attrs: {
              placeholder: "请输入规则名称",
            },
            model: {
              value: _vm.searchForm.name,
              callback: function callback($$v) {
                _vm.$set(_vm.searchForm, "name", $$v)
              },
              expression: "searchForm.name",
            },
          }),
          _c(
            "el-button",
            {
              attrs: {
                round: "",
                icon: "el-icon-refresh",
              },
              on: {
                click: _vm.resetSearchForm,
              },
            },
            [_vm._v(" 重置 ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                round: "",
                icon: "el-icon-search",
              },
              on: {
                click: function click($event) {
                  return _vm.getList()
                },
              },
            },
            [_vm._v(" 搜索 ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "flex items-center justify-end mb-[12px]",
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                round: "",
                type: "primary",
              },
              on: {
                click: _vm.handleGreetingDialogOpen,
              },
            },
            [_vm._v(" 欢迎语 ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                round: "",
                type: "primary",
              },
              on: {
                click: _vm.handleEndResponseDialogOpen,
              },
            },
            [_vm._v(" 结束语 ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                round: "",
                type: "primary",
              },
              on: {
                click: function click($event) {
                  return _vm.handleKeywordDialogOpen()
                },
              },
            },
            [_vm._v(" 添加关键词 ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: {
            width: "100%",
          },
          attrs: {
            data: _vm.keywordList,
            stripe: "",
            border: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              type: "index",
              index: _vm.formatIndex,
              label: "序号",
              width: "60",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "name",
              label: "规则名称",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "key_info_list",
              label: "关键词",
              formatter: _vm.keywordFormatter,
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "message_type",
              label: "回复类型",
              formatter: function formatter(_, __, value) {
                return _vm.MESSAGE_TYPE_VALUE_DIC[value]
              },
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "回复内容",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(scope) {
                  return [
                    scope.row.message_type ===
                    _vm.MESSAGE_TYPE_VALUE_MAP["TEXT"]
                      ? _c(
                          "div",
                          {
                            staticClass: "ell",
                          },
                          [_vm._v(" " + _vm._s(scope.row.response_text) + " ")]
                        )
                      : _vm._e(),
                    scope.row.message_type ===
                    _vm.MESSAGE_TYPE_VALUE_MAP["IMAGE"]
                      ? _c("el-image", {
                          staticStyle: {
                            width: "100px",
                            height: "100px",
                          },
                          attrs: {
                            src: scope.row.image_url,
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "create_time",
              label: "创建时间",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "200px",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "mr-[10px]",
                        attrs: {
                          type: "text",
                        },
                        on: {
                          click: function click($event) {
                            return _vm.handleKeywordDialogOpen(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                    _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          title: "确定要删除该关键词规则吗？",
                        },
                        on: {
                          onConfirm: function onConfirm($event) {
                            return _vm.handleDelete(scope.row.id)
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              type: "text",
                            },
                            slot: "reference",
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function updatePage($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function updateLimit($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c("GreetingDialog", {
        ref: "greetingDialog",
      }),
      _c("EndResponseDialog", {
        ref: "endResponseDialog",
      }),
      _c("KeywordDialog", {
        ref: "keywordDialog",
        on: {
          success: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "page-header--wrap",
      },
      [
        _c(
          "div",
          {
            staticClass: "page-header-wrap--title",
          },
          [_vm._v("关键词回复")]
        ),
        _c("div"),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }