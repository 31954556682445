var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "page-padding",
    },
    [
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "page-header--wrap",
            },
            [
              _c(
                "div",
                {
                  staticClass: "page-header-wrap--title",
                },
                [_vm._v("发货管理")]
              ),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: _vm.multipleSelection.length == 0,
                        type: "primary",
                      },
                      on: {
                        click: _vm.handleBatchExpress,
                      },
                    },
                    [_vm._v(" 批量发货 ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-row",
            {
              staticClass: "page-filter--wrap",
            },
            [
              _c(
                "div",
                {
                  staticClass: "page-color--normal",
                },
                [_vm._v("订单搜索")]
              ),
              _c(
                "el-input",
                {
                  staticStyle: {
                    width: "350px",
                  },
                  attrs: {
                    placeholder: "请输入内容",
                    clearable: "",
                  },
                  on: {
                    input: _vm.handleTypeInputChange,
                  },
                  model: {
                    value: _vm.orderTypeInput,
                    callback: function callback($$v) {
                      _vm.orderTypeInput = $$v
                    },
                    expression: "orderTypeInput",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        width: "120px",
                      },
                      attrs: {
                        slot: "prepend",
                        placeholder: "请选择",
                      },
                      on: {
                        change: _vm.handleTypeChange,
                      },
                      slot: "prepend",
                      model: {
                        value: _vm.orderType,
                        callback: function callback($$v) {
                          _vm.orderType = $$v
                        },
                        expression: "orderType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label: "发货单编号",
                          value: "item_no",
                        },
                      }),
                      _c("el-option", {
                        attrs: {
                          label: "定时发货单号",
                          value: "regular_order_no",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "page-color--normal",
                },
                [_vm._v("预计发货时间")]
              ),
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  clearable: "",
                  "unlink-panels": "",
                },
                on: {
                  change: _vm.handleDateChange,
                },
                model: {
                  value: _vm.order_time,
                  callback: function callback($$v) {
                    _vm.order_time = $$v
                  },
                  expression: "order_time",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "page-color--normal",
                },
                [_vm._v("物流单号")]
              ),
              _c("el-input", {
                staticClass: "page-select--item",
                staticStyle: {
                  width: "150px",
                },
                attrs: {
                  clearable: "",
                  placeholder: "请输入物流单号",
                },
                model: {
                  value: _vm.searchForm.delivery_no,
                  callback: function callback($$v) {
                    _vm.$set(_vm.searchForm, "delivery_no", $$v)
                  },
                  expression: "searchForm.delivery_no",
                },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            {
              staticClass: "page-filter--wrap",
            },
            [
              _c(
                "div",
                {
                  staticClass: "page-color--normal",
                },
                [_vm._v("发货时间")]
              ),
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  clearable: "",
                  "unlink-panels": "",
                },
                on: {
                  change: _vm.handleDeliveryDateChange,
                },
                model: {
                  value: _vm.delivery_time,
                  callback: function callback($$v) {
                    _vm.delivery_time = $$v
                  },
                  expression: "delivery_time",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "page-color--normal",
                },
                [_vm._v("仅看当前节气")]
              ),
              _c(
                "el-select",
                {
                  staticClass: "page-select--item",
                  staticStyle: {
                    width: "150px",
                  },
                  attrs: {
                    placeholder: "",
                  },
                  model: {
                    value: _vm.searchForm.sole_term,
                    callback: function callback($$v) {
                      _vm.$set(_vm.searchForm, "sole_term", $$v)
                    },
                    expression: "searchForm.sole_term",
                  },
                },
                [
                  _c("el-option", {
                    attrs: {
                      label: "是",
                      value: true,
                    },
                  }),
                  _c("el-option", {
                    attrs: {
                      label: "否",
                      value: false,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "page-color--normal",
                },
                [_vm._v("收货人手机")]
              ),
              _c("el-input", {
                staticClass: "page-select--item",
                staticStyle: {
                  width: "150px",
                },
                attrs: {
                  clearable: "",
                  placeholder: "请输入收货人手机",
                },
                model: {
                  value: _vm.searchForm.receiver_phone,
                  callback: function callback($$v) {
                    _vm.$set(_vm.searchForm, "receiver_phone", $$v)
                  },
                  expression: "searchForm.receiver_phone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            {
              staticClass: "page-filter--wrap",
              staticStyle: {
                margin: "20px 0",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    round: "",
                    icon: "el-icon-refresh",
                  },
                  on: {
                    click: _vm.resetSearchForm,
                  },
                },
                [_vm._v(" 重置 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    round: "",
                    icon: "el-icon-search",
                  },
                  on: {
                    click: _vm.getList,
                  },
                },
                [_vm._v(" 搜索 ")]
              ),
              _c(
                "el-popconfirm",
                {
                  attrs: {
                    title: "是否按当前搜索条件导出文件？",
                  },
                  on: {
                    onConfirm: function onConfirm($event) {
                      return _vm.exportFile("order")
                    },
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        "margin-left": "10px",
                      },
                      attrs: {
                        slot: "reference",
                        type: "success",
                        round: "",
                        icon: "el-icon-download",
                        loading: _vm.downloadingOrder,
                      },
                      slot: "reference",
                    },
                    [_vm._v(" 导出订单文件 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-row", [
            _c(
              "div",
              {
                staticStyle: {
                  "margin-bottom": "15px",
                },
              },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: {
                      size: "small",
                    },
                    on: {
                      change: _vm.handleOrderRadioChange,
                    },
                    model: {
                      value: _vm.order_filter,
                      callback: function callback($$v) {
                        _vm.order_filter = $$v
                      },
                      expression: "order_filter",
                    },
                  },
                  [
                    _c("el-radio-button", {
                      attrs: {
                        label: "全部",
                      },
                    }),
                    _c(
                      "el-radio-button",
                      {
                        attrs: {
                          label: "NODELIVERY",
                        },
                      },
                      [_vm._v(" 待发货 ")]
                    ),
                    _c(
                      "el-radio-button",
                      {
                        attrs: {
                          label: "DELIVERY",
                        },
                      },
                      [_vm._v(" 已发货 ")]
                    ),
                    _c(
                      "el-radio-button",
                      {
                        attrs: {
                          label: "SKIP",
                        },
                      },
                      [_vm._v(" 已跳过 ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: {
                width: "100%",
              },
              attrs: {
                data: _vm.storeData,
                stripe: "",
                border: "",
                "row-key": "id",
              },
              on: {
                "selection-change": _vm.handleSelectionChange,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  width: "55",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: "center",
                  width: "250",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref) {
                      var row = _ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: row.order_states != "NODELIVERY",
                              type: "text",
                            },
                            on: {
                              click: function click($event) {
                                return _vm.handleDispatchOrder(row)
                              },
                            },
                          },
                          [_vm._v(" 发货 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                            },
                            on: {
                              click: function click($event) {
                                return _vm.handleAddNote(row)
                              },
                            },
                          },
                          [_vm._v(" 备注 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "spu_name",
                  label: "商品信息",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "delivery_no",
                  label: "物流单号",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "order_no",
                  label: "订单编号",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "regular_order_no",
                  label: "定时发货单号",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "item_no",
                  label: "发货单编号",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "pre_sent_time",
                  label: "预计发货时间",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "solar_term",
                  label: "所属节气",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "收货人",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref2) {
                      var row = _ref2.row
                      return [
                        _c("div", [
                          _c("div", [_vm._v(_vm._s(row.receiver_name))]),
                          _c("div", [_vm._v(_vm._s(row.receiver_phone))]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "收货地址",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref3) {
                      var row = _ref3.row
                      return [
                        _vm._v(
                          " " +
                            _vm._s(row.province) +
                            _vm._s(row.city) +
                            _vm._s(row.county) +
                            _vm._s(row.receiver_address) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "发货状态",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref4) {
                      var row = _ref4.row
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("regularOrderDetailText")(row.order_states)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "remark",
                  align: "center",
                  label: "备注",
                  "show-overflow-tooltip": "",
                },
              }),
            ],
            1
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit,
            },
            on: {
              "update:page": function updatePage($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function updateLimit($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "卖家备注",
            visible: _vm.dialogAddNote,
            width: "40%",
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.dialogAddNote = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              placeholder: "最多可输入256个字",
              maxlength: "256",
              "show-word-limit": "",
            },
            model: {
              value: _vm.note_form.remark,
              callback: function callback($$v) {
                _vm.$set(_vm.note_form, "remark", $$v)
              },
              expression: "note_form.remark",
            },
          }),
          _c(
            "div",
            {
              attrs: {
                slot: "footer",
              },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "text",
                  },
                  on: {
                    click: function click($event) {
                      _vm.dialogAddNote = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                  },
                  on: {
                    click: _vm.handleAddNoteConfirm,
                  },
                },
                [_vm._v(" 确定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "订单发货",
            visible: _vm.dialogDispatchOrder,
            top: "5vh",
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.dialogDispatchOrder = $event
            },
          },
        },
        [
          _c("dispatch-order", {
            attrs: {
              "goods-data": _vm.currentGoodsData,
            },
            on: {
              handleDelivery: _vm.handleDelivery,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }