<template>
  <el-table-column v-bind="$attrs">
    <template slot-scope="{ row }">
      {{ format(row, $attrs.prop) }}
    </template>
  </el-table-column>
</template>

<script>
import { moneyFormatter, percentFormatter, percentToFixedFormatter } from "@/utils";
import { formatDuration } from "@/utils/format-time";
export default {
  name: "MyTableColumn",
  props: {
    formatMoney: {
      type: Boolean,
      default: false,
    },
    formatDuration: {
      type: Boolean,
      default: false,
    },
    toFixed: {
      type: Boolean,
      default: false,
    },
    toFixedNoSuffix: {
      type: Boolean,
      default: false,
    },
    formatter: {
      default: null,
    },
  },
  methods: {
    format(row, prop) {
      const props = prop.split(".");
      const data = props.length === 1 ? row[prop] : row[props[0]] ? row[props[0]][props[1]] : null;
      if (typeof data !== "number" && !data) return "/";
      // 自定义的格式化函数优先级最高
      if (typeof this.formatter === "function") {
        return this.formatter(data);
      }
      if (this.formatMoney) return moneyFormatter(data);
      if (this.formatDuration) return formatDuration(data);
      if (this.toFixed) return percentFormatter(data);
      if (this.toFixedNoSuffix) return percentToFixedFormatter(data, { suffix: false });
      return data;
    },
  },
};
</script>
